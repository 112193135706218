import { Injectable } from "@angular/core";

import { Judgement } from "../shared/judgement";
import { baseURL } from "../shared/baseurl";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Http, Headers } from "@angular/http";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, forkJoin, of, from, timer } from "rxjs";

import { ProcessHttpmsgService } from "./process-httpmsg.service";
import { AuthService } from "./auth.service";

import { map, delay, catchError, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class BrowseService {
  judgement: Judgement;
  check = "0";

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService,
    private authService: AuthService
  ) {}

  getAllPrintData(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.set("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    return this.http
      .get(
        baseURL + "nearlaw/journaljudgments/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        delay(4000),
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllSCData(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.set("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    return this.http
      .get(
        baseURL + "nearlaw/getSCdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        delay(4000),
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getTimelineData(citation: string): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    return this.http
      .get(baseURL + "nearlaw/timeline/" + citation, { headers: headers })
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllMumHCData(pagenumber, usertype: string): Observable<any> {
    this.authService.checkJWTtoken();
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.set("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    return this.http
      .get(
        baseURL +
          "nearlaw/getMumHCdata/" +
          pagenumber +
          "?usertype=" +
          usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllCATdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    return this.http
      .get(
        baseURL + "nearlaw/getCATdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllMATdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    return this.http
      .get(
        baseURL + "nearlaw/getMATdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllRCTdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    return this.http
      .get(
        baseURL + "nearlaw/getRCTdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllIPABdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    return this.http
      .get(
        baseURL + "nearlaw/getIPABdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllNCDRCdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    return this.http
      .get(
        baseURL +
          "nearlaw/getNCDRCdata/" +
          pagenumber +
          "?usertype=" +
          usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllMCDRCdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL +
          "nearlaw/getMCDRCdata/" +
          pagenumber +
          "?usertype=" +
          usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllITATdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getITATdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllCESTATdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL +
          "nearlaw/getCESTATdata/" +
          pagenumber +
          "?usertype=" +
          usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllNCLATdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL +
          "nearlaw/getNCLATdata/" +
          pagenumber +
          "?usertype=" +
          usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllMAHARERAdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL +
          "nearlaw/getMAHARERAdata/" +
          pagenumber +
          "?usertype=" +
          usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllMSCAdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getMSCAdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllMACTdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getMACTdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllMILCdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getMILCdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllMSTdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getMSTdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllSATdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getSATdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllATFPdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getATFPdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllBIFRdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getBIFRdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllPNGdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getPNGdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getAllDRTdata(pagenumber, usertype: string): Observable<any> {
    const authToken = this.authService.getToken();

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    const params = new HttpParams();
    params.set("usertype", usertype);

    return this.http
      .get(
        baseURL + "nearlaw/getDRTdata/" + pagenumber + "?usertype=" + usertype,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getLatestJudgements(
    pagenumber,
    usertype: string,
    sortValue
  ): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    params.set("sortvalue", sortValue);
    return this.http
      .get(
        baseURL +
          "nearlaw/latestjudgments/" +
          pagenumber +
          "?usertype=" +
          usertype +
          "&sortvalue=" +
          sortValue,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  searchLatestJudgements(
    pagenumber,
    usertype: string,
    sortValue,
    query
  ): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    params.set("sortvalue", sortValue);
    params.set("q", query);
    return this.http
      .get(
        baseURL +
          "nearlaw/searchlatestjudgments/" +
          pagenumber +
          "?usertype=" +
          usertype +
          "&sortvalue=" +
          sortValue +
          "&q=" +
          query,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getlandmarkJudgements(
    pagenumber,
    usertype: string,
    sortValue
  ): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    params.set("sortvalue", sortValue);
    return this.http
      .get(
        baseURL +
          "nearlaw/landmarkjudgments/" +
          pagenumber +
          "?usertype=" +
          usertype +
          "&sortvalue=" +
          sortValue,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  searchLandmarkJudgements(
    pagenumber,
    usertype: string,
    sortValue,
    query
  ): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    params.set("sortvalue", sortValue);
    params.set("q", query);
    return this.http
      .get(
        baseURL +
          "nearlaw/searchlandmarkjudgments/" +
          pagenumber +
          "?usertype=" +
          usertype +
          "&sortvalue=" +
          sortValue +
          "&q=" +
          query,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getimportantJudgements(
    pagenumber,
    usertype: string,
    sortValue
  ): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    params.set("sortvalue", sortValue);
    return this.http
      .get(
        baseURL +
          "nearlaw/importantjudgments/" +
          pagenumber +
          "?usertype=" +
          usertype +
          "&sortvalue=" +
          sortValue,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  searchImportantJudgements(
    pagenumber,
    usertype: string,
    sortValue,
    query
  ): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    params.set("sortvalue", sortValue);
    params.set("q", query);
    return this.http
      .get(
        baseURL +
          "nearlaw/searchimportantjudgments/" +
          pagenumber +
          "?usertype=" +
          usertype +
          "&sortvalue=" +
          sortValue +
          "&q=" +
          query,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  getoftencitedJudgements(
    pagenumber,
    usertype: string,
    sortValue
  ): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    params.set("sortvalue", sortValue);
    return this.http
      .get(
        baseURL +
          "nearlaw/oftencitedjudgments/" +
          pagenumber +
          "?usertype=" +
          usertype +
          "&sortvalue=" +
          sortValue,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  searchOftenCitedJudgements(
    pagenumber,
    usertype: string,
    sortValue,
    query
  ): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);
    params.set("sortvalue", sortValue);
    params.set("q", query);
    return this.http
      .get(
        baseURL +
          "nearlaw/searchoftencitedjudgments/" +
          pagenumber +
          "?usertype=" +
          usertype +
          "&sortvalue=" +
          sortValue +
          "&q=" +
          query,
        { headers: headers, params: params }
      )
      .pipe(
        map(res => res),
        catchError(error => {
          return this.processHTTPMsgService.handleError(error);
        })
      );
  }

  // used in first load of browse page
  getJudgementsFromAllTribunals(
    pagenumber,
    usertype: string
  ): Observable<any[]> {
    this.authService.checkJWTtoken();
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const params = new HttpParams();
    params.set("usertype", usertype);

    const SCresponse = this.http.get(
      baseURL + "nearlaw/getSCdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const MumbaiHCresponse = this.http.get(
      baseURL + "nearlaw/getMumHCdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const CATresponse = this.http.get(
      baseURL + "nearlaw/getCATdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const MATresponse = this.http.get(
      baseURL + "nearlaw/getMATdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const RCTresponse = this.http.get(
      baseURL + "nearlaw/getRCTdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const IPABresponse = this.http.get(
      baseURL + "nearlaw/getIPABdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const NCDRCresponse = this.http.get(
      baseURL + "nearlaw/getNCDRCdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const MCDRCresponse = this.http.get(
      baseURL + "nearlaw/getMCDRCdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const ITATresonse = this.http.get(
      baseURL + "nearlaw/getITATdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const CESTATresponse = this.http.get(
      baseURL + "nearlaw/getCESTATdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const NCLATresponse = this.http.get(
      baseURL + "nearlaw/getNCLATdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const MAHARERAresponse = this.http.get(
      baseURL +
        "nearlaw/getMAHARERAdata/" +
        pagenumber +
        "?usertype=" +
        usertype,
      { headers: headers, params: params }
    );
    const MSCAresponse = this.http.get(
      baseURL + "nearlaw/getMSCAdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const MACTresponse = this.http.get(
      baseURL + "nearlaw/getMACTdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const MILCresponse = this.http.get(
      baseURL + "nearlaw/getMILCdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const MSTresponse = this.http.get(
      baseURL + "nearlaw/getMSTdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const SATresponse = this.http.get(
      baseURL + "nearlaw/getSATdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const ATFPresponse = this.http.get(
      baseURL + "nearlaw/getATFPdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const BIFRresponse = this.http.get(
      baseURL + "nearlaw/getBIFRdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const PNGresponse = this.http.get(
      baseURL + "nearlaw/getPNGdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );
    const DRTresponse = this.http.get(
      baseURL + "nearlaw/getDRTdata/" + pagenumber + "?usertype=" + usertype,
      { headers: headers, params: params }
    );

    return forkJoin([
      SCresponse.pipe(delay(5)),
      MumbaiHCresponse.pipe(delay(10)),
      CATresponse.pipe(delay(10)),
      MATresponse.pipe(delay(10)),
      RCTresponse.pipe(delay(3000)),
      IPABresponse.pipe(delay(3000)),
      NCDRCresponse.pipe(delay(3000)),
      MCDRCresponse.pipe(delay(3000)),
      ITATresonse.pipe(delay(3000)),
      CESTATresponse.pipe(delay(3000)),
      NCLATresponse.pipe(delay(3000)),
      MAHARERAresponse.pipe(delay(5000)),
      MSCAresponse.pipe(delay(5000)),
      MACTresponse.pipe(delay(5000)),
      MILCresponse.pipe(delay(5000)),
      MSTresponse.pipe(delay(5000)),
      SATresponse.pipe(delay(5000)),
      ATFPresponse.pipe(delay(5000)),
      BIFRresponse.pipe(delay(5000)),
      PNGresponse.pipe(delay(5000)),
      DRTresponse.pipe(delay(5000))
    ]);
    // .pipe(mergeMap(q => forkJoin))
  }
}

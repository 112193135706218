import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Http, Headers } from "@angular/http";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, TimeoutError } from "rxjs";
import { RouterModule, Routes, Router } from "@angular/router";

import { ProcessHttpmsgService } from "./process-httpmsg.service";
import { CookieService } from "ngx-cookie-service";
// import { AuthService } from './auth.service';

import { map, delay, catchError } from "rxjs/operators";

import { User } from "../shared/user";
import { baseURL, baseDomain } from "../shared/baseurl";
import { pipe } from "@angular/core/src/render3/pipe";
import { getToken } from "@angular/router/src/utils/preactivation";
import { async } from "@angular/core/testing";

interface AuthResponse {
  status: string;
  success: string;
  token: string;
}

interface JWTResponse {
  status: string;
  success: string;
  user: any;
}

@Injectable({
  providedIn: "root"
})
export class AuthService {

  tokenKey = "JWT";
  isAuthenticated: Boolean = false;
  username: Subject<string> = new Subject<string>();
  authToken: string = undefined;
  user: any;
  uSer: User;
  time: any;
  response = 0;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService,
    private cookieService: CookieService,
    private router: Router
  ) { }

  async checkJWTtoken() {
    await this.getTime().subscribe(async (data) => {
      let trialexpirydate;
      let expirydate;
      this.time = data;
      if (this.time !== undefined) {
        const serverTime: number = this.time.time;
        let tokenExpiry: number = +this.cookieService.get("expToken");
        const refreshTokenExpiry: number = +this.cookieService.get("expiryRefreshToken");
        tokenExpiry = tokenExpiry * 1000 - 10000;
        if (refreshTokenExpiry > 0) {
          if (serverTime > (refreshTokenExpiry * 1000)) {
            this.destroyUserCredentials();
            this.router.navigate(["/homepage"]);
            return;
          } else {
            if (tokenExpiry > 0) {
              if (serverTime > tokenExpiry) {
                await this.verifyToken().subscribe(data1 => {
                  if (data1.success === true) {
                    const newToken = data1.token;
                    const newExpiryToken = data1.expiryToken;
                    const newRefreshToken = data1.refreshToken;
                    const newExpiryRefreshToken = data1.expiryRefreshToken;

                    if (typeof newToken !== "undefined") {
                      this.cookieService.set("JWT", newToken, 30, "/", baseDomain, false, "Lax");
                    }
                    if (typeof newExpiryToken !== "undefined") {
                      this.cookieService.set("expToken", newExpiryToken, 30, "/", baseDomain, false, "Lax");
                    }
                    if (typeof newRefreshToken !== "undefined") {
                      this.cookieService.set("refreshToken", newRefreshToken, 30, "/", baseDomain, false, "Lax");
                    }
                    if (typeof newExpiryRefreshToken !== "undefined") {
                      this.cookieService.set("expiryRefreshToken", newExpiryRefreshToken, 30, "/", baseDomain, false, "Lax");
                    }

                    if (data1.user) {
                      this.cookieService.set("username", data1.user.username, 30, "/", baseDomain, false, "Lax");
                      this.cookieService.set("contactno", data1.user.contactno, 30, "/", baseDomain, false, "Lax");
                      this.cookieService.set("ID", data1.user._id, 30, "/", baseDomain, false, "Lax");
                      this.cookieService.set("usertype", data1.user.usertype, 30, "/", baseDomain, false, "Lax");

                      if (typeof data1.user.expirydate !== null) {
                        const expirystring = data1.user.expirydate;
                        expirydate = new Date(expirystring);
                      }
                      if (typeof data1.user.trialexpirydate !== null) {
                        const trialexpirystring = data1.user.trialexpirydate;
                        trialexpirydate = new Date(trialexpirystring);
                      }
                      const currentdateiso = Date.now();
                      const currentdate = new Date(currentdateiso);
                      this.user = data1.user;
                      if ((currentdate > trialexpirydate) && data1.user.usertype === "Trial") {
                        this.user.usertype = "Free";
                        // update session
                        this.updateSession(data1.user.contactno, this.user.usertype);
                        this.updateUserWithToken(data1.user._id, this.user, data1.token).subscribe(result => {
                          this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain, false, "Lax");
                        });
                      } else if ((currentdate <= trialexpirydate) && data1.user.usertype === "Trial") {
                        this.cookieService.set("usertype", data1.user.usertype, 30, "/", baseDomain, false, "Lax");
                        // update session
                        this.updateSession(data1.user.contactno, this.user.usertype);
                      } else if (data1.user.usertype === "Free") {
                        this.cookieService.set("usertype", "Free", 30, "/", baseDomain, false, "Lax");
                        // update session
                        this.updateSession(data1.user.contactno, this.user.usertype);
                      } else if (typeof data1.user.expirydate !== null && (expirydate <= currentdate) && (data1.user.usertype === "Paid" || data1.user.usertype === "MPP")) {
                        this.user.usertype = "Free";
                        this.user.paidflag = false;
                        this.user.amount = "";
                        // update session
                        this.updateSession(data1.user.contactno, this.user.usertype);
                        this.updateUserWithToken(data1.user._id, this.user, data1.token).subscribe(result => {
                          this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain, false, "Lax");
                          // update session
                          this.updateSession(data1.user.contactno, this.user.usertype);
                        });
                      } else if (typeof data1.user.expirydate !== null && (expirydate > currentdate) && (data1.user.usertype === "Paid" || data1.user.usertype === "MPP")) {
                        this.cookieService.set("usertype", data1.user.usertype, 30, "/", baseDomain, false, "Lax");
                        // update session
                        this.updateSession(data1.user.contactno, this.user.usertype);
                      }
                    }
                  }
                });
              } else {
                // return true;
              }
            }
          }
        }
      }
    });
  }

  sendUsername(name: string) {
    this.username.next(name);
  }

  clearUsername() {
    this.username.next(undefined);
  }

  loadUserCredentials() {
    const credentials = JSON.parse(localStorage.getItem(this.tokenKey));
    if (credentials && credentials.username !== undefined) {
      this.useCredentials(credentials);
      if (this.authToken) {
        this.checkJWTtoken();
      }
    }
  }

  storeUserData(token, user, Id) {
    localStorage.setItem("jwt", JSON.stringify(token));
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("Id", JSON.stringify(Id));
    this.authToken = token;
    this.user = user;
  }

  useCredentials(credentials: any) {
    this.isAuthenticated = true;
    this.sendUsername(credentials.username);
    this.authToken = credentials.token;
  }

  destroyUserCredentials() {
    this.cookieService.deleteAll("/", baseURL);
  }

  getOtp(user: User): Observable<any> {
    return this.http.post(baseURL + "users/otp", user)
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  signup(user: User): Observable<any> {
    return this.http.post(baseURL + "users/signup", user)
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  login(user: User): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post(baseURL + "users/login", user, { headers: headers })
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  updateUserWithToken(id: string, data: User, token: string): Observable<any> {
    const authToken = token;
    const myString = authToken.substr(1);   // Need to trim first and last character of authToken because it stores in the for ""abc"" which results  in mismatch of token
    // trim last character
    const actual = myString.slice(0, -1);

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + actual);
    headers.append("Set-Cookie", "HttpOnly;Secure;SameSite=Strict");
    return this.http.put(baseURL + "users/" + id, data, { headers: headers })
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  getToken(): string {
    const token = this.cookieService.get("JWT");
    return token;
  }

  getRefreshToken(): string {
    const token = this.cookieService.get("refreshToken");
    return token;
  }

  verifyToken(): Observable<any> {
    let refreshToken = "";
    const token = this.getToken();
    refreshToken = this.getRefreshToken();
    const data = {
      token,
      refreshToken
    };

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + refreshToken);

    return this.http.post(baseURL + "users/refreshTokens", data, { headers: headers })
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  submitPaymentData(data): Observable<any> {
    return this.http.post(baseURL + "paytmData", data)
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  sendDatatoPaytm(data): Observable<any> {
    return this.http.post("https://secure.paytm.in/oltp-web/processTransaction", data)
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  getUserByContactno(number): Observable<any> {
    const authToken = this.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    return this.http.get(baseURL + "users/userbycontactno/" + number, { headers: headers })
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  updateUserById(id: string, user: User): Observable<any> {
    const authToken = this.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    return this.http.put(baseURL + "users/" + id, user, { headers: headers })
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  uploadPprofilePic(photo: File, user: User): Observable<any> {
    const authToken = this.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    const uploadData = new FormData();
    uploadData.append("photo", photo, photo.name);
    uploadData.append("user", JSON.stringify(user));
    return this.http.post(baseURL + "users/profilepicupload", uploadData, { headers: headers })
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  isLoggedIn(): Boolean {
    const token = this.cookieService.get("JWT");
    if (token === null) {
      this.isAuthenticated = false;
    } else {
      this.isAuthenticated = true;
    }
    return this.isAuthenticated;
  }

  getTime(): any {
    return this.http.get(baseURL + "getTime")
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  checkUser(contactno) {
    return this.http.post(baseURL + "users/checkuser", contactno)
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  getSession(contactno) {
    const authToken = this.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    return this.http.get(baseURL + "users/session?contactno=" + contactno, { headers: headers })
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  updateSession(contactno, data) {
    const authToken = this.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    return this.http.put(baseURL + "users/session?contactno=" + contactno, data, { headers: headers })
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }
}

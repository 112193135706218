export class User {
    _id: string;
    username: string;
    contactno: string;
    bcienrollmentno: string;
    practicearea: string;
    state: string;
    // district: string;
    usertype: string;
    expirydate: Date;
    trialexpirydate: Date;
    amount: string;
    paidflag: boolean;
    otp: string;
    userprofilephotopath: string;
    userprofilephotoname: string;
    useridentity: string;
    emailid: string;
    age: string;
    gender: string;
    secondarypracticearea: string;
    tertiarypracticearea: string;
    courtofpractice: string;
    documentation: boolean;
    typesofdocumentation: string[];
    bookmarks: string[];
    annotations: Annotation[];
    // trial_user: boolean;
}

export class Annotation {
    citation: string;
    body: string;
    createdAt: Date;
    updatedAt: Date;
    _id: string;
}

import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  ViewChild,
  ElementRef
} from "@angular/core";
import {
  MatDialog,
  MatChip,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar
} from "@angular/material";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

import { LoginComponent } from "../login/login.component";
import { SignupComponent } from "../signup/signup.component";

import { Judgement } from "../shared/judgement";
import { courtnames } from "../shared/courtnames";

import { HomepageService } from "../services/homepage.service";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "../services/auth.service";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HomepageComponent implements OnInit {
  judgements = [];
  singlejud: Judgement;
  citationClicked = false;
  acts = [];
  sortedActs = [];
  cnts = [Object];
  topicCounts = [Object];
  actFocus = false;
  judgBasedOnAct = [];
  ActIndexExpansion = false;
  isJudgementsVisible = false;
  singlejudBasedOnAct: Judgement;
  isActClicked = false;
  initialState = true;
  selectedCourt: string;
  searchText: string;
  isExact = false;
  search_judgements = [];
  isSearchedJudgVisible = true;
  totalSearchResults: number;
  isSearchedCitationClicked = false;
  singleSearchedJudg: Judgement;
  homeSearchQuery: string;
  CitationCount: number; // used in act index
  topics = [];
  sortedTopics = [];
  judgBasedOnTopic = [];
  singlejudBasedOnTopic: Judgement;
  isJudgementBasedOnTopic = false;
  query: string;
  mob_no = new FormControl("", [
    Validators.minLength(10),
    Validators.maxLength(10),
    Validators.pattern("[0-9]*"),
    Validators.required
  ]);
  mob_no_demo = new FormControl("", [
    Validators.minLength(10),
    Validators.maxLength(10),
    Validators.pattern("[0-9]*"),
    Validators.required
  ]);
  matcher = new MyErrorStateMatcher();

  tacChecked = false;
  contactNo: string;
  contactNoDemo: string;
  showTrib = false;
  showProgressBar = false;

  ratinArr = [];

  CourtList = courtnames;
  
  reviews = [
    {
      id:
        "gp:AOqpTOFHwAxF5e7UWkVshp_DPi9PZ5fAJKn1R6xY4plziU0lippEY7L1kVYRxA6ek0mqIKeMmLeedv1Grf19wKg",
      userName: "Somnath Gaikwad",
      userImage:
        "https://lh6.googleusercontent.com/-CA7VzVMfMSE/AAAAAAAAAAI/AAAAAAAAAAA/AAN31DWW1HwvzpPqfNlozH13mwmc3L8-qg/w96-h96-p/photo.jpg",
      date: "August 25, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPRkh3QXhGNWU3VVdrVnNocF9EUGk5UFo1ZkFKS24xUjZ4WTRwbHppVTBsaXBwRVk3TDFrVllSeEE2ZWswbXFJS2VNbUxlZWR2MUdyZjE5d0tn",
      score: 5,
      title: "",
      text: "Very nice app",
      replyDate: "September 10, 2018",
      replyText:
        "Mr. Gaikwad, thank you so much for your kind words. Your encouragement and patronage keeps us going. Please do try out the Advanced Voice search and Advanced Search (with Multiple Acts, Section, Order Rule ) and Multiple Keywords and Multiple Headnote Keywords.  Pls share with other Advocates/ Lawyers so maximum people can benefit from this."
    },
    {
      id:
        "gp:AOqpTOEngqhWnVsjGx6U4w9FZbVXDk1WW3011Yd9TFvWne6JbgY83goRuQYZ9s64uVgmeZTiYdxnM2B3d0oNk-I",
      userName: "Ram Kripal Gautam",
      userImage:
        "https://lh3.googleusercontent.com/-ZtYzo6EbBIc/AAAAAAAAAAI/AAAAAAAAB3s/Um2dCL7AWwU/w96-h96-p/photo.jpg",
      date: "August 16, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPRW5ncWhXblZzakd4NlU0dzlGWmJWWERrMVdXMzAxMVlkOVRGdlduZTZKYmdZODNnb1J1UVlaOXM2NHVWZ21lWlRpWWR4bk0yQjNkMG9Oay1J",
      score: 5,
      title: "",
      text: "This is very usfull",
      replyDate: "September 10, 2018",
      replyText:
        "Thank you very much for your kind words Mr. Ram Kripal Gautam Sir.  Our team is thankful for your appreciation. We are working on making app more useful by adding Latest Recent Judgments of Supreme Court and Bombay High Court and Other High Courts in Journal Section. Also working on headnotes of case-laws and adding Law Codes (Central Acts) soon."
    },
    {
      id:
        "gp:AOqpTOE4wI0F9ktDBAC2Nl3zMINwh71ZiIgbazuW_aqUmQcSfxszK3asJwEExm7BH7Bq1Hld6bT7f_FuxxUgXpM",
      userName: "VENKATRAMAN J",
      userImage:
        "https://lh6.googleusercontent.com/-1iO_0uhz3iY/AAAAAAAAAAI/AAAAAAAACN4/n7uP7gTMQFY/w96-h96-p/photo.jpg",
      date: "August 4, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPRTR3STBGOWt0REJBQzJObDN6TUlOd2g3MVppSWdiYXp1V19hcVVtUWNTZnhzekszYXNKd0VFeG03Qkg3QnExSGxkNmJUN2ZfRnV4eFVnWHBN",
      score: 5,
      title: "",
      text: "User friendly",
      replyDate: "September 10, 2018",
      replyText:
        "Thank you Mr. Venkatraman. Your feedback and high rating are much appreciated by our entire team. We are making NearLaw Legal app more useful for Lawyers in India by adding Latest Recent Judgments of Supreme Court and Bombay High Court and Other High Courts in Journal Section. Also working on headnotes of case-laws and adding Central Acts soon."
    },
    {
      id:
        "gp:AOqpTOGa2qhnhIId78-_ruGOkFoqW9KBhH8bQs-GmX-j_EZb8IUYm7g6Gb938ZzheYdGbPG7vbKMSyhYDeisDZ0",
      userName: "Knowledge & Research Academy For UPSC/MPSC/SSC",
      userImage:
        "https://lh6.googleusercontent.com/-exH5VCuhkJU/AAAAAAAAAAI/AAAAAAAAEqs/3d4k4CrX5t8/w96-h96-p/photo.jpg",
      date: "August 2, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPR2EycWhuaElJZDc4LV9ydUdPa0ZvcVc5S0JoSDhiUXMtR21YLWpfRVpiOElVWW03ZzZHYjkzOFp6aGVZZEdiUEc3dmJLTVN5aFlEZWlzRFow",
      score: 5,
      title: "",
      text: "Good apps for advocate",
      replyDate: "September 10, 2018",
      replyText:
        "Thank you so much for your kind words Mr. Shamim Shaikh. Your kind words and high rating for NearLaw app encourages our team to do more. We have added \"Latest Judgment\" screen for reporting High Court Judgements and Supreme Court Judgements quickly.  Also working on reporting Central Acts & Amendments soon.  Please do share App with your friends."
    },
    {
      id:
        "gp:AOqpTOGHSZSm4MNuA-W17HiC8hP9mRbu7KTINMy4Osm0xFeNgYncuTPdVG9VVdAF1wHKpj99BRX0paTo8hEHI80",
      userName: "sarfraz pirzade",
      userImage:
        "https://lh4.googleusercontent.com/-fToYGbTOlzw/AAAAAAAAAAI/AAAAAAAAAAA/AAN31DUYSErDFaRCRvTic4RWrbb3ybIMAw/w96-h96-p/photo.jpg",
      date: "July 30, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPR0hTWlNtNE1OdUEtVzE3SGlDOGhQOW1SYnU3S1RJTk15NE9zbTB4RmVOZ1luY3VUUGRWRzlWVmRBRjF3SEtwajk5QlJYMHBhVG84aEVISTgw",
      score: 4,
      title: "",
      text: "Nice",
      replyDate: "September 10, 2018",
      replyText:
        "Thank you so much for your kind words Mr. Sarfraz Pirzade. Your kind words and high rating for NearLaw app encourages our team to do more. We have added \"Latest Judgment\" screen for reporting Bombay High Court Judgements and Supreme Court Judgements.  One small request is to try and share the App with friends, colleagues interested in Indian laws."
    },
    {
      id:
        "gp:AOqpTOHBfFLtnHcXvWeFF-jDrrv8zlMyOaG7wxiZMwpyWmUwzJ4dugMOyxI4zIq6YCEQyA71US2Uh44ZxhvvUNg",
      userName: "Jaydeo Ishwar lahore",
      userImage:
        "https://lh3.googleusercontent.com/-hFj0Ni88LPs/AAAAAAAAAAI/AAAAAAAAAJs/QQmPdBZT3Js/w96-h96-p/photo.jpg",
      date: "July 20, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPSEJmRkx0bkhjWHZXZUZGLWpEcnJ2OHpsTXlPYUc3d3hpWk13cHlXbVV3eko0ZHVnTU95eEk0eklxNllDRVF5QTcxVVMyVWg0NFp4aHZ2VU5n",
      score: 5,
      title: "",
      text: "Good apps but update case law necessary",
      replyDate: "July 26, 2018",
      replyText:
        "Adv. Lahore, we have just released the Update for January to June 2018 with Supreme Court judgments\n\nUpdate for Bombay High Court judgments + SC judgments + 7 MH Tribunals (MH Administrative Trib. , MH Consumer Commsn, MH Accident Claims, MH Coop Societies App. Trib., MahaRERA, MH School Trib, MH Industrial/Labour Courts) is on https://NearLaw.com"
    },
    {
      id:
        "gp:AOqpTOGfva4j6cXAGosbBcxcniOcVlotZGwfV4GhbRelNdoSGJTRRIZDLcKJrOMq4weSUMplx2lBE1SHgV3iXsk",
      userName: "ajay Tamhane",
      userImage:
        "https://lh6.googleusercontent.com/-CxkC-xRW0oI/AAAAAAAAAAI/AAAAAAAAAAA/AAN31DU4ReY_vzjtxnst-o1qsma2BxDYbA/w96-h96-p/photo.jpg",
      date: "July 6, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPR2Z2YTRqNmNYQUdvc2JCY3hjbmlPY1Zsb3RaR3dmVjRHaGJSZWxOZG9TR0pUUlJJWkRMY0tKck9NcTR3ZVNVTXBseDJsQkUxU0hnVjNpWHNr",
      score: 5,
      title: "",
      text:
        "Nice app. I love it. Please make app for unlimited for. Do not limit it's \nworking",
      replyDate: "July 26, 2018",
      replyText:
        "Thank you for your review Adv. Tamhane.  We are adding latest case-law and just added the January to June 2018 update for SC judgments.\n\nUpdate for Bombay High Court + SC judgments + 7 MH Tribunals (MH Administrative Trib. , MH Consumer Commsn, MH Accident Claims, MH Coop Societies App. Trib., MahaRERA, etc.) is on https://NearLaw.com"
    },
    {
      id:
        "gp:AOqpTOGktYBrd0NKj1Rfbu7Qv61sptofvtwBU_Mz8vj4YU2s5PvEikbLyAe9FRf3tnFerl0F1lau1TMs9Q6VhEM",
      userName: "padmakar bidkar",
      userImage:
        "https://lh5.googleusercontent.com/-Q3HNdGN_aEE/AAAAAAAAAAI/AAAAAAAAAAA/AAN31DUBwaNsyM4hJkmfjwIeZzxHSN6vwg/w96-h96-p/photo.jpg",
      date: "July 6, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPR2t0WUJyZDBOS2oxUmZidTdRdjYxc3B0b2Z2dHdCVV9Nejh2ajRZVTJzNVB2RWlrYkx5QWU5RlJmM3RuRmVybDBGMWxhdTFUTXM5UTZWaEVN",
      score: 5,
      title: "",
      text: "खुपच छान"
    },
    {
      id:
        "gp:AOqpTOGfQEqfq56_UyFpJVmU4ekcP-If4ipIivDTVzNfTf-fPU8W6dlrXTjEvbB6P7c7m35hgr6431UvNvtKSlQ",
      userName: "B Ravi",
      userImage:
        "https://lh5.googleusercontent.com/-SV9hvh041Mg/AAAAAAAAAAI/AAAAAAAAAAA/AAN31DWLgy8LiAK89YdxaUNSZzFntNTAvQ/w96-h96-p/photo.jpg",
      date: "May 12, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPR2ZRRXFmcTU2X1V5RnBKVm1VNGVrY1AtSWY0aXBJaXZEVFZ6TmZUZi1mUFU4VzZkbHJYVGpFdmJCNlA3YzdtMzVoZ3I2NDMxVXZOdnRLU2xR",
      score: 5,
      title: "",
      text: "The Best",
      replyDate: "July 26, 2018",
      replyText:
        "Thank you for the kind words of appreciation Adv. B Ravi.  We have recently updated the January to June 2018 batch of SC judgments.  More updates will be provided quickly.\n\nPlease email us at help@nearlaw.com with any improvements we can provide to make legal research fast, accurate, easy and responsive."
    },
    {
      id:
        "gp:AOqpTOGjaZvQwjCWXA74tpHVV0d5AwqiGLbzqbVXa_to4SCWub0IiUnKi20GFnGR9VyodZKZOxqoRmfHIsgTN6k",
      userName: "pari mandhane",
      userImage:
        "https://lh4.googleusercontent.com/-b72stlNhKp8/AAAAAAAAAAI/AAAAAAAAAAA/AAN31DXzAg9SMIEt60V-8f4KiD19tu2oPA/w96-h96-p/photo.jpg",
      date: "February 1, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPR2phWnZRd2pDV1hBNzR0cEhWVjBkNUF3cWlHTGJ6cWJWWGFfdG80U0NXdWIwSWlVbktpMjBHRm5HUjlWeW9kWktaT3hxb1JtZkhJc2dUTjZr",
      score: 5,
      title: "",
      text: "Nice app for lawyer",
      replyDate: "September 10, 2018",
      replyText:
        "Thank you Ms. Mandhane for the kind words, please do share with other colleagues who are interested in Indian legal information.  Our latest update covers Latest Judgments screen with Latest Judgments of Supreme Court and Bombay High Court.  New Voice and Advanced Search is better with much more options. The CaseProfile now has speech feature too!"
    },
    {
      id:
        "gp:AOqpTOEwqsMt4UmD-2m4iKGM7VFafD4q-U5QyRJYoHAsmikTDRcSs67TZi11mFM8M-VCNAPNBUj-6frz-f7W01U",
      userName: "Nishant Berad",
      userImage:
        "https://lh3.googleusercontent.com/-I0KdQI2qeVM/AAAAAAAAAAI/AAAAAAAAAAA/AAN31DXphcwQlqBeYIDYlGtXf_lBCdvrQQ/w96-h96-p/photo.jpg",
      date: "January 29, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPRXdxc010NFVtRC0ybTRpS0dNN1ZGYWZENHEtVTVReVJKWW9IQXNtaWtURFJjU3M2N1RaaTExbUZNOE0tVkNOQVBOQlVqLTZmcnotZjdXMDFV",
      score: 5,
      title: "",
      text: "Good app for junior advocates",
      replyDate: "February 12, 2018",
      replyText:
        "Thank you Adv. Berad. We are adding more information i.e. case-law. Last week, we updated 1990-1994 SC case-law with Headnotes and also added SC Sept-Dec 2017 data. Soon we will also be adding 2018 Jan and Feb data.  Pls do login at https://NearLaw to use web product on laptop/ desktop and recommend app to other Lawyers and Advocates too."
    },
    {
      id:
        "gp:AOqpTOHT0UcnUPxSPsQz9Ne3bqJSJkffJbUSbV85jZdBVv8fILw0ERnDY_EpDokwPTnEGRT7HDutinqTyD9B2PU",
      userName: "nikhil dhumatker",
      userImage:
        "https://lh5.googleusercontent.com/-UrC2KjeyAcc/AAAAAAAAAAI/AAAAAAAAA0A/ggJGZwzd0Ew/w96-h96-p/photo.jpg",
      date: "January 27, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPSFQwVWNuVVB4U1BzUXo5TmUzYnFKU0prZmZKYlVTYlY4NWpaZEJWdjhmSUx3MEVSbkRZX0VwRG9rd1BUbkVHUlQ3SER1dGlucVR5RDlCMlBV",
      score: 5,
      title: "",
      text: "Good",
      replyDate: "February 12, 2018",
      replyText:
        "Thank you Adv. Dhumatker for your kind words, it encourages us to do better.  You can also login to https://NearLaw.com for accessing the web platform with your registered email address and password.  Thanks and do refer NearLaw app to other Advocates and Lawyers."
    },
    {
      id:
        "gp:AOqpTOHZFAbE84F7ti2YIrr9xSRQkIXaRH01gRM8pBmykiGIDY68eWbldhGj3_nHIOAn4LPeSA7Hhipq86JBCpE",
      userName: "Vishnu Bhosale",
      userImage:
        "https://lh6.googleusercontent.com/-dWj_T3FxZdc/AAAAAAAAAAI/AAAAAAAAAAA/AAN31DWwczw_H3mmHyfx1P5Ui3-NE1nxtw/w96-h96-p/photo.jpg",
      date: "January 24, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPSFpGQWJFODRGN3RpMllJcnI5eFNSUWtJWGFSSDAxZ1JNOHBCbXlraUdJRFk2OGVXYmxkaEdqM19uSElPQW40TFBlU0E3SGhpcHE4NkpCQ3BF",
      score: 5,
      title: "",
      text: "Excellent",
      replyDate: "January 26, 2018",
      replyText:
        "Thank you Adv. Bhosale for your kind words, it encourages us to do better.  You can also login to https://NearLaw.com for accessing the web platform with your registered email address and password.  Thanks and do refer NearLaw app to other Advocates and Lawyers."
    },
    {
      id:
        "gp:AOqpTOFNZsgXPnjHih7vaIT77VvMk4tjniZ0Y3dIOjWBfNKZADY-42DKQmpaLQhzeTSeIDoT88vwrFvyg99toTU",
      userName: "Sham Narvekar",
      userImage:
        "https://lh3.googleusercontent.com/-R5wT7UVVyLk/AAAAAAAAAAI/AAAAAAAAADs/PDGwbearz6g/w96-h96-p/photo.jpg",
      date: "January 22, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPRk5ac2dYUG5qSGloN3ZhSVQ3N1Z2TWs0dGpuaVowWTNkSU9qV0JmTktaQURZLTQyREtRbXBhTFFoemVUU2VJRG9UODh2d3JGdnlnOTl0b1RV",
      score: 5,
      title: "",
      text: "best app",
      replyDate: "January 26, 2018",
      replyText:
        "Thank you Adv. Narvekar for your kind words and appreciation.  You can also login to NearLaw.com with your registered email address and same password.  Also, pls do recommend NearLaw app to other Advocates and Lawyers."
    },
    {
      id:
        "gp:AOqpTOEHYMV7WLUe4HAoFzIjS2WnIpSok2EBF56qK98SaMbC3jCTyErx1lSMKbIUt9PXe2sg2xcti1MWB--nnWk",
      userName: "Murtza Rathod",
      userImage:
        "https://lh6.googleusercontent.com/-JNhx-gUoX0k/AAAAAAAAAAI/AAAAAAAAACQ/VMjNx4xk6cI/w96-h96-p/photo.jpg",
      date: "January 20, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPRUhZTVY3V0xVZTRIQW9GeklqUzJXbklwU29rMkVCRjU2cUs5OFNhTWJDM2pDVHlFcngxbFNNS2JJVXQ5UFhlMnNnMnhjdGkxTVdCLS1ubldr",
      score: 5,
      title: "",
      text: "Nice app for law judgement",
      replyDate: "January 23, 2018",
      replyText:
        "Thank you Adv. Rathod for kind words of encouragement. Pls do recommend and share app link with other Advocates and Lawyers, so they too can utilize it. Also you can also login to https://NearLaw.com with registered email address and set password, no need to re-register there. Thanks again."
    },
    {
      id:
        "gp:AOqpTOHhER9dOytrp_uVyVxd91YwExmLP-PdmkeqarhGi3oU8N__tlf-gQWsQJyZR3o0tlM79lZD9BDmd3Bly-s",
      userName: "DNYANESHWAR KORDE",
      userImage:
        "https://lh4.googleusercontent.com/-8gKnmxtOgSQ/AAAAAAAAAAI/AAAAAAAAAAA/AAN31DXMNSaDXY1HK8VhyK2T6SZcaJlULg/w96-h96-p/photo.jpg",
      date: "January 11, 2018",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPSGhFUjlkT3l0cnBfdVZ5VnhkOTFZd0V4bUxQLVBkbWtlcWFyaEdpM29VOE5fX3RsZi1nUVdzUUp5WlIzbzB0bE03OWxaRDlCRG1kM0JseS1z",
      score: 5,
      title: "",
      text: "It is best",
      replyDate: "January 12, 2018",
      replyText:
        "Thank you for your kind words Adv. Korde.  Your appreciation encourages us to do better.  Pls do inform  other Advocates of the NearLaw app."
    },
    {
      id:
        "gp:AOqpTOGpPMAggRHb1diGBuM9YDRgWYOD1lEdmD3ntBPnRj0jBIfZQNvd5u9aK_yaotGDrMQK0Mom9VA-vtCsDfY",
      userName: "sandeep vishwakarma",
      userImage:
        "https://lh5.googleusercontent.com/-5Lq42W8kfMk/AAAAAAAAAAI/AAAAAAAAAB4/Mh-PAkKo28U/w96-h96-p/photo.jpg",
      date: "December 26, 2017",
      url:
        "https://play.google.com/store/apps/details?id=com.advocate.caselaw.acts&reviewId=Z3A6QU9xcFRPR3BQTUFnZ1JIYjFkaUdCdU05WURSZ1dZT0QxbEVkbUQzbnRCUG5SajBqQklmWlFOdmQ1dTlhS195YW90R0RyTVFLME1vbTlWQS12dENzRGZZ",
      score: 5,
      title: "",
      text: "Good for  Advocate",
      replyDate: "January 4, 2018",
      replyText:
        "Thank you Adv. Vishwakarma for your appreciation. Pls do recommend and share with other Advocates, who might find it useful."
    }
  ];

  @ViewChild("results") public Results: ElementRef;

  constructor(
    public loginModal: MatDialog,
    public signupModal: MatDialog,
    private homepageService: HomepageService,
    private cookieService: CookieService,
    private authService: AuthService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.homepageService.getLatestJudgement().subscribe(data => {
      data.hits.hits.forEach(juddata => {
        this.judgements.push(juddata._source);
      });
      for (let i = 0; i < this.judgements.length; i++) {
        const act: string = this.judgements[i].act_statue_name;

        if (act.indexOf("\n") > 0) {
          // For detecting line break
          const firstact = act.split("\n");
          this.acts.push(firstact[0]);
        } else {
          this.acts.push(act);
        }
        if (this.judgements[i].topics !== undefined) {
          for (let j = 0; j < this.judgements[i].topics.length; j++) {
            this.topics.push(this.judgements[i].topics[j]);
          }
        }
      }
      const counts = this.acts.reduce(function(obj, val) {
        // for storing occurence of all acts in array
        obj[val] = (obj[val] || 0) + 1;
        return obj;
      }, {});
      this.cnts = counts;
      this.sortedActs = Object.keys(counts).sort(function(a, b) {
        // sorting in descending order
        return counts[b] - counts[a];
      });
      const topiccounts = this.topics.reduce(function(obj, val) {
        // for storing occurence of all topics in array
        obj[val] = (obj[val] || 0) + 1;
        return obj;
      }, {});
      this.topicCounts = topiccounts;
      this.sortedTopics = Object.keys(topiccounts).sort(function(a, b) {
        // sorting in descending order
        return topiccounts[b] - topiccounts[a];
      });

      // act index initial act/judgement
      this.judgBasedOnAct = [];
      for (let i = 0; i < this.judgements.length; i++) {
        const act: string = this.judgements[i].act_statue_name;
        const actposition = act.includes(this.acts[0]);
        if (actposition === true) {
          this.judgBasedOnAct.push(this.judgements[i]);
        }
      }

      // topical index initial topic/ judgement
      this.judgBasedOnTopic = [];
      for (let i = 0; i < this.judgements.length; i++) {
        if (this.judgements[i].topics !== undefined) {
          for (let j = 0; j < this.judgements[i].topics.length; j++) {
            if (this.judgements[i].topics[j] === this.sortedTopics[0]) {
              this.judgBasedOnTopic.push(this.judgements[i]);
            }
          }
        }
      }
    });
    for (let i = 0; i < 5; i++) {
      this.ratinArr.push(i);
    }
  }

  openLoginModal() {
    const loginDialogRef = this.loginModal.open(LoginComponent, {
      width: "400px",
      height: "400px"
    });
  }

  openSignupModal() {
    const signupDialogRef = this.signupModal.open(SignupComponent, {
      width: "450px",
      height: "450px",
      disableClose: true
    });
  }

  onSearch() {
    let searchvalue: string = this.searchText;
    if (
      searchvalue === "*.*" ||
      searchvalue[0] === ":" ||
      searchvalue.slice(-1) === ":"
    ) {
      this.snackBar.open("Invalid search query!", "OK", {
        duration: 3000
      });
    } else {
      if (this.isExact === true) {
        // //('true');
        searchvalue = "(\"" + searchvalue + "\")";
      } else if (this.isExact === false) {
        // //('false');
        if (searchvalue.indexOf(" ") !== -1) {
          searchvalue = searchvalue.replace(/\s+/g, " AND "); // replacing space with '%20AND%20'
        }
        searchvalue = "(" + searchvalue + ")";
      }
    }

    if (this.selectedCourt === "" || this.selectedCourt === undefined) {
      this.query = searchvalue + " AND tribunal:\"Supreme Court\"";
    } else if (this.selectedCourt !== "") {
      this.query = searchvalue + " AND tribunal:" + this.selectedCourt;
    }
    this.homeSearchQuery = this.query;
    this.search_judgements = [];
    this.showProgressBar = true;

    this.homepageService.homeSearch(this.query).subscribe(data => {
      this.showProgressBar = false;
      if (data.hits.total > 0) {
        setTimeout(() => {
          document
            .getElementById("results")
            .scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "start"
            });
        });
        // console.log('hey');
        this.totalSearchResults = data.hits.total;
        for (let i = 0; i < 5; i++) {
          this.search_judgements.push(data.hits.hits[i]._source);
        }
      } else {
        this.snackBar.open("No results!", "OK", {
          duration: 3000
        });
      }
    });
  }

  onExactClick() {
    this.isExact = !this.isExact;
  }

  openJudgement(judgement: Judgement) {
    this.citationClicked = true;
    this.singlejud = judgement;
  }

  actClicked(selectedAct: string) {
    this.judgBasedOnAct = [];
    this.initialState = false;
    for (let i = 0; i < this.judgements.length; i++) {
      const act: string = this.judgements[i].act_statue_name;
      const firstactpos = act.split("\n");
      const firstact = firstactpos[0];
      const position = firstact.includes(selectedAct);
      if (position === true) {
        this.judgBasedOnAct.push(this.judgements[i]);
      }
    }
    this.CitationCount = this.judgBasedOnAct.length;
  }

  showAllJdg(data) {
    this.singlejudBasedOnAct = data;
    this.isJudgementsVisible = true;
  }

  showSearchedJdg(data) {
    this.isSearchedJudgVisible = true;
    this.isSearchedCitationClicked = true;
    this.singleSearchedJudg = data;
  }

  openLoginfrmHomeSearch() {
    const time = this.authService.getTime().subscribe(data => {
      const time = data.time;
      this.cookieService.set(
        "Homesearchquery",
        this.homeSearchQuery,
        time + 6000000
      ); // cookie will expire after 10 minutes
    });
    const loginDialogRef = this.loginModal.open(LoginComponent, {
      width: "400px",
      height: "400px"
    });
  }

  topicClicked(selectedTopic: string) {
    this.judgBasedOnTopic = [];
    for (let i = 0; i < this.judgements.length; i++) {
      if (this.judgements[i].topics !== undefined) {
        for (let j = 0; j < this.judgements[i].topics.length; j++) {
          if (this.judgements[i].topics[j] === selectedTopic) {
            this.judgBasedOnTopic.push(this.judgements[i]);
          }
        }
      }
    }
  }

  showAllTopicJudg(data) {
    this.isJudgementBasedOnTopic = true;
    this.singlejudBasedOnTopic = data;
  }

  isTaCchecked() {
    this.tacChecked = !this.tacChecked;
  }

  getApp() {
    if (this.tacChecked === false) {
      this.snackBar.open("Kindly check terms and conditions!", "OK", {
        duration: 3000
      });
    } else if (this.tacChecked === true) {
      const dataobj = {};
      dataobj["contact"] = this.contactNo;
      this.homepageService.sendAppLink(dataobj).subscribe(data => {
        if (data.data === "1") {
          this.snackBar.open("Please check your mobile for app link.", "OK");
        }
      });
    }
  }

  showTribs() {
    this.showTrib = !this.showTrib;
  }

  showIcon(index: number) {
    if (5 >= index + 1) {
      return "star";
    } else {
      return "star_border";
    }
  }

  requestDemo() {
    const dataobj = {};
    dataobj["contact"] = this.contactNoDemo;
    // //('mobno', dataobj);

    this.homepageService.requestDemo(dataobj).subscribe(data => {
      // //('demoresponse', data);
      if (data.data === "1") {
        this.snackBar.open(
          "Thank You. We will contact you within two days!",
          "OK"
        );
      } else if (data.data === "0") {
        this.snackBar.open("Please try again later!", "OK");
      }
    });
  }
}

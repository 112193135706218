import { User } from '../../shared/user';
import { AuthActionTypes, All } from '../actions/auth.actions';

export interface State {
    isAuthenticated: boolean;
    user: User | null;
    token: string;
    tokenExpiry: string;
    refreshToken: string;
    refreshTokenExpiry: string;
    errorMessage: string | null;
}

export const initialstate: State = {
    isAuthenticated: false,
    user: null,
    token: null,
    tokenExpiry: null,
    refreshToken: null,
    refreshTokenExpiry: null,
    errorMessage: null
};

export function reducer(state = initialstate, action: All): State {
    switch (action.type) {
        case AuthActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token,
                tokenExpiry: action.payload.tokenExpiry,
                refreshToken: action.payload.refreshToken,
                refreshTokenExpiry: action.payload.refreshTokenExpiry,
                errorMessage: null
            };
        }
        case AuthActionTypes.LOGIN_FAILURE: {
            return {
              ...state,
              errorMessage: 'Incorrect Contact number.'
            };
        }
        case AuthActionTypes.SIGNUP_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                token: action.payload.token,
                tokenExpiry: action.payload.tokenExpiry,
                refreshToken: action.payload.refreshToken,
                refreshTokenExpiry: action.payload.refreshTokenExpiry,
                errorMessage: null
            };
        }
        case AuthActionTypes.SIGNUP_FAILURE: {
            return {
              ...state,
              errorMessage: 'Contactno already in use'
            };
        }
        default: {
            return state;
        }
    }
}

import { Component, OnInit, Input } from "@angular/core";
import {
  RouterModule,
  Routes,
  Router,
  ActivatedRoute,
  NavigationEnd
} from "@angular/router";
import {
  MatDialog,
  MatChip,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSelectChange
} from "@angular/material";

import { AuthService } from "../services/auth.service";
import { BrowseService } from "../services/browse.service";
import { CookieService } from "ngx-cookie-service";

import { Judgement } from "../shared/judgement";
import { User } from "../shared/user";
import { courtNameForLatestJudg } from "../shared/courtnames";

import { AnnotationComponent } from "../annotation/annotation.component";
import { timer } from "rxjs";
import {
  trigger,
  state,
  style,
  animate,
  transition
} from "@angular/animations";

import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-latestjudg",
  templateUrl: "./latestjudg.component.html",
  styleUrls: ["./latestjudg.component.scss"],
  animations: [
    trigger("animateLeft", [
      state(
        "initial",
        style({
          // backgroundColor: 'green',
          width: "0%"
          // height: '50px'
        })
      ),
      state(
        "final",
        style({
          // backgroundColor: 'red',
          width: "80%"
          // paddingRight: '5px'
          // height: '50px'
        })
      ),
      transition("initial=>final", animate("500ms")),
      transition("final=>initial", animate("500ms"))
    ]),
    trigger("animateLeftContainer", [
      state(
        "initial",
        style({
          // backgroundColor: 'green',
          width: "2%"
          // height: '50px'
        })
      ),
      state(
        "final",
        style({
          // backgroundColor: 'red',
          width: "20%"
          // height: '50px'
        })
      ),
      transition("initial=>final", animate("500ms")),
      transition("final=>initial", animate("500ms"))
    ]),
    trigger("animateRight", [
      state(
        "initial",
        style({
          // backgroundColor: 'green',
          width: "90%",
          height: "70px"
        })
      ),
      state(
        "final",
        style({
          // backgroundColor: 'red',
          width: "0px",
          height: "70px"
        })
      ),
      transition("initial=>final", animate("500ms")),
      transition("final=>initial", animate("500ms"))
    ]),
    trigger("animateLeftMobile", [
      state(
        "initial",
        style({
          // backgroundColor: 'green',
          width: "0px",
          height: "50px"
        })
      ),
      state(
        "final",
        style({
          // backgroundColor: 'red',
          width: "80%",
          height: "50px"
        })
      ),
      transition("initial=>final", animate("500ms")),
      transition("final=>initial", animate("500ms"))
    ]),
    trigger("animateLeftTab", [
      state(
        "initial",
        style({
          // backgroundColor: 'green',
          width: "0px",
          height: "50px"
        })
      ),
      state(
        "final",
        style({
          // backgroundColor: 'red',
          width: "80%",
          height: "50px"
        })
      ),
      transition("initial=>final", animate("500ms")),
      transition("final=>initial", animate("500ms"))
    ])
  ]
})
export class LatestjudgComponent implements OnInit {
  user = new User();
  contactno: string;
  latest_spinner = false;
  usertype: string;
  latestJudgments: Judgement[] = [];
  latestJudgCount: number;
  latestPageNo = 1;
  latestTotalPages: number;
  latestpagination = false;

  fontSize = 16;
  zoomInCount = 0;
  zoomOutCount = 0;
  zoomCount = 0;

  isList = true;

  playSpeech = false;
  counterSpeech = 0;

  showFilters = true;
  showCourtFilters = true;

  animateLeftState = "initial";
  animateRightState = "initial";
  sortValue = "desc";
  filterDateFrom: Date = null;
  filterDateTo: Date = null;
  query = "";
  maxDate = new Date(Date.now());
  showDateFilterCancelBtn = false;
  bookmarkSpinner = false;
  isMobile = false;
  isDesktop = false;
  isTablet = false;
  opened = false;
  singleJudg: Judgement;
  isSingleJudg = false;
  showListView = true;

  filterCourtList = courtNameForLatestJudg;
  filterCourtValue: string = null;
  totalResult: number = null;

  filterSearchQuery = "";
  filterSearchQueryValue = "";
  filterQuery = "";
  filterDateQuery = "";
  filterCourtQuery = "";
  filterSearchState = "initial";

  disablePreviousBtn = false;
  disableNextBtn = false;

  constructor(
    private authService: AuthService,
    private browseService: BrowseService,
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    public snackBar: MatSnackBar,
    public annotationModal: MatDialog
  ) {}

  ngOnInit() {
    // this.usertype = localStorage.getItem('usertype');
    this.usertype = this.cookieService.get("usertype");
    // this.contactno = localStorage.getItem('contactno');
    this.contactno = this.cookieService.get("contactno");

    // // console.log('yo1', this.deviceService.isMobile());
    // // console.log('yo2', this.deviceService.isDesktop());
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    if (this.isMobile === true) {
      this.showCourtFilters = false;
    }
    
    this.authService.checkJWTtoken().then(() => {
      timer(2000).subscribe(() => {
        this.authService
          .getUserByContactno(this.contactno)
          .subscribe(userdata => {
            this.user = userdata;
          });
      });
    });
    this.latest_spinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService
          .getLatestJudgements(0, this.usertype, "")
          .subscribe(data => {
            this.totalResult = data.hits.total;
            this.latest_spinner = false;
            this.disablePreviousBtn = true;
            if (data.hits.total > 0) {
              this.latestJudgCount = data.hits.total;
              for (let i = 0; i < data.hits.hits.length; i++) {
                this.latestJudgments.push(data.hits.hits[i]._source);
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;
                  // ('citation', citation);
                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);

              // calculate total pages
              const a: number = this.latestJudgCount / 15;
              if (this.latestJudgCount % 15 === 0) {
                this.latestTotalPages = a;
              } else if (this.latestJudgCount % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.latestTotalPages = +a.toFixed() + 1;
                } else {
                  this.latestTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.latestpagination = true;
              } else {
                this.latestpagination = false;
              }
            }
          });
      });
    });
  }
  /**
   * to insert the PDF url in session storage
   * @param pdfName
   */
  setpdf(pdfName) {
    pdfName = "https://nearlaw.com/PDF/" + pdfName;
    sessionStorage.setItem("pdfUrl", pdfName);
    window.open("https://nearlaw.com/PDF/web/viewer.html", "_blank");
  }
  GetParent(elem) {
    this.counterSpeech = this.counterSpeech + 1;
    this.playSpeech = !this.playSpeech;
    if (this.playSpeech === true) {
      if (this.counterSpeech > 1) {
        speechSynthesis.resume();
      } else {
        speechSynthesis.cancel();
        const parentElem = document.getElementById(elem).parentElement
          .innerText;
        const text = parentElem.substr(65);
        const text1 = text.slice(0, -19);
        text1.replace(/\./g, " ");
        text1.replace(/\:/g, " ");
        const chunk = text1.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,15}\b/g); // spillting at \n and every 15th whitespace
        const ttsArray: SpeechSynthesisUtterance[] = [];
        chunk.forEach(chunkElem => {
          ttsArray.push(new SpeechSynthesisUtterance(chunkElem));
        });
        ttsArray.forEach(ttsElem => {
          ttsElem.lang = "en-IN";
          ttsElem.pitch = 1;
          ttsElem.rate = 0.9;
        });
        ttsArray.forEach(ttsElem => {
          window.speechSynthesis.speak(ttsElem);
        });
      }
    } else if (this.playSpeech === false) {
      speechSynthesis.pause();
    }
  }

  StopSpeech() {
    speechSynthesis.cancel();
    this.counterSpeech = 0;
    this.playSpeech = false;
  }

  ZoomIn(id) {
    this.zoomCount += 1;
    // //('zoomincount', this.zoomCount);
    if (this.zoomCount < 9) {
      this.fontSize += 1.2;
      // //('fontsize', this.fontSize);

      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if (this.zoomCount >= 9) {
      this.zoomCount = 9;
    }
  }

  ZoomOut(id) {
    this.zoomCount -= 1;
    // //('zoomoutcount', this.zoomCount);

    if (this.zoomCount > -4) {
      this.fontSize -= 1.2;
      // //('fontsize', this.fontSize);

      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if (this.zoomCount < -3) {
      this.zoomCount = -3;
    }
  }

  nextLatestPage() {
    this.latestJudgments = [];
    this.latestPageNo += 1;
    // console.log('total Page', this.latestTotalPages);
    if (this.latestPageNo > 1) {
      this.disablePreviousBtn = false;
    }

    if (this.latestPageNo === this.latestTotalPages) {
      this.disableNextBtn = true;
    }

    if (this.latestPageNo > this.latestTotalPages) {
      this.snackBar.open("End of result!", "OK");
    } else {
      this.latest_spinner = true;
      const page_no = (this.latestPageNo - 1) * 15;

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService
            .searchLatestJudgements(
              page_no,
              this.usertype,
              this.sortValue,
              this.query
            )
            .subscribe(data => {
              // //('latest next', data);
              this.latest_spinner = false;

              for (let i = 0; i < data.hits.hits.length; i++) {
                this.latestJudgments.push(data.hits.hits[i]._source);
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;
                  // ('citation', citation);
                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            });
        });
      });
    }
  }

  previousLatestPage() {
    this.latestJudgments = [];
    this.latestPageNo -= 1;
    // console.log('check');

    if (this.latestPageNo === 1) {
      this.disablePreviousBtn = true;
    }

    if (this.latestPageNo < this.latestTotalPages) {
      this.disableNextBtn = false;
    }

    if (this.latestPageNo > 0) {
      // console.log('check');
      this.latest_spinner = true;
      const page_no = (this.latestPageNo - 1) * 15;

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService
            .searchLatestJudgements(
              page_no,
              this.usertype,
              this.sortValue,
              this.query
            )
            .subscribe(data => {
              // //('latest next', data);
              this.latest_spinner = false;

              for (let i = 0; i < data.hits.hits.length; i++) {
                this.latestJudgments.push(data.hits.hits[i]._source);
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;
                  // ('citation', citation);
                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            });
        });
      });
    } else {
      this.snackBar.open("Start of a result!", "OK");
    }
  }

  singlePrint(id: string) {
    // // //('divId', id);
    const printContents = document.getElementById(id).innerHTML;
    // // //(printContents);
    const w = window.open();
    w.document.write("<h1 align=\"center\" style=\"color: blue;\">NearLaw</h1>");
    w.document.write(
      "<div style=\"text-align: justify\">" + printContents + "</div>"
    );
    w.document.write("<style>.hidden-print{display:none!important}</style>");
    w.document.write(
      "<scr" +
        "ipt type=\"text/javascript\">" +
        "window.onload = function() { window.print(); window.close(); };" +
        "</sc" +
        "ript>"
    );

    w.document.close(); // necessary for IE >= 10
    w.focus(); // necessary for IE >= 10
    return true;
  }

  userBoomarks(judgement) {
    const citation = judgement.Citation;
    if (this.user.bookmarks.find(cit => cit === citation) === undefined) {
      // to check if case is already bookarked
      if (this.user.bookmarks[0] === "") {
        this.user.bookmarks.splice(0, 1);
      }
      if (this.user.usertype === "Paid" || this.user.usertype === "MPP") {
        // Paid user can add unlimited bookmarks
        this.user.bookmarks.push(citation);
        const token = this.cookieService.get("JWT");
        const id = this.cookieService.get("ID");
        this.bookmarkSpinner = true;
        this.authService.checkJWTtoken().then(() => {
          timer(3000).subscribe(() => {
            this.authService
              .updateUserWithToken(id, this.user, token)
              .subscribe(userdata => {
                this.user = userdata.result;
                this.bookmarkSpinner = false;

                const x = document.getElementById(citation + " active");
                x.style.display = "block";
                const y = document.getElementById(citation + " inactive");
                y.style.display = "none";

                this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                  duration: 3000
                });
              });
          });
        });
      } else if (
        this.user.usertype === "Trial" ||
        this.user.usertype === "Free"
      ) {
        // Trial and Free users can add only 5 bookmarks
        if (this.user.bookmarks.length < 5) {
          this.user.bookmarks.push(citation);
          const token = this.cookieService.get("JWT");
          const id = this.cookieService.get("ID");

          this.bookmarkSpinner = true;
          this.authService.checkJWTtoken().then(() => {
            timer(3000).subscribe(() => {
              this.authService
                .updateUserWithToken(id, this.user, token)
                .subscribe(userdata => {
                  this.user = userdata.result;
                  this.bookmarkSpinner = false;

                  const x = document.getElementById(citation + " active");
                  x.style.display = "block";
                  const y = document.getElementById(citation + " inactive");
                  y.style.display = "none";
                  this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                    duration: 3000
                  });
                });
            });
          });
        } else {
          const upgradeSnackbar = this.snackBar.open(
            "To save more than 5 bookmarks, please Upgrade to premium plan!",
            "Upgrade",
            {
              duration: 3000
            }
          );
          upgradeSnackbar.onAction().subscribe(() => {
            this.router.navigate(["/paymentgateway"]);
          });
        }
      }
    } else {
      this.user.bookmarks.splice(this.user.bookmarks.indexOf(citation), 1);
      const token = this.cookieService.get("JWT");
      const id = this.cookieService.get("ID");
      this.bookmarkSpinner = true;
      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.authService
            .updateUserWithToken(id, this.user, token)
            .subscribe(userdata => {
              this.user = userdata.result;
              this.bookmarkSpinner = false;
              const x = document.getElementById(citation + " active");
              x.style.display = "none";
              const y = document.getElementById(citation + " inactive");
              y.style.display = "block";
              this.snackBar.open("Successfully removed from Bookmarks!", "OK", {
                duration: 3000
              });
            });
        });
      });
    }
  }

  openAnnotationModal() {
    const loginDialogRef = this.annotationModal.open(AnnotationComponent, {
      width: "400px",
      height: "400px"
    });
  }

  userAnnotations(judgement) {
    const citation = judgement.Citation;

    if (
      this.user.annotations.find(
        annotation => annotation.citation === citation
      ) === undefined ||
      this.user.annotations.length === 0
    ) {
      // localStorage.setItem('cit', citation);
      this.cookieService.set("cit", citation);
      this.openAnnotationModal();
    } else {
      this.user.annotations.forEach(annotation => {
        if (annotation.citation === citation) {
          const cit = annotation.citation;
          const body = annotation.body;
          // localStorage.setItem('cit', cit);
          this.cookieService.set("cit", cit);
          // localStorage.setItem('body', body);
          this.cookieService.set("body", body);
          this.openAnnotationModal();
        }
      });
    }
  }

  openFilters() {
    if (this.showFilters === false) {
      timer(40).subscribe(() => {
        this.showFilters = true;
      });
      // this.animateLeftState = 'final';
    } else if (this.showFilters === true) {
      timer(450).subscribe(() => {
        this.showFilters = false;
      });
      // this.animateLeftState = 'initial';
    }

    this.animateLeftState =
      this.animateLeftState === "initial" ? "final" : "initial";
  }

  getJudgOnSort() {
    this.sortValue = this.sortValue === "desc" ? "asc" : "desc";
    if (this.sortValue === "desc") {
      this.latest_spinner = true;
      this.latestJudgments = [];

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService
            .searchLatestJudgements(
              0,
              this.usertype,
              this.sortValue,
              this.query
            )
            .subscribe(resp => {
              this.latest_spinner = false;

              this.latestPageNo = 1;
              this.totalResult = resp.hits.total.value;
              if (resp.hits.total.value > 0) {
                for (let i = 0; i < resp.hits.hits.length; i++) {
                  this.latestJudgments.push(resp.hits.hits[i]._source);
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.latestJudgments.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.latestJudgments.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;
                    // ('citation', citation);
                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);

                if (this.showListView === false) {
                  this.singleJudg = this.latestJudgments[0];
                  this.isSingleJudg = true;
                }
              }
            });
        });
      });
    } else if (this.sortValue === "asc") {
      this.latest_spinner = true;
      this.latestJudgments = [];
      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService
            .searchLatestJudgements(
              0,
              this.usertype,
              this.sortValue,
              this.query
            )
            .subscribe(resp => {
              this.latest_spinner = false;
              this.latestPageNo = 1;

              this.totalResult = resp.hits.total.value;
              if (resp.hits.total.value > 0) {
                for (let i = 0; i < resp.hits.hits.length; i++) {
                  this.latestJudgments.push(resp.hits.hits[i]._source);
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.latestJudgments.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.latestJudgments.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;
                    // ('citation', citation);
                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);

                if (this.showListView === false) {
                  this.singleJudg = this.latestJudgments[0];
                  this.isSingleJudg = true;
                }
              }
            });
        });
      });
    }
  }

  // date filter
  searchLatestJudg() {
    const from = this.filterDateFrom
      .toISOString()
      .replace("T", " ")
      .slice(0, -5);
    const to = this.filterDateTo
      .toISOString()
      .replace("T", " ")
      .slice(0, -5);

    this.filterDateQuery = "doj_sort:[\"" + from + "\"+TO+\"" + to + "\"] AND ";
    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);
    this.latest_spinner = true;
    this.latestJudgments = [];
    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.browseService
          .searchLatestJudgements(0, this.usertype, this.sortValue, this.query)
          .subscribe(resp => {
            this.latest_spinner = false;
            this.showDateFilterCancelBtn = true;

            this.latestPageNo = 1;
            this.totalResult = resp.hits.total.value;
            if (resp.hits.total.value > 0) {
              for (let i = 0; i < resp.hits.hits.length; i++) {
                this.latestJudgments.push(resp.hits.hits[i]._source);
              }

              this.disablePreviousBtn = true;
              this.disableNextBtn = false;

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;
                  // ('citation', citation);
                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);

              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.latestTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.latestTotalPages = +a.toFixed() + 1;
                } else {
                  this.latestTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.latestpagination = true;
              } else {
                this.latestpagination = false;
              }
            } else {
              this.snackBar.open("No Results found!", "OK", {
                duration: 4000
              });
            }
          });
      });
    });
  }

  defaultSearch() {
    this.filterDateFrom = null;
    this.filterDateTo = null;
    this.latest_spinner = true;
    this.latestJudgments = [];
    // this.query = '';
    this.filterDateQuery = "";
    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);

    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService
          .searchLatestJudgements(0, this.usertype, this.sortValue, this.query)
          .subscribe(resp => {
            this.latest_spinner = false;
            this.showDateFilterCancelBtn = false;

            this.latestPageNo = 1;
            this.totalResult = resp.hits.total.value;
            if (resp.hits.total.value > 0) {
              for (let i = 0; i < resp.hits.hits.length; i++) {
                this.latestJudgments.push(resp.hits.hits[i]._source);
              }

              this.disablePreviousBtn = true;
              this.disableNextBtn = false;

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.latestJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;
                  // ('citation', citation);
                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);

              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.latestTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.latestTotalPages = +a.toFixed() + 1;
                } else {
                  this.latestTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.latestpagination = true;
              } else {
                this.latestpagination = false;
              }
            }
          });
      });
    });
  }

  showJudgementData(data: Judgement) {
    this.singleJudg = data;
    this.isSingleJudg = true;

    // retaining appropriate boomark icon on page load
    setTimeout(() => {
      const citation = this.singleJudg.Citation;
      if (this.user.bookmarks.find(cit => cit === citation) !== undefined) {
        const x = document.getElementById(citation + " active");

        if (x !== null) {
          x.style.display = "block";
        }

        const y = document.getElementById(citation + " inactive");

        if (y !== null) {
          y.style.display = "none";
        }
      } else {
        const x = document.getElementById(citation + " active");

        if (x !== null) {
          x.style.display = "none";
        }

        const y = document.getElementById(citation + " inactive");

        if (y !== null) {
          y.style.display = "block";
        }
      }
      //   });
    }, 100);

    // retaining appropriate annotation icon on page load
    setTimeout(() => {
      //   this.judgements.forEach(data =>{
      //     let i = 0;
      //     i += 1;
      //     //('i', i);
      const citation = this.singleJudg.Citation;
      //
      // // // // console.log('test', this.user.annotations.find((z) => z.citation === citation));
      if (
        this.user.annotations.find(z => z.citation === citation) !== undefined
      ) {
        const x = document.getElementById(citation + " annotation active");

        if (x !== null) {
          x.style.display = "block";
        }

        const y = document.getElementById(citation + " annotation inactive");

        if (y !== null) {
          y.style.display = "none";
        }
      } else {
        const x = document.getElementById(citation + " annotation active");

        if (x !== null) {
          x.style.display = "none";
        }

        const y = document.getElementById(citation + " annotation inactive");

        if (y !== null) {
          y.style.display = "block";
        }
      }
      //   });
    }, 200);
  }

  toggleView() {
    this.showListView = !this.showListView;
    if (this.showListView === false) {
      this.singleJudg = this.latestJudgments[0];
      this.isSingleJudg = true;

      // retaining appropriate boomark icon on view change
      setTimeout(() => {
        this.latestJudgments.forEach(data => {
          const citation = data.Citation;

          if (this.user.bookmarks.find(cit => cit === citation) !== undefined) {
            const x = document.getElementById(citation + " active");

            if (x !== null) {
              x.style.display = "block";
            }

            const y = document.getElementById(citation + " inactive");

            if (y !== null) {
              y.style.display = "none";
            }
          } else {
            const x = document.getElementById(citation + " active");

            if (x !== null) {
              x.style.display = "none";
            }

            const y = document.getElementById(citation + " inactive");

            if (y !== null) {
              y.style.display = "block";
            }
          }
        });
      }, 100);

      // retaining appropriate annotation icon on view change
      setTimeout(() => {
        this.latestJudgments.forEach(data => {
          const citation = data.Citation;

          if (
            this.user.annotations.find(z => z.citation === citation) !==
            undefined
          ) {
            const x = document.getElementById(citation + " annotation active");

            if (x !== null) {
              x.style.display = "block";
            }

            const y = document.getElementById(
              citation + " annotation inactive"
            );

            if (y !== null) {
              y.style.display = "none";
            }
          } else {
            const x = document.getElementById(citation + " annotation active");

            if (x !== null) {
              x.style.display = "none";
            }

            const y = document.getElementById(
              citation + " annotation inactive"
            );

            if (y !== null) {
              y.style.display = "block";
            }
          }
        });
      }, 300);
    } else if (this.showListView === true) {
      // retaining appropriate boomark icon on view change
      setTimeout(() => {
        this.latestJudgments.forEach(data => {
          const citation = data.Citation;

          if (this.user.bookmarks.find(cit => cit === citation) !== undefined) {
            const x = document.getElementById(citation + " active");

            if (x !== null) {
              x.style.display = "block";
            }

            const y = document.getElementById(citation + " inactive");

            if (y !== null) {
              y.style.display = "none";
            }
          } else {
            const x = document.getElementById(citation + " active");

            if (x !== null) {
              x.style.display = "none";
            }

            const y = document.getElementById(citation + " inactive");

            if (y !== null) {
              y.style.display = "block";
            }
          }
        });
      }, 100);

      // retaining appropriate annotation icon on view change
      setTimeout(() => {
        this.latestJudgments.forEach(data => {
          const citation = data.Citation;

          if (
            this.user.annotations.find(z => z.citation === citation) !==
            undefined
          ) {
            const x = document.getElementById(citation + " annotation active");

            if (x !== null) {
              x.style.display = "block";
            }

            const y = document.getElementById(
              citation + " annotation inactive"
            );

            if (y !== null) {
              y.style.display = "none";
            }
          } else {
            const x = document.getElementById(citation + " annotation active");

            if (x !== null) {
              x.style.display = "none";
            }

            const y = document.getElementById(
              citation + " annotation inactive"
            );

            if (y !== null) {
              y.style.display = "block";
            }
          }
        });
      }, 300);
    }
  }

  openCourtFilters() {
    if (this.showCourtFilters === false) {
      timer(40).subscribe(() => {
        this.showCourtFilters = true;
      });
      // this.animateLeftState = 'final';
    } else if (this.showCourtFilters === true) {
      timer(450).subscribe(() => {
        this.showCourtFilters = false;
      });
      // this.animateLeftState = 'initial';
    }

    this.animateRightState =
      this.animateRightState === "initial" ? "final" : "initial";
  }

  onFilterCourtChange(event: MatSelectChange) {
    const court = "";
    const param = "";

    this.latestJudgments = [];
    if (event.value !== "All") {
      this.filterCourtQuery = "tribunal:\"" + event.value + "\" AND ";
    } else {
      this.filterCourtQuery = "";
    }

    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);

    this.latest_spinner = true;
    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService
          .searchLatestJudgements(0, this.usertype, this.sortValue, this.query)
          .subscribe(data => {
            // this.query = this.query.slice(0, -(param.length));
            this.latestPageNo = 1;

            this.totalResult = data.hits.total.value;
            this.latest_spinner = false;
            if (data.hits.total.value > 0) {
              for (let i = 0; i < data.hits.hits.length; i++) {
                this.latestJudgments.push(data.hits.hits[i]._source);
              }

              this.disablePreviousBtn = true;
              this.disableNextBtn = false;

              this.singleJudg = data.hits.hits[0]._source;
              this.isSingleJudg = true;

              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.latestTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.latestTotalPages = +a.toFixed() + 1;
                } else {
                  this.latestTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.latestpagination = true;
              } else {
                this.latestpagination = false;
              }
            } else {
              this.totalResult = data.hits.total.value;
              this.snackBar.open("No results found!", "OK", {
                duration: 4000
              });
            }
          });
      });
    });
  }

  filterSearch() {
    // this.filterSearchState = this.filterSearchState === 'initial' ? 'final' : 'initial';

    if (this.filterSearchQueryValue.trim().indexOf(" ") !== -1) {
      this.filterSearchQuery =
        this.filterSearchQueryValue.trim().replace(/\s+/g, " AND ") + " AND ";
    } else {
      this.filterSearchQuery = this.filterSearchQueryValue.trim() + " AND ";
    }
    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);

    this.latestJudgments = [];
    this.latest_spinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.browseService
          .searchLatestJudgements(0, this.usertype, this.sortValue, this.query)
          .subscribe(data => {
            this.latestPageNo = 1;
            this.latest_spinner = false;

            this.totalResult = data.hits.total.value;
            if (data.hits.total.value > 0) {
              for (let i = 0; i < data.hits.hits.length; i++) {
                this.latestJudgments.push(data.hits.hits[i]._source);
              }

              this.disablePreviousBtn = true;
              this.disableNextBtn = false;

              this.singleJudg = data.hits.hits[0]._source;
              this.isSingleJudg = true;

              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.latestTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.latestTotalPages = +a.toFixed() + 1;
                } else {
                  this.latestTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.latestpagination = true;
              } else {
                this.latestpagination = false;
              }
            } else {
              this.snackBar.open("No results found!", "OK", {
                duration: 4000
              });
            }
          });
      });
    });
  }

  cancelSearchFilter() {
    this.filterSearchQuery = "";
    this.filterSearchQueryValue = "";
    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);
    this.latestJudgments = [];
    this.latest_spinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.browseService
          .searchLatestJudgements(0, this.usertype, this.sortValue, this.query)
          .subscribe(data => {
            this.latestPageNo = 1;
            this.latest_spinner = false;

            this.totalResult = data.hits.total.value;
            if (data.hits.total.value > 0) {
              for (let i = 0; i < data.hits.hits.length; i++) {
                this.latestJudgments.push(data.hits.hits[i]._source);
              }

              this.disablePreviousBtn = true;
              this.disableNextBtn = false;

              this.singleJudg = data.hits.hits[0]._source;
              this.isSingleJudg = true;

              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.latestTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.latestTotalPages = +a.toFixed() + 1;
                } else {
                  this.latestTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.latestpagination = true;
              } else {
                this.latestpagination = false;
              }
            } else {
              this.snackBar.open("No results found!", "OK", {
                duration: 4000
              });
            }
          });
      });
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';

import { User } from '../shared/user';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';

import { LoginComponent } from '../login/login.component';
import { baseURL } from '../shared/baseurl';

import { Store } from '@ngrx/store';
import { AppState } from '../store/app.states';
import { SignUp } from '../store/actions/auth.actions';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  isLawyer: boolean = false;
  user = new User();
  practiceArea: string = '';
  state: string = '';
  tacChecked: boolean = false;
  contactno: string;
  otp: string;
  username: string;
  bciregno: string;
  practicearea: string;
  sTate: string;
  showOTPfield = false;

  httpSecure = false;

  mobileNoControl = new FormControl('', [
    Validators.required,
    // Validators.pattern('[0-9]*'),
    Validators.min(1000000000),
    Validators.max(9999999999)
  ]);
  otpControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9]*'),
    Validators.minLength(4)
  ]);
  nameControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5)
  ]);
  bciControl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[0-9a-zA-Z/]+$')
  ]);
  practiceAreaControl = new FormControl('', [
    Validators.required
  ]);
  stateControl = new FormControl('', [
    Validators.required
  ]);
  districtControl = new FormControl('', [
    Validators.required
  ]);

  matcher = new MyErrorStateMatcher();

  practiceAreaList = [
    // "Accident Claims","Arbitration","Armed Forces Tribunal","Banking / Finance",
    // "Cheque Bounce","Child Custody","Civil","Civil / Criminal","Consumer Court","Constitution of India","Co-operative Societies",
    // "Corporate","Criminal","Customs and Excise","Cyber Crime","Debt Recovery","Deeds, Documents and Conveyance",
    // "Divorce","Environmental Law","Entertaintment Law","Family","Immigration","Insurance","International Law",
    // "Labour and Service","Land Acquisition","Landlord and Tenant","Legal Process Outsourcing (LPO)",
    // "Medical Negligence","Muslim Law","Negotiation","Property","Right to Information",
    // "Scheduled Caste/ Scheduled Tribe","Sports Law","Startup","Supreme Court","Taxation (GST, Customs, etc.)",
    // "Trademark Copyright Patent IP","Wills Trust"
    'Accident Claims',
    'Administrative Law',
    'Arbitration & Mediation',
    'Armed Forces',
    'Banking & Finance',
    'Company Law',
    'Civil & Criminal',
    'Competition/ Monopoly',
    'Contracts',
    'Constitution of India',
    'Criminal',
    'Deeds & Conveyance',
    'Environmental Law',
    'Family',
    'Goods & Services',
    'Immigration',
    'Information Technology',
    'Insurance',
    'Intellectual Property',
    'International',
    'Juvenile',
    'Labour & Service',
    'Law of Medicine',
    'Law of Torts',
    'Legal Process Outsourcing',
    'Maritime',
    'Media & Entertainment',
    'Other Civil',
    'Property & Real Estate',
    'Public Interest Litigation',
    'Sports',
    'Taxation',
    'Wills & Trusts',
    'Women & Children'
  ];

  stateList = [
    "Maharashtra", "Andaman and Nicobar Islands", "Andhra Pradesh", "Arunachal Pradesh", "Assam",
    "Bihar","Chandigarh","Chhattisgarh","Dadra and Nagar Haveli","Daman and Diu","Delhi","Goa","Gujarat",
    "Haryana","Himachal Pradesh","Jammu and Kashmir","Jharkhand","Karnataka","Kerala","Lakshadweep",
    "Madhya Pradesh","Manipur","Meghalaya","Mizoram","Nagaland","Odisha","Puducherry","Punjab","Rajasthan",
    "Sikkim","Tamil Nadu","Telangana","Tripura","Uttar Pradesh","Uttarakhand","West Bengal"
  ];

  // maharashtraDistrictList = [
  //   'Ahmednagar', 'Akola', 'Amravati',
  //   'Aurangabad', 'Beed', 'Bhandara',
  //   'Buldhana', 'Chandrapur', 'Dhule',
  //   'Gadchiroli', 'Gondia', 'Hingoli',
  //   'Jalgaon', 'Jalna', 'Kolhapur',
  //   'Latur', 'Mumbai City', 'Mumbai Suburban',
  //   'Nagpur', 'Nanded',
  //   'Nandurbar', 'Nashik',
  //   'Osmanabad', 'Palghar', 'Parbhani',
  //   'Pune', 'Raigad', 'Ratnagiri',
  //   'Sangli', 'Satara', 'Sindhudurg',
  //   'Solapur', 'Thane', 'Wardha', 'Washim', 
  //   'Yavatmal'
  // ];
  constructor(
    public signupModal: MatDialogRef<SignupComponent>,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    public loginModal: MatDialog,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
  }

  closeSignupModal() {
    this.signupModal.close();
  }

  isLawyerClicked() {
    this.isLawyer = !this.isLawyer;
    // console.log('islawyer', this.isLawyer);
  }

  tacClicked() {
    this.tacChecked = ! this.tacChecked;
    // console.log('tacchecked', this.tacChecked);
  }

  onSignUp() {
    if ( this.tacChecked === false ) {
      this.snackBar.open('Kindly check terms and conditions!', 'Ok', {
        duration: 3000
      });
    } else if ( this.tacChecked === true ) {
      this.user.contactno = this.contactno;
      if ( this.bciregno !== undefined )
        this.user.bcienrollmentno = this.bciregno;
      this.user.username = this.username;
      this.user.otp = this.otp;
      if ( this.practicearea !== undefined )
        this.user.practicearea = this.practicearea;
      // if ( this.state !== undefined )
      this.user.state = this.state;
      var currentUnixdate = Date.now();
      var currentdate = new Date(currentUnixdate);
      var day = currentdate.getDate();
      // console.log('date', day);
      var expiryday = day + 7;
      var expirydate = currentdate.setDate(expiryday);
      // console.log('expirydate', expirydate);
      this.user.trialexpirydate = new Date(expirydate);
      // console.log('user', this.user);
      if ( this.isLawyer === true ) {
        this.user.useridentity = 'Lawyer';
      } else if ( this.isLawyer === false ) {
        this.user.useridentity = 'Consumer';
      }
      console.log(this.user);
      this.store.dispatch(new SignUp(this.user));
      this.signupModal.close();
      // this.authService.signup(this.user).subscribe(data => {
      //   // console.log('signupdata', data);
      //   // console.log('user', data.user);
      //   if ( data.success === true ) {
      //     // localStorage.setItem('username', data.user.username);
      //     this.cookieService.set('username', data.user.username, 30);
      //     // localStorage.setItem('contactno', data.user.contactno);
      //     this.cookieService.set('contactno', data.user.contactno, 30);
      //   //   localStorage.setItem('username', data.user.username);
      //   // localStorage.setItem('contactno', data.user.contactno);
      //     // localStorage.setItem('JWT', data.token);
      //     this.cookieService.set('JWT', data.token, 30);
      //     // localStorage.setItem('refreshToken', data.refreshToken);
      //     this.cookieService.set('refreshToken', data.refreshToken, 30);
      //   // localStorage.setItem('expToken', data.expiryToken);
      //     this.cookieService.set('expToken', data.expiryToken, 30);
      //     // localStorage.setItem('ID', data.user._id);
      //     this.cookieService.set('ID', data.user._id, 30);
      //     // localStorage.setItem('usertype', data.user.usertype);
      //     this.cookieService.set('usertype', data.user.usertype, 30);
      //     this.signupModal.close();
      //     this.router.navigate(['/browse']).then(() => {
      //       this.snackBar.open('Welcome to nearlaw ' + data.user.username + '!', 'OK', {
      //         duration: 3000
      //       });
      //     });
      //   } else if ( data.success === false && data.msg === 'error') {
      //     this.snackBar.open('There as an error, kindly signup again!', 'OK');
      //   } else if ( data.success === false && data.status === 'Wrong otp') {
      //     this.snackBar.open('OTP was incorrect, kindly enter correct OTP!', 'OK');
      //   } else if ( data.success === false && data.status === 'user exists') {
      //     // this.signupModal.close();
      //     // this.loginModal.open(LoginComponent, {
      //     //   width: '400px',
      //     //   height: '400px'
      //     // });
      //     const loginSnackBar = this.snackBar.open('This Mobile Number is already registered with us, kindly do login!', 'OK');
      //     loginSnackBar.onAction().subscribe(() => {
      //       this.signupModal.close();
      //       this.loginModal.open(LoginComponent, {
      //         width: '400px',
      //         height: '400px'
      //       });
      //     });
      //   }
      // });
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  requestOtp() {
    if ( typeof this.contactno !== 'undefined' ) {
      if ( this.contactno.length === 10 ) {
        this.user.contactno = this.contactno;
        //console.log('user', this.user);
        this.authService.checkUser(this.user).subscribe(user => {
          let resp: any = {};
          resp = user;
          console.log('user', resp.data);
          if ( resp.data === 0 ) {
            const loginSnackBar = this.snackBar.open('This number is already registered with us, kindly do login!', 'Login', {
              duration: 10000
            });
            loginSnackBar.onAction().subscribe(() => {
              this.signupModal.close();
              this.loginModal.open(LoginComponent, {
                  width: '400px',
                  height: '400px'
              });
            });
          } else if ( resp.data === 1 ) {
            this.authService.getOtp(this.user).subscribe(data => {
              // console.log('data', data);
              this.showOTPfield = true;

            });
          }
        });


      } else {
        this.snackBar.open('Incorrect contact number', 'OK', {
          duration: 3000
        });
      }
    } else if ( typeof this.contactno === 'undefined' ) {
      this.snackBar.open('Kindly enter contact number', 'OK', {
        duration: 4000
      });
    }

  }
}

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroupDirective, NgForm, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ErrorStateMatcher } from "@angular/material/core";
import { RouterModule, Routes, Router } from "@angular/router";

import { User } from "../shared/user";
import { Login } from "../shared/login";
import { baseDomain } from "../shared/baseurl";
import { SignupComponent } from "../signup/signup.component";

import { Store } from "@ngrx/store";
import { AppState } from "../store/app.states";
import { LogIn } from "../store/actions/auth.actions";

import { AuthService } from "../services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { baseURL } from "../shared/baseurl";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

  users: User[];
  user = new User();
  Login = new Login();
  mobileNo: string;
  otp: string;

  httpSecure = false;

  mobileNoControl = new FormControl("", [
    Validators.required,
    Validators.pattern("[0-9]*"),
    Validators.minLength(10)
  ]);
  otpControl = new FormControl("", [
    Validators.required,
    Validators.pattern("[0-9]*"),
    Validators.minLength(4)
  ]);
  matcher = new MyErrorStateMatcher();

  showOtpField = false;

  constructor(
    private store: Store<AppState>,
    public loginModal: MatDialogRef<LoginComponent>,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    public snackBar: MatSnackBar,
    public signupModal: MatDialog
  ) { }

  ngOnInit() {
  }

  closeLoginModal() {
    this.loginModal.close();
  }

  login() {
    let expirydate;
    this.user.contactno = this.mobileNo;
    this.user.otp = this.otp;
    this.authService.login(this.user).subscribe(data => {
      if (data.success === true) {
        this.user = data.user;
        
        this.cookieService.set("JWT", data.token, 30, "/", baseDomain, false, "Lax");
        this.cookieService.set("username", data.user.username, 30, "/", baseDomain, false, "Lax");
        this.cookieService.set("contactno", data.user.contactno, 30, "/", baseDomain, false, "Lax");
        this.cookieService.set("refreshToken", data.refreshToken, 30, "/", baseDomain, false, "Lax");
        this.cookieService.set("expToken", data.expiryToken, 30, "/", baseDomain, false, "Lax");
        this.cookieService.set("expiryRefreshToken", data.expiryRefreshToken, 30, "/", baseDomain, false, "Lax");
        this.cookieService.set("ID", data.user._id, 30, "/", baseDomain, false, "Lax");

        const trialexpirystring = data.user.trialexpirydate;
        const trialexpirydate = new Date(trialexpirystring);
        const currentdateiso = Date.now();
        const currentdate = new Date(currentdateiso);
        if (typeof data.user.expirydate !== null) {
          const expirystring = data.user.expirydate;
          expirydate = new Date(expirystring);
        }
        if ((currentdate > trialexpirydate) && data.user.usertype === "Trial") {
          this.user.usertype = "Free";
          this.authService.updateUserWithToken(data.user._id, this.user, data.token).subscribe(result => {
            this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain, false, "Lax");
            this.loginModal.close();
            this.router.navigate(["/browse"]).then(() => {
              this.snackBar.open("Welcome to nearlaw, " + data.user.username + "!", "OK", {
                duration: 3000
              });
            });
          });
        } else if ((currentdate <= trialexpirydate) && data.user.usertype === "Trial") {
          this.cookieService.set("usertype", data.user.usertype, 30, "/", baseDomain, false, "Lax");
          this.loginModal.close();
          this.router.navigate(["/browse"]).then(() => {
            this.snackBar.open("Welcome to nearlaw, " + data.user.username + "!", "OK", {
              duration: 3000
            });
          });
        } else if (data.user.usertype === "Free") {
          this.cookieService.set("usertype", data.user.usertype, 30, "/", baseDomain, false, "Lax");
          this.loginModal.close();
          this.router.navigate(["/browse"]).then(() => {
            this.snackBar.open("Welcome to nearlaw, " + data.user.username + "!", "OK", {
              duration: 3000
            });
          });
        } else if (typeof data.user.expirydate !== null && (expirydate <= currentdate) && (data.user.usertype === "Paid" || data.user.usertype === "MPP")) {
          this.user.usertype = "Free";
          this.user.paidflag = false;
          this.user.amount = "";
          this.authService.updateUserWithToken(data.user._id, this.user, data.token).subscribe(result => {
            // localStorage.setItem('usertype', result.usertype);
            this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain, false, "Lax");
            this.loginModal.close();
            this.router.navigate(["/browse"]).then(() => {
              this.snackBar.open("Welcome to nearlaw, " + data.user.username + "!", "OK", {
                duration: 3000
              });
            });
          });
        } else if (typeof data.user.expirydate !== null && (expirydate > currentdate) && (data.user.usertype === "Paid" || data.user.usertype === "MPP")) {
          this.cookieService.set("usertype", data.user.usertype, 30, "/", baseDomain, false, "Lax");
          this.loginModal.close();
          this.router.navigate(["/browse"]).then(() => {
            this.snackBar.open("Welcome to nearlaw, " + data.user.username + "!", "OK", {
              duration: 3000
            });
          });
        }
      } else if (data.success === false && data.msg === "error") {
        this.snackBar.open("There as an error, kindly login again!", "OK");
      } else if (data.success === false && data.status === "wrong otp") {
        this.snackBar.open("OTP was incorrect, kindly enter correct OTP!", "OK");
      } else if (data.success === false && data.status === "user not found") {
        this.loginModal.close();
        this.snackBar.open("This Mobile Number is not registered with us, kindly do signup!", "OK");
      }
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  requestOtp() {
    if (typeof this.mobileNo !== "undefined") {
      if (this.mobileNo.length === 10) {
        this.user.contactno = this.mobileNo;
        this.authService.checkUser(this.user).subscribe(user => {
          let resp: any = {};
          resp = user;
          if (resp.data === 0) {
            this.authService.getOtp(this.user).subscribe(data => {
              this.showOtpField = true;
            });
          } else if (resp.data === 1) {
            const loginSnackBar = this.snackBar.open("This number is not registered with us, kindly do Signup!", "Signup", {
              duration: 10000
            });
            loginSnackBar.onAction().subscribe(() => {
              this.loginModal.close();
              this.signupModal.open(SignupComponent, {
                width: "450px",
                height: "450px",
                disableClose: true
              });
            });
          }
        });
      } else {
        this.snackBar.open("Incorrect contact number", "OK", {
          duration: 3000
        });
      }
    } else if (typeof this.mobileNo === "undefined") {
      this.snackBar.open("Kindly enter contact number", "OK", {
        duration: 4000
      });
    }
  }
}

import { Component, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar
} from "@angular/material";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { Router } from "@angular/router";
import { Node } from "../shared/node";
import { TimelineModalComponent } from "../timeline-modal/timeline-modal.component";

import { CookieService } from "ngx-cookie-service";

// import { VisCaseprofileComponent } from '../vis-caseprofile/vis-caseprofile.component';
@Component({
  selector: "app-vis-caseprofile",
  templateUrl: "./vis-caseprofile.component.html",
  styleUrls: ["./vis-caseprofile.component.scss"]
})
export class VisCaseprofileComponent implements OnInit {
  node: Node;

  constructor(
    public visCaseProfileModal: MatDialogRef<VisCaseprofileComponent>,
    public timelineModal: MatDialog,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    const nodedata = this.cookieService.get("viscaseprof");
    this.node = JSON.parse(nodedata);
  }

  closevisCaseProfileModal() {
    this.visCaseProfileModal.close();
  }

  GetParent(elem) {
    speechSynthesis.cancel();
    const parentElem = document.getElementById(elem).parentElement.innerText;
    const text = parentElem.substr(23);
    const text1 = text.slice(0, -24);
    text1.replace(/\./g, " ");
    text1.replace(/\:/g, " ");
    const texttospeak = new SpeechSynthesisUtterance(text1);
    speechSynthesis.speak(texttospeak);
  }

  StopSpeech() {
    speechSynthesis.cancel();
  }

  getTimelineData(citation: string) {
    const timelineDialogRef = this.timelineModal.open(TimelineModalComponent, {
      width: "850px",
      height: "550px"
    });
    this.cookieService.set("timeline", citation);
  }
}

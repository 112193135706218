export const courtnames = [
    // {value: 'All', view: 'All'},
    {value: 'Supreme Court', view: 'Supreme Court'},
    {value: 'MumbaiHC', view: 'Bombay High Court'},
    {value: 'DelhiHC', view: 'Delhi High Court'},
    {value: 'GujaratHC', view: 'Gujarat High Court'},
    {value: 'KeralaHC', view: 'Kerala High Court'},
    {value: 'KarnatakaHC', view: 'Karnataka High Court'},
    {value: 'CalcuttaHC', view: 'Calcutta High Court'},
    {value: 'PunjabHC', view: 'Punjab High Court'},
    {value: 'AllahabadHC', view: 'Allahabad High Court'},
    {value: 'MpHC', view: 'Madhya Pradesh High Court'},
    {value: 'ATFP', view: 'Appellate Tribunal for Forfeited Property (ATFP)'},
    {value: 'BIFR', view: 'Board for Industrial & Financial Reconstruction (BIFR)'},
    {value: 'CAT', view: 'Central Administrative tribunal (CAT)'},
    {value: 'MAT', view: 'Maharashtra Administrative Tribunal (MAT)'},
    {value: 'CESTAT', view: 'Customs Excise And Service Tax Appellate Tribunal'},
    {value: 'DRT', view: 'Debt Recovery Tribunal'},
    {value: 'DRAT', view: 'Debt Recovery Appellate Tribunal'},
    {value:  'ITAT', view: 'Income Tax Appellate Tribunal'},
    {value: 'IPAB', view: 'Intellectual Property Appellate Board'},
    {value: 'NCLAT', view: 'National Company Law Appellate Tribunal'},
    {value: 'NCDRC', view: 'National Consumer Dispute Redressal Commision'},
    {value: 'MCDRC', view: 'Mah. Consumer Dispute Redressal Commision'},
    {value: 'RCT', view: 'Railway claims Tribunal'},
    {value: 'SAT', view: 'Securities Appellate Tribunal'},
    {value: 'MHRERA', view: 'Maharashtra Real Estate Regulatory Authority'},
    {value: 'MSCA', view: 'Mah. Cooperative Societies Appellate Court'},
    {value: 'MACT', view: 'Motor Accident Claims Tribunal, Mumbai'},
    {value: 'MILC', view: 'Maharashtra Industrial/ Labour Courts'},
    {value: 'MST', view: 'Maharashtra School Tribunal'}
];

export const courtnamesForMPP = [
    {value: 'Supreme Court', view:  'Supreme Court'},
    {value: 'MumbaiHC', view: 'Bombay High Court'}
];

export const courtNameForLatestJudg = [
    {value: 'Supreme Court', view: 'Supreme Court'},
    {value: 'MumbaiHC', view: 'Bombay High Court'},
    {value: 'DelhiHC', view: 'Delhi High Court'}
];

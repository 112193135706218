export const Journals = [
    {view: 'ALL SCR (ONLINE)', value: 'ALL SCR (ONLINE)'},
    {view: 'ALL SCR', value: 'ALL SCR'},
    {view: 'ALL SCR (Cri)', value: 'ALL SCR (Cri)'},
    {view: 'ALL MR', value: 'ALL MR'},
    {view: 'ALLMR ONLINE', value: 'ALLMR ONLINE'},
    {view: 'ALL MR (SUPREME COURT)', value: 'ALL MR SC'},
    {view: 'ALL MR (Cri)', value: 'ALL MR (Cri)'},
    {view: 'ALL MR (Cri) (SUPREME COURT)', value: 'ALL MR (Cri) SC'},
    {view: 'ALL MR (JOURNAL)', value: 'ALL MR (JOURNAL)'},
    {view: 'ALL MR (Cri) (JOURNAL)', value: 'ALL MR (Cri) (JOURNAL)'},
    {view: 'NearLaw (Mumbai HC)', value: 'NearLaw (Mumbai HC)'},
    {view: 'NearLaw (Mumbai HC) (Cri)', value: 'NearLaw (Mumbai HC) (Cri)'},
    {view: 'NearLaw (Mumbai HC) (SUPREME COURT)', value: 'NearLaw (Mumbai HC) SC'},
    {view: 'NearLaw (Mumbai HC) (Cri) (SUPREME COURT)', value: 'NearLaw (Mumbai HC) (Cri) SC'},
    {view: 'NearLaw (BombayHC) Online', value: 'NearLaw (BombayHC) Online'},
    {view: 'NearLaw (BombayHC Goa) Online', value: 'NearLaw (BombayHC Goa) Online'},
    {view: 'NearLaw (BombayHC Nagpur) Online', value: 'NearLaw (BombayHC Nagpur) Online'},
    {view: 'NearLaw (BombayHC Aurangabad) Online', value: 'NearLaw (BombayHC Aurangabad) Online'},
    {view: 'NearLaw (DelhiHC)', value: 'NearLaw (DelhiHC)'},
    {view: 'NearLaw (KER) Online', value: 'NearLaw (KER) Online'},
    {view: 'NearLaw (KAR) Online', value: 'NearLaw (KAR) Online'},
    {view: 'NearLaw (GUJ) Online', value: 'NearLaw (GUJ) Online'},
    {view: 'NearLaw (CAL) Online', value: 'NearLaw (CAL) Online'},
    {view: 'NearLaw (PUN) Online', value: 'NearLaw (PUN) Online'},
    {view: 'NearLaw (ALH) Online', value: 'NearLaw (ALH) Online'},
];

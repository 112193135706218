import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, NavigationExtras } from '@angular/router';
import {MatDialog, MatChip, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';

import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { baseDomain } from '../shared/baseurl';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  username: string;
  contactno: string;
  // token: string;
  usertype: string;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    // let username = localStorage.getItem('username');
    let username = this.cookieService.get('username');
    if ( username.length > 6 ) {
      // console.log('username', username.substr(0, 6));
      username = username.substr(0, 6);
      this.username = username + '...';
    } else if ( username.length <= 6 ) {
      this.username = username;
    }

    // this.contactno = localStorage.getItem('contactno');
    this.contactno = this.cookieService.get('contactno');
    // document.getElementById('browselink').classList.add('text-floral-white');
    // document.getElementById('searchlink').classList.add('text-floral-white');
    // document.getElementById('latestlink').classList.add('text-floral-white');
    // document.getElementById('landmarklink').classList.add('text-floral-white');
    // document.getElementById('importantlink').classList.add('text-floral-white');
    // document.getElementById('oftenlink').classList.add('text-floral-white');
  }

  onLogout() {
    this.authService.destroyUserCredentials();
    // localStorage.removeItem('username');
    localStorage.clear();
    this.cookieService.delete('ID', '/', baseDomain);
    this.cookieService.delete('JWT', '/', baseDomain);
    this.cookieService.delete('contactno', '/', baseDomain);
    this.cookieService.delete('expToken', '/', baseDomain);
    this.cookieService.delete('refreshToken', '/', baseDomain);
    this.cookieService.delete('username', '/', baseDomain);
    this.cookieService.delete('usertype', '/', baseDomain);
    this.router.navigate(['/homepage']).then(() => {
      this.snackBar.open('Thank You for using NearLaw, ' + this.username + '!', 'OK', {
        duration: 3000
      });
    });
  }

  // goToBookmarks() {
  //   let navigationExtras: NavigationExtras = {
  //     fragment: 'bookmarks'
  //   };

  //   this.router.navigate(['/profile'], navigationExtras);
  // }

  // onBrowse() {
    
  //   // event.target.classList.remove('text-floral-white');
  //   document.getElementById('browselink').classList.remove('text-floral-white');
  //   document.getElementById('searchlink').classList.add('text-floral-white');
  //   document.getElementById('latestlink').classList.add('text-floral-white');
  //   document.getElementById('landmarklink').classList.add('text-floral-white');
  //   document.getElementById('importantlink').classList.add('text-floral-white');
  //   document.getElementById('oftenlink').classList.add('text-floral-white');

  //   document.getElementById('browselink').classList.add('active-link');
  //   document.getElementById('searchlink').classList.remove('active-link');
  //   document.getElementById('latestlink').classList.remove('active-link');
  //   document.getElementById('landmarklink').classList.remove('active-link');
  //   document.getElementById('importantlink').classList.remove('active-link');
  //   document.getElementById('oftenlink').classList.remove('active-link');

  //   this.router.navigate(['/browse']);
  // }

  // onSearch() {

  //   document.getElementById('browselink').classList.add('text-floral-white');
  //   document.getElementById('searchlink').classList.remove('text-floral-white');
  //   document.getElementById('latestlink').classList.add('text-floral-white');
  //   document.getElementById('landmarklink').classList.add('text-floral-white');
  //   document.getElementById('importantlink').classList.add('text-floral-white');
  //   document.getElementById('oftenlink').classList.add('text-floral-white');

  //   document.getElementById('browselink').classList.remove('active-link');
  //   document.getElementById('searchlink').classList.add('active-link');
  //   document.getElementById('latestlink').classList.remove('active-link');
  //   document.getElementById('landmarklink').classList.remove('active-link');
  //   document.getElementById('importantlink').classList.remove('active-link');
  //   document.getElementById('oftenlink').classList.remove('active-link');

  //   this.router.navigate(['/search']);
  // }

  // onLatest() {
    
  //   document.getElementById('browselink').classList.add('text-floral-white');
  //   document.getElementById('searchlink').classList.add('text-floral-white');
  //   document.getElementById('latestlink').classList.remove('text-floral-white');
  //   document.getElementById('landmarklink').classList.add('text-floral-white');
  //   document.getElementById('importantlink').classList.add('text-floral-white');
  //   document.getElementById('oftenlink').classList.add('text-floral-white');

  //   document.getElementById('browselink').classList.remove('active-link');
  //   document.getElementById('searchlink').classList.remove('active-link');
  //   document.getElementById('latestlink').classList.add('active-link');
  //   document.getElementById('landmarklink').classList.remove('active-link');
  //   document.getElementById('importantlink').classList.remove('active-link');
  //   document.getElementById('oftenlink').classList.remove('active-link');

  //   this.router.navigate(['/latestjudgments']);
  // }

  // onLandmark() {
  //   document.getElementById('browselink').classList.add('text-floral-white');
  //   document.getElementById('searchlink').classList.add('text-floral-white');
  //   document.getElementById('latestlink').classList.add('text-floral-white');
  //   document.getElementById('landmarklink').classList.remove('text-floral-white');
  //   document.getElementById('importantlink').classList.add('text-floral-white');
  //   document.getElementById('oftenlink').classList.add('text-floral-white');

  //   document.getElementById('browselink').classList.remove('active-link');
  //   document.getElementById('searchlink').classList.remove('active-link');
  //   document.getElementById('latestlink').classList.remove('active-link');
  //   document.getElementById('landmarklink').classList.add('active-link');
  //   document.getElementById('importantlink').classList.remove('active-link');
  //   document.getElementById('oftenlink').classList.remove('active-link');

  //   this.router.navigate(['/landmarkjudgments']);
  // }

  // onImportant() {
  //   document.getElementById('browselink').classList.add('text-floral-white');
  //   document.getElementById('searchlink').classList.add('text-floral-white');
  //   document.getElementById('latestlink').classList.add('text-floral-white');
  //   document.getElementById('landmarklink').classList.add('text-floral-white');
  //   document.getElementById('importantlink').classList.remove('text-floral-white');
  //   document.getElementById('oftenlink').classList.add('text-floral-white');

  //   document.getElementById('browselink').classList.remove('active-link');
  //   document.getElementById('searchlink').classList.remove('active-link');
  //   document.getElementById('latestlink').classList.remove('active-link');
  //   document.getElementById('landmarklink').classList.remove('active-link');
  //   document.getElementById('importantlink').classList.add('active-link');
  //   document.getElementById('oftenlink').classList.remove('active-link');

  //   this.router.navigate(['/importantjudgments']);
  // }

  // onOftenCited() {
  //   document.getElementById('browselink').classList.add('text-floral-white');
  //   document.getElementById('searchlink').classList.add('text-floral-white');
  //   document.getElementById('latestlink').classList.add('text-floral-white');
  //   document.getElementById('landmarklink').classList.add('text-floral-white');
  //   document.getElementById('importantlink').classList.add('text-floral-white');
  //   document.getElementById('oftenlink').classList.remove('text-floral-white');

  //   document.getElementById('browselink').classList.remove('active-link');
  //   document.getElementById('searchlink').classList.remove('active-link');
  //   document.getElementById('latestlink').classList.remove('active-link');
  //   document.getElementById('landmarklink').classList.remove('active-link');
  //   document.getElementById('importantlink').classList.remove('active-link');
  //   document.getElementById('oftenlink').classList.add('active-link');

  //   this.router.navigate(['/oftencitedjudgments']);
  // }

}

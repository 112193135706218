import { Component, OnInit, ChangeDetectionStrategy, HostListener, ViewChild, ElementRef, ViewEncapsulation } from "@angular/core";
import { Network, Timeline, DataSet } from "vis";
// import * as mark from 'mark.js';
import { RouterModule, Routes, Router } from "@angular/router";
import {MatDialog, MatChip, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatAccordion, MatChipInputEvent,
   MatAutocompleteSelectedEvent, MatAutocomplete, MatExpansionPanel, MatInput, MatChipInput, MatChipList} from "@angular/material";
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import {Observable, empty, timer} from "rxjs";
import {map, startWith} from "rxjs/operators";
// import {} from 'mark.js';
import {COMMA, ENTER} from "@angular/cdk/keycodes";

import { AuthService } from "../services/auth.service";
import { SearchService } from "../services/search.service";
import { CookieService } from "ngx-cookie-service";

import { Judgement } from "../shared/judgement";
import { courtnames, courtnamesForMPP } from "../shared/courtnames";
import { acts } from "../shared/new-acts";
import { actsformated } from "../shared/new-acts_copy";
import { Years } from "../shared/yearList";
import { Journals } from "../shared/Journals";
import { User } from "../shared/user";
import { autoCompletion } from "../shared/autocompletion";

import { VisCaseprofileComponent } from "../vis-caseprofile/vis-caseprofile.component";
import { AnnotationComponent } from "../annotation/annotation.component";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {

  user = new User();
  username: string;
  contactno: string;
  usertype: string;
  searchtext: string;
  isexact = false;
  node: Node;
  totalSearchResult: number;
  totalpages: number;
  currentpage = 1;
  fontSize = 16;
  zoomInCount = 0;
  zoomOutCount = 0;
  zoomCount = 0;
  opened = false;
  closed = false;
  searchParameters = [
    "Full Judgement",
    "Acts",
    "Headnote"
  ];
  searchParamenterText = "Full Judgement";

  myControl = new FormControl();
  acts = acts;
  filteredOptions: Observable<string[]>;
  filtered1Options: Observable<string[]>;
  filtered2Options: Observable<string[]>;
  actnames: string[] = [];  // array for only view of acts

  judgements: Judgement[] = [];
  singleJudg: Judgement;

  searchresultPB = false;
  searchresult = false;  // show search result div
  isSingleJudg = false;
  searchSpinner = false;
  advanceSearchSpinner = false;

  courtNames = courtnames;
  courtNamesForMPP = courtnamesForMPP;
  courtonlyNames: string[] = [];  // array for only view of courtNames
  years = Years;
  journals = Journals;
  filtervalue: any;
  query = "";
  filterquery = "";
  isFilterQuery = false;

  // used in chips
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  keywords: string[] = [];
  headnotes: string[] = [];
  partynames: string[] = [];
  courts: string[] = [];
  filteredCourts: Observable<string[]>;
  courtCtrl = new FormControl();
  actControl = new FormControl();
  act1Control = new FormControl();
  act2Control = new FormControl();
  volumeControl = new FormControl("", [
    Validators.pattern("[0-9]*"),
    Validators.maxLength(1)
  ]);
  pagenoControl = new FormControl("", [
    Validators.pattern("[0-9]*")
  ]);
  matcher = new MyErrorStateMatcher();
  actContent = "";
  actContent1 = "";
  actContent2 = "";
  ActContents = ["Section", "Order", "Article", "Rule"];
  subActContents: string[] = [];
  subActContents1: string[] = [];
  subActContents2: string[] = [];
  act1Visible = false;
  act2Visible = false;
  actCount = 0;

  actname = "";
  act1name = "";
  act2name = "";
  anykeyword = "";
  AdvanceSearchQuery = "";
  citationYear = "";
  citationVolume = "";
  citationJournal = "";
  citationPageNo = "";

  keywordChecked = false;
  keywordExact = false;
  keyword1Exact = false;
  keyword2Exact = false;

  headnoteChecked = false;
  headnoteExact = false;
  headnote1Exact = false;
  headnote2Exact = false;
  headnoteChipCount = 0;

  partynameselect = ["Any", "Petitioner", "Respondent"];
  partynameSelect = "";
  PartyName: "";

  judgename = "";

  couRt = "";

  headNote = "";
  subActcOntent = "";
  subActcOntent1 = "";
  subActcOntent2 = "";

  additiveSearch = [];
  searchList1: any;

  autoCompletionList = autoCompletion;
  actsAutoCompletionList = [];
  normalSearchControl = new FormControl();
  normalSearchFilteredOptions: Observable<string[]>;
  additiveSearchClass = "background-primary-light";
  highlightText = "";

  play = false;               // play/stop button in case profile
  playSpeech = false;
  counterSpeech = 0;

  showViz = false;

  @ViewChild("courtInput") courtInput: ElementRef<HTMLInputElement>;
  @ViewChild("courtAuto") courtAutocomplete: MatAutocomplete;
  @ViewChild("vizExpPanel") vizExpPanel: MatExpansionPanel;
  @ViewChild("searchText") searchText: MatInput;
  @ViewChild("searchText") searchListChipInput: MatChipInputEvent;
  @ViewChild("searchList1") searchListChip: MatChipList;
  @ViewChild("searchChip") searchChip: MatChip;

  constructor(
    private authService: AuthService,
    private searchServce: SearchService,
    public snackBar: MatSnackBar,
    private router: Router,
    private cookieService: CookieService,
    public viscaseprofileModal: MatDialog,
    public annotationModal: MatDialog
    // private visDataSet: DataSet
  ) {
    this.normalSearchFilteredOptions = this.normalSearchControl.valueChanges
      .pipe(
        startWith(""),
        map(value => this._normalSearchfilter(value))
      );
   }

  ngOnInit() {
    this.username = this.cookieService.get("username");
    this.contactno = this.cookieService.get("contactno");
    this.authService.authToken = this.cookieService.get("JWT");
    this.usertype = this.cookieService.get("usertype");
    const newdate = Date.now();
    const dte = new Date(newdate);
    
    // search highlight text
    for ( let i = 1; i < 79; i ++ ) {
      localStorage.removeItem("s" + i);
    }
    // ends here

    this.authService.checkJWTtoken().then(() => {
      timer(2000).subscribe(() => {
        this.authService.getUserByContactno(this.contactno).subscribe(userdata => {
          this.user = userdata;
        });
      });
    });


    this.acts.forEach(data => {
      this.actnames.push(data.view);
    });

    this.courtNames.forEach(data => {
      this.courtonlyNames.push(data.view);
    });

    this.filteredOptions = this.actControl.valueChanges
      .pipe(
        startWith(""),
        map(value => this._filter(value))
      );

    this.filtered1Options = this.act1Control.valueChanges
      .pipe(
        startWith(""),
        map(value => this._filter(value))
      );

    this.filtered2Options = this.act2Control.valueChanges
      .pipe(
        startWith(""),
        map(value => this._filter(value))
      );

    this.filteredCourts = this.courtCtrl.valueChanges.pipe(
        startWith(null),
        map((court: string | null) => court ? this._courtfilter(court) : this.courtonlyNames.slice())
      );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.actnames.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _normalSearchfilter(value: string): string[] {
    if ( typeof value !== "undefined" ) {
      if ( value.length > 2 ) {
        const filterValue = value.toLowerCase();

        return this.autoCompletionList.filter(option => option.toLowerCase().includes(filterValue));
      }
    }

  }
  // @HostListener('window:scroll', ['$event']) 
  //   scrollHandler(event) {
  //     // // //('event', event.target.body.scroll);
  //     console.debug("Scroll Event");
  //     var scrolltop = window.pageYOffset || document.documentElement.scrollTop
  //     var searchbar = document.getElementById('searchDiv');
  //     // //('event', scrolltop);
  //     if (scrolltop < 40) {
  //       document.getElementById('searchDiv').setAttribute('class', 'a');
  //     } 
  //     if ((scrolltop >= 40) && (scrolltop < 360)) {
  //       document.getElementById('searchDiv').setAttribute('class', 'Fixed');
  //     }
  //     if (scrolltop >= 360) {
  //       document.getElementById('searchDiv').setAttribute('class', 'atResult');
  //     }
  //   }
  advanceButton() {
    this.opened = !this.opened;
  }

  // exactCheck() {
  //   this.isexact = !this.isexact;
  //   // // // console.log('exact', this.isexact);
  // }

  onLogout() {
    this.authService.destroyUserCredentials();
    // localStorage.removeItem('username');
    localStorage.clear();
    this.cookieService.deleteAll();
    this.router.navigate(["/homepage"]).then(() => {
      this.snackBar.open("Thank You for using NearLaw, " + this.username + "!", "OK", {
        duration: 3000
      });
    });
  }

  // addAdditiveSearch(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;
  //   // //('length', this.keywords.length);
  //   // if (this.keywords.length < 3) {

  //   //   if ( this.keywordChecked === true ) {
  //   //     if ((value || '').trim()) {
  //   //       this.keywords.push('"' + value.trim() + '"');
  //   //       this.keywordChecked = false;
  //   //     }
  //   //   } else if  ( this.keywordChecked === false ) {
  //   //     if ((value || '').trim()) {
  //   //       this.keywords.push(value.trim());
  //   //     }
  //   //   }

  //   //   // //('keywords', this.keywords);
  //   //   // Reset the input value
  //   //   if (input) {
  //   //     input.value = '';
  //   //   }


  //   // } else if (this.keywords.length >= 3) {
  //   //   this.snackBar.open('You cannot add more than three keywords to search in Full judgment!', 'OK', {
  //   //     duration: 5000
  //   //   });
  //   // }

  //   if ( this.searchParamenterText === 'Headnote' ) {
  //     if ( this.isexact === true ) {
  //       this.searchtext = '"' + value.trim() + '"';
  //     }
  //     this.searchtext = 'HeadNote:' + this.searchtext.trim();
  //     // // // console.log('headnotesearch', this.searchtext);
  //   } else if ( this.searchParamenterText === 'Citation' ) {
  //     if ( this.isexact === true ) {
  //       this.searchtext = '"' + value.trim() + '"';
  //     }
  //     this.searchtext = 'Citation:' + this.searchtext.trim();
  //   } else if ( this.searchParamenterText === 'Full Judgement' ) {
  //     // // // console.log('exact', this.isexact);
  //     if ( this.isexact === true ) {
  //       this.searchtext = '"' + value.trim() + '"';
  //     }
  //     // // // console.log('test', this.searchtext);
  //   } else if ( this.searchParamenterText === 'Acts') {
      
  //       this.searchtext = '"' + value.trim().slice(0, -6) + '"';
      
  //   }
    
  //   if ( this.query.length === 0 ) {
      
  //     this.query = this.searchtext.trim();
     
  //     this.additiveSearch.push(this.query);

  //     if (input) {
  //       input.value = '';
  //     }
  //   } else {
  //     // if ( this.isexact === true ) {
  //     //   this.searchtext = '"' + this.searchtext.trim() + '"';
  //     // }
  //     if ( this.additiveSearch.length < 6 ) {
  //       this.additiveSearch.push(this.searchtext.trim());
  //       this.query = this.query + '%20AND%20' + this.searchtext.trim();
  //     } else {
  //       this.snackBar.open('Search parameter limit exceeded!', 'OK', {
  //         duration: 4000
  //       });
  //     }
  //   }

  //   if ( typeof this.query === 'undefined' || this.query === '' ) {
  //     this.snackBar.open('Kindly search something!', 'OK');
  //   } else {

      
  //     // // // console.log('additive search', this.additiveSearch);
  //     // // // console.log('query', this.query);
  //     this.searchtext = '';

  //     this.searchresultPB = true;
  //     this.showViz = false;
  //     if ( typeof this.vizExpPanel !== 'undefined' ) {
  //       this.vizExpPanel.close();
  //     }
      
  //     // // console.log('showviz', this.showViz);

  //     this.searchServce.search(this.query, this.usertype, 0).subscribe( data => {
  //       // //('data', data);
  //       // this.isexact = false;

  //       this.totalSearchResult = data.hits.total;
  //       if ( this.totalSearchResult !== 0 ) {

  //         for ( let i = 0; i < data.hits.hits.length; i++ ) {
  //           this.judgements.push(data.hits.hits[i]._source);
  //         }
  
  
  
  //       // retaining appropriate boomark icon on page load
  //         setTimeout(() => {
  //         //   this.judgements.forEach(data =>{
  //         //     let i = 0;
  //         //     i += 1;
  //         //     //('i', i);
  //           const citation = data.hits.hits[0]._source.Citation;
  //           // // // console.log('citation', citation);
  //           // // // console.log('test', this.user.bookmarks.find((cit) => cit === citation))
  //           if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
  //             //('acive');
  //             const x = document.getElementById(citation + ' active');
  //             //('x', x);
  //             if ( x !== null ) {
  //               x.style.display = 'block';
  //             }
              
  //             const y = document.getElementById(citation + ' inactive');
  //             //('y', y);
  //             if ( y!== null ) {
  //               y.style.display = 'none';
  //             }
              
  //           } else {
  //             //('inacive');
  //             const x = document.getElementById(citation + ' active');
  //             //('x', x);
  //             if ( x !== null ) {
  //               x.style.display = 'none';
  //             }
              
  //             const y = document.getElementById(citation + ' inactive');
  //             //('y', y);
  //             if ( y !== null ) {
  //               y.style.display = 'block';
  //             } 
  //           }
  //       //   });
  //         }, 100);
  
  //       // retaining appropriate annotation icon on page load
  //         setTimeout(() => {
  //       //   this.judgements.forEach(data =>{
  //       //     let i = 0;
  //       //     i += 1;
  //       //     //('i', i);
  //           const citation = data.hits.hits[0]._source.Citation;
  //           //('citation', citation);
  //           if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
  //             //('acive');
  //             const x = document.getElementById(citation + ' annotation active');
  //             //('x', x);
  //             if ( x !== null ) {
  //               x.style.display = 'block';
  //             }
              
  //             const y = document.getElementById(citation + ' annotation inactive');
  //             //('y', y);
  //             if ( y!== null ) {
  //               y.style.display = 'none';
  //             }
              
  //           } else {
  //             //('inacive');
  //             const x = document.getElementById(citation + ' annotation active');
  //             //('x', x);
  //             if ( x !== null ) {
  //               x.style.display = 'none';
  //             }
              
  //             const y = document.getElementById(citation + ' annotation inactive');
  //             //('y', y);
  //             if ( y !== null ) {
  //               y.style.display = 'block';
  //             }
              
             
  //           }
  //       //   });
  //         }, 200);
  
  //         this.searchresultPB = false;
  //         // //('search result', this.judgements);
  
  //         this.searchresult = true;
  
  //         // load first data
  //         this.isSingleJudg = true;
  //         this.singleJudg = data.hits.hits[0]._source;
  //         //('singlejudg', this.singleJudg);
  
  //         //calculate total pages
  //         const a = this.totalSearchResult / 15;
  //         if ( this.totalSearchResult % 15 === 0 ) {
  //           this.totalpages = a;
  //         } else if ( this.totalSearchResult % 15 > 0 ) {
  //           this.totalpages = a + 1;
  //         }
  
          
  //       } else {
  //         this.searchresultPB = false;
  //         this.snackBar.open('No search results!', 'OK', {
  //           duration: 3000
  //         });
  //       }
        

        
  //     });
      
  //   }
  // }

  headnoteHighlight() {
    // console.log('yo');
    let highlightedContentArray = [];
    const wordArray = [];   // text to be highlighted
    const searchParamArray = [];  // search parameters with multiple words
    let tempArray = [];
    const index = 0;
    const highlightContentArray = [];
    let indicesResult = [];

    const context = document.querySelectorAll(".highlight");
      // console.log('context', context);
    let i = 0;
      context.forEach((item) => {
        
        item.setAttribute("class", "highlightH" + i);
        highlightContentArray.push(item.innerHTML);
        i += 1;
      });
      // console.log('context', context);
      // console.log('highlightContentArray', highlightContentArray);
      
      // // console.log('content', highlightContent);

      // wordArray = highlightContent.split(' ');
      // // // console.log('wordArray', wordArray);

      function applyHighlights(text: string) {
        // // console.log('text type', typeof text);
        // // console.log('text', text);
        if ( typeof text !== "object" ) {
          if ( text !== "" ) {
            return text.replace(text, "<mark>" + text + "</mark>");
          }
        }
      }

      for ( let k = 0; k <= 78; k++) {
        let s = "s" + k;
        s = localStorage.getItem(s);
        // console.log('s', s);

        if ( typeof s !== "object") {
          if ( s[0] !== "\"" && s[s.length - 1] !== "\"" ) {    // if search param is inexact ie. without double quotes
            tempArray = s.split(" ");                         // if multiple words in search param then split into array
            for ( let j = 0; j < tempArray.length; j++ ) {
              searchParamArray.push(tempArray[j]);
            }
          } else if ( s[0] === "\"" && s[s.length - 1] === "\"" ) { // if search param is exact ie. with double quotes
            // // console.log('exact test', s);
            const tempText = s.substr(1).slice(0, -1);          // remove double quotes
            // // console.log('temp text', tempText);
            searchParamArray.push(tempText);
          }
        }
      }
      // console.log('searchParamArray', searchParamArray);
      // let counter = 0;
      highlightedContentArray = [];
      
      searchParamArray.forEach((searchParam: string) => {
        let highlightContentArrayCounter = 0;
        highlightContentArray.forEach((highlightContent: string) => {
          
          // console.log('check1', highlightContent.toLowerCase().includes(searchParam.toLowerCase()));
          if ( highlightContent.toLowerCase().includes(searchParam.toLowerCase()) === true ) {
            // console.log('yo');
            // console.log('searchPAram', searchParam);
            
            indicesResult = this.getIndicesOf(searchParam.toLowerCase(), highlightContent.toLowerCase());
            // console.log('indicesResult', indicesResult);
            let counter = 0; // counter is used because <mark></mark> get added after every iteration
              indicesResult.forEach((indexresult) => {

                const temp1 = highlightContent.substr(indexresult + counter);
                const temp2 = highlightContent.slice(0, -(highlightContent.length - (indexresult + counter)));
                const temp3 = temp2 + "<mark>" + temp1;
                // // console.log('test3', temp3);
                const temp4 = temp3.substr(indexresult + 6 + searchParam.length + counter); // 
                const temp5 = temp3.slice(0 , -((highlightContent.length - indexresult) - searchParam.length - counter));
                // // console.log('test4', temp4);
                // // console.log('test5', temp5);
                const temp6 = temp5 + "</mark>" + temp4;
                // console.log('test6', temp6);
                // highlightedContent = temp6;
                highlightedContentArray.push(temp6);
                highlightContentArray[highlightContentArrayCounter] = temp6;
                // console.log('yoyo', highlightContentArray[highlightContentArrayCounter]);
                // console.log('highlightContentArray', highlightContentArray);
                counter += 13;
              });
            // counter += 1;
            // highlightedContentArray.push(highlightedContent);
            // tempArray.push(counter);
          }
          // counter += 1;
          highlightContentArrayCounter += 1;
        });
        
      });
      // console.log('highlightedContentArray', highlightedContentArray);
      // console.log('highlightContentArray', highlightContentArray);
      
      for ( let j = 0; j < context.length; j++ ) {
        document.getElementsByClassName("highlightH" + j).item(0).innerHTML = highlightContentArray[j];
        document.getElementsByClassName("highlightH" + j).item(0).setAttribute("class", "highlight");
      }
      // context.forEach((item) => {
      //   j += 1;
      //   item.setAttribute('class', 'highlightH' + i);
      //   highlightContentArray.push(item.innerHTML);
      // });

      // // console.log('highlightedContentArray', highlightedContentArray);

      // for (let i = 1; i <= 78; i++) {
      //   let s = 's' + i;
      //   s = localStorage.getItem(s);
      //   // // console.log('s', s);

      //   if ( typeof s !== 'object') {
      //     if ( s[0] !== '"' && s[s.length - 1] !== '"' ) {    // if search param is inexact ie. without double quotes
      //       tempArray = s.split(' ');                         // if multiple words in search param then split into array
      //       for ( let j = 0; j < tempArray.length; j++ ) {
      //         searchParamArray.push(tempArray[j]);
      //       }
            
      //       // // console.log('search param array', searchParamArray);
      //     } else if ( s[0] === '"' && s[s.length - 1] === '"' ) { // if search param is exact ie. with double quotes
      //       // // console.log('exact test', s);
      //       const tempText = s.substr(1).slice(0, -1);          // remove double quotes
      //       // // console.log('temp text', tempText);
      //       searchParamArray.push(tempText);
      //     }
      //   }

        

      //   // for inexact
      //   wordArray.forEach((word: string) => {
      //     // if ( typeof s !== 'object') {
      //     if ( searchParamArray.length > 0 ) {

      //       if ( searchParamArray.length === 1 ) {
      //         const temp: string = searchParamArray[0];
      //         if (temp.indexOf(' ') < 0 ) {       // check for space ie. multiple words
      //           if ( searchParamArray[0].toLowerCase() === word.toLowerCase() ) {
      //             const highlightedText = applyHighlights(word);
      //             // // console.log('highlightedtext' + i, highlightedText);
      //             index = wordArray.indexOf(word);
      //             wordArray.splice(index, 1);
      //             wordArray.splice(index, 0, highlightedText);
      //             // // console.log('index', index);
      //             // // console.log('check', wordArray[index]);
      //           }
      //         }
      //       } else if ( searchParamArray.length > 1 ) {
      //         searchParamArray.forEach((searchParam: string) => {
      //           if ( searchParam.indexOf(' ') < 0 ) {    // check for space ie. multiple words
      //             if ( searchParam.toLowerCase() === word.toLowerCase() ) {
      //               const highlightedText = applyHighlights(word);
      //               // // console.log('highlightedtext' + i, highlightedText);
      //               index = wordArray.indexOf(word);
      //               wordArray.splice(index, 1);
      //               wordArray.splice(index, 0, highlightedText);
      //               // // console.log('index', index);
      //               // // console.log('check', wordArray[index]);
      //             }
      //           }
      //         });
      //       }
            
      //     }
      //     // }
      //   });
      //   // highlightedContent = highlightContent.replace(s, highlightedText);
      //   // // // console.log('highlightedContent' + i, highlightedContent);
      // }

      // for ( let j = 0; j < wordArray.length; j ++ ) {
      //   // // console.log('check', wordArray[j]);
      //   highlightedContent = highlightedContent + wordArray[j] + ' ';
      // }
      // highlightedContent = highlightedContent.trim();
     
      // // // console.log('wordArray after highlight', wordArray);

      // if ( searchParamArray.length > 0 ) {

      //   searchParamArray.forEach((searchParam: string) => {
      //     if ( searchParam.indexOf(' ') > -1 ) {
      //       // // console.log('exact check');
      //       const test = highlightedContent.match(searchParam);
      //       // // console.log('exact check', test);
      //       highlightedContent = highlightedContent.replace(searchParam, '<mark>' + searchParam + '</mark>');
      //       // // console.log('exact check 1', highlightedContent);
      //     }
      //   });

      // }
      // // // console.log('highlightedContent', highlightedContent);
      // document.getElementById('highlightHeadnote').innerHTML = highlightedContent;
  }

  judgeHighlight() {
    let highlightedContent = "";
    let wordArray = [];   // text to be highlighted
    const searchParamArray = [];  // search parameters with multiple words
    let tempArray = [];
    let index = 0;

    const context = document.getElementById("highlightJudge");
      // // console.log('context', context);
      const highlightContent = context.innerHTML;
      // // console.log('content', highlightContent);

      wordArray = highlightContent.split(" ");
      // // console.log('wordArray', wordArray);

      function applyHighlights(text: string) {
        // // console.log('text type', typeof text);
        // // console.log('text', text);
        if ( typeof text !== "object" ) {
          if ( text !== "" ) {
            return text.replace(text, "<mark>" + text + "</mark>");
          }
        }
      }

      for (let i = 1; i <= 78; i++) {
        let s = "s" + i;
        s = localStorage.getItem(s);
        // // console.log('s', s);

        if ( typeof s !== "object") {
          if ( s[0] !== "\"" && s[s.length - 1] !== "\"" ) {    // if search param is inexact ie. without double quotes
            tempArray = s.split(" ");                         // if multiple words in search param then split into array
            for ( let j = 0; j < tempArray.length; j++ ) {
              searchParamArray.push(tempArray[j]);
            }
            
            // // console.log('search param array', searchParamArray);
          } else if ( s[0] === "\"" && s[s.length - 1] === "\"" ) { // if search param is exact ie. with double quotes
            // // console.log('exact test', s);
            const tempText = s.substr(1).slice(0, -1);          // remove double quotes
            // // console.log('temp text', tempText);
            searchParamArray.push(tempText);
          }
        }

        

        // for inexact
        wordArray.forEach((word: string) => {
          // if ( typeof s !== 'object') {
          if ( searchParamArray.length > 0 ) {

            if ( searchParamArray.length === 1 ) {
              const temp: string = searchParamArray[0];
              if (temp.indexOf(" ") < 0 ) {       // check for space ie. multiple words
                if ( searchParamArray[0].toLowerCase() === word.toLowerCase() ) {
                  const highlightedText = applyHighlights(word);
                  // // console.log('highlightedtext' + i, highlightedText);
                  index = wordArray.indexOf(word);
                  wordArray.splice(index, 1);
                  wordArray.splice(index, 0, highlightedText);
                  // // console.log('index', index);
                  // // console.log('check', wordArray[index]);
                }
              }
            } else if ( searchParamArray.length > 1 ) {
              searchParamArray.forEach((searchParam: string) => {
                if ( searchParam.indexOf(" ") < 0 ) {    // check for space ie. multiple words
                  if ( searchParam.toLowerCase() === word.toLowerCase() ) {
                    const highlightedText = applyHighlights(word);
                    // // console.log('highlightedtext' + i, highlightedText);
                    index = wordArray.indexOf(word);
                    wordArray.splice(index, 1);
                    wordArray.splice(index, 0, highlightedText);
                    // // console.log('index', index);
                    // // console.log('check', wordArray[index]);
                  }
                }
              });
            }
            
          }
          // }
        });
        // highlightedContent = highlightContent.replace(s, highlightedText);
        // // // console.log('highlightedContent' + i, highlightedContent);
      }

      for ( let j = 0; j < wordArray.length; j ++ ) {
        // // console.log('check', wordArray[j]);
        highlightedContent = highlightedContent + wordArray[j] + " ";
      }
      highlightedContent = highlightedContent.trim();
     
      // // console.log('wordArray after highlight', wordArray);

      if ( searchParamArray.length > 0 ) {

        searchParamArray.forEach((searchParam: string) => {
          if ( searchParam.indexOf(" ") > -1 ) {
            // // console.log('exact check');
            const test = highlightedContent.match(searchParam);
            // // console.log('exact check', test);
            highlightedContent = highlightedContent.replace(searchParam, "<mark>" + searchParam + "</mark>");
            // // console.log('exact check 1', highlightedContent);
          }
        });

      }
      // // console.log('highlightedContent', highlightedContent);
      document.getElementById("highlightJudge").innerHTML = highlightedContent;
  }

  getIndicesOf(searchStr: string, str: string) { // function to fetch array of indices of searchParams in highlightedContent
    // console.log('str', str);
    // console.log('searchStr', searchStr);
    const searchStrLen = searchStr.length;
    if (searchStrLen === 0) {
        return [];
    }
    let startIndex = 0; let index1 = 0; const indices1 = [];
    // if (!caseSensitive) {
    //     str = str.toLowerCase();
    //     searchStr = searchStr.toLowerCase();
    // }
    while ((index1 = str.indexOf(searchStr, startIndex)) > -1) {
        indices1.push(index1);
        startIndex = index1 + searchStrLen;
    }
    return indices1;
  }

  searchResultHighlight() {
    let highlightedContent = "";
    let wordArray = [];   // text to be highlighted
    const searchParamArray = [];  // search parameters with multiple words
    let tempArray = [];
    let index = 0;

    const context = document.getElementById("searchresult");
      // // console.log('context', context);
      const highlightContent = context.innerHTML;
      // // console.log('content', highlightContent);

      wordArray = highlightContent.split(" ");
      // // console.log('wordArray', wordArray);

      function applyHighlights(text: string) {
        // // console.log('text type', typeof text);
        // // console.log('text', text);
        if ( typeof text !== "object" ) {
          if ( text !== "" ) {
            return text.replace(text, "<mark>" + text + "</mark>");
          }
        }
      }

      for (let i = 1; i <= 78; i++) {
        let s = "s" + i;
        s = localStorage.getItem(s);
        // // // console.log('s', s);

        if ( typeof s !== "object") {
          if ( s[0] !== "\"" && s[s.length - 1] !== "\"" ) {    // if search param is inexact ie. without double quotes
            tempArray = s.split(" ");                         // if multiple words in search param then split into array
            for ( let j = 0; j < tempArray.length; j++ ) {
              searchParamArray.push(tempArray[j]);
            }
            
            // // // console.log('search param array', searchParamArray);
          } else if ( s[0] === "\"" && s[s.length - 1] === "\"" ) { // if search param is exact ie. with double quotes
            // // // console.log('exact test', s);
            const tempText = s.substr(1).slice(0, -1);          // remove double quotes
            // // // console.log('temp text', tempText);
            searchParamArray.push(tempText);
          }
        }

        

        // for inexact
        wordArray.forEach((word: string) => {
          // if ( typeof s !== 'object') {
          if ( searchParamArray.length > 0 ) {

            if ( searchParamArray.length === 1 ) {
              const temp: string = searchParamArray[0];
              if (temp.indexOf(" ") < 0 ) {       // check for space ie. multiple words
                if ( typeof word !== "undefined" ) {
                  if ( searchParamArray[0].toLowerCase() === word.toLowerCase() ) {
                    const highlightedText = applyHighlights(word);
                    // // // console.log('highlightedtext' + i, highlightedText);
                    index = wordArray.indexOf(word);
                    wordArray.splice(index, 1);
                    wordArray.splice(index, 0, highlightedText);
                    // // // console.log('index', index);
                    // // // console.log('check', wordArray[index]);
                  }
                }
              }
            } else if ( searchParamArray.length > 1 ) {
              searchParamArray.forEach((searchParam: string) => {
                if ( searchParam.indexOf(" ") < 0 ) {    // check for space ie. multiple words
                  if ( typeof word !== "undefined" ) {
                    if ( searchParam.toLowerCase() === word.toLowerCase() ) {
                      const highlightedText = applyHighlights(word);
                      // // // console.log('highlightedtext' + i, highlightedText);
                      index = wordArray.indexOf(word);
                      wordArray.splice(index, 1);
                      wordArray.splice(index, 0, highlightedText);
                      // // // console.log('index', index);
                      // // // console.log('check', wordArray[index]);
                    }
                  }
                }
              });
            }
            
          }
          // }
        });
        // highlightedContent = highlightContent.replace(s, highlightedText);
        // // // console.log('highlightedContent' + i, highlightedContent);
      }

      for ( let j = 0; j < wordArray.length; j ++ ) {
        // // // console.log('check', wordArray[j]);
        highlightedContent = highlightedContent + wordArray[j] + " ";
      }
      highlightedContent = highlightedContent.trim();
     
      // // // console.log('wordArray after highlight', wordArray);

      // for exact
      if ( searchParamArray.length > 0 ) {
        let indicesresult = [];
        

        searchParamArray.forEach((searchParam: string) => {
          if ( searchParam.indexOf(" ") > -1 ) {
              if ( highlightedContent.toLowerCase().includes(searchParam.toLowerCase()) === true ) {  // check whether searchParam is present in highlightedContent
              indicesresult = this.getIndicesOf(searchParam.toLowerCase(), highlightedContent.toLowerCase());  // gets array of index positions of searchParams in highlightedContent 
              // iterate over all occurances of searchParam in highlightedContent
              let counter = 0; // counter is used because <mark></mark> get added after every iteration
              indicesresult.forEach((indexresult) => {
                const temp1 = highlightedContent.substr(indexresult + counter);
                const temp2 = highlightedContent.slice(0, -(highlightedContent.length - (indexresult + counter)));
                const temp3 = temp2 + "<mark>" + temp1;
                const temp4 = temp3.substr(indexresult + 6 + searchParam.length + counter); // 
                const temp5 = temp3.slice(0 , -((highlightedContent.length - indexresult) - searchParam.length - counter));
                const temp6 = temp5 + "</mark>" + temp4;
                highlightedContent = temp6;
                counter += 13;
              });
            }
          }
        });
      }
      document.getElementById("searchresult").innerHTML = highlightedContent;
  }

  highlight() {
    const delay = timer(200);
    delay.subscribe(() => {
      this.searchResultHighlight();
    });
  }

  searchParamenterTextChange(event) {
    if ( event === "Headnote" ) {
      this.autoCompletionList = autoCompletion;
    } else if ( event === "Citation" ) {
      this.autoCompletionList = [];
    } else if ( event === "Full Judgement" ) {
      this.autoCompletionList = autoCompletion;
    } else if ( event === "Acts") {
      this.autoCompletionList = actsformated;
      if ( this.isexact === true ) {
        this.searchtext = "\"" + this.searchtext.trim() + "\"";
      }
    }
  }

  getViz() {
    const nodes = new DataSet();
    const edges = new DataSet();
    const idarray = [];

    document.getElementById("visid").style.height = "400px";
    document.getElementById("visid").style.backgroundColor = "aliceblue";
    document.getElementById("visid").style.marginBottom = "20px";

    for ( let y = 0; y < this.judgements.length; y++ ) {
      const citation = this.judgements[y].Citation;
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.searchServce.mainnode(citation).subscribe(mainnodedata => {
            if ( mainnodedata.length > 0 ) {
              for ( let z = 0; z < mainnodedata.length; z++) {
                delete mainnodedata[z]["_id"];
                nodes.add(mainnodedata[z]);
                const id = mainnodedata[z].id;
                idarray.push(id);
              }
            }
          });
        });
      }); 
    }

    const cookieService = this.cookieService;
      // try{
        const container = document.getElementById("visid");
        const data = {
            nodes: nodes,
            edges: edges
        };
        const options = {      
          edges: {
            font: {
              size: 16
            },
            selfReferenceSize: 30
          },
          nodes: {
            shape: "dot",
            // color : '#1b41b7',
            font: {
              size: 13
            }
          },
          physics: {
              "enabled": true,
              forceAtlas2Based: {
                  gravitationalConstant: -26,
                  centralGravity: 0.005,
                  springLength: 230,
                  springConstant: 0.18
                  },
              maxVelocity: 146,
              solver: "forceAtlas2Based",
              timestep: 0.35,
              stabilization: {
                iterations: 150
              }
              },
          interaction: {
            navigationButtons: true,
            hover: true,
            keyboard: false
          }
        };
        const viscaseprofileDialogRef = this.viscaseprofileModal;
        const network = new Network(container, data, options);
        window.onresize = function() {
          setInterval(function() {
            network.fit();
          }, 2000);
        };
        network.on("click", function(params) {
          // // //(params.nodes[0]);
          
          if (typeof params.nodes[0] !== "undefined") {
            const node = data.nodes.get(params.nodes[0]);
            // //(node);
            
            // localStorage.setItem('viscaseprof', JSON.stringify(node));
            cookieService.set("viscaseprof", JSON.stringify(node));
            viscaseprofileDialogRef.open(VisCaseprofileComponent, {
              width: "850px",
              height: "550px"
            });
          }
        });
  }

  clearAll() {
    this.judgements = [];
    this.isSingleJudg = false;
    this.singleJudg = null;
    this.query = "";

    this.isFilterQuery = false;

    this.additiveSearch = [];

    this.showViz = false;

    this.searchresult = false;
    this.searchresultPB = false;

    // search highlight text
    localStorage.removeItem("s1");
    localStorage.removeItem("s2");
    localStorage.removeItem("s3");
    localStorage.removeItem("s4");
    localStorage.removeItem("s5");
    localStorage.removeItem("s6");
    // ends here

  }

  onSearch() {

    // search highlight text
    for ( let i = 7; i < 79; i ++ ) {
      localStorage.removeItem("s" + i);
    }
    // ends here

    // // // console.log('test', this.searchtext.trim().indexOf('&'));
    if ( this.searchtext.trim() === "" ) {
      this.snackBar.open("Kindly enter text you want to search!", "OK", {
        duration: 4000
      });
    } else if ( this.searchtext.trim().indexOf("&") >= 0
       || this.searchtext.trim().indexOf(":") >= 0
       || this.searchtext.trim().indexOf("*") >= 0
       || this.searchtext.trim().indexOf("^") >= 0
       || this.searchtext.trim().indexOf("!") >= 0 ) {
      this.snackBar.open("Invalid search parameters", "OK", {
        duration: 4000
      });
    } else {
    this.judgements = [];
    this.currentpage = 1;

    this.isSingleJudg = false;
    this.singleJudg = null;

    this.isFilterQuery = false;                           // true only when filter courts are selected

    this.searchListChipInput.value = this.searchtext;
    // this.searchListChipInput.value = this.highlightText;
    // // // console.log('test', this.searchListChipInput);
    // // // console.log('test1', this.searchListChip);

    if ( this.searchParamenterText === "Headnote" ) {
      // this.autoCompletionList = autoCompletion;
      if ( this.isexact === true ) {
        this.highlightText = "\"" + this.searchtext.trim() + "\"";
        this.searchtext = "\"" + this.searchtext.trim() + "\"";
      } else if ( this.isexact === false ) {
        this.highlightText = this.searchtext.trim();
        if ( this.searchtext.indexOf(" ") !== -1 ) {
          this.searchtext = this.searchtext.replace(/\s+/g, " AND HeadNote:"); // replacing space with '%20AND%20'
        }
      }
      this.searchtext = "HeadNote:" + this.searchtext.trim();
      // // console.log('headnotesearch', this.searchtext);
    } else if ( this.searchParamenterText === "Citation" ) {
      // this.autoCompletionList = [];
      if ( this.isexact === true ) {
        this.highlightText = "\"" + this.searchtext.trim() + "\"";
        this.searchtext = "\"" + this.searchtext.trim() + "\"";
      }
      this.searchtext = "Citation:" + this.searchtext.trim();
    } else if ( this.searchParamenterText === "Full Judgement" ) {
      // // // console.log('exact', this.isexact);

      // this.autoCompletionList = autoCompletion;
      if ( this.isexact === true ) {
        this.highlightText = "\"" + this.searchtext.trim() + "\"";
        this.searchtext = "\"" + this.searchtext.trim() + "\"";
      } else if ( this.isexact === false ) {
        this.highlightText = this.searchtext.trim();
        this.searchtext = this.searchtext.replace(/\s+/g, " AND "); // replacing space with '%20AND%20'
      }
      // // // console.log('test', this.searchtext);
    } else if ( this.searchParamenterText === "Acts") {
      this.highlightText = "\"" + this.searchtext.replace(/(\,\s\d{4})/g, "") + "\"";
      this.searchtext = "\"" + this.searchtext.replace(/(\,\s\d{4})/g, "") + "\"";
      // // // console.log('regextrim', this.searchtext);
      // this.searchtext = '"' + this.searchtext.trim().slice(0, -6) + '"';
      // // // console.log('act search text', this.searchtext);
      // if ( this.isexact === true ) {
      //   this.searchtext = '"' + this.searchtext.trim() + '"';
      // }

    }
    
    if ( this.query.length === 0 ) {
      
      // search query highlight
      // // // console.log('ql', this.query.length);
      // // console.log('yo', this.searchtext.trim());
      let tempStr = "";
      if ( typeof localStorage.getItem("s1") === "object" ) {
        // if ( this.searchtext.includes('HeadNote:') === true ) {
        //   tempStr = this.searchtext.replace('HeadNote', '');
        // } else {
          tempStr = this.highlightText.trim();
        // }

        localStorage.setItem("s1", tempStr.trim());
      }
      // ends here

      this.query = this.searchtext.trim();
      const searchParamChip = this.highlightText.trim();
     
      this.additiveSearch.push(searchParamChip);
    } else {
      // if ( this.isexact === true ) {
      //   this.searchtext = '"' + this.searchtext.trim() + '"';
      // }

      // search query highlight
      // // // console.log('ql', this.additiveSearch.length);
      // // // console.log('qa', this.query);
      // // console.log('s2', typeof localStorage.getItem('s2'));
      if ( typeof localStorage.getItem("s2") === "object" ) {
        // // console.log('search check', this.searchtext.includes('HeadNote:'));
        let tempStr = "";
        // if ( this.searchtext.includes('HeadNote:') === true ) {
        //   tempStr = this.searchtext.substr(9);
        // } else {
          tempStr = this.highlightText.trim();
        // }
        // // console.log('yo1', this.searchtext.trim());
        localStorage.setItem("s2", tempStr.trim());
      } else if ( typeof localStorage.getItem("s3") === "object" ) {
        let tempStr = "";
        // if ( this.searchtext.includes('HeadNote:') === true ) {
        //   tempStr = this.searchtext.substr(9);
        // } else {
          tempStr = this.highlightText.trim();
        // }
        // // console.log('yo2', this.searchtext.trim());
        localStorage.setItem("s3", tempStr.trim());
      } else if ( typeof localStorage.getItem("s4") === "object" ) {
        let tempStr = "";
        // if ( this.searchtext.includes('HeadNote:') === true ) {
        //   tempStr = this.searchtext.substr(9);
        // } else {
          tempStr = this.highlightText.trim();
        // }
        // // console.log('yo3', this.searchtext.trim());
        localStorage.setItem("s4", tempStr.trim());
      } else if ( typeof localStorage.getItem("s5") === "object" ) {
        let tempStr = "";
        // if ( this.searchtext.includes('HeadNote:') === true ) {
        //   tempStr = this.searchtext.substr(9);
        // } else {
          tempStr = this.highlightText.trim();
        // }
        // // console.log('yo4', this.searchtext.trim());
        localStorage.setItem("s5", tempStr.trim());
      } else if ( typeof localStorage.getItem("s6") === "object" ) {
        let tempStr = "";
        // if ( this.searchtext.includes('HeadNote:') === true ) {
        //   tempStr = this.searchtext.substr(9);
        // } else {
          tempStr = this.highlightText.trim();
        // }
        // // console.log('yo5', this.searchtext.trim());
        localStorage.setItem("s6", tempStr.trim());
      }

      
      


      if ( this.additiveSearch.length < 6 ) {
        this.additiveSearch.push(this.searchtext.trim());
        this.query = this.query + "%20AND%20" + this.searchtext.trim();
      } else {
        this.snackBar.open("Search parameter limit exceeded!", "OK", {
          duration: 4000
        });
      }
      
    }
    
    // if ( this.isexact === true ) {
    //   this.query = '"' + this.query + '"';
    // }
    // //('search text', this.searchtext);

    // const nodes = new DataSet();
    // const edges = new DataSet();
    // var idarray = [];

    if ( typeof this.query === "undefined" || this.query === "" ) {
      this.snackBar.open("Kindly search something!", "OK");
    } else {

      
      // // // console.log('additive search', this.additiveSearch);
      // // console.log('query', this.query);
      this.searchtext = "";

      this.searchresultPB = true;
      this.showViz = false;
      if ( typeof this.vizExpPanel !== "undefined" ) {
        this.vizExpPanel.close();
      }
      
      // // // console.log('showviz', this.showViz);

      // console.log('query', this.query);
      if ( this.usertype === "Free" ) {
        const datenow = new Date(Date.now());
        const a = datenow.toISOString().replace("T", " ").slice(0, -5);
        // console.log('a', a);
        this.query = this.query + " AND doj_sort:[\"2010-01-01 00:00:00\"+TO+\"" + a + "\"]";
      }

      // this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          // console.log('hi');
          this.searchServce.search(this.query, this.usertype, 0).subscribe( data => {
            // //('data', data);
    
            // // test for exact search which will be used in highlight text
            // if ( this.isexact === true ) {
            //   localStorage.setItem('exact', 'true');
            // } else if ( this.isexact === false ) {
            //   localStorage.setItem('exact', 'false');
            // }
    
            // search highlight
            // localStorage.setItem('s1', this.query);
            // local
            this.isexact = false;
    
            
    
            this.totalSearchResult = data.hits.total;
            if ( this.totalSearchResult !== 0 ) {
    
              for ( let i = 0; i < data.hits.hits.length; i++ ) {
                this.judgements.push(data.hits.hits[i]._source);
              }
      
      
      
            // retaining appropriate boomark icon on page load
              setTimeout(() => {
              //   this.judgements.forEach(data =>{
              //     let i = 0;
              //     i += 1;
              //     //('i', i);
                const citation = data.hits.hits[0]._source.Citation;
                // // // console.log('citation', citation);
                // // // console.log('test', this.user.bookmarks.find((cit) => cit === citation))
                if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                  // ('acive');
                  const x = document.getElementById(citation + " active");
                  // ('x', x);
                  if ( x !== null ) {
                    x.style.display = "block";
                  }
                  
                  const y = document.getElementById(citation + " inactive");
                  // ('y', y);
                  if ( y !== null ) {
                    y.style.display = "none";
                  }
                  
                } else {
                  // ('inacive');
                  const x = document.getElementById(citation + " active");
                  // ('x', x);
                  if ( x !== null ) {
                    x.style.display = "none";
                  }
                  
                  const y = document.getElementById(citation + " inactive");
                  // ('y', y);
                  if ( y !== null ) {
                    y.style.display = "block";
                  } 
                }
            //   });
              }, 100);
      
            // retaining appropriate annotation icon on page load
              setTimeout(() => {
            //   this.judgements.forEach(data =>{
            //     let i = 0;
            //     i += 1;
            //     //('i', i);
                const citation = data.hits.hits[0]._source.Citation;
                // ('citation', citation);
                if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                  // ('acive');
                  const x = document.getElementById(citation + " annotation active");
                  // ('x', x);
                  if ( x !== null ) {
                    x.style.display = "block";
                  }
                  
                  const y = document.getElementById(citation + " annotation inactive");
                  // ('y', y);
                  if ( y !== null ) {
                    y.style.display = "none";
                  }
                  
                } else {
                  // ('inacive');
                  const x = document.getElementById(citation + " annotation active");
                  // ('x', x);
                  if ( x !== null ) {
                    x.style.display = "none";
                  }
                  
                  const y = document.getElementById(citation + " annotation inactive");
                  // ('y', y);
                  if ( y !== null ) {
                    y.style.display = "block";
                  }
                  
                 
                }
            //   });
              }, 200);
      
              this.searchresultPB = false;
              // //('search result', this.judgements);
      
              this.searchresult = true;
      
              // load first data
              this.isSingleJudg = true;
              this.singleJudg = data.hits.hits[0]._source;
              // ('singlejudg', this.singleJudg);
      
              // calculate total pages
              const a = this.totalSearchResult / 15;
              if ( this.totalSearchResult % 15 === 0 ) {
                this.totalpages = a;
              } else if ( this.totalSearchResult % 15 > 0 ) {
                this.totalpages = a + 1;
              }
      
              // highlight
              this.highlight();
              
            } else {
              this.searchresultPB = false;
              this.snackBar.open("No search results!", "OK", {
                duration: 3000
              });
            }
            
    
            
          });
        });
      // });
    }

  }
  }

  searchnextPage() {
    this.judgements = [];
    this.currentpage += 1;
    let query = "";
    // var nodes = new DataSet();
    // var edges = new DataSet();
    // var idarray = [];


    if ( this.currentpage > this.totalpages ) {
      this.snackBar.open("End of result!", "OK");
    } else {
      this.searchSpinner = true;
      // this.query = this.searchtext.trim();
      const page_no = (this.currentpage - 1 ) * 15;
      
      if ( typeof this.vizExpPanel !== "undefined" ) {
        this.vizExpPanel.close();
      }

      if ( this.isFilterQuery === true ) {
        query = this.filterquery;
      } else if ( this.isFilterQuery === false ) {
        query = this.query;
      }

      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.searchServce.search(query, this.usertype, page_no).subscribe(data => {
            // //('search next', data);
            this.searchSpinner = false;
    
    
            this.totalSearchResult = data.hits.total;
            if ( this.totalSearchResult !== 0 ) {
              for ( let i = 0; i < data.hits.hits.length; i++ ) {
                this.judgements.push(data.hits.hits[i]._source);
              }
      
              // retaining appropriate boomark icon on page load
              setTimeout(() => {
              //   this.judgements.forEach(data =>{
              //     let i = 0;
              //     i += 1;
              //     //('i', i);
                  const citation = data.hits.hits[0]._source.Citation;
                  // // // console.log('citation', citation);
                  // // // console.log('test', this.user.bookmarks.find((cit) => cit === citation))
                  if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                    // ('acive');
                    const x = document.getElementById(citation + " active");
                    // ('x', x);
                    if ( x !== null ) {
                      x.style.display = "block";
                    }
                    
                    const y = document.getElementById(citation + " inactive");
                    // ('y', y);
                    if ( y !== null ) {
                      y.style.display = "none";
                    }
                    
                  } else {
                    // ('inacive');
                    const x = document.getElementById(citation + " active");
                    // ('x', x);
                    if ( x !== null ) {
                      x.style.display = "none";
                    }
                    
                    const y = document.getElementById(citation + " inactive");
                    // ('y', y);
                    if ( y !== null ) {
                      y.style.display = "block";
                    } 
                  }
              //   });
                }, 100);
        
              // retaining appropriate annotation icon on page load
                setTimeout(() => {
              //   this.judgements.forEach(data =>{
              //     let i = 0;
              //     i += 1;
              //     //('i', i);
                  const citation = data.hits.hits[0]._source.Citation;
                  // ('citation', citation);
                  if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                    // ('acive');
                    const x = document.getElementById(citation + " annotation active");
                    // ('x', x);
                    if ( x !== null ) {
                      x.style.display = "block";
                    }
                    
                    const y = document.getElementById(citation + " annotation inactive");
                    // ('y', y);
                    if ( y !== null ) {
                      y.style.display = "none";
                    }
                    
                  } else {
                    // ('inacive');
                    const x = document.getElementById(citation + " annotation active");
                    // ('x', x);
                    if ( x !== null ) {
                      x.style.display = "none";
                    }
                    
                    const y = document.getElementById(citation + " annotation inactive");
                    // ('y', y);
                    if ( y !== null ) {
                      y.style.display = "block";
                    }
                    
                   
                  }
              //   });
                }, 200);
      
      
              
                // document.getElementById('visid').style.height = '400px';
                // document.getElementById('visid').style.backgroundColor= "aliceblue";
                // document.getElementById('visid').style.marginBottom= '20px';
      
                this.searchresult = true;
    
                // highlight
              // this.highlight();
      
                
              } else {
                this.searchresultPB = false;
                this.snackBar.open("No search results!", "OK", {
                  duration: 3000
                });
              }
            // } 
            
          });
        });
      });
      
    }
  }

  searchpreviousPage() {
    this.judgements = [];
    this.currentpage -= 1;
    let query = "";
    // var nodes = new DataSet();
    // var edges = new DataSet();
    // var idarray = [];

    if ( this.currentpage > 0 ) {
      this.searchSpinner = true;
      // var search = this.searchtext.trim();
      const page_no = (this.currentpage - 1 ) * 15;
      
      if ( typeof this.vizExpPanel !== "undefined" ) {
        this.vizExpPanel.close();
      }

      if ( this.isFilterQuery === true ) {
        query = this.filterquery;
      } else if ( this.isFilterQuery === false ) {
        query = this.query;
      }

      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.searchServce.search(query, this.usertype, page_no).subscribe(data => {
            // //('search next', data);
            this.searchSpinner = false;
    
            this.totalSearchResult = data.hits.total;
            if ( this.totalSearchResult !== 0 ) {
              for ( let i = 0; i < data.hits.hits.length; i++ ) {
                this.judgements.push(data.hits.hits[i]._source);
              }
      
              // retaining appropriate boomark icon on page load
            setTimeout(() => {
              //   this.judgements.forEach(data =>{
              //     let i = 0;
              //     i += 1;
              //     //('i', i);
                  const citation = data.hits.hits[0]._source.Citation;
                  // // // console.log('citation', citation);
                  // // // console.log('test', this.user.bookmarks.find((cit) => cit === citation))
                  if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                    // ('acive');
                    const x = document.getElementById(citation + " active");
                    // ('x', x);
                    if ( x !== null ) {
                      x.style.display = "block";
                    }
                    
                    const y = document.getElementById(citation + " inactive");
                    // ('y', y);
                    if ( y !== null ) {
                      y.style.display = "none";
                    }
                    
                  } else {
                    // ('inacive');
                    const x = document.getElementById(citation + " active");
                    // ('x', x);
                    if ( x !== null ) {
                      x.style.display = "none";
                    }
                    
                    const y = document.getElementById(citation + " inactive");
                    // ('y', y);
                    if ( y !== null ) {
                      y.style.display = "block";
                    } 
                  }
              //   });
              }, 100);
        
              // retaining appropriate annotation icon on page load
              setTimeout(() => {
              //   this.judgements.forEach(data =>{
              //     let i = 0;
              //     i += 1;
              //     //('i', i);
                  const citation = data.hits.hits[0]._source.Citation;
                  // ('citation', citation);
                  if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                    // ('acive');
                    const x = document.getElementById(citation + " annotation active");
                    // ('x', x);
                    if ( x !== null ) {
                      x.style.display = "block";
                    }
                    
                    const y = document.getElementById(citation + " annotation inactive");
                    // ('y', y);
                    if ( y !== null ) {
                      y.style.display = "none";
                    }
                    
                  } else {
                    // ('inacive');
                    const x = document.getElementById(citation + " annotation active");
                    // ('x', x);
                    if ( x !== null ) {
                      x.style.display = "none";
                    }
                    
                    const y = document.getElementById(citation + " annotation inactive");
                    // ('y', y);
                    if ( y !== null ) {
                      y.style.display = "block";
                    }
                    
                   
                  }
              //   });
              }, 200);
      
      
              // if ( this.judgements.length === 0 ) {
              //   this.snackBar.open('No results!', 'OK');
              // } else if ( this.judgements.length > 0 ) {
                // document.getElementById('visid').style.height = '400px';
                // document.getElementById('visid').style.backgroundColor= "aliceblue";
                // document.getElementById('visid').style.marginBottom= '20px';
      
                this.searchresult = true;
                // highlight
              // this.highlight();
                
            } else {
              this.searchresultPB = false;
              this.snackBar.open("No search results!", "OK", {
                duration: 3000
              });
            }
            
            
          });
        });
      });
      
    } else {
      // this.currentpage = 1;
      this.snackBar.open("Start of result!", "OK");
    }
  }

  showJudgementData(data: Judgement) {
    this.singleJudg = data;
    // // console.log('single judg', this.singleJudg);
    this.isSingleJudg = true;

    // for updating UI after clicking citation on left, bcoz of highlighting auto update not working
    document.getElementById("searchresult").innerHTML = 
      "<p class=\"paddingRight highlight\" id=\"highlightCaseNo\"><strong>Case No:</strong> "
        + this.singleJudg.Case_No +
      "</p><p class=\"paddingRight highlight\" id=\"highlightPetitioner\" style=\"text-align: justify;\"><strong>Petitioner:</strong> "
        + this.singleJudg.Petitioner +
      "</p><p class=\"paddingRight highlight\" id=\"highlightRespondent\" style=\"text-align: justify;\"><strong>Responent:</strong> "
        + this.singleJudg.Respondent +
      "</p><p class=\"paddingRight highlight\" id=\"highlightCourt\"><strong>Court:</strong> "
        + this.singleJudg.Court +
      "</p><p class=\"paddingRight highlight\"><strong>Date:</strong> "
        + this.singleJudg.Date +
      "</p><p class=\"paddingRight highlight\" id=\"highlightJudge\"><strong>Judge:</strong> "
        + this.singleJudg.Judge_name +
      "</p><p class=\"paddingRight highlight\" id=\"highlightHeadnote\" style=\"text-align: justify; white-space:pre-wrap;\"><strong>Headnote:</strong> "
        + this.singleJudg.HeadNote +
      "</p><br>";
      // '<div fxlayout="row" fxlayoutalign="center" ng-reflect-fx-layout="row" ng-reflect-fx-layout-align="center" style="flex-direction: row; box-sizing: border-box; display: flex; place-content: stretch center; align-items: stretch;">'
      //   + '<a class="background-primary hidden-print mat-raised-button" mat-raised-button="" target="_blank" href="' +
      //   'https://nearlaw.com/PDF/' + this.singleJudg.fileurl +
      // '><span class="mat-button-wrapper"><span class="text-floral-white">Full Judgment</span></span><div class="mat-button-ripple mat-ripple" matripple="" ng-reflect-centered="false" ng-reflect-disabled="false" ></div><div class="mat-button-focus-overlay"></div></a></div><br>';
    
    // retaining appropriate boomark icon on page load
    setTimeout(() => {
      //   this.judgements.forEach(data =>{
      //     let i = 0;
      //     i += 1;
      //     //('i', i);
      const citation = this.singleJudg.Citation;
      // // // console.log('citation', citation);
          // // // console.log('test', this.user.bookmarks.find((cit) => cit === citation))
          if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
            // ('acive');
            const x = document.getElementById(citation + " active");
            // ('x', x);
            if ( x !== null ) {
              x.style.display = "block";
            }
            
            const y = document.getElementById(citation + " inactive");
            // ('y', y);
            if ( y !== null ) {
              y.style.display = "none";
            }
            
          } else {
            // ('inacive');
            const x = document.getElementById(citation + " active");
            // ('x', x);
            if ( x !== null ) {
              x.style.display = "none";
            }
            
            const y = document.getElementById(citation + " inactive");
            // ('y', y);
            if ( y !== null ) {
              y.style.display = "block";
            } 
          }
      //   });
      }, 100);

      // retaining appropriate annotation icon on page load
      setTimeout(() => {
      //   this.judgements.forEach(data =>{
      //     let i = 0;
      //     i += 1;
      //     //('i', i);
        const citation = this.singleJudg.Citation;
        // // // console.log('citation', citation);
        // // // console.log('test', this.user.annotations.find((z) => z.citation === citation));
          if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
            // ('acive');
            const x = document.getElementById(citation + " annotation active");
            // ('x', x);
            if ( x !== null ) {
              x.style.display = "block";
            }
            
            const y = document.getElementById(citation + " annotation inactive");
            // ('y', y);
            if ( y !== null ) {
              y.style.display = "none";
            }
            
          } else {
            // ('inacive');
            const x = document.getElementById(citation + " annotation active");
            // ('x', x);
            if ( x !== null ) {
              x.style.display = "none";
            }
            
            const y = document.getElementById(citation + " annotation inactive");
            // ('y', y);
            if ( y !== null ) {
              y.style.display = "block";
            }
            
           
          }
      //   });
      }, 200);
        
     this.highlight();

  }

  ZoomIn(id) {
    this.zoomCount += 1;
    // //('zoomincount', this.zoomCount);
    if ( this.zoomCount < 9 ) {
      this.fontSize += 1.2;
      // //('fontsize', this.fontSize);
      
      const elem = document.getElementById(id).parentElement;
      // ('elem', elem);
      elem.style.fontSize = this.fontSize + "px";
    }
    if ( this.zoomCount >= 9 ) {
      this.zoomCount = 9;
    }
  }

  ZoomOut(id) {
    this.zoomCount -= 1;
    // //('zoomoutcount', this.zoomCount);

    if ( this.zoomCount > -4 ) {
      this.fontSize -= 1.2;
      // //('fontsize', this.fontSize);

      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if ( this.zoomCount < -3 ) {
      this.zoomCount = -3;
    }
  }

  GetParent(elem) {
    this.counterSpeech = this.counterSpeech + 1;
    // // console.log('counterSpeech', this.counterSpeech);
    this.playSpeech = !this.playSpeech;
    // // console.log('playSpeech', this.playSpeech);
    if ( this.playSpeech === true ) {
      if ( this.counterSpeech > 1 ) {
        speechSynthesis.resume();
      } else {
        speechSynthesis.cancel();
        // // console.log('elem', elem);
        const parentElem = document.getElementById(elem).parentElement.innerText;
        // if (!parentElem) {
        //   parentElem = document.getElementById(elem).parentNode;
        // }
        // alert ("The id of the parent element: " + parentElem.id);
        // var node = document.getElementById(elem).querySelectorAll('div');
        // var parentelement = node.parentElement
        // // console.log('node', parentElem);
        const text = parentElem.substr(80);
        const text1 = text.slice(0, -24);
        text1.replace(/\./g, " ");
        text1.replace(/\:/g, " ");
        // // console.log('text', text1);
    
        // const splitAt = index => x => [x.slice(0, index), x.slice(index)];
        // const chunk = text1.match(/(.|[\r\n]){1,90}/g);
        const chunk = text1.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,15}\b/g);  // spillting at \n and every 15th whitespace
        // // console.log('chunk', chunk);
        const ttsArray: SpeechSynthesisUtterance[] = [];
        chunk.forEach((chunkElem) => {
          ttsArray.push(new SpeechSynthesisUtterance(chunkElem));
        });
        ttsArray.forEach((ttsElem) => {
          ttsElem.lang = "en-IN";
          ttsElem.pitch = 1;
          ttsElem.rate = 0.9;
        });
        // // console.log('ttsArray', ttsArray);
        ttsArray.forEach((ttsElem) => {
          // ttsElem.lang = 'en-IN';
          // ttsElem.pitch = 1;
          // ttsElem.rate = 0.9;
          window.speechSynthesis.speak(ttsElem);
        });
        
    //     var texttospeak = new SpeechSynthesisUtterance(text1);
    // // console.log('tts', texttospeak);
    // window.speechSynthesis.speak(texttospeak);
        // const recognition = this.speechRecognition;
        // recognition.onspeechend = () => {
        //   // console.log('Speech has stopped being detected');
        // }
      }
      
      
    } else if ( this.playSpeech === false ) {
      speechSynthesis.pause();
    }

  }

  StopSpeech() {
    speechSynthesis.cancel();
    this.counterSpeech = 0;
    this.playSpeech = false;
  }

  singlePrint() {
    // // //('divId', id);
        const printContents = document.getElementById("searchresult").innerHTML;
        // // //(printContents);
        const w = window.open();
        w.document.write("<h1 align=\"center\" style=\"color: blue;\">NearLaw</h1>");
        w.document.write("<div style=\"text-align: justify\">" + printContents + "</div>");
        w.document.write("<style>.hidden-print{display:none!important}</style>");
        w.document.write("<scr" + "ipt type=\"text/javascript\">" + "window.onload = function() { window.print(); window.close(); };" + "</sc" + "ript>");
        
        w.document.close(); // necessary for IE >= 10
        w.focus(); // necessary for IE >= 10
        // window.print();
        return true;
  }

  applyfilter() {
    this.judgements = [];
    this.currentpage = 1;
    // //('filter', this.filtervalue);
    // this.query = this.searchtext.trim() + ' AND tribunal:' + this.filtervalue;
    this.filterquery = this.query.trim() + " AND tribunal:" + this.filtervalue;
    // //('filter query', this.query);
    // var nodes = new DataSet();
    // var edges = new DataSet();
    // var idarray = [];
    this.searchSpinner = true;
    
    if ( typeof this.vizExpPanel !== "undefined" ) {
      this.vizExpPanel.close();
    }

    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.searchServce.search(this.filterquery, this.usertype, 0).subscribe(data => {
          // //('fliterresult', data);
          this.totalSearchResult = data.hits.total;
    
          this.isFilterQuery = true;
    
          for ( let i = 0; i < data.hits.hits.length; i++ ) {
            this.judgements.push(data.hits.hits[i]._source);
          }
    
          // retaining appropriate boomark icon on page load
          setTimeout(() => {
            this.judgements.forEach(data => {
              let i = 0;
              i += 1;
              // ('i', i);
              const citation = data.Citation;
              // ('citation', citation);
              if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                // ('acive');
                const x = document.getElementById(citation + " active");
                // ('x', x);
                if ( x !== null ) {
                  x.style.display = "block";
                }
                
                const y = document.getElementById(citation + " inactive");
                // ('y', y);
                if ( y !== null ) {
                  y.style.display = "none";
                }
                
              } else {
                // ('inacive');
                const x = document.getElementById(citation + " active");
                // ('x', x);
                if ( x !== null ) {
                  x.style.display = "none";
                }
                
                const y = document.getElementById(citation + " inactive");
                // ('y', y);
                if ( y !== null ) {
                  y.style.display = "block";
                } 
              }
            });
          }, 100);
    
          this.searchSpinner = false;
          const a = this.totalSearchResult / 15;
          if ( this.totalSearchResult % 15 === 0 ) {
            this.totalpages = a;
          } else if ( this.totalSearchResult % 15 > 0 ) {
            this.totalpages = a + 1;
          }
    
          if ( this.judgements.length === 0 ) {
            this.snackBar.open("No results!", "OK");
          } else if ( this.judgements.length > 0 ) {
            // vis code
            // document.getElementById('visid').style.height = '400px';
            // document.getElementById('visid').style.backgroundColor= "aliceblue";
            // document.getElementById('visid').style.marginBottom= '20px';
    
            this.searchresult = true;
          }
        });
      });
    });
    
    
  }

  addKeyword(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // //('length', this.keywords.length);
    if (this.keywords.length < 3) {

      if ( this.keywordChecked === true ) {
        if ((value || "").trim()) {
          this.keywords.push("\"" + value.trim() + "\"");
          this.keywordChecked = false;
        }
      } else if  ( this.keywordChecked === false ) {
        if ((value || "").trim()) {
          this.keywords.push(value.trim());
        }
      }

      // //('keywords', this.keywords);
      // Reset the input value
      if (input) {
        input.value = "";
      }


    } else if (this.keywords.length >= 3) {
      this.snackBar.open("You cannot add more than three keywords to search in Full judgment!", "OK", {
        duration: 5000
      });
    }

    // // set exact value
    // if ( this.keywords.length === 1 && this.keywordChecked === true ) {
    //   this.keywordExact = true;
    //   this.keywordChecked = false;
    //   // //('keywordExact', this.keywordExact);
    // } else if ( this.keywords.length === 2 && this.keywordChecked === true ) {
    //   this.keyword1Exact = true;
    //   this.keywordChecked = false;
    //   // //('keyword1Exact', this.keyword1Exact);
    // } else if ( this.keywords.length === 3 && this.keywordChecked === true ) {
    //   this.keyword2Exact = true;
    //   this.keywordChecked = false;
    //   // //('keyword2Exact', this.keyword2Exact);
    // }
  }

  removeKeyword(keyword): void {
    const index = this.keywords.indexOf(keyword);

    if ( localStorage.getItem("s7") === keyword ) {
      localStorage.removeItem("s7");
    } else if ( localStorage.getItem("s8") === keyword ) {
      localStorage.removeItem("s8");
    } else if ( localStorage.getItem("s9") === keyword ) {
      localStorage.removeItem("s9");
    }

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  addHeadnote(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (this.headnotes.length < 3) {
      // Add our Headnote

      if ( this.headnoteChecked === true ) {
        if ((value || "").trim()) {
          this.headnotes.push("\"" + value.trim() + "\"");
          this.headnoteChecked = false;
        }
      } else if  ( this.headnoteChecked === false ) {
        if ((value || "").trim()) {
          this.headnotes.push(value.trim());
        }
      }
      // //('headnotes', this.headnotes);
      // Reset the input value
      if (input) {
        input.value = "";
      }
    } else if (this.headnotes.length >= 3) {
      this.snackBar.open("You cannot add more than three keywords to search in Headnote!", "OK", {
        duration: 5000
      });
    }

    // if ( this.headnotes.length === 1 && this.headnoteChecked === true ) {
    //   this.headnoteExact = true;
    //   this.headnoteChecked = false;
    //   // //('headnoteExact', this.headnoteExact);
    // } else if ( this.headnotes.length === 2 && this.headnoteChecked === true ) {
    //   this.headnote1Exact = true;
    //   this.headnoteChecked = false;
    //   // //('headnote1Exact', this.headnote1Exact);
    // } else if ( this.headnotes.length === 3 && this.headnoteChecked === true ) {
    //   this.headnote2Exact = true;
    //   this.headnoteChecked = false;
    //   // //('headnote2Exact', this.headnote2Exact);
    // }
  }

  // onHeadnoteSelect(headnote: string): void {
  //   this.headnoteChecked = !this.headnoteChecked;
  //   if ( this.headnoteChecked === true ) {
  //     // //('headnote', headnote);
  //     const index = this.headnotes.indexOf(headnote);
  //     const value  = '"' + headnote + '"';
  //     this.headnotes[index] = value;
  //     // //('exact', this.headnotes[index]);
  //     document.getElementById(headnote).classList.add('background-primary');
  //   } else if ( this.headnoteChecked === false ) {
  //     const index = this.headnotes.indexOf(headnote);
  //     const value1 = headnote.substr(1);
  //     const value2 = value1.slice(0, -1);
  //     this.headnotes[index] = value2;
  //     // //('exact', this.headnotes[index]);
  //   }
  // }

  removeHeadnote(headnote): void {
    const index = this.headnotes.indexOf(headnote);
    // // // console.log('index', index);
    // // // console.log('hl', this.headnotes.length);

    if ( localStorage.getItem("s11") === headnote ) {
      localStorage.removeItem("s11");
    } else if ( localStorage.getItem("s12") === headnote ) {
      localStorage.removeItem("s12");
    } else if ( localStorage.getItem("s13") === headnote ) {
      localStorage.removeItem("s13");
    }
    if (index >= 0) {
      this.headnotes.splice(index, 1);
    }
  }

  addPartyname(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if (this.partynames.length < 3) {
      // Add our Party Name
      if ((value || "").trim()) {
        this.partynames.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = "";
      }
    } else if (this.partynames.length >= 3) {
      this.snackBar.open("You cannot add more than three keywords to search in Party Name!", "OK", {
        duration: 5000
      });
    }
  }

  removePartyname(partyname): void {
    const index = this.partynames.indexOf(partyname);

    if (index >= 0) {
      this.partynames.splice(index, 1);
    }
  }

  addCourt(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.courtAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ( this.courts.length < 2 ) {
        // Add our court
        if ((value || "").trim()) {
          this.courts.push(value.trim());
        }

        // Reset the input value
        if (input) {
          input.value = "";
        }

        this.courtCtrl.setValue(null);
      } else if ( this.courts.length >= 2 ) {
        this.snackBar.open("You cannot select more than two courts in Court Search!", "OK", {
          duration: 5000
        });
      }
      // //('length', this.courts.length);
    }
  }

  removeCourt(court: string): void {
    const index = this.courts.indexOf(court);

    if (index >= 0) {
      this.courts.splice(index, 1);
    }
  }

  selectedCourt(event: MatAutocompleteSelectedEvent): void {
    if ( this.courts.length < 2 ) {
      this.courts.push(event.option.viewValue);
      this.courtInput.nativeElement.value = "";
      this.courtCtrl.setValue(null);
    } else if ( this.courts.length >= 2 ) {
      this.snackBar.open("You cannot select more than two courts in Court Search!", "OK", {
        duration: 5000
      });
    }
  }

  private _courtfilter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.courtonlyNames.filter(court => court.toLowerCase().indexOf(filterValue) === 0);
  }

  addsubActContent(event: MatChipInputEvent): void {

    if ( this.actContent !== "" && typeof this.actContent !== undefined ) {
      const input = event.input;
      const value = this.actContent + " " + event.value;

      if ( this.subActContents.length < 5 ) {
        // Add our actcontent
        if ((value || "").trim()) {
          this.subActContents.push(value.trim());
          this.actContent = "";
        }

        // Reset the input value
        if (input) {
          input.value = "";
        }
      } else if ( this.subActContents.length >= 5 ) {
        this.snackBar.open("You cannot add more than five items!", "OK", {
          duration: 3000
        });
      }
    }
  }

  removesubActContent(subActContent: string): void {
    // //('test');
    const index = this.subActContents.indexOf(subActContent);

    if (index >= 0) {
      this.subActContents.splice(index, 1);
    }
  }

  addsubActContent1(event: MatChipInputEvent): void {
    if ( this.actContent1 !== "" && typeof this.actContent1 !== undefined ) {
      const input = event.input;
      const value = this.actContent1 + " " + event.value;

      if ( this.subActContents1.length < 5 ) {
        // Add our actcontent
        if ((value || "").trim()) {
          this.subActContents1.push(value.trim());
          this.actContent1 = "";
        }

        // Reset the input value
        if (input) {
          input.value = "";
        }
      } else if ( this.subActContents1.length >= 5 ) {
        this.snackBar.open("You cannot add more than five items!", "OK", {
          duration: 3000
        });
      }
    }
  }

  removesubActContent1(subActContent: string): void {
    const index = this.subActContents1.indexOf(subActContent);

    if (index >= 0) {
      this.subActContents1.splice(index, 1);
    }
  }

  addsubActContent2(event: MatChipInputEvent): void {
    if ( this.actContent2 !== "" && typeof this.actContent2 !== undefined ) {
      const input = event.input;
      const value = this.actContent2 + " " + event.value;

      if ( this.subActContents2.length < 5 ) {
        // Add our actcontent
        if ((value || "").trim()) {
          this.subActContents2.push(value.trim());
          this.actContent2 = "";
        }

        // Reset the input value
        if (input) {
          input.value = "";
        }
      } else if ( this.subActContents2.length >= 5 ) {
        this.snackBar.open("You cannot add more than five items!", "OK", {
          duration: 3000
        });
      }
    }
  }

  removesubActContent2(subActContent: string): void {
    const index = this.subActContents2.indexOf(subActContent);

    if (index >= 0) {
      this.subActContents2.splice(index, 1);
    }
  }

  addAct() {
    this.actCount = this.actCount + 1;
    // //('actcount', this.actCount);

    if ( this.actCount < 3 ) {
      // 
      if ( this.actCount === 1 ) {
          this.act1Visible = true;
      } else if ( this.actCount === 2 ) {
          this.act2Visible = true;
      }
    }
    if ( this.actCount > 2 ) {
      this.actCount = this.actCount - 1;
      // //('act>2', this.actCount);
      this.snackBar.open("You cannot add more than three acts at a time in search!", "OK", {
        duration: 3000
      });
    }
  }

  actclose() {
    this.actname = "";
    this.subActContents = [];

    localStorage.removeItem("s16");
    for ( let i = 19; i < 39; i++ ) {
      localStorage.removeItem("s" + i);
    }
  }

  act1close() {
    this.act1Visible = false;
    this.actCount = this.actCount - 1;
    // //('actCountclose2', this.actCount);
    this.act1name = "";
    this.subActContents1 = [];

    localStorage.removeItem("s17");
    for ( let i = 39; i < 59; i++ ) {
      localStorage.removeItem("s" + i);
    }
  }

  act2close() {
    this.act2Visible = false;
    this.actCount = this.actCount - 1;
    // //('actCountclose2', this.actCount);
    this.act2name = "";
    this.subActContents2 = [];

    localStorage.removeItem("s18");
    for ( let i = 59; i < 79; i++ ) {
      localStorage.removeItem("s" + i);
    }
  }

  closeAdvanceSearch() {
    this.opened = false;
    localStorage.clear();
  }

  advanceSearch() {
    this.currentpage = 1;
    this.advanceSearchSpinner = true;
    this.opened = false;
    this.judgements = [];
    this.isSingleJudg = false;
    this.singleJudg = null;
    const nodes = new DataSet();
    const edges = new DataSet();
    const idarray = [];

    // search highlight text
    for ( let i = 1; i < 79; i ++ ) {
      localStorage.removeItem("s" + i);
    }
    // ends here

    this.isFilterQuery = false;

    let keyword = "";  let keyword1 = "";  let keyword2 = ""; 
    let keywordError = false; let keyword1Error = false;  let keyword2Error = false;

    let volume = "";  let citationpageno = "";  let citation = ""; let citation1 = ""; let citation2 = "";
    let citationError = false;

    let headnote = "";  let headnote1 = "";  let headnote2 = "";
    let headnoteError = false; let headnote1Error = false;  let headnote2Error = false;

    let partyname = ""; let partynameError = false;

    let court = ""; let court1 = "";  let Courts = "";
    const courtError = false; const court1Error = false;

    let act = ""; let act1 = "";  let act2 = "";
    let subactcontent00 = ""; let subactcontent01 = ""; let subactcontent02 = ""; let subactcontent03 = ""; let subactcontent04 = "";
    let subactcontent10 = ""; let subactcontent11 = ""; let subactcontent12 = ""; let subactcontent13 = ""; let subactcontent14 = "";
    let subactcontent20 = ""; let subactcontent21 = ""; let subactcontent22 = ""; let subactcontent23 = ""; let subactcontent24 = "";
    let actError = false; let act1Error = false; let act2Error = false;

    let judgeName = "";
    let judgenameaError = false;

    if (this.keywords.length > 0) {
      if (typeof this.keywords[0] !== "undefined") {
        keyword = this.keywords[0].trim();

        // search highlight
        localStorage.setItem("s7", keyword);
      }
      if (typeof this.keywords[1] !== "undefined") {
        keyword1 = this.keywords[1].trim();

        // search highlight
        localStorage.setItem("s8", keyword1);
      }
      if (typeof this.keywords[2] !== "undefined") {
        keyword2 = this.keywords[2].trim();

        // search highlight
        localStorage.setItem("s9", keyword2);
      }

      // //('keyword', keyword);
      // //('keyword1', keyword1);
      // //('keyword2', keyword2);
    }

    if ( typeof this.citationVolume !== "undefined" && this.citationVolume !== "") {
      volume = "(" + this.citationVolume.trim() + ")";
    }
    // this.citationVolume = '(' + this.citationVolume + ')';
    if ( typeof this.citationPageNo !== "undefined" ) {
      citationpageno = this.citationPageNo.trim();
    }
    // // console.log('citationJournal', this.citationJournal);
    let journal = this.citationJournal;
    if ( this.citationJournal === "ALL MR SC" || this.citationJournal === "ALL MR (Cri) SC" || this.citationJournal === "NearLaw (Mumbai HC) SC" || this.citationJournal === "NearLaw (Mumbai HC) (Cri) SC") {
      journal = this.citationJournal.slice(0, -3);
    }
    citation1 = this.citationYear + volume;
    citation2 = journal + " " + citationpageno;
    
    citation = citation1 + " " + citation2.trim();
    // // console.log('citation', citation);

    // search highlight
    localStorage.setItem("s10", citation);
    if ( citation.length < 5 ) {
      citation = "";

      // search highlight
      localStorage.removeItem("s10");
    }

    if ( this.headnotes.length > 0 ) {
      if (typeof this.headnotes[0] !== "undefined") {
        headnote = this.headnotes[0].trim();

        // search highlight
        localStorage.setItem("s11", headnote);
      }
      if (typeof this.headnotes[1] !== "undefined") {
        headnote1 = this.headnotes[1].trim();

        // search highlight
        localStorage.setItem("s12", headnote1);
      }
      if (typeof this.headnotes[2] !== "undefined") {
        headnote2 = this.headnotes[2].trim();

        // search highlight
        localStorage.setItem("s13", headnote2);
      }

      // //('headnote', headnote);
      // //('headnote1', headnote1);
      // //('headnote2', headnote2);
    }

    if ( typeof this.judgename !== "undefined" ) {
      judgeName = this.judgename.trim();

      // search highlight
      localStorage.setItem("s14", judgeName);
    }

    if ( typeof this.PartyName !== "undefined" ) {
      partyname = this.PartyName.trim();

      // search highlight
      localStorage.setItem("s15", "\"" + partyname + "\"");
    }

    if ( this.courts.length > 0 ) {
      if (typeof this.courts[0] !== "undefined") {
        court = this.courts[0].trim();
      }
      if (typeof this.courts[1] !== "undefined") {
        court1 = this.courts[1].trim();
      }

      // //('court', court);
      // //('court1', court1);
    }

    if ( typeof this.actname !== "undefined" ) {
      const Act = this.actname.trim().split(",");
      act = Act[0];

      // search highlight
      localStorage.setItem("s16", "\"" + act + "\"");
    }

    if ( typeof this.act1name !== "undefined" ) {
      const Act1 = this.act1name.trim().split(",");
      act1 = Act1[0];

      // search highlight
      localStorage.setItem("s17", "\"" + act1 + "\"");
    }

    if ( typeof this.act2name !== "undefined" ) {
      const Act2 = this.act2name.trim().split(",");
      act2 = Act2[0];

      // search highlight
      localStorage.setItem("s18", "\"" + act2 + "\"");
    }

    if ( this.subActContents.length > 0 ) {
      if (typeof this.subActContents[0] !== "undefined" ) {
        subactcontent00 = this.subActContents[0].trim();
        if ( subactcontent00[0] === "S" ) {
          // //('test');
          subactcontent00 = subactcontent00.substr(8);
          // search highlight
          localStorage.setItem("s19", "\"section " + subactcontent00 + "\"");

          subactcontent00 = "\"section " + subactcontent00 + "\" AND ";
          // //('test1', subactcontent00);
        } else if ( subactcontent00[0] === "O" ) {
          subactcontent00 = subactcontent00.substr(6);
          // search highlight
          localStorage.setItem("s20", "\"order " + subactcontent00 + "\"");

          subactcontent00 = "\"order " + subactcontent00 + "\" AND ";

          // //('test1', subactcontent00);
        } else if ( subactcontent00[0] === "A" ) {
          subactcontent00 = subactcontent00.substr(8);

          // search highlight
          localStorage.setItem("s21", "\"article " + subactcontent00 + "\"");

          subactcontent00 = "\"article " + subactcontent00 + "\" AND ";
          // //('test1', subactcontent00);
        } else if ( subactcontent00[0] === "R" ) {
          subactcontent00 = subactcontent00.substr(8);

          // search highlight
          localStorage.setItem("s22", "\"rule " + subactcontent00 + "\"");

          subactcontent00 = "\"rule " + subactcontent00 + "\" AND ";


          // //('test1', subactcontent00);
        }
      }
      if (typeof this.subActContents[1] !== "undefined" ) {
        subactcontent01 = this.subActContents[1].trim();
        if ( subactcontent01[0] === "S" ) {
          // //('test');
          subactcontent01 = subactcontent01.substr(8);

          // search highlight
          localStorage.setItem("s23", "\"section " + subactcontent01 + "\"");

          subactcontent01 = "\"section " + subactcontent01 + "\" AND ";

          // //('test1', subactcontent01);
        } else if ( subactcontent01[0] === "O" ) {
          subactcontent01 = subactcontent01.substr(6);

          // search highlight
          localStorage.setItem("s24", "\"order " + subactcontent01 + "\"");

          subactcontent01 = "\"order " + subactcontent01 + "\" AND ";

          // //('test1', subactcontent01);
        } else if ( subactcontent01[0] === "A" ) {
          subactcontent01 = subactcontent01.substr(8);

          // search highlight
          localStorage.setItem("s25", "\"article " + subactcontent01 + "\"");

          subactcontent01 = "\"article " + subactcontent01 + "\" AND ";

          // //('test1', subactcontent01);
        } else if ( subactcontent01[0] === "R" ) {
          subactcontent01 = subactcontent01.substr(8);

          // search highlight
          localStorage.setItem("s26", "\"rule " + subactcontent01 + "\"");

          subactcontent01 = "\"rule " + subactcontent01 + "\" AND ";

          // //('test1', subactcontent01);
        }
      }
      if (typeof this.subActContents[2] !== "undefined" ) {
        subactcontent02 = this.subActContents[2].trim();
        if ( subactcontent02[0] === "S" ) {
          // //('test');
          subactcontent02 = subactcontent02.substr(8);

          // search highlight
          localStorage.setItem("s27", "\"section " + subactcontent02 + "\"");

          subactcontent02 = "\"section " + subactcontent02 + "\" AND ";


          // //('test1', subactcontent02);
        } else if ( subactcontent02[0] === "O" ) {
          subactcontent02 = subactcontent02.substr(6);

          // search highlight
          localStorage.setItem("s28", "\"order " + subactcontent02 + "\"");

          subactcontent02 = "\"order " + subactcontent02 + "\" AND ";

          // //('test1', subactcontent02);
        } else if ( subactcontent02[0] === "A" ) {
          subactcontent02 = subactcontent02.substr(8);

          // search highlight
          localStorage.setItem("s29", "\"article " + subactcontent02 + "\"");

          subactcontent02 = "\"article " + subactcontent02 + "\" AND ";

          // //('test1', subactcontent02);
        } else if ( subactcontent02[0] === "R" ) {
          subactcontent02 = subactcontent02.substr(8);

          // search highlight
          localStorage.setItem("s30", "\"rule " + subactcontent02 + "\"");

          subactcontent02 = "\"rule " + subactcontent02 + "\" AND ";

          // //('test1', subactcontent02);
        }
      }
      if (typeof this.subActContents[3] !== "undefined" ) {
        subactcontent03 = this.subActContents[3].trim();
        if ( subactcontent03[0] === "S" ) {
          // //('test');
          subactcontent03 = subactcontent03.substr(8);

          // search highlight
          localStorage.setItem("s31", "\"section " + subactcontent03 + "\"");

          subactcontent03 = "\"section " + subactcontent03 + "\" AND ";


          // //('test1', subactcontent03);
        } else if ( subactcontent03[0] === "O" ) {
          subactcontent03 = subactcontent03.substr(6);

          // search highlight
          localStorage.setItem("s32", "\"order " + subactcontent03 + "\"");

          subactcontent03 = "\"order " + subactcontent03 + "\" AND ";

          // //('test1', subactcontent03);
        } else if ( subactcontent03[0] === "A" ) {
          subactcontent03 = subactcontent03.substr(8);

          // search highlight
          localStorage.setItem("s33", "\"article " + subactcontent03 + "\"");

          subactcontent03 = "\"article " + subactcontent03 + "\" AND ";

          // //('test1', subactcontent03);
        } else if ( subactcontent03[0] === "R" ) {
          subactcontent03 = subactcontent03.substr(8);

          // search highlight
          localStorage.setItem("s34", "\"rule " + subactcontent03 + "\"");

          subactcontent03 = "\"rule " + subactcontent03 + "\" AND ";

          // //('test1', subactcontent03);
        }
      }
      if (typeof this.subActContents[4] !== "undefined" ) {
        subactcontent04 = this.subActContents[4].trim();
        if ( subactcontent04[0] === "S" ) {
          // //('test');
          subactcontent04 = subactcontent04.substr(8);

          // search highlight
          localStorage.setItem("s35", "\"section " + subactcontent04 + "\"");

          subactcontent04 = "\"section " + subactcontent04 + "\" AND ";

          // //('test1', subactcontent04);
        } else if ( subactcontent04[0] === "O" ) {
          subactcontent04 = subactcontent04.substr(6);

          // search highlight
          localStorage.setItem("s36", "\"order " + subactcontent04 + "\"");

          subactcontent04 = "\"order " + subactcontent04 + "\" AND ";


          // //('test1', subactcontent04);
        } else if ( subactcontent04[0] === "A" ) {
          subactcontent04 = subactcontent04.substr(8);

          // search highlight
          localStorage.setItem("s37", "\"article " + subactcontent04 + "\"");

          subactcontent04 = "\"article " + subactcontent04 + "\" AND ";

          // //('test1', subactcontent04);
        } else if ( subactcontent04[0] === "R" ) {
          subactcontent04 = subactcontent04.substr(8);

          // search highlight
          localStorage.setItem("s38", "\"rule " + subactcontent04 + "\"");

          subactcontent04 = "\"rule " + subactcontent04 + "\" AND ";

          // //('test1', subactcontent04);
        }
      }
    }

    if ( this.subActContents1.length > 0 ) {
      if (typeof this.subActContents1[0] !== "undefined" ) {
        subactcontent10 = this.subActContents1[0].trim();
        if ( subactcontent10[0] === "S" ) {
          // //('test');
          subactcontent10 = subactcontent10.substr(8);

          // search highlight
          localStorage.setItem("s39", "\"section " + subactcontent10 + "\"");

          subactcontent10 = "\"section " + subactcontent10 + "\" AND ";

          // //('test1', subactcontent10);
        } else if ( subactcontent10[0] === "O" ) {
          subactcontent10 = subactcontent10.substr(6);

          // search highlight
          localStorage.setItem("s40", "\"order " + subactcontent10 + "\"");

          subactcontent10 = "\"order " + subactcontent10 + "\" AND ";

          // //('test1', subactcontent10);
        } else if ( subactcontent10[0] === "A" ) {
          subactcontent10 = subactcontent10.substr(8);

          // search highlight
          localStorage.setItem("s41", "\"article " + subactcontent10 + "\"");

          subactcontent10 = "\"article " + subactcontent10 + "\" AND ";

          // //('test1', subactcontent10);
        } else if ( subactcontent10[0] === "R" ) {
          subactcontent10 = subactcontent10.substr(8);

          // search highlight
          localStorage.setItem("s42", "\"rule " + subactcontent10 + "\"");

          subactcontent10 = "\"rule " + subactcontent10 + "\" AND ";

          // //('test1', subactcontent10);
        }
      }
      if (typeof this.subActContents1[1] !== "undefined" ) {
        subactcontent11 = this.subActContents1[1].trim();
        if ( subactcontent11[0] === "S" ) {
          // //('test');
          subactcontent11 = subactcontent11.substr(8);

          // search highlight
          localStorage.setItem("s43", "\"section " + subactcontent11 + "\"");

          subactcontent11 = "\"section " + subactcontent11 + "\" AND ";

          // //('test1', subactcontent11);
        } else if ( subactcontent11[0] === "O" ) {
          subactcontent11 = subactcontent11.substr(6);

          // search highlight
          localStorage.setItem("s44", "\"order " + subactcontent11 + "\"");

          subactcontent11 = "\"order " + subactcontent11 + "\" AND ";

          // //('test1', subactcontent11);
        } else if ( subactcontent11[0] === "A" ) {
          subactcontent11 = subactcontent11.substr(8);

          // search highlight
          localStorage.setItem("s45", "\"article " + subactcontent11 + "\"");

          subactcontent11 = "\"article " + subactcontent11 + "\" AND ";

          // //('test1', subactcontent11);
        } else if ( subactcontent11[0] === "R" ) {
          subactcontent11 = subactcontent11.substr(8);

          // search highlight
          localStorage.setItem("s46", "\"rule " + subactcontent11 + "\"");

          subactcontent11 = "\"rule " + subactcontent11 + "\" AND ";

          // //('test1', subactcontent11);
        }
      }
      if (typeof this.subActContents1[2] !== "undefined" ) {
        subactcontent12 = this.subActContents1[2].trim();
        if ( subactcontent12[0] === "S" ) {
          // //('test');
          subactcontent12 = subactcontent12.substr(8);

          // search highlight
          localStorage.setItem("s47", "\"section " + subactcontent12 + "\"");

          subactcontent12 = "\"section " + subactcontent12 + "\" AND ";

          // //('test1', subactcontent12);
        } else if ( subactcontent12[0] === "O" ) {
          subactcontent12 = subactcontent12.substr(6);

          // search highlight
          localStorage.setItem("s48", "\"order " + subactcontent12 + "\"");

          subactcontent12 = "\"order " + subactcontent12 + "\" AND ";


          // //('test1', subactcontent12);
        } else if ( subactcontent12[0] === "A" ) {
          subactcontent12 = subactcontent12.substr(8);

          // search highlight
          localStorage.setItem("s49", "\"article " + subactcontent12 + "\"");

          subactcontent12 = "\"article " + subactcontent12 + "\" AND ";

          // //('test1', subactcontent12);
        } else if ( subactcontent12[0] === "R" ) {
          subactcontent12 = subactcontent12.substr(8);

          // search highlight
          localStorage.setItem("s50", "\"rule " + subactcontent12 + "\"");

          subactcontent12 = "\"rule " + subactcontent12 + "\" AND ";

          // //('test1', subactcontent12);
        }
      }
      if (typeof this.subActContents1[3] !== "undefined" ) {
        subactcontent13 = this.subActContents1[3].trim();
        if ( subactcontent13[0] === "S" ) {
          // //('test');
          subactcontent13 = subactcontent13.substr(8);

          // search highlight
          localStorage.setItem("s51", "\"section " + subactcontent13 + "\"");

          subactcontent13 = "\"section " + subactcontent13 + "\" AND ";


          // //('test1', subactcontent13);
        } else if ( subactcontent13[0] === "O" ) {
          subactcontent13 = subactcontent13.substr(6);

          // search highlight
          localStorage.setItem("s52", "\"order " + subactcontent12 + "\"");

          subactcontent13 = "\"order " + subactcontent13 + "\" AND ";

          // //('test1', subactcontent13);

        } else if ( subactcontent13[0] === "A" ) {
          subactcontent13 = subactcontent13.substr(8);

          // search highlight
          localStorage.setItem("s53", "\"article " + subactcontent13 + "\"");

          subactcontent13 = "\"article " + subactcontent13 + "\" AND ";

          // //('test1', subactcontent13);
        } else if ( subactcontent13[0] === "R" ) {
          subactcontent13 = subactcontent13.substr(8);

          // search highlight
          localStorage.setItem("s54", "\"rule " + subactcontent13 + "\"");

          subactcontent13 = "\"rule " + subactcontent13 + "\" AND ";

          // //('test1', subactcontent13);
        }
      }
      if (typeof this.subActContents1[4] !== "undefined" ) {
        subactcontent14 = this.subActContents1[4].trim();
        if ( subactcontent14[0] === "S" ) {
          // //('test');
          subactcontent14 = subactcontent14.substr(8);

          // search highlight
          localStorage.setItem("s55", "\"section " + subactcontent14 + "\"");

          subactcontent14 = "\"section " + subactcontent14 + "\" AND ";

          // //('test1', subactcontent14);
        } else if ( subactcontent14[0] === "O" ) {
          subactcontent14 = subactcontent14.substr(6);

          // search highlight
          localStorage.setItem("s56", "\"order " + subactcontent14 + "\"");

          subactcontent14 = "\"order " + subactcontent14 + "\" AND ";

          // //('test1', subactcontent14);
        } else if ( subactcontent14[0] === "A" ) {
          subactcontent14 = subactcontent14.substr(8);

          // search highlight
          localStorage.setItem("s57", "\"article " + subactcontent14 + "\"");

          subactcontent14 = "\"article " + subactcontent14 + "\" AND ";

          // //('test1', subactcontent14);
        } else if ( subactcontent14[0] === "R" ) {
          subactcontent14 = subactcontent14.substr(8);

          // search highlight
          localStorage.setItem("s58", "\"rule " + subactcontent14 + "\"");

          subactcontent14 = "\"rule " + subactcontent14 + "\" AND ";

          // //('test1', subactcontent14);
        }
      }
    }

    if ( this.subActContents2.length > 0 ) {
      if (typeof this.subActContents2[0] !== "undefined" ) {
        subactcontent20 = this.subActContents2[0].trim();
        if ( subactcontent20[0] === "S" ) {
          // //('test');
          subactcontent20 = subactcontent20.substr(8);

          // search highlight
          localStorage.setItem("s59", "\"section " + subactcontent20 + "\"");

          subactcontent20 = "\"section " + subactcontent20 + "\" AND ";

          // //('test1', subactcontent20);
        } else if ( subactcontent20[0] === "O" ) {
          subactcontent20 = subactcontent20.substr(6);

          // search highlight
          localStorage.setItem("s60", "\"order " + subactcontent20 + "\"");

          subactcontent20 = "\"order " + subactcontent20 + "\" AND ";

          // //('test1', subactcontent20);
        } else if ( subactcontent20[0] === "A" ) {
          subactcontent20 = subactcontent20.substr(8);

          // search highlight
          localStorage.setItem("s61", "\"article " + subactcontent20 + "\"");

          subactcontent20 = "\"article " + subactcontent20 + "\" AND ";

          // //('test1', subactcontent20);
        } else if ( subactcontent20[0] === "R" ) {
          subactcontent20 = subactcontent20.substr(8);

          // search highlight
          localStorage.setItem("s62", "\"rule " + subactcontent20 + "\"");

          subactcontent20 = "\"rule " + subactcontent20 + "\" AND ";

          // //('test1', subactcontent20);
        }
      }
      if (typeof this.subActContents2[1] !== "undefined" ) {
        subactcontent21 = this.subActContents2[1].trim();
        if ( subactcontent21[0] === "S" ) {
          // //('test');
          subactcontent21 = subactcontent21.substr(8);

          // search highlight
          localStorage.setItem("s63", "\"section " + subactcontent21 + "\"");

          subactcontent21 = "\"section " + subactcontent21 + "\" AND ";

          // //('test1', subactcontent21);
        } else if ( subactcontent21[0] === "O" ) {
          subactcontent21 = subactcontent21.substr(6);

          // search highlight
          localStorage.setItem("s64", "\"order " + subactcontent21 + "\"");

          subactcontent21 = "\"order " + subactcontent21 + "\" AND ";

          // //('test1', subactcontent21);
        } else if ( subactcontent21[0] === "A" ) {
          subactcontent21 = subactcontent21.substr(8);

          // search highlight
          localStorage.setItem("s65", "\"article " + subactcontent21 + "\"");

          subactcontent21 = "\"article " + subactcontent21 + "\" AND ";

          // //('test1', subactcontent21);
        } else if ( subactcontent21[0] === "R" ) {
          subactcontent21 = subactcontent21.substr(8);

          // search highlight
          localStorage.setItem("s66", "\"rule " + subactcontent21 + "\"");

          subactcontent21 = "\"rule " + subactcontent21 + "\" AND ";

          // //('test1', subactcontent21);
        }
      }
      if (typeof this.subActContents2[2] !== "undefined" ) {
        subactcontent22 = this.subActContents2[2].trim();
        if ( subactcontent22[0] === "S" ) {
          // //('test');
          subactcontent22 = subactcontent22.substr(8);

          // search highlight
          localStorage.setItem("s67", "\"section " + subactcontent22 + "\"");

          subactcontent22 = "\"section " + subactcontent22 + "\" AND ";

          // //('test1', subactcontent22);
        } else if ( subactcontent22[0] === "O" ) {
          subactcontent22 = subactcontent22.substr(6);

          // search highlight
          localStorage.setItem("s68", "\"order " + subactcontent22 + "\"");

          subactcontent22 = "\"order " + subactcontent22 + "\" AND ";

          // //('test1', subactcontent22);
        } else if ( subactcontent22[0] === "A" ) {
          subactcontent22 = subactcontent22.substr(8);

          // search highlight
          localStorage.setItem("s69", "\"article " + subactcontent22 + "\"");

          subactcontent22 = "\"article " + subactcontent22 + "\" AND ";

          // //('test1', subactcontent22);
        } else if ( subactcontent22[0] === "R" ) {
          subactcontent22 = subactcontent22.substr(8);

          // search highlight
          localStorage.setItem("s70", "\"rule " + subactcontent22 + "\"");

          subactcontent22 = "\"rule " + subactcontent22 + "\" AND ";

          // //('test1', subactcontent22);
        }
      }
      if (typeof this.subActContents2[3] !== "undefined" ) {
        subactcontent23 = this.subActContents2[3].trim();
        if ( subactcontent23[0] === "S" ) {
          // //('test');
          subactcontent23 = subactcontent23.substr(8);

          // search highlight
          localStorage.setItem("s71", "\"section " + subactcontent23 + "\"");

          subactcontent23 = "\"section " + subactcontent23 + "\" AND ";

          // //('test1', subactcontent23);
        } else if ( subactcontent23[0] === "O" ) {
          subactcontent23 = subactcontent23.substr(6);

          // search highlight
          localStorage.setItem("s72", "\"order " + subactcontent23 + "\"");

          subactcontent23 = "\"order " + subactcontent23 + "\" AND ";

          // //('test1', subactcontent23);
        } else if ( subactcontent23[0] === "A" ) {
          subactcontent23 = subactcontent23.substr(8);

          // search highlight
          localStorage.setItem("s73", "\"article " + subactcontent23 + "\"");

          subactcontent23 = "\"article " + subactcontent23 + "\" AND ";

          // //('test1', subactcontent23);
        } else if ( subactcontent23[0] === "R" ) {
          subactcontent23 = subactcontent23.substr(8);

          // search highlight
          localStorage.setItem("s74", "\"rule " + subactcontent23 + "\"");

          subactcontent23 = "\"rule " + subactcontent23 + "\" AND ";

          // //('test1', subactcontent23);
        }
      }
      if (typeof this.subActContents2[4] !== "undefined" ) {
        subactcontent24 = this.subActContents2[4].trim();
        if ( subactcontent24[0] === "S" ) {
          // //('test');
          subactcontent24 = subactcontent24.substr(8);

          // search highlight
          localStorage.setItem("s75", "\"section " + subactcontent24 + "\"");

          subactcontent24 = "\"section " + subactcontent24 + "\" AND ";

          // //('test1', subactcontent24);
        } else if ( subactcontent24[0] === "O" ) {
          subactcontent24 = subactcontent24.substr(6);

          // search highlight
          localStorage.setItem("s76", "\"order " + subactcontent24 + "\"");

          subactcontent24 = "\"order " + subactcontent24 + "\" AND ";

          // //('test1', subactcontent24);
        } else if ( subactcontent24[0] === "A" ) {
          subactcontent24 = subactcontent24.substr(8);

          // search highlight
          localStorage.setItem("s77", "\"article " + subactcontent24 + "\"");

          subactcontent24 = "\"article " + subactcontent24 + "\" AND ";

          // //('test1', subactcontent24);
        } else if ( subactcontent24[0] === "R" ) {
          subactcontent24 = subactcontent24.substr(8);

          // search highlight
          localStorage.setItem("s78", "\"rule " + subactcontent24 + "\"");

          subactcontent24 = "\"rule " + subactcontent24 + "\" AND ";

          // //('test1', subactcontent24);
        }
      }
    }

    if ( typeof keyword !== "undefined" && keyword !== "") {
      if ( keyword === "*.*" || keyword[0] === ":" || keyword.slice(-1) === ":") {
        keywordError = true;
      } else {
        keywordError = false;
      }

      if ( keyword[0] === "\"" && keyword[keyword.length - 1] === "\"") {
        keyword = "(" + keyword + ") AND ";
      } else {
        if ( keyword.indexOf(" ") !== -1 ) {
          keyword = keyword.replace(/\s+/g, " AND "); // replacing space with '%20AND%20'
        }
        keyword = "(" + keyword + ") AND ";
      }
    }

    if ( typeof keyword1 !== "undefined" && keyword1 !== "") {
      if ( keyword1 === "*.*" || keyword1[0] === ":" || keyword1.slice(-1) === ":") {
        keyword1Error = true;
      } else {
        keyword1Error = false;
      }

      if ( keyword1[0] === "\"" && keyword1[keyword1.length - 1] === "\"") {
        keyword1 = "(" + keyword1 + ") AND ";
      } else {
        if ( keyword1.indexOf(" ") !== -1 ) {
          keyword1 = keyword1.replace(/\s+/g, " AND "); // replacing space with '%20AND%20'
        }
        keyword1 = "(" + keyword1 + ") AND ";
      }
    }

    if ( typeof keyword2 !== "undefined" && keyword2 !== "") {
      if ( keyword2 === "*.*" || keyword2[0] === ":" || keyword2.slice(-1) === ":") {
        keyword2Error = true;
      } else {
        keyword2Error = false;
      }

      if ( keyword2[0] === "\"" && keyword2[keyword2.length - 1] === "\"") {
        keyword2 = "(" + keyword2 + ") AND ";
      } else {
        if ( keyword2.indexOf(" ") !== -1 ) {
          keyword2 = keyword2.replace(/\s+/g, " AND "); // replacing space with '%20AND%20'
        }
        keyword2 = "(" + keyword2  + ") AND ";
      }
    }

    if ( typeof citation !== "undefined" && citation !== "") {
      if ( citation.slice(-1) === ":") {
        citationError = true;
      } else {
        citationError = false;
      }

      citation = "(Citation:\"" + citation1 + "\") AND (Citation:\"" + citation2 + "\") AND ";
      if ( this.citationJournal === "ALL MR SC" || this.citationJournal === "ALL MR (Cri) SC" || this.citationJournal === "NearLaw (Mumbai HC) SC" || this.citationJournal === "NearLaw (Mumbai HC) (Cri) SC" ) {
        citation = citation + "Citation:(S.C.) AND ";
      }
      // //('citation', citation);
    }

    if ( typeof headnote !== "undefined" && headnote !== "") {
      if ( headnote === "*.*" || headnote[0] === ":" || headnote.slice(-1) === ":") {
        headnoteError = true;
      } else {
        headnoteError = false;
      }

      if ( headnote[0] === "\"" && headnote[headnote.length - 1] === "\"") {
        headnote = "(HeadNote:" + headnote + ") AND ";
      } else {
        if ( headnote.indexOf(" ") !== -1 ) {
          headnote = headnote.replace(/\s+/g, " AND HeadNote:"); // replacing space with '%20AND%20'
        }
        headnote = "(HeadNote:" + headnote + ") AND ";
      }
    }

    if ( typeof headnote1 !== "undefined" && headnote1 !== "") {
      if ( headnote1 === "*.*" || headnote1[0] === ":" || headnote1.slice(-1) === ":") {
        headnote1Error = true;
      } else {
        headnote1Error = false;
      }

      if ( headnote1[0] === "\"" && headnote1[headnote1.length - 1] === "\"") {
        headnote1 = "(HeadNote:" + headnote1 + ") AND ";
      } else {
        if ( headnote1.indexOf(" ") !== -1 ) {
          headnote1 = headnote1.replace(/\s+/g, " AND HeadNote:"); // replacing space with '%20AND%20'
        }
        headnote1 = "(HeadNote:" + headnote1 + ") AND ";
      }
    }

    if ( typeof headnote2 !== "undefined" && headnote2 !== "") {
      if ( headnote2 === "*.*" || headnote2[0] === ":" || headnote2.slice(-1) === ":") {
        headnote2Error = true;
      } else {
        headnote2Error = false;
      }

      if ( headnote2[0] === "\"" && headnote2[headnote2.length - 1] === "\"") {
        headnote2 = "(HeadNote:" + headnote2 + ") AND ";
      } else {
        if ( headnote2.indexOf(" ") !== -1 ) {
          headnote2 = headnote2.replace(/\s+/g, " AND HeadNote:"); // replacing space with '%20AND%20'
        }
        headnote2 = "(HeadNote:" + headnote2  + ") AND ";
      }
    }

    if ( typeof act !== "undefined" && act !== "" ) {
      if ( act === "*.*" || act[0] === ":" || act.slice(-1) === ":") {
        actError = true;
      } else {
        actError = false;
      }
      if ( act === "Code of Civil Procedure" ) {
        act = "((\"Code of Civil Procedure\") OR (\"Civil Procedure Code\")) AND ";
      } else {
        act = "\"" + act + "\" AND ";
      }
    }

    if ( typeof act1 !== "undefined" && act1 !== "" ) {
      if ( act1 === "*.*" || act1[0] === ":" || act1.slice(-1) === ":") {
        act1Error = true;
      } else {
        act1Error = false;
      }
      if ( act1 === "Code of Civil Procedure" ) {
        act1 = "((\"Code of Civil Procedure\") OR (\"Civil Procedure Code\")) AND ";
      } else {
        act1 = "\"" + act1 + "\" AND ";
      }
      
    }

    if ( typeof act2 !== "undefined" && act2 !== "" ) {
      if ( act2 === "*.*" || act2[0] === ":" || act2.slice(-1) === ":") {
        act2Error = true;
      } else {
        act2Error = false;
      }
      if ( act2 === "Code of Civil Procedure" ) {
        act2 = "((\"Code of Civil Procedure\") OR (\"Civil Procedure Code\")) AND ";
      } else {
        act2 = "\"" + act2 + "\" AND ";
      }
      
    }

    if ( typeof judgeName !== "undefined" && judgeName !== "" ) {
      if ( judgeName === "*.*" || judgeName[0] === ":" || judgeName.slice(-1) === ":") {
        judgenameaError = true;
      } else {
        judgenameaError = false;
      }

      judgeName = "Judge_name:\"" + judgeName + "\" AND ";
      // //('judgename', judgeName);
    }

    if ( typeof partyname !== "undefined" && partyname !== "" ) {
      // //('select', this.partynameSelect);
      if ( partyname === "*.*" || partyname[0] === ":" || partyname.slice(-1) === ":") {
        partynameError = true;
      } else {
        partynameError = false;
      }
      if ( this.partynameSelect === "" || this.partynameSelect === "Any" ) {
        partyname = "((Respondent:\"" + partyname + "\") OR " + "(Petitioner:\"" + partyname + "\")) AND ";
      } else if ( this.partynameSelect === "Petitioner" ) {
        partyname = "(Petitioner:\"" + partyname + "\") AND ";
      } else if ( this.partynameSelect === "Respondent" ) {
        partyname = "(Respondent:\"" + partyname + "\") AND ";
      }
    }

    if ( typeof court !== "undefined" && court !== "" ) {
      if ( court === "Bombay High Court" ) {
        court = "MumbaiHC";
      } else if ( court === "Delhi High Court" ) {
        court = "DelhiHC";
      } else if ( court === "Gujarat High Court" ) {
        court = "GujaratHC";
      } else if ( court === "Kerala High Court" ) {
        court = "KeralaHC";
      } else if ( court === "Karnataka High Court" ) {
        court = "KarnatakaHC";
      } else if ( court === "Calcutta High Court" ) {
        court = "CalcuttaHC";
      } else if ( court === "Punjab High Court" ) {
        court = "PunjabHC";
      } else if ( court === "Allahabad High Court" ) {
        court = "AllahabadHC";
      } else if ( court === "Madhya Pradesh High Court" ) {
        court = "MpHC";
      } else if ( court === "Appellate Tribunal for Forfeited Property (ATFP)") {
        court = "ATFP";
      } else if ( court === "Board for Industrial & Financial Reconstruction (BIFR)" ) {
        court = "BIFR";
      } else if ( court === "Central Administrative tribunal (CAT)" ) {
        court = "CAT";
      } else if ( court === "Maharashtra Administrative Tribunal (MAT)" ) {
        court = "MAT";
      } else if ( court === "Customs Excise And Service Tax Appellate Tribunal" ) {
        court = "CESTAT";
      } else if ( court === "Debt Recovery Tribunal" ) {
        court = "DRT";
      } else if ( court === "Debt Recovery Appellate Tribunal" ) {
        court = "DRAT";
      } else if ( court === "Income Tax Appellate Tribunal" ) {
        court = "ITAT";
      } else if ( court === "Intellectual Property Appellate Board" ) {
        court = "IPAB";
      } else if ( court === "National Company Law Appellate Tribunal" ) {
        court = "NCLAT";
      } else if ( court === "National Consumer Dispute Redressal Commision" ) {
        court = "NCDRC";
      } else if ( court === "Mah. Consumer Dispute Redressal Commision" ) {
        court = "MCDRC";
      } else if ( court === "Railway claims Tribunal" ) {
        court = "RCT";
      } else if ( court === "Securities Appellate Tribunal" ) {
        court = "SAT";
      } else if ( court === "Maharashtra Real Estate Regulatory Authority" ) {
        court = "MHRERA";
      } else if ( court === "Mah. Cooperative Societies Appellate Court" ) {
        court = "MSCA";
      } else if ( court === "Motor Accident Claims Tribunal, Mumbai" ) {
        court = "MACT";
      } else if ( court === "Maharashtra Industrial/ Labour Courts" ) {
        court = "MILC";
      } else if ( court === "Maharashtra School Tribunal" ) {
        court = "MST";
      }
      court = "((Court:\"" + court + "\") OR (tribunal:\"" + court + "\"))";
    }

    if ( typeof court1 !== "undefined" && court1 !== "" ) {
      if ( court1 === "Bombay High Court" ) {
        court1 = "MumbaiHC";
      } else if ( court1 === "Delhi High Court" ) {
        court1 = "DelhiHC";
      } else if ( court1 === "Gujarat High Court" ) {
        court1 = "GujaratHC";
      } else if ( court1 === "Kerala High Court" ) {
        court1 = "KeralaHC";
      } else if ( court1 === "Karnataka High Court" ) {
        court1 = "KarnatakaHC";
      } else if ( court1 === "Calcutta High Court" ) {
        court1 = "CalcuttaHC";
      } else if ( court1 === "Punjab High Court" ) {
        court1 = "PunjabHC";
      } else if ( court1 === "Allahabad High Court" ) {
        court1 = "AllahabadHC";
      } else if ( court1 === "Madhya Pradesh High Court" ) {
        court1 = "MpHC";
      } else if ( court1 === "Appellate Tribunal for Forfeited Property (ATFP)") {
        court1 = "ATFP";
      } else if ( court1 === "Board for Industrial & Financial Reconstruction (BIFR)" ) {
        court1 = "BIFR";
      } else if ( court1 === "Central Administrative tribunal (CAT)" ) {
        court1 = "CAT";
      } else if ( court1 === "Maharashtra Administrative Tribunal (MAT)" ) {
        court1 = "MAT";
      } else if ( court1 === "Customs Excise And Service Tax Appellate Tribunal" ) {
        court1 = "CESTAT";
      } else if ( court1 === "Debt Recovery Tribunal" ) {
        court1 = "DRT";
      } else if ( court1 === "Debt Recovery Appellate Tribunal" ) {
        court1 = "DRAT";
      } else if ( court1 === "Income Tax Appellate Tribunal" ) {
        court1 = "ITAT";
      } else if ( court1 === "Intellectual Property Appellate Board" ) {
        court1 = "IPAB";
      } else if ( court1 === "National Company Law Appellate Tribunal" ) {
        court1 = "NCLAT";
      } else if ( court1 === "National Consumer Dispute Redressal Commision" ) {
        court1 = "NCDRC";
      } else if ( court1 === "Mah. Consumer Dispute Redressal Commision" ) {
        court1 = "MCDRC";
      } else if ( court1 === "Railway claims Tribunal" ) {
        court1 = "RCT";
      } else if ( court1 === "Securities Appellate Tribunal" ) {
        court1 = "SAT";
      } else if ( court1 === "Maharashtra Real Estate Regulatory Authority" ) {
        court1 = "MHRERA";
      } else if ( court1 === "Mah. Cooperative Societies Appellate Court" ) {
        court1 = "MSCA";
      } else if ( court1 === "Motor Accident Claims Tribunal, Mumbai" ) {
        court1 = "MACT";
      } else if ( court1 === "Maharashtra Industrial/ Labour Courts" ) {
        court1 = "MILC";
      } else if ( court1 === "Maharashtra School Tribunal" ) {
        court1 = "MST";
      }
      court1 = "((Court:\"" + court1 + "\") OR (tribunal:\"" + court1 + "\"))";
      court = court + " OR ";
    }

    if ( court !== "" && court1 !== "" ) {
      Courts = "(" + court + court1 + ") AND ";
      this.query = keyword + keyword1 + keyword2 + citation + headnote + headnote1 + headnote2 + act + subactcontent00 + subactcontent01 + subactcontent02 + 
          subactcontent03 + subactcontent04 + act1 + subactcontent10 + subactcontent11 + subactcontent12 + subactcontent13 + subactcontent14 + act2 + 
          subactcontent20 + subactcontent21 + subactcontent22 + subactcontent23 + subactcontent24 + judgeName + partyname + Courts;
      this.query = this.query.slice(0, -5);
    } else if ( court !== "" && court1 === "" ) {
      court = court + " AND ";
      this.query = keyword + keyword1 + keyword2 + citation + headnote + headnote1 + headnote2 + act + subactcontent00 + subactcontent01 + subactcontent02 + 
      subactcontent03 + subactcontent04 + act1 + subactcontent10 + subactcontent11 + subactcontent12 + subactcontent13 + subactcontent14 + act2 + 
      subactcontent20 + subactcontent21 + subactcontent22 + subactcontent23 + subactcontent24 + judgeName + partyname + court + court1;
      this.query = this.query.slice(0, -5);
    } else {
      this.query = keyword + keyword1 + keyword2 + citation + headnote + headnote1 + headnote2 + act + subactcontent00 + subactcontent01 + subactcontent02 + 
      subactcontent03 + subactcontent04 + act1 + subactcontent10 + subactcontent11 + subactcontent12 + subactcontent13 + subactcontent14 + act2 + 
      subactcontent20 + subactcontent21 + subactcontent22 + subactcontent23 + subactcontent24 + judgeName + partyname + court + court1;
      this.query = this.query.slice(0, -5);
    }

    this.query = this.query.replace("*", "");
    this.query = this.query.replace("?", "");
    this.query = this.query.replace("\\", "");

    // // console.log('query', this.query);
    // //('query', this.query);
    if ( typeof this.vizExpPanel !== "undefined" ) {
      this.vizExpPanel.close();
    }

    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.searchServce.search(this.query, this.usertype, 0).subscribe(data => {
          // //('data', data);
          this.advanceSearchSpinner = false;
          this.totalSearchResult = data.hits.total;
          for ( let i = 0; i < data.hits.hits.length; i++ ) {
            this.judgements.push(data.hits.hits[i]._source);
          }
          this.searchresultPB = false;
          // //('search result', this.judgements);
    
          // retaining appropriate boomark icon on page load
          setTimeout(() => {
            this.judgements.forEach(data => {
              let i = 0;
              i += 1;
              // ('i', i);
              const citation = data.Citation;
              // ('citation', citation);
              if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                // ('acive');
                const x = document.getElementById(citation + " active");
                // ('x', x);
                if ( x !== null ) {
                  x.style.display = "block";
                }
                
                const y = document.getElementById(citation + " inactive");
                // ('y', y);
                if ( y !== null ) {
                  y.style.display = "none";
                }
                
              } else {
                // ('inacive');
                const x = document.getElementById(citation + " active");
                // ('x', x);
                if ( x !== null ) {
                  x.style.display = "none";
                }
                
                const y = document.getElementById(citation + " inactive");
                // ('y', y);
                if ( y !== null ) {
                  y.style.display = "block";
                } 
              }
            });
          }, 100);
    
          // load first result
          this.isSingleJudg = true;
          if ( typeof data.hits.hits[0] !== "undefined" ) {
            this.singleJudg = data.hits.hits[0]._source;
          }
          
          // //('data', data.hits.hits[0]._source.headNote);
          this.searchresult = true;
          // calculate total pages
          const a = this.totalSearchResult / 15;
          if ( this.totalSearchResult % 15 === 0 ) {
            this.totalpages = a;
          } else if ( this.totalSearchResult % 15 > 0 ) {
            this.totalpages = a + 1;
          }
    
          // highlight
          this.highlight();
    
          if ( this.judgements.length === 0 ) {
            this.snackBar.open("No results!", "OK");
          } else if ( this.judgements.length > 0 ) {
            // vis code
            // document.getElementById('visid').style.height = '400px';
            // document.getElementById('visid').style.backgroundColor = 'aliceblue';
            // document.getElementById('visid').style.marginBottom = '20px';
    
            // this.searchresult = true;
    
            // for ( let y = 0; y < this.judgements.length; y++ ) {
            //   const citation = this.judgements[y].Citation;
            //   // mainnode(citation);
            //   this.searchServce.mainnode(citation).subscribe(mainnodedata => {
            //     if ( mainnodedata.length > 0 ) {
            //       for ( let z = 0; z < mainnodedata.length; z++) {
            //         delete mainnodedata[z]['_id'];
            //         nodes.add(mainnodedata[z]);
            //         const id = mainnodedata[z].id;
            //         idarray.push(id);
            //         // this.searchServce.link(id).subscribe(linkdata => {
            //         //   if( linkdata.length > 0 ) {
            //         //     // try {
            //         //       for (let a = 0; a  < linkdata.length; a++ ) {
            //         //         delete linkdata[a]['_id'];
            //         //         edges.add(linkdata[a]);
            //         //         let bincheck = 0;
            //         //         let binchecktarget = 0;
            //         //         for (let x = 0; x < mainnodedata.length; x++) {
            //         //           if (parseInt(mainnodedata[x].id) !== parseInt(linkdata[a].from))
            //         //           {
            //         //               bincheck = 1;
            //         //           }
            //         //           else {
            //         //               break;
            //         //           }
            //         //           if (parseInt(mainnodedata[x].id) !== parseInt(linkdata[a].to))
            //         //           {
            //         //               binchecktarget = 1;
            //         //           }
            //         //           else{
            //         //               break;
            //         //           }
            //         //         }
            //         //         if (bincheck == 1)
            //         //         {
            //         //             // addnodes(linkdata[a].from);
            //         //             this.searchServce.findByNodeId(linkdata[a].from).subscribe(nodedata => {
            //         //               for(var k=0;k<nodedata.length;k++){
            //         //                 // // // //("NODEDATA");
            //         //                 // // // //(nodedata);
            //         //                 // delete nodedata[k]["color"];
            //         //                 delete nodedata[k]["_id"];
            //         //                 nodes.add(nodedata[k]);
            //         //               }
            //         //             });
            //         //         }
            //         //         if (binchecktarget == 1)
            //         //         {
            //         //             // addnodes(linkdata[a].to);
            //         //             this.searchServce.findByNodeId(linkdata[a].to).subscribe(nodedata => {
            //         //               for(var k=0;k<nodedata.length;k++){
            //         //                 // // // //("NODEDATA");
            //         //                 // // // //(nodedata);
            //         //                 // delete nodedata[k]["color"];
            //         //                 delete nodedata[k]["_id"];
            //         //                 nodes.add(nodedata[k]);
            //         //               }
            //         //             });
            //         //         }
            //         //       }
            //         //     // }
            //         //     // catch(e){
            //         //     //   // alert(e.message);
            //         //     // }
            //         //   }
            //         // });
            //       }
            //     }
            //   });
            // }
          }
        });
      });
    });
    
    // var container = document.getElementById('visid');
    // var data = {
    //     nodes: nodes,
    //     edges: edges
    // };
    // var options = {
                
    //   edges: {
    //     font: {
    //       size: 16
    //     },
    //     selfReferenceSize: 30
    //   },
    //   nodes: {
    //     shape: 'dot',
    //     // color : '#1b41b7',
    //     font: {
    //       size: 13
    //     }
    //   },
    //   physics: {
    //       "enabled": true,
    //       forceAtlas2Based: {
    //           gravitationalConstant: -26,
    //           centralGravity: 0.005,
    //           springLength: 230,
    //           springConstant: 0.18
    //           },
    //       maxVelocity: 146,
    //       solver: 'forceAtlas2Based',
    //       timestep: 0.35,
    //       stabilization: {iterations: 150}
    //       },
    //   interaction: {
    //     navigationButtons: true,
    //     hover: true,
    //     keyboard: false
    //   }
    // };
    // let viscaseprofileDialogRef = this.viscaseprofileModal;
    // const cookieService = this.cookieService;
    // var network = new Network(container, data, options);
    // network.on('click',function(params){
    //   // // //(params.nodes[0]);
    //   if (typeof params.nodes[0] != 'undefined')
    //   {
    //     var node=data.nodes.get(params.nodes[0]);
    //     // //(node);
        
    //     localStorage.setItem('viscaseprof', JSON.stringify(node));
    //     cookieService.set('viscaseprof', JSON.stringify(node));
    //     viscaseprofileDialogRef.open(VisCaseprofileComponent, {
    //       width: '850px',
    //       height: '550px'
    //     });
    //   }
    // });
  }

  userBoomarks(judgement) {
    // (judgement);
    const citation = judgement.Citation;
    // // // console.log('citation', judgement.Citation);
    // ('user', this.user);
    if ( this.user.bookmarks.find((cit) => cit === citation) === undefined ) {      // to check if case is already bookarked
      if ( this.user.bookmarks[0] === "" ) {
        this.user.bookmarks.splice(0, 1);
      }
      if ( this.user.usertype === "Paid" || this.user.usertype === "MPP" ) {                                        // Paid user can add unlimited bookmarks
        this.user.bookmarks.push(citation);
        // ('user', this.user);
        // const token = localStorage.getItem('JWT');
        const token = this.cookieService.get("JWT");
        // const id = localStorage.getItem('ID');
        const id = this.cookieService.get("ID");
        this.authService.checkJWTtoken().then(() => {
          timer(3500).subscribe(() => {
            this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
              this.user = userdata.result;
              // ('userdata', this.user);
              // this.isBookmarked = true;
              const x = document.getElementById(citation + " active");
              x.style.display = "block";
              const y = document.getElementById(citation + " inactive");
              y.style.display = "none";
              this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                duration: 3000
              });
            });
          });
        });
        
      } else if ( this.user.usertype === "Trial" || this.user.usertype === "Free" ) {   // Trial and Free users can add only 5 bookmarks
        if ( this.user.bookmarks.length < 5 ) {
          this.user.bookmarks.push(citation);
          // ('user', this.user);
          // const token = localStorage.getItem('JWT');
          const token = this.cookieService.get("JWT");
          // const id = localStorage.getItem('ID');
          const id = this.cookieService.get("ID");
          this.authService.checkJWTtoken().then(() => {
            timer(3500).subscribe(() => {
              this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
                this.user = userdata.result;
                // ('userdata', this.user);
                // this.isBookmarked = true;
                const x = document.getElementById(citation + " active");
                x.style.display = "block";
                const y = document.getElementById(citation + " inactive");
                y.style.display = "none";
                this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                  duration: 3000
                });
              });
            });
          });
          
        } else {
          const upgradeSnackbar = this.snackBar.open("To save more than 5 bookmarks, please Upgrade to premium plan!", "Upgrade", {
            duration: 3000
          });
          upgradeSnackbar.onAction().subscribe(() => {
            this.router.navigate(["/paymentgateway"]);
          });
        }
      }
    } else {
      // this.snackBar.open('Already Bookmarked!', 'OK', {
      //   duration: 3000
      // });
      this.user.bookmarks.splice(this.user.bookmarks.indexOf(citation), 1);
      // ('after delete', this.user);
      // const token = localStorage.getItem('JWT');
      const token = this.cookieService.get("JWT");
      // const id = localStorage.getItem('ID');
      const id = this.cookieService.get("ID");
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
            this.user = userdata.result;
            // ('userdata', this.user);
            // this.isBookmarked = false;
            const x = document.getElementById(citation + " active");
            x.style.display = "none";
            const y = document.getElementById(citation + " inactive");
            y.style.display = "block";
            this.snackBar.open("Successfully removed from Bookmarks!", "OK", {
              duration: 3000
            });
          });
        });
      });
      
    }
  }

  openAnnotationModal() {
    const loginDialogRef = this.annotationModal.open(AnnotationComponent, {
      width: "400px",
      height: "400px"
    });
  }

  userAnnotations(judgement) {
    const citation = judgement.Citation;
    // // // console.log('annotation', this.user);
    if ((this.user.annotations.find((annotation) => annotation.citation === citation) === undefined) || (this.user.annotations.length === 0)) {
      // localStorage.setItem('cit', citation);
      this.cookieService.set("cit", citation);
      this.openAnnotationModal();
      
    } else {
      this.user.annotations.forEach((annotation) => {
        if ( annotation.citation === citation ) {
          const cit = annotation.citation;
          const body = annotation.body;
          // localStorage.setItem('cit', cit);
          this.cookieService.set("cit", cit);
          // localStorage.setItem('body', body);
          this.cookieService.set("body", body);
          this.openAnnotationModal();
        }
      });
    }
  }

  onReset() {
    this.keywords = [];
    this.headnotes = [];
    this.partynames = [];
    this.courts = [];
    this.judgename = "";

    this.actname = "";
    this.act1name = "";
    this.act2name = "";
    this.actContent = "";
    this.actContent1 = "";
    this.actContent2 = "";
    this.subActContents = [];
    this.subActContents1 = [];
    this.subActContents2 = [];

    this.act1Visible = false;
    this.act2Visible = false;

    this.citationYear = "";
    this.citationVolume = "";
    this.citationJournal = "";
    this.citationPageNo = "";
  }

  clearAnyKeywords() {
    this.keywords = []; this.anykeyword = "";
  }

  clearCitation() {
    this.citationJournal = ""; this.citationPageNo = ""; this.citationVolume = ""; this.citationYear = "";
  }

  clearHeadnote() {
    this.headnotes = []; this.headNote = "";
  }

  clearActs() {
    this.actname = "";
    this.act1name = "";
    this.act2name = "";
    this.actContent = "";
    this.actContent1 = "";
    this.actContent2 = "";
    this.subActContents = [];
    this.subActContents1 = [];
    this.subActContents2 = [];

    this.act1Visible = false;
    this.act2Visible = false;
  }

  clearJudgename() {
    this.judgename = "";
  }

  clearPartyName() {
    this.partynames = [];
  }

  clearCourt() {
    this.courts = [];
  }

  // addInSearchList(event) {
  //   const input = event.input;
  //   const value = event.value;
  //   // //('length', this.keywords.length);
  //   if (this.additiveSearch.length < 6) {

  //     if ( this.keywordChecked === true ) {
  //       if ((value || '').trim()) {
  //         this.keywords.push('"' + value.trim() + '"');
  //         this.keywordChecked = false;
  //       }
  //     } else if  ( this.keywordChecked === false ) {
  //       if ((value || '').trim()) {
  //         this.keywords.push(value.trim());
  //       }
  //     }

  //     // //('keywords', this.keywords);
  //     // Reset the input value
  //     if (input) {
  //       input.value = '';
  //     }


  //   } else if (this.keywords.length >= 3) {
  //     this.snackBar.open('You cannot add more than three keywords to search in Full judgment!', 'OK', {
  //       duration: 5000
  //     });
  //   }
  // }

  removeSearchList(keyword): void {
    // var nodes = new DataSet();
    // var edges = new DataSet();
    // var idarray = [];
    let i = 0;
    this.query = "";

    // search query highlight
    // // // console.log('ql', this.additiveSearch.length);
    // // // console.log('qa', this.query);

    // if ( this.additiveSearch.length === 1 ) {
    //   localStorage.removeItem('s1');
    // } else if ( this.additiveSearch.length === 2 ) {
    //   localStorage.removeItem('s2');
    // } else if ( this.additiveSearch.length === 3 ) {
    //   localStorage.removeItem('s3');
    // } else if ( this.additiveSearch.length === 4 ) {
    //   localStorage.removeItem('s4');
    // } else if ( this.additiveSearch.length === 5 ) {
    //   localStorage.removeItem('s5');
    // } else if ( this.additiveSearch.length === 6 ) {
    //   localStorage.removeItem('s6');
    // }
    // ends here



    const index = this.additiveSearch.indexOf(keyword);

    if (index >= 0) {
      this.additiveSearch.splice(index, 1);
      localStorage.removeItem("s" + (index + 1).toString());
    }
    // // // console.log('additive search', this.additiveSearch);
    if ( this.additiveSearch.length > 0 ) {
    this.additiveSearch.forEach((query: string) => {
      this.query = this.query + "%20AND%20" + this.additiveSearch[i];
      i += 1;
    });
    let temp = "";
    if ( this.query[0] === "%" ) {
      temp = this.query.substring(9);
      this.query = temp;
    }
    // // // console.log('query after remove', this.query);
    this.searchresultPB = true;
    if ( typeof this.vizExpPanel !== "undefined" ) {
      this.vizExpPanel.close();
    }

    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.searchServce.search(this.query, this.usertype, 0).subscribe( data => {
          // //('data', data);
          this.isexact = false;
          
          this.totalSearchResult = data.hits.total;
          for ( let i = 0; i < data.hits.hits.length; i++ ) {
            this.judgements.push(data.hits.hits[i]._source);
          }
    
          // highlight
          this.highlight();
    
        // retaining appropriate boomark icon on page load
        setTimeout(() => {
        //   this.judgements.forEach(data =>{
        //     let i = 0;
        //     i += 1;
        //     //('i', i);
            const citation = data.hits.hits[0]._source.Citation;
            // // // console.log('citation', citation);
            // // // console.log('test', this.user.bookmarks.find((cit) => cit === citation))
            if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
              // ('acive');
              const x = document.getElementById(citation + " active");
              // ('x', x);
              if ( x !== null ) {
                x.style.display = "block";
              }
              
              const y = document.getElementById(citation + " inactive");
              // ('y', y);
              if ( y !== null ) {
                y.style.display = "none";
              }
              
            } else {
              // ('inacive');
              const x = document.getElementById(citation + " active");
              // ('x', x);
              if ( x !== null ) {
                x.style.display = "none";
              }
              
              const y = document.getElementById(citation + " inactive");
              // ('y', y);
              if ( y !== null ) {
                y.style.display = "block";
              } 
            }
        //   });
        }, 100);
    
        // retaining appropriate annotation icon on page load
        setTimeout(() => {
        //   this.judgements.forEach(data =>{
        //     let i = 0;
        //     i += 1;
        //     //('i', i);
            const citation = data.hits.hits[0]._source.Citation;
            // ('citation', citation);
            if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
              // ('acive');
              const x = document.getElementById(citation + " annotation active");
              // ('x', x);
              if ( x !== null ) {
                x.style.display = "block";
              }
              
              const y = document.getElementById(citation + " annotation inactive");
              // ('y', y);
              if ( y !== null ) {
                y.style.display = "none";
              }
              
            } else {
              // ('inacive');
              const x = document.getElementById(citation + " annotation active");
              // ('x', x);
              if ( x !== null ) {
                x.style.display = "none";
              }
              
              const y = document.getElementById(citation + " annotation inactive");
              // ('y', y);
              if ( y !== null ) {
                y.style.display = "block";
              }
              
             
            }
        //   });
        }, 200);
    
          this.searchresultPB = false;
          // //('search result', this.judgements);
    
          this.searchresult = true;
    
          // load first data
          this.isSingleJudg = true;
          this.singleJudg = data.hits.hits[0]._source;
          // ('singlejudg', this.singleJudg);
    
          // calculate total pages
          const a = this.totalSearchResult / 15;
          if ( this.totalSearchResult % 15 === 0 ) {
            this.totalpages = a;
          } else if ( this.totalSearchResult % 15 > 0 ) {
            this.totalpages = a + 1;
          }
    
          if ( this.judgements.length === 0 ) {
            this.snackBar.open("No results!", "OK");
          } else if ( this.judgements.length > 0 ) {
            // vis code
            // document.getElementById('visid').style.height = '400px';
            // document.getElementById('visid').style.backgroundColor = 'aliceblue';
            // document.getElementById('visid').style.marginBottom = '20px';
    
            // this.searchresult = true;
    
            // for ( let y = 0; y < this.judgements.length; y++ ) {
            //   const citation = this.judgements[y].Citation;
            //   // mainnode(citation);
            //   this.searchServce.mainnode(citation).subscribe(mainnodedata => {
            //     if ( mainnodedata.length > 0 ) {
            //       for ( let z = 0; z < mainnodedata.length; z++) {
            //         delete mainnodedata[z]['_id'];
            //         nodes.add(mainnodedata[z]);
            //         const id = mainnodedata[z].id;
            //         idarray.push(id);
            //         // this.searchServce.link(id).subscribe(linkdata => {
            //         //   if( linkdata.length > 0 ) {
            //         //     // try {
            //         //       for (let a = 0; a  < linkdata.length; a++ ) {
            //         //         delete linkdata[a]['_id'];
            //         //         edges.add(linkdata[a]);
            //         //         let bincheck = 0;
            //         //         let binchecktarget = 0;
            //         //         for (let x = 0; x < mainnodedata.length; x++) {
            //         //           if (parseInt(mainnodedata[x].id) !== parseInt(linkdata[a].from))
            //         //           {
            //         //               bincheck = 1;
            //         //           }
            //         //           else {
            //         //               break;
            //         //           }
            //         //           if (parseInt(mainnodedata[x].id) !== parseInt(linkdata[a].to))
            //         //           {
            //         //               binchecktarget = 1;
            //         //           }
            //         //           else{
            //         //               break;
            //         //           }
            //         //         }
            //         //         if (bincheck == 1)
            //         //         {
            //         //             // addnodes(linkdata[a].from);
            //         //             this.searchServce.findByNodeId(linkdata[a].from).subscribe(nodedata => {
            //         //               for(var k=0;k<nodedata.length;k++){
            //         //                 // // // //("NODEDATA");
            //         //                 // // // //(nodedata);
            //         //                 // delete nodedata[k]["color"];
            //         //                 delete nodedata[k]["_id"];
            //         //                 nodes.add(nodedata[k]);
            //         //               }
            //         //             });
            //         //         }
            //         //         if (binchecktarget == 1)
            //         //         {
            //         //             // addnodes(linkdata[a].to);
            //         //             this.searchServce.findByNodeId(linkdata[a].to).subscribe(nodedata => {
            //         //               for(var k=0;k<nodedata.length;k++){
            //         //                 // // // //("NODEDATA");
            //         //                 // // // //(nodedata);
            //         //                 // delete nodedata[k]["color"];
            //         //                 delete nodedata[k]["_id"];
            //         //                 nodes.add(nodedata[k]);
            //         //               }
            //         //             });
            //         //         }
            //         //       }
            //         //     // }
            //         //     // catch(e){
            //         //     //   // alert(e.message);
            //         //     // }
            //         //   }
            //         // });
            //       }
            //     }
            //   });
            // }
          }

        });
      });
    });
    
    // abc();

    // try{
      // var container = document.getElementById('visid');
      // var data = {
      //     nodes: nodes,
      //     edges: edges
      // };
      // var options = {      
      //   edges: {
      //     font: {
      //       size: 16
      //     },
      //     selfReferenceSize: 30
      //   },
      //   nodes: {
      //     shape: 'dot',
      //     // color : '#1b41b7',
      //     font: {
      //       size: 13
      //     }
      //   },
      //   physics: {
      //       "enabled": true,
      //       forceAtlas2Based: {
      //           gravitationalConstant: -26,
      //           centralGravity: 0.005,
      //           springLength: 230,
      //           springConstant: 0.18
      //           },
      //       maxVelocity: 146,
      //       solver: 'forceAtlas2Based',
      //       timestep: 0.35,
      //       stabilization: {
      //         iterations: 150
      //       }
      //       },
      //   interaction: {
      //     navigationButtons: true,
      //     hover: true,
      //     keyboard: false
      //   }
      // };
      // let viscaseprofileDialogRef = this.viscaseprofileModal;
      // var network = new Network(container, data, options);
      // window.onresize = function() {
      //   setInterval(function() {
      //     network.fit();
      //   }, 2000);
      // };
      // const cookieService = this.cookieService;
      // network.on('click',function(params){
      //   // // //(params.nodes[0]);
        
      //   if (typeof params.nodes[0] != 'undefined')
      //   {
      //     var node=data.nodes.get(params.nodes[0]);
      //     // //(node);
          
      //     // localStorage.setItem('viscaseprof', JSON.stringify(node));
      //     cookieService.set('viscaseprof', JSON.stringify(node));

      //     viscaseprofileDialogRef.open(VisCaseprofileComponent, {
      //       width: '850px',
      //       height: '550px'
      //     });
      //   }
      // });
    }
  }
}

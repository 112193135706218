import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { Action } from "@ngrx/store";
import { Router } from "@angular/router";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { tap, map, switchMap, catchError } from "rxjs/operators";

import { AuthService } from "../../services/auth.service";
import { CookieService } from "ngx-cookie-service";

import { User } from "../../shared/user";
import { baseDomain } from "../../shared/baseurl";

import { LoginComponent } from "../../login/login.component";

import { LogIn, AuthActionTypes, LogInSuccess, LogInFailure, SignUp, SignUpSuccess, SignUpFailure } from "../actions/auth.actions";


@Injectable()
export class AuthEffects {

  user = new User();
  baseDomain = baseDomain;

  constructor(
    private actions: Actions,
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    // public loginModal: MatDialogRef<LoginComponent>,
    public signupModal: MatDialog,
    public snackBar: MatSnackBar,
  ) { }

  // effects go here
  @Effect()
  LogIn: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN),
    map((action: LogIn) => action.payload),
    switchMap(payload => {
      return this.authService.login(payload).pipe(
        map((data) => {
          // console.log('data', data);
          if (data.success === true) {
            return new LogInSuccess({
              token: data.token,
              user: data.user,
              tokenExpiry: data.expiryToken,
              refreshToken: data.refreshToken,
              refreshTokenExpiry: data.expiryRefreshToken
            });
          } else if (data.success === false && data.status === "wrong otp") {
            // console.log('yo');
            // this.snackBar.open('')
            return new LogInFailure({ error: data.msg });
          }
        }),
        catchError((error) => {
          console.log("error", error);
          return of(new LogInFailure({ error: error }));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((data) => {
      // console.log("data", data.payload);
      this.user = data.payload.user;
      // console.log('user', data.payload.user);
      this.cookieService.set("JWT", data.payload.token, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("username", data.payload.user.username, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("contactno", data.payload.user.contactno, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("refreshToken", data.payload.refreshToken, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("expToken", data.payload.tokenExpiry, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("expiryRefreshToken", data.payload.refreshTokenExpiry, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("ID", data.payload.user._id, 30, "/", baseDomain, false, "Lax");
      const trialexpirystring = data.payload.user.trialexpirydate;
      const trialexpirydate = new Date(trialexpirystring);
      let expirydate;

      const currentdateiso = Date.now();
      const currentdate = new Date(currentdateiso);
      if (typeof data.payload.user.expirydate !== null) {
        const expirystring = data.payload.user.expirydate;
        expirydate = new Date(expirystring);
      }
      if ((currentdate > trialexpirydate) && data.payload.user.usertype === "Trial") {
        this.user.usertype = "Free";
        this.authService.updateUserWithToken(data.payload.user._id, this.user, data.payload.token).subscribe(result => {
          this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain, false, "Lax");
          this.router.navigate(["/browse"]).then(() => {
            this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
              duration: 3000
            });
          });
        });
      } else if ((currentdate <= trialexpirydate) && data.payload.user.usertype === "Trial") {
        this.cookieService.set("usertype", data.payload.user.usertype, 30, "/", baseDomain);
        this.router.navigate(["/browse"]).then(() => {
          this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
            duration: 3000
          });
        });
      } else if (data.payload.user.usertype === "Free") {
        this.cookieService.set("usertype", data.payload.user.usertype, 30, "/", baseDomain, false, "Lax");
        this.router.navigate(["/browse"]).then(() => {
          this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
            duration: 3000
          });
        });
       
      } else if (typeof data.payload.user.expirydate !== null && (expirydate <= currentdate) && (data.payload.user.usertype === "Paid" || data.payload.user.usertype === "MPP")) {
        this.user.usertype = "Free";
        this.user.paidflag = false;
        this.user.amount = "";
        this.authService.updateUserWithToken(data.payload.user._id, this.user, data.payload.token).subscribe(result => {
          // localStorage.setItem('usertype', result.usertype);
          this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain, false, "Lax");
          // this.loginModal.close();
          this.router.navigate(["/browse"]).then(() => {
            this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
              duration: 3000
            });
          });
        });
     
      } else if (typeof data.payload.user.expirydate !== null && (expirydate > currentdate) && (data.payload.user.usertype === "Paid" || data.payload.user.usertype === "MPP")) {
        // localStorage.setItem('usertype', data.payload.user.usertype);
        this.cookieService.set("usertype", data.payload.user.usertype, 30, "/", baseDomain, false, "Lax");
        // this.loginModal.close();
        this.router.navigate(["/browse"]).then(() => {
          this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
            duration: 3000
          });
        });
      }
    })
  );

  @Effect({ dispatch: false })
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE)
  );

  @Effect()
  SignUp: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SIGNUP),
    map((action: SignUp) => action.payload),
    switchMap(payload => {
      return this.authService.signup(payload).pipe(
        map((data) => {
          // console.log('data', data);
          if (data.success === true) {
            return new SignUpSuccess({
              token: data.token,
              user: data.user,
              tokenExpiry: data.expiryToken,
              refreshToken: data.refreshToken,
              refreshTokenExpiry: data.expiryRefreshToken
            });
          } else if (data.success === false) {
            return new SignUpFailure({ error: data.msg });
          }
        }),
        catchError((error) => {
          // console.log('error', error);
          return of(new SignUpFailure({ error: error }));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  SignUpSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SIGNUP_SUCCESS),
    tap((data) => {
      // console.log('data', data.payload);
      this.user = data.payload.user;
      // console.log('user', data.payload.user);
      this.cookieService.set("JWT", data.payload.token, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("username", data.payload.user.username, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("contactno", data.payload.user.contactno, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("refreshToken", data.payload.refreshToken, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("expToken", data.payload.tokenExpiry, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("expiryRefreshToken", data.payload.refreshTokenExpiry, 30, "/", baseDomain, false, "Lax");
      this.cookieService.set("ID", data.payload.user._id, 30, "/", baseDomain, false, "Lax");
      const trialexpirystring = data.payload.user.trialexpirydate;
      const trialexpirydate = new Date(trialexpirystring);
      let expirydate;
      // console.log('trial expiry date', trialexpirydate);
      // let trialexpiryday = trialexpirydate.getDate();
      // console.log('trial', trialexpirydate);
      const currentdateiso = Date.now();
      const currentdate = new Date(currentdateiso);
      // console.log('current date', currentdate);
      // let currentday = currentdate.getDate();
      if (typeof data.payload.user.expirydate !== null) {
        const expirystring = data.payload.user.expirydate;
        expirydate = new Date(expirystring);
      }
      if ((currentdate > trialexpirydate) && data.payload.user.usertype === "Trial") {
        // console.log('1');
        this.user.usertype = "Free";
        this.authService.updateUserWithToken(data.payload.user._id, this.user, data.payload.token).subscribe(result => {
          // console.log('result', result);
          // localStorage.setItem('usertype', result.usertype);
          this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain, false, "Lax");
          // localStorage.setItem('ted', JSON.stringify(Date.now() + 10368000000));
          // this.loginModal.close();
          this.router.navigate(["/browse"]).then(() => {
            this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
              duration: 3000
            });
          });
        });
      } else if ((currentdate <= trialexpirydate) && data.payload.user.usertype === "Trial") {
        // console.log('2');
        // localStorage.setItem('usertype', data.payload.user.usertype);
        this.cookieService.set("usertype", data.payload.user.usertype, 30, "/", baseDomain, false, "Lax");
        // this.loginModal.close();
        this.router.navigate(["/browse"]).then(() => {
          this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
            duration: 3000
          });
        });
      } else if (data.payload.user.usertype === "Free") {
        // localStorage.setItem('usertype', data.payload.user.usertype);
        this.cookieService.set("usertype", data.payload.user.usertype, 30, "/", baseDomain, false, "Lax");
        // this.loginModal.close();
        this.router.navigate(["/browse"]).then(() => {
          this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
            duration: 3000
          });
        });
     
      } else if (typeof data.payload.user.expirydate !== null && (expirydate <= currentdate) && (data.payload.user.usertype === "Paid" || data.payload.user.usertype === "MPP")) {
        this.user.usertype = "Free";
        this.user.paidflag = false;
        this.user.amount = "";
        this.authService.updateUserWithToken(data.payload.user._id, this.user, data.payload.token).subscribe(result => {
          // localStorage.setItem('usertype', result.usertype);
          this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain);
          // this.loginModal.close();
          this.router.navigate(["/browse"]).then(() => {
            this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
              duration: 3000
            });
          });
        });
     
      } else if (typeof data.payload.user.expirydate !== null && (expirydate > currentdate) && (data.payload.user.usertype === "Paid" || data.payload.user.usertype === "MPP")) {
        // localStorage.setItem('usertype', data.payload.user.usertype);
        this.cookieService.set("usertype", data.payload.user.usertype, 30, "/", baseDomain, false, "Lax");
        // this.loginModal.close();
        this.router.navigate(["/browse"]).then(() => {
          this.snackBar.open("Welcome to nearlaw, " + data.payload.user.username + "!", "OK", {
            duration: 3000
          });
        });
      }
    })
  );

  @Effect({ dispatch: false })
  SignUpFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.SIGNUP_FAILURE)
  );
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HomeRegistrationComponent } from './home-registration/home-registration.component';
import { HomepageComponent } from './homepage/homepage.component';
import { WebfeaturesComponent } from './webfeatures/webfeatures.component';
import { AppfeaturesComponent } from './appfeatures/appfeatures.component';
import { BrowseComponent } from './browse/browse.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PaymentgatewayComponent } from './paymentgateway/paymentgateway.component';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { SearchComponent } from './search/search.component';
import { TypesComponent } from './types/types.component';
import { LatestjudgComponent } from './latestjudg/latestjudg.component';
import { LandmarkjudgComponent } from './landmarkjudg/landmarkjudg.component';
import { ImpjudgComponent } from './impjudg/impjudg.component';
import { OftenjudgComponent } from './oftenjudg/oftenjudg.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'homeregistration',
    component: HomeRegistrationComponent
    // children: [
    //   { path: 'webfeatures', component: WebfeaturesComponent },
    //   { path: 'appfeatures', component: AppfeaturesComponent }
    // ]
  },
  {
    path: 'homepage',
    component: HomepageComponent,
  },
  {path: 'paymentgateway', component: PaymentgatewayComponent},
  {path: 'browse', component: BrowseComponent, canActivate: [AuthGuard], data: { animation: '1' }},
  {path: 'about', component: AboutusComponent},
  {path: 'paymentresponse', component: PaymentResponseComponent},
  {path: 'profile', component: UserprofileComponent, canActivate: [AuthGuard]},
  {path: 'search', component: SearchComponent, canActivate: [AuthGuard], data: { animation: '2' }},
  // {path: 'judgementtypes', component: TypesComponent},
  {path: 'latestjudgments', component: LatestjudgComponent, canActivate: [AuthGuard], data: { animation: '3' }},
  {path: 'landmarkjudgments', component: LandmarkjudgComponent, canActivate: [AuthGuard], data: { animation: '4' }},
  {path: 'oftencitedjudgments', component: OftenjudgComponent, canActivate: [AuthGuard], data: { animation: '5' }},
  {path: 'importantjudgments', component: ImpjudgComponent, canActivate: [AuthGuard], data: { animation: '6' }},
  // {path: '404', component: PagenotfoundComponent},
  {path: '**', redirectTo: '/homepage', pathMatch: 'full'}
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  scrollOffset: [0, 64]
  // ...any other options you'd like to use
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions), InfiniteScrollModule],
  exports: [RouterModule, InfiniteScrollModule]
})
export class AppRoutingModule { }

export const acts = [
    {value : 'Absorbed Areas (Laws) Act',view : 'Absorbed Areas (Laws) Act, 1954'}, 
    {value : 'Academy of Scientific and Innovation Research Act',view : 'Academy of Scientific and Innovation Research Act, 2012'}, 
    {value : 'Acquired Territories (Merger) Act',view : 'Acquired Territories (Merger) Act, 1960'}, 
    {value : 'Acquisition of Certain Area at Ayodhya Act',view : 'Acquisition of Certain Area at Ayodhya Act, 1993'}, 
    {value : 'Additional Duties of Excise (Goods of Special Importance) Act',view : 'Additional Duties of Excise (Goods of Special Importance) Act, 1957'}, 
    {value : 'Additional Duties of Excise (Textiles and Textile Articles) Act',view : 'Additional Duties of Excise (Textiles and Textile Articles) Act, 1978'}, 
    {value : 'Additional Emoluments (Compulsory Deposit) Act',view : 'Additional Emoluments (Compulsory Deposit) Act, 1974'}, 
    {value : 'Administrative Tribunals Act',view : 'Administrative Tribunals Act, 1985'}, 
    {value : 'Administrative Tribunal (Amendment) Act',view : 'Administrative Tribunal (Amendment) Act, 1986'}, 
    {value : 'Administrators-General Act',view : 'Administrators-General Act, 1963'}, 
    {value : 'Advocates Act',view : 'Advocates Act, 1961'}, 
    {value : 'Advocates Welfare Fund Act',view : 'Advocates Welfare Fund Act, 2001'}, 
    {value : 'African Development Bank Act',view : 'African Development Bank Act, 1983'}, 
    {value : 'African Development Fund Act',view : 'African Development Fund Act, 1982'}, 
    {value : 'Agricultural and Processed Food Products Export Development Authority Act',view : 'Agricultural and Processed Food Products Export Development Authority Act, 1986'}, 
    {value : 'Agricultural Produce (Grading and Marking) Act',view : 'Agricultural Produce (Grading and Marking) Act, 1937'}, 
    {value : 'Agriculturists\' Loans Act',view : 'Agriculturists\' Loans Act, 1884'}, 
    {value : 'Aircraft Act',view : 'Aircraft Act, 1934'}, 
    {value : 'Airports Authority of India Act',view : 'Airports Authority of India Act, 1994'}, 
    {value : 'Airports Economic Regulatory Authority of India Act',view : 'Airports Economic Regulatory Authority of India Act, 2008'}, 
    {value : 'Air Corporations (Transfer of Undertakings and Repeal) Act',view : 'Air Corporations (Transfer of Undertakings and Repeal) Act, 1994'}, 
    {value : 'Air Force Act',view : 'Air Force Act, 1950'}, 
    {value : 'Air (Prevention and Control of Pollution) Act',view : 'Air (Prevention and Control of Pollution) Act, 1981'}, 
    {value : 'Air (Prevention and Control of Pollution) Amendment Act',view : 'Air (Prevention and Control of Pollution) Amendment Act, 1987'}, 
    {value : 'Ajmer Tenancy and Land Records Act',view : 'Ajmer Tenancy and Land Records Act, 1950'}, 
    {value : 'Alcock Ashdown Company Limited (Acquisition of Undertakings) Act',view : 'Alcock Ashdown Company Limited (Acquisition of Undertakings) Act, 1973'}, 
    {value : 'Aligarh Muslim University Act',view : 'Aligarh Muslim University Act, 1920'}, 
    {value : 'All-India Council for Technical Education Act',view : 'All-India Council for Technical Education Act, 1987'}, 
    {value : 'All-India Institute of Medical Sciences Act',view : 'All-India Institute of Medical Sciences Act, 1956'}, 
    {value : 'All-India Services Act',view : 'All-India Services Act, 1951'}, 
    {value : 'All-India Services Regulations (Indemnity) Act',view : 'All-India Services Regulations (Indemnity) Act, 1975'}, 
    {value : 'Aluminium Corporation of India Limited (Acquisition and Transfer of Aluminium) Undertaking Act',view : 'Aluminium Corporation of India Limited (Acquisition and Transfer of Aluminium) Undertaking Act, 1984'}, 
    {value : 'Amending Act',view : 'Amending Act, 1897'}, 
    {value : 'Amending Act',view : 'Amending Act, 1901'}, 
    {value : 'Amending Act',view : 'Amending Act, 1903'}, 
    {value : 'Amritsar Oil Works (Acquisition and Transfer ofUndertakings) Act',view : 'Amritsar Oil Works (Acquisition and Transfer ofUndertakings) Act, 1982'}, 
    {value : 'Anand Marriage Act',view : 'Anand Marriage Act, 1909'}, 
    {value : 'Ancient Monuments and Archaeological Sites and Remains Act',view : 'Ancient Monuments and Archaeological Sites and Remains Act, 1958'}, 
    {value : 'Ancient Monuments Preservation Act',view : 'Ancient Monuments Preservation Act, 1904'}, 
    {value : 'Andhra Pradesh and Madras (Alteration ofBoundaries) Act',view : 'Andhra Pradesh and Madras (Alteration ofBoundaries) Act, 1959'}, 
    {value : 'Andhra Pradesh and Mysore (Transfer of Territory) Act',view : 'Andhra Pradesh and Mysore (Transfer of Territory) Act, 1968'}, 
    {value : 'Andhra Pradesh Legislative Council Act, ',view : 'Andhra Pradesh Legislative Council Act, '}, 
    {value : 'Andhra Pradesh Reorganisation Act',view : 'Andhra Pradesh Reorganisation Act, 2014'}, 
    {value : 'Andhra Scientific Company Limited (Acquisition and Transfer of Undertakings) Act, ',view : 'Andhra Scientific Company Limited (Acquisition and Transfer of Undertakings) Act, '}, 
    {value : 'AndhraState Act',view : 'AndhraState Act, 1953'}, 
    {value : 'Anti-Apartheid (United Nations Convention) Act',view : 'Anti-Apartheid (United Nations Convention) Act, 1981'}, 
    {value : 'Anti-Corruption Laws (Amendment) Act',view : 'Anti-Corruption Laws (Amendment) Act, 1967'}, 
    {value : 'Anti-Hijacking Act',view : 'Anti-Hijacking Act, 1982'}, 
    {value : 'Antiquities and Art Treasures Act',view : 'Antiquities and Art Treasures Act, 1972'}, 
    {value : 'Apprentices Act',view : 'Apprentices Act, 1961'}, 
    {value : 'Arbitration and Conciliation Act',view : 'Arbitration and Conciliation Act, 1996'}, 
    {value : 'Architects Act',view : 'Architects Act, 1972'}, 
    {value : 'Armed Forces (Emergency Duties) Act',view : 'Armed Forces (Emergency Duties) Act, 1947'}, 
    {value : 'Armed Forces (Jammu and Kashmir) Special Powers Act',view : 'Armed Forces (Jammu and Kashmir) Special Powers Act, 1990'}, 
    {value : 'Armed Forces (Punjab and Chandigarh) Special Powers Act',view : 'Armed Forces (Punjab and Chandigarh) Special Powers Act, 1983'}, 
    {value : 'Armed Forces (Special Powers) Act',view : 'Armed Forces (Special Powers) Act, 1958'}, 
    {value : 'Armed Forces Tribunal Act',view : 'Armed Forces Tribunal Act, 2007'}, 
    {value : 'Arms Act',view : 'Arms Act, 1959'}, 
    {value : 'Army Act',view : 'Army Act, 1950'}, 
    {value : 'Army and Air Force (Disposal of Private Property) Act',view : 'Army and Air Force (Disposal of Private Property) Act, 1950'}, 
    {value : 'Arya Marriage Validation Act',view : 'Arya Marriage Validation Act, 1937'}, 
    {value : 'Asian Development Bank Act',view : 'Asian Development Bank Act, 1966'}, 
    {value : 'Asian Refractories Limited (Acquisition of Undertakings) Act',view : 'Asian Refractories Limited (Acquisition of Undertakings) Act, 1971'}, 
    {value : 'Asiatic Society Act',view : 'Asiatic Society Act, 1984'}, 
    {value : 'Assam (Alteration of Boundaries) Act',view : 'Assam (Alteration of Boundaries) Act, 1951'}, 
    {value : 'Assam Criminal Law Amendment (Supplementary) Act',view : 'Assam Criminal Law Amendment (Supplementary) Act, 1934'}, 
    {value : 'Assam Municipal (Manipur Amendment) Act',view : 'Assam Municipal (Manipur Amendment) Act, 1961'}, 
    {value : 'Assam Reorganisation (Meghalaya) Act',view : 'Assam Reorganisation (Meghalaya) Act, 1969'}, 
    {value : 'Assam Rifles Act',view : 'Assam Rifles Act, 1941'}, 
    {value : 'Assam Rifle Act',view : 'Assam Rifle Act, 2006'}, 
    {value : 'Assam Sillimanite Limited (Acquisition and Transfer of Refractory Plant) Act',view : 'Assam Sillimanite Limited (Acquisition and Transfer of Refractory Plant) Act, 1976'}, 
    {value : 'Assam University Act',view : 'Assam University Act, 1989'}, 
    {value : 'Atomic Energy Act',view : 'Atomic Energy Act, 1962'}, 
    {value : 'Actquaries Act',view : 'Actquaries Act, 2006'}, 
    {value : 'Auroville Foundation Act',view : 'Auroville Foundation Act, 1988'}, 
    {value : 'Authoritative Texts (Central Laws) Act',view : 'Authoritative Texts (Central Laws) Act, 1973'}, 
    {value : 'Babasaheb Bhimrao Ambedkar University Act',view : 'Babasaheb Bhimrao Ambedkar University Act, 1994'}, 
    {value : 'Banaras Hindu University Act',view : 'Banaras Hindu University Act, 1915'}, 
    {value : 'Bangalore Marriages Validating Act',view : 'Bangalore Marriages Validating Act, 1936'}, 
    {value : 'Bankers\' Books Evidence Act',view : 'Bankers\' Books Evidence Act, 1891'}, 
    {value : 'Banking Companies (Acquisition and Transfer of Undertakings) Act',view : 'Banking Companies (Acquisition and Transfer of Undertakings) Act, 1970'}, 
    {value : 'Banking Companies (Acquisition and Transfer of Undertakings) Act',view : 'Banking Companies (Acquisition and Transfer of Undertakings) Act, 1980'}, 
    {value : 'Banking Laws (Application to Co-operative Societies) Act',view : 'Banking Laws (Application to Co-operative Societies) Act, 1965'}, 
    {value : 'Banking Laws (Amendment) Act',view : 'Banking Laws (Amendment) Act, 1985'}, 
    {value : 'Banking Regulation Act',view : 'Banking Regulation Act, 1949'}, 
    {value : 'Bar Councils (Validation of State Laws) Act',view : 'Bar Councils (Validation of State Laws) Act, 1956'}, 
    {value : 'Beedi and Cigar Workers (Conditions of Employment) Act',view : 'Beedi and Cigar Workers (Conditions of Employment) Act, 1966'}, 
    {value : 'Beedi and Cigar Workers (Conditions of Employment) Amendment Act',view : 'Beedi and Cigar Workers (Conditions of Employment) Amendment Act, 1993'}, 
    {value : 'Beedi Workers Welfare Cess Act',view : 'Beedi Workers Welfare Cess Act, 1976'}, 
    {value : 'Beedi Workers Welfare Fund Act',view : 'Beedi Workers Welfare Fund Act, 1976'}, 
    {value : 'Benami Transactions (Prohibition) Act',view : 'Benami Transactions (Prohibition) Act, 1988'}, 
    {value : 'Bengal Alluvion and Diluvion Act',view : 'Bengal Alluvion and Diluvion Act, 1847'}, 
    {value : 'Bengal Bonded Warehouse Association Act',view : 'Bengal Bonded Warehouse Association Act, 1838'}, 
    {value : 'Bengal Bonded Warehouse Association Act',view : 'Bengal Bonded Warehouse Association Act, 1854'}, 
    {value : 'Bengal Chaukidari Act',view : 'Bengal Chaukidari Act, 1856'}, 
    {value : 'Bengal Chemical and Pharmaceutical Works Limited (Acquisition and Transfer of Undertakings) Act',view : 'Bengal Chemical and Pharmaceutical Works Limited (Acquisition and Transfer of Undertakings) Act, 1980'}, 
    {value : 'Bengal Criminal Law Amendment (Supplementary) Act',view : 'Bengal Criminal Law Amendment (Supplementary) Act, 1925'}, 
    {value : 'Bengal Districts Act',view : 'Bengal Districts Act, 1836'}, 
    {value : 'Bengal Embankment Act',view : 'Bengal Embankment Act, 1855'}, 
    {value : 'Bengal Finance (Sales Tax) (Delhi Validation of Appointments and Proceedings) Act',view : 'Bengal Finance (Sales Tax) (Delhi Validation of Appointments and Proceedings) Act, 1971'}, 
    {value : 'Bengal Ghatwali Lands Act',view : 'Bengal Ghatwali Lands Act, 1859'}, 
    {value : 'Bengal Immunity Company Limited (Acquisition and Transfer of Undertakings) Act',view : 'Bengal Immunity Company Limited (Acquisition and Transfer of Undertakings) Act, 1984'}, 
    {value : 'Bengal Indigo Contracts Act',view : 'Bengal Indigo Contracts Act, 1836'}, 
    {value : 'BengalLand Holders\' Attendance Act',view : 'BengalLand Holders\' Attendance Act, 1848'}, 
    {value : 'Bengal Land Revenue Sales Act',view : 'Bengal Land Revenue Sales Act, 1841'}, 
    {value : 'BengalLand Revenue Sales Act',view : 'BengalLand Revenue Sales Act, 1859'}, 
    {value : 'Bengal Military Police Act',view : 'Bengal Military Police Act, 1892'}, 
    {value : 'Bengal Rent Act',view : 'Bengal Rent Act, 1859'}, 
    {value : 'Bengal Suppression of Terrorist Outrages (Supplementary) Act',view : 'Bengal Suppression of Terrorist Outrages (Supplementary) Act, 1932'}, 
    {value : 'Bengal Tenancy Act',view : 'Bengal Tenancy Act, 1885'}, 
    {value : 'Berar Laws Act',view : 'Berar Laws Act, 1941'}, 
    {value : 'BetwaRiver Board (Amendment) Act',view : 'BetwaRiver Board (Amendment) Act, 1993'}, 
    {value : 'Betwa River Board Act',view : 'Betwa River Board Act, 1976'}, 
    {value : 'Bharat Petroleum Corporation Limited (Determination of Conditions of Service of Employees) Act',view : 'Bharat Petroleum Corporation Limited (Determination of Conditions of Service of Employees) Act, 1988'}, 
    {value : 'Bhopal Gas Leak Disaster (Processing of Claims) Act',view : 'Bhopal Gas Leak Disaster (Processing of Claims) Act, 1985'}, 
    {value : 'Bihar Land Reforms Laws (Regulating Mines and Minerals) Validation Act',view : 'Bihar Land Reforms Laws (Regulating Mines and Minerals) Validation Act, 1969'}, 
    {value : 'Bihar Reorganisation Act',view : 'Bihar Reorganisation Act, 1925'}, 
    {value : 'Bihar and Uttar Pradesh (Alteration of Boundaries) Act',view : 'Bihar and Uttar Pradesh (Alteration of Boundaries) Act, 1968'}, 
    {value : 'Bihar Value Added Tax Act',view : 'Bihar Value Added Tax Act, 2005'}, 
    {value : 'Bihar and West Bengal (Transfer of Territories) Act',view : 'Bihar and West Bengal (Transfer of Territories) Act, 1956'}, 
    {value : 'Bikrama Singh\'s Estates Act',view : 'Bikrama Singh\'s Estates Act, 1883'}, 
    {value : 'Biological Diversity Act',view : 'Biological Diversity Act, 1925'}, 
    {value : 'Bird and Company Limited (Acquisition and Transfer of Undertakings and Other Properties) Act',view : 'Bird and Company Limited (Acquisition and Transfer of Undertakings and Other Properties) Act, 1980'}, 
    {value : 'Bolani Ores Limited (Acquisition of Shares) and Miscellaneous Provisions Act',view : 'Bolani Ores Limited (Acquisition of Shares) and Miscellaneous Provisions Act, 1978'}, 
    {value : 'Bombay Civil Courts Act',view : 'Bombay Civil Courts Act, 1869'}, 
    {value : 'Bombay Municipal Debentures Act',view : 'Bombay Municipal Debentures Act, 1876'}, 
    {value : 'Bombay Public Security Measures (Delhi Amendment) Act',view : 'Bombay Public Security Measures (Delhi Amendment) Act, 1948'}, 
    {value : 'Bombay Rent-free Estates Act',view : 'Bombay Rent-free Estates Act, 1852'}, 
    {value : 'Bombay Reorganisation Act',view : 'Bombay Reorganisation Act, 1960'}, 
    {value : 'Bombay Revenue Jurisdiction Act',view : 'Bombay Revenue Jurisdiction Act, 1876'}, 
    {value : 'Bonded Labour System (Abolition) Act',view : 'Bonded Labour System (Abolition) Act, 1976'}, 
    {value : 'Border Security Force Act',view : 'Border Security Force Act, 1968'}, 
    {value : 'Brahmaputra Board Act',view : 'Brahmaputra Board Act, 1980'}, 
    {value : 'Braithwaite and Company (India) Limited(Acquisition and Transfer of Undertakings) Act',view : 'Braithwaite and Company (India) Limited(Acquisition and Transfer of Undertakings) Act, 1976'}, 
    {value : 'Brentford Electric (India) Limited (Acquisitionand Transfer of Undertakings) Act',view : 'Brentford Electric (India) Limited (Acquisitionand Transfer of Undertakings) Act, 1987'}, 
    {value : 'Britannia Engineering Company Limited (Mokameh Unit) and theArthur Butler and Company (Muzaffarpore) Limited (Acquisition and Transferof Undertakings) Act',view : 'Britannia Engineering Company Limited (Mokameh Unit) and theArthur Butler and Company (Muzaffarpore) Limited (Acquisition and Transferof Undertakings) Act, 1978'}, 
    {value : 'British India Corporation Limited (Acquisition of Shares) Act',view : 'British India Corporation Limited (Acquisition of Shares) Act, 1981'}, 
    {value : 'British Statutes (Application to India) Repeal Act',view : 'British Statutes (Application to India) Repeal Act, 1960'}, 
    {value : 'Broach and KairaIncumbered Estates Act',view : 'Broach and KairaIncumbered Estates Act, 1877'}, 
    {value : 'Bronze Coin (Legal Tender) Act',view : 'Bronze Coin (Legal Tender) Act, 1918'}, 
    {value : 'Building and Other Construction Workers (Regulation of Employment and Conditions ofService) Act',view : 'Building and Other Construction Workers (Regulation of Employment and Conditions ofService) Act, 1996'}, 
    {value : 'Building and Other Construction Workers\' Welfare Cess Act',view : 'Building and Other Construction Workers\' Welfare Cess Act, 1996'}, 
    {value : 'Bureau of Indian Standards Act',view : 'Bureau of Indian Standards Act, 1986'}, 
    {value : 'Burmah Oil Company [Acquisition of Shares of Oil India Limited and of the Undertakings in India of Assam Oil Company Limited and the Burmah Oil Company (India Trading) Limited] Act',view : 'Burmah Oil Company [Acquisition of Shares of Oil India Limited and of the Undertakings in India of Assam Oil Company Limited and the Burmah Oil Company (India Trading) Limited] Act, 1981'}, 
    {value : 'Burmah Shell (Acquisition of Undertakings in India) Act',view : 'Burmah Shell (Acquisition of Undertakings in India) Act, 1976'}, 
    {value : 'Burn Company and Indian Standard Wagon Company (Nationalisation) Act',view : 'Burn Company and Indian Standard Wagon Company (Nationalisation) Act, 1976'}, 
    {value : 'Cable Television Networks (Regulation) Act',view : 'Cable Television Networks (Regulation) Act, 1995'}, 
    {value : 'Calcutta High Court (Extension of Jurisdiction) Act',view : 'Calcutta High Court (Extension of Jurisdiction) Act, 1953'}, 
    {value : 'Calcutta High Court (Jurisdictional Limits) Act',view : 'Calcutta High Court (Jurisdictional Limits) Act, 1919'}, 
    {value : 'Calcutta Land-revenue Act',view : 'Calcutta Land-revenue Act, 1850'}, 
    {value : 'Calcutta Land-revenue Act',view : 'Calcutta Land-revenue Act, 1856'}, 
    {value : 'Calcutta Metro Railway (Operation and Maintenance) Temporary Provisions Act',view : 'Calcutta Metro Railway (Operation and Maintenance) Temporary Provisions Act, 1985'}, 
    {value : 'Calcutta Pilots Act',view : 'Calcutta Pilots Act, 1859'}, 
    {value : 'Calcutta Port (Pilotage) Act',view : 'Calcutta Port (Pilotage) Act, 1948'}, 
    {value : 'Caltex [Acquisition of Shares of Caltex Oil Refining (India) Limited and of the Undertakings in India of Caltex (India) Limited] Act',view : 'Caltex [Acquisition of Shares of Caltex Oil Refining (India) Limited and of the Undertakings in India of Caltex (India) Limited] Act, 1977'}, 
    {value : 'Cantonments (Extension of Rent Control Laws) Act',view : 'Cantonments (Extension of Rent Control Laws) Act, 1957'}, 
    {value : 'Cantonments (House Accommodation) Act',view : 'Cantonments (House Accommodation) Act, 1923'}, 
    {value : 'Cantonments Act',view : 'Cantonments Act, 1924'}, 
    {value : 'Cantonments Act',view : 'Cantonments Act, 2006'}, 
    {value : 'Capital of Punjab Development and Regulation (Chandigarh Amendment) Act',view : 'Capital of Punjab Development and Regulation (Chandigarh Amendment) Act, 1973'}, 
    {value : 'Cardamom Act',view : 'Cardamom Act, 1965'}, 
    {value : 'Carriage by Air Act',view : 'Carriage by Air Act, 1972'}, 
    {value : 'Carriage by Air (Amendment) Act',view : 'Carriage by Air (Amendment) Act, 2016'}, 
    {value : 'Carriage by Road Act',view : 'Carriage by Road Act, 2007'}, 
    {value : 'Carriers Act',view : 'Carriers Act, 1865'}, 
    {value : 'Caste Disabilities Removal Act',view : 'Caste Disabilities Removal Act, 1850'}, 
    {value : 'Cattle-trespass Act',view : 'Cattle-trespass Act, 1871'}, 
    {value : 'Census Act',view : 'Census Act, 1948'}, 
    {value : 'Central Agricultural University Act',view : 'Central Agricultural University Act, 1992'}, 
    {value : 'Central Boards of Revenue Act',view : 'Central Boards of Revenue Act, 1963'}, 
    {value : 'Central Educational Institutions (Reservation in Admission) Act',view : 'Central Educational Institutions (Reservation in Admission) Act, 1944'}, 
    {value : 'Central Excise Tariff Act',view : 'Central Excise Tariff Act, 1985'}, 
    {value : 'Central Excises (Conversion to Metric Units) Act',view : 'Central Excises (Conversion to Metric Units) Act, 1960'}, 
    {value : 'Central Industrial Security Force (Amendment and Validation) Act',view : 'Central Industrial Security Force (Amendment and Validation) Act, 1999'}, 
    {value : 'Central Industrial Security Force Act',view : 'Central Industrial Security Force Act, 1968'}, 
    {value : 'Central Labour Laws (Extension to Jammu and Kashmir) Act',view : 'Central Labour Laws (Extension to Jammu and Kashmir) Act, 1970'}, 
    {value : 'Central Laws (Extension to Arunachal Pradesh) Act',view : 'Central Laws (Extension to Arunachal Pradesh) Act, 1993'}, 
    {value : 'Central Laws (Extension to Jammu and Kashmir) Act',view : 'Central Laws (Extension to Jammu and Kashmir) Act, 1968'}, 
    {value : 'Central Provinces Court of Wards Act',view : 'Central Provinces Court of Wards Act, 1899'}, 
    {value : 'Central Provinces Financial Commissioner\'s Act',view : 'Central Provinces Financial Commissioner\'s Act, 1908'}, 
    {value : 'Central Provinces Land-revenue Act',view : 'Central Provinces Land-revenue Act, 1881'}, 
    {value : 'Central Provinces Laws Act',view : 'Central Provinces Laws Act, 1875'}, 
    {value : 'Central Provinces Tenancy Act',view : 'Central Provinces Tenancy Act, 1898'}, 
    {value : 'Central Road Fund Act',view : 'Central Road Fund Act, 2000'}, 
    {value : 'Central Reserve Police Force Act',view : 'Central Reserve Police Force Act, 1949'}, 
    {value : 'Central Sales Tax (Amendment) Act',view : 'Central Sales Tax (Amendment) Act, 1969'}, 
    {value : 'Central Sales Tax Act',view : 'Central Sales Tax Act, 1956'}, 
    {value : 'Central Silk Board Act',view : 'Central Silk Board Act, 1948'}, 
    {value : 'Central Universities Act',view : 'Central Universities Act, 2009'}, 
    {value : 'Central Universities (Amendment) Act',view : 'Central Universities (Amendment) Act, 2014'}, 
    {value : 'Central Vigilance Commission Act',view : 'Central Vigilance Commission Act, 2003'}, 
    {value : 'Cess and Other Taxes on Minerals (Validation) Act',view : 'Cess and Other Taxes on Minerals (Validation) Act, 1992'}, 
    {value : 'Chandernagore (Merger) Act',view : 'Chandernagore (Merger) Act, 1954'}, 
    {value : 'Chandigarh (Delegation of Powers) Act',view : 'Chandigarh (Delegation of Powers) Act, 1987'}, 
    {value : 'Chandigarh Disturbed Areas Act',view : 'Chandigarh Disturbed Areas Act, 1983'}, 
    {value : 'Chaparmukh Silghat Railway Line and the Katakhal Lalabazar Railway Line (Nationalisation) Act',view : 'Chaparmukh Silghat Railway Line and the Katakhal Lalabazar Railway Line (Nationalisation) Act, 1982'}, 
    {value : 'Charitable Endowments Act',view : 'Charitable Endowments Act, 1890'}, 
    {value : 'Charitable and Religious Trusts Act',view : 'Charitable and Religious Trusts Act, 1920'}, 
    {value : 'Chartered Accountants Act',view : 'Chartered Accountants Act, 1949'}, 
    {value : 'Chemical Weapons Convention Act',view : 'Chemical Weapons Convention Act, 2000'}, 
    {value : 'Child Labour (Prohibition and Regulation) Act',view : 'Child Labour (Prohibition and Regulation) Act, 1986'}, 
    {value : 'Children (Pledging of Labour) Act',view : 'Children (Pledging of Labour) Act, 1933'}, 
    {value : 'Chit Funds Act',view : 'Chit Funds Act, 1982'}, 
    {value : 'Chota Nagpur Encumbered Estates Act',view : 'Chota Nagpur Encumbered Estates Act, 1876'}, 
    {value : 'Church of Scotland Kirk Sessions Act',view : 'Church of Scotland Kirk Sessions Act, 1899'}, 
    {value : 'Cigarettes and Other Tobacco Products (Prohibition of Advertisement and Regulation of Trade and Commerce, Production, Supply and Distribution) Act',view : 'Cigarettes and Other Tobacco Products (Prohibition of Advertisement and Regulation of Trade and Commerce, Production, Supply and Distribution) Act, 2003'}, 
    {value : 'Cinematograph Act',view : 'Cinematograph Act, 1918'}, 
    {value : 'Cinematograph Act',view : 'Cinematograph Act, 1952'}, 
    {value : 'Cine-Workers and Cinema Theatre Workers(Regulation of Employment) Act',view : 'Cine-Workers and Cinema Theatre Workers(Regulation of Employment) Act, 1981'}, 
    {value : 'Cine-Workers Welfare Cess Act',view : 'Cine-Workers Welfare Cess Act, 1981'}, 
    {value : 'Cine-Workers Welfare Fund Act',view : 'Cine-Workers Welfare Fund Act, 1981'}, 
    {value : 'Citizenship Act',view : 'Citizenship Act, 1955'}, 
    {value : 'City of Bombay Municipal (Supplementary) Act',view : 'City of Bombay Municipal (Supplementary) Act, 1888'}, 
    {value : 'Civil Defence Act',view : 'Civil Defence Act, 1968'}, 
    {value : 'Civil Liability for Nuclear Damage Act',view : 'Civil Liability for Nuclear Damage Act, 2010'}, 
    {value : 'Clinical Establishment (Registration and Regulation) Act',view : 'Clinical Establishment (Registration and Regulation) Act, 2010'}, 
    {value : 'Coal Bearing Areas (Acquisition and Development) Act',view : 'Coal Bearing Areas (Acquisition and Development) Act, 1957'}, 
    {value : 'Coal Bearing Areas (Acquisition and Development) Amendment and Validation Act',view : 'Coal Bearing Areas (Acquisition and Development) Amendment and Validation Act, 1971'}, 
    {value : 'Coal India (Regulation of Transfers and Validation) Act',view : 'Coal India (Regulation of Transfers and Validation) Act, 2000'}, 
    {value : 'Coal Mines (Conservation and Development) Act',view : 'Coal Mines (Conservation and Development) Act, 1974'}, 
    {value : 'Coal Mines (Nationalisation) Act',view : 'Coal Mines (Nationalisation) Act, 1973'}, 
    {value : 'Coal Mines Labour Welfare Fund (Repeal) Act',view : 'Coal Mines Labour Welfare Fund (Repeal) Act, 1986'}, 
    {value : 'Coal Mines Provident Fund and Miscellaneous Provisions Act',view : 'Coal Mines Provident Fund and Miscellaneous Provisions Act, 1948'}, 
    {value : 'Coal Mines (Taking Over of Management) Act',view : 'Coal Mines (Taking Over of Management) Act, 1973'}, 
    {value : 'Coastal Aquaculture Authority Act',view : 'Coastal Aquaculture Authority Act, 2005'}, 
    {value : 'Coast Guard Act',view : 'Coast Guard Act, 1978'}, 
    {value : 'Coasting Vessels Act',view : 'Coasting Vessels Act, 1838'}, 
    {value : 'Coconut Development Board Act',view : 'Coconut Development Board Act, 1979'}, 
    {value : 'Code of Civil Procedure',view : 'Code of Civil Procedure, 1908'}, 
    {value : 'Code of Criminal Procedure',view : 'Code of Criminal Procedure, 1973'}, 
    {value : 'Coffee Act',view : 'Coffee Act, 1942'}, 
    {value : 'Coinage Act',view : 'Coinage Act, 2011'}, 
    {value : 'Coir Industry Act',view : 'Coir Industry Act, 1953'}, 
    {value : 'Coking Coal Mines (Emergency Provisions) Act',view : 'Coking Coal Mines (Emergency Provisions) Act, 1971'}, 
    {value : 'Coking Coal Mines (Nationalisation) Act',view : 'Coking Coal Mines (Nationalisation) Act, 1972'}, 
    {value : 'Collection of Statistics Act',view : 'Collection of Statistics Act, 1953'}, 
    {value : 'Collection of Statistics Act',view : 'Collection of Statistics Act, 2009'}, 
    {value : 'Colonial Courts of Admiralty (India) Act',view : 'Colonial Courts of Admiralty (India) Act, 1891'}, 
    {value : 'Commanders-in-Chief (Change in Designation) Act',view : 'Commanders-in-Chief (Change in Designation) Act, 1955'}, 
    {value : 'Commercial Documents Evidence Act',view : 'Commercial Documents Evidence Act, 1939'}, 
    {value : 'Commissions for Protection of Child Rights Act',view : 'Commissions for Protection of Child Rights Act, 2005'}, 
    {value : 'Commissions of Inquiry Act',view : 'Commissions of Inquiry Act, 1952'}, 
    {value : 'Commission of Sati (Prevention) Act',view : 'Commission of Sati (Prevention) Act, 1988'}, 
    {value : 'Companies Act',view : 'Companies Act, 1956'}, 
    {value : 'Companies Act',view : 'Companies Act, 2013'}, 
    {value : 'Companies (Amendment) Act',view : 'Companies (Amendment) Act, 1988'}, 
    {value : 'Companies (Donations to National Funds) Act',view : 'Companies (Donations to National Funds) Act, 1951'}, 
    {value : 'Companies (Profits) Surtax Act',view : 'Companies (Profits) Surtax Act, 1964'}, 
    {value : 'Company Secretaries Act',view : 'Company Secretaries Act, 1980'}, 
    {value : 'Competition Act',view : 'Competition Act, 2002'}, 
    {value : 'Competition (Amendment) Act',view : 'Competition (Amendment) Act, 2007'}, 
    {value : 'Comptroller and Auditor General\'s (Duties, Powers and Conditions of Service) Act',view : 'Comptroller and Auditor General\'s (Duties, Powers and Conditions of Service) Act, 1971'}, 
    {value : 'Compulsory Deposit Scheme Act',view : 'Compulsory Deposit Scheme Act, 1963'}, 
    {value : 'Conservation of Foreign Exchange and Prevention of Smuggling Activities Act',view : 'Conservation of Foreign Exchange and Prevention of Smuggling Activities Act, 1974'}, 
    {value : 'Consumer Protection Act',view : 'Consumer Protection Act, 1986'}, 
    {value : 'Constitution of India',view : 'Constitution of India, 1950'}, 
    {value : 'Contempt of Courts Act',view : 'Contempt of Courts Act, 1971'}, 
    {value : 'Contingency Fund of India Act',view : 'Contingency Fund of India Act, 1950'}, 
    {value : 'Continuance of Legal Proceedings Act',view : 'Continuance of Legal Proceedings Act, 1948'}, 
    {value : 'Contract Labour (Regulation and Abolition) Act',view : 'Contract Labour (Regulation and Abolition) Act, 1970'}, 
    {value : 'Control of National Highways (Land and Traffic) Act',view : 'Control of National Highways (Land and Traffic) Act, 2002'}, 
    {value : 'Converts\' Marriage Dissolution Act',view : 'Converts\' Marriage Dissolution Act, 1866'}, 
    {value : 'Cooch-Behar (Assimilation of Laws) Act',view : 'Cooch-Behar (Assimilation of Laws) Act, 1950'}, 
    {value : 'Co-operative Societies Act',view : 'Co-operative Societies Act, 1912'}, 
    {value : 'Copyright Act',view : 'Copyright Act, 1957'}, 
    {value : 'Coroners Act',view : 'Coroners Act, 1871'}, 
    {value : 'Cost and Works Accountants Act',view : 'Cost and Works Accountants Act, 1959'}, 
    {value : 'Cotton Copra and Vegetable Oils Cess (Abolition) Act',view : 'Cotton Copra and Vegetable Oils Cess (Abolition) Act, 1987'}, 
    {value : 'Court-fees Act',view : 'Court-fees Act, 1870'}, 
    {value : 'Court-fees (Delhi Amendment) Act',view : 'Court-fees (Delhi Amendment) Act, 1967'}, 
    {value : 'Credit Information Companies (Regulation) Act',view : 'Credit Information Companies (Regulation) Act, 2005'}, 
    {value : 'Criminal and Election Laws Amendment Act',view : 'Criminal and Election Laws Amendment Act, 1969'}, 
    {value : 'Criminal Law Amendment Act',view : 'Criminal Law Amendment Act, 1932'}, 
    {value : 'Criminal Law Amendment Act',view : 'Criminal Law Amendment Act, 1938'}, 
    {value : 'Criminal Law Amendment Act',view : 'Criminal Law Amendment Act, 1961'}, 
    {value : 'Customs Act',view : 'Customs Act, 1962'}, 
    {value : 'Customs (Amendment) Act',view : 'Customs (Amendment) Act, 1985'}, 
    {value : 'Customs and Central Excise Laws (Repeal) Act',view : 'Customs and Central Excise Laws (Repeal) Act, 2004'}, 
    {value : 'Customs Duties and Cesses (Conversion to Metric Units) Act',view : 'Customs Duties and Cesses (Conversion to Metric Units) Act, 1960'}, 
    {value : 'Customs Tariff Act',view : 'Customs Tariff Act, 1975'}, 
    {value : 'Cutchi Memons Act',view : 'Cutchi Memons Act, 1938'}, 
    {value : 'Dadra and Nagar Haveli Act',view : 'Dadra and Nagar Haveli Act, 1961'}, 
    {value : 'Dakshina Bharat Hindi Prachar Sabha Act',view : 'Dakshina Bharat Hindi Prachar Sabha Act, 1964'}, 
    {value : 'Dalmia Dadri Cement Limited (Acquisition and Transfer ofUndertakings) Act',view : 'Dalmia Dadri Cement Limited (Acquisition and Transfer ofUndertakings) Act, 1981'}, 
    {value : 'Damodar Valley Corporation Act',view : 'Damodar Valley Corporation Act, 1948'}, 
    {value : 'Dangerous Machines (Regulation) Act',view : 'Dangerous Machines (Regulation) Act, 1983'}, 
    {value : 'Decrees and Orders Validating Act',view : 'Decrees and Orders Validating Act, 1936'}, 
    {value : 'Dekkhan Agriculturists Relief Act',view : 'Dekkhan Agriculturists Relief Act, 1879'}, 
    {value : 'Delhi Agricultural Produce Marketing (Regulation) Act',view : 'Delhi Agricultural Produce Marketing (Regulation) Act, 1976'}, 
    {value : 'Delhi and Ajmer-MerwaraLand Development Act',view : 'Delhi and Ajmer-MerwaraLand Development Act, 1948'}, 
    {value : 'Delhi and Ajmer Rent Control Act',view : 'Delhi and Ajmer Rent Control Act, 1952'}, 
    {value : 'Delhi and Ajmer Rent Control (Nasirabad Cantonment Repeal) Act',view : 'Delhi and Ajmer Rent Control (Nasirabad Cantonment Repeal) Act, 1968'}, 
    {value : 'Delhi Apartment Ownership Act',view : 'Delhi Apartment Ownership Act, 1986'}, 
    {value : 'Delhi Co-operative Societies Act',view : 'Delhi Co-operative Societies Act, 1972'}, 
    {value : 'Delhi Delegation of Powers Act',view : 'Delhi Delegation of Powers Act, 1964'}, 
    {value : 'Delhi Development Act',view : 'Delhi Development Act, 1957'}, 
    {value : 'Delhi Development Authority (Validation of DisciplinaryPowers) Act',view : 'Delhi Development Authority (Validation of DisciplinaryPowers) Act, 1999'}, 
    {value : 'Delhi Fire Prevention and Fire Safety Act',view : 'Delhi Fire Prevention and Fire Safety Act, 1986'}, 
    {value : 'Delhi High Court Act',view : 'Delhi High Court Act, 1966'}, 
    {value : 'Delhi High Court (Amendment) Act',view : 'Delhi High Court (Amendment) Act, 1980'}, 
    {value : 'Delhi Hotels (Control of Accommodation) Act',view : 'Delhi Hotels (Control of Accommodation) Act, 1949'}, 
    {value : 'DelhiLand Holdings (Ceiling) Act',view : 'DelhiLand Holdings (Ceiling) Act, 1960'}, 
    {value : 'Delhi Lands (Restriction on Transfer) Act',view : 'Delhi Lands (Restriction on Transfer) Act, 1972'}, 
    {value : 'Delhi Laws Act',view : 'Delhi Laws Act, 1912'}, 
    {value : 'Delhi Laws (Special Provisions) Act',view : 'Delhi Laws (Special Provisions) Act, 2006'}, 
    {value : 'Delhi Laws Act',view : 'Delhi Laws Act, 1915'}, 
    {value : 'Delhi Metro Railways (Operation and Maintenance) Act',view : 'Delhi Metro Railways (Operation and Maintenance) Act, 2002'}, 
    {value : 'Delhi Motor Vehicles Taxation Act',view : 'Delhi Motor Vehicles Taxation Act, 1962'}, 
    {value : 'Delhi Police Act',view : 'Delhi Police Act, 1978'}, 
    {value : 'Delhi Primary Education Act',view : 'Delhi Primary Education Act, 1960'}, 
    {value : 'Delhi Rent Act',view : 'Delhi Rent Act, 1995'}, 
    {value : 'Delhi Rent Control Act',view : 'Delhi Rent Control Act, 1958'}, 
    {value : 'Delhi Restriction of Uses of Land Act',view : 'Delhi Restriction of Uses of Land Act, 1941'}, 
    {value : 'Delhi Road Transport Laws (Amendment) Act',view : 'Delhi Road Transport Laws (Amendment) Act, 1971'}, 
    {value : 'Delhi Sales Tax Act',view : 'Delhi Sales Tax Act, 1975'}, 
    {value : 'Delhi Sales Tax (Amendment and Validation) Act',view : 'Delhi Sales Tax (Amendment and Validation) Act, 1976'}, 
    {value : 'Delhi School Education Act',view : 'Delhi School Education Act, 1973'}, 
    {value : 'Delhi Sikh Gurdwaras Act',view : 'Delhi Sikh Gurdwaras Act, 1971'}, 
    {value : 'Delhi Special Police Establishment Act',view : 'Delhi Special Police Establishment Act, 1946'}, 
    {value : 'DelhiUniversity Act',view : 'DelhiUniversity Act, 1922'}, 
    {value : 'Delhi (Urban Areas) Tenants\' Relief Act',view : 'Delhi (Urban Areas) Tenants\' Relief Act, 1961'}, 
    {value : 'Delhi Urban Art Commission Act',view : 'Delhi Urban Art Commission Act, 1973'}, 
    {value : 'Delimitation Act',view : 'Delimitation Act, 2002'}, 
    {value : 'Delivery of Books and Newspapers (Public Libraries) Act',view : 'Delivery of Books and Newspapers (Public Libraries) Act, 1954'}, 
    {value : 'Dentists Act',view : 'Dentists Act, 1948'}, 
    {value : 'Departmental Inquiries (Enforcement of Attendance of Witnesses and Production of Documents) Act',view : 'Departmental Inquiries (Enforcement of Attendance of Witnesses and Production of Documents) Act, 1972'}, 
    {value : 'Departmentalisation of Union Accounts (Transfer of Personnel) Act',view : 'Departmentalisation of Union Accounts (Transfer of Personnel) Act, 1976'}, 
    {value : 'Deposit Insurance and Credit Guarantee Corporation Act',view : 'Deposit Insurance and Credit Guarantee Corporation Act, 1961'}, 
    {value : 'Deposit Insurance Corporation (Amendment and Miscellaneous Provisions) Act',view : 'Deposit Insurance Corporation (Amendment and Miscellaneous Provisions) Act, 1978'}, 
    {value : 'Depositories Act',view : 'Depositories Act, 1996'}, 
    {value : 'Designs Act',view : 'Designs Act, 2000'}, 
    {value : 'Destruction of Records Act',view : 'Destruction of Records Act, 1917'}, 
    {value : 'Destructive Insects and Pests Act',view : 'Destructive Insects and Pests Act, 1914'}, 
    {value : 'Destructive Insects and Pests (Amendment and Validation) Act',view : 'Destructive Insects and Pests (Amendment and Validation) Act, 1992'}, 
    {value : 'Diplomatic and Consular Officers (Oaths and Fees) Act',view : 'Diplomatic and Consular Officers (Oaths and Fees) Act, 1948'}, 
    {value : 'Diplomatic and Consular Officers (Oaths and Fees) (Extension to Jammu And Kashmir) Act',view : 'Diplomatic and Consular Officers (Oaths and Fees) (Extension to Jammu And Kashmir) Act, 1973'}, 
    {value : 'Diplomatic Relations (Vienna Convention) Act',view : 'Diplomatic Relations (Vienna Convention) Act, 1972'}, 
    {value : 'Direct Tax Laws (Amendment) Act',view : 'Direct Tax Laws (Amendment) Act, 1989'}, 
    {value : 'Direct-Tax Laws (Miscellaneous) Repeal Act',view : 'Direct-Tax Laws (Miscellaneous) Repeal Act, 2000'}, 
    {value : 'Disaster Management Act',view : 'Disaster Management Act, 2005'}, 
    {value : 'Displaced Persons (Debts Adjustment) Act',view : 'Displaced Persons (Debts Adjustment) Act, 1951'}, 
    {value : 'Disputed Elections (Prime Minister and Speaker)Act',view : 'Disputed Elections (Prime Minister and Speaker)Act, 1977'}, 
    {value : 'Dissolution of Muslim Marriages Act',view : 'Dissolution of Muslim Marriages Act, 1939'}, 
    {value : 'Disturbed Areas (Special Courts) Act',view : 'Disturbed Areas (Special Courts) Act, 1976'}, 
    {value : 'Divorce Act',view : 'Divorce Act, 1869'}, 
    {value : 'Dock Workers (Regulation of Employment) Act',view : 'Dock Workers (Regulation of Employment) Act, 1948'}, 
    {value : 'Dock Workers (Regulation of Employment Inapplicability to Major Ports) Act',view : 'Dock Workers (Regulation of Employment Inapplicability to Major Ports) Act, 1997'}, 
    {value : 'Dock Workers, (Safety, Health and Welfare) Act',view : 'Dock Workers, (Safety, Health and Welfare) Act, 1986'}, 
    {value : 'Dourine Act',view : 'Dourine Act, 1910'}, 
    {value : 'Dowry Prohibition Act',view : 'Dowry Prohibition Act, 1961'}, 
    {value : 'Dramatic Performances Act',view : 'Dramatic Performances Act, 1876'}, 
    {value : 'Drugs and Cosmetics Act',view : 'Drugs and Cosmetics Act, 1940'}, 
    {value : 'Drugs and Magic Remedies (Objectionable Advertisements) Act',view : 'Drugs and Magic Remedies (Objectionable Advertisements) Act, 1954'}, 
    {value : 'Drugs (Control) Act',view : 'Drugs (Control) Act, 1950'}, 
    {value : 'Durgah Khawaja Saheb Act',view : 'Durgah Khawaja Saheb Act, 1955'}, 
    {value : 'Easements (Extending Act 5 of 1882),1891',view : 'Easements (Extending Act 5 of 1882),1891'}, 
    {value : 'East-Punjab Urban Rent Restriction (Extension to Chandigarh) Act',view : 'East-Punjab Urban Rent Restriction (Extension to Chandigarh) Act, 1974'}, 
    {value : 'Economic Offences (Inapplicability of Limitation) Act',view : 'Economic Offences (Inapplicability of Limitation) Act, 1974'}, 
    {value : 'Election Commission (Conditions of Service of Election Commissioners and Transaction of Business) Act',view : 'Election Commission (Conditions of Service of Election Commissioners and Transaction of Business) Act, 1991'}, 
    {value : 'Election Laws (Extension to Sikkim) Act',view : 'Election Laws (Extension to Sikkim) Act, 1976'}, 
    {value : 'Elephants\' Preservation Act',view : 'Elephants\' Preservation Act, 1879'}, 
    {value : 'Emblems and Names (Prevention of Improper Use) Act',view : 'Emblems and Names (Prevention of Improper Use) Act, 1950'}, 
    {value : 'Emigration Act',view : 'Emigration Act, 1983'}, 
    {value : 'Employees\' Provident Funds and Miscellaneous Provisions Act',view : 'Employees\' Provident Funds and Miscellaneous Provisions Act, 1952'}, 
    {value : 'Employees\' Provident Funds and Miscellaneous Provisions (Amendment) Act',view : 'Employees\' Provident Funds and Miscellaneous Provisions (Amendment) Act, 1988'}, 
    {value : 'Employees\' State Insurance Act',view : 'Employees\' State Insurance Act, 1948'}, 
    {value : 'Employees\' State Insurance (Amendment) Act',view : 'Employees\' State Insurance (Amendment) Act, 1989'}, 
    {value : 'Employers\' Liability Act',view : 'Employers\' Liability Act, 1938'}, 
    {value : 'Employment Exchanges (Compulsory Notification of Vacancies) Act',view : 'Employment Exchanges (Compulsory Notification of Vacancies) Act, 1959'}, 
    {value : 'Employment of Manual Scavengers and Construction of Dry Latrines (Prohibition) Act',view : 'Employment of Manual Scavengers and Construction of Dry Latrines (Prohibition) Act, 1993'}, 
    {value : 'Enemy Property Act',view : 'Enemy Property Act, 1968'}, 
    {value : 'English and Foreign Languages University Act',view : 'English and Foreign Languages University Act, 2007'}, 
    {value : 'Energy Conservation Act',view : 'Energy Conservation Act, 2001'}, 
    {value : 'Environment (Protection) Act',view : 'Environment (Protection) Act, 1986'}, 
    {value : 'Epidemic Diseases Act',view : 'Epidemic Diseases Act, 1897'}, 
    {value : 'Equal Remuneration Act',view : 'Equal Remuneration Act, 1976'}, 
    {value : 'Essential Commodities Act',view : 'Essential Commodities Act, 1955'}, 
    {value : 'Essential Commodities (Amendment and Validation) Act',view : 'Essential Commodities (Amendment and Validation) Act, 2009'}, 
    {value : 'Essential Services Maintenance (Assam) Act',view : 'Essential Services Maintenance (Assam) Act, 1980'}, 
    {value : 'Esso (Acquisition of Undertakings in India) Act',view : 'Esso (Acquisition of Undertakings in India) Act, 1974'}, 
    {value : 'Exchange of Prisoners Act',view : 'Exchange of Prisoners Act, 1948'}, 
    {value : 'Excise (Malt Liquors) Act',view : 'Excise (Malt Liquors) Act, 1890'}, 
    {value : 'Excise (Spirits) Act',view : 'Excise (Spirits) Act, 1863'}, 
    {value : 'Expenditure-tax Act',view : 'Expenditure-tax Act, 1987'}, 
    {value : 'Explosive Substances Act',view : 'Explosive Substances Act, 1908'}, 
    {value : 'Explosives Act',view : 'Explosives Act, 1884'}, 
    {value : 'Export-Import Bank of India Act',view : 'Export-Import Bank of India Act, 1981'}, 
    {value : 'Export (Quality Control and Inspection) Act',view : 'Export (Quality Control and Inspection) Act, 1963'}, 
    {value : 'Extradition Act',view : 'Extradition Act, 1962'}, 
    {value : 'Factories Act',view : 'Factories Act, 1948'}, 
    {value : 'Factoring Act',view : 'Factoring Act, 2012'}, 
    {value : 'Family Courts Act',view : 'Family Courts Act, 1984'}, 
    {value : 'Faridabad Development Corporation Act',view : 'Faridabad Development Corporation Act, 1956'}, 
    {value : 'Fatal Accidents Act',view : 'Fatal Accidents Act, 1855'}, 
    {value : 'Finance Commission (Miscellaneous Provisions) Act',view : 'Finance Commission (Miscellaneous Provisions) Act, 1951'}, 
    {value : 'Fiscal Responsibility and Budget Management Act',view : 'Fiscal Responsibility and Budget Management Act, 2003'}, 
    {value : 'Food Corporations Act',view : 'Food Corporations Act, 1964'}, 
    {value : 'Food Safety and Standards Act',view : 'Food Safety and Standards Act, 2006'}, 
    {value : 'Foreign Aircraft (Exemption from Taxes and Duties on Fuel and Lubricants) Act',view : 'Foreign Aircraft (Exemption from Taxes and Duties on Fuel and Lubricants) Act, 2002'}, 
    {value : 'Foreign Contribution (Regulation) Act',view : 'Foreign Contribution (Regulation) Act, 2010'}, 
    {value : 'Foreign Exchange Management Act',view : 'Foreign Exchange Management Act, 1999'}, 
    {value : 'Foreign Jurisdiction Act',view : 'Foreign Jurisdiction Act, 1947'}, 
    {value : 'Foreign Marriage Act',view : 'Foreign Marriage Act, 1969'}, 
    {value : 'Foreign Recruiting Act',view : 'Foreign Recruiting Act, 1874'}, 
    {value : 'Foreign Trade (Development and Regulation) Act',view : 'Foreign Trade (Development and Regulation) Act, 1992'}, 
    {value : 'Foreigners Act',view : 'Foreigners Act, 1946'}, 
    {value : 'Foreigners Law (Application and Amendment) Act',view : 'Foreigners Law (Application and Amendment) Act, 1962'}, 
    {value : 'Forest (Conservation) Act',view : 'Forest (Conservation) Act, 1980'}, 
    {value : 'Forfeited Deposits Act',view : 'Forfeited Deposits Act, 1850'}, 
    {value : 'Former Secretary of State Service Officers (Conditions ofService) Act',view : 'Former Secretary of State Service Officers (Conditions ofService) Act, 1972'}, 
    {value : 'Fort William Act',view : 'Fort William Act, 1881'}, 
    {value : 'Forward Contracts (Regulation) Act',view : 'Forward Contracts (Regulation) Act, 1952'}, 
    {value : 'Futwah-Islampur Light Railway Line (Nationalisation) Act',view : 'Futwah-Islampur Light Railway Line (Nationalisation) Act, 1985'}, 
    {value : 'Ganesh Flour Mills Company Limited (Acquisitionand Transfer of Undertakings) Act',view : 'Ganesh Flour Mills Company Limited (Acquisitionand Transfer of Undertakings) Act, 1984'}, 
    {value : 'Ganges Tolls Act',view : 'Ganges Tolls Act, 1867'}, 
    {value : 'General Clauses Act',view : 'General Clauses Act, 1897'}, 
    {value : 'General Insurance Business (Nationalisation) Act',view : 'General Insurance Business (Nationalisation) Act, 1972'}, 
    {value : 'General Insurance Business (Nationalisation) Amendment Act',view : 'General Insurance Business (Nationalisation) Amendment Act, 1985'}, 
    {value : 'Geneva Conventions Act',view : 'Geneva Conventions Act, 1960'}, 
    {value : 'Geographical Indications of Goods (Registration and Protection) Act',view : 'Geographical Indications of Goods (Registration and Protection) Act, 1999'}, 
    {value : 'Gift-tax Act',view : 'Gift-tax Act, 1958'}, 
    {value : 'Glanders and Farcy Act',view : 'Glanders and Farcy Act, 1899'}, 
    {value : 'Gram Nyayalayas Act',view : 'Gram Nyayalayas Act, 2009'}, 
    {value : 'Goa Arbitration Act) Act',view : 'Goa Arbitration Act) Act, 1965'}, 
    {value : 'Goa Mining Leases) Act, ',view : 'Goa Mining Leases) Act, '}, 
    {value : 'Gold Bonds (Immunities and Exemptions) Act',view : 'Gold Bonds (Immunities and Exemptions) Act, 1993'}, 
    {value : 'Central Goods and Services Act',view : 'Central Goods and Services Act, 2017'}, 
    {value : 'Central Goods and Services Tax (Extension to Jammu and Kashmir) Act',view : 'Central Goods and Services Tax (Extension to Jammu and Kashmir) Act, 2017'}, 
    {value : 'State Goods and Services Tax Act',view : 'State Goods and Services Tax Act, 2017'}, 
    {value : 'Union Territory Goods and Services Tax Act',view : 'Union Territory Goods and Services Tax Act, 2017'}, 
    {value : 'Integrated Goods and Services Tax Act',view : 'Integrated Goods and Services Tax Act, 2017'}, 
    {value : 'Integrated Goods and Services Tax (Extension to Jammu and Kashmir Act',view : 'Integrated Goods and Services Tax (Extension to Jammu and Kashmir Act, 2017'}, 
    {value : 'Goods and Services Tax (Compensation to States) Act',view : 'Goods and Services Tax (Compensation to States) Act, 2017'}, 
    {value : 'Government Buildings Act',view : 'Government Buildings Act, 1899'}, 
    {value : 'Government Grants Act',view : 'Government Grants Act, 1895'}, 
    {value : 'Government Management of Private Estates Act',view : 'Government Management of Private Estates Act, 1892'}, 
    {value : 'Government of National Capital Territory of Delhi Act',view : 'Government of National Capital Territory of Delhi Act, 1991'}, 
    {value : 'Government of Union Territories Act',view : 'Government of Union Territories Act, 1963'}, 
    {value : 'Government Savings Banks Act',view : 'Government Savings Banks Act, 1873'}, 
    {value : 'Government Savings Certificates Act',view : 'Government Savings Certificates Act, 1959'}, 
    {value : 'Government Seal Act',view : 'Government Seal Act, 1862'}, 
    {value : 'Governent Securities Act',view : 'Governent Securities Act, 2006'}, 
    {value : 'Governor\'s (Emoulments, Allowances and Priveleges) Act',view : 'Governor\'s (Emoulments, Allowances and Priveleges) Act, 1982'}, 
    {value : 'Gresham and Craven of India (Private) Limited (Acquisition and Transfer of Undertakings) Act',view : 'Gresham and Craven of India (Private) Limited (Acquisition and Transfer of Undertakings) Act, 1977'}, 
    {value : 'Guardians and Wards Act',view : 'Guardians and Wards Act, 1890'}, 
    {value : 'Hackney-carriage Act',view : 'Hackney-carriage Act, 1879'}, 
    {value : 'Haj Committee Act',view : 'Haj Committee Act, 2002'}, 
    {value : 'Handlooms (Reservation of Articles for Production) Act',view : 'Handlooms (Reservation of Articles for Production) Act, 1985'}, 
    {value : 'Haryana and Punjab Agricultural Universities Act',view : 'Haryana and Punjab Agricultural Universities Act, 1970'}, 
    {value : 'Haryana and Uttar Pradesh (Alteration of Boundaries) Act',view : 'Haryana and Uttar Pradesh (Alteration of Boundaries) Act, 1979'}, 
    {value : 'High Court and Bombay (Extension of Jurisdiction to Goa Diu) Act',view : 'High Court and Bombay (Extension of Jurisdiction to Goa Diu) Act, 1981'}, 
    {value : 'High Court at Patna (Establishment of a Permanent Bench at Ranchi) Act',view : 'High Court at Patna (Establishment of a Permanent Bench at Ranchi) Act, 1976'}, 
    {value : 'High Court Judges (Salaries and Conditions of Service) Act',view : 'High Court Judges (Salaries and Conditions of Service) Act, 1954'}, 
    {value : 'High Courts (Seals) Act',view : 'High Courts (Seals) Act, 1950'}, 
    {value : 'High Denomination Bank Notes (Demonetisation) Act',view : 'High Denomination Bank Notes (Demonetisation) Act, 1978'}, 
    {value : 'Himachal Pradesh and Bilaspur (New State) Act',view : 'Himachal Pradesh and Bilaspur (New State) Act, 1954'}, 
    {value : 'Himachal Pradesh Legislative Assembly (Constitution and Proceedings) Validation Act',view : 'Himachal Pradesh Legislative Assembly (Constitution and Proceedings) Validation Act, 1958'}, 
    {value : 'Hind Cycles Limited and Sen-Raleigh Limited (Nationalisation) Act',view : 'Hind Cycles Limited and Sen-Raleigh Limited (Nationalisation) Act, 1980'}, 
    {value : 'Hindi Sahitya Sammelan Act',view : 'Hindi Sahitya Sammelan Act, 1962'}, 
    {value : 'Hindu Adoptions and Maintenance Act',view : 'Hindu Adoptions and Maintenance Act, 1956'}, 
    {value : 'Hindu Disposition of Property Act',view : 'Hindu Disposition of Property Act, 1916'}, 
    {value : 'Hindu Gains of Learning Act',view : 'Hindu Gains of Learning Act, 1930'}, 
    {value : 'Hindu Inheritance (Removal of Disabilities) Act',view : 'Hindu Inheritance (Removal of Disabilities) Act, 1928'}, 
    {value : 'Hindu Marriage Act',view : 'Hindu Marriage Act, 1955'}, 
    {value : 'Hindu Marriages (Validation of Proceedings) Act',view : 'Hindu Marriages (Validation of Proceedings) Act, 1960'}, 
    {value : 'Hindu Minority and Guardianship Act',view : 'Hindu Minority and Guardianship Act, 1956'}, 
    {value : 'Hindu Succession Act',view : 'Hindu Succession Act, 1956'}, 
    {value : 'Hindustan Tractors Limited (Acquisition and Transfer of Undertakings) Act',view : 'Hindustan Tractors Limited (Acquisition and Transfer of Undertakings) Act, 1978'}, 
    {value : 'Homoeopathy Central Council Act',view : 'Homoeopathy Central Council Act, 1973'}, 
    {value : 'Hooghly Docking and Engineering Company Limited (Acquisition and Transfer of Undertakings) Act',view : 'Hooghly Docking and Engineering Company Limited (Acquisition and Transfer of Undertakings) Act, 1984'}, 
    {value : 'Hotel-Receipts Tax Act',view : 'Hotel-Receipts Tax Act, 1980'}, 
    {value : 'Howrah Offences Act',view : 'Howrah Offences Act, 1857'}, 
    {value : 'Identification of Prisoners Act',view : 'Identification of Prisoners Act, 1920'}, 
    {value : 'Illegal Migrants (Determination by Tribunals) Act',view : 'Illegal Migrants (Determination by Tribunals) Act, 1983'}, 
    {value : 'Immigration (Carriers Liability) Act',view : 'Immigration (Carriers Liability) Act, 2000'}, 
    {value : 'Immigrants (Expulsion from Assam) Act',view : 'Immigrants (Expulsion from Assam) Act, 1950'}, 
    {value : 'Immoral Traffic (Prevention) Act',view : 'Immoral Traffic (Prevention) Act, 1956'}, 
    {value : 'Imperial Library (Change of Name) Act',view : 'Imperial Library (Change of Name) Act, 1948'}, 
    {value : 'Improvements in Towns',view : 'Improvements in Towns, 1850'}, 
    {value : 'Inchek Tyres Limited and National Rubber Manufacturers Limited (Nationalisation) Act',view : 'Inchek Tyres Limited and National Rubber Manufacturers Limited (Nationalisation) Act, 1984'}, 
    {value : 'Income-tax Act',view : 'Income-tax Act, 1961'}, 
    {value : 'Indecent Representation of Women (Prohibition) Act',view : 'Indecent Representation of Women (Prohibition) Act, 1986'}, 
    {value : 'Indian Bar Councils Act',view : 'Indian Bar Councils Act, 1926'}, 
    {value : 'Indian Bills of Lading Act',view : 'Indian Bills of Lading Act, 1856'}, 
    {value : 'Indian Boilers Act',view : 'Indian Boilers Act, 1923'}, 
    {value : 'Indian Carriage of Goods by Sea Act',view : 'Indian Carriage of Goods by Sea Act, 1925'}, 
    {value : 'Indian Christian Marriage Act',view : 'Indian Christian Marriage Act, 1872'}, 
    {value : 'Indian Council of World Affairs Act',view : 'Indian Council of World Affairs Act, 2001'}, 
    {value : 'Indian Copper Corporation (Acquisition of Undertaking) Act',view : 'Indian Copper Corporation (Acquisition of Undertaking) Act, 1972'}, 
    {value : 'Indian Criminal Law Amendment Act',view : 'Indian Criminal Law Amendment Act, 1908'}, 
    {value : 'Easements Act',view : 'Easements Act, 1882'}, 
    {value : 'Evidence Act',view : 'Evidence Act, 1872'}, 
    {value : 'Fisheries Act',view : 'Fisheries Act, 1897'}, 
    {value : 'Forest Act',view : 'Forest Act, 1927'}, 
    {value : 'Indian Independence Pakistan Courts (Pending Proceedings)Act',view : 'Indian Independence Pakistan Courts (Pending Proceedings)Act, 1952'}, 
    {value : 'Indian Iron and Steel Company (Acquisition of Shares) Act',view : 'Indian Iron and Steel Company (Acquisition of Shares) Act, 1976'}, 
    {value : 'Law Reports Act',view : 'Law Reports Act, 1875'}, 
    {value : 'Maritime University Act',view : 'Maritime University Act, 2008'}, 
    {value : 'Matrimonial Causes (War Marriages) Act',view : 'Matrimonial Causes (War Marriages) Act, 1948'}, 
    {value : 'Medical Council Act',view : 'Medical Council Act, 1956'}, 
    {value : 'Medical Degrees Act',view : 'Medical Degrees Act, 1916'}, 
    {value : 'Medicine Central Council Act',view : 'Medicine Central Council Act, 1970'}, 
    {value : 'Museum Act',view : 'Museum Act, 1910'}, 
    {value : 'Naval Armament Act',view : 'Naval Armament Act, 1923'}, 
    {value : 'Nursing Council Act',view : 'Nursing Council Act, 1947'}, 
    {value : 'Partnership Act',view : 'Partnership Act, 1932'}, 
    {value : 'Penal Code,1860',view : 'Penal Code,1860'}, 
    {value : 'Ports Act',view : 'Ports Act, 1908'}, 
    {value : 'Post Office Act',view : 'Post Office Act, 1898'}, 
    {value : 'Railway Board Act',view : 'Railway Board Act, 1905'}, 
    {value : 'Red Cross Society Act',view : 'Red Cross Society Act, 1920'}, 
    {value : 'Reserve Forces Act',view : 'Reserve Forces Act, 1888'}, 
    {value : 'Short Titles Act',view : 'Short Titles Act, 1897'}, 
    {value : 'Soldiers (Litigation) Act',view : 'Soldiers (Litigation) Act, 1925'}, 
    {value : 'Stamp Act',view : 'Stamp Act, 1899'}, 
    {value : 'Statistical Institute Act',view : 'Statistical Institute Act, 1959'}, 
    {value : 'Succession Act',view : 'Succession Act, 1925'}, 
    {value : 'Telegraph Act',view : 'Telegraph Act, 1885'}, 
    {value : 'Tolls Act',view : 'Tolls Act, 1851'}, 
    {value : 'Tolls Act',view : 'Tolls Act, 1888'}, 
    {value : 'Tolls Act',view : 'Tolls Act, 1864'}, 
    {value : 'Tolls (Army and Air Force) Act',view : 'Tolls (Army and Air Force) Act, 1901'}, 
    {value : 'Tramways Act',view : 'Tramways Act, 1886'}, 
    {value : 'Tramways Act',view : 'Tramways Act, 1902'}, 
    {value : 'Treasure-trove Act',view : 'Treasure-trove Act, 1878'}, 
    {value : 'Trusts Act',view : 'Trusts Act, 1882'}, 
    {value : 'Veterinary Council Act',view : 'Veterinary Council Act, 1984'}, 
    {value : 'Wireless Telegraphy Act',view : 'Wireless Telegraphy Act, 1933'}, 
    {value : 'Rifles (Repeal) Act',view : 'Rifles (Repeal) Act, 2006'}, 
    {value : 'Indira Gandhi National Open University Act',view : 'Indira Gandhi National Open University Act, 1985'}, 
    {value : 'Indira Gandhi National Tribal University Act',view : 'Indira Gandhi National Tribal University Act, 2007'}, 
    {value : 'Indo-Tibetan Border Police Force Act',view : 'Indo-Tibetan Border Police Force Act, 1992'}, 
    {value : 'Industrial Development Bank (Transfer of Undertaking and Repeal) Act',view : 'Industrial Development Bank (Transfer of Undertaking and Repeal) Act, 2003'}, 
    {value : 'Industrial Disputes Act',view : 'Industrial Disputes Act, 1947'}, 
    {value : 'Industrial Disputes (Amendment and Miscellaneous Provisions) Act',view : 'Industrial Disputes (Amendment and Miscellaneous Provisions) Act, 1956'}, 
    {value : 'Industrial Disputes (Banking and Insurance Companies) Act',view : 'Industrial Disputes (Banking and Insurance Companies) Act, 1949'}, 
    {value : 'Industrial Employment (Standing Orders) Act',view : 'Industrial Employment (Standing Orders) Act, 1946'}, 
    {value : 'Industrial Finance Corporation (Transfer of Undertaking and Repeal) Act',view : 'Industrial Finance Corporation (Transfer of Undertaking and Repeal) Act, 1993'}, 
    {value : 'Industrial Reconstruction Bank (Transfer of Undertaking andRepeal) Act',view : 'Industrial Reconstruction Bank (Transfer of Undertaking andRepeal) Act, 1997'}, 
    {value : 'Industrial Reconstruction Bank of India Act',view : 'Industrial Reconstruction Bank of India Act, 1984'}, 
    {value : 'Industries (Development and Regulation) Act',view : 'Industries (Development and Regulation) Act, 1951'}, 
    {value : 'Infant Milk Substitutes, Feeding Bottles and Infant Foods (Regulation of Production, Supply and Distribution) Act',view : 'Infant Milk Substitutes, Feeding Bottles and Infant Foods (Regulation of Production, Supply and Distribution) Act, 1992'}, 
    {value : 'Inflammable Substances Act',view : 'Inflammable Substances Act, 1952'}, 
    {value : 'Information Technology Act',view : 'Information Technology Act, 2000'}, 
    {value : 'Inland Vessels Act',view : 'Inland Vessels Act, 1917'}, 
    {value : 'Inland Waterways Authority of India Act',view : 'Inland Waterways Authority of India Act, 1985'}, 
    {value : 'Insecticides Act',view : 'Insecticides Act, 1968'}, 
    {value : 'Institutes of Technology Act',view : 'Institutes of Technology Act, 1961'}, 
    {value : 'Insurance Act',view : 'Insurance Act, 1938'}, 
    {value : 'Insurance Regulatory and Development Authority Act',view : 'Insurance Regulatory and Development Authority Act, 1999'}, 
    {value : 'Intelligence Organisations (Restriction on Rights) Act',view : 'Intelligence Organisations (Restriction on Rights) Act, 1985'}, 
    {value : 'Interest Act',view : 'Interest Act, 1978'}, 
    {value : 'Interest Tax Act',view : 'Interest Tax Act, 1974'}, 
    {value : 'International Development Association (Status, Immunities and Privileges) Act',view : 'International Development Association (Status, Immunities and Privileges) Act, 1960'}, 
    {value : 'International Finance Corporation (Status, Immunities and Privileges) Act',view : 'International Finance Corporation (Status, Immunities and Privileges) Act, 1958'}, 
    {value : 'International Monetary Fund and Bank Act',view : 'International Monetary Fund and Bank Act, 1945'}, 
    {value : 'Inter-State Corporation Act',view : 'Inter-State Corporation Act, 1957'}, 
    {value : 'Inter-State Migrant Workmen (Regulation of Employment and Conditions of Service) Act',view : 'Inter-State Migrant Workmen (Regulation of Employment and Conditions of Service) Act, 1979'}, 
    {value : 'Inter-StateRiver Water Disputes Act',view : 'Inter-StateRiver Water Disputes Act, 1956'}, 
    {value : 'Iron Ore Mines Manganese Ore Mines and Chrome Ore Mines Labour Welfare Cess Act',view : 'Iron Ore Mines Manganese Ore Mines and Chrome Ore Mines Labour Welfare Cess Act, 1976'}, 
    {value : 'Iron Ore Mines Manganese Ore Mines and Chrome Ore Mines Labour Welfare Fund Act',view : 'Iron Ore Mines Manganese Ore Mines and Chrome Ore Mines Labour Welfare Fund Act, 1976'}, 
    {value : 'Indian Maritime University Act',view : 'Indian Maritime University Act, 2008'}, 
    {value : 'Jallianwala Bagh National Memorial Act',view : 'Jallianwala Bagh National Memorial Act, 1951'}, 
    {value : 'Jamia Millia Islamia Act',view : 'Jamia Millia Islamia Act, 1988'}, 
    {value : 'Jammu and Kashmir (Extension of Laws) Act',view : 'Jammu and Kashmir (Extension of Laws) Act, 1956'}, 
    {value : 'Jammu and Kashmir Representation of the People (Supplementary) Act',view : 'Jammu and Kashmir Representation of the People (Supplementary) Act, 1968'}, 
    {value : 'Jawaharlal Institute of Post Graduate Medical Education and Research Puducherry Act',view : 'Jawaharlal Institute of Post Graduate Medical Education and Research Puducherry Act, 2008'}, 
    {value : 'Jawaharlal Nehru University Act',view : 'Jawaharlal Nehru University Act, 1966'}, 
    {value : 'Jayanti Shipping Company (Acquisition of Shares) Act',view : 'Jayanti Shipping Company (Acquisition of Shares) Act, 1971'}, 
    {value : 'Jharkhand Contingency Fund (Amendment) Act',view : 'Jharkhand Contingency Fund (Amendment) Act, 2009'}, 
    {value : 'Jubbalpore and Chhattisgarh Divisions (Divorce Proceedings Validation) Act',view : 'Jubbalpore and Chhattisgarh Divisions (Divorce Proceedings Validation) Act, 1935'}, 
    {value : 'Judges (Inquiry) Act',view : 'Judges (Inquiry) Act, 1968'}, 
    {value : 'Judges (Protection) Act',view : 'Judges (Protection) Act, 1985'}, 
    {value : 'Judicial Officers Protection Act',view : 'Judicial Officers Protection Act, 1850'}, 
    {value : 'Junagarh Administration (Property) Act',view : 'Junagarh Administration (Property) Act, 1948'}, 
    {value : 'Jute Companies (Nationalisation) Act',view : 'Jute Companies (Nationalisation) Act, 1980'}, 
    {value : 'Jute Manufactures Cess Act',view : 'Jute Manufactures Cess Act, 1983'}, 
    {value : 'Jute Manufacturers Development Council Act',view : 'Jute Manufacturers Development Council Act, 1983'}, 
    {value : 'Jute Packaging Materials (Compulsory Use in Packing Commodities) Act',view : 'Jute Packaging Materials (Compulsory Use in Packing Commodities) Act, 1987'}, 
    {value : 'Juvenile Justice (Care and Protection of Children) Act',view : 'Juvenile Justice (Care and Protection of Children) Act, 2000'}, 
    {value : 'Kalakshetra Foundation Act',view : 'Kalakshetra Foundation Act, 1993'}, 
    {value : 'Kazis Act',view : 'Kazis Act, 1880'}, 
    {value : 'Khaddar (Protection of Name) Act',view : 'Khaddar (Protection of Name) Act, 1950'}, 
    {value : 'Khadi and Village Industries Commission Act',view : 'Khadi and Village Industries Commission Act, 1956'}, 
    {value : 'Khuda Bakhsh Oriental Public Library Act',view : 'Khuda Bakhsh Oriental Public Library Act, 1969'}, 
    {value : 'King of Oudh\'s Estate Act',view : 'King of Oudh\'s Estate Act, 1887'}, 
    {value : 'King of Oudh\'s Estate Act',view : 'King of Oudh\'s Estate Act, 1888'}, 
    {value : 'King of Oudh\'s Estate Validation Act',view : 'King of Oudh\'s Estate Validation Act, 1917'}, 
    {value : 'Konkan Passenger Ships (Acquisition) Act',view : 'Konkan Passenger Ships (Acquisition) Act, 1973'}, 
    {value : 'Kosangas Company (Acquisition of Undertakings) Act',view : 'Kosangas Company (Acquisition of Undertakings) Act, 1979'}, 
    {value : 'Labour Laws (Exemption from Furnishing Returns and Maintaining Registers by certain Establishments) Act',view : 'Labour Laws (Exemption from Furnishing Returns and Maintaining Registers by certain Establishments) Act, 1988'}, 
    {value : 'Lady Hardinge Medical College and Hospital (Acquisition andMiscellaneous Provisions) Act',view : 'Lady Hardinge Medical College and Hospital (Acquisition andMiscellaneous Provisions) Act, 1977'}, 
    {value : 'Lalitkala Akademi (Taking Over of Management) Act',view : 'Lalitkala Akademi (Taking Over of Management) Act, 1997'}, 
    {value : 'Land Acquisition Act',view : 'Land Acquisition Act, 1894'}, 
    {value : 'Land Acquisition (Amendment) Act',view : 'Land Acquisition (Amendment) Act, 1962'}, 
    {value : 'Land Acquisition (Amendment and Validation) Act',view : 'Land Acquisition (Amendment and Validation) Act, 1967'}, 
    {value : 'Land Acquisition (Mines) Act',view : 'Land Acquisition (Mines) Act, 1885'}, 
    {value : 'Land Improvement Loans Act',view : 'Land Improvement Loans Act, 1883'}, 
    {value : 'Land Port Authority of India Act',view : 'Land Port Authority of India Act, 2010'}, 
    {value : 'Laws Local Extent Act',view : 'Laws Local Extent Act, 1874'}, 
    {value : 'Laxmirattan Cotton Mills Company Limited (Taking Over of Management) Act',view : 'Laxmirattan Cotton Mills Company Limited (Taking Over of Management) Act, 1976'}, 
    {value : 'Leaders of Chief Whips of Recognised Parties and Groups in Parliament (Facilities) Act',view : 'Leaders of Chief Whips of Recognised Parties and Groups in Parliament (Facilities) Act, 1999'}, 
    {value : 'Legal Metrology Act',view : 'Legal Metrology Act, 2010'}, 
    {value : 'Legal Practitioners Act',view : 'Legal Practitioners Act, 1879'}, 
    {value : 'Legal Practitioners (Fees) Act',view : 'Legal Practitioners (Fees) Act, 1926'}, 
    {value : 'Legal Practitioners (Women) Act',view : 'Legal Practitioners (Women) Act, 1923'}, 
    {value : 'Legal Representatives\' Suits Act',view : 'Legal Representatives\' Suits Act, 1855'}, 
    {value : 'Legal Services Authorities Act',view : 'Legal Services Authorities Act, 1987'}, 
    {value : 'Legal Tender (Inscribed Notes) Act',view : 'Legal Tender (Inscribed Notes) Act, 1964'}, 
    {value : 'Legislative Assembly of Nagaland (Change inRepresentation) Act',view : 'Legislative Assembly of Nagaland (Change inRepresentation) Act, 1968'}, 
    {value : 'Legislative Councils Act',view : 'Legislative Councils Act, 1957'}, 
    {value : 'Lepers Act',view : 'Lepers Act, 1898'}, 
    {value : 'Levy Sugar Price Equalisation Fund Act',view : 'Levy Sugar Price Equalisation Fund Act, 1976'}, 
    {value : 'Life Insurance Corporation Act',view : 'Life Insurance Corporation Act, 1956'}, 
    {value : 'Life Insurance Corporation (Modification of Settlement) Act',view : 'Life Insurance Corporation (Modification of Settlement) Act, 1976'}, 
    {value : 'Light House Act',view : 'Light House Act, 1927'}, 
    {value : 'Limestone and Dolomite Mines Labour Welfare Fund Act',view : 'Limestone and Dolomite Mines Labour Welfare Fund Act, 1972'}, 
    {value : 'Limited Liability Partnership Act',view : 'Limited Liability Partnership Act, 2009'}, 
    {value : 'Limitation Act',view : 'Limitation Act, 1963'}, 
    {value : 'Live-stock Importation Act',view : 'Live-stock Importation Act, 1898'}, 
    {value : 'Lokpal and Lokayuktas Act',view : 'Lokpal and Lokayuktas Act, 2014'}, 
    {value : 'Local Authorities Loans Act',view : 'Local Authorities Loans Act, 1914'}, 
    {value : 'Local Authorities Pensions and Gratuities Act',view : 'Local Authorities Pensions and Gratuities Act, 1919'}, 
    {value : 'Lok Sahayak Sena Act',view : 'Lok Sahayak Sena Act, 1956'}, 
    {value : 'Lotteries (Regulation) Act',view : 'Lotteries (Regulation) Act, 1998'}, 
    {value : 'Lushai Hills District (Change of Name) Act',view : 'Lushai Hills District (Change of Name) Act, 1954'}, 
    {value : 'Madhya Pradesh Reorganisation Act',view : 'Madhya Pradesh Reorganisation Act, 2000'}, 
    {value : 'Madras City Civil Court Act',view : 'Madras City Civil Court Act, 1892'}, 
    {value : 'Madras City Land Revenue Act',view : 'Madras City Land Revenue Act, 1851'}, 
    {value : 'Madras Civil Courts Act',view : 'Madras Civil Courts Act, 1873'}, 
    {value : 'Madras Compulsory Labour Act',view : 'Madras Compulsory Labour Act, 1858'}, 
    {value : 'Madras District Police Act',view : 'Madras District Police Act, 1859'}, 
    {value : 'Madras Forest (Validation) Act',view : 'Madras Forest (Validation) Act, 1882'}, 
    {value : 'Madras Public Property Malversation Act',view : 'Madras Public Property Malversation Act, 1837'}, 
    {value : 'Madras Rent and Revenue Sales Act',view : 'Madras Rent and Revenue Sales Act, 1839'}, 
    {value : 'Madras Revenue Commissioner Act',view : 'Madras Revenue Commissioner Act, 1849'}, 
    {value : 'Madras Uncovenated Officers\' Act',view : 'Madras Uncovenated Officers\' Act, 1857'}, 
    {value : 'Mahatama Gandhi Antarashtriya Hindi Vishwavidyalaya Act',view : 'Mahatama Gandhi Antarashtriya Hindi Vishwavidyalaya Act, 1996'}, 
    {value : 'Mahendra Pratap Singh Estate (Repeal) Act',view : 'Mahendra Pratap Singh Estate (Repeal) Act, 1960'}, 
    {value : 'Maintenance and Welfare of Parents and Senior Citizens Act',view : 'Maintenance and Welfare of Parents and Senior Citizens Act, 2007'}, 
    {value : 'Maintenance Orders Enforcement Act',view : 'Maintenance Orders Enforcement Act, 1921'}, 
    {value : 'Major Port Trusts Act',view : 'Major Port Trusts Act, 1963'}, 
    {value : 'Majority Act',view : 'Majority Act, 1875'}, 
    {value : 'Mangrol and Manavadar (Administration of Property) Act',view : 'Mangrol and Manavadar (Administration of Property) Act, 1949'}, 
    {value : 'Manipur and Tripura (Repeal of Laws) Act',view : 'Manipur and Tripura (Repeal of Laws) Act, 1958'}, 
    {value : 'Manipur (Courts) Act',view : 'Manipur (Courts) Act, 1955'}, 
    {value : 'Manipur (Hill Areas District Council) Act',view : 'Manipur (Hill Areas District Council) Act, 1971'}, 
    {value : 'ManipurLand Revenue and Land Reforms Act',view : 'ManipurLand Revenue and Land Reforms Act, 1960'}, 
    {value : 'Manipur Municipalities Act',view : 'Manipur Municipalities Act, 1994'}, 
    {value : 'Manipur Panchayati Raj Act',view : 'Manipur Panchayati Raj Act, 1994'}, 
    {value : 'Manipur (Sales of Motor Spirit and Lubricants) Taxation Act',view : 'Manipur (Sales of Motor Spirit and Lubricants) Taxation Act, 1962'}, 
    {value : 'Manipur University Act',view : 'Manipur University Act, 2005'}, 
    {value : 'Manipur (Village Authorities in Hill Areas) Act',view : 'Manipur (Village Authorities in Hill Areas) Act, 1956'}, 
    {value : 'Marine Insurance Act',view : 'Marine Insurance Act, 1963'}, 
    {value : 'Marine Products Export Development Authority Act',view : 'Marine Products Export Development Authority Act, 1972'}, 
    {value : 'Maritime Zones of India (Regulation of Fishing by ForeignVessels) Act',view : 'Maritime Zones of India (Regulation of Fishing by ForeignVessels) Act, 1981'}, 
    {value : 'Marking of Heavy Packages Act',view : 'Marking of Heavy Packages Act, 1951'}, 
    {value : 'Marriages Validation Act',view : 'Marriages Validation Act, 1892'}, 
    {value : 'Married Women\'s Property Act',view : 'Married Women\'s Property Act, 1874'}, 
    {value : 'Married Women\'s Property (Extension) Act',view : 'Married Women\'s Property (Extension) Act, 1959'}, 
    {value : 'Maruti Limited (Acquisition and Transfer of Undertakings)Act',view : 'Maruti Limited (Acquisition and Transfer of Undertakings)Act, 1980'}, 
    {value : 'Maternity Benefit Act',view : 'Maternity Benefit Act, 1961'}, 
    {value : 'Maulana Azad National Urdu University Act',view : 'Maulana Azad National Urdu University Act, 1996'}, 
    {value : 'Medical Termination of Pregnancy Act',view : 'Medical Termination of Pregnancy Act, 1971'}, 
    {value : 'Medicinal and Toilet Preparations (Excise Duties) Act',view : 'Medicinal and Toilet Preparations (Excise Duties) Act, 1955'}, 
    {value : 'Mental Health Act',view : 'Mental Health Act, 1987'}, 
    {value : 'Merchant Shipping Act',view : 'Merchant Shipping Act, 1958'}, 
    {value : 'Merchant Shipping (Amendment) Act',view : 'Merchant Shipping (Amendment) Act, 1986'}, 
    {value : 'Merchant Shipping (Amendment) Act',view : 'Merchant Shipping (Amendment) Act, 1987'}, 
    {value : 'Merged States (Laws) Act',view : 'Merged States (Laws) Act, 1949'}, 
    {value : 'Metal Corporation (Nationalisation and Miscellaneous Provisions) Act',view : 'Metal Corporation (Nationalisation and Miscellaneous Provisions) Act, 1976'}, 
    {value : 'Metal Tokens Act',view : 'Metal Tokens Act, 1889'}, 
    {value : 'Metro Railways (Construction of Works) Act',view : 'Metro Railways (Construction of Works) Act, 1978'}, 
    {value : 'Mica Mines Labour Welfare Fund Act',view : 'Mica Mines Labour Welfare Fund Act, 1946'}, 
    {value : 'Mines Act',view : 'Mines Act, 1952'}, 
    {value : 'Mines and Minerals (Development and Regulation ) Act',view : 'Mines and Minerals (Development and Regulation ) Act, 1957'}, 
    {value : 'Minimum Wages Act',view : 'Minimum Wages Act, 1948'}, 
    {value : 'Mirzapur Stone Mahal Act',view : 'Mirzapur Stone Mahal Act, 1886'}, 
    {value : 'Miscellaneous Personal Laws (Extension) Act',view : 'Miscellaneous Personal Laws (Extension) Act, 1959'}, 
    {value : 'Mizoram University Act',view : 'Mizoram University Act, 2000'}, 
    {value : 'Mogul Line Limited (Acquisition of Shares) Act',view : 'Mogul Line Limited (Acquisition of Shares) Act, 1984'}, 
    {value : 'Monopolies and Restrictive Trade Practices Act',view : 'Monopolies and Restrictive Trade Practices Act, 1969'}, 
    {value : 'Motor Transport Workers Act',view : 'Motor Transport Workers Act, 1961'}, 
    {value : 'Motor Vehicles Act',view : 'Motor Vehicles Act, 1988'}, 
    {value : 'Multimodal Transportation of Goods Act',view : 'Multimodal Transportation of Goods Act, 1993'}, 
    {value : 'Multi-State Co-operative Societies Act',view : 'Multi-State Co-operative Societies Act, 2002'}, 
    {value : 'Municipal Taxation Act',view : 'Municipal Taxation Act, 1881'}, 
    {value : 'Murshidabad Act',view : 'Murshidabad Act, 1891'}, 
    {value : 'Murshidabad Estate Administration Act',view : 'Murshidabad Estate Administration Act, 1933'}, 
    {value : 'Muslim Personal Law (Shariat) Application Act',view : 'Muslim Personal Law (Shariat) Application Act, 1937'}, 
    {value : 'Muslim Women (Protection of Rights on Divorce) Act',view : 'Muslim Women (Protection of Rights on Divorce) Act, 1986'}, 
    {value : 'Mussalman Wakf Act',view : 'Mussalman Wakf Act, 1923'}, 
    {value : 'Mussalman Wakf Validating Act',view : 'Mussalman Wakf Validating Act, 1913'}, 
    {value : 'Mussalman Wakf Validating Act',view : 'Mussalman Wakf Validating Act, 1930'}, 
    {value : 'Naga Hills-Tuensang Area Act',view : 'Naga Hills-Tuensang Area Act, 1957'}, 
    {value : 'NagalandUniversity Act',view : 'NagalandUniversity Act, 1989'}, 
    {value : 'Nalanda University Act',view : 'Nalanda University Act, 2010'}, 
    {value : 'Narcotic Drugs and Psychotropic Substances Act',view : 'Narcotic Drugs and Psychotropic Substances Act, 1985'}, 
    {value : 'National Bank for Agriculture and Rural Development Act',view : 'National Bank for Agriculture and Rural Development Act, 1981'}, 
    {value : 'National Cadet Corps Act',view : 'National Cadet Corps Act, 1948'}, 
    {value : 'National Capital Territory of Delhi Laws (Special Provisions) Act',view : 'National Capital Territory of Delhi Laws (Special Provisions) Act, 2007'}, 
    {value : 'National Capital Territory of Delhi Laws (Special Provisions) Act',view : 'National Capital Territory of Delhi Laws (Special Provisions) Act, 2011'}, 
    {value : 'National Capital Territory of Delhi Laws (Special Provisions) Second Act',view : 'National Capital Territory of Delhi Laws (Special Provisions) Second Act, 2017'}, 
    {value : 'National Capital Region Planning Board Act',view : 'National Capital Region Planning Board Act, 1985'}, 
    {value : 'National Commission for Backward Classes Act',view : 'National Commission for Backward Classes Act, 1993'}, 
    {value : 'National Commission for Minorities Act',view : 'National Commission for Minorities Act, 1992'}, 
    {value : 'National Commission for Minority Educational Institutions Act',view : 'National Commission for Minority Educational Institutions Act, 2004'}, 
    {value : 'National Commission for Safai Karamcharis Act',view : 'National Commission for Safai Karamcharis Act, 1993'}, 
    {value : 'National Commission for Women Act',view : 'National Commission for Women Act, 1990'}, 
    {value : 'National Company (Acquisition and Transfer of Undertakings) Act',view : 'National Company (Acquisition and Transfer of Undertakings) Act, 1980'}, 
    {value : 'National Co-operative Development Corporation Act',view : 'National Co-operative Development Corporation Act, 1962'}, 
    {value : 'National Council for Teacher Education Act',view : 'National Council for Teacher Education Act, 1993'}, 
    {value : 'National Dairy Development Board Act',view : 'National Dairy Development Board Act, 1987'}, 
    {value : 'National Environment Appellate Authority Act',view : 'National Environment Appellate Authority Act, 1997'}, 
    {value : 'National Environment Tribunal Act',view : 'National Environment Tribunal Act, 1995'}, 
    {value : 'National Food Security Act',view : 'National Food Security Act, 2013'}, 
    {value : 'National Green Tribunal Act',view : 'National Green Tribunal Act, 2010'}, 
    {value : 'National Highways Act',view : 'National Highways Act, 1956'}, 
    {value : 'National Highways Authority of India Act',view : 'National Highways Authority of India Act, 1988'}, 
    {value : 'National Housing Bank Act',view : 'National Housing Bank Act, 1987'}, 
    {value : 'National Institute of Fashion Technology Act',view : 'National Institute of Fashion Technology Act, 2006'}, 
    {value : 'National Institute of Pharmaceutical Education and Research Act',view : 'National Institute of Pharmaceutical Education and Research Act, 1998'}, 
    {value : 'National Investigation Agency Act',view : 'National Investigation Agency Act, 2008'}, 
    {value : 'National Jute Board Act',view : 'National Jute Board Act, 2008'}, 
    {value : 'National Library of India Act',view : 'National Library of India Act, 1976'}, 
    {value : 'National Oil Seeds and Vegetable Oils Development Board Act',view : 'National Oil Seeds and Vegetable Oils Development Board Act, 1983'}, 
    {value : 'National Rural Employment Guarantee Act',view : 'National Rural Employment Guarantee Act, 2005'}, 
    {value : 'National Rural Employment Guarantee (Amendment) Act',view : 'National Rural Employment Guarantee (Amendment) Act, 2009'}, 
    {value : 'National Rural Employment Guarantee (Extension to Jammu And Kashmir) Act',view : 'National Rural Employment Guarantee (Extension to Jammu And Kashmir) Act, 2007'}, 
    {value : 'National Institute of Design Act',view : 'National Institute of Design Act, 2014'}, 
    {value : 'National Institutes of Technology Act',view : 'National Institutes of Technology Act, 2007'}, 
    {value : 'National Institute of Mental Health and Neuro-Sciences Bangalore Act',view : 'National Institute of Mental Health and Neuro-Sciences Bangalore Act, 2012'}, 
    {value : 'National Security Act',view : 'National Security Act, 1980'}, 
    {value : 'National Security Guard Act',view : 'National Security Guard Act, 1986'}, 
    {value : 'National Service Act',view : 'National Service Act, 1972'}, 
    {value : 'National Tax Tribunal Act',view : 'National Tax Tribunal Act, 2005'}, 
    {value : 'National Thermal Power Corporation Limited, the National Hydroelectric Power Corporation Limited and the North-Eastern Electric Power Corporation Limited (Acquisition and Transfer of Power Transmission Systems) Act',view : 'National Thermal Power Corporation Limited, the National Hydroelectric Power Corporation Limited and the North-Eastern Electric Power Corporation Limited (Acquisition and Transfer of Power Transmission Systems) Act, 1993'}, 
    {value : 'National Trust for Welfare of Persons with Autism, Cerebral Palsy, Mental Retardation and Multiple Disabilities Act',view : 'National Trust for Welfare of Persons with Autism, Cerebral Palsy, Mental Retardation and Multiple Disabilities Act, 1999'}, 
    {value : 'National Waterway (Allahabad-Haldia Stretch of the Ganga-Bhagirathi-Hooghly River) Act',view : 'National Waterway (Allahabad-Haldia Stretch of the Ganga-Bhagirathi-Hooghly River) Act, 1982'}, 
    {value : 'National Waterway (Kollam-Kottapuram Stretch of West Coast Canal and Champakara and Udyogmandal Canals) Act',view : 'National Waterway (Kollam-Kottapuram Stretch of West Coast Canal and Champakara and Udyogmandal Canals) Act, 1992'}, 
    {value : 'National Waterway (Sadiya-Dhubri Stretch of the BrahmaputraRiver) Act',view : 'National Waterway (Sadiya-Dhubri Stretch of the BrahmaputraRiver) Act, 1988'}, 
    {value : 'National Waterway (Talcher-Dhamra Stretch of Rivers, Geonkhali-Charbatia Stretch of East Coast Canal, Charbatia Dhamra Stretch of Matai River and Mahanadi Delta Rivers) Act',view : 'National Waterway (Talcher-Dhamra Stretch of Rivers, Geonkhali-Charbatia Stretch of East Coast Canal, Charbatia Dhamra Stretch of Matai River and Mahanadi Delta Rivers) Act, 2008'}, 
    {value : 'National Waterway (Kakinadi-Puducherry Stretch of Canals and the Kaluvelly Tank Vijayawada Stretch of River Krishna) Act',view : 'National Waterway (Kakinadi-Puducherry Stretch of Canals and the Kaluvelly Tank Vijayawada Stretch of River Krishna) Act, 2008'}, 
    {value : 'Naval and Aircraft Prize Act',view : 'Naval and Aircraft Prize Act, 1971'}, 
    {value : 'Navy Act',view : 'Navy Act, 1957'}, 
    {value : 'National Capital Territory of Delhi Laws (Special Provisions) Act',view : 'National Capital Territory of Delhi Laws (Special Provisions) Act, 2011'}, 
    {value : 'National Capital Territory of Delhi Laws (Special Provisions)Second Act',view : 'National Capital Territory of Delhi Laws (Special Provisions)Second Act, 2011'}, 
    {value : 'Negotiable Instruments Act',view : 'Negotiable Instruments Act, 1881'}, 
    {value : 'New Delhi Municipal Council Act',view : 'New Delhi Municipal Council Act, 1994'}, 
    {value : 'Newspaper (Price and Page) Act',view : 'Newspaper (Price and Page) Act, 1956'}, 
    {value : 'Newspaper (Price and Page Continuance) Act',view : 'Newspaper (Price and Page Continuance) Act, 1961'}, 
    {value : 'Neyveli Lignite Corporation Limited (Acquisition andTransfer of Power Transmission System) Act',view : 'Neyveli Lignite Corporation Limited (Acquisition andTransfer of Power Transmission System) Act, 1994'}, 
    {value : 'North-Eastern Area (Reorganisation) Act',view : 'North-Eastern Area (Reorganisation) Act, 1971'}, 
    {value : 'North-Eastern Council Act',view : 'North-Eastern Council Act, 1971'}, 
    {value : 'North-Eastern Hill University Act',view : 'North-Eastern Hill University Act, 1973'}, 
    {value : 'North-Eastern Provinces Village and Road Police Act',view : 'North-Eastern Provinces Village and Road Police Act, 1873'}, 
    {value : 'Northern India Canal and Drainage Act',view : 'Northern India Canal and Drainage Act, 1873'}, 
    {value : 'Northern India Ferries Act',view : 'Northern India Ferries Act, 1878'}, 
    {value : 'Notaries Act',view : 'Notaries Act, 1952'}, 
    {value : 'Oaths Act',view : 'Oaths Act, 1969'}, 
    {value : 'Obstructions in Fairways Act',view : 'Obstructions in Fairways Act, 1881'}, 
    {value : 'Official Languages Act',view : 'Official Languages Act, 1963'}, 
    {value : 'Official Secrets Act',view : 'Official Secrets Act, 1923'}, 
    {value : 'Official Trustees Act',view : 'Official Trustees Act, 1913'}, 
    {value : 'Offshore Areas Minerals (Development and Regulation) Act',view : 'Offshore Areas Minerals (Development and Regulation) Act, 2003'}, 
    {value : 'Oil and Natural Gas Commission (Transfer of Undertaking and Repeal) Act',view : 'Oil and Natural Gas Commission (Transfer of Undertaking and Repeal) Act, 1993'}, 
    {value : 'Oil Fields (Regulation and Development) Act',view : 'Oil Fields (Regulation and Development) Act, 1948'}, 
    {value : 'Oil Industry (Development) Act',view : 'Oil Industry (Development) Act, 1974'}, 
    {value : 'Opium and Revenue Laws (Extension of Application) Act',view : 'Opium and Revenue Laws (Extension of Application) Act, 1950'}, 
    {value : 'Oriental Gas Company Act',view : 'Oriental Gas Company Act, 1857'}, 
    {value : 'Orissa Weights and Measures (Delhi Repeal) Act',view : 'Orissa Weights and Measures (Delhi Repeal) Act, 1958'}, 
    {value : 'Orphanages and Other Charitable Homes (Supervision andControl) Act',view : 'Orphanages and Other Charitable Homes (Supervision andControl) Act, 1960'}, 
    {value : 'Orissa (Alteration of Name) Act',view : 'Orissa (Alteration of Name) Act, 2011'}, 
    {value : 'Oudh Estates Act',view : 'Oudh Estates Act, 1869'}, 
    {value : 'Oudh Laws Act',view : 'Oudh Laws Act, 1876'}, 
    {value : 'Oudh Sub-settlement Act',view : 'Oudh Sub-settlement Act, 1866'}, 
    {value : 'Oudh Taluqdars\' Relief Act',view : 'Oudh Taluqdars\' Relief Act, 1870'}, 
    {value : 'Oudh Wasikas Act',view : 'Oudh Wasikas Act, 1886'}, 
    {value : 'Parel Investment and Trading Private Limited and Domestic Gas Private Limited (Taking Over of Management) Repeal Act',view : 'Parel Investment and Trading Private Limited and Domestic Gas Private Limited (Taking Over of Management) Repeal Act, 2005'}, 
    {value : 'Parliament (Prevention of Disqualification) Act',view : 'Parliament (Prevention of Disqualification) Act, 1959'}, 
    {value : 'Parliamentary Proceedings (Protection of Publication) Act',view : 'Parliamentary Proceedings (Protection of Publication) Act, 1977'}, 
    {value : 'Parsi Marriage and Divorce Act',view : 'Parsi Marriage and Divorce Act, 1936'}, 
    {value : 'Part B States (Laws) Act',view : 'Part B States (Laws) Act, 1951'}, 
    {value : 'Part B States Marriages Validating Act',view : 'Part B States Marriages Validating Act, 1952'}, 
    {value : 'Part C States Miscellaneous Laws (Repealing) Act',view : 'Part C States Miscellaneous Laws (Repealing) Act, 1951'}, 
    {value : 'Parliamentary Proceedings (Protection of Publication) Repeal Act',view : 'Parliamentary Proceedings (Protection of Publication) Repeal Act, 1976'}, 
    {value : 'Partition Act',view : 'Partition Act, 1893'}, 
    {value : 'Partition of Revenue-paying Estates Act,Repealed',view : 'Partition of Revenue-paying Estates Act, 1863,Repealed'}, 
    {value : 'Passport (Entry into India) Act',view : 'Passport (Entry into India) Act, 1920'}, 
    {value : 'Passports Act',view : 'Passports Act, 1967'}, 
    {value : 'Patents Act',view : 'Patents Act, 1970'}, 
    {value : 'Payment and Settlement Systems Act',view : 'Payment and Settlement Systems Act, 2007'}, 
    {value : 'Payment of Bonus Act',view : 'Payment of Bonus Act, 1965'}, 
    {value : 'Payment of Gratuity Act',view : 'Payment of Gratuity Act, 1972'}, 
    {value : 'Payment of Gratuity (Amendment) Act',view : 'Payment of Gratuity (Amendment) Act, 1987'}, 
    {value : 'Payment of Wages Act',view : 'Payment of Wages Act, 1936'}, 
    {value : 'Pensions Act',view : 'Pensions Act, 1871'}, 
    {value : 'Pension Fund Regulatory and Development Authority Act',view : 'Pension Fund Regulatory and Development Authority Act, 2013'}, 
    {value : 'Personal Injuries (Compensation Insurance) Act',view : 'Personal Injuries (Compensation Insurance) Act, 1963'}, 
    {value : 'Persons With Disabilities (Equal Opportunities Participation) Act',view : 'Persons With Disabilities (Equal Opportunities Participation) Act, 1995'}, 
    {value : 'Petroleum Act',view : 'Petroleum Act, 1934'}, 
    {value : 'Petroleum and Minerals Pipelines (Acquisition of Right ofUser in Land) Act',view : 'Petroleum and Minerals Pipelines (Acquisition of Right ofUser in Land) Act, 1962'}, 
    {value : 'Petroleum and Natural Gas Regulatory Board Act',view : 'Petroleum and Natural Gas Regulatory Board Act, 2006'}, 
    {value : 'Pharmacy Act',view : 'Pharmacy Act, 1948'}, 
    {value : 'Places of Worship (Special Provisions) Act',view : 'Places of Worship (Special Provisions) Act, 1991'}, 
    {value : 'Plantations Labour Act',view : 'Plantations Labour Act, 1951'}, 
    {value : 'Poisons Act',view : 'Poisons Act, 1919'}, 
    {value : 'Police Act',view : 'Police Act, 1861'}, 
    {value : 'Police Act',view : 'Police Act, 1888'}, 
    {value : 'Police Act',view : 'Police Act, 1949'}, 
    {value : 'Police Forces (Restriction of Rights) Act',view : 'Police Forces (Restriction of Rights) Act, 1966'}, 
    {value : 'Police (Incitement to Disaffection) Act',view : 'Police (Incitement to Disaffection) Act, 1922'}, 
    {value : 'Pondicherry (Administration) Act',view : 'Pondicherry (Administration) Act, 1962'}, 
    {value : 'Pondicherry (Extension of Laws) Act',view : 'Pondicherry (Extension of Laws) Act, 1968'}, 
    {value : 'Pondicherry University Act',view : 'Pondicherry University Act, 1985'}, 
    {value : 'Pondichery (Alteration of Name) Act',view : 'Pondichery (Alteration of Name) Act, 2006'}, 
    {value : 'Porahat Estate Act',view : 'Porahat Estate Act, 1893'}, 
    {value : 'Post-Graduate Institute of Medical Education and Research, Chandigarh Act',view : 'Post-Graduate Institute of Medical Education and Research, Chandigarh Act, 1966'}, 
    {value : 'Post Office Cash Certificates Act',view : 'Post Office Cash Certificates Act, 1917'}, 
    {value : 'Powers-of-Attorney Act',view : 'Powers-of-Attorney Act, 1882'}, 
    {value : 'Prasar Bharati (Broadcasting Corporation of India) Act',view : 'Prasar Bharati (Broadcasting Corporation of India) Act, 1990'}, 
    {value : 'Preference Shares (Regulation of Dividend) Act',view : 'Preference Shares (Regulation of Dividend) Act, 1960'}, 
    {value : 'Pre-conception and Pre-natal Diagnostic Techniques (Prohibition of Sex Selection) Act',view : 'Pre-conception and Pre-natal Diagnostic Techniques (Prohibition of Sex Selection) Act, 1994'}, 
    {value : 'Presidency Small Cause Courts Act',view : 'Presidency Small Cause Courts Act, 1882'}, 
    {value : 'Presidency-towns Insolvency Act',view : 'Presidency-towns Insolvency Act, 1909'}, 
    {value : 'President (Discharge of Functions) Act',view : 'President (Discharge of Functions) Act, 1969'}, 
    {value : 'Presidential and Vice-Presidential Elections Act',view : 'Presidential and Vice-Presidential Elections Act, 1952'}, 
    {value : 'President\'s Emoluments and Pension Act',view : 'President\'s Emoluments and Pension Act, 1951'}, 
    {value : 'Press and Registration of Books Act',view : 'Press and Registration of Books Act, 1867'}, 
    {value : 'Press Council Act',view : 'Press Council Act, 1978'}, 
    {value : 'Protection of Children From Sexual Offences Act',view : 'Protection of Children From Sexual Offences Act, 2012'}, 
    {value : 'Prevention and Control of Infections and Contagious Diseases in Animals Act',view : 'Prevention and Control of Infections and Contagious Diseases in Animals Act, 2009'}, 
    {value : 'Prevention of Blackmarketing and Maintenance ofSupplies of Essential Commodities Act',view : 'Prevention of Blackmarketing and Maintenance ofSupplies of Essential Commodities Act, 1980'}, 
    {value : 'Prevention of Corruption Act',view : 'Prevention of Corruption Act, 1988'}, 
    {value : 'Prevention of Cruelty to Animals Act',view : 'Prevention of Cruelty to Animals Act, 1960'}, 
    {value : 'Prevention of Damage to Public Property Act',view : 'Prevention of Damage to Public Property Act, 1984'}, 
    {value : 'Prevention of Food Adulteration Act',view : 'Prevention of Food Adulteration Act, 1954'}, 
    {value : 'Prevention of Money Laundering Act',view : 'Prevention of Money Laundering Act, 2002'}, 
    {value : 'Prevention of Illicit Traffic in Narcotic Drugs and Psychotropic Substances Act',view : 'Prevention of Illicit Traffic in Narcotic Drugs and Psychotropic Substances Act, 1988'}, 
    {value : 'Prevention of Insults to National Honour Act',view : 'Prevention of Insults to National Honour Act, 1971'}, 
    {value : 'Prevention of Seditious Meetings Act',view : 'Prevention of Seditious Meetings Act, 1911'}, 
    {value : 'Prevention of Terrorism (Repeal) Act',view : 'Prevention of Terrorism (Repeal) Act, 2004'}, 
    {value : 'Prisoners Act',view : 'Prisoners Act, 1900'}, 
    {value : 'Prisoners (Attendance in Courts) Act',view : 'Prisoners (Attendance in Courts) Act, 1955'}, 
    {value : 'Prisons Act',view : 'Prisons Act, 1894'}, 
    {value : 'Private Security Agencies (Regulation) Act',view : 'Private Security Agencies (Regulation) Act, 2005'}, 
    {value : 'Prize Chits and Money Circulation Scheme (Banning) Act',view : 'Prize Chits and Money Circulation Scheme (Banning) Act, 1978'}, 
    {value : 'Prize Competitions Act',view : 'Prize Competitions Act, 1955'}, 
    {value : 'Probation of Offenders Act',view : 'Probation of Offenders Act, 1958'}, 
    {value : 'Produce Cess Laws (Abolition) Action,2006',view : 'Produce Cess Laws (Abolition) Action,2006'}, 
    {value : 'Professions Tax Limitation (Amendment and Validation) Act',view : 'Professions Tax Limitation (Amendment and Validation) Act, 1949'}, 
    {value : 'Prohibition of Child Marriage Act',view : 'Prohibition of Child Marriage Act, 2007'}, 
    {value : 'Prohibition of Employment as Manual Scavengers and Their Rehabilitation Act',view : 'Prohibition of Employment as Manual Scavengers and Their Rehabilitation Act, 2013'}, 
    {value : 'Protection of Civil Rights Act',view : 'Protection of Civil Rights Act, 1955'}, 
    {value : 'Protection of Human Rights Act',view : 'Protection of Human Rights Act, 1993'}, 
    {value : 'Protection of Plant Varieties and Farmers Right Act',view : 'Protection of Plant Varieties and Farmers Right Act, 2001'}, 
    {value : 'Protection of Women from Domestic Violence Act',view : 'Protection of Women from Domestic Violence Act, 2005'}, 
    {value : 'Provident Funds Act',view : 'Provident Funds Act, 1925'}, 
    {value : 'Provincial Insolvency Act',view : 'Provincial Insolvency Act, 1920'}, 
    {value : 'Provincial Small Cause Courts Act',view : 'Provincial Small Cause Courts Act, 1887'}, 
    {value : 'Provisional Collection of Taxes Act',view : 'Provisional Collection of Taxes Act, 1931'}, 
    {value : 'Provisions of the Panchayats (Extension to the ScheduledAreas) Act',view : 'Provisions of the Panchayats (Extension to the ScheduledAreas) Act, 1996'}, 
    {value : 'Public Accountants\' Defaults Act',view : 'Public Accountants\' Defaults Act, 1850'}, 
    {value : 'Public Debt Act',view : 'Public Debt Act, 1944'}, 
    {value : 'Public Employment (Requirement as to Residence) Act',view : 'Public Employment (Requirement as to Residence) Act, 1957'}, 
    {value : 'Public Financial Institutions (Obligation as to Fidelityand Secrecy) Act',view : 'Public Financial Institutions (Obligation as to Fidelityand Secrecy) Act, 1983'}, 
    {value : 'Public Gambling Act',view : 'Public Gambling Act, 1867'}, 
    {value : 'Public Liability Insurance Act',view : 'Public Liability Insurance Act, 1991'}, 
    {value : 'Public Premises (Eviction of Unauthorised Occupants) Act',view : 'Public Premises (Eviction of Unauthorised Occupants) Act, 1971'}, 
    {value : 'Public Provident Fund Act',view : 'Public Provident Fund Act, 1968'}, 
    {value : 'Public Records Act',view : 'Public Records Act, 1993'}, 
    {value : 'Public Sector Iron and Steel Companies(Restructuring) and Miscellaneous Provisions Act',view : 'Public Sector Iron and Steel Companies(Restructuring) and Miscellaneous Provisions Act, 1978'}, 
    {value : 'Public Servants (Inquiries) Act',view : 'Public Servants (Inquiries) Act, 1850'}, 
    {value : 'Public Suits Validation Act',view : 'Public Suits Validation Act, 1932'}, 
    {value : 'Public Wakfs (Extension of Limitation) Act',view : 'Public Wakfs (Extension of Limitation) Act, 1959'}, 
    {value : 'Punjab District Boards Act',view : 'Punjab District Boards Act, 1883'}, 
    {value : 'Punjab Disturbed Areas Act',view : 'Punjab Disturbed Areas Act, 1983'}, 
    {value : 'Punjab Excise (Delhi Amendment) Act',view : 'Punjab Excise (Delhi Amendment) Act, 1979'}, 
    {value : 'Punjab Gram Panchayat Act',view : 'Punjab Gram Panchayat Act, 1952'}, 
    {value : 'Punjab Land Revenue Act',view : 'Punjab Land Revenue Act, 1887'}, 
    {value : 'Punjab Laws Act',view : 'Punjab Laws Act, 1872'}, 
    {value : 'Punjab Legislative Council (Abolition) Act',view : 'Punjab Legislative Council (Abolition) Act, 1969'}, 
    {value : 'Punjab Municipal Corporation Law (Extension to Chandigarh) Act',view : 'Punjab Municipal Corporation Law (Extension to Chandigarh) Act, 1994'}, 
    {value : 'Punjab Reorganisation Act',view : 'Punjab Reorganisation Act, 1966'}, 
    {value : 'Punjab State Legislature (Delegation of Powers) Act',view : 'Punjab State Legislature (Delegation of Powers) Act, 1984'}, 
    {value : 'Punjab Tenancy Act',view : 'Punjab Tenancy Act, 1887'}, 
    {value : 'Railway Claims Tribunal Act',view : 'Railway Claims Tribunal Act, 1987'}, 
    {value : 'Railway Companies (Emergency Provisions) Act',view : 'Railway Companies (Emergency Provisions) Act, 1951'}, 
    {value : 'Railway Property (Unlawful Possession) Act',view : 'Railway Property (Unlawful Possession) Act, 1966'}, 
    {value : 'Railway Protection Force Act',view : 'Railway Protection Force Act, 1957'}, 
    {value : 'Railway Protection Force (Amendment) Act',view : 'Railway Protection Force (Amendment) Act, 1985'}, 
    {value : 'Railways Act',view : 'Railways Act, 1989'}, 
    {value : 'Railways Employment of Members of the Armed Forces Act',view : 'Railways Employment of Members of the Armed Forces Act, 1965'}, 
    {value : 'Railways (Local Authorities\' Taxation) Act',view : 'Railways (Local Authorities\' Taxation) Act, 1941'}, 
    {value : 'Raipur and Khattra Laws Act',view : 'Raipur and Khattra Laws Act, 1879'}, 
    {value : 'Rajasthan and Madhya Pradesh (Transfer of Territories) Act',view : 'Rajasthan and Madhya Pradesh (Transfer of Territories) Act, 1959'}, 
    {value : 'Rajghat Samadhi Act',view : 'Rajghat Samadhi Act, 1951'}, 
    {value : 'Rajiv Gandhi Institute of Petroleum Technology Act',view : 'Rajiv Gandhi Institute of Petroleum Technology Act, 2007'}, 
    {value : 'Rajiv Gandhi University Act',view : 'Rajiv Gandhi University Act, 2007'}, 
    {value : 'Rajiv Gandhi National Institutes of Youth DevelopmentAct',view : 'Rajiv Gandhi National Institutes of Youth DevelopmentAct, 2012'}, 
    {value : 'Rajiv Gandhi National Aviation University Act',view : 'Rajiv Gandhi National Aviation University Act, 2013'}, 
    {value : 'Rampur Raza Library Act',view : 'Rampur Raza Library Act, 1975'}, 
    {value : 'Rani Lakshmi Bai Central Agricultural University Act',view : 'Rani Lakshmi Bai Central Agricultural University Act, 2014'}, 
    {value : 'Reciprocity Act',view : 'Reciprocity Act, 1943'}, 
    {value : 'Recovery of Debts Due to Banks and Financial Institutions Act',view : 'Recovery of Debts Due to Banks and Financial Institutions Act, 1993'}, 
    {value : 'Red Cross Society (Allocation of Property) Act',view : 'Red Cross Society (Allocation of Property) Act, 1936'}, 
    {value : 'Reformatory Schools Act',view : 'Reformatory Schools Act, 1897'}, 
    {value : 'Regional Rural Banks Act',view : 'Regional Rural Banks Act, 1976'}, 
    {value : 'Registration Act',view : 'Registration Act, 1908'}, 
    {value : 'Registration of Births and Deaths Act',view : 'Registration of Births and Deaths Act, 1969'}, 
    {value : 'Registration of Foreigners Act',view : 'Registration of Foreigners Act, 1939'}, 
    {value : 'Rehabilitation Council of India Act',view : 'Rehabilitation Council of India Act, 1992'}, 
    {value : 'Rehabilitation Finance Administration Act',view : 'Rehabilitation Finance Administration Act, 1948'}, 
    {value : 'Religious Endowments Act',view : 'Religious Endowments Act, 1863'}, 
    {value : 'Religious Institutions (Prevention of Misuse) Act',view : 'Religious Institutions (Prevention of Misuse) Act, 1988'}, 
    {value : 'Religious Societies Act',view : 'Religious Societies Act, 1880'}, 
    {value : 'Remittances of Foreign Exchange and Investment in Foreign Exchange Bonds (Immunities and Exemptions) Act',view : 'Remittances of Foreign Exchange and Investment in Foreign Exchange Bonds (Immunities and Exemptions) Act, 1991'}, 
    {value : 'Rent Recovery Act',view : 'Rent Recovery Act, 1853'}, 
    {value : 'Repatriation of Prisoners Act',view : 'Repatriation of Prisoners Act, 2003'}, 
    {value : 'Representation of the People Act',view : 'Representation of the People Act, 1950'}, 
    {value : 'Representation of the People (Amendment) Act',view : 'Representation of the People (Amendment) Act, 1989'}, 
    {value : 'Representation of the People Act',view : 'Representation of the People Act, 1951'}, 
    {value : 'Representation of People (Amendment) Act',view : 'Representation of People (Amendment) Act, 2010'}, 
    {value : 'Representation of the People (Miscellaneous Provisions) Act',view : 'Representation of the People (Miscellaneous Provisions) Act, 1956'}, 
    {value : 'Requisitioned Land (Apportionment of Compensation) Act',view : 'Requisitioned Land (Apportionment of Compensation) Act, 1949'}, 
    {value : 'Requisitioning and Acquisition of Immovable Property Act',view : 'Requisitioning and Acquisition of Immovable Property Act, 1952'}, 
    {value : 'Research and Development Cess Act',view : 'Research and Development Cess Act, 1986'}, 
    {value : 'Reserve Bank (Transfer of Public Ownership) Act',view : 'Reserve Bank (Transfer of Public Ownership) Act, 1948'}, 
    {value : 'Reserve Bank of India Act',view : 'Reserve Bank of India Act, 1934'}, 
    {value : 'Reserve Bank of India (Amendment and Miscellaneous Provisions) Act',view : 'Reserve Bank of India (Amendment and Miscellaneous Provisions) Act, 1953'}, 
    {value : 'Reserve and Auxiliary Air Forces Act',view : 'Reserve and Auxiliary Air Forces Act, 1952'}, 
    {value : 'Resettlement of Displaced Persons (Land Acquisition) Act',view : 'Resettlement of Displaced Persons (Land Acquisition) Act, 1948'}, 
    {value : 'Revenue Recovery Act',view : 'Revenue Recovery Act, 1890'}, 
    {value : 'Richardson and Cruddas Limited (Acquisition and Transfer of Undertakings) Act',view : 'Richardson and Cruddas Limited (Acquisition and Transfer of Undertakings) Act, 1972'}, 
    {value : 'Right of Children to Free and Compulsory Education Act',view : 'Right of Children to Free and Compulsory Education Act, 2009'}, 
    {value : 'Right to Information Act',view : 'Right to Information Act, 2005'}, 
    {value : 'Right to Fair Compensation and Transparency in Land Acquisition, Rehabilitation and Resettlement Act',view : 'Right to Fair Compensation and Transparency in Land Acquisition, Rehabilitation and Resettlement Act, 2013'}, 
    {value : 'River Boards Act',view : 'River Boards Act, 1956'}, 
    {value : 'Road Transport Corporations Act',view : 'Road Transport Corporations Act, 1950'}, 
    {value : 'Rubber Act',view : 'Rubber Act, 1947'}, 
    {value : 'SAARC Convention (Suppression of Terrorism) Act',view : 'SAARC Convention (Suppression of Terrorism) Act, 1993'}, 
    {value : 'Salar Jung Museum Act',view : 'Salar Jung Museum Act, 1961'}, 
    {value : 'Salaries and Allowances of Ministers Act',view : 'Salaries and Allowances of Ministers Act, 1952'}, 
    {value : 'Salaries and Allowances of Ministers (Amendment) Act',view : 'Salaries and Allowances of Ministers (Amendment) Act, 2010'}, 
    {value : 'Salaries and Allowances of Officers of Parliament Act',view : 'Salaries and Allowances of Officers of Parliament Act, 1953'}, 
    {value : 'Salary and Allowances of Leaders of Opposition in Parliament Act',view : 'Salary and Allowances of Leaders of Opposition in Parliament Act, 1977'}, 
    {value : 'Sale of Goods Act',view : 'Sale of Goods Act, 1930'}, 
    {value : 'Sales of Land for Revenue Arrears Act,repealed',view : 'Sales of Land for Revenue Arrears Act, 1845,repealed'}, 
    {value : 'Sales Promotion Employees (Conditions of Service) Act',view : 'Sales Promotion Employees (Conditions of Service) Act, 1976'}, 
    {value : 'Sales Tax Laws Validation Act',view : 'Sales Tax Laws Validation Act, 1956'}, 
    {value : 'Salt Cess Act',view : 'Salt Cess Act, 1953'}, 
    {value : 'Sarais Act',view : 'Sarais Act, 1867'}, 
    {value : 'Sashatra Seema Bal Act',view : 'Sashatra Seema Bal Act, 2007'}, 
    {value : 'Scheduled Areas (Assimilation of Laws) Act',view : 'Scheduled Areas (Assimilation of Laws) Act, 1951'}, 
    {value : 'Scheduled Areas (Assimilation of Laws) Act',view : 'Scheduled Areas (Assimilation of Laws) Act, 1953'}, 
    {value : 'Scheduled Castes and Scheduled Tribes Orders (Amendment) Act',view : 'Scheduled Castes and Scheduled Tribes Orders (Amendment) Act, 1956'}, 
    {value : 'Scheduled Castes and Scheduled Tribes Orders (Amendment) Act',view : 'Scheduled Castes and Scheduled Tribes Orders (Amendment) Act, 1976'}, 
    {value : 'Scheduled Castes and the Scheduled Tribes (Prevention of Atrocities) Act',view : 'Scheduled Castes and the Scheduled Tribes (Prevention of Atrocities) Act, 1989'}, 
    {value : 'Scheduled Securities (Hyderabad) Act',view : 'Scheduled Securities (Hyderabad) Act, 1949'}, 
    {value : 'Scheduled Tribes and other Traditional Forest Dwellers (Recognition of Forest Rights) Act',view : 'Scheduled Tribes and other Traditional Forest Dwellers (Recognition of Forest Rights) Act, 2006'}, 
    {value : 'Science and Engineering Research Board Act',view : 'Science and Engineering Research Board Act, 2009'}, 
    {value : 'Seamen\'s Provident Fund Act',view : 'Seamen\'s Provident Fund Act, 1966'}, 
    {value : 'Seaward Artillery Practice Act',view : 'Seaward Artillery Practice Act, 1949'}, 
    {value : 'Securities and Exchange Board of India Act',view : 'Securities and Exchange Board of India Act, 1992'}, 
    {value : 'Securities Contracts (Regulation) Act',view : 'Securities Contracts (Regulation) Act, 1956'}, 
    {value : 'Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest Act',view : 'Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest Act, 2002'}, 
    {value : 'Seeds Act',view : 'Seeds Act, 1966'}, 
    {value : 'Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act',view : 'Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013'}, 
    {value : 'Semiconductor Integrated Circuits Layout Design Act',view : 'Semiconductor Integrated Circuits Layout Design Act, 2000'}, 
    {value : 'Sheriff of Calcutta (Power of Custody) Act',view : 'Sheriff of Calcutta (Power of Custody) Act, 1931'}, 
    {value : 'Sheriffs\' Fees Act',view : 'Sheriffs\' Fees Act, 1852'}, 
    {value : 'Shillong (Rifle Range and Umlong) Cantonments Assimilation of Laws Act',view : 'Shillong (Rifle Range and Umlong) Cantonments Assimilation of Laws Act, 1954'}, 
    {value : 'Shipping Development Fund Committee (Abolition) Act',view : 'Shipping Development Fund Committee (Abolition) Act, 1986'}, 
    {value : 'Shore Nuisances (Bombay and Kolaba) Act',view : 'Shore Nuisances (Bombay and Kolaba) Act, 1853'}, 
    {value : 'Sick Industrial Companies (Special Provisions) Act',view : 'Sick Industrial Companies (Special Provisions) Act, 1985'}, 
    {value : 'Sick Industrial Companies (Special Provisions) Repeal Act',view : 'Sick Industrial Companies (Special Provisions) Repeal Act, 2003'}, 
    {value : 'Sick Textile Undertakings (Nationalisation) Act',view : 'Sick Textile Undertakings (Nationalisation) Act, 1974'}, 
    {value : 'Sick Textile Undertakings (Taking Over of Management) Act',view : 'Sick Textile Undertakings (Taking Over of Management) Act, 1972'}, 
    {value : 'Sikh Gurdwaras (Supplementary) Act',view : 'Sikh Gurdwaras (Supplementary) Act, 1925'}, 
    {value : 'Sikkim University Act',view : 'Sikkim University Act, 2007'}, 
    {value : 'Sir Dinshaw Maneckjee Petit Act',view : 'Sir Dinshaw Maneckjee Petit Act, 1893'}, 
    {value : 'Sir Jamsetjee Jejeebhoy Baronetcy Act',view : 'Sir Jamsetjee Jejeebhoy Baronetcy Act, 1915'}, 
    {value : 'Slum Areas (Improvement and Clearance) Act',view : 'Slum Areas (Improvement and Clearance) Act, 1956'}, 
    {value : 'Small Industries Development Bank of India Act Undertakings) Act',view : 'Small Industries Development Bank of India Act Undertakings) Act, 1989'}, 
    {value : 'Smugglers and Foreign Exchange Manipulators (Forfeiture of Property) Act',view : 'Smugglers and Foreign Exchange Manipulators (Forfeiture of Property) Act, 1976'}, 
    {value : 'Societies Registration Act',view : 'Societies Registration Act, 1860'}, 
    {value : 'Sonthal Parganas Act',view : 'Sonthal Parganas Act, 1855'}, 
    {value : 'Sonthal Parganas Act,South Asian University Act',view : 'Sonthal Parganas Act, 1857,South Asian University Act, 2009'}, 
    {value : 'Special Court (Trial of Offences Relating to Transactions in Securities) Act',view : 'Special Court (Trial of Offences Relating to Transactions in Securities) Act, 1992'}, 
    {value : 'Special Criminal Courts (Jurisdiction) Act',view : 'Special Criminal Courts (Jurisdiction) Act, 1950'}, 
    {value : 'Special Economic Zones Act',view : 'Special Economic Zones Act, 2005'}, 
    {value : 'Special Marriage Act',view : 'Special Marriage Act, 1954'}, 
    {value : 'Special Protection Group Act',view : 'Special Protection Group Act, 1988'}, 
    {value : 'Specific Relief Act',view : 'Specific Relief Act, 1963'}, 
    {value : 'Spices Board Act',view : 'Spices Board Act, 1986'}, 
    {value : 'The Spirituous Preparations (Inter-State Trade and Commerce) Control (Repeal) Act',view : 'The Spirituous Preparations (Inter-State Trade and Commerce) Control (Repeal) Act, 2006'}, 
    {value : 'Sports Broadcasting Signals (Mandatory sharing with Prasar Bharti) Act',view : 'Sports Broadcasting Signals (Mandatory sharing with Prasar Bharti) Act, 2007'}, 
    {value : 'Sree Chitra Tirunal Institute for Medical Sciences and Technology Trivandrum Act',view : 'Sree Chitra Tirunal Institute for Medical Sciences and Technology Trivandrum Act, 1980'}, 
    {value : 'Stage Carriages Act',view : 'Stage Carriages Act, 1861'}, 
    {value : 'Standards of Weights and Measures Act',view : 'Standards of Weights and Measures Act, 1976'}, 
    {value : 'Standards of Weights and Measures (Enforcement) Act',view : 'Standards of Weights and Measures (Enforcement) Act, 1985'}, 
    {value : 'Standards of Weights and Measures (Extension to Kohima and Mokokchung Districts) Act',view : 'Standards of Weights and Measures (Extension to Kohima and Mokokchung Districts) Act, 1967'}, 
    {value : 'State Acquisition of Lands for Union Purposes (Validation) Act',view : 'State Acquisition of Lands for Union Purposes (Validation) Act, 1954'}, 
    {value : 'State Agricultural Credit Corporation Act',view : 'State Agricultural Credit Corporation Act, 1968'}, 
    {value : 'State Armed Police Forces (Extension of Laws) Act',view : 'State Armed Police Forces (Extension of Laws) Act, 1952'}, 
    {value : 'State Associated Banks (Miscellaneous Provisions) Act',view : 'State Associated Banks (Miscellaneous Provisions) Act, 1962'}, 
    {value : 'State Bank of Hyderabad Act',view : 'State Bank of Hyderabad Act, 1956'}, 
    {value : 'State Bank of India Act',view : 'State Bank of India Act, 1955'}, 
    {value : 'State Bank of India (Subsidiary Banks) Act',view : 'State Bank of India (Subsidiary Banks) Act, 1959'}, 
    {value : 'State Bank of Saurashtra (Repeal) and the State Bank of India (Subsidiary Bank) Amendment Act',view : 'State Bank of Saurashtra (Repeal) and the State Bank of India (Subsidiary Bank) Amendment Act, 2009'}, 
    {value : 'State Bank of Sikkim (Acquisition of Shares) and Miscellaneous Provisions Act',view : 'State Bank of Sikkim (Acquisition of Shares) and Miscellaneous Provisions Act, 1982'}, 
    {value : 'State Emblems of India (Prohibition of Improper Use) Act',view : 'State Emblems of India (Prohibition of Improper Use) Act, 2005'}, 
    {value : 'State Financial Corporations Act',view : 'State Financial Corporations Act, 1951'}, 
    {value : 'State of Arunachal Pradesh Act',view : 'State of Arunachal Pradesh Act, 1986'}, 
    {value : 'State of Himachal Pradesh Act',view : 'State of Himachal Pradesh Act, 1970'}, 
    {value : 'State of Mizoram Act',view : 'State of Mizoram Act, 1986'}, 
    {value : 'State of Nagaland Act',view : 'State of Nagaland Act, 1962'}, 
    {value : 'Street Venders (Protection of Livelihood and Regulation of Street Vending) Act',view : 'Street Venders (Protection of Livelihood and Regulation of Street Vending) Act, 2014'}, 
    {value : 'States Reorganisation Act',view : 'States Reorganisation Act, 1956'}, 
    {value : 'Sugarcane Act',view : 'Sugarcane Act, 1934'}, 
    {value : 'Sugar Cess Act',view : 'Sugar Cess Act, 1982'}, 
    {value : 'Sugar Development Fund Act',view : 'Sugar Development Fund Act, 1982'}, 
    {value : 'Sugar (Regulation of Production) Act',view : 'Sugar (Regulation of Production) Act, 1961'}, 
    {value : 'Sugar Undertaking (Taking over of Management) Act',view : 'Sugar Undertaking (Taking over of Management) Act, 1978'}, 
    {value : 'Suits Valuation Act',view : 'Suits Valuation Act, 1887'}, 
    {value : 'Suppression of Unlawful Acts against Safety of Civil Aviation Act',view : 'Suppression of Unlawful Acts against Safety of Civil Aviation Act, 1982'}, 
    {value : 'Suppression of Unlawful Acts against Safety of Maritime Navigation and Fixed Platform on Continental Shelf Act',view : 'Suppression of Unlawful Acts against Safety of Maritime Navigation and Fixed Platform on Continental Shelf Act, 2002'}, 
    {value : 'Supreme Court Advocates (Practice in High Courts) Act',view : 'Supreme Court Advocates (Practice in High Courts) Act, 1951'}, 
    {value : 'Supreme Court (Enlargement of Criminal Appellate Jurisdiction) Act',view : 'Supreme Court (Enlargement of Criminal Appellate Jurisdiction) Act, 1970'}, 
    {value : 'Supreme Court Judges (Salaries and Conditions of Service) Act',view : 'Supreme Court Judges (Salaries and Conditions of Service) Act, 1958'}, 
    {value : 'Supreme Court (Number of Judges) Act',view : 'Supreme Court (Number of Judges) Act, 1956'}, 
    {value : 'Swadeshi Cotton Mills Company Limited (Acquisition and Transfer of Undertakings) Act',view : 'Swadeshi Cotton Mills Company Limited (Acquisition and Transfer of Undertakings) Act, 1986'}, 
    {value : 'Tamil Nadu Agricultural Service Co-operative Societies (Appointment of Special Officers) Amendment Act',view : 'Tamil Nadu Agricultural Service Co-operative Societies (Appointment of Special Officers) Amendment Act, 1988'}, 
    {value : 'Tamil Nadu Legislative Council Act',view : 'Tamil Nadu Legislative Council Act, 2010'}, 
    {value : 'Taxation Laws (Amendment and Miscellaneous Provisions) Act',view : 'Taxation Laws (Amendment and Miscellaneous Provisions) Act, 1965'}, 
    {value : 'Taxation Laws (Amendment and Miscellaneous Provisions) Act',view : 'Taxation Laws (Amendment and Miscellaneous Provisions) Act, 1986'}, 
    {value : 'Taxation Laws (Continuation and Validation of Recovery Proceedings) Act',view : 'Taxation Laws (Continuation and Validation of Recovery Proceedings) Act, 1964'}, 
    {value : 'Taxation Laws (Extension to Jammu and Kashmir) Act',view : 'Taxation Laws (Extension to Jammu and Kashmir) Act, 1954'}, 
    {value : 'Taxation Laws (Extension to Jammu and Kashmir) Act',view : 'Taxation Laws (Extension to Jammu and Kashmir) Act, 1972'}, 
    {value : 'Tea Act',view : 'Tea Act, 1953'}, 
    {value : 'Tea Companies (Acquisition and Transfer of Sick Tea Units) Act',view : 'Tea Companies (Acquisition and Transfer of Sick Tea Units) Act, 1985'}, 
    {value : 'Technology Development Board Act',view : 'Technology Development Board Act, 1995'}, 
    {value : 'Telecom Regulatory Authority of India Act',view : 'Telecom Regulatory Authority of India Act, 1997'}, 
    {value : 'Telegraph Wires (Unlawful Possession) Act',view : 'Telegraph Wires (Unlawful Possession) Act, 1950'}, 
    {value : 'Terminal Tax on Railway Passengers Act',view : 'Terminal Tax on Railway Passengers Act, 1956'}, 
    {value : 'Territorial Army Act',view : 'Territorial Army Act, 1948'}, 
    {value : 'Territorial Waters Maritime Zones Act',view : 'Territorial Waters Maritime Zones Act, 1976'}, 
    {value : 'Terrorist Affected Areas (Special Courts) Act',view : 'Terrorist Affected Areas (Special Courts) Act, 1984'}, 
    {value : 'Textile Undertakings (Nationalisation) Act',view : 'Textile Undertakings (Nationalisation) Act, 1995'}, 
    {value : 'Textile Undertakings (Taking Over of Management) Act',view : 'Textile Undertakings (Taking Over of Management) Act, 1983'}, 
    {value : 'Textiles Committee Act',view : 'Textiles Committee Act, 1963'}, 
    {value : 'Tezpur University Act',view : 'Tezpur University Act, 1993'}, 
    {value : 'Tobacco Board Act',view : 'Tobacco Board Act, 1975'}, 
    {value : 'Tobacco Cess Act',view : 'Tobacco Cess Act, 1975'}, 
    {value : 'Tobacco Duty (Town of Bombay) Act',view : 'Tobacco Duty (Town of Bombay) Act, 1857'}, 
    {value : 'Tokyo Convention Act',view : 'Tokyo Convention Act, 1975'}, 
    {value : 'Trade Marks Act',view : 'Trade Marks Act, 1999'}, 
    {value : 'Trade Unions Act',view : 'Trade Unions Act, 1926'}, 
    {value : 'Trading with the Enemy (Continuance of Emergency Provisions) Act',view : 'Trading with the Enemy (Continuance of Emergency Provisions) Act, 1947'}, 
    {value : 'Transfer of Evacuee Deposits Act',view : 'Transfer of Evacuee Deposits Act, 1954'}, 
    {value : 'Transfer of Prisoners Act',view : 'Transfer of Prisoners Act, 1950'}, 
    {value : 'Transfer of Property Act',view : 'Transfer of Property Act, 1882'}, 
    {value : 'Transfer of Property (Amendment) Supplementary Act',view : 'Transfer of Property (Amendment) Supplementary Act, 1929'}, 
    {value : 'Transformer and Switchgear Limited (Acquisition and Transfer of Undertakings) Act',view : 'Transformer and Switchgear Limited (Acquisition and Transfer of Undertakings) Act, 1983'}, 
    {value : 'Transplantation of Human Organs Act',view : 'Transplantation of Human Organs Act, 1994'}, 
    {value : 'Travancore-Cochin Vehicles Taxation (Amendment and Validation) Act',view : 'Travancore-Cochin Vehicles Taxation (Amendment and Validation) Act, 1959'}, 
    {value : 'Tripura Land Revenue and Land Reforms Act',view : 'Tripura Land Revenue and Land Reforms Act, 1960'}, 
    {value : 'Tripura University Act',view : 'Tripura University Act, 2007'}, 
    {value : 'Tyre Corporation of India Limited (Disinvestment of Ownership) Act',view : 'Tyre Corporation of India Limited (Disinvestment of Ownership) Act, 2007'}, 
    {value : 'Union Duties of Excise (Distribution) Act',view : 'Union Duties of Excise (Distribution) Act, 1979'}, 
    {value : 'UnionTerritories (Direct Election to the House of thePeople) Act',view : 'UnionTerritories (Direct Election to the House of thePeople) Act, 1965'}, 
    {value : 'Union Territories (Laws) Act',view : 'Union Territories (Laws) Act, 1950'}, 
    {value : 'Union Territories (Separation of Judicial and Executive Functions) Act',view : 'Union Territories (Separation of Judicial and Executive Functions) Act, 1969'}, 
    {value : 'Union Territories (Stamp and Court-fees Laws) Act',view : 'Union Territories (Stamp and Court-fees Laws) Act, 1961'}, 
    {value : 'Union Duties of Excise (Electricity) Distribution Repeal Act',view : 'Union Duties of Excise (Electricity) Distribution Repeal Act, 2006'}, 
    {value : 'Unit Trust of India Act',view : 'Unit Trust of India Act, 1963'}, 
    {value : 'Unit Trust of India (Transfer of Undertaking and Repeal) Act',view : 'Unit Trust of India (Transfer of Undertaking and Repeal) Act, 2002'}, 
    {value : 'United Nations (Privileges and Immunities) Act',view : 'United Nations (Privileges and Immunities) Act, 1947'}, 
    {value : 'United Nations (Security Council) Act',view : 'United Nations (Security Council) Act, 1947'}, 
    {value : 'United Provinces Act',view : 'United Provinces Act, 1890'}, 
    {value : 'University Grants Commission Act',view : 'University Grants Commission Act, 1956'}, 
    {value : 'University of Allahabad Act',view : 'University of Allahabad Act, 2005'}, 
    {value : 'University of Hyderabad Act',view : 'University of Hyderabad Act, 1974'}, 
    {value : 'Unlawful Activities (Prevention) Act',view : 'Unlawful Activities (Prevention) Act, 1967'}, 
    {value : 'Untouchability (Offences) Amendment and Miscellaneous Provision Act',view : 'Untouchability (Offences) Amendment and Miscellaneous Provision Act, 1976'}, 
    {value : 'Unorganised Sector Workers Social Security Act',view : 'Unorganised Sector Workers Social Security Act, 2008'}, 
    {value : 'Urban Land (Ceiling and Regulation) Act',view : 'Urban Land (Ceiling and Regulation) Act, 1976'}, 
    {value : 'Urban Land (Ceiling and Regulation) Repeal Act',view : 'Urban Land (Ceiling and Regulation) Repeal Act, 1999'}, 
    {value : 'Usurious Loans Act',view : 'Usurious Loans Act, 1918'}, 
    {value : 'Usury Laws Repeal Act',view : 'Usury Laws Repeal Act, 1855'}, 
    {value : 'Uttar Pradesh Cantonments (Control of Rent and Eviction) Repeal Act',view : 'Uttar Pradesh Cantonments (Control of Rent and Eviction) Repeal Act, 1971'}, 
    {value : 'Uttar Pradesh Reorganisation Act',view : 'Uttar Pradesh Reorganisation Act, 2000'}, 
    {value : 'Uttaranchal (Alteration of Name) Act',view : 'Uttaranchal (Alteration of Name) Act, 2006'}, 
    {value : 'Vice-President\'s Pension Act',view : 'Vice-President\'s Pension Act, 1997'}, 
    {value : 'Victoria Memorial Act',view : 'Victoria Memorial Act, 1903'}, 
    {value : 'Visva-Bharati Act',view : 'Visva-Bharati Act, 1951'}, 
    {value : 'Voluntary Surrender of Salaries (Exemption from Taxation) Act',view : 'Voluntary Surrender of Salaries (Exemption from Taxation) Act, 1961'}, 
    {value : 'Wakf Act',view : 'Wakf Act, 1995'}, 
    {value : 'War Injuries (Compensation Insurance) Act',view : 'War Injuries (Compensation Insurance) Act, 1943'}, 
    {value : 'Warehousing Corporations Act',view : 'Warehousing Corporations Act, 1962'}, 
    {value : 'Warehousing Corporations (Supplementary) Act',view : 'Warehousing Corporations (Supplementary) Act, 1965'}, 
    {value : 'Warehousing (Development and Regulation) Act',view : 'Warehousing (Development and Regulation) Act, 2007'}, 
    {value : 'Waste-Lands (Claims) Act',view : 'Waste-Lands (Claims) Act, 1863'}, 
    {value : 'Water (Prevention and Control of Pollution) Act',view : 'Water (Prevention and Control of Pollution) Act, 1974'}, 
    {value : 'Water (Prevention and Control of Pollution) Cess Act',view : 'Water (Prevention and Control of Pollution) Cess Act, 1977'}, 
    {value : 'Wealth Tax Act',view : 'Wealth Tax Act, 1957'}, 
    {value : 'Weapons of Mass Destruction and Their Delivery Systems (Prohibition of Unlawful Activities) Act',view : 'Weapons of Mass Destruction and Their Delivery Systems (Prohibition of Unlawful Activities) Act, 2005'}, 
    {value : 'Weekly Holidays Act',view : 'Weekly Holidays Act, 1942'}, 
    {value : 'West Godavari District (Assimilation of Laws on Federal Subjects) Act',view : 'West Godavari District (Assimilation of Laws on Federal Subjects) Act, 1949'}, 
    {value : 'White Phosphorus Matches Prohibition Act',view : 'White Phosphorus Matches Prohibition Act, 1913'}, 
    {value : 'Whistle Blowers Protection Act',view : 'Whistle Blowers Protection Act, 2011'}, 
    {value : 'Wild Birds and Animals Protection Act',view : 'Wild Birds and Animals Protection Act, 1912'}, 
    {value : 'Wild Life (Protection) Act',view : 'Wild Life (Protection) Act, 1972'}, 
    {value : 'Wild Life (Protection) Amendment Act',view : 'Wild Life (Protection) Amendment Act, 1991'}, 
    {value : 'Women\'s and Children\'s Institutions (Licensing) Act',view : 'Women\'s and Children\'s Institutions (Licensing) Act, 1956'}, 
    {value : 'Working Journalists and other Newspaper Employees (Conditions of Service) and Miscellaneous Provisions Act',view : 'Working Journalists and other Newspaper Employees (Conditions of Service) and Miscellaneous Provisions Act, 1955'}, 
    {value : 'Working Journalists (Fixation of Rates of Wages) Act',view : 'Working Journalists (Fixation of Rates of Wages) Act, 1958'}, 
    {value : 'Workmen\'s Compensation Act',view : 'Workmen\'s Compensation Act, 1923'}, 
    {value : 'Workmen\'s Compensation (Amendment) Act',view : 'Workmen\'s Compensation (Amendment) Act, 2009'}, 
    {value : 'Works of Defence Act',view : 'Works of Defence Act, 1903'}, 
    {value : 'Young Persons (Harmful Publications) Act',view : 'Young Persons (Harmful Publications) Act, 1956'}, 
    {value : 'Bombay Abkari Act',view : 'Bombay Abkari Act, 1878'}, 
    {value : 'Maharashtra Abolition of Subsisting Proprietary Rights to Mines and Minerals in Certain Lands Act',view : 'Maharashtra Abolition of Subsisting Proprietary Rights to Mines and Minerals in Certain Lands Act, 1985'}, 
    {value : 'Bombay Adjudication Proceedings (Transfer and Continuance) Act',view : 'Bombay Adjudication Proceedings (Transfer and Continuance) Act, 1947'}, 
    {value : 'Maharashtra Advertisements Tax Act',view : 'Maharashtra Advertisements Tax Act, 1967'}, 
    {value : 'Maharashtra Advocates Welfare Fund Act',view : 'Maharashtra Advocates Welfare Fund Act, 1981'}, 
    {value : 'Bombay Aerial Ropeways Act',view : 'Bombay Aerial Ropeways Act, 1955'}, 
    {value : 'Bombay Agricultural Debtors Relief Act',view : 'Bombay Agricultural Debtors Relief Act, 1947'}, 
    {value : 'Maharashtra Agricultural Debtors Relief Act (Vidarbha Region)',view : 'Maharashtra Agricultural Debtors Relief Act, 1969 (Vidarbha Region)'}, 
    {value : 'Maharashtra Agricultural Lands (Ceiling on Holdings) Act',view : 'Maharashtra Agricultural Lands (Ceiling on Holdings) Act, 1961'}, 
    {value : 'Bombay Agricultural Pests and Diseases Act',view : 'Bombay Agricultural Pests and Diseases Act, 1947'}, 
    {value : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) Act',view : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) Act, 1963'}, 
    {value : 'Maharashtra Agricultural Universities (Krishi Vidyapeeths) Act',view : 'Maharashtra Agricultural Universities (Krishi Vidyapeeths) Act, 1983'}, 
    {value : 'Bombay Anatomy Act',view : 'Bombay Anatomy Act, 1949'}, 
    {value : 'Maharashtra Ancient Monuments and Archaeological Sites and Remains Act',view : 'Maharashtra Ancient Monuments and Archaeological Sites and Remains Act, 1960'}, 
    {value : 'Maharashtra Animal and Fishery Sciences University Act',view : 'Maharashtra Animal and Fishery Sciences University Act, 1988'}, 
    {value : 'Maharashtra Animal Preservation Act',view : 'Maharashtra Animal Preservation Act, 1976'}, 
    {value : 'Maharashtra Apartment Ownership Act',view : 'Maharashtra Apartment Ownership Act, 1970'}, 
    {value : 'Maharashtra Animal and Fishery Sciences University Act',view : 'Maharashtra Animal and Fishery Sciences University Act, 1988'}, 
    {value : 'Bombay Betting Tax Act',view : 'Bombay Betting Tax Act, 1925'}, 
    {value : 'Bombay Bhagdari and Narwadari Tenures Abolition Act',view : 'Bombay Bhagdari and Narwadari Tenures Abolition Act, 1949'}, 
    {value : 'Bombay Bhil Naik Inams Abol on Act',view : 'Bombay Bhil Naik Inams Abol on Act, 1955'}, 
    {value : 'Maharashtra Bombay City (Inami And Special Tenures) Abolition and Land Revenue Code (Amendment) Act',view : 'Maharashtra Bombay City (Inami And Special Tenures) Abolition and Land Revenue Code (Amendment) Act, 1969'}, 
    {value : 'Bombay Borstal School Act',view : 'Bombay Borstal School Act, 1929'}, 
    {value : 'Maharashtra Casinos (Control and Tax) Act',view : 'Maharashtra Casinos (Control and Tax) Act, 1976'}, 
    {value : 'Maharashtra Central India Spinning Weaving and Manufacturing Company Limited The Empress Mills  Nagpur (Acquisition and Transfer of Undertaking) Act',view : 'Maharashtra Central India Spinning Weaving and Manufacturing Company Limited The Empress Mills  Nagpur (Acquisition and Transfer of Undertaking) Act, 1986'}, 
    {value : 'Bombay Chhatrapati Shivaji Mahara Vastu Sangrahalaya Act',view : 'Bombay Chhatrapati Shivaji Mahara Vastu Sangrahalaya Act, 1909'}, 
    {value : 'Bombay Childrens Act',view : 'Bombay Childrens Act, 1948'}, 
    {value : 'Bombay Cinemas (Regulation) Act',view : 'Bombay Cinemas (Regulation) Act, 1953'}, 
    {value : 'Bombay City Civil Court Act',view : 'Bombay City Civil Court Act, 1948'}, 
    {value : 'Bombay City of  (Building Works Restriction) Act',view : 'Bombay City of  (Building Works Restriction) Act, 1949'}, 
    {value : 'Bombay City of  Mu al Investments Act',view : 'Bombay City of  Mu al Investments Act, 1898'}, 
    {value : 'Bombay City of  Primary Education Act',view : 'Bombay City of  Primary Education Act, 1920'}, 
    {value : 'Civil Courts Act',view : 'Civil Courts Act, 1869'}, 
    {value : 'Bombay Civil Jails Act',view : 'Bombay Civil Jails Act, 1874'}, 
    {value : 'Bombay Commissioners of Divisions Act',view : 'Bombay Commissioners of Divisions Act, 1957'}, 
    {value : 'Bombay Contingency Fund Act, ',view : 'Bombay Contingency Fund Act, '}, 
    {value : 'Maharashtra Control of Organised Crime Act',view : 'Maharashtra Control of Organised Crime Act, 1999'}, 
    {value : 'Maharashtra Co-Operative Societies Act',view : 'Maharashtra Co-Operative Societies Act, 1960'}, 
    {value : 'Bombay Corneal Grafting Act',view : 'Bombay Corneal Grafting Act, 1957'}, 
    {value : 'Maharashtra Coroners ( Repeal) Act',view : 'Maharashtra Coroners ( Repeal) Act, 1999'}, 
    {value : 'Bombay Cotton Contracts Act',view : 'Bombay Cotton Contracts Act, 1932'}, 
    {value : 'Bombay Cotton Control Act',view : 'Bombay Cotton Control Act, 1942'}, 
    {value : 'Maharashtra Cotton Seeds (Regulation of Supply Distribution Sale and Fixation of Sale Price) Act',view : 'Maharashtra Cotton Seeds (Regulation of Supply Distribution Sale and Fixation of Sale Price) Act, 2009'}, 
    {value : 'Bombay Cotton (Statistics) Act',view : 'Bombay Cotton (Statistics) Act, 1946'}, 
    {value : 'Bombay Court-Fees Act',view : 'Bombay Court-Fees Act, 1959'}, 
    {value : 'Bombay Court of Wards Act',view : 'Bombay Court of Wards Act, 1905'}, 
    {value : 'Maharashtra Debt Relief Act',view : 'Maharashtra Debt Relief Act, 1975'}, 
    {value : 'Maharashtra Departmental Inquiries (Enforcement of Attendance of Witnesses and Production of Documents) Act',view : 'Maharashtra Departmental Inquiries (Enforcement of Attendance of Witnesses and Production of Documents) Act, 1986'}, 
    {value : 'Maharashtra Devdasi System (Abolition) Act',view : 'Maharashtra Devdasi System (Abolition) Act, 2005'}, 
    {value : 'Maharashtra Director of Prohibition and Excise (Change in Designation) Act',view : 'Maharashtra Director of Prohibition and Excise (Change in Designation) Act, 1973'}, 
    {value : 'Maharashtra Diseases of Animals Act',view : 'Maharashtra Diseases of Animals Act, 1948'}, 
    {value : 'Bombay Disqualification of Aliens Act',view : 'Bombay Disqualification of Aliens Act, 1918'}, 
    {value : 'Maharashtra District Planning Committees (Constitution and Functions) Act',view : 'Maharashtra District Planning Committees (Constitution and Functions) Act, 1998'}, 
    {value : 'Maharashtra Dog Race-Courses Licensing Act',view : 'Maharashtra Dog Race-Courses Licensing Act, 1976'}, 
    {value : 'Maharashtra Domestic Workers Welfare Board Act',view : 'Maharashtra Domestic Workers Welfare Board Act, 2008'}, 
    {value : 'Maharashtra Drinking Water Supply Requisition Act',view : 'Maharashtra Drinking Water Supply Requisition Act, 1983'}, 
    {value : 'Bombay Drugs (Control) Act',view : 'Bombay Drugs (Control) Act, 1959'}, 
    {value : 'Maharashtra Education and Employment Guarantee (Cess) Act',view : 'Maharashtra Education and Employment Guarantee (Cess) Act, 1962'}, 
    {value : 'Maharashtra Educational institutions (Management) Act',view : 'Maharashtra Educational institutions (Management) Act, 1976'}, 
    {value : 'Maharashtra Educational Institutions (Prohibition of Capitation Fee) Act',view : 'Maharashtra Educational Institutions (Prohibition of Capitation Fee) Act, 1987'}, 
    {value : 'Maharashtra Educational institutions (Transfer of Management) Act',view : 'Maharashtra Educational institutions (Transfer of Management) Act, 1971'}, 
    {value : 'Bombay Edulabad and Warangaon Parganas Laws Act',view : 'Bombay Edulabad and Warangaon Parganas Laws Act, 1866'}, 
    {value : 'Bombay Electricity (Special Powers) Act',view : 'Bombay Electricity (Special Powers) Act, 1946'}, 
    {value : 'Bombay Electricity Duty Act',view : 'Bombay Electricity Duty Act, 1958'}, 
    {value : 'Maharashtra Employees of Private Schools (Conditions of Service) Regulation Act',view : 'Maharashtra Employees of Private Schools (Conditions of Service) Regulation Act, 1977'}, 
    {value : 'Maharashtra Employees Guarantee Act',view : 'Maharashtra Employees Guarantee Act, 1977'}, 
    {value : 'Bombay Entertainments Duty Act',view : 'Bombay Entertainments Duty Act, 1923'}, 
    {value : 'Maharashtra Eradication of Unfair activities at Pandharpur Temples (For Providing Better Facilities for Worship) Act',view : 'Maharashtra Eradication of Unfair activities at Pandharpur Temples (For Providing Better Facilities for Worship) Act, 1980'}, 
    {value : 'Bombay Essential Commodities and Cattle (Control) Act',view : 'Bombay Essential Commodities and Cattle (Control) Act, 1958'}, 
    {value : 'Maharashtra Essential Services Maintenance Act',view : 'Maharashtra Essential Services Maintenance Act, 2005'}, 
    {value : 'Bombay Exemptions from Land Revenue (No. 1) Act',view : 'Bombay Exemptions from Land Revenue (No. 1) Act, 1863'}, 
    {value : 'Bombay Exemption from Land Revenue (No. 2) Act',view : 'Bombay Exemption from Land Revenue (No. 2) Act, 1863'}, 
    {value : 'Maharashtra Felling of Trees (Regulation) Act',view : 'Maharashtra Felling of Trees (Regulation) Act, 1964'}, 
    {value : 'Bombay Ferries and Island Vessels Act',view : 'Bombay Ferries and Island Vessels Act, 1868'}, 
    {value : 'Maharashtra Finance Commission (Miscellaneous Provision) Act',view : 'Maharashtra Finance Commission (Miscellaneous Provision) Act, 1994'}, 
    {value : 'Maharashtra Fire Prevention and Life Safety Measures Act',view : 'Maharashtra Fire Prevention and Life Safety Measures Act, 2006'}, 
    {value : 'Maharashtra Fiscal Responsibility and Budgetary Management Act',view : 'Maharashtra Fiscal Responsibility and Budgetary Management Act, 2005'}, 
    {value : 'Maharashtra Fisheries Act',view : 'Maharashtra Fisheries Act, 1960'}, 
    {value : 'Bombay Fodder and Grain Control Act',view : 'Bombay Fodder and Grain Control Act, 1939'}, 
    {value : 'Maharashtra Forest Development (Tax on Sale of Forest-Produce by Government of Forest Development Corporation) (Continuance) Act',view : 'Maharashtra Forest Development (Tax on Sale of Forest-Produce by Government of Forest Development Corporation) (Continuance) Act, 1983'}, 
    {value : 'Maharashtra Forest Produce (Regulation of Trade) Act',view : 'Maharashtra Forest Produce (Regulation of Trade) Act, 1969'}, 
    {value : 'Bombay Forfeited Lands Restoration Act',view : 'Bombay Forfeited Lands Restoration Act, 1938'}, 
    {value : 'Bombay Forward Control Contracts Act',view : 'Bombay Forward Control Contracts Act, 1947'}, 
    {value : 'Maharashtra Fruit Nurseries and Sale of Fruit of Fruit Plants (Regulation) Act',view : 'Maharashtra Fruit Nurseries and Sale of Fruit of Fruit Plants (Regulation) Act, 1969'}, 
    {value : 'Bombay Gandhi National Memorial Fund (Local Authorities Donations) Act',view : 'Bombay Gandhi National Memorial Fund (Local Authorities Donations) Act, 1953'}, 
    {value : 'Bombay Gas Companies Act',view : 'Bombay Gas Companies Act, 1863'}, 
    {value : 'Bombay Gas Supply Act',view : 'Bombay Gas Supply Act, 1939'}, 
    {value : 'Bombay General Clauses Act',view : 'Bombay General Clauses Act, 1904'}, 
    {value : 'Maharashtra Godawari Marathwada Irrigation Development Corporation Act',view : 'Maharashtra Godawari Marathwada Irrigation Development Corporation Act, 1998'}, 
    {value : 'Bombay Government Premises (Eviction) Act',view : 'Bombay Government Premises (Eviction) Act, 1955'}, 
    {value : 'Maharashtra Government Servants Inquiries (Evidence of Corruption) Act',view : 'Maharashtra Government Servants Inquiries (Evidence of Corruption) Act, 1965'}, 
    {value : 'Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties Act',view : 'Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties Act, 2005'}, 
    {value : 'Maharashtra Gramdan Act',view : 'Maharashtra Gramdan Act, 1964'}, 
    {value : 'Maharashtra Groundwater (Regulation for Drinking Water Purposes) Act',view : 'Maharashtra Groundwater (Regulation for Drinking Water Purposes) Act, 1993'}, 
    {value : 'Maharashtra Gunthewari Developments (Regularisation Upgradation and Control) Act',view : 'Maharashtra Gunthewari Developments (Regularisation Upgradation and Control) Act, 2001'}, 
    {value : 'Bombay Habitual Offenders Act',view : 'Bombay Habitual Offenders Act, 1959'}, 
    {value : 'Bombay Hereditary Offices Act',view : 'Bombay Hereditary Offices Act, 1874'}, 
    {value : 'Maharashtra High Court (Hearing of Writ Petitions by Division Bench and Abolition of Letters Patent Appeals) Act',view : 'Maharashtra High Court (Hearing of Writ Petitions by Division Bench and Abolition of Letters Patent Appeals) Act, 1986'}, 
    {value : 'Bombay Highways Act',view : 'Bombay Highways Act, 1955'}, 
    {value : 'Bombay Hindu Heirs Relief Act',view : 'Bombay Hindu Heirs Relief Act, 1866'}, 
    {value : 'Bombay Hindu Places of Public Worship (Entry Authorization) Act',view : 'Bombay Hindu Places of Public Worship (Entry Authorization) Act, 1956'}, 
    {value : 'Bombay Home Guards Act',view : 'Bombay Home Guards Act, 1947'}, 
    {value : 'Bombay Homoeopathic Practitioners Act',view : 'Bombay Homoeopathic Practitioners Act, 1959'}, 
    {value : 'Maharashtra Horticulture Development Corporation Act',view : 'Maharashtra Horticulture Development Corporation Act, 1984'}, 
    {value : 'Maharashtra Housing and Area Development Act',view : 'Maharashtra Housing and Area Development Act, 1976'}, 
    {value : 'Maharashtra Increase of Land Revenue and Special Assessment Act',view : 'Maharashtra Increase of Land Revenue and Special Assessment Act, 1974'}, 
    {value : 'Maharashtra Industrial Development Act',view : 'Maharashtra Industrial Development Act, 1961'}, 
    {value : 'Bombay Industrial Disputes ( Provision for Uniformity Act',view : 'Bombay Industrial Disputes ( Provision for Uniformity Act, 1959'}, 
    {value : 'Bombay Industrial Relations Act',view : 'Bombay Industrial Relations Act, 1946'}, 
    {value : 'Bombay Inferior Village Watans Abolition Act',view : 'Bombay Inferior Village Watans Abolition Act, 1958'}, 
    {value : 'Maharashtra Inferior Village Watans Abolition Act',view : 'Maharashtra Inferior Village Watans Abolition Act, 2016'}, 
    {value : 'Bombay Invalidation of Hindu Ceremonial Emoluments Act',view : 'Bombay Invalidation of Hindu Ceremonial Emoluments Act, 1926'}, 
    {value : 'Maharashtra Irrigation Act',view : 'Maharashtra Irrigation Act, 1976'}, 
    {value : 'Maharashtra Jeevan Authority Act',view : 'Maharashtra Jeevan Authority Act, 1976'}, 
    {value : 'Bombay Judicial Proceedings (Regulation Of Reports) Act',view : 'Bombay Judicial Proceedings (Regulation Of Reports) Act, 1955'}, 
    {value : 'Bombay Kauli and Katuban Tenures (Abolition) Act',view : 'Bombay Kauli and Katuban Tenures (Abolition) Act, 1953'}, 
    {value : 'Maharashtra Kavi Kulaguru Kalidas Sanskrit Vishvavidyalaya University Act',view : 'Maharashtra Kavi Kulaguru Kalidas Sanskrit Vishvavidyalaya University Act, 1997'}, 
    {value : 'Maharashtra Keeping and Movement of Cattle In Urban Areas (Control) Act',view : 'Maharashtra Keeping and Movement of Cattle In Urban Areas (Control) Act, 1976'}, 
    {value : 'Bombay Khadi and Village Industries Act',view : 'Bombay Khadi and Village Industries Act, 1960'}, 
    {value : 'Maharashtra Khar Lands Development Act',view : 'Maharashtra Khar Lands Development Act, 1979'}, 
    {value : 'Bombay Khoti Abolition Act',view : 'Bombay Khoti Abolition Act, 1949'}, 
    {value : 'Maharashtra Kidney Transplantation Act',view : 'Maharashtra Kidney Transplantation Act, 1982'}, 
    {value : 'Maharashtra Korikan Irrigation Development Corporation Act',view : 'Maharashtra Korikan Irrigation Development Corporation Act, 1997'}, 
    {value : 'Maharashtra Krishna Valley Development Corporation Act',view : 'Maharashtra Krishna Valley Development Corporation Act, 1996'}, 
    {value : 'Bombay Labour Welfare Fund Act',view : 'Bombay Labour Welfare Fund Act, 1953'}, 
    {value : 'Bombay Land Improvement Schemes Act',view : 'Bombay Land Improvement Schemes Act, 1942'}, 
    {value : 'Bombay Land Requisition Act',view : 'Bombay Land Requisition Act, 1948'}, 
    {value : 'Maharashtra Land Revenue (Revival of Certain Rules Relating to Non-Agricultural Assessment) Act',view : 'Maharashtra Land Revenue (Revival of Certain Rules Relating to Non-Agricultural Assessment) Act, 1972'}, 
    {value : 'Maharashtra Land Revenue Code',view : 'Maharashtra Land Revenue Code, 1966'}, 
    {value : 'Bombay Land Tenures Abolition (Recovery of Records) Act',view : 'Bombay Land Tenures Abolition (Recovery of Records) Act, 1953'}, 
    {value : 'Bombay Landing and Wharfage Fees Act',view : 'Bombay Landing and Wharfage Fees Act, 1882'}, 
    {value : 'Maharashtra Leaders of The Opposition in The  Legislature Salaries and Allowances Act',view : 'Maharashtra Leaders of The Opposition in The  Legislature Salaries and Allowances Act, 1978'}, 
    {value : 'Maharashtra Legislative Council (Chairman and Deputy Chairman) and  Legislative Assembly (Speaker and Deputy Speaker) Salaries and Allowances Act, Bombay',view : 'Maharashtra Legislative Council (Chairman and Deputy Chairman) and  Legislative Assembly (Speaker and Deputy Speaker) Salaries and Allowances Act, Bombay, 1956'}, 
    {value : 'Maharashtra Legislature Members\' Pension Act',view : 'Maharashtra Legislature Members\' Pension Act, 1976'}, 
    {value : 'Bombay Legislature Members\' Salaries and Allowances Act',view : 'Bombay Legislature Members\' Salaries and Allowances Act, 1956'}, 
    {value : 'Bombay Legislature Members (Removal of Disqualifications) Act',view : 'Bombay Legislature Members (Removal of Disqualifications) Act, 1956'}, 
    {value : 'Maharashtra Lepers ( Repeal) Act',view : 'Maharashtra Lepers ( Repeal) Act, 1983'}, 
    {value : 'Bombay Lifts Act',view : 'Bombay Lifts Act, 1939'}, 
    {value : 'Bombay Livestock Improvement Act',view : 'Bombay Livestock Improvement Act, 1933'}, 
    {value : 'Bombay Local Authorities Census Expanses Contribution Act',view : 'Bombay Local Authorities Census Expanses Contribution Act, 1950'}, 
    {value : 'Maharashtra Local Authority Members\' Disqualification Act',view : 'Maharashtra Local Authority Members\' Disqualification Act, 1986'}, 
    {value : 'Bombay Local Fund Audit Act',view : 'Bombay Local Fund Audit Act, 1930'}, 
    {value : 'Maharashtra Lokayukta and UpavLokayuktas Act',view : 'Maharashtra Lokayukta and UpavLokayuktas Act, 1971'}, 
    {value : 'Bombay Lotteries (Control and Tax) and Prize Competitions (Tax) Act',view : 'Bombay Lotteries (Control and Tax) and Prize Competitions (Tax) Act, 1958'}, 
    {value : 'Maharashtra Mahatma Phule Vastu Sangrahalaya Poona Act',view : 'Maharashtra Mahatma Phule Vastu Sangrahalaya Poona Act, 1968'}, 
    {value : 'Bombay Mahul Creek (Extinguishment of Rights) Act',view : 'Bombay Mahul Creek (Extinguishment of Rights) Act, 1922'}, 
    {value : 'Bombay Mamlatdars\' Courts Act',view : 'Bombay Mamlatdars\' Courts Act, 1906'}, 
    {value : 'Maharashtra Management of Irrigation Systems by Farmers Act',view : 'Maharashtra Management of Irrigation Systems by Farmers Act, 2005'}, 
    {value : 'Maharashtra Marine Fishing Regulation Act',view : 'Maharashtra Marine Fishing Regulation Act, 1981'}, 
    {value : 'Maharashtra Maritime Board Act',view : 'Maharashtra Maritime Board Act, 1996'}, 
    {value : 'Bombay Markels and Fairs Act',view : 'Bombay Markels and Fairs Act, 1862'}, 
    {value : 'Maharashtra Mathadi Hamal and other Manual Workers (Regulation of Employment and Welfare) Act',view : 'Maharashtra Mathadi Hamal and other Manual Workers (Regulation of Employment and Welfare) Act, 1969'}, 
    {value : 'Maharashtra Medical and Dental Colleges Admissions (Regulation and Abolition of All India Quota) Act',view : 'Maharashtra Medical and Dental Colleges Admissions (Regulation and Abolition of All India Quota) Act, 2003'}, 
    {value : 'Maharashtra Medical Council Act',view : 'Maharashtra Medical Council Act, 1965'}, 
    {value : 'Maharashtra Medical Practitioners Act',view : 'Maharashtra Medical Practitioners Act, 1961'}, 
    {value : 'Bombay Merged Territories (Janjira and Bhor) Khoti Tenure Abolition Act',view : 'Bombay Merged Territories (Janjira and Bhor) Khoti Tenure Abolition Act, 1953'}, 
    {value : 'Bombay Merged Territories and Areas (Jagirs Abolition) Act',view : 'Bombay Merged Territories and Areas (Jagirs Abolition) Act, 1953'}, 
    {value : 'Bombay Merged Territories Miscellaneous Alienations Abolition Act',view : 'Bombay Merged Territories Miscellaneous Alienations Abolition Act, 1955'}, 
    {value : 'Maharashtra Metropolitan Planning Committees (Constitutions and Functions) (Continuance of Provisions) Act',view : 'Maharashtra Metropolitan Planning Committees (Constitutions and Functions) (Continuance of Provisions) Act, 1999'}, 
    {value : 'Maharashtra Metropolitan Region Specified Commodities Markets (Regulation of Location) Act',view : 'Maharashtra Metropolitan Region Specified Commodities Markets (Regulation of Location) Act, 1983'}, 
    {value : 'Maharashtra Mumbai Metropolitan Region Development Authority Act',view : 'Maharashtra Mumbai Metropolitan Region Development Authority Act, 1974'}, 
    {value : 'Maharashtra Mineral Development (Creation and Utilisation) Fund Act',view : 'Maharashtra Mineral Development (Creation and Utilisation) Fund Act, 2001'}, 
    {value : 'Bombay Ministers\' Salaries and Allowances Act',view : 'Bombay Ministers\' Salaries and Allowances Act, 1956'}, 
    {value : 'Maharashtra Miscellaneous Alienations (In Hyderabad Enclaves) Abolition Act',view : 'Maharashtra Miscellaneous Alienations (In Hyderabad Enclaves) Abolition Act, 1965'}, 
    {value : 'Bombay Molasses (Control) Act',view : 'Bombay Molasses (Control) Act, 1956'}, 
    {value : 'Bombay Money-Lenders Act',view : 'Bombay Money-Lenders Act, 1946'}, 
    {value : 'Bombay Motor Vehicles (Taxation of Passengers) Act',view : 'Bombay Motor Vehicles (Taxation of Passengers) Act, 1958'}, 
    {value : 'Bombay Motor Vehicles Tax Act',view : 'Bombay Motor Vehicles Tax Act, 1958'}, 
    {value : 'Bombay Mumbai Municipal Corporation Act',view : 'Bombay Mumbai Municipal Corporation Act, 1888'}, 
    {value : 'Maharashtra Municipal Councils Nagar Panchayats and Industrial Townships Act',view : 'Maharashtra Municipal Councils Nagar Panchayats and Industrial Townships Act, 1965'}, 
    {value : 'Bombay Municipal Servants Act',view : 'Bombay Municipal Servants Act, 1890'}, 
    {value : 'Maharashtra National and State Parks Act',view : 'Maharashtra National and State Parks Act, 1970'}, 
    {value : 'Bombay Non-Agriculturists\' Loans Act',view : 'Bombay Non-Agriculturists\' Loans Act, 1928'}, 
    {value : 'Maharashtra Non-Biodegradable Garbage (Control) Act',view : 'Maharashtra Non-Biodegradable Garbage (Control) Act, 2006'}, 
    {value : 'Bombay Non-Trading Corporations Act',view : 'Bombay Non-Trading Corporations Act, 1959'}, 
    {value : 'Maharashtra Nurses Act',view : 'Maharashtra Nurses Act, 1956'}, 
    {value : 'Bombay Nursing Homes Registration Act',view : 'Bombay Nursing Homes Registration Act, 1949'}, 
    {value : 'Maharashtra Official Languages Act',view : 'Maharashtra Official Languages Act, 1964'}, 
    {value : 'Bombay Opium Smoking Act',view : 'Bombay Opium Smoking Act, 1936'}, 
    {value : 'Maharashtra Ownership Flas (Regulation of The Promotion of Construction Sale Management and Transfer) Act',view : 'Maharashtra Ownership Flas (Regulation of The Promotion of Construction Sale Management and Transfer) Act, 1963'}, 
    {value : 'Maharashtra Pandharpur Development Authority Act',view : 'Maharashtra Pandharpur Development Authority Act, 2009'}, 
    {value : 'Maharashtra Pandharpur Temples Act',view : 'Maharashtra Pandharpur Temples Act, 1973'}, 
    {value : 'Bombay Paragana and Kulkarni Watans (Abolition) Act',view : 'Bombay Paragana and Kulkarni Watans (Abolition) Act, 1950'}, 
    {value : 'Bombay Peint Laws Act',view : 'Bombay Peint Laws Act, 1894'}, 
    {value : 'Bombay Personal Inams Abolition Act',view : 'Bombay Personal Inams Abolition Act, 1952'}, 
    {value : 'Bombay Pleaders Act',view : 'Bombay Pleaders Act, 1920'}, 
    {value : 'Bombay Police Act',view : 'Bombay Police Act, 1951'}, 
    {value : 'Bombay Prevention of Begging Act',view : 'Bombay Prevention of Begging Act, 1959'}, 
    {value : 'Maharashtra Prevention of Communal  Anti-Social and Other Dangerous Activities Act',view : 'Maharashtra Prevention of Communal  Anti-Social and Other Dangerous Activities Act, 1980'}, 
    {value : 'Maharashtra Prevention of Dangerous Activities of Slumlords Bootleggers Drug-Offenders/Dangerous Persons and Video Pirates Act',view : 'Maharashtra Prevention of Dangerous Activities of Slumlords Bootleggers Drug-Offenders/Dangerous Persons and Video Pirates Act, 1981'}, 
    {value : 'Maharashtra Prevention of Defacement of Property Act',view : 'Maharashtra Prevention of Defacement of Property Act, 1995'}, 
    {value : 'Bombay Prevention of Fragmentation and Consolidation of Holdings Act',view : 'Bombay Prevention of Fragmentation and Consolidation of Holdings Act, 1947'}, 
    {value : 'Bombay Prevention of Gambling Act',view : 'Bombay Prevention of Gambling Act, 1887'}, 
    {value : 'Maharashtra Prevention of Malpractices at University',view : 'Maharashtra Prevention of Malpractices at University, 1982'}, 
    {value : 'Maharashtra Preventive Detention Act',view : 'Maharashtra Preventive Detention Act, 1970'}, 
    {value : 'Bombay Primary Education Act',view : 'Bombay Primary Education Act, 1947'}, 
    {value : 'Maharashtra Private forests (Acquisition) Act',view : 'Maharashtra Private forests (Acquisition) Act, 1975'}, 
    {value : 'Maharashtra Private Professional Educational Institutions (Reservation of Seats for Admission for Scheduled Castes Scheduled Tribes Denotified Tribes (Vimukta Jatis) Nomadic Tribes and Other Backward Classes) Act',view : 'Maharashtra Private Professional Educational Institutions (Reservation of Seats for Admission for Scheduled Castes Scheduled Tribes Denotified Tribes (Vimukta Jatis) Nomadic Tribes and Other Backward Classes) Act, 2006'}, 
    {value : 'Maharashtra Private Security Guards (Regulation of Employment and Welfare) Act',view : 'Maharashtra Private Security Guards (Regulation of Employment and Welfare) Act, 1981'}, 
    {value : 'Bombay Prohibition Act',view : 'Bombay Prohibition Act, 1949'}, 
    {value : 'Maharashtra Prohibition of Ragging Act',view : 'Maharashtra Prohibition of Ragging Act, 1999'}, 
    {value : 'Bombay Prohibition of Simultaneous Membership Act',view : 'Bombay Prohibition of Simultaneous Membership Act, 1957'}, 
    {value : 'Maharashtra Project Affected Persons Rehabilitation Act',view : 'Maharashtra Project Affected Persons Rehabilitation Act, 1999'}, 
    {value : 'Maharashtra Protection of Interest of Depositors (In Financial Establishments) Act',view : 'Maharashtra Protection of Interest of Depositors (In Financial Establishments) Act, 1999'}, 
    {value : 'Bombay Protection of Pilgrims Act',view : 'Bombay Protection of Pilgrims Act, 1887'}, 
    {value : 'Bombay Provincial Municipal Corporations Act',view : 'Bombay Provincial Municipal Corporations Act, 1949'}, 
    {value : 'Maharashtra Provisional Collection of Taxes Act',view : 'Maharashtra Provisional Collection of Taxes Act, 1962'}, 
    {value : 'Maharashtra Provision of Facilities for Agricultural Credit by Banks Act',view : 'Maharashtra Provision of Facilities for Agricultural Credit by Banks Act, 1974'}, 
    {value : 'Bombay Public Authorities Seals Act',view : 'Bombay Public Authorities Seals Act, 1883'}, 
    {value : 'Bombay Public Conveyances Act',view : 'Bombay Public Conveyances Act, 1920'}, 
    {value : 'Maharashtra Public Libraries Act',view : 'Maharashtra Public Libraries Act, 1967'}, 
    {value : 'Maharashtra Public Records Act',view : 'Maharashtra Public Records Act, 2005'}, 
    {value : 'Bombay Public Trusts Act',view : 'Bombay Public Trusts Act, 1950'}, 
    {value : 'Maharashtra Pulgaon Cotton Mills Limited (Acquisition of Shares) Act',view : 'Maharashtra Pulgaon Cotton Mills Limited (Acquisition of Shares) Act, 1982'}, 
    {value : 'Maharashtra Purchase Tax on Sugarcane Act',view : 'Maharashtra Purchase Tax on Sugarcane Act, 1962'}, 
    {value : 'Bombay Race-Courses Licensing Act',view : 'Bombay Race-Courses Licensing Act, 1912'}, 
    {value : 'Maharashtra Rajiv Gandhi Science and Technology Commission Act',view : 'Maharashtra Rajiv Gandhi Science and Technology Commission Act, 2004'}, 
    {value : 'Bombay Rationing (Preparatory and Continuance) Measures Act',view : 'Bombay Rationing (Preparatory and Continuance) Measures Act, 1947'}, 
    {value : 'Maharashtra Raw Cotton (Procurement Processing and Marketing) Act',view : 'Maharashtra Raw Cotton (Procurement Processing and Marketing) Act, 1971'}, 
    {value : 'Maharashtra Recognition of Trade Unions and Prevention of Unfair Labour Practices Act',view : 'Maharashtra Recognition of Trade Unions and Prevention of Unfair Labour Practices Act, 1971'}, 
    {value : 'Bombay Refugees Act',view : 'Bombay Refugees Act, 1948'}, 
    {value : 'Maharashtra Regional and Town Planning Act',view : 'Maharashtra Regional and Town Planning Act, 1966'}, 
    {value : 'Bombay Regulations',view : 'Bombay Regulations, 1827'}, 
    {value : 'Maharashtra Regulation of Marriage Bureaus and Registration of Marriages Act',view : 'Maharashtra Regulation of Marriage Bureaus and Registration of Marriages Act, 1998'}, 
    {value : 'Maharashtra Regularisation of Unauthorised Developments in the City of Ulhasnagar Act',view : 'Maharashtra Regularisation of Unauthorised Developments in the City of Ulhasnagar Act, 2006'}, 
    {value : 'Bombay Relief Undertakings (Special Provisions) Act',view : 'Bombay Relief Undertakings (Special Provisions) Act, 1958'}, 
    {value : 'Maharashtra Religious Endowments (Reconstruction on Resettlement Sites) Act',view : 'Maharashtra Religious Endowments (Reconstruction on Resettlement Sites) Act, 1970'}, 
    {value : 'Maharashtra Rent Control Act',view : 'Maharashtra Rent Control Act, 1999'}, 
    {value : 'Bombay Rent Free Estates Act',view : 'Bombay Rent Free Estates Act, 1852'}, 
    {value : 'Maharashtra Requisitioning (And Control) of Motor Vehicles Act',view : 'Maharashtra Requisitioning (And Control) of Motor Vehicles Act, 1965'}, 
    {value : 'Maharashtra Restoration of Lands to Scheduled Tribes Act',view : 'Maharashtra Restoration of Lands to Scheduled Tribes Act, 1974'}, 
    {value : 'Maharashtra Restoration of Name \'Mumbai\' for \'Bombay\' Act',view : 'Maharashtra Restoration of Name \'Mumbai\' for \'Bombay\' Act, 1996'}, 
    {value : 'Bombay Revenue Jurisdiction Act',view : 'Bombay Revenue Jurisdiction Act, 1876'}, 
    {value : 'Maharashtra Revenue Patels (Abolition of Office) Act',view : 'Maharashtra Revenue Patels (Abolition of Office) Act, 1962'}, 
    {value : 'Maharashtra Right to Information (Repeal) Act',view : 'Maharashtra Right to Information (Repeal) Act, 2005'}, 
    {value : 'Maharashtra Sale of Trees by Occupants Belonging to Scheduled Tribes (Regulation) Act',view : 'Maharashtra Sale of Trees by Occupants Belonging to Scheduled Tribes (Regulation) Act, 1969'}, 
    {value : 'Bombay Salsette Estates (Land Revenue Exemption Abolition) Act',view : 'Bombay Salsette Estates (Land Revenue Exemption Abolition) Act, 1951'}, 
    {value : 'Bombay Satara Sholapur and Southern Maratha Country Laws Act',view : 'Bombay Satara Sholapur and Southern Maratha Country Laws Act, 1853'}, 
    {value : 'Maharashtra Scheduled Castes Scheduled Tribes De-Notified Tribes (Vimukta Jatis) Nomadic Tribes Other Backward Classes and Special Backward Category Regulation of Issuance and Verification of Caste Certificate Act',view : 'Maharashtra Scheduled Castes Scheduled Tribes De-Notified Tribes (Vimukta Jatis) Nomadic Tribes Other Backward Classes and Special Backward Category Regulation of Issuance and Verification of Caste Certificate Act, 2000'}, 
    {value : 'Bombay Seals Act',view : 'Bombay Seals Act, 1949'}, 
    {value : 'Bombay Seals Act (Application to Scheduled Areas) Regulation',view : 'Bombay Seals Act (Application to Scheduled Areas) Regulation, 1951'}, 
    {value : 'Maharashtra Secondary and Higher Secondary Education Boards Act',view : 'Maharashtra Secondary and Higher Secondary Education Boards Act, 1965'}, 
    {value : 'Bombay Separation of Judicial and Executive Functions Act',view : 'Bombay Separation of Judicial and Executive Functions Act, 1951'}, 
    {value : 'Bombay Separation of Judicial and Executive Functions (Extension) and The Code of Criminal Procedure (Provision for Uniformity) Act',view : 'Bombay Separation of Judicial and Executive Functions (Extension) and The Code of Criminal Procedure (Provision for Uniformity) Act, 1958'}, 
    {value : 'Bombay Separation of Judicial and Executive Functions (Supplementary) Act',view : 'Bombay Separation of Judicial and Executive Functions (Supplementary) Act, 1954'}, 
    {value : 'Bombay Service Inams (Useful to Community) Abolition Act',view : 'Bombay Service Inams (Useful to Community) Abolition Act, 1953'}, 
    {value : 'Bombay Shilotri Rights (Kolaba) Abolition Act',view : 'Bombay Shilotri Rights (Kolaba) Abolition Act, 1955'}, 
    {value : 'Maharashtra Shivraj Fine Art Litho Works (Acquisition and Transfer of Undertaking) Act',view : 'Maharashtra Shivraj Fine Art Litho Works (Acquisition and Transfer of Undertaking) Act, 1984'}, 
    {value : 'Bombay Shops and Establishments Act',view : 'Bombay Shops and Establishments Act, 1948'}, 
    {value : 'Maharashtra Shore Nuisances (Bombay and Kolaba) Act',view : 'Maharashtra Shore Nuisances (Bombay and Kolaba) Act, 1853'}, 
    {value : 'Maharashtra Shree Sai Baba Sansthan Trust (Shirdi) Act',view : 'Maharashtra Shree Sai Baba Sansthan Trust (Shirdi) Act, 2004'}, 
    {value : 'Maharashtra Shree Siddhi Vinayak Ganapati Temple Trust (Prabhadevi) Act',view : 'Maharashtra Shree Siddhi Vinayak Ganapati Temple Trust (Prabhadevi) Act, 1980'}, 
    {value : 'Maharashtra Sinhastha Fair Pilgrim Tax Act',view : 'Maharashtra Sinhastha Fair Pilgrim Tax Act, 1980'}, 
    {value : 'Maharashtra Slum Areas (Improvement Clearance and Redevelopment) Act',view : 'Maharashtra Slum Areas (Improvement Clearance and Redevelopment) Act, 1971'}, 
    {value : 'Bombay Smoke-Nuisances Act',view : 'Bombay Smoke-Nuisances Act, 1912'}, 
    {value : 'Maharashtra Special Provision for Payment of Court-Fees Act',view : 'Maharashtra Special Provision for Payment of Court-Fees Act, 1976'}, 
    {value : 'Bombay Special Tribunal (Continuance) Act',view : 'Bombay Special Tribunal (Continuance) Act, 1946'}, 
    {value : 'Bombay Stamp Act',view : 'Bombay Stamp Act, 1958'}, 
    {value : 'Maharashtra State-Aid to Industries Act',view : 'Maharashtra State-Aid to Industries Act, 1960'}, 
    {value : 'Maharashtra State Board of Technical Education Act',view : 'Maharashtra State Board of Technical Education Act, 1997'}, 
    {value : 'Maharashtra State Commission for Backward Classes Act',view : 'Maharashtra State Commission for Backward Classes Act, 2005'}, 
    {value : 'Maharashtra State Commission for Safai Karmacharis Act',view : 'Maharashtra State Commission for Safai Karmacharis Act, 1997'}, 
    {value : 'Maharashtra State Commission for Women Act',view : 'Maharashtra State Commission for Women Act, 1993'}, 
    {value : 'Bombay State Commissioners of Police Act',view : 'Bombay State Commissioners of Police Act, 1959'}, 
    {value : 'Maharashtra State Council for Occupational Therapy and Physiotherapy Act',view : 'Maharashtra State Council for Occupational Therapy and Physiotherapy Act, 2002'}, 
    {value : 'Maharashtra State Council of Examinations Act',view : 'Maharashtra State Council of Examinations Act, 1998'}, 
    {value : 'Maharashtra State Election Commissioner (Qualification and Appointment) Act',view : 'Maharashtra State Election Commissioner (Qualification and Appointment) Act, 1994'}, 
    {value : 'Maharashtra State Enterprises (Restructuring and Other Special Provisions (Repeal) Act',view : 'Maharashtra State Enterprises (Restructuring and Other Special Provisions (Repeal) Act, 2007'}, 
    {value : 'Maharashtra State Legislature Proceedings (Protection of Publication) Act',view : 'Maharashtra State Legislature Proceedings (Protection of Publication) Act, 1969'}, 
    {value : 'Maharashtra State Minorities Commission Act',view : 'Maharashtra State Minorities Commission Act, 2004'}, 
    {value : 'Maharashtra State Public Services (Reservation for Scheduled Castes Scheduled Tribes De-Notified Tribes (Vimukta Jatis) Nomadic Tribes Special Backward Category and Other Backward Classes) Act',view : 'Maharashtra State Public Services (Reservation for Scheduled Castes Scheduled Tribes De-Notified Tribes (Vimukta Jatis) Nomadic Tribes Special Backward Category and Other Backward Classes) Act, 2001'}, 
    {value : 'Bombay State Reserve Police Force Act',view : 'Bombay State Reserve Police Force Act, 1951'}, 
    {value : 'Bombay State Scarcity Relief Fund Act',view : 'Bombay State Scarcity Relief Fund Act, 1956'}, 
    {value : 'Bombay State Scarcity Relief Fund Act',view : 'Bombay State Scarcity Relief Fund Act, 1958'}, 
    {value : 'Maharashtra State Security Corporation Act',view : 'Maharashtra State Security Corporation Act, 2010'}, 
    {value : 'Maharashtra State Tax on Professions Trades Ceilings and Employments Act',view : 'Maharashtra State Tax on Professions Trades Ceilings and Employments Act, 1975'}, 
    {value : 'Bombay Statutory Corporations (Regional Reorganisation) Act',view : 'Bombay Statutory Corporations (Regional Reorganisation) Act, 1960'}, 
    {value : 'Bombay Statutory Funds Act',view : 'Bombay Statutory Funds Act, 1959'}, 
    {value : 'Bombay Steam-Vessels Act',view : 'Bombay Steam-Vessels Act, 1864'}, 
    {value : 'Maharashtra Supply of Forest\' Produce by Government (Revision of Agreements) Act',view : 'Maharashtra Supply of Forest\' Produce by Government (Revision of Agreements) Act, 1982'}, 
    {value : 'Maharashtra Surgeon General with Government etc. (Change in Designations) Act',view : 'Maharashtra Surgeon General with Government etc. (Change in Designations) Act, 1972'}, 
    {value : 'Maharashtra Tapi Irrigation Development Corporation Act',view : 'Maharashtra Tapi Irrigation Development Corporation Act, 1997'}, 
    {value : 'Maharashtra Tax on Buildings (With Larger Residential Premises) (Re-Enacted) Act',view : 'Maharashtra Tax on Buildings (With Larger Residential Premises) (Re-Enacted) Act, 1979'}, 
    {value : 'Maharashtra Tax on Lotteries Act',view : 'Maharashtra Tax on Lotteries Act, 2006'}, 
    {value : 'Maharashtra Tax on Sale of Electricity Act',view : 'Maharashtra Tax on Sale of Electricity Act, 1963'}, 
    {value : 'Maharashtra Tax on The Entry of Goods into Local Areas Act',view : 'Maharashtra Tax on The Entry of Goods into Local Areas Act, 2002'}, 
    {value : 'Maharashtra Tax on Entry of Motor Vehicles into Local Areas Act',view : 'Maharashtra Tax on Entry of Motor Vehicles into Local Areas Act, 1987'}, 
    {value : 'Maharashtra Tax on Luxuries Act',view : 'Maharashtra Tax on Luxuries Act, 1987'}, 
    {value : 'Bombay Tenancy and Agricultural Lands Act',view : 'Bombay Tenancy and Agricultural Lands Act, 1948'}, 
    {value : 'Maharashtra Textile Companies (Acquisition and Transfer of Undertakings) Act',view : 'Maharashtra Textile Companies (Acquisition and Transfer of Undertakings) Act, 1982'}, 
    {value : 'Bombay Tolls on Roads and Bridges Act',view : 'Bombay Tolls on Roads and Bridges Act, 1875'}, 
    {value : 'Maharashtra Transfer of Ownership of Minor Forest Produce in The Scheduled Areas and The  Minor Forest Produce (Regulation of Trade) (Amendment) Act',view : 'Maharashtra Transfer of Ownership of Minor Forest Produce in The Scheduled Areas and The  Minor Forest Produce (Regulation of Trade) (Amendment) Act, 1997'}, 
    {value : 'Maharashtra Tribals Economic Condition (Improvement) Act',view : 'Maharashtra Tribals Economic Condition (Improvement) Act, 1976'}, 
    {value : 'Maharashtra Truck Terminal (Regulation of Location) Act',view : 'Maharashtra Truck Terminal (Regulation of Location) Act, 1995'}, 
    {value : 'Maharashtra Tuljapur Development Authority Act',view : 'Maharashtra Tuljapur Development Authority Act, 2008'}, 
    {value : 'Maharashtra Universities Act',view : 'Maharashtra Universities Act, 1994'}, 
    {value : 'Maharashtra University of Health Sciences Act',view : 'Maharashtra University of Health Sciences Act, 1998'}, 
    {value : 'Maharashtra (Urban Areas) Protection and Preservation of Trees Act',view : 'Maharashtra (Urban Areas) Protection and Preservation of Trees Act, 1975'}, 
    {value : 'Maharashtra Urban Immovable Property Tax (Abolition) and General Tax (Increase of Maximum Rate) Act',view : 'Maharashtra Urban Immovable Property Tax (Abolition) and General Tax (Increase of Maximum Rate) Act, 1962'}, 
    {value : 'Maharashtra Value Added Tax Act',view : 'Maharashtra Value Added Tax Act, 2002'}, 
    {value : 'Maharashtra Veterinary Practitioners Act',view : 'Maharashtra Veterinary Practitioners Act, 1971'}, 
    {value : 'Maharashtra Vexatious Litigation (Prevention) Act',view : 'Maharashtra Vexatious Litigation (Prevention) Act, 1971'}, 
    {value : 'Maharashtra Vidarbha Irrigation Development Corporation Act',view : 'Maharashtra Vidarbha Irrigation Development Corporation Act, 1997'}, 
    {value : 'Bombay Village Panchayat Act',view : 'Bombay Village Panchayat Act, 1958'}, 
    {value : 'Maharashtra Village Police Act',view : 'Maharashtra Village Police Act, 1967'}, 
    {value : 'Bombay Village Sanitation Act',view : 'Bombay Village Sanitation Act, 1889'}, 
    {value : 'Bombay Warehouses Act',view : 'Bombay Warehouses Act, 1959'}, 
    {value : 'Maharashtra Water Conservation Corporation Act',view : 'Maharashtra Water Conservation Corporation Act, 2000'}, 
    {value : 'Maharashtra Water Resources Regulatory Authority Act',view : 'Maharashtra Water Resources Regulatory Authority Act, 2005'}, 
    {value : 'Bombay Weights and Measures Act',view : 'Bombay Weights and Measures Act, 1932'}, 
    {value : 'Bombay Weights and Measures (Enforcement) Act',view : 'Bombay Weights and Measures (Enforcement) Act, 1958'}, 
    {value : 'Maharashtra West Khandesh Mehwassi Estate (Proprietary Rights Abolition Etc.) Regulation',view : 'Maharashtra West Khandesh Mehwassi Estate (Proprietary Rights Abolition Etc.) Regulation, 1961'}, 
    {value : 'Bombay West Khandesh Mehwassi Estates Regulation',view : 'Bombay West Khandesh Mehwassi Estates Regulation, 1949'}, 
    {value : 'Bombay Wild Animals and Wild Birds Protection Act',view : 'Bombay Wild Animals and Wild Birds Protection Act, 1951'}, 
    {value : 'Maharashtra Workmens\' Minimum House-Rent Allowance Act',view : 'Maharashtra Workmens\' Minimum House-Rent Allowance Act, 1983'}, 
    {value : 'Maharashtra Yashwantrao Chavan  Open University Act',view : 'Maharashtra Yashwantrao Chavan  Open University Act, 1989'}, 
    {value : 'Maharashtra Zilla Parishads and Panchayat Samitis Act',view : 'Maharashtra Zilla Parishads and Panchayat Samitis Act, 1961'}, 
    {value : 'Maharashtra Right to Information (Repeal) Act',view : 'Maharashtra Right to Information (Repeal) Act, 2005'}, 
    {value : 'Bombay Nursing Homes Registration (Amendment) Act',view : 'Bombay Nursing Homes Registration (Amendment) Act, 2005'}, 
    {value : 'Maharashtra State Board of Technical Education (Amendment) Act',view : 'Maharashtra State Board of Technical Education (Amendment) Act, 2005'}, 
    {value : 'Maharashtra Public Records Act',view : 'Maharashtra Public Records Act, 2005'}, 
    {value : 'Bombay Tenancy and Agricultural Lands (Amendment) Act',view : 'Bombay Tenancy and Agricultural Lands (Amendment) Act, 2005'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2006'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2006'}, 
    {value : 'Maharashtra Agricultural Lands (Ceiling on Holdings) (Amendment) Act',view : 'Maharashtra Agricultural Lands (Ceiling on Holdings) (Amendment) Act, 2006'}, 
    {value : 'Regularisation of Unauthorised Developments in the City of Ulhasnagar Act',view : 'Regularisation of Unauthorised Developments in the City of Ulhasnagar Act, 2006'}, 
    {value : 'Maharashtra Non-biodegradable Garbage (Control) Act',view : 'Maharashtra Non-biodegradable Garbage (Control) Act, 2006'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2006'}, 
    {value : 'Bombay Stamp (Amendment) Act',view : 'Bombay Stamp (Amendment) Act, 2006'}, 
    {value : 'Contract Labour (Regulation and Abolition) (Maharashtra Amendment) Act',view : 'Contract Labour (Regulation and Abolition) (Maharashtra Amendment) Act, 2005'}, 
    {value : 'Bombay Motor Vehicles Tax (Second Amendment) Act',view : 'Bombay Motor Vehicles Tax (Second Amendment) Act, 2006'}, 
    {value : 'Maharashtra Legislature Members\' Pension (Amendment) Act',view : 'Maharashtra Legislature Members\' Pension (Amendment) Act, 2006'}, 
    {value : 'Bombay Entertainments Duty (Amendment) Act',view : 'Bombay Entertainments Duty (Amendment) Act, 2006'}, 
    {value : 'Maharashtra State Tax on Professions, Trades, Callings and Employments (Amendment) Act',view : 'Maharashtra State Tax on Professions, Trades, Callings and Employments (Amendment) Act, 2006'}, 
    {value : 'Maharashtra State Enterprises (Restructuring and Other Special Provisions) (Amendment) Act',view : 'Maharashtra State Enterprises (Restructuring and Other Special Provisions) (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Municipal Corporations (Amendment) Act',view : 'Maharashtra Municipal Corporations (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Co-operative Societies (Amendment) Act',view : 'Maharashtra Co-operative Societies (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties Act',view : 'Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties Act, 2005'}, 
    {value : 'Bombay Motor Vehicles Tax (Amendment) Act',view : 'Bombay Motor Vehicles Tax (Amendment) Act, 2006'}, 
    {value : 'Industrial Disputes (Maharashtra Amendment) Act',view : 'Industrial Disputes (Maharashtra Amendment) Act, 2003'}, 
    {value : 'Mumbai Municipal Corporation (Amendment) Act',view : 'Mumbai Municipal Corporation (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act',view : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2006'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2006'}, 
    {value : 'Mumbai Municipal Corporation (Second Amendment) Act',view : 'Mumbai Municipal Corporation (Second Amendment) Act, 2006'}, 
    {value : 'Factories (Maharashtra Amendment) Act',view : 'Factories (Maharashtra Amendment) Act, 2006'}, 
    {value : 'Bombay Prohibition (Amendment) Act',view : 'Bombay Prohibition (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Private Professional and Educational Institutions (Reservation seats for admission for Scheduled Castes, Scheduled Tribes',view : 'Maharashtra Private Professional and Educational Institutions (Reservation seats for admission for Scheduled Castes, Scheduled Tribes, 2006'}, 
    {value : 'De-notified Tribes (Vimukta Jatis), Nomadic Tribes and Other Backward Classes) Act',view : 'De-notified Tribes (Vimukta Jatis), Nomadic Tribes and Other Backward Classes) Act, 2006'}, 
    {value : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act',view : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act',view : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2006'}, 
    {value : 'Maharashtra Devdasi System (Abolition) Act',view : 'Maharashtra Devdasi System (Abolition) Act, 2006'}, 
    {value : 'Maharashtra State Commission for Backward Classes Act',view : 'Maharashtra State Commission for Backward Classes Act, 2005'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Municipal Corporations & Municipal Councils, Nagar Panchayats and Industrial Townships (Third Amendment) Act',view : 'Maharashtra Municipal Corporations & Municipal Councils, Nagar Panchayats and Industrial Townships (Third Amendment) Act, 2006'}, 
    {value : 'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act',view : 'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2006'}, 
    {value : 'Bombay Village Panchayats (Amendment) Act',view : 'Bombay Village Panchayats (Amendment) Act, 2006'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2006'}, 
    {value : 'Maharashtra Appropriation (Excess Expenditure) Act',view : 'Maharashtra Appropriation (Excess Expenditure) Act, 2006'}, 
    {value : 'Maharashtra Appropriation (Second Excess Expenditure) Act',view : 'Maharashtra Appropriation (Second Excess Expenditure) Act, 2006'}, 
    {value : 'Maharashtra Fiscal Responsibility and Budgetary Management (Amendment) Act',view : 'Maharashtra Fiscal Responsibility and Budgetary Management (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Tax on Lotteries Act',view : 'Maharashtra Tax on Lotteries Act, 2006'}, 
    {value : 'Bombay Motor Vehicles Tax (Third Amendment) Act',view : 'Bombay Motor Vehicles Tax (Third Amendment) Act, 2006'}, 
    {value : 'Maharashtra Education and Employment Guarantee (Cess) (Amendment) Act',view : 'Maharashtra Education and Employment Guarantee (Cess) (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Legislature Members\' Pension (Third Amendment) Act',view : 'Maharashtra Legislature Members\' Pension (Third Amendment) Act, 2006'}, 
    {value : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Second Amendment) Act',view : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Second Amendment) Act, 2006'}, 
    {value : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Second Amendment) Act',view : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Second Amendment) Act, 2006'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2006'}, 
    {value : 'Maharashtra Local Authority Members\' Disqualification (Amendment) Act',view : 'Maharashtra Local Authority Members\' Disqualification (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Employment Guarantee (Amendment) Act',view : 'Maharashtra Employment Guarantee (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Legislature Members\' Pension (Second Amendment) Act',view : 'Maharashtra Legislature Members\' Pension (Second Amendment) Act, 2006'}, 
    {value : 'Hyderabad Abolition of Inams and Cash Grants (Amendment) Act',view : 'Hyderabad Abolition of Inams and Cash Grants (Amendment) Act, 2006'}, 
    {value : 'Maharashtra Fire Prevention and Life Safety Measures Act',view : 'Maharashtra Fire Prevention and Life Safety Measures Act, 2006'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2007'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2007'}, 
    {value : 'Maharashtra State Tax on Professions, Trades, Callings and Employments and the Maharashtra Value Added Tax (Amendment) Act',view : 'Maharashtra State Tax on Professions, Trades, Callings and Employments and the Maharashtra Value Added Tax (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act',view : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2007'}, 
    {value : 'Vidarbha Irrigation Development Corporation and the Konkan Irrigation Development Corporation (Amendment) Act',view : 'Vidarbha Irrigation Development Corporation and the Konkan Irrigation Development Corporation (Amendment) Act, 2007'}, 
    {value : 'Maharashtra State Enterprises (Restructuring and Other Special Provisions)(Repeal) Act',view : 'Maharashtra State Enterprises (Restructuring and Other Special Provisions)(Repeal) Act, 2007'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2007'}, 
    {value : 'Maharashtra Municipal Corporations (Amendment) Act',view : 'Maharashtra Municipal Corporations (Amendment) Act, 2007'}, 
    {value : 'Mumbai Municipal Corporation and the Bombay Provincial Municipal Corporation (Temporary Amendment) Act',view : 'Mumbai Municipal Corporation and the Bombay Provincial Municipal Corporation (Temporary Amendment) Act, 2007'}, 
    {value : 'Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties (Amendment) Act',view : 'Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties (Amendment) Act, 2007'}, 
    {value : 'Bombay Primary Education and the Maharashtra Employees of Private Schools (Conditions of Service) Regulation (Amendment) Act',view : 'Bombay Primary Education and the Maharashtra Employees of Private Schools (Conditions of Service) Regulation (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Regional and Town Planning (Amendment) Act',view : 'Maharashtra Regional and Town Planning (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Land Revenue Code (Amendment) Act',view : 'Maharashtra Land Revenue Code (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Legislature Members\' Salaries and Allowances (Amendment) Act',view : 'Maharashtra Legislature Members\' Salaries and Allowances (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Value Added Tax (Amendment) Act',view : 'Maharashtra Value Added Tax (Amendment) Act, 2007'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2007'}, 
    {value : 'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act',view : 'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Township (Amendment) Act',view : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Township (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Land Revenue Code (Second Amendment) Act',view : 'Maharashtra Land Revenue Code (Second Amendment) Act, 2007'}, 
    {value : 'Maharashtra Land Revenue Code (Third Amendment) Act',view : 'Maharashtra Land Revenue Code (Third Amendment) Act, 2007'}, 
    {value : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act',view : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2007'}, 
    {value : 'Maharashtra Legislature Members\' Pension (Amendment) Act',view : 'Maharashtra Legislature Members\' Pension (Amendment) Act, 2007'}, 
    {value : 'Code of Criminal Procedure (Maharashtra Amendment) Act',view : 'Code of Criminal Procedure (Maharashtra Amendment) Act, 2007'}, 
    {value : 'Prevention of Food Adulteration (Maharashtra Amendment) Act',view : 'Prevention of Food Adulteration (Maharashtra Amendment) Act, 2006'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2007'}, 
    {value : 'Maharashtra Co-operative Societies (Amendment) Act',view : 'Maharashtra Co-operative Societies (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Co-operative Societies (Third Amendment) Act',view : 'Maharashtra Co-operative Societies (Third Amendment) Act, 2007'}, 
    {value : 'Dangerous Persons (Amendment) Act',view : 'Dangerous Persons (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2007'}, 
    {value : 'Bombay Government Premises (Eviction) (Amendment) Act',view : 'Bombay Government Premises (Eviction) (Amendment) Act, 2007'}, 
    {value : 'Bombay Provincial Municipal Corporations, the City of Nagar Corporation and the Maharashtra Municipal Councils, Nagar',view : 'Bombay Provincial Municipal Corporations, the City of Nagar Corporation and the Maharashtra Municipal Councils, Nagar'}, 
    {value : 'Panchayats and Industrial Townships (Amendment) Act',view : 'Panchayats and Industrial Townships (Amendment) Act, 2007'}, 
    {value : 'Maharashtra Municipal Corporations (Second Amendment) Act',view : 'Maharashtra Municipal Corporations (Second Amendment) Act, 2007'}, 
    {value : 'Maharashtra Ownership Flats (Regulation of the Promotion of Construction, Sale, Management and Transfer) (Amendment) Act',view : 'Maharashtra Ownership Flats (Regulation of the Promotion of Construction, Sale, Management and Transfer) (Amendment) Act, 2005'}, 
    {value : 'Bombay State Tax on Professions, Trades, Callings and Employments Act',view : 'Bombay State Tax on Professions, Trades, Callings and Employments Act, 1975'}, 
    {value : 'Maharashtra Tax Laws (Levy and Amendment) Act',view : 'Maharashtra Tax Laws (Levy and Amendment) Act, 2013'}, 
    {value : 'Relief Fund (Repeal) Act',view : 'Relief Fund (Repeal) Act, 2008'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2008'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2008'}, 
    {value : 'Maharashtra Value Added Tax (Amendment) Act',view : 'Maharashtra Value Added Tax (Amendment) Act, 2008'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2008'}, 
    {value : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act',view : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2008'}, 
    {value : 'Maharashtra Co-operative Societies (Amendment) Act',view : 'Maharashtra Co-operative Societies (Amendment) Act, 2008'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Third Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Third Amendment) Act, 2007'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2008'}, 
    {value : 'Mumbai Municipal Corporation (Amendment) Act',view : 'Mumbai Municipal Corporation (Amendment) Act, 2008'}, 
    {value : 'Maharashtra Tax on Sales of Electricity (Amendment) Act',view : 'Maharashtra Tax on Sales of Electricity (Amendment) Act, 2008'}, 
    {value : 'Bombay Stamp (Amendment) Act',view : 'Bombay Stamp (Amendment) Act, 2008'}, 
    {value : 'Bombay Court-fees (Amendment) Act',view : 'Bombay Court-fees (Amendment) Act, 2008'}, 
    {value : 'Mumbai Municipal Corporation (Second Amendment) Act',view : 'Mumbai Municipal Corporation (Second Amendment) Act, 2008'}, 
    {value : 'Bombay Paragana and Kulkarni Watans (Abolition) Act',view : 'Bombay Paragana and Kulkarni Watans (Abolition) Act, 1950'}, 
    {value : 'Bombay Service Inams (Useful to Community) Abolition Act',view : 'Bombay Service Inams (Useful to Community) Abolition Act, 1953'}, 
    {value : 'Maharashtra Merged Territories (Janjira and Bhor) Khoti Tenure Abolition Act',view : 'Maharashtra Merged Territories (Janjira and Bhor) Khoti Tenure Abolition Act, 2012'}, 
    {value : 'Watans Abolition and the Maharashtra Revenue Patels (Abolition of Office) (Amendment) Act',view : 'Watans Abolition and the Maharashtra Revenue Patels (Abolition of Office) (Amendment) Act, 2008'}, 
    {value : 'Maharashtra Legislature Members\' Salaries and allowances and Pension (Amendment) Act',view : 'Maharashtra Legislature Members\' Salaries and allowances and Pension (Amendment) Act, 2008'}, 
    {value : 'Shree Siddhi Vinayak Ganpati Trust (Prabhadevi) (Amendment) Act',view : 'Shree Siddhi Vinayak Ganpati Trust (Prabhadevi) (Amendment) Act, 2008'}, 
    {value : 'Mumbai Municipal Corporation (Temporary Amendment) Act',view : 'Mumbai Municipal Corporation (Temporary Amendment) Act, 2007'}, 
    {value : 'Maharashtra Legislature Members\' Pension (Amendment) Act',view : 'Maharashtra Legislature Members\' Pension (Amendment) Act, 2008'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2008'}, 
    {value : 'Maharashtra Legislature Members\' Salaries and Allowances (Amendment) Act',view : 'Maharashtra Legislature Members\' Salaries and Allowances (Amendment) Act, 2008'}, 
    {value : 'Maharashtra High Court (Hearing of Writ Petitions by Division Bench and Abolition of Letters Patent Appeals) (Amendment) Act',view : 'Maharashtra High Court (Hearing of Writ Petitions by Division Bench and Abolition of Letters Patent Appeals) (Amendment) Act, 2008'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2008'}, 
    {value : 'Maharashtra Appropriation (Excess Expenditure) Act',view : 'Maharashtra Appropriation (Excess Expenditure) Act, 2008'}, 
    {value : 'Maharashtra Appropriation (Second Excess Expenditure) Act',view : 'Maharashtra Appropriation (Second Excess Expenditure) Act, 2008'}, 
    {value : 'Maharashtra Housig and Area Development (Amendment) Act',view : 'Maharashtra Housig and Area Development (Amendment) Act, 2008'}, 
    {value : 'Maharashtra Domestic Workers Welfare Board Act',view : 'Maharashtra Domestic Workers Welfare Board Act, 2008'}, 
    {value : 'Bombay Police (Amendment) Act',view : 'Bombay Police (Amendment) Act, 2008'}, 
    {value : 'Tuljapur Development Authority Act',view : 'Tuljapur Development Authority Act, 2008'}, 
    {value : 'Bombay Provincial Municipal Corporations (Amendment) Act',view : 'Bombay Provincial Municipal Corporations (Amendment) Act, 2008'}, 
    {value : 'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act',view : 'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2008'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Fifth Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Fifth Amendment) Act, 2008'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Sixth Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Sixth Amendment) Act, 2008'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2009'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2009'}, 
    {value : 'Maharashtra Land Revenue Code (Amendment) Act',view : 'Maharashtra Land Revenue Code (Amendment) Act, 2009'}, 
    {value : 'Mumbai Municipal Corporation (Third Amendment) Act',view : 'Mumbai Municipal Corporation (Third Amendment) Act, 2006'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2009'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2009'}, 
    {value : 'The Maharashtra Universities and the Kavi Kulaguru Kalidas Sanskrit Vishvavidyalaya (University) (Amendment) Act',view : 'The Maharashtra Universities and the Kavi Kulaguru Kalidas Sanskrit Vishvavidyalaya (University) (Amendment) Act, 2009'}, 
    {value : 'Pandharpur Development Authority Act',view : 'Pandharpur Development Authority Act, 2009'}, 
    {value : 'Maharashtra Regional and Town Planning (Second Amendment) Act',view : 'Maharashtra Regional and Town Planning (Second Amendment) Act, 2009'}, 
    {value : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act',view : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2009'}, 
    {value : 'Maharashtra Legislative Council (Chairman & Deputy Chairman) & Maharashtra Legislative Assembly (Speaker & Deputy Speaker) Salaries & Allowances Act',view : 'Maharashtra Legislative Council (Chairman & Deputy Chairman) & Maharashtra Legislative Assembly (Speaker & Deputy Speaker) Salaries & Allowances Act, 2017'}, 
    {value : 'Maharashtra Ministers Salaries and Allowances',view : 'Maharashtra Ministers Salaries and Allowances, 1956'}, 
    {value : 'Maharashtra Legislature Members’ (Removal of Disqualifications) Act',view : 'Maharashtra Legislature Members’ (Removal of Disqualifications) Act, 1956'}, 
    {value : 'Maharashtra Legislature Members’ Pension Act',view : 'Maharashtra Legislature Members’ Pension Act, 1976'}, 
    {value : 'Maharashtra Legislature Members’ Salaries and Allowances Act',view : 'Maharashtra Legislature Members’ Salaries and Allowances Act, 1956'}, 
    {value : 'Maharashtra Legislature Salaries and Allowances (Amendment) Act',view : 'Maharashtra Legislature Salaries and Allowances (Amendment) Act, 2009'}, 
    {value : 'Maharashtra Cotton Seeds (Regulation of Supply, Distribution, Sale and Fixation of Sale Price) Act',view : 'Maharashtra Cotton Seeds (Regulation of Supply, Distribution, Sale and Fixation of Sale Price) Act, 2009'}, 
    {value : 'Bombay Public Trusts (Amendment) Act',view : 'Bombay Public Trusts (Amendment) Act, 2009'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2009'}, 
    {value : 'Maharashtra Value Added Tax (Levy and Amendment and Validation) Act',view : 'Maharashtra Value Added Tax (Levy and Amendment and Validation) Act, 2009'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2009'}, 
    {value : 'Maharashtra Prevention of Dangerous Activities of Slumlords, Bottleggers, Drug-offenders and Dangerous persons (Amendment) Act',view : 'Maharashtra Prevention of Dangerous Activities of Slumlords, Bottleggers, Drug-offenders and Dangerous persons (Amendment) Act, 2009'}, 
    {value : 'Maharashtra Regional and Town Planning (Third Amendment) Act',view : 'Maharashtra Regional and Town Planning (Third Amendment) Act, 2009'}, 
    {value : 'Maharashtra University of Health Sciences (Amendment) Act',view : 'Maharashtra University of Health Sciences (Amendment) Act, 2009'}, 
    {value : 'Bombay Provincial Municipal Corporations and the Bombay Village Panchayats (Amendment) Act',view : 'Bombay Provincial Municipal Corporations and the Bombay Village Panchayats (Amendment) Act, 2009'}, 
    {value : 'Maharashtra Project Affected Persons Rehabilitation (Amendment) Act',view : 'Maharashtra Project Affected Persons Rehabilitation (Amendment) Act, 2009'}, 
    {value : 'Maharashtra Motor Vehicles Tax (Amendment) Act',view : 'Maharashtra Motor Vehicles Tax (Amendment) Act, 2009'}, 
    {value : 'Dr. Babasaheb Ambedkar Technological University and the Maharashtra Universities (Amendment) Act',view : 'Dr. Babasaheb Ambedkar Technological University and the Maharashtra Universities (Amendment) Act, 2009'}, 
    {value : 'Bombay Entertainments Duty (Amendment) Act',view : 'Bombay Entertainments Duty (Amendment) Act, 2009'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2010'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2010'}, 
    {value : 'Bombay Stamp (Amendment) Act',view : 'Bombay Stamp (Amendment) Act, 2009'}, 
    {value : 'Maharashtra State Security Corporation Act',view : 'Maharashtra State Security Corporation Act, 2010'}, 
    {value : 'Maharashtra Value Added Tax (Amendment) Act',view : 'Maharashtra Value Added Tax (Amendment) Act, 2010'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2010'}, 
    {value : 'Bombay Court-fees (Amendment and Continuance) Act',view : 'Bombay Court-fees (Amendment and Continuance) Act, 2010'}, 
    {value : 'Bombay Provincial Municipal Corporations Act 1949',view : 'Bombay Provincial Municipal Corporations Act 1949'}, 
    {value : 'City of Nagpur Corporation Act',view : 'City of Nagpur Corporation Act, 1948'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment), Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment), Act, 2012'}, 
    {value : 'and the Maharashtra Tax on Buildings (with larger Residential Premises) (Re-enacted)(Amendment) Act',view : 'and the Maharashtra Tax on Buildings (with larger Residential Premises) (Re-enacted)(Amendment) Act, 2009'}, 
    {value : 'Maharashtra Medicare Service Persons and Medicare Service Institutions (Prevention of Violence and Damage or Loss to Property) Act',view : 'Maharashtra Medicare Service Persons and Medicare Service Institutions (Prevention of Violence and Damage or Loss to Property) Act, 2010'}, 
    {value : 'Maharashtra Tax Laws (Levy and Amendment) Act',view : 'Maharashtra Tax Laws (Levy and Amendment) Act, 2010'}, 
    {value : 'Bombay Motor Vehicles Tax (Amendment) Act',view : 'Bombay Motor Vehicles Tax (Amendment) Act, 2010'}, 
    {value : 'Maharashtra Workmen\'s Minimum House-rent Allowance (Amendment) Act',view : 'Maharashtra Workmen\'s Minimum House-rent Allowance (Amendment) Act, 2010'}, 
    {value : 'Bombay Betting Tax (Amendment) Act',view : 'Bombay Betting Tax (Amendment) Act, 2010'}, 
    {value : 'Bombay Village Panchayats and Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act',view : 'Bombay Village Panchayats and Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2010'}, 
    {value : 'Maharashtra University of Health Sciences (Amendment) Act',view : 'Maharashtra University of Health Sciences (Amendment) Act, 2010'}, 
    {value : 'Maharashtra Agricultural Universities (Krishi Vidyapeeths) (Amendment) Act',view : 'Maharashtra Agricultural Universities (Krishi Vidyapeeths) (Amendment) Act, 2010'}, 
    {value : 'Maharashtra Animal and Fishery Sciences University (Amendment) Act',view : 'Maharashtra Animal and Fishery Sciences University (Amendment) Act, 2010'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2010'}, 
    {value : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act',view : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2010'}, 
    {value : 'Bombay Entertainments Duty (Amendment) Act',view : 'Bombay Entertainments Duty (Amendment) Act, 2010'}, 
    {value : 'Bombay Village Panchayats (Amendment) Act',view : 'Bombay Village Panchayats (Amendment) Act, 2010'}, 
    {value : 'Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act',view : 'Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2010'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2010'}, 
    {value : 'Payment of Wages and Minimum Wages (Maharashtra Amendment) Act',view : 'Payment of Wages and Minimum Wages (Maharashtra Amendment) Act, 2010'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Third Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Third Amendment) Act, 2010'}, 
    {value : 'Bombay Village Panchayats (Second Amendment) Act',view : 'Bombay Village Panchayats (Second Amendment) Act, 2010'}, 
    {value : 'Maharashtra Regional and Town Planning (Amendment and Validation) Act',view : 'Maharashtra Regional and Town Planning (Amendment and Validation) Act, 2010'}, 
    {value : 'Bombay Motor Vehicles Tax (Second Amendment) Act',view : 'Bombay Motor Vehicles Tax (Second Amendment) Act, 2010'}, 
    {value : 'Bombay Entertainments Duty (Second Amendment) Act',view : 'Bombay Entertainments Duty (Second Amendment) Act, 2010'}, 
    {value : 'Bombay Village Panchayats and Maharashtra Zilla Parishads and panchayat Samitis (Second Amendment) Act',view : 'Bombay Village Panchayats and Maharashtra Zilla Parishads and panchayat Samitis (Second Amendment) Act, 2010'}, 
    {value : 'Maharashtra Regional and Town Planning (Amendment) Act',view : 'Maharashtra Regional and Town Planning (Amendment) Act, 2010'}, 
    {value : 'Maharashtra University of Health Sciences (Second Amendment) Act',view : 'Maharashtra University of Health Sciences (Second Amendment) Act, 2010'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2010'}, 
    {value : 'Maharashtra Co-operative Societies (Amendment) Act',view : 'Maharashtra Co-operative Societies (Amendment) Act, 2010'}, 
    {value : 'Payment of Bonus (Maharashtra Amendment) Act',view : 'Payment of Bonus (Maharashtra Amendment) Act, 2010'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2011'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2011'}, 
    {value : 'Maharashtra Value Added Tax (Amendment and Validation) Act',view : 'Maharashtra Value Added Tax (Amendment and Validation) Act, 2011'}, 
    {value : 'Maharashtra Value Added Tax (Amendment) Act',view : 'Maharashtra Value Added Tax (Amendment) Act, 2011'}, 
    {value : 'Bombay Local Fund Audit (Amendment) Act',view : 'Bombay Local Fund Audit (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2010'}, 
    {value : 'Maharashtra Regional and Town Planning (Second Amendment) Act',view : 'Maharashtra Regional and Town Planning (Second Amendment) Act, 2010'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Municipal Corporations (Amendment) Act',view : 'Maharashtra Municipal Corporations (Amendment) Act, 2011'}, 
    {value : 'Bombay Entertainments Duty (Amendment) Act',view : 'Bombay Entertainments Duty (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Municipal Property Tax Board Act',view : 'Maharashtra Municipal Property Tax Board Act, 2011'}, 
    {value : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act',view : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2011'}, 
    {value : 'Maharashtra Universities (Amendment) Act',view : 'Maharashtra Universities (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2011'}, 
    {value : 'Maharashtra Lokayukta and Upa-Lokayuktas (Amendment) Act',view : 'Maharashtra Lokayukta and Upa-Lokayuktas (Amendment) Act, 2011'}, 
    {value : 'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act',view : 'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2011'}, 
    {value : 'Maharashtra Water Resources Regulatory Authority (Amendment and Continuance) Act',view : 'Maharashtra Water Resources Regulatory Authority (Amendment and Continuance) Act, 2011'}, 
    {value : 'Bombay Homoeopathic Practitioners\' (Amendment) Act',view : 'Bombay Homoeopathic Practitioners\' (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Legislature Members\' Pension (Amendment) Act',view : 'Maharashtra Legislature Members\' Pension (Amendment) Act, 2011'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2011'}, 
    {value : 'Bombay Court-fees (Amendment) Act',view : 'Bombay Court-fees (Amendment) Act, 2011'}, 
    {value : 'Bombay Provincial Municipal Corporations, the City of Nagar Corporation and the Maharashtra Municipal Councils, Nagar Panchayats',view : 'Bombay Provincial Municipal Corporations, the City of Nagar Corporation and the Maharashtra Municipal Councils, Nagar Panchayats'}, 
    {value : 'and Industrial Townships (Amendment) Act',view : 'and Industrial Townships (Amendment) Act, 2011'}, 
    {value : 'Bombay Village Panchayats (Amendment) Act',view : 'Bombay Village Panchayats (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Universities (Second Amendment) Act',view : 'Maharashtra Universities (Second Amendment) Act, 2011'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2010'}, 
    {value : 'Shree Sai Baba Sansthan Trust (Shridi) (Amendment) Act',view : 'Shree Sai Baba Sansthan Trust (Shridi) (Amendment) Act, 2011'}, 
    {value : 'Nagpur Improvoement Trust (Amendment) Act',view : 'Nagpur Improvoement Trust (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Municipal Corporation (Second Amendment) Act',view : 'Maharashtra Municipal Corporation (Second Amendment) Act, 2011'}, 
    {value : 'Mimimum Wages (Maharashtra Amendment) Act',view : 'Mimimum Wages (Maharashtra Amendment) Act, 2010'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2011'}, 
    {value : 'Maharashtra Appropriation (Excess Expenditure) Act',view : 'Maharashtra Appropriation (Excess Expenditure) Act, 2011'}, 
    {value : 'Maharashtra Appropriation (Second Excess Expenditure) Act',view : 'Maharashtra Appropriation (Second Excess Expenditure) Act, 2011'}, 
    {value : 'Maharashtra Appropriation (Third Excess Expenditure) Act',view : 'Maharashtra Appropriation (Third Excess Expenditure) Act, 2011'}, 
    {value : 'Maharashtra Appropriation (Fourth Excess Expenditure) Act',view : 'Maharashtra Appropriation (Fourth Excess Expenditure) Act, 2011'}, 
    {value : 'Bombay Village Panchayats and Maharashtra Zilla Parishads and Panchayat Samitis (Second Amendment) Act',view : 'Bombay Village Panchayats and Maharashtra Zilla Parishads and Panchayat Samitis (Second Amendment) Act, 2011'}, 
    {value : 'Maharashtra Municipal Corporation (Third Amendment) Act',view : 'Maharashtra Municipal Corporation (Third Amendment) Act, 2011'}, 
    {value : 'Bombay Stamp (Amendment) Act',view : 'Bombay Stamp (Amendment) Act, 2011'}, 
    {value : 'Nagar Panchayats and Industrial Townships (Amendment) Act',view : 'Nagar Panchayats and Industrial Townships (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Land Revenue Code and the Maharashtra Restoration of Lands to Scheduled Tribes (Amendment) Act',view : 'Maharashtra Land Revenue Code and the Maharashtra Restoration of Lands to Scheduled Tribes (Amendment) Act, 2010'}, 
    {value : 'Bombay Civil Courts (Amendment) Act',view : 'Bombay Civil Courts (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Agricultural Lands (Ceiling on Holdings) (Amendment) Act',view : 'Maharashtra Agricultural Lands (Ceiling on Holdings) (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Municipal Corporations, Municipal Councils and Maharashtra Regional and Town Planning (Amendment) Act',view : 'Maharashtra Municipal Corporations, Municipal Councils and Maharashtra Regional and Town Planning (Amendment) Act, 2010'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2012'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2012'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2012'}, 
    {value : 'Mumbai Municipal Corporation and the Maharashtra (Urban Areas) Protection and Preservation of Trees (Amendment) Act',view : 'Mumbai Municipal Corporation and the Maharashtra (Urban Areas) Protection and Preservation of Trees (Amendment) Act, 2012'}, 
    {value : 'Bombay Electricity Duty (Amendment) Act',view : 'Bombay Electricity Duty (Amendment) Act, 2012'}, 
    {value : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act',view : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2012'}, 
    {value : 'Bombay Primary Education and the Maharashtra Employees of Private Schools (Conditions of Service) Regulation (Amendment) Act',view : 'Bombay Primary Education and the Maharashtra Employees of Private Schools (Conditions of Service) Regulation (Amendment) Act, 2011'}, 
    {value : 'Registration (Maharashtra Amendment) Act',view : 'Registration (Maharashtra Amendment) Act, 2010'}, 
    {value : 'Maharashtra Slum Areas (Improvement, Clearance and Redevelopment) (Amendment) Act',view : 'Maharashtra Slum Areas (Improvement, Clearance and Redevelopment) (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Essential Services Maintenance Act',view : 'Maharashtra Essential Services Maintenance Act, 2011'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2012'}, 
    {value : 'Bombay Motor Vehicles Tax (Amendment) Act',view : 'Bombay Motor Vehicles Tax (Amendment) Act, 2012'}, 
    {value : 'Maharashtra Municipal Councils Nagar Panchayats and Industrial Township (Amendment) Act',view : 'Maharashtra Municipal Councils Nagar Panchayats and Industrial Township (Amendment) Act, 2011'}, 
    {value : 'Bombay Village Panchayats (Second Amendment) Act',view : 'Bombay Village Panchayats (Second Amendment) Act, 2012'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2012'}, 
    {value : 'Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act',view : 'Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2012'}, 
    {value : 'Maharashtra Land Revenue Code (Amendment) Act',view : 'Maharashtra Land Revenue Code (Amendment) Act, 2012'}, 
    {value : 'Mumbai Municipal Corporation (Amendment and Validation) Act',view : 'Mumbai Municipal Corporation (Amendment and Validation) Act, 2011'}, 
    {value : 'Maharashtra State Minorities Commission (Amendment) Act',view : 'Maharashtra State Minorities Commission (Amendment) Act, 2012'}, 
    {value : 'Bombay Village Panchayats (Amendment) Act',view : 'Bombay Village Panchayats (Amendment) Act, 2012'}, 
    {value : 'Bombay Provincial Municipal Corporations (Amendment) and the City of Nagpur Corporation (Repeal) Act',view : 'Bombay Provincial Municipal Corporations (Amendment) and the City of Nagpur Corporation (Repeal) Act, 2011'}, 
    {value : 'Maharashtra (Change of Short Titles of certain Bombay Acts) Act',view : 'Maharashtra (Change of Short Titles of certain Bombay Acts) Act, 2011'}, 
    {value : 'Bombay City Civil Court (Amendment) Act',view : 'Bombay City Civil Court (Amendment) Act, 2012'}, 
    {value : 'Maharashtra Education and Employment Guarantee (Cess) (Amendment) Act',view : 'Maharashtra Education and Employment Guarantee (Cess) (Amendment) Act, 2012'}, 
    {value : 'State Election Commissioner (Qualifications and Appointment) (Amendment) Act',view : 'State Election Commissioner (Qualifications and Appointment) (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Municipal Corporations and Municipal Councils (Third Amendment) Act',view : 'Maharashtra Municipal Corporations and Municipal Councils (Third Amendment) Act, 2012'}, 
    {value : 'Maharashtra Village Panchayats (Third Amendment) Act',view : 'Maharashtra Village Panchayats (Third Amendment) Act, 2012'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2012'}, 
    {value : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Second Amendment) Act',view : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Second Amendment) Act, 2012'}, 
    {value : 'Maharashtra State Security Corporation (Amendment) Act',view : 'Maharashtra State Security Corporation (Amendment) Act, 2012'}, 
    {value : 'Maharashtra District Planning Committees (Constitution and Functions) (Amendment) Act',view : 'Maharashtra District Planning Committees (Constitution and Functions) (Amendment) Act, 2012'}, 
    {value : 'Maharashtra Self-financed Schools (Establishment and Regulation) Act',view : 'Maharashtra Self-financed Schools (Establishment and Regulation) Act, 2012'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2013'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2013'}, 
    {value : 'Maharashtra Value Added Tax (Amendment) Act',view : 'Maharashtra Value Added Tax (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Self-financed Schools (Establishment and Regulation) (Amendment) Act',view : 'Maharashtra Self-financed Schools (Establishment and Regulation) (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Entertainments Duty (Amendmen) Act',view : 'Maharashtra Entertainments Duty (Amendmen) Act, 2013'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2013'}, 
    {value : 'Maharashtra Tax Laws (Levy and Amendment) Act',view : 'Maharashtra Tax Laws (Levy and Amendment) Act, 2013'}, 
    {value : 'Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act',view : 'Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Project Affected Persons Rehabilitation (Amendment) Act',view : 'Maharashtra Project Affected Persons Rehabilitation (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Legislative Council (Chairman and Deputy Chairman) and Maharashtra Legislative Assembly',view : 'Maharashtra Legislative Council (Chairman and Deputy Chairman) and Maharashtra Legislative Assembly'}, 
    {value : '(Speaker and Deputy Speaker) Salaries and Allowances, the Maharashtra Ministers\' Salaries and Allowances,',view : '(Speaker and Deputy Speaker) Salaries and Allowances, the Maharashtra Ministers\' Salaries and Allowances,'}, 
    {value : 'the Maharashtra Legislature Members\' Salaries and Allowances and the Leaders of Opposition in',view : 'the Maharashtra Legislature Members\' Salaries and Allowances and the Leaders of Opposition in'}, 
    {value : 'Maharashtra Legislature Salaries and Allowances (Amendment) Act',view : 'Maharashtra Legislature Salaries and Allowances (Amendment) Act, 2013'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2013'}, 
    {value : 'Maharashtra Agricultural Universities (Krishi Vidyapeeths) (Amendment) Act',view : 'Maharashtra Agricultural Universities (Krishi Vidyapeeths) (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Universities (Amendment) Act',view : 'Maharashtra Universities (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Industrial Development (Amendment) Act',view : 'Maharashtra Industrial Development (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Co-operative Societies (Amendment) Act',view : 'Maharashtra Co-operative Societies (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Universities (Second Amendment) Act',view : 'Maharashtra Universities (Second Amendment) Act, 2013'}, 
    {value : 'Maharashtra Live-Stock Improvement (Amendment) Act',view : 'Maharashtra Live-Stock Improvement (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Self-financed Schools (Establishment and Regulation) (Second Amendment) Act',view : 'Maharashtra Self-financed Schools (Establishment and Regulation) (Second Amendment) Act, 2013'}, 
    {value : 'Maharashtra Unauthorized Institutions and Unauthorized Courses of Study in Agriculture, Animal and Fishery Sciences, Health Sciences, Higher Technical and Vocational Education (Prohibition) Act',view : 'Maharashtra Unauthorized Institutions and Unauthorized Courses of Study in Agriculture, Animal and Fishery Sciences, Health Sciences, Higher Technical and Vocational Education (Prohibition) Act, 2013'}, 
    {value : 'Maharashtra Khadi and Village Industries (Amendment) Act',view : 'Maharashtra Khadi and Village Industries (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Devdasi System (Abolition) (Amendment) Act',view : 'Maharashtra Devdasi System (Abolition) (Amendment) Act, 2013'}, 
    {value : 'Maharashtra State Board of Nursing and Paramedical Education Act',view : 'Maharashtra State Board of Nursing and Paramedical Education Act, 2013'}, 
    {value : 'Maharashtra Legislature Members\' Pension (Amendment) Act',view : 'Maharashtra Legislature Members\' Pension (Amendment) Act, 2013'}, 
    {value : 'Bombay Shops and Establishments (Amendment) Act',view : 'Bombay Shops and Establishments (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Groundwater (Development and Management) Act',view : 'Maharashtra Groundwater (Development and Management) Act, 2009'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2013'}, 
    {value : 'Maharashtra Appropriation (Excess Expenditure) Act',view : 'Maharashtra Appropriation (Excess Expenditure) Act, 2013'}, 
    {value : 'Maharashtra Appropriation (Second Excess Expenditure) Act',view : 'Maharashtra Appropriation (Second Excess Expenditure) Act, 2013'}, 
    {value : 'Maharashtra Prevention and Eradication of Human Sacrifice and other Inhuman, Evil and Aghori Practices and Black Magic Act',view : 'Maharashtra Prevention and Eradication of Human Sacrifice and other Inhuman, Evil and Aghori Practices and Black Magic Act, 2013'}, 
    {value : 'Maharashtra Co-operative Societies (Second Amendment) Act',view : 'Maharashtra Co-operative Societies (Second Amendment) Act, 2013'}, 
    {value : 'Maharashtra Agricultural Universities (Krishi Vidyapeeths) (Second Amendment) Act',view : 'Maharashtra Agricultural Universities (Krishi Vidyapeeths) (Second Amendment) Act, 2013'}, 
    {value : 'Maharashtra Regulation of Surgarcane Price (Supplied to Factories) Act',view : 'Maharashtra Regulation of Surgarcane Price (Supplied to Factories) Act, 2013'}, 
    {value : 'The Bombay Tenancy and Agricultural Lands, the Hyderabad Tenancy and Agricultural Lands and the Bombay Tenancy and Agricultural Lands (Vidarbha Region) (Amendment) Act',view : 'The Bombay Tenancy and Agricultural Lands, the Hyderabad Tenancy and Agricultural Lands and the Bombay Tenancy and Agricultural Lands (Vidarbha Region) (Amendment) Act, 2012'}, 
    {value : 'The Maharashtra Housing (Regulation and Development) Act',view : 'The Maharashtra Housing (Regulation and Development) Act, 2012'}, 
    {value : 'The Maharashtra (Suppllementary) Appropriation Act',view : 'The Maharashtra (Suppllementary) Appropriation Act, 2014'}, 
    {value : 'The Maharashtra Appropriation (Vote on Account) Act',view : 'The Maharashtra Appropriation (Vote on Account) Act, 2014'}, 
    {value : 'The Maharashtra Regional and Town Planning (Amendment and Continuance) Act',view : 'The Maharashtra Regional and Town Planning (Amendment and Continuance) Act, 2014'}, 
    {value : 'The Maharashtra National Law University Act',view : 'The Maharashtra National Law University Act, 2014'}, 
    {value : 'The Maharashtra Educational Institutions (Regulation of Fee) Act',view : 'The Maharashtra Educational Institutions (Regulation of Fee) Act, 2011'}, 
    {value : 'The Maharashtra Money-Lending (Regulation) Act',view : 'The Maharashtra Money-Lending (Regulation) Act, 2014'}, 
    {value : 'The Maharashtra Slum Areas (Improvement, Clearance and Redevelopment) (Amendment) Act',view : 'The Maharashtra Slum Areas (Improvement, Clearance and Redevelopment) (Amendment) Act, 2014'}, 
    {value : 'The Bombay Tenancy and Agricultural Lands, the Hyderabad Tenancy and Agricultural Lands and the Bombay Tenancy and Agricultural Lands (Vidarbha Region) (Amendment) Act',view : 'The Bombay Tenancy and Agricultural Lands, the Hyderabad Tenancy and Agricultural Lands and the Bombay Tenancy and Agricultural Lands (Vidarbha Region) (Amendment) Act, 2011'}, 
    {value : 'The Maharashtra Municipal Corporations and Municipal Councils Temporary Postponement of Elections (of the Mayors and Deputy Mayors of certain Municipal Corporations and Presidents of certain Municipal Councils due to ensuing general elections to the State Legislative Assembly) Act',view : 'The Maharashtra Municipal Corporations and Municipal Councils Temporary Postponement of Elections (of the Mayors and Deputy Mayors of certain Municipal Corporations and Presidents of certain Municipal Councils due to ensuing general elections to the State Legislative Assembly) Act, 2014'}, 
    {value : 'The Maharashtra Appropriation Act',view : 'The Maharashtra Appropriation Act, 2014'}, 
    {value : 'The Amity University Act',view : 'The Amity University Act, 2014'}, 
    {value : 'The Spicer Adventist University Act',view : 'The Spicer Adventist University Act, 2014'}, 
    {value : 'The Maharashtra Police (Second Amendment) Act',view : 'The Maharashtra Police (Second Amendment) Act, 2014'}, 
    {value : 'The Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties (Amendment) Act',view : 'The Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties (Amendment) Act, 2014'}, 
    {value : 'The Maharashtra Agricultural Pests and Diseases (Amendment) Act',view : 'The Maharashtra Agricultural Pests and Diseases (Amendment) Act, 2014'}, 
    {value : 'The Maharashtra Village Panchayats (Amendment and Continuance) Act',view : 'The Maharashtra Village Panchayats (Amendment and Continuance) Act, 2014'}, 
    {value : 'The Maharashtra Homoeopathic Practitioners\' and the Maharashtra Medical Council (Amendment) Act',view : 'The Maharashtra Homoeopathic Practitioners\' and the Maharashtra Medical Council (Amendment) Act, 2014'}, 
    {value : 'The Maharashtra Value Added Tax (Amendment) Act',view : 'The Maharashtra Value Added Tax (Amendment) Act, 2014'}, 
    {value : 'The Maharashtra (Second Supplementary) Appropriation Act',view : 'The Maharashtra (Second Supplementary) Appropriation Act, 2014'}, 
    {value : 'The Maharashtra Entertainments Duty (Amendment and Continuance) Act',view : 'The Maharashtra Entertainments Duty (Amendment and Continuance) Act, 2014'}, 
    {value : 'The Maharashtra Money-Lending (Regulation) Amendment Act',view : 'The Maharashtra Money-Lending (Regulation) Amendment Act, 2014'}, 
    {value : 'The Maharashtra Police (Amendment and Continuance) Act',view : 'The Maharashtra Police (Amendment and Continuance) Act, 2014'}, 
    {value : 'The City of Mumbai Primary Education, the Maharashtra Primary Education, the Hyderabad Compulsory Primary Education and the Madhya Pradesh Primary Education (Repeal) Act',view : 'The City of Mumbai Primary Education, the Maharashtra Primary Education, the Hyderabad Compulsory Primary Education and the Madhya Pradesh Primary Education (Repeal) Act, 2013'}, 
    {value : 'Maharashtra Employment Guarantee (Amendment) Act',view : 'Maharashtra Employment Guarantee (Amendment) Act, 2014'}, 
    {value : 'Maharashtra Employment Guarantee Act',view : 'Maharashtra Employment Guarantee Act, 1977'}, 
    {value : 'The Maharashtra Tax Laws (Levy, Amendment and Validation) Act',view : 'The Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2014'}, 
    {value : 'The Maharashtra Medical Practitioners (Amendment) Act',view : 'The Maharashtra Medical Practitioners (Amendment) Act, 2014'}, 
    {value : 'Dr. Babasaheb Ambedkar Technological University Act',view : 'Dr. Babasaheb Ambedkar Technological University Act, 2014'}, 
    {value : 'Dr. Babasaheb Ambedkar Technological University Act',view : 'Dr. Babasaheb Ambedkar Technological University Act, 1989'}, 
    {value : 'The Maharashtra Land Revenue Code (Amendment) Act',view : 'The Maharashtra Land Revenue Code (Amendment) Act, 2014'}, 
    {value : 'Maharashtra Land Improvement Schemes (Amendment) Act',view : 'Maharashtra Land Improvement Schemes (Amendment) Act, 2013'}, 
    {value : 'Maharashtra Forest Produce (Regulation of Trade) (Amendment) Act',view : 'Maharashtra Forest Produce (Regulation of Trade) (Amendment) Act, 2014'}, 
    {value : 'The Code of Criminal Procedure (Maharashtra Amendment) Act',view : 'The Code of Criminal Procedure (Maharashtra Amendment) Act, 2006'}, 
    {value : 'The Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act',view : 'The Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2012'}, 
    {value : 'The Maharashtra Regional and Town Planning (Amendment) Act',view : 'The Maharashtra Regional and Town Planning (Amendment) Act, 2011'}, 
    {value : 'Maharashtra Universities (Amendment) Act',view : 'Maharashtra Universities (Amendment) Act, 2014'}, 
    {value : 'The Maharashtra Land Revenue Code (Second Amendment) Act',view : 'The Maharashtra Land Revenue Code (Second Amendment) Act, 2014'}, 
    {value : 'Maharashtra Regional and Town Planning (Amendment) Act',view : 'Maharashtra Regional and Town Planning (Amendment) Act, 2014'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2014'}, 
    {value : 'The Maharashtra Co-operative Societies (Amendment) Act',view : 'The Maharashtra Co-operative Societies (Amendment) Act, 2014'}, 
    {value : 'The Maharashtra Appropriation (Excess Expenditure) Act',view : 'The Maharashtra Appropriation (Excess Expenditure) Act, 2014'}, 
    {value : 'The Maharashtra Entertainments Duty (Amendment) Act',view : 'The Maharashtra Entertainments Duty (Amendment) Act, 2014'}, 
    {value : 'The Maharashtra Village Panchayats and the Maharashtra Regional and Town Planning (Amendment) Act',view : 'The Maharashtra Village Panchayats and the Maharashtra Regional and Town Planning (Amendment) Act, 2014'}, 
    {value : 'Maharashtra State Reservation (of seats for admission in educational institutions in the State and for appointments or posts in the public services under the State) for Educationally and Socially Backward Category (ESBC) Act',view : 'Maharashtra State Reservation (of seats for admission in educational institutions in the State and for appointments or posts in the public services under the State) for Educationally and Socially Backward Category (ESBC) Act, 2014'}, 
    {value : 'FLAME University Act',view : 'FLAME University Act, 2014'}, 
    {value : 'Ajeenkya DY Patil University Act',view : 'Ajeenkya DY Patil University Act, 2014'}, 
    {value : 'Maharashtra Land Revenue Code (Second Amendment) Act',view : 'Maharashtra Land Revenue Code (Second Amendment) Act, 2012'}, 
    {value : 'Maharashtra Animal Preservation (Amendment) Act',view : 'Maharashtra Animal Preservation (Amendment) Act, 1995'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2015'}, 
    {value : 'Maharashtra Village Panchayats (Amendment) Act',view : 'Maharashtra Village Panchayats (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Universities (Amendment) Act',view : 'Maharashtra Universities (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2015'}, 
    {value : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act',view : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Police (Amendment) Act',view : 'Maharashtra Police (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Fire Prevention and Life Safety Measures (Amendment) Act',view : 'Maharashtra Fire Prevention and Life Safety Measures (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Second Amendment) Act',view : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Second Amendment) Act, 2015'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2015'}, 
    {value : 'Maharashtra (Urban Areas) Protection and Preservation of Trees (Amendment) Act',view : 'Maharashtra (Urban Areas) Protection and Preservation of Trees (Amendment) Act, 2015'}, 
    {value : 'Nanded Sikh Gurudwara Sachkhand Shri Hazur Apchalnagar Sahib (Amendment) Act',view : 'Nanded Sikh Gurudwara Sachkhand Shri Hazur Apchalnagar Sahib (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act',view : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2015'}, 
    {value : 'Maharashtra Municipal Corporations (Amendment) Act',view : 'Maharashtra Municipal Corporations (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Land Revenue Code (Amendment) Act',view : 'Maharashtra Land Revenue Code (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Stamp (Amendment) Act',view : 'Maharashtra Stamp (Amendment) Act, 2015'}, 
    {value : 'Indian Forest (Maharashtra Amendment) Act',view : 'Indian Forest (Maharashtra Amendment) Act, 2013'}, 
    {value : 'Maharashtra Civil Courts (Amendment) Act',view : 'Maharashtra Civil Courts (Amendment) Act, 2015'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2015'}, 
    {value : 'Maharashtra Co-operative Societies (Amendment) Act',view : 'Maharashtra Co-operative Societies (Amendment) Act, 2015'}, 
    {value : 'Hyderabad Abolition of Inams and Cash Grants (Amendment) Act',view : 'Hyderabad Abolition of Inams and Cash Grants (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Entertainments Duty (Amendment) Act',view : 'Maharashtra Entertainments Duty (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Land Revenue Code (Second Amendment) Act',view : 'Maharashtra Land Revenue Code (Second Amendment) Act, 2015'}, 
    {value : 'Maharashtra Unaided Private Professional Educational Institutions (Regulation of Admission and Fees) Act',view : 'Maharashtra Unaided Private Professional Educational Institutions (Regulation of Admission and Fees) Act, 2015'}, 
    {value : 'Maharashtra Universities (Temporary postponement of elections of members of university authorities and other bodies) Act',view : 'Maharashtra Universities (Temporary postponement of elections of members of university authorities and other bodies) Act, 2015'}, 
    {value : 'Maharashtra Marine Fishing Regulation (Amendment) Act',view : 'Maharashtra Marine Fishing Regulation (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Right to Public Services Act',view : 'Maharashtra Right to Public Services Act, 2015'}, 
    {value : 'Maharashtra Regional and Town Planning (Amendment) Act',view : 'Maharashtra Regional and Town Planning (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Fisheries (Amendment) Act',view : 'Maharashtra Fisheries (Amendment) Act, 2015'}, 
    {value : 'Mumbai Municipal Corporation (Amendment) Act',view : 'Mumbai Municipal Corporation (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Municipal Corporations (Second Amendment) Act',view : 'Maharashtra Municipal Corporations (Second Amendment) Act, 2015'}, 
    {value : 'Maharashtra Official Languages (Amendment) Act',view : 'Maharashtra Official Languages (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Regional and Town Planning (Second Amendment) Act',view : 'Maharashtra Regional and Town Planning (Second Amendment) Act, 2015'}, 
    {value : 'Sandip University Act',view : 'Sandip University Act, 2015'}, 
    {value : 'MIT Art, Design and Technology University Act',view : 'MIT Art, Design and Technology University Act, 2015'}, 
    {value : 'Factories (Maharashtra Amendment) Act',view : 'Factories (Maharashtra Amendment) Act, 2015'}, 
    {value : 'Maharashtra Regional and Town Planning (Third Amemdment) Act',view : 'Maharashtra Regional and Town Planning (Third Amemdment) Act, 2015'}, 
    {value : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Third Amendment) Act',view : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Third Amendment) Act, 2015'}, 
    {value : 'Maharashtra Universities (Second Amendment) Act',view : 'Maharashtra Universities (Second Amendment) Act, 2015'}, 
    {value : 'Maharashtra National Law University (Amendment) Act',view : 'Maharashtra National Law University (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Advocates Welfare Fund (Amendment) Act',view : 'Maharashtra Advocates Welfare Fund (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Tenancy and Agricultural Lands, Hyderabad Tenancy and Agricultural Lands and Maharashtra Tenancy and Agricultural Lands (Vidarbha Region) (Amendment) Act',view : 'Maharashtra Tenancy and Agricultural Lands, Hyderabad Tenancy and Agricultural Lands and Maharashtra Tenancy and Agricultural Lands (Vidarbha Region) (Amendment) Act, 2015'}, 
    {value : 'Maharashtra State Public Services [Reservation for Scheduled Castes, Scheduled Tribes, De-notified Tribes (Vimukta Jatis), Nomadic Tribes, Special Backward Category and Other Backward Classes] (Amendment) Act',view : 'Maharashtra State Public Services [Reservation for Scheduled Castes, Scheduled Tribes, De-notified Tribes (Vimukta Jatis), Nomadic Tribes, Special Backward Category and Other Backward Classes] (Amendment) Act, 2015'}, 
    {value : 'Orphanges & Other Charitable Homes (Supervision & Control) the Persons with Disabilities (Equal Opportunities, Protection of Rights & Full Participation) & the Building & Other Construction Workers (Regulation of Employment & Conditions of Service) (Maharashtra Amendment) Act',view : 'Orphanges & Other Charitable Homes (Supervision & Control) the Persons with Disabilities (Equal Opportunities, Protection of Rights & Full Participation) & the Building & Other Construction Workers (Regulation of Employment & Conditions of Service) (Maharashtra Amendment) Act, 2009'}, 
    {value : 'Maharashtra Prevention of Dangerous Activities of Slumlords, Bootleggers, Drug-offenders, Dangerous persons and video pirates (Amendment) Act',view : 'Maharashtra Prevention of Dangerous Activities of Slumlords, Bootleggers, Drug-offenders, Dangerous persons and video pirates (Amendment) Act, 2015'}, 
    {value : 'Maharashtra Paramedical Council Act',view : 'Maharashtra Paramedical Council Act, 2011'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2016'}, 
    {value : 'Maharashtra Appropriation (Vote on Account) Act',view : 'Maharashtra Appropriation (Vote on Account) Act, 2016'}, 
    {value : 'Maharashtra Public Trusts (Amendment) Act',view : 'Maharashtra Public Trusts (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Village Panchayats (Amendment) Act',view : 'Maharashtra Village Panchayats (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Land Revenue Code (Amendment) Act',view : 'Maharashtra Land Revenue Code (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Prohibition of Obscene Dance in Hotels, Restaurants and Bar Rooms and Protection of Dignity of Women (working therein) Act',view : 'Maharashtra Prohibition of Obscene Dance in Hotels, Restaurants and Bar Rooms and Protection of Dignity of Women (working therein) Act, 2016'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2016'}, 
    {value : 'Maharashtra Water Conservation Corporation (Amendment) Act',view : 'Maharashtra Water Conservation Corporation (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act',view : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2016'}, 
    {value : 'Maharashtra Settlement of Arrears in Disputes Act',view : 'Maharashtra Settlement of Arrears in Disputes Act, 2016'}, 
    {value : 'Maharashtra Land Revenue Code (Second Amendment) Act',view : 'Maharashtra Land Revenue Code (Second Amendment) Act, 2016'}, 
    {value : 'Maharashtra Stamp (Amendment) Act',view : 'Maharashtra Stamp (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Fourth Amendment) Act',view : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Fourth Amendment) Act, 2015'}, 
    {value : 'Maharashtra Tenancy and Agricultural Lands, the Hyderabad Tenancy and Agricultural Lands and the Maharashtra Tenancy and Agricultural Lands (Vidarbha Region) (Second Amendment) Act',view : 'Maharashtra Tenancy and Agricultural Lands, the Hyderabad Tenancy and Agricultural Lands and the Maharashtra Tenancy and Agricultural Lands (Vidarbha Region) (Second Amendment) Act, 2016'}, 
    {value : 'Maharashtra Zilla Parishads and Panchayat Samities (Amendment) Act',view : 'Maharashtra Zilla Parishads and Panchayat Samities (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Universities (Amendment) Act',view : 'Maharashtra Universities (Amendment) Act, 2016'}, 
    {value : 'Mumbai Municipal Corporation and Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act',view : 'Mumbai Municipal Corporation and Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2015'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2016'}, 
    {value : 'Maharashtra Zilla Parishads and Panchayat Samitis (Second Amendment) Act',view : 'Maharashtra Zilla Parishads and Panchayat Samitis (Second Amendment) Act, 2016'}, 
    {value : 'Maharashtra Electricity Duty Act',view : 'Maharashtra Electricity Duty Act, 2016'}, 
    {value : 'Maharashtra Land Revenue Code (Third Amendment) Act',view : 'Maharashtra Land Revenue Code (Third Amendment) Act, 2016'}, 
    {value : 'Maharashtra Highways (Amendment) Act',view : 'Maharashtra Highways (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Land Revenue Code (Fourth Amendment) Act',view : 'Maharashtra Land Revenue Code (Fourth Amendment) Act, 2016'}, 
    {value : 'Maharashtra Motor Vehicles Tax (Amendment) Act',view : 'Maharashtra Motor Vehicles Tax (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Repealing Act',view : 'Maharashtra Repealing Act, 2016'}, 
    {value : 'Maharashtra Legislative Council (Chairman and Deputy Chairman) and the Maharashtra Legislative Assembly (Speaker and Deputy Speaker) Salaries and Allowances, the Maharashtra Ministers Salaries and Allowances, the Maharashtra Legislature Members Salaries and Allowances, the Maharashtra Legislature Members Pension and the Leaders of Opposition in Maharashtra Legislature Salaries and Allowances (Amendment) Act',view : 'Maharashtra Legislative Council (Chairman and Deputy Chairman) and the Maharashtra Legislative Assembly (Speaker and Deputy Speaker) Salaries and Allowances, the Maharashtra Ministers Salaries and Allowances, the Maharashtra Legislature Members Salaries and Allowances, the Maharashtra Legislature Members Pension and the Leaders of Opposition in Maharashtra Legislature Salaries and Allowances (Amendment) Act, 2016'}, 
    {value : 'Code of Criminal Procedure (Maharashtra Amendment) Act',view : 'Code of Criminal Procedure (Maharashtra Amendment) Act, 2015'}, 
    {value : 'Maharashtra Co-operative Societies Act',view : 'Maharashtra Co-operative Societies Act, 2016'}, 
    {value : 'Maharashtra Agricultural Produce Marketing (Development and Regulaton) (Amendment and Continuance) Act',view : 'Maharashtra Agricultural Produce Marketing (Development and Regulaton) (Amendment and Continuance) Act, 2016'}, 
    {value : 'Maharashtra Co-operative Societies (Second Amendment) Act',view : 'Maharashtra Co-operative Societies (Second Amendment) Act, 2016'}, 
    {value : 'Maharashtra Local Authority Members Disqualifications (Amendment) Act',view : 'Maharashtra Local Authority Members Disqualifications (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act',view : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2016'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2016'}, 
    {value : 'Contract Labour (Regulation and Abolition) (Maharashtra Amendment) Act',view : 'Contract Labour (Regulation and Abolition) (Maharashtra Amendment) Act, 2016'}, 
    {value : 'Maharashtra Metropolitan Region Development Authority Act',view : 'Maharashtra Metropolitan Region Development Authority Act, 2016'}, 
    {value : 'Maharashtra Settlement of Arrears in Disputes (Amendment) Act',view : 'Maharashtra Settlement of Arrears in Disputes (Amendment) Act, 2016'}, 
    {value : 'Mumbai Municipal Corporation (Second Amendment) Act',view : 'Mumbai Municipal Corporation (Second Amendment) Act, 2016'}, 
    {value : 'Maharashtra Public Universities Act',view : 'Maharashtra Public Universities Act, 2016'}, 
    {value : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act',view : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Water Resources Regulatory Authority (Amendment) Act',view : 'Maharashtra Water Resources Regulatory Authority (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act',view : 'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Municipal Corporations (Amendment) Act',view : 'Maharashtra Municipal Corporations (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Appropriation (Excess Expenditure) Act',view : 'Maharashtra Appropriation (Excess Expenditure) Act, 2016'}, 
    {value : 'Maharashtra Appropriation (Second Excess Expenditure) Act',view : 'Maharashtra Appropriation (Second Excess Expenditure) Act, 2016'}, 
    {value : 'Maharashtra (Urban Areas) Protection and Preservation of Trees (Amendment) Act',view : 'Maharashtra (Urban Areas) Protection and Preservation of Trees (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Co-operative Societies (Third Amendment) Act',view : 'Maharashtra Co-operative Societies (Third Amendment) Act, 2016'}, 
    {value : 'Maharashtra Repealing (Second) Act',view : 'Maharashtra Repealing (Second) Act, 2016'}, 
    {value : 'Maharashtra Appropriation (Third Excess Expenditure) Act',view : 'Maharashtra Appropriation (Third Excess Expenditure) Act, 2016'}, 
    {value : 'Maharashtra Legislative Council (Chairman and Deputy Chairman) and the Maharashtra Legislative Assembly (Speaker and Deputy Speaker) Salaries and Allowances, the Maharashtra Ministers Salaries and Allowances, the Maharashtra Legislature Members Salaries and Allowances and the Leaders of Opposition in Maharashtra Legislature Salaries and Allowances (Amendment) Act',view : 'Maharashtra Legislative Council (Chairman and Deputy Chairman) and the Maharashtra Legislative Assembly (Speaker and Deputy Speaker) Salaries and Allowances, the Maharashtra Ministers Salaries and Allowances, the Maharashtra Legislature Members Salaries and Allowances and the Leaders of Opposition in Maharashtra Legislature Salaries and Allowances (Amendment) Act, 2016'}, 
    {value : 'Maharashtra Aadhaar (Targeted Delivery of Financial and Other Subsidies, Benefits and Services) Act',view : 'Maharashtra Aadhaar (Targeted Delivery of Financial and Other Subsidies, Benefits and Services) Act, 2016'}, 
    {value : 'Maharashtra Prohibition (Amendment) Act',view : 'Maharashtra Prohibition (Amendment) Act, 2016'}, 
    {value : 'Leaders of Opposition in Maharashtra Legislature Salaries and Allowances Act',view : 'Leaders of Opposition in Maharashtra Legislature Salaries and Allowances Act, 1978'}, 
    {value : 'Maharashtra Mineral Development (Creation and Utilisation) Fund (Repeal) Act',view : 'Maharashtra Mineral Development (Creation and Utilisation) Fund (Repeal) Act, 2016'}, 
    {value : 'Maharashtra Land Revenue Code (Fifth Amendment) Act',view : 'Maharashtra Land Revenue Code (Fifth Amendment) Act, 2016'}, 
    {value : 'Mumbai Municipal Corporation (Amendment) Act',view : 'Mumbai Municipal Corporation (Amendment) Act, 2016'}, 
    {value : 'Maharashtra (Supplementary) Appropriation Act',view : 'Maharashtra (Supplementary) Appropriation Act, 2017'}, 
    {value : 'Maharashtra Appropriation (Vote Account) Act',view : 'Maharashtra Appropriation (Vote Account) Act, 2017'}, 
    {value : 'Maharashtra Acupuncture System of Therapy Act',view : 'Maharashtra Acupuncture System of Therapy Act, 2015'}, 
    {value : 'Maharashtra Appropriation Act',view : 'Maharashtra Appropriation Act, 2017'}, 
    {value : 'Mumbai Municipal Corporation (Amendment) Act',view : 'Mumbai Municipal Corporation (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Village Panchayats and Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act',view : 'Maharashtra Village Panchayats and Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2017'}, 
    {value : 'Maharashtra State Commission for Backward Classes (Amendment) Act',view : 'Maharashtra State Commission for Backward Classes (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Land Revenue Code (Amendment) Act',view : 'Maharashtra Land Revenue Code (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act',view : 'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2017'}, 
    {value : 'Maharashtra Regional and Town Planning (Amendment) Act',view : 'Maharashtra Regional and Town Planning (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Co-operative Societies (Amendment) Act',view : 'Maharashtra Co-operative Societies (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Land Revenue Code (Second Amendment) Act',view : 'Maharashtra Land Revenue Code (Second Amendment) Act, 2017'}, 
    {value : 'Dr. Vishwanath Karad MIT World Peace University Act',view : 'Dr. Vishwanath Karad MIT World Peace University Act, 2016'}, 
    {value : 'Maharashtra Paramedical Council (Amendment) Act',view : 'Maharashtra Paramedical Council (Amendment) Act, 2017'}, 
    {value : 'Symbiosis Skills and Open University Act',view : 'Symbiosis Skills and Open University Act, 2017'}, 
    {value : 'Vishwakarma University Act',view : 'Vishwakarma University Act, 2017'}, 
    {value : 'DSK World University Act',view : 'DSK World University Act, 2017'}, 
    {value : 'Sanjay Ghodawat University, Kolhapur, Act',view : 'Sanjay Ghodawat University, Kolhapur, Act, 2017'}, 
    {value : 'Maharashtra Goods and Services Tax (Compensation to the Local Authorities) Act',view : 'Maharashtra Goods and Services Tax (Compensation to the Local Authorities) Act, 2017'}, 
    {value : 'Maharashtra Goods and Services Tax related laws (Amendments, Validation and Savings) Act',view : 'Maharashtra Goods and Services Tax related laws (Amendments, Validation and Savings) Act, 2017'}, 
    {value : 'Maharashtra Goods and Services Tax Act Notifications -12345',view : 'Maharashtra Goods and Services Tax Act, 2017 Notifications -12345'}, 
    {value : 'Maharashtra Protection of People from Social Boycott (Prevention, Prohibition and Redressal) Act',view : 'Maharashtra Protection of People from Social Boycott (Prevention, Prohibition and Redressal) Act, 2016'}, 
    {value : 'Prevention of Cruelty to Animals (Maharashtra Amendment) Act',view : 'Prevention of Cruelty to Animals (Maharashtra Amendment) Act, 2017'}, 
    {value : 'Maharashtra (Second Supplementary) Appropriation Act',view : 'Maharashtra (Second Supplementary) Appropriation Act, 2017'}, 
    {value : 'Maharashtra Stamp (Amendment) Act',view : 'Maharashtra Stamp (Amendment) Act, 2017'}, 
    {value : 'Shree Sai Baba Sansthan Trust (Shirdi) (Amendment) Act',view : 'Shree Sai Baba Sansthan Trust (Shirdi) (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Prohibition (Amendment) Act',view : 'Maharashtra Prohibition (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Motor Vehicles Tax (Amendment) Act',view : 'Maharashtra Motor Vehicles Tax (Amendment) Act, 2017'}, 
    {value : 'Mumbai Municipal Corporation and the Maharashtra Municipal Corporations (Amendment) Act',view : 'Mumbai Municipal Corporation and the Maharashtra Municipal Corporations (Amendment) Act, 2017'}, 
    {value : 'Mumbai Municipal Corporation, the Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act',view : 'Mumbai Municipal Corporation, the Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Municipal Corporations (Amendment) Act',view : 'Maharashtra Municipal Corporations (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Land Revenue Code and the Maharashtra Land Revenue (Conversion of Use of Land and Nonagricultural Assessment) Rules (Amendment) Act',view : 'Maharashtra Land Revenue Code and the Maharashtra Land Revenue (Conversion of Use of Land and Nonagricultural Assessment) Rules (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Public Trusts (Second Amendment) Act',view : 'Maharashtra Public Trusts (Second Amendment) Act, 2017'}, 
    {value : 'Maharashtra Legislature Members (Removal of Disqualification) (Amendment) Act',view : 'Maharashtra Legislature Members (Removal of Disqualification) (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Co-operative Societies (Second Amendment) Act',view : 'Maharashtra Co-operative Societies (Second Amendment) Act, 2017'}, 
    {value : 'Maharashtra Prevention of Fragmentation and Consolidation of Holdings (Amendment) Act',view : 'Maharashtra Prevention of Fragmentation and Consolidation of Holdings (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Stamp (Second Amendment) Act',view : 'Maharashtra Stamp (Second Amendment) Act, 2017'}, 
    {value : 'Maharashtra Land Revenue Code (Third Amendment) Act',view : 'Maharashtra Land Revenue Code (Third Amendment) Act, 2017'}, 
    {value : 'Maharashtra Shops and Establishments (Regulation of Employment and Conditions of Service) Act',view : 'Maharashtra Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2017'}, 
    {value : 'Maharashtra (Third Supplementary) Appropriation Act',view : 'Maharashtra (Third Supplementary) Appropriation Act, 2017'}, 
    {value : 'D. Y. Patil International University, Pune, Act',view : 'D. Y. Patil International University, Pune, Act, 2017'}, 
    {value : 'Maharashtra Local Authority Members Disqualification (Amendment) Act',view : 'Maharashtra Local Authority Members Disqualification (Amendment) Act, 2017'}, 
    {value : 'Pandharpur Temples (Amendment) Act',view : 'Pandharpur Temples (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Appropriation (Excess Expenditure) Act',view : 'Maharashtra Appropriation (Excess Expenditure) Act, 2017'}, 
    {value : 'Maharashtra Public Trusts (Third Amendment) Act',view : 'Maharashtra Public Trusts (Third Amendment) Act, 2017'}, 
    {value : 'Maharashtra Stamp (Amendment and Validation) Act',view : 'Maharashtra Stamp (Amendment and Validation) Act, 2017'}, 
    {value : 'Maharashtra Land Revenue Code (Fourth Amendment) Act',view : 'Maharashtra Land Revenue Code (Fourth Amendment) Act, 2017'}, 
    {value : 'Maharashtra Municipal Corporations (Second Amendment) Act',view : 'Maharashtra Municipal Corporations (Second Amendment) Act, 2017'}, 
    {value : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act',view : 'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Witness Protection and Security Act',view : 'Maharashtra Witness Protection and Security Act, 2017'}, 
    {value : 'Maharashtra Court-fees (Amendment) Act',view : 'Maharashtra Court-fees (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Village Panchayats (Amendment) and the Maharashtra Village Panchayats (Payment of Lumpsum Contribution by Factories in Lieu of Taxes) Rules (Repeal) Act',view : 'Maharashtra Village Panchayats (Amendment) and the Maharashtra Village Panchayats (Payment of Lumpsum Contribution by Factories in Lieu of Taxes) Rules (Repeal) Act, 2017'}, 
    {value : 'Maharashtra Land Revenue Code (Fifth Amendment) Act',view : 'Maharashtra Land Revenue Code (Fifth Amendment) Act, 2017'}, 
    {value : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act',view : 'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2017'}, 
    {value : 'Maharashtra Co-operative Societies (Third Amendment) Act',view : 'Maharashtra Co-operative Societies (Third Amendment) Act, 2017'}, 
    {value : 'Maharashtra Lifts, Escalators and Moving Walks Act',view : 'Maharashtra Lifts, Escalators and Moving Walks Act, 2017'}, 
    {value : 'Indian Partnership (Maharashtra Amendment) Act',view : 'Indian Partnership (Maharashtra Amendment) Act, 2017'}, 
    {value : 'Apprentices (Maharashtra Amendment) Act',view : 'Apprentices (Maharashtra Amendment) Act, 2017'}, 
    {value : 'Maharashtra Essential Services Maintenance Act',view : 'Maharashtra Essential Services Maintenance Act, 2017'}, 
    {value : 'Maharashtra Agricultural Lands (Ceiling on Holdings) (Amendment) Act',view : 'Maharashtra Agricultural Lands (Ceiling on Holdings) (Amendment) Act, 2017'}
]
export const autoCompletion = [
    "Admission",
    "Abandonment of Service",
    "Abolish/Creating of post",
    "Absenteeism",
    "Adjudication",
    "Admission to Medical Colleges",
    "Adoption",
    "Adoption by Female Hindu",
    "Adoption of Child",
    "AICTE (All India Council for Technical Education)",
    "Alienation of minor’s property",
    "Alienation of Property",
    "Alimony/Permanent Alimony",
    "Appointment",
    "Appointment",
    "Appointment of Guardian",
    "Apprentice",
    "Appropriate Government",
    "Arrears of pay",
    "Award",
    "Back Wages",
    "Backward Class",
    "Bank Employees",
    "Board Examination",
    "Bombay Industrial Relation Act",
    "Bonus/Incentives",
    "Central Administrative Tribunal",
    "Central Civil Services Rules",
    "Civil Service",
    "Closure of Undertaking",
    "Compassionate Appointment",
    "Compulsory Retirement",
    "Confidential Report",
    "Consent Terms",
    "Continuous Service",
    "Contract Labour",
    "Customs",
    "Daily Wager",
    "Date of Birth",
    "Departmental Enquiry",
    "Departmental Examination",
    "Departmental Proceedings",
    "Deputation",
    "Direction to Pay Wages Pending Proceedings",
    "Discharge",
    "Disciplinary Action",
    "Disciplinary Enquiry",
    "Disciplinary Proceedings",
    "Discrimination",
    "Dismissal",
    "Disobedience",
    "Disqualification",
    "Doctrine",
    "Domestic Enquiry",
    "Educational Institution",
    "Employee",
    "Employees of Private Schools",
    "Employees Provident Fund",
    "Employees State Insurance (ESIC)",
    "Employment",
    "Enquiry/Enquiry Committee",
    "Equal Pay for Equal Work",
    "Examination",
    "Family Arrangement",
    "Family Court",
    "Family Court Appeal",
    "Family Pension",
    "Family Settlement",
    "­ Family Settlement Deed",
    "­ Female Hindu",
    "Finding of Fact",
    "Fixation of Fee",
    "Free and Compulsory Education",
    "Government Servant",
    "Gratification",
    "Gratuity",
    "­ Guardians and Wards",
    "­ Guardianship",
    "Guardianship of Children",
    "Guardianship of Minor",
    "Heirship Certificate",
    "Hindu Adoption",
    "Hindu Adoption and Maintenance",
    "Hindu Law",
    "Hindu Marriage",
    "Hindu Minority and Guardianship",
    "Hindu Succession",
    "Hindu Widow",
    "Hindu Widow’s Remarriage",
    "Hindu Women",
    "Hindu Women’s Right to Property",
    "House Rent Allowance (HRA)",
    "HUF and Firm",
    "HUF Property",
    "Industrial Court/Industrial Tribunal",
    "Industrial Disputes",
    "Industry",
    "Joint Family Business",
    "Joint Family Property",
    "Joint Hindu Family",
    "Judicial Service",
    "Jurisdiction of Industrial/Labour Court",
    "Labour Court",
    "Limitation",
    "Lockout/Strike",
    "Maintenance",
    "Maintenance Pendente Lite",
    "Manufacturing",
    "Marriage and Divorce",
    "Marriage Registration Certificate",
    "Matrimonial Cruelty",
    "Medical Colleges",
    "Medical Education",
    "Mental Cruelty",
    "Merger",
    "Minimum Wages",
    "Minor",
    "Minority Institution",
    "Minority Right",
    "Minority School",
    "Misbehaviour",
    "Misconduct",
    "Model Standing Orders",
    "Mohammedan Law",
    "Muslim Law",
    "Muslim Women",
    "National Council for Teacher Education",
    "Natural Justice",
    "New School/College",
    "Offence against Marriage",
    "Parity/Disparity",
    "Pay Fixation/Revision",
    "Pay Scale",
    "Payment of Gratuity",
    "Payment of Wages",
    "Pension",
    "Pensionary Benefit",
    "Permanancy",
    "Probationer",
    "Promotion",
    "Property of Female Hindu",
    "Property of Minor",
    "Provident Fund",
    "Public Employment",
    "Public Service Commission",
    "Punishment",
    "Qualification",
    "Quantum of Punishment",
    "Railway Employees",
    "Recognition",
    "Recruitment",
    "Reference",
    "Regularisation of Service",
    "Reinstatement",
    "Reinstatement and Back Wages",
    "Removal from Service",
    "Reservation",
    "Reservation",
    "Resignation",
    "Restitution of Conjugal Rights",
    "Retirement/ Retirement Age",
    "Retrenchment",
    "Reversion to Lower Post",
    "School Employment",
    "Seasonal Industry/Workers",
    "Secondary School Code",
    "Select List",
    "Selection/Selection Process",
    "Seniority",
    "Service / Conditions of Service",
    "Service Law / Service Conditions",
    "Service Matters",
    "Service of Notice",
    "Service of Summons",
    "Service Records",
    "Service Rules/Regulations",
    "Settlement",
    "Sexual Harassment",
    "Stridhana",
    "Subsistence Allowance",
    "Suspension",
    "Talaq",
    "Temporary Employee",
    "Temporary Worker",
    "Termination of Service",
    "Trade Union",
    "Transfer in Service",
    "Transfer of Employee",
    "Transfer of Proceedings",
    "Transfer Order",
    "Unfair Labour Practices",
    "Universities",
    "Verification Form",
    "Victimisation",
    "Voluntary Retirement",
    "Wages",
    "Widow’s Estate",
    "Words & Phrases",
    "Workman",
    "Workmen’s Compensation",
    "Zilla Parishad",
    "Abandonment of Part of Claim",
    "Abatement",
    "Abatement of Appeal",
    "Abatement of Second Appeal",
    "Abatement of Suit",
    "Abduction",
    "Abduction for Ransom",
    "Abetment",
    "Abetment of Murder",
    "Abetment of Offence",
    "Abetment of Suicide",
    "Abkari Rules",
    "Abolition of Inams",
    "Absconding Accused",
    "Abuse of Process of Court",
    "Accident Claim",
    "Accomplice Evidence",
    "Accused",
    "Acquisition Proceedings",
    "Acquittal and Discharge",
    "Acquittal of Accused",
    "Acting in Law",
    "Addition of Accused",
    "Addition of Party/Parties",
    "Additional Documents",
    "Additional Evidence",
    "Adjournment",
    "Adjudicating",
    "Administration of Criminal Justice",
    "Administration of Justice",
    "Administration Suit",
    "Administrative Act",
    "Administrative Actions",
    "Administrative Decision",
    "Administrative Law",
    "Administrative Order",
    "Administrative Tribunal",
    "Administrator",
    "Admiralty Jurisdiction",
    "Admiralty Proceedings",
    "Admiralty Suit",
    "Admissible Evidence",
    "Admission",
    "Admission by Plaintiff",
    "Admission in Pleading",
    "Admission of Documents",
    "Admission of Evidence",
    "Admission of Facts",
    "Admission of Proof",
    "Adultery",
    "Adverse Inference",
    "Adverse Possession",
    "Adverse Remarks",
    "Advocate General",
    "Advocate’s duty",
    "Advocates/Advocates Act",
    "Advocates-on-Record",
    "Affidavit",
    "Agent",
    "Agreement",
    "Agreement of Sale",
    "Agricultural Land",
    "Agricultural Lands Ceiling",
    "Agricultural Produce",
    "Agricultural Produce Market",
    "Agricultural Tenancy",
    "Agricultural Universities",
    "Agriculturist",
    "Air Corporation",
    "Air Pollution",
    "Airlines/Air Carrier",
    "Airports",
    "Alibi",
    "Allotment of Dealership",
    "Allotment of Land",
    "Allotment of Plots",
    "Allotment of Quarters",
    "Allotment of Ration Shop",
    "Alteration of Boundaries",
    "Alteration of Charge",
    "Alteration of Conviction",
    "Alternative Remedy",
    "Amalgamation of Banks",
    "Amalgamation of Companies",
    "Amarnath Yatra",
    "Amendment Application",
    "Amendment in Complaint",
    "Amendment of Affidavit",
    "Amendment of Constitution",
    "Amendment of Decree",
    "Amendment of Issue",
    "Amendment of Petition",
    "Amendment of Plaint",
    "Amendment of Pleadings",
    "Amendment of Written Statement",
    "Amendment to Title Clause",
    "Ancient Monuments",
    "Anganwadi Sevikas",
    "Animal Birth Control",
    "Animal Preservation",
    "Annexation of Map",
    "Annual Confidential Report",
    "Annual Letting Value",
    "Ante-nuptial Agreement between Couple",
    "Apartment Ownership Act",
    "Apex Court",
    "Appeal",
    "Appeal Against Acquittal",
    "Appeal Against Conviction",
    "Appeal by Special Leave",
    "Appeal by Victims of Crime",
    "Appeal to Supreme Court",
    "Appearance Before Court",
    "Appellate Authority",
    "Appellate Court",
    "Appellate Jurisdiction of Supreme Court",
    "Appellate Side Rules",
    "Applicability",
    "Application for Discharge",
    "Appointment as Chief Minister",
    "Appointment of Arbitrator",
    "Appointment of Commissioner",
    "Appointment of Court Receiver",
    "Appointment of Government Pleader",
    "Appointment of Special Police Officer",
    "Appreciation of Evidence",
    "Approbate and Reprobate",
    "Appropriate Government",
    "Approver",
    "Approver’s Evidence",
    "Arbitration",
    "Arbitration Agreement",
    "Architects",
    "Arms Act",
    "Army",
    "Arrest",
    "Arrest and Custody",
    "Arrest and Detention",
    "Arrest in Appeal",
    "Arrest of Accused",
    "Arrest of Judgment-debtor",
    "Arrest Pending Appeal",
    "Arrest u/s.151 Cr.P.C.",
    "Arrest Warrant",
    "Arrested Person",
    "Ashram School",
    "Assault",
    "Assault and Wrongful Confinement",
    "Assault on Police",
    "Assault on Public Servant",
    "Assessment List",
    "Atiyat Grants",
    "Atomic Energy",
    "Attachment",
    "Attachment before Judgment",
    "Attachment of Property",
    "Attachment without Notice",
    "Attempt to Commit an Offence",
    "Attempt to Commit Culpable Homicide",
    "Attempt to Commit Robbery",
    "Attempt to Commit Robbery and Assault on Public Servant",
    "Attempt to Commit Suicide",
    "Attempt to Murder",
    "Attempt to Murder and Grievous Hurt",
    "Attempt to Murder and House Trespass",
    "Attempt to Rape",
    "Attempt to Theft",
    "Attesting Witness",
    "Auction of Attached Property",
    "Auction Proceedings",
    "Auction Sale",
    "Authentication of Orders",
    "Authority",
    "Auto Rickshaws",
    "Award of Interest",
    "Bail",
    "Anticipatory Bail",
    "Bailment",
    "Ballistic Experts",
    "Ban on Book",
    "Ban on Dance Bars",
    "Ban on Gutka",
    "Ban on Slaughter of Cows",
    "Bandhs",
    "Bank Guarantee",
    "Banker",
    "Bankers Book",
    "Banking Business",
    "Banking Companies",
    "Banking Laws",
    "Banking Regulation",
    "Banking Transactions",
    "Banks",
    "Bar Council",
    "Bar Council Elections",
    "Bar Council of India Rules",
    "Bar on Institution of Suit",
    "Beautification of Garden",
    "Benami",
    "Benefit of Doubt",
    "Bias",
    "Bigamy",
    "Bill of Exchange",
    "Birth-certificate",
    "Blacklisting",
    "Bomb Blast Cases",
    "Bombay Police Act",
    "Bombay Prohibition Act",
    "Bombay Stamp Act",
    "Bond for Appearance",
    "Bonds and Sureties",
    "Books of Accounts",
    "Border Security Force",
    "Breach of Peace",
    "Bribe Case",
    "Bride Burning",
    "Building",
    "Building and Other Construction Workers",
    "Burden of Proof",
    "Burial Exhumation",
    "Bus Conductor",
    "Bye-election",
    "Cable Television Networks",
    "Cantonments Act",
    "Car Parking",
    "Carriage of Goods",
    "Carriage Permit",
    "Carriers",
    "Case Diary",
    "Caste",
    "Caste Certificate/Caste Claim",
    "Caste Scrutiny",
    "Caste Scrutiny Committee",
    "Categorisation",
    "Cause of Action",
    "Causing Abortion",
    "Causing Death by Negligence",
    "Causing Disappearance of Evidence",
    "Causing Hurt",
    "Causing Miscarriage",
    "Caveat/Caveator",
    "CBI Enquiry",
    "CBI Functions",
    "CBI Investigation",
    "Ceiling on Holdings",
    "Central Excise",
    "Central Reserve Police",
    "Central Vigilance Commissioner",
    "Certified Copy",
    "Certiorari",
    "Chairman of Public Service Commission",
    "Chamber Summons",
    "Change of User of Plot",
    "Charge/Chargesheet",
    "Charity Commissioner",
    "Charter Party Agreement",
    "Cheating",
    "Cheating and Conspiracy",
    "Cheating and Criminal Conspiracy",
    "Cheating and Forgery",
    "Cheating by Personation",
    "Cheque",
    "Chief Metropolitan Magistrate",
    "Child",
    "Child Abuse",
    "Child Custody",
    "Child Labour",
    "Children",
    "Cigarettes and Other Tobacco Products",
    "Cinema Certification",
    "Circular",
    "Circumstantial Evidence",
    "Citizenship",
    "Civil Court",
    "Civil Servant",
    "Civil Services",
    "Civil Suit",
    "Claim against Central Government",
    "Claim for Damages",
    "Claim of Reliefs in Suit",
    "Class Action",
    "Classification",
    "Clearing Public Lane",
    "Club Membership",
    "Clubbing",
    "Coal Mine",
    "Coal Supply Agreement",
    "Coastal Zone",
    "Code of Communidades",
    "Cognizable Cases",
    "Cognizable Offence",
    "Cognizance",
    "Cognizance by Special Court",
    "Cognizance of Complaint",
    "Cognizance of Offence",
    "Collector",
    "Colour of Duty",
    "Commencement of Proceeding",
    "Commercial Establishment",
    "Commission of Enquiry",
    "Commission to Examine Accounts",
    "Commissioner",
    "Committal Proceedings",
    "Common Carriers",
    "Common Intention",
    "Common Object",
    "Communal Disturbances",
    "Communal Riots",
    "Commutation of Death Sentence",
    "Companies",
    "Company Law Cases",
    "Compensation",
    "Compensatory Costs",
    "Compensatory Justice",
    "Competition",
    "Competition Commission",
    "Competitive Exams",
    "Complaint",
    "Composition",
    "Compounding of Offence",
    "Compounding or Composition",
    "Compromise",
    "Compromise Decree",
    "Comptroller and Auditor General",
    "Concept of Justice",
    "Concurrent Finding of Fact",
    "Concurrent Judgments by Courts Below",
    "Concurrent Jurisdiction",
    "Concurrent Sentence",
    "Conditional Sale or Mortgage",
    "Condonation of Delay",
    "Confession",
    "Confessional Report",
    "Confessional Statement",
    "Confirmation of Death Penalty",
    "Confiscation of Goods",
    "Confiscation of Vehicle",
    "Conjoint Twins",
    "Consent Decree",
    "Consent for Prosecution",
    "Consent Order",
    "Conservation of Foreign Exchange",
    "Consolidation of Holding",
    "Consolidation of Suits",
    "Consolidation Scheme",
    "Conspiracy",
    "Constitution of India",
    "Constitutional Court",
    "Constitutional Functionaries",
    "Constitutional Legislation",
    "Constitutional Remedies",
    "Constitutional Validity",
    "Constitutued Attorney",
    "Construction and Development",
    "Construction Licence",
    "Construction on Public Streets",
    "Construction Workers",
    "Consumer Forum",
    "Consumer Protection",
    "Contempt of Courts",
    "Contempt of Industrial or Labour Courts",
    "Contempt of Public Servant",
    "Contempt Petition",
    "Contempt Proceedings",
    "Contract",
    "Contract for Property Sale",
    "Contract of Continuing Guarantee",
    "Contract of Guarantee",
    "Contract of Insurance",
    "Contract of Marriage",
    "Contract of Service",
    "Contract of Surgery",
    "Contractual Matters of State",
    "Conversion of Land Use",
    "Conversion of Proceeding",
    "Conversion of S.482 Cr.P.C.",
    "Converting Finding of Aacquittal into Conviction",
    "Conviction",
    "Conviction and Sentence",
    "Co-operative Societies",
    "Co-owners",
    "Co-parcenary Property",
    "Copyright",
    "Copyright and Trade-mark",
    "Correction in Name",
    "Correction of Decree",
    "Correction of Error/Mistake",
    "Correction of Revenue Record",
    "Corrupt Practice",
    "Corruption",
    "Cost",
    "Cost Accountants’ Chapters",
    "Cotton Seeds",
    "Councillor",
    "Counter Cases",
    "Counter Claim",
    "Counterfeit Currency",
    "Counterfeiting",
    "Counterfeiting and Cheating",
    "Counterfeiting Stamps",
    "Court",
    "Court Commissioner",
    "Court Directions",
    "Court Fees",
    "Court Martial",
    "Court Proceedings",
    "Court Receiver",
    "Code of Civil Procedure",
    "Code of Criminal Procedure",
    "Crime against Society",
    "Criminal Breach of Trust",
    "Criminal Breach of Trust and Cheating",
    "Criminal Breach of Trust and Cruelty",
    "Criminal Conspiracy",
    "Criminal Contempt",
    "Criminal Intimidation",
    "Criminal Intimidation and Outraging Modesty of Woman",
    "Criminal Jurisprudence",
    "Criminal Justice",
    "Criminal Misconduct",
    "Criminal Negligence",
    "Criminal Proceedings",
    "Criminal Trespass",
    "Criminal Trespass and Voluntarily Causing Hurt",
    "Criminal Trial",
    "Cross Cases",
    "Cross Order",
    "Cross Suits",
    "Cross-examination",
    "Cross-examine One’s Own Witness",
    "Cross-objection",
    "Cruelty",
    "Cruelty and Abetment of Suicide",
    "Cruelty and Bigamy",
    "Cruelty and Dowry",
    "Cruelty and Dowry Death",
    "Cruelty and Harassment",
    "Cruelty and Murder",
    "Cruelty by Juvenile",
    "Cruelty to Animals",
    "Cruelty to Wife",
    "Cryptic Order",
    "Culpable Homicide",
    "Custodial Crime/Custodial Death",
    "Custody of Minor Child",
    "Custody of Seized Articles",
    "Custody of Stolen Articles",
    "Customs Act",
    "Customs and Excise",
    "Customs Duty",
    "Customs House Agents",
    "Customs House Agents Licensing",
    "Customs Tariff",
    "Customs Valuation",
    "Dacoity",
    "Dacoity and Robbery",
    "Dacoity with Murder",
    "Dacoity, Murder and Gang Rape",
    "Dacoity, Murder and Robbery",
    "Damages",
    "Dangerous and Dilapidated Buildings",
    "Date of Birth",
    "Deaf and Dumb Witness",
    "Dealership of Petroleum Products",
    "Death by Burning",
    "Death Due to Poisoning",
    "Death of Child by Drowning",
    "Death of Defendant",
    "Death of Party",
    "Death of Plaintiff",
    "Death of Respondent in Appeal",
    "Death Penalty/Sentence",
    "Debt Recovery",
    "Deception as to Marriage",
    "Decree",
    "Decree for Partition",
    "Decree for Possession",
    "Decree for Specific Performance",
    "Decree on Admission",
    "Deed",
    "Defamation",
    "Defaulting Bidder",
    "Defection",
    "Defective Investigation",
    "Defence of India Rules",
    "Defence Services",
    "Defence Works",
    "Delay and Latches",
    "Delegated Legislation",
    "Delegation of Financial Powers",
    "Delegation of Judicial Function",
    "Delegation of Legislative Powers",
    "Delegation of Power",
    "Delimitation",
    "Delimitation of Constituencies",
    "Demolition",
    "Demolition Notice",
    "Demolition of House",
    "Demolition of Illegal Structure",
    "Demolition of Premises",
    "Demolition Order",
    "Dentists Act",
    "Deportation",
    "Deposit Insurance",
    "Deposit of Costs",
    "Deposition",
    "Depositions by Witnesses",
    "De-requisition of Land",
    "De-reservation of Land",
    "Deserter",
    "Desertion and Cruelty",
    "Design Infringement",
    "Design Registration",
    "Designs",
    "Detention/Preventive Detention/Detention Order",
    "Devasthan Regulations",
    "Development",
    "Development Agreement",
    "Development Control Regulations",
    "Development Control Rules",
    "Development of Plot",
    "Development Plan",
    "Development Regulations",
    "Development Rights",
    "Development Scheme",
    "Differential Treatment",
    "Diplomatic Immunity",
    "Disability Pension",
    "Disabled Persons",
    "Disaster Management",
    "Discharge of Accused",
    "Discharge of Advocate",
    "Discharge Order",
    "Disclosure Evidence",
    "Disclosure Statement",
    "Discovery",
    "Discovery and Inspection",
    "Discovery Evidence",
    "Discovery of Fact",
    "Discretion",
    "Discretionary Jurisdiction",
    "Discrimination",
    "Dishonest Concealment",
    "Dishonour of Cheque",
    "Dismissal",
    "Dismissal for Default",
    "Dismissal of Complaint",
    "Dismissal of Petition",
    "Dismissal of Suit",
    "Disobedience of Orders",
    "Disparaging Remarks",
    "Displaced Persons",
    "Disposal of Property",
    "Disposal of Property Pending Trial",
    "Disposal of Seized Vehicles",
    "Disposal of Suit",
    "Disqualification",
    "Disqualification of Councillor",
    "Disqualification of Member",
    "Disqualification of Panchayat Member",
    "Disqualification of Sarpanch",
    "Disqualification of Zilla Parishad Members",
    "Disqualification on Ground of Defection",
    "Disqualification on Ground of More than Two Children",
    "Disqualification Process",
    "Dissolution of Gram Panchayat",
    "Dissolution of Partnership",
    "Distinction between Ss.396 & 302",
    "District Judge",
    "District Planning Committees",
    "DNA Test",
    "Doctrines",
    "Document",
    "Document Given in Evidence",
    "Document whether settlement or Will",
    "Documentary Evidence",
    "Domestic Violence",
    "Double Jeopardy",
    "Double Punishment",
    "Dowry",
    "Dowry Death",
    "Drinking Water Supply",
    "Dropping of Proceedings",
    "Dropping or Diluting a Charge",
    "Drug Control",
    "Drugs and Cosmetics",
    "Drugs and Magic Remedies",
    "Drunken Driving",
    "Due Process of Law",
    "Dues from Railways",
    "Duplicate Receipts",
    "Duty Free Shop",
    "Dying Declaration",
    "Earnest Money",
    "Easement of Grant",
    "Easements",
    "Eating House Licence",
    "Eating Houses",
    "E-auction",
    "Education",
    "Election",
    "Election Code",
    "Election Commission",
    "Election Disqualification",
    "Election of Councillor",
    "Election of Gram Panchayat",
    "Election of Municipal Councillor",
    "Election Petition",
    "Election Process",
    "Election to Municipalities",
    "Election to Panchayats",
    "Election to State Bar Council",
    "Election Tribunal",
    "Electoral College",
    "Electoral Rights",
    "Electoral Roll",
    "Electric Lines",
    "Electric Meter",
    "Electricity",
    "Electricity Act",
    "Electricity Dues",
    "Electricity Duty",
    "Electricity Rate",
    "Emblems and Names",
    "Encounter Killings",
    "Encroachment",
    "Encroachment on Public Street",
    "Enemy Property",
    "Enforcement of Orders",
    "English Translation of GRs.",
    "Enlargement of Time",
    "Entering Upon Defence",
    "Entertainment Duty",
    "Entertainment License",
    "Entertainment Tax",
    "Entry Tax",
    "Environment",
    "Environment Clearance",
    "Environment Protection",
    "Episcopacy",
    "Equality",
    "Equality before Law",
    "Equality of Opportunity",
    "Equitable Relief",
    "Equity",
    "Escrow Agent",
    "Escrow Arrangement",
    "Essential Commodities",
    "Establishment",
    "Estoppel",
    "Estoppel against Appeal",
    "Estoppel against Law",
    "Estoppel and Waiver",
    "Euthanasia",
    "Evacuee Property",
    "Evidence",
    "Evidence Act",
    "Evidence and Proof",
    "Evidence by Defendant",
    "Evidence in Rebuttal of Prosecution Case",
    "Evidence of Accomplice",
    "Evidence of Child Witness",
    "Evidence of Complainant",
    "Evidence of Dog Handler",
    "Evidence of Original Documents",
    "Evidence of Recovery",
    "Evidence of Related Witness",
    "Evidence of Relationship",
    "Evidence of Sniffer Dog",
    "Evidence of Witnesses",
    "Examination of Accused",
    "Examination of Complainant",
    "Examination of Party",
    "Examination of Witness by Police",
    "Examination of Witnesses",
    "Examination-in-chief",
    "Examination-in-chief of witnesses",
    "Exchange of Property",
    "Excise",
    "Excise Duty",
    "Exclusion of Jurisdiction",
    "Exclusive Jurisdiction Clause",
    "Executing Court",
    "Execution",
    "Execution Application",
    "Execution of Decree",
    "Execution of Wills",
    "Execution Proceedings",
    "Execution Sale",
    "Executive Instructions",
    "Executive Order",
    "Executive Power",
    "Exemption",
    "Exemption from Personal Appearance",
    "Exercise of Option",
    "Exercise of Power",
    "Exhibiting Carbon Copy",
    "Exhibiting of Document",
    "Exhibition of Film",
    "Exhibits",
    "Ex-parte award by Labour Court",
    "Ex-parte Decree",
    "Ex-parte Decree in Appeal",
    "Ex-parte Hearing",
    "Ex-parte Injunction",
    "Ex-parte Order",
    "Ex-parte Proceedings",
    "Expenditure Tax",
    "Expert Evidence",
    "Expert Opinion",
    "Explosive Substances",
    "Expression",
    "Expunction of Remarks",
    "Extension of Time",
    "Externment/Externment Order",
    "Extortion",
    "Extra Judicial Confession",
    "Extract of Minutes Book",
    "Extradition",
    "Eye-witness",
    "Fact Discovered",
    "Facts Especially Within Knowledge",
    "Fair Price Shop/Fair Price Shop Licence",
    "Fair Trial",
    "False Information of Offence",
    "Family Laws",
    "Fast Track Court Scheme",
    "Fee",
    "Fee Structure",
    "Felling of Trees",
    "Final Decree Proceeding",
    "Financial Powers of Governor",
    "Findings of Fact",
    "Fire Arms",
    "Fire Insurance",
    "Fire Policy",
    "Fire Prevention and Life Safety Measures",
    "Firearm Licence",
    "First Information Report (FIR)",
    "Fiscal Matters",
    "Flag Code",
    "Floodgate Argument",
    "Food Adulteration",
    "Food and Drugs Licence",
    "Food Safety and Standards",
    "Foreclosure",
    "Foreign Award",
    "Foreign Decree",
    "Foreign Exchange",
    "Foreign Exchange Management (FEMA)",
    "Foreign Exchange Regulation (FERA)",
    "Foreign Judgment",
    "Foreign Law Firms",
    "Foreign Liquor",
    "Foreign State",
    "Foreign Trade",
    "Foreign Travel Tax",
    "Foreigner",
    "Foreigners Act",
    "Forensic Evidence",
    "Forest",
    "Forest Conservation",
    "Forest Land",
    "Forest Offence",
    "Forest Produce",
    "Forest Rules",
    "Forest Service",
    "Forfeiture of Book",
    "Forfeiture of Gratuity and Provident Fund",
    "Forfeiture of Money",
    "Forfeiture of Property",
    "Forfeiture of Property of Smugglers",
    "Forged Cheque",
    "Forged Document",
    "Forgery",
    "Fragmentation and Consolidation of Holdings",
    "Framing of Charge",
    "Framing of Issues",
    "Franchising Agreement",
    "Fraud",
    "Fraud on Court",
    "Fraud, Forgery, Cheating and Breach of Trust",
    "Fraud, Forgery, Falsification of Account",
    "Free and Fair Trial",
    "Freedom Fighter",
    "Freedom Fighters Pension Scheme",
    "Freedom of Expression",
    "Freedom of Press",
    "Freedom of Religion",
    "Freedom of Speech and Expression",
    "Freedom of Trade",
    "Freezing of Accounts",
    "Fresh Investigation",
    "FSI (Floor Space Index)",
    "Fugitive Criminal",
    "Full Bench",
    "Fundamental Rights",
    "Fundamental Rights and Directive Principles",
    "Furlough and Parole",
    "Gambling",
    "Gang Rape",
    "Gang Rape and Murder",
    "Gang Rape, Trespass and Criminal Intimidation",
    "Gangsters and Anti-social Activities",
    "Garnishee Order",
    "Gender Equality",
    "General Clauses Act",
    "General Court Martial",
    "Gift",
    "Gift Deed",
    "Gift of Immovable Property",
    "Gift or Will",
    "Girgaum Beach",
    "Global Tender",
    "Goa Abolition of Mining",
    "Goa Children’s Act",
    "Goa Civil Code",
    "Goa Co-operative Societies",
    "Goa Devasthans",
    "Goa Land Use",
    "Goa Legislative Assembly",
    "Goa Panchayat",
    "Goa Public Premises",
    "Goa State Business Rules",
    "Goa, Daman and Diu Agricultural Tenancy",
    "Goa, Daman and Diu Ports Rules",
    "Goa, Daman and Diu Reorganisation",
    "Government",
    "Government Accommodation",
    "Government Bid",
    "Government Business",
    "Government Contract",
    "Government Counsel",
    "Government Grants",
    "Government Land",
    "Government Lease",
    "Government Order",
    "Government Premises",
    "Government Resolution",
    "Government Servant",
    "Government Transfer",
    "Government Works Contract",
    "Gram Panchayat",
    "Gram Sabha",
    "Grant of Land",
    "Grant of Letters",
    "Grant of Pardon",
    "Grant of relief under Arts.226, 227",
    "Grant-in-aid",
    "Gratuity",
    "Grievous Hurt",
    "Grievous Hurt and Culpable Homicide",
    "Grievous Hurt and House Trespass",
    "Guardians and Wards",
    "Gunthewari Development",
    "Habeas Corpus",
    "Habitual Offender",
    "Haj Pilgrimage",
    "Haj Policy",
    "Handwriting Expert",
    "Hawkers",
    "Hazardous Substances",
    "Hazardous Wastes",
    "Hearsay Evidence",
    "Hierarchy of Courts",
    "High Court",
    "High Court Appellate Side Rules",
    "High Court Original Side Rules",
    "Highways",
    "Hire Purchase Agreement",
    "Historical Monument",
    "Hoardings",
    "Home Guards",
    "Homeopathic Practitioners",
    "Homicidal Death",
    "Homicidal Death in Matrimonial Home",
    "Honour Killing",
    "Hostile Witness",
    "House Trespass",
    "House-breaking",
    "Houses and Rents",
    "Housing Board",
    "Housing Project",
    "Housing Society",
    "Human Rights",
    "Humanitarian Grounds",
    "Hurt",
    "Hurt and Grievous Hurt",
    "Hurt and Robbery",
    "Identification",
    "Identification by Sniffer Dog",
    "Identification by Voice",
    "Identification of Accused",
    "Identification of Muddemal Articles",
    "Identification of Prisoners",
    "Identification of Property",
    "Identification of Signature",
    "Identification of Witnesses",
    "Illegal Arrest",
    "Illegal Arrest and Detention",
    "Illegal Detention",
    "Illegal Gratification",
    "Illegal or Void Order",
    "Illegitimate Daughters",
    "Immoral Traffic",
    "Impleadment",
    "Implied Renunciation",
    "Import of Contraband",
    "Imports and Exports",
    "Impounding of Instrument",
    "Inam Abolition",
    "Inam Abolition",
    "Incest",
    "Income Tax",
    "Income Tax Authority",
    "Incriminating Circumstances",
    "Indian Police Service",
    "Indigent Person",
    "Industrial Areas",
    "Industrial Court",
    "Industrial Development",
    "Industrial Investment",
    "Industrial Township",
    "Industrial Use of Water",
    "Industry",
    "Inferior Village Watans Abolition",
    "Information Technology",
    "Inherent Jurisdiction",
    "Inherent Powers",
    "Injunction and Injunction Order",
    "Inquest Report",
    "Inquiry by Magistrate into Cause of Death",
    "Insecticides",
    "Insolvency",
    "Insolvency Notice",
    "Insolvency Petition",
    "Insolvency Proceedings",
    "Instigation",
    "Insurance",
    "Insurance Act",
    "Insurance Claim",
    "Insurance Company",
    "Insurance Contract",
    "Insurance Policy",
    "Insurance Surveyors",
    "Insurer",
    "Insurgency",
    "Inter State Water Disputes",
    "Interest",
    "Interest on Compensation",
    "Interest on Delayed Payments",
    "Interested Witnesses",
    "Interim Application",
    "Interim Arrangement",
    "Interim Maintenance",
    "Interim Order",
    "Interim Order to Furnish Security",
    "Interim Relief",
    "Interim relief and Issue of Jurisdiction",
    "Interim Relief of Injunction",
    "Interim Relief Orders",
    "Interlocutory Order",
    "Interlocutory Proceedings",
    "International Convention",
    "International Law",
    "International Treaty",
    "INTERPOL",
    "Interpretation of Provisions",
    "Interpretation of Statutes",
    "Interruption or Desertion",
    "Intoxicating Drugs",
    "Inventory Proceedings",
    "Investigation",
    "Investigation by CBI",
    "Investigation by Police",
    "Investigation by State CID",
    "Investigation of Complaint",
    "Investigation of Offence",
    "Invocation of Power",
    "Invocation of S.319",
    "IPC S.394",
    "IPC Ss.302 and 304",
    "IPC Ss.34 and 149",
    "Irregular Proceedings",
    "Issuance of Process",
    "Issuance of Search Warrant",
    "Issue of Fact",
    "Issue of Whip",
    "IT Unit",
    "Joint Transfer for Consideration",
    "Joint Trial of Suits",
    "Joint Venture",
    "Judge",
    "Judges Protection",
    "Judgment",
    "Judgment and Order",
    "Judgment of Criminal Court",
    "Judgment of Foreign Court",
    "Judgment on Admissions",
    "Judi Inam",
    "Judicial Activism",
    "Judicial Confession",
    "Judicial Decision",
    "Judicial Legislation",
    "Judicial Officer",
    "Judicial Remedies",
    "Judicial Review",
    "Judicial Tribunal",
    "Judiciary",
    "Jurisdiction",
    "Jurisdiction and Power of Third Judge",
    "Jurisdiction of Arbitral Tribunal",
    "Jurisdiction of Charity Commissioner",
    "Jurisdiction of Civil Court",
    "Jurisdiction of Company Court",
    "Jurisdiction of Court",
    "Jurisdiction of Criminal Courts",
    "Jurisdiction of Full Bench",
    "Jurisdiction of High Court",
    "Jurisdiction of Industrial Court",
    "Jurisdiction of Industrial Tribunal",
    "Jurisdiction of Judicial Review",
    "Jurisdiction of Labour Court",
    "Jurisdiction of Lok Adalat",
    "Jurisdiction of SEBI",
    "Jurisdiction of Single Judge",
    "Jurisdiction of Special Court",
    "Jurisdiction of Supreme Court",
    "Jurisdiction under Art.142",
    "Jurisprudence",
    "Juvenile",
    "Juvenile Justice",
    "Kazis Act",
    "Kerosene Dealers License",
    "Khadi and Village Industries Board",
    "Khalwadi Land",
    "Kidnapping",
    "Kidnapping and Abduction",
    "Kidnapping and Gang Rape",
    "Kidnapping and Murder",
    "Kidnapping and Rape",
    "Kidnapping and Robbery",
    "Kidnapping and Wrongful Confinement",
    "Kidnapping for Ransom",
    "Kidnapping, Murder and Causing Disappearance of Evidence",
    "Kidnapping, Rape and Murder",
    "Kidnapping, Rape and Offence of Atrocity",
    "Kidnapping, Theft and Murder",
    "Kidnapping, Unnatural Offence and Murder",
    "Kisan Vikas Patra",
    "Labour and Service Cases",
    "Land Acquisition",
    "Land Acquisition by Railways",
    "Land Development",
    "Land for Land",
    "Land Records",
    "Land Revenue Code",
    "Landing Place",
    "Language in Court",
    "Law declared by Supreme Court",
    "Law of Torts",
    "Leader of Opposition",
    "Lease",
    "Lease Agreement",
    "Lease and License",
    "Lease and Rent",
    "Lease by Development Authority",
    "Lease Deed",
    "Lease of Residential Premises",
    "Leasing and Hire Purchase",
    "Leave and Licence",
    "Leave of High Court",
    "Leave to Appeal",
    "Leave to Defend",
    "Leave to File Suit",
    "Legal Aid",
    "Legal Representative",
    "Legal Services",
    "Legislative Assembly",
    "Legislative Powers",
    "Legislative Privilege",
    "Legislature or Parliament",
    "Legitimacy of Children",
    "Legitimate Expectation",
    "Letters of Administration",
    "Letters Patent Appeal",
    "Level Playing Field",
    "Levy of Cess",
    "Levy of Octroi",
    "Liability of Agent",
    "Licence",
    "Licence Fee",
    "Licence for Fire Arms",
    "Licence for Liquor Shop",
    "Licensee",
    "Lieutenant Governor of Delhi",
    "Life",
    "Life Imprisonment",
    "Life Policy",
    "Limitation",
    "Liquor",
    "Liquor Bar License",
    "Liquor Licence",
    "Liquor Policy",
    "Lis Pendens",
    "Litigious Matters",
    "Loan Documents",
    "Local Authorities Members’ Disqualification",
    "Locus Standi",
    "Lodging House Licence",
    "Lok Adalat",
    "Lokayukta",
    "Lottery Ticket",
    "LP Appeal",
    "LPG Distributorship",
    "Magistrate",
    "Maintenance of Child",
    "Maintenance of Unmarried Daughter",
    "Major Ports Trust",
    "Mala Fides",
    "Malicious Prosecution",
    "Mamlatdar’s Court",
    "Mandamus",
    "Marathi Films",
    "Marine Insurance",
    "Maritime Arbitration",
    "Maritime Claims",
    "Market Cess and Supervision Charges",
    "Market Committee",
    "Mathadi Hamal",
    "Matrimonial Disputes",
    "Matrimonial Offences",
    "Matrimonial Proceedings",
    "Maxim",
    "MCOCA",
    "Medical Care",
    "Medical Council",
    "Medical Evidence",
    "Medical Examination of Accused",
    "Medical Jurisprudence",
    "Medical Negligence",
    "Medical Practice",
    "Medical Practitioners",
    "Medical Service",
    "Mental Health",
    "Mentally ill person",
    "Merchant Shipping",
    "Mercy Petition",
    "Mesne Profits",
    "Metropolitan Commissioner",
    "MHADA",
    "MIDC",
    "Military Service",
    "Mineral Extraction",
    "Mines and Minerals",
    "Mining Lease",
    "Minor girl",
    "Misappropriation",
    "Mischief",
    "Mischief by Fire",
    "Misjoinder of Causes of Action and Parties",
    "Misjoinder of Charges",
    "Misjoinder of Parties",
    "Mistake in Judgment and Decree",
    "Mistake of Law",
    "Mistakes in judgment, correction of",
    "MMRDA",
    "Mobile Hoarding Vans",
    "Moffusil Pleadings",
    "Money Circulation Scheme",
    "Money Decree",
    "Money Lenders",
    "Money-Laundering",
    "Monopolies and Restrictive Trade Practices",
    "Mortgage",
    "Mortgage by Conditional Sale",
    "Mortgage by Deposit of Title Deeds",
    "Motive",
    "Motor Accident Claim [See under “Accident Claim”]",
    "Motor Vehicles",
    "Motor Vehicles Rules",
    "Motor Vehicles Tax",
    "MSRTC Settlement",
    "Mundkars",
    "Mundkars Protection",
    "Municipal Corporation",
    "Municipal Councillors",
    "Municipal Councils",
    "Municipal Councils, Nagar Panchayats",
    "Municipal Councils, Nagar Panchayats and Industrial Townships",
    "Municipal Election",
    "Municipal Market",
    "Municipal Taxes",
    "Municipalities",
    "Murder",
    "Murder and Attempt to Commit Suicide",
    "Murder and Attempt to Murder",
    "Murder and Causing Disappearance of Evidence",
    "Murder and Criminal Intimidation",
    "Murder and Culpable Homicide",
    "Murder and Dacoity",
    "Murder and Grievous Hurt",
    "Murder and Hurt",
    "Murder and Misappropriation",
    "Murder and Robbery",
    "Murder and Unlawful Assembly",
    "Murder by Several Persons",
    "Murder or Culpable Homicide",
    "Murder or Grievous Hurt",
    "Murder or Suicide",
    "Murder Trial",
    "Murder with Criminal Conspiracy",
    "Murder, Criminal Conspiracy and Waging War",
    "Murder, Hurt and Criminal Intimidation",
    "Murder, Hurt and Unlawful Assembly",
    "Murder, Rioting and Unlawful Assembly",
    "Mutation on basis of Will",
    "Mutatis Mutandis",
    "MVAT",
    "Nagpur Improvement Trust",
    "Narco Analysis Test",
    "Narcotic Drugs/NDPS",
    "National Council for Teacher Education",
    "National Flag",
    "National Highways",
    "National Security",
    "Natural Guardian",
    "Natural Justice",
    "Natural Resources",
    "Navy Regulations",
    "Necessary Issues",
    "Necessary Party/Parties",
    "New Plea",
    "Newspaper Establishments",
    "No Confidence Motion",
    "Noise Pollution",
    "Nomination Paper",
    "Nominee of Deceased Person",
    "Non-appearance of Complainant",
    "Non-appearance of Defendant",
    "Non-bailable Warrant",
    "Non-cognizable Offence",
    "Non-framing of Separate Charge",
    "Non-joinder of parties",
    "Non-suiting of Party",
    "Notarised Document",
    "Notary",
    "Notice Inviting Tenders",
    "Notice of Forfeiture",
    "Notice of Motion",
    "Notice of Recovery",
    "Notice to Government",
    "Notice to Produce Document",
    "Notice under S.80 of CPC",
    "Notification by Government",
    "Novation of Contract",
    "Nozul Land",
    "Nuisance",
    "Nuisance and Trespass",
    "Nursing Council",
    "Nursing Education",
    "OBC",
    "Obiter Dictum",
    "Obscene Acts",
    "Obscenity",
    "Occupancy Certificate",
    "Octroi",
    "Octroi Duty",
    "Octroi on Aircrafts",
    "Ocular and Medical Evidence",
    "Offence",
    "Offence Committed Outside India",
    "Offence under S.493",
    "Offence under Ss.307, 326",
    "Office Order",
    "Official Secrets",
    "Ombudsman",
    "Omission to Give Information of Offence",
    "Omission to Sue",
    "Open Court",
    "Open Prisons",
    "Opening Case for Prosecution",
    "Opposition Leader",
    "Order",
    "Order by Public Authority",
    "Order Enquiry",
    "Order not appealable",
    "Order of Labour Court",
    "Order to Pay Compensation",
    "Ordinance",
    "Originating Summons",
    "Outraging Modesty",
    "Overtime Allowance",
    "Overtime Wages",
    "Owner of Property",
    "Ownership",
    "Ownership of Female Hindu",
    "Ownership of Flats",
    "Ownership Rights",
    "Panchayat",
    "Panchayat Elections",
    "Panchayat Raj",
    "Panchayat Samitis",
    "Pardon",
    "Pardon to Accomplice",
    "Parliament",
    "Parliamentary Proceedings",
    "Part Performance",
    "Parties to the Suit",
    "Partition",
    "Partition Proceedings",
    "Partition Suit",
    "Partnership",
    "Partnership Firm",
    "Party Membership",
    "Passing off",
    "Passports",
    "Patent",
    "Patent Act",
    "Patent Appeal",
    "Patent Rules",
    "Pawnee",
    "Payment of Deposit",
    "Pecuniary Jurisdiction",
    "Penalty",
    "Penology",
    "Pension Scheme",
    "Pension to Freedom Fighter",
    "Perjury",
    "Permanent Judge",
    "Permissible Classification",
    "Perpetuity",
    "Person",
    "Person of Indian Origin",
    "Personal Liberty",
    "Personal Liberty and Public Safety",
    "Personal Liberty of a Suspect",
    "Persons with Disabilities",
    "Petition",
    "Petrol pump",
    "Petroleum and Minerals Pipelines",
    "Petroleum Dealership",
    "Pharmacy Act",
    "Physically Handicapped Special Schools Code",
    "Place of Public Amusement",
    "Places of Worship",
    "Plaint",
    "Plea Bargaining",
    "Plea of Alibi",
    "Plea of Fraud",
    "Plea of Good Faith",
    "Plea of Guilty",
    "Plea of Insanity",
    "Plea of Private Defence",
    "Plea of Self Defence",
    "Pleadings",
    "Pleadings and Proof",
    "Pleasure Doctrine",
    "Police",
    "Police Atrocities",
    "Police Custody",
    "Police Force",
    "Police Humiliation",
    "Police Investigation",
    "Police Manual",
    "Police Officer",
    "Police Patil",
    "Police Remand",
    "Police Report",
    "Police Service",
    "Police Statement",
    "Police Witness",
    "Policy Decision",
    "Pollution Control",
    "Polygraph (Lie Detection) Test",
    "Port",
    "Port Trust Charges",
    "Port Trusts",
    "Portuguese Civil Code",
    "Post Office",
    "Post-mortem Reports",
    "POTA",
    "Pot-kharab Land",
    "Powai Area Development Scheme",
    "Power of Attorney",
    "Power of Attorney Holder",
    "Power of Clemency",
    "Power of Pardon",
    "Power of Review",
    "Power of Superintendence",
    "Power to Commute Sentence",
    "Power to proceed against other persons appearing to be guilty of offence",
    "Power to Strike or Add Parties",
    "Powers of Appellate Court",
    "Practice and Procedure",
    "Prasar Bharati",
    "Preamble to Constitution",
    "Precedents",
    "Pre-conception and Pre-natal Diagnostic Techniques",
    "Pre-emption",
    "Preliminary Decree",
    "Preliminary Decree and Final Decree",
    "Preliminary Enquiry",
    "Preliminary Issue",
    "Premature Release",
    "Premature Transfer",
    "Premises License",
    "Prescription",
    "Prescriptive Right",
    "Present Demise",
    "Press and Registration of Books",
    "Presumption",
    "Presumption Against Accused",
    "Presumption as to Abetment of Suicide",
    "Presumption as to Dead Person",
    "Presumption as to Dowry Death",
    "Presumption as to Marriage",
    "Presumption as to Thirty Years Old Documents",
    "Presumption of Consideration",
    "Presumption of Innocence",
    "Presumption of Truth",
    "Presumption under S.113A of Evidence Act",
    "Presumption under S.114",
    "Presumption under S.20",
    "Prevention of Atrocities",
    "Prevention of Corruption",
    "Prevention of Disqualification",
    "Prevention of Gambling",
    "Prevention of Terrorism",
    "Price Control Order",
    "Principal and Agent",
    "Principle of Equality",
    "Prison",
    "Prison Excesses",
    "Prison Offence",
    "Prison Reforms",
    "Prison Staff",
    "Prison Vices",
    "Prisoners",
    "Private Case",
    "Private Complaint",
    "Private Defence/Self Defence",
    "Private Forest",
    "Private Security Guards",
    "Private Temple/trust",
    "Private Trust",
    "Privileged Communication",
    "Probate",
    "Probate of Will",
    "Probate Petition",
    "Probate Proceedings",
    "Probation",
    "Probation of Good Conduct",
    "Probation of Offenders",
    "Procedural Law",
    "Procedure",
    "Procedure Established by Law",
    "Procedure under Cr.P.C. S.156",
    "Proceedings under Cr.P.C. S.145",
    "Proceedings under Cr.P.C. Ss.107,111,116",
    "Proclaimed Offender",
    "Proclamation of Sale",
    "Production of Document",
    "Production of Documentary Evidence",
    "Production of Evidence",
    "Production Warrant",
    "Professional Conduct and Etiquette",
    "Professional Misconduct",
    "Prohibition",
    "Prohibition of Sex Selection",
    "Prohibitory Order",
    "Project Affected Oustees",
    "Project Affected Persons",
    "Project Displaced Persons",
    "Promissory Estoppel",
    "Promissory Note",
    "Promissory/Equitable Estoppels",
    "Pronouncement of Judgment",
    "Proof",
    "Proof beyond Reasonable Doubt",
    "Proof of Defence",
    "Proof of Document",
    "Proof of Facts",
    "Proof of Handwriting",
    "Proof of Map",
    "Proof of Residence",
    "Proof of Signature",
    "Proof of Title",
    "Property",
    "Property Dispute",
    "Property Law",
    "Property Right",
    "Property Tax",
    "Prosecution",
    "Prosecution by CBI",
    "Prospective Overruling",
    "Prostitution",
    "Protection against Self-incrimination",
    "Protection of Children from Sexual Offences",
    "Protection of Civil Rights",
    "Protection of Historical Monument",
    "Protection to Laws in 9th Schedule",
    "Protector of Emigrants",
    "Protest Petition",
    "Prothonotary",
    "Protraction of Litigation",
    "Provincial Municipal Corporation",
    "Public Amusement",
    "Public Auction",
    "Public Authority",
    "Public Contract",
    "Public Distribution System",
    "Public Document",
    "Public Financial Institutions",
    "Public Health",
    "Public Interest Litigation (PIL)",
    "Public Land",
    "Public Meetings",
    "Public Nuisance",
    "Public Order",
    "Public Place",
    "Public Policy",
    "Public Premises",
    "Public Project",
    "Public Property",
    "Public Prosecutor",
    "Public Purpose",
    "Public Sector Undertaking",
    "Public Servant",
    "Public Service Commission",
    "Public Street",
    "Public Trusts",
    "Public View",
    "Punishment",
    "Punishment in Court Martial",
    "Punishment of Dismissal",
    "Purchase Tax & Value Added Tax",
    "Quantum of Damages",
    "Quashing of Charge",
    "Quashing of Charge-sheet",
    "Quashing of Complaint",
    "Quashing of Criminal Proceedings",
    "Quashing of Criminal Prosecution",
    "Quashing of FIR",
    "Quashing of Offence",
    "Quashing of Order",
    "Quashing of Proceedings",
    "Quashing of Process",
    "Quashing of Prosecution",
    "Quasi Judicial",
    "Quasi Judicial Authority",
    "Quasi Judicial Order",
    "Quasi Judicial Proceedings",
    "Question of Facts",
    "Quo Warranto",
    "Ragging",
    "Railway",
    "Railway Accident Claim",
    "Railway Act",
    "Railway Administration",
    "Railway Claim",
    "Railway Property",
    "Railway Quarter",
    "Railway Servants",
    "Railway Tender",
    "Railway Vigilance Manual",
    "Rape",
    "Rape and Abetment of Suicide",
    "Rape and Criminal Intimidation",
    "Rape and Indecent Assault",
    "Rape and Murder",
    "Rape and Wrongful Restraint",
    "Rape on Minor",
    "Rape or Cheating",
    "Rarest of Rare Case",
    "Rash and Negligent Driving",
    "Rate of Interest",
    "Rate of Tax",
    "Rateable Value",
    "Ratio decidendi",
    "Reasonable Classification",
    "Reasonable Restrictions",
    "Reasonous Judgment",
    "Reasonous Order",
    "Recall of Order",
    "Recall of Process",
    "Recalling Earlier Order",
    "Recalling of Dismissal Order",
    "Recalling of Witness",
    "Receiver",
    "Receiving Stolen Property",
    "Reciprocal Promise",
    "Reconstruction of Market",
    "Record of Right",
    "Recording of Evidence",
    "Recording of Statement",
    "Recovery Certificate",
    "Recovery Evidence",
    "Recovery of Debts",
    "Recovery of Excess Allowance",
    "Recovery of Excess Amount",
    "Recovery of Loan (Dues)",
    "Recovery of Public Dues",
    "Recovery Proceedings",
    "Recruitment/Selection Process",
    "Rectification of Deed",
    "Red Corner Notice",
    "Redemption",
    "Redemption of Mortgaged Property",
    "Redevelopment",
    "Redevelopment of Chawl",
    "Redevelopment Scheme",
    "Re-examination",
    "Re-examination of Accused",
    "Re-examination of Witness",
    "Reference",
    "Reference to Larger Bench",
    "Refund of Earnest Money",
    "Regional and Town Planning",
    "Regional Transport Authority",
    "Register of Mutations",
    "Registration",
    "Registration as Mundkar",
    "Registration as Overseas Citizen",
    "Registration Fee",
    "Registration of Conveyance",
    "Registration of Crime",
    "Registration of Document",
    "Registration of Immovable Property",
    "Registration of Marriage",
    "Registration of Newspaper",
    "Registration of Vehicle",
    "Regrant of Watan Lands",
    "Regulations of Statutory Corporations",
    "Rehabilitation of Sex Workers",
    "Rehabilitation Scheme",
    "Re-investigation",
    "Rejection of plaint",
    "Rejection of Visa",
    "Related Witness",
    "Release of Seized Articles",
    "Release of Seized Property",
    "Release of Seized Vehicle",
    "Relevancy and Admissibility of Evidence",
    "Relevancy of Bad Character",
    "Relevancy of Evidence",
    "Relevancy of Public Document",
    "Relief under Art.142 of Constitution",
    "Relief under Art.226",
    "Relief Undertaking",
    "Religion",
    "Religious Endowment",
    "Religious Institutions and Endowments",
    "Relinquishing Claim",
    "Relinquishment Deed",
    "Remand",
    "Remand of Matter",
    "Remission",
    "Remission of Sentence",
    "Remission or Commutation of Sentence",
    "Removal of Encroachment",
    "Removal of Encroachment on Public Roads",
    "Removal of Governor",
    "Removal of Obstruction",
    "Removal of Trees",
    "Repeal",
    "Representation to Government",
    "Representative Suit",
    "Repugnancy",
    "Repugnancy of Laws",
    "Repugnancy of Legislations",
    "Requisition of Land",
    "Requisition of Property",
    "Requisitioning of Immovable Property",
    "Res Gestae",
    "Res Gestae Witnesses",
    "Res Judicata",
    "Reservation",
    "Reservation for Children of Weaker Section",
    "Reservation for Handicapped",
    "Reservation for Women",
    "Reservation in Matters of Promotion",
    "Reservation in Post Graduate Courses",
    "Reservation in Service",
    "Reservation Policy",
    "Reserved Land",
    "Restitution",
    "Restoration",
    "Restoration Application",
    "Restoration of Appeal",
    "Restoration of Electricity",
    "Restoration of Lands",
    "Restoration of Possession",
    "Restoration of Suit",
    "Restrainment of Trade",
    "Restrictive Trade Practices",
    "Retail Dealer’s Licensing Order",
    "Retail Dealers’ Licensing",
    "Retail Outlet Dealership",
    "Retiral Benefits",
    "Retracted Confession",
    "Retracted Judicial Confession",
    "Retrial",
    "Return of Property",
    "Revaluation of Answer Books",
    "Revenue Record",
    "Revenue Tribunal",
    "Review",
    "Revision",
    "Revision Against Acquittal",
    "Revision Against Conviction",
    "Revisional and Inherent Powers",
    "Revisional Jurisdiction",
    "Revisional Power of Chief Settlement Commissioner",
    "Revisional Powers",
    "Revocation of Guarantee",
    "Revocation of Special Leave",
    "Right against Self-incrimination",
    "Right of Accused of Confrontment",
    "Right of Appeal and Forum of Appeal",
    "Right of Defence",
    "Right of Inheritance",
    "Right of Life",
    "Right of Minorities",
    "Right of Pawnee",
    "Right of Personal Liberty",
    "Right of Pre-emption",
    "Right of Privacy",
    "Right of Way",
    "Right to Appeal",
    "Right to Begin",
    "Right to Education",
    "Right to Employment",
    "Right to Establish Educational Institutions",
    "Right to Fair Trial",
    "Right to Gender Equality",
    "Right to Information",
    "Right to Lead Evidence",
    "Right to Legal Services",
    "Right to Life",
    "Right to Life and Liberty",
    "Right to Live with Dignity",
    "Right to Livelihood",
    "Right to Organize Sports Events",
    "Right to Pension",
    "Right to Practice",
    "Right to Privacy",
    "Right to Produce Evidence",
    "Right to Property",
    "Right to Trade",
    "Right to Voice Grievance",
    "Right to Vote",
    "Right to Work",
    "Rights of Backward Classes",
    "Rights of Citizens",
    "Rights of Prisoners",
    "Rights of Women",
    "Riot and Communal Disturbance",
    "Riot by Unlawful Assembly",
    "Rioting and Assault",
    "Rioting, Armed with Deadly Weapon",
    "Rioting, Dacoity and Unlawful Assembly",
    "Riverine Land",
    "Road",
    "Robbery",
    "Robbery and Dacoity",
    "Robbery and Grievous Hurt",
    "Robbery and Murder",
    "Robbery and Voluntarily Causing Hurt",
    "Robbery or Dacoity",
    "Robbery with Attempt to Cause Death",
    "Robbery with Attempt to Cause Grievous Hurt",
    "Rules of Business",
    "Rules of Law",
    "Rural Development",
    "Rural Employment Guarantee",
    "Salary Paid in Excess",
    "Salary Saving Scheme of L.I.C.",
    "Sale",
    "Sale by Minor",
    "Sale Deed",
    "Sale of Goods",
    "Sale of Land",
    "Sale of Minor’s Property",
    "Sale of Spurious Liquor",
    "Sale under Undue Influence",
    "Sales Tax",
    "Sanad",
    "Sanction",
    "Sanction for Prosecution",
    "SC/ST",
    "Scheduled Industry",
    "Scholarship Schemes",
    "School",
    "School Leaving Certificate",
    "School Register",
    "School Tribunal",
    "Scientific Expert",
    "Scope and Object of the Act",
    "Sea Transport",
    "Search and Seizure",
    "Search of Arrested Person",
    "Search of Person Wrongfully Confined",
    "Search Warrant",
    "SEBI",
    "SEBI Regulations",
    "Second Appeal",
    "Second Marriage",
    "Second Suit",
    "Secondary Evidence",
    "Secular Activity/ Secularism",
    "Securities",
    "Securities Contracts",
    "Securities Transaction",
    "Securitisation and Reconstruction",
    "Security Deposit",
    "Security Guards",
    "Security Interest",
    "Security of Citizens",
    "Seeds",
    "Seeds Act",
    "Seizure of Bank Account",
    "Seizure of Property",
    "Seizure of Vehicles",
    "Sentence",
    "Sentencing in Criminal Trial",
    "Sentencing Pattern",
    "Sentencing System",
    "Separate Trial",
    "Separation of Powers",
    "Service by Post",
    "Service of Citation",
    "Service Tax",
    "Sessions Judge",
    "Set off Against Sentence",
    "Set-off",
    "Setting aside Award",
    "Setting aside Ex-parte Decree",
    "Setting aside Sale",
    "Settlement",
    "Sexual Assault",
    "Sexual Harassment",
    "Shariat Law",
    "Show Cause Notice",
    "Sick Companies",
    "Sick Industrial Companies",
    "Sick Industries",
    "Simulteneous Civil and Criminal Proceedings",
    "Slaughter Houses",
    "Slaughter of Bullocks",
    "Slaughter of Cow",
    "Slum Areas",
    "Slum Development/Redevelopment",
    "Slum Rehabilitation",
    "Small Cause Courts",
    "Small Scale Industrial Unit",
    "Small Scale Undertakings",
    "Smugglers and Foreign Exchange Manipulators",
    "Smuggling",
    "Smuggling of Gold",
    "Social and Economic Justice to Women",
    "Social Clubs",
    "Social Order",
    "Social Status Claim",
    "Solitary Confinement",
    "Solitary Eye-witness",
    "Solitary Witness",
    "Solvency Certificate",
    "Sovereign Functions",
    "Special Case",
    "Special Court",
    "Special Economic Zones",
    "Special Leave Petition",
    "Special Leave to Appeal",
    "Special Public Prosecutor",
    "Special Report",
    "Special Statute",
    "Specific Performance",
    "Specific Relief",
    "Spectrum Scam",
    "Speedy Trial",
    "Sports Complex",
    "Stamp Act",
    "Stamp Duty",
    "Standards of Weights and Measures",
    "Standing Committee",
    "State",
    "State Commission for Women",
    "State Election Commissions",
    "State Financial Corporation",
    "State Government",
    "State Rules of Business",
    "State to Act Fairly",
    "State Trading Corporation",
    "State under Art.12",
    "Statement",
    "Statement of Accused",
    "Statement of Deceased",
    "Statement of Police",
    "Statement of Prosecution Witnesses",
    "Statement of Witness",
    "Statement Recorded by Police",
    "Statement to Police",
    "Statement under S.161",
    "Statement under S.164",
    "Statement under S.313",
    "Statements made while in Custody",
    "States Reorganization",
    "Status of the Heirs",
    "Status quo",
    "Statute",
    "Statute and Court decision",
    "Statutory Arbitrator",
    "Statutory Authority",
    "Statutory First Charge",
    "Statutory Notice",
    "Statutory Rules",
    "Stay",
    "Stay Application",
    "Stay in Appeal",
    "Stay of Arrest",
    "Stay of Criminal Proceedings",
    "Stay of Decree",
    "Stay of Proceedings",
    "Stay of Subsequent Suit",
    "Stay of Suit",
    "Stock Exchange",
    "Stolen Property",
    "Stray Dogs",
    "Strike out Pleadings",
    "Striking off Additional Written Statement",
    "Striking off Defence",
    "Subordinate Courts",
    "Subordinate Judiciary",
    "Subordinate Legislation",
    "Subordinate Regulations",
    "Subsequent Events",
    "Subsequent Suit",
    "Substantial Justice",
    "Substantial Question of Law",
    "Substitution of Heirs",
    "Substitution of Legal Representative",
    "Succession",
    "Succession Certificate",
    "Succession Proceedings",
    "Sudden Provocation",
    "Sudden Quarrel",
    "Sugarcane Price",
    "Suicidal Death",
    "Suicide",
    "Suicide Note",
    "Suit",
    "Suit against Apprehended Injury",
    "Suit Against Demolition",
    "Suit against Diplomatic Agent",
    "Suit against Dispossession",
    "Suit against Foreign Ruler",
    "Suit against Government",
    "Suit against Minor",
    "Suit by or against Trust",
    "Suit by Partnership Firm",
    "Suit by Persons of Unsound Mind",
    "Suit by Several Plaintiffs",
    "Suit for Cancellation of Sale Deed",
    "Suit for Cancellation of Will",
    "Suit for Damages",
    "Suit for Declaration",
    "Suit for Declaration and Injunction",
    "Suit for Dissolution of Partnership",
    "Suit for Enforcement of Legal Right",
    "Suit for Eviction",
    "Suit for Injunction",
    "Suit for Malicious Prosecution",
    "Suit for Partition",
    "Suit for Partition and Separate Possession",
    "Suit for Partition of HUF Property",
    "Suit for Permanent Injunction",
    "Suit for Perpetual Injunction",
    "Suit for Recovery",
    "Suit for Redemption of Mortgage",
    "Suit for Share in Partnership Property",
    "Suit on Behalf of Company",
    "Suit on Promissory Note",
    "Suit to Include Whole Claim",
    "Suit under Art.131",
    "Suits Valuation",
    "Summary Eviction",
    "Summary Proceedings",
    "Summary Suit",
    "Summary Trial",
    "Summon Witness",
    "Summoning of Accused",
    "Summoning of Additional Accused",
    "Summoning of Additional Witnesses",
    "Summoning of Witnesses",
    "Summoning Order",
    "Summons",
    "Summons Case",
    "Summons for Judgment",
    "Summons to Produce",
    "Suo motu Enquiry",
    "Suo motu Revision",
    "Superannuation",
    "Superintendence",
    "Superintendence of Police Force",
    "Supersession of Notification",
    "Supervisory Jurisdiction",
    "Supreme Court",
    "Supreme Court Rules",
    "Surety",
    "Surety Agreement",
    "Surplus Land",
    "Surrender of Tenancy",
    "Surrogate Mother",
    "Suspension of Conviction",
    "Suspension of Legal Proceedings",
    "Suspension of License",
    "Suspension of Members of PSC",
    "Suspension of Sentence",
    "Suspension Pending Enquiry",
    "Swatantra Sainik Sanman Pension",
    "TADA",
    "Tainted Shares",
    "Tape Recorded Evidence",
    "Tape-recorder",
    "Tax",
    "Tax and Fee",
    "Tax Evasion",
    "Tax on Land and Buildings",
    "Tax on Lotteries",
    "TDS Certificates",
    "Teacher Education",
    "Teachers Training",
    "Technical Education",
    "Telecom",
    "Telecom Licence",
    "Telecom Policy",
    "Telecom Regulatory Authority",
    "Telecommunications",
    "Telegraph",
    "Telephone",
    "Telephone Bill",
    "Temple Properties",
    "Temporary Injunction",
    "Tenancy",
    "Tenancy and Agricultural Lands",
    "Tenancy Law",
    "Tenancy Rights",
    "Tender",
    "Tender Notice",
    "Tender of Pardon",
    "Tender Process",
    "Termination of Contract",
    "Termination of Dealership",
    "Termination of Pregnancy",
    "Termination of Probationer",
    "Termination of Proceedings",
    "Termination of Tenancy",
    "Territorial Jurisdiction",
    "Terrorism",
    "Terrorist Attack",
    "Testamentary Suit",
    "Testimony Compulsion",
    "Testimony of Witness",
    "Textile Committee",
    "Textile Undertakings",
    "Theatre Tax",
    "Theft",
    "Theft and Lurking House Trespass",
    "Theft and Murder",
    "Theft of Electricity",
    "Theft of Municipal Property",
    "Thermal Power Project",
    "Time Barred Debt",
    "Title by Prescription",
    "Title of Person",
    "Toll",
    "Toll Collection at Flyovers",
    "Toll Fee",
    "Toll on Vehicles",
    "Torts",
    "Tourism",
    "Town Planning",
    "Town Planning and Development",
    "Town Planning Scheme",
    "Trade Marks",
    "Traffic Jam",
    "Transactions in Securities",
    "Transfer",
    "Transfer by Co-heir",
    "Transfer of Case",
    "Transfer of Civil Suit",
    "Transfer of Criminal Case",
    "Transfer of Criminal Proceedings",
    "Transfer of Decree",
    "Transfer of Holding",
    "Transfer of Investigation",
    "Transfer of Lands",
    "Transfer of Prisoners",
    "Transfer of Proceedings",
    "Transfer of Property",
    "Transfer of Suit",
    "Transfer of Vehicle",
    "Transfer Pendent",
    "Transplantation of Human Organs",
    "Transport",
    "Transport Assistance Allowance",
    "Transposition",
    "Transposition of Parties",
    "Trap Case",
    "Treaties",
    "Trees Felling",
    "Trial by Court Martial",
    "Trial by Media",
    "Trial for More than One Offence",
    "Trial of Suit",
    "Tribe Claim",
    "Trust",
    "Trust Deed",
    "Trust Property",
    "Tsunami Disaster",
    "Unaccounted Money",
    "Unauthorised Construction",
    "Unauthorised Excavation",
    "Unauthorised Occupation",
    "Unauthorised Structures",
    "Under Trial Prisoners",
    "Undivided Share",
    "Undue Influence",
    "Unemployed Youth",
    "Unfair Trade Practice",
    "Unlawful Activities",
    "Unlawful Arrest",
    "Unlawful Assembly",
    "Unlawful Assembly and Murder",
    "Unlawful Possession of Arms",
    "Unlicensed Revolver",
    "Unnatural Conduct",
    "Unnatural Lust",
    "Unnatural Offence",
    "Unsoundness of Mind",
    "Untoward Incident",
    "Urban Land Ceiling",
    "Validity of Act",
    "Validity of Legislation",
    "Validity of Roerich Act",
    "Validity of Scheme",
    "Valuation of Property",
    "Valuation Report",
    "VAT",
    "Vehicles Permit",
    "Venue of Trial",
    "Verification Statement",
    "Veterinary Council",
    "Vexatious Litigation",
    "Vexatious Proceedings",
    "Victim",
    "Video Conferencing System",
    "Village Panchayats",
    "Village Panchayats Election",
    "Village Panchayats Taxes and Fees Rules",
    "Village Police",
    "Voice Identification",
    "Voluntarily Causing Hurt",
    "Vulgar SMS",
    "Waging War",
    "Waiver",
    "Wakf",
    "Wakf Board",
    "Wakf Registration",
    "Ward Committee",
    "Warrant",
    "Warrant Trial",
    "Water Charges",
    "Water Disputes",
    "Water Pollution",
    "Water Tax",
    "Weights and Measures",
    "Welfare of Child",
    "Welfare Provision",
    "Welfare Scheme",
    "Welfare State",
    "Wild Life",
    "Wild Life Protection",
    "Will",
    "Will Registration",
    "Willful Defaulters",
    "Winding up Petition",
    "Withdrawal Application",
    "Withdrawal from Prosecution",
    "Withdrawal of Amount",
    "Withdrawal of Charges",
    "Withdrawal of Deposit",
    "Withdrawal of Proceedings",
    "Withdrawal of Suit",
    "Witness",
    "Witness against Himself",
    "Witness in Expectation of Death",
    "Witness Summons",
    "Women",
    "Women Prisoners",
    "Words and Phrases",
    "Working Journalists",
    "Works Contract",
    "Writ Appeal",
    "Writ Jurisdiction",
    "Writ of Certiorari",
    "Writ of Mandamus",
    "Writ of Prohibition",
    "Writ of Quo Warranto",
    "Writ of Summons",
    "Writ Petition",
    "Written Statement",
    "Wrongful Confinement",
    "Wrongful Confinement by Police",
    "Wrongful Restraint",
    "Wrongful Restraint and Criminal Intimidation",
    "Wrongful Restraint and Criminal Trespass",
    "Wrongful Restraint and Dacoity",
    "Wrongfully withholding Property",
    "Zamindari Abolition",
    "Zilla Parishad",
    "Zilla Parishads and Panchayat Samities",
    "Zunka Bhakar Scheme"
];

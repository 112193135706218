import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "../services/auth.service";

import { Observable } from "rxjs";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // console.log('islogged', this.authService.isLoggedIn());
        if (this.authService.isLoggedIn()) {
            return true;
        } else {
            this.router.navigate(["/"]);
            return false;
        }
    }
}

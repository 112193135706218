export const actsformated = [
    'Absorbed Areas (Laws) Act, 1954',
    'Academy of Scientific and Innovation Research Act, 2012',
    'Acquired Territories (Merger) Act, 1960',
    'Acquisition of Certain Area at Ayodhya Act, 1993',
    'Additional Duties of Excise (Goods of Special Importance) Act, 1957',
    'Additional Duties of Excise (Textiles and Textile Articles) Act, 1978',
    'Additional Emoluments (Compulsory Deposit) Act, 1974',
    'Administrative Tribunals Act, 1985',
    'Administrative Tribunal (Amendment) Act, 1986',
    'Administrators-General Act, 1963',
    'Advocates Act, 1961',
    'Advocates Welfare Fund Act, 2001',
    'African Development Bank Act, 1983',
    'African Development Fund Act, 1982',
    'Agricultural and Processed Food Products Export Development Authority Act, 1986',
    'Agricultural Produce (Grading and Marking) Act, 1937',
    'Agriculturists\' Loans Act, 1884',
    'Aircraft Act, 1934',
    'Airports Authority of India Act, 1994',
    'Airports Economic Regulatory Authority of India Act, 2008',
    'Air Corporations (Transfer of Undertakings and Repeal) Act, 1994',
    'Air Force Act, 1950',
    'Air (Prevention and Control of Pollution) Act, 1981',
    'Air (Prevention and Control of Pollution) Amendment Act, 1987',
    'Ajmer Tenancy and Land Records Act, 1950',
    'Alcock Ashdown Company Limited (Acquisition of Undertakings) Act, 1973',
    'Aligarh Muslim University Act, 1920',
    'All-India Council for Technical Education Act, 1987',
    'All-India Institute of Medical Sciences Act, 1956',
    'All-India Services Act, 1951',
    'All-India Services Regulations (Indemnity) Act, 1975',
    'Aluminium Corporation of India Limited (Acquisition and Transfer of Aluminium) Undertaking Act, 1984',
    'Amending Act, 1897',
    'Amending Act, 1901',
    'Amending Act, 1903',
    'Amritsar Oil Works (Acquisition and Transfer ofUndertakings) Act, 1982',
    'Anand Marriage Act, 1909',
    'Ancient Monuments and Archaeological Sites and Remains Act, 1958',
    'Ancient Monuments Preservation Act, 1904',
    'Andhra Pradesh and Madras (Alteration ofBoundaries) Act, 1959',
    'Andhra Pradesh and Mysore (Transfer of Territory) Act, 1968',
    'Andhra Pradesh Legislative Council Act, ',
    'Andhra Pradesh Reorganisation Act, 2014',
    'Andhra Scientific Company Limited (Acquisition and Transfer of Undertakings) Act, ',
    'AndhraState Act, 1953',
    'Anti-Apartheid (United Nations Convention) Act, 1981',
    'Anti-Corruption Laws (Amendment) Act, 1967',
    'Anti-Hijacking Act, 1982',
    'Antiquities and Art Treasures Act, 1972',
    'Apprentices Act, 1961',
    'Arbitration and Conciliation Act, 1996',
    'Architects Act, 1972',
    'Armed Forces (Emergency Duties) Act, 1947',
    'Armed Forces (Jammu and Kashmir) Special Powers Act, 1990',
    'Armed Forces (Punjab and Chandigarh) Special Powers Act, 1983',
    'Armed Forces (Special Powers) Act, 1958',
    'Armed Forces Tribunal Act, 2007',
    'Arms Act, 1959',
    'Army Act, 1950',
    'Army and Air Force (Disposal of Private Property) Act, 1950',
    'Arya Marriage Validation Act, 1937',
    'Asian Development Bank Act, 1966',
    'Asian Refractories Limited (Acquisition of Undertakings) Act, 1971',
    'Asiatic Society Act, 1984',
    'Assam (Alteration of Boundaries) Act, 1951',
    'Assam Criminal Law Amendment (Supplementary) Act, 1934',
    'Assam Municipal (Manipur Amendment) Act, 1961',
    'Assam Reorganisation (Meghalaya) Act, 1969',
    'Assam Rifles Act, 1941',
    'Assam Rifle Act, 2006',
    'Assam Sillimanite Limited (Acquisition and Transfer of Refractory Plant) Act, 1976',
    'Assam University Act, 1989',
    'Atomic Energy Act, 1962',
    'Actquaries Act, 2006',
    'Auroville Foundation Act, 1988',
    'Authoritative Texts (Central Laws) Act, 1973',
    'Babasaheb Bhimrao Ambedkar University Act, 1994',
    'Banaras Hindu University Act, 1915',
    'Bangalore Marriages Validating Act, 1936',
    'Bankers\' Books Evidence Act, 1891',
    'Banking Companies (Acquisition and Transfer of Undertakings) Act, 1970',
    'Banking Companies (Acquisition and Transfer of Undertakings) Act, 1980',
    'Banking Laws (Application to Co-operative Societies) Act, 1965',
    'Banking Laws (Amendment) Act, 1985',
    'Banking Regulation Act, 1949',
    'Bar Councils (Validation of State Laws) Act, 1956',
    'Beedi and Cigar Workers (Conditions of Employment) Act, 1966',
    'Beedi and Cigar Workers (Conditions of Employment) Amendment Act, 1993',
    'Beedi Workers Welfare Cess Act, 1976',
    'Beedi Workers Welfare Fund Act, 1976',
    'Benami Transactions (Prohibition) Act, 1988',
    'Bengal Alluvion and Diluvion Act, 1847',
    'Bengal Bonded Warehouse Association Act, 1838',
    'Bengal Bonded Warehouse Association Act, 1854',
    'Bengal Chaukidari Act, 1856',
    'Bengal Chemical and Pharmaceutical Works Limited (Acquisition and Transfer of Undertakings) Act, 1980',
    'Bengal Criminal Law Amendment (Supplementary) Act, 1925',
    'Bengal Districts Act, 1836',
    'Bengal Embankment Act, 1855',
    'Bengal Finance (Sales Tax) (Delhi Validation of Appointments and Proceedings) Act, 1971',
    'Bengal Ghatwali Lands Act, 1859',
    'Bengal Immunity Company Limited (Acquisition and Transfer of Undertakings) Act, 1984',
    'Bengal Indigo Contracts Act, 1836',
    'BengalLand Holders\' Attendance Act, 1848',
    'Bengal Land Revenue Sales Act, 1841',
    'BengalLand Revenue Sales Act, 1859',
    'Bengal Military Police Act, 1892',
    'Bengal Rent Act, 1859',
    'Bengal Suppression of Terrorist Outrages (Supplementary) Act, 1932',
    'Bengal Tenancy Act, 1885',
    'Berar Laws Act, 1941',
    'BetwaRiver Board (Amendment) Act, 1993',
    'Betwa River Board Act, 1976',
    'Bharat Petroleum Corporation Limited (Determination of Conditions of Service of Employees) Act, 1988',
    'Bhopal Gas Leak Disaster (Processing of Claims) Act, 1985',
    'Bihar Land Reforms Laws (Regulating Mines and Minerals) Validation Act, 1969',
    'Bihar Reorganisation Act, 1925',
    'Bihar and Uttar Pradesh (Alteration of Boundaries) Act, 1968',
    'Bihar Value Added Tax Act, 2005',
    'Bihar and West Bengal (Transfer of Territories) Act, 1956',
    'Bikrama Singh\'s Estates Act, 1883',
    'Biological Diversity Act, 1925',
    'Bird and Company Limited (Acquisition and Transfer of Undertakings and Other Properties) Act, 1980',
    'Bolani Ores Limited (Acquisition of Shares) and Miscellaneous Provisions Act, 1978',
    'Bombay Civil Courts Act, 1869',
    'Bombay Municipal Debentures Act, 1876',
    'Bombay Public Security Measures (Delhi Amendment) Act, 1948',
    'Bombay Rent-free Estates Act, 1852',
    'Bombay Reorganisation Act, 1960',
    'Bombay Revenue Jurisdiction Act, 1876',
    'Bonded Labour System (Abolition) Act, 1976',
    'Border Security Force Act, 1968',
    'Brahmaputra Board Act, 1980',
    'Braithwaite and Company (India) Limited(Acquisition and Transfer of Undertakings) Act, 1976',
    'Brentford Electric (India) Limited (Acquisitionand Transfer of Undertakings) Act, 1987',
    'Britannia Engineering Company Limited (Mokameh Unit) and theArthur Butler and Company (Muzaffarpore) Limited (Acquisition and Transferof Undertakings) Act, 1978',
    'British India Corporation Limited (Acquisition of Shares) Act, 1981',
    'British Statutes (Application to India) Repeal Act, 1960',
    'Broach and KairaIncumbered Estates Act, 1877',
    'Bronze Coin (Legal Tender) Act, 1918',
    'Building and Other Construction Workers (Regulation of Employment and Conditions ofService) Act, 1996',
    'Building and Other Construction Workers\' Welfare Cess Act, 1996',
    'Bureau of Indian Standards Act, 1986',
    'Burmah Oil Company [Acquisition of Shares of Oil India Limited and of the Undertakings in India of Assam Oil Company Limited and the Burmah Oil Company (India Trading) Limited] Act, 1981',
    'Burmah Shell (Acquisition of Undertakings in India) Act, 1976',
    'Burn Company and Indian Standard Wagon Company (Nationalisation) Act, 1976',
    'Cable Television Networks (Regulation) Act, 1995',
    'Calcutta High Court (Extension of Jurisdiction) Act, 1953',
    'Calcutta High Court (Jurisdictional Limits) Act, 1919',
    'Calcutta Land-revenue Act, 1850',
    'Calcutta Land-revenue Act, 1856',
    'Calcutta Metro Railway (Operation and Maintenance) Temporary Provisions Act, 1985',
    'Calcutta Pilots Act, 1859',
    'Calcutta Port (Pilotage) Act, 1948',
    'Caltex [Acquisition of Shares of Caltex Oil Refining (India) Limited and of the Undertakings in India of Caltex (India) Limited] Act, 1977',
    'Cantonments (Extension of Rent Control Laws) Act, 1957',
    'Cantonments (House Accommodation) Act, 1923',
    'Cantonments Act, 1924',
    'Cantonments Act, 2006',
    'Capital of Punjab Development and Regulation (Chandigarh Amendment) Act, 1973',
    'Cardamom Act, 1965',
    'Carriage by Air Act, 1972',
    'Carriage by Air (Amendment) Act, 2016',
    'Carriage by Road Act, 2007',
    'Carriers Act, 1865',
    'Caste Disabilities Removal Act, 1850',
    'Cattle-trespass Act, 1871',
    'Census Act, 1948',
    'Central Agricultural University Act, 1992',
    'Central Boards of Revenue Act, 1963',
    'Central Educational Institutions (Reservation in Admission) Act, 1944',
    'Central Excise Tariff Act, 1985',
    'Central Excises (Conversion to Metric Units) Act, 1960',
    'Central Industrial Security Force (Amendment and Validation) Act, 1999',
    'Central Industrial Security Force Act, 1968',
    'Central Labour Laws (Extension to Jammu and Kashmir) Act, 1970',
    'Central Laws (Extension to Arunachal Pradesh) Act, 1993',
    'Central Laws (Extension to Jammu and Kashmir) Act, 1968',
    'Central Provinces Court of Wards Act, 1899',
    'Central Provinces Financial Commissioner\'s Act, 1908',
    'Central Provinces Land-revenue Act, 1881',
    'Central Provinces Laws Act, 1875',
    'Central Provinces Tenancy Act, 1898',
    'Central Road Fund Act, 2000',
    'Central Reserve Police Force Act, 1949',
    'Central Sales Tax (Amendment) Act, 1969',
    'Central Sales Tax Act, 1956',
    'Central Silk Board Act, 1948',
    'Central Universities Act, 2009',
    'Central Universities (Amendment) Act, 2014',
    'Central Vigilance Commission Act, 2003',
    'Cess and Other Taxes on Minerals (Validation) Act, 1992',
    'Chandernagore (Merger) Act, 1954',
    'Chandigarh (Delegation of Powers) Act, 1987',
    'Chandigarh Disturbed Areas Act, 1983',
    'Chaparmukh Silghat Railway Line and the Katakhal Lalabazar Railway Line (Nationalisation) Act, 1982',
    'Charitable Endowments Act, 1890',
    'Charitable and Religious Trusts Act, 1920',
    'Chartered Accountants Act, 1949',
    'Chemical Weapons Convention Act, 2000',
    'Child Labour (Prohibition and Regulation) Act, 1986',
    'Children (Pledging of Labour) Act, 1933',
    'Chit Funds Act, 1982',
    'Chota Nagpur Encumbered Estates Act, 1876',
    'Church of Scotland Kirk Sessions Act, 1899',
    'Cigarettes and Other Tobacco Products (Prohibition of Advertisement and Regulation of Trade and Commerce, Production, Supply and Distribution) Act, 2003',
    'Cinematograph Act, 1918',
    'Cinematograph Act, 1952',
    'Cine-Workers and Cinema Theatre Workers(Regulation of Employment) Act, 1981',
    'Cine-Workers Welfare Cess Act, 1981',
    'Cine-Workers Welfare Fund Act, 1981',
    'Citizenship Act, 1955',
    'City of Bombay Municipal (Supplementary) Act, 1888',
    'Civil Defence Act, 1968',
    'Civil Liability for Nuclear Damage Act, 2010',
    'Clinical Establishment (Registration and Regulation) Act, 2010',
    'Coal Bearing Areas (Acquisition and Development) Act, 1957',
    'Coal Bearing Areas (Acquisition and Development) Amendment and Validation Act, 1971',
    'Coal India (Regulation of Transfers and Validation) Act, 2000',
    'Coal Mines (Conservation and Development) Act, 1974',
    'Coal Mines (Nationalisation) Act, 1973',
    'Coal Mines Labour Welfare Fund (Repeal) Act, 1986',
    'Coal Mines Provident Fund and Miscellaneous Provisions Act, 1948',
    'Coal Mines (Taking Over of Management) Act, 1973',
    'Coastal Aquaculture Authority Act, 2005',
    'Coast Guard Act, 1978',
    'Coasting Vessels Act, 1838',
    'Coconut Development Board Act, 1979',
    'Code of Civil Procedure, 1908',
    'Code of Criminal Procedure, 1973',
    'Coffee Act, 1942',
    'Coinage Act, 2011',
    'Coir Industry Act, 1953',
    'Coking Coal Mines (Emergency Provisions) Act, 1971',
    'Coking Coal Mines (Nationalisation) Act, 1972',
    'Collection of Statistics Act, 1953',
    'Collection of Statistics Act, 2009',
    'Colonial Courts of Admiralty (India) Act, 1891',
    'Commanders-in-Chief (Change in Designation) Act, 1955',
    'Commercial Documents Evidence Act, 1939',
    'Commissions for Protection of Child Rights Act, 2005',
    'Commissions of Inquiry Act, 1952',
    'Commission of Sati (Prevention) Act, 1988',
    'Companies Act, 1956',
    'Companies Act, 2013',
    'Companies (Amendment) Act, 1988',
    'Companies (Donations to National Funds) Act, 1951',
    'Companies (Profits) Surtax Act, 1964',
    'Company Secretaries Act, 1980',
    'Competition Act, 2002',
    'Competition (Amendment) Act, 2007',
    'Comptroller and Auditor General\'s (Duties, Powers and Conditions of Service) Act, 1971',
    'Compulsory Deposit Scheme Act, 1963',
    'Conservation of Foreign Exchange and Prevention of Smuggling Activities Act, 1974',
    'Consumer Protection Act, 1986',
    'Constitution of India, 1950',
    'Contempt of Courts Act, 1971',
    'Contingency Fund of India Act, 1950',
    'Continuance of Legal Proceedings Act, 1948',
    'Contract Labour (Regulation and Abolition) Act, 1970',
    'Control of National Highways (Land and Traffic) Act, 2002',
    'Converts\' Marriage Dissolution Act, 1866',
    'Cooch-Behar (Assimilation of Laws) Act, 1950',
    'Co-operative Societies Act, 1912',
    'Copyright Act, 1957',
    'Coroners Act, 1871',
    'Cost and Works Accountants Act, 1959',
    'Cotton Copra and Vegetable Oils Cess (Abolition) Act, 1987',
    'Court-fees Act, 1870',
    'Court-fees (Delhi Amendment) Act, 1967',
    'Credit Information Companies (Regulation) Act, 2005',
    'Criminal and Election Laws Amendment Act, 1969',
    'Criminal Law Amendment Act, 1932',
    'Criminal Law Amendment Act, 1938',
    'Criminal Law Amendment Act, 1961',
    'Customs Act, 1962',
    'Customs (Amendment) Act, 1985',
    'Customs and Central Excise Laws (Repeal) Act, 2004',
    'Customs Duties and Cesses (Conversion to Metric Units) Act, 1960',
    'Customs Tariff Act, 1975',
    'Cutchi Memons Act, 1938',
    'Dadra and Nagar Haveli Act, 1961',
    'Dakshina Bharat Hindi Prachar Sabha Act, 1964',
    'Dalmia Dadri Cement Limited (Acquisition and Transfer ofUndertakings) Act, 1981',
    'Damodar Valley Corporation Act, 1948',
    'Dangerous Machines (Regulation) Act, 1983',
    'Decrees and Orders Validating Act, 1936',
    'Dekkhan Agriculturists Relief Act, 1879',
    'Delhi Agricultural Produce Marketing (Regulation) Act, 1976',
    'Delhi and Ajmer-MerwaraLand Development Act, 1948',
    'Delhi and Ajmer Rent Control Act, 1952',
    'Delhi and Ajmer Rent Control (Nasirabad Cantonment Repeal) Act, 1968',
    'Delhi Apartment Ownership Act, 1986',
    'Delhi Co-operative Societies Act, 1972',
    'Delhi Delegation of Powers Act, 1964',
    'Delhi Development Act, 1957',
    'Delhi Development Authority (Validation of DisciplinaryPowers) Act, 1999',
    'Delhi Fire Prevention and Fire Safety Act, 1986',
    'Delhi High Court Act, 1966',
    'Delhi High Court (Amendment) Act, 1980',
    'Delhi Hotels (Control of Accommodation) Act, 1949',
    'DelhiLand Holdings (Ceiling) Act, 1960',
    'Delhi Lands (Restriction on Transfer) Act, 1972',
    'Delhi Laws Act, 1912',
    'Delhi Laws (Special Provisions) Act, 2006',
    'Delhi Laws Act, 1915',
    'Delhi Metro Railways (Operation and Maintenance) Act, 2002',
    'Delhi Motor Vehicles Taxation Act, 1962',
    'Delhi Police Act, 1978',
    'Delhi Primary Education Act, 1960',
    'Delhi Rent Act, 1995',
    'Delhi Rent Control Act, 1958',
    'Delhi Restriction of Uses of Land Act, 1941',
    'Delhi Road Transport Laws (Amendment) Act, 1971',
    'Delhi Sales Tax Act, 1975',
    'Delhi Sales Tax (Amendment and Validation) Act, 1976',
    'Delhi School Education Act, 1973',
    'Delhi Sikh Gurdwaras Act, 1971',
    'Delhi Special Police Establishment Act, 1946',
    'DelhiUniversity Act, 1922',
    'Delhi (Urban Areas) Tenants\' Relief Act, 1961',
    'Delhi Urban Art Commission Act, 1973',
    'Delimitation Act, 2002',
    'Delivery of Books and Newspapers (Public Libraries) Act, 1954',
    'Dentists Act, 1948',
    'Departmental Inquiries (Enforcement of Attendance of Witnesses and Production of Documents) Act, 1972',
    'Departmentalisation of Union Accounts (Transfer of Personnel) Act, 1976',
    'Deposit Insurance and Credit Guarantee Corporation Act, 1961',
    'Deposit Insurance Corporation (Amendment and Miscellaneous Provisions) Act, 1978',
    'Depositories Act, 1996',
    'Designs Act, 2000',
    'Destruction of Records Act, 1917',
    'Destructive Insects and Pests Act, 1914',
    'Destructive Insects and Pests (Amendment and Validation) Act, 1992',
    'Diplomatic and Consular Officers (Oaths and Fees) Act, 1948',
    'Diplomatic and Consular Officers (Oaths and Fees) (Extension to Jammu And Kashmir) Act, 1973',
    'Diplomatic Relations (Vienna Convention) Act, 1972',
    'Direct Tax Laws (Amendment) Act, 1989',
    'Direct-Tax Laws (Miscellaneous) Repeal Act, 2000',
    'Disaster Management Act, 2005',
    'Displaced Persons (Debts Adjustment) Act, 1951',
    'Disputed Elections (Prime Minister and Speaker)Act, 1977',
    'Dissolution of Muslim Marriages Act, 1939',
    'Disturbed Areas (Special Courts) Act, 1976',
    'Divorce Act, 1869',
    'Dock Workers (Regulation of Employment) Act, 1948',
    'Dock Workers (Regulation of Employment Inapplicability to Major Ports) Act, 1997',
    'Dock Workers, (Safety, Health and Welfare) Act, 1986',
    'Dourine Act, 1910',
    'Dowry Prohibition Act, 1961',
    'Dramatic Performances Act, 1876',
    'Drugs and Cosmetics Act, 1940',
    'Drugs and Magic Remedies (Objectionable Advertisements) Act, 1954',
    'Drugs (Control) Act, 1950',
    'Durgah Khawaja Saheb Act, 1955',
    'Easements (Extending Act 5 of 1882),1891',
    'East-Punjab Urban Rent Restriction (Extension to Chandigarh) Act, 1974',
    'Economic Offences (Inapplicability of Limitation) Act, 1974',
    'Election Commission (Conditions of Service of Election Commissioners and Transaction of Business) Act, 1991',
    'Election Laws (Extension to Sikkim) Act, 1976',
    'Elephants\' Preservation Act, 1879',
    'Emblems and Names (Prevention of Improper Use) Act, 1950',
    'Emigration Act, 1983',
    'Employees\' Provident Funds and Miscellaneous Provisions Act, 1952',
    'Employees\' Provident Funds and Miscellaneous Provisions (Amendment) Act, 1988',
    'Employees\' State Insurance Act, 1948',
    'Employees\' State Insurance (Amendment) Act, 1989',
    'Employers\' Liability Act, 1938',
    'Employment Exchanges (Compulsory Notification of Vacancies) Act, 1959',
    'Employment of Manual Scavengers and Construction of Dry Latrines (Prohibition) Act, 1993',
    'Enemy Property Act, 1968',
    'English and Foreign Languages University Act, 2007',
    'Energy Conservation Act, 2001',
    'Environment (Protection) Act, 1986',
    'Epidemic Diseases Act, 1897',
    'Equal Remuneration Act, 1976',
    'Essential Commodities Act, 1955',
    'Essential Commodities (Amendment and Validation) Act, 2009',
    'Essential Services Maintenance (Assam) Act, 1980',
    'Esso (Acquisition of Undertakings in India) Act, 1974',
    'Exchange of Prisoners Act, 1948',
    'Excise (Malt Liquors) Act, 1890',
    'Excise (Spirits) Act, 1863',
    'Expenditure-tax Act, 1987',
    'Explosive Substances Act, 1908',
    'Explosives Act, 1884',
    'Export-Import Bank of India Act, 1981',
    'Export (Quality Control and Inspection) Act, 1963',
    'Extradition Act, 1962',
    'Factories Act, 1948',
    'Factoring Act, 2012',
    'Family Courts Act, 1984',
    'Faridabad Development Corporation Act, 1956',
    'Fatal Accidents Act, 1855',
    'Finance Commission (Miscellaneous Provisions) Act, 1951',
    'Fiscal Responsibility and Budget Management Act, 2003',
    'Food Corporations Act, 1964',
    'Food Safety and Standards Act, 2006',
    'Foreign Aircraft (Exemption from Taxes and Duties on Fuel and Lubricants) Act, 2002',
    'Foreign Contribution (Regulation) Act, 2010',
    'Foreign Exchange Management Act, 1999',
    'Foreign Jurisdiction Act, 1947',
    'Foreign Marriage Act, 1969',
    'Foreign Recruiting Act, 1874',
    'Foreign Trade (Development and Regulation) Act, 1992',
    'Foreigners Act, 1946',
    'Foreigners Law (Application and Amendment) Act, 1962',
    'Forest (Conservation) Act, 1980',
    'Forfeited Deposits Act, 1850',
    'Former Secretary of State Service Officers (Conditions ofService) Act, 1972',
    'Fort William Act, 1881',
    'Forward Contracts (Regulation) Act, 1952',
    'Futwah-Islampur Light Railway Line (Nationalisation) Act, 1985',
    'Ganesh Flour Mills Company Limited (Acquisitionand Transfer of Undertakings) Act, 1984',
    'Ganges Tolls Act, 1867',
    'General Clauses Act, 1897',
    'General Insurance Business (Nationalisation) Act, 1972',
    'General Insurance Business (Nationalisation) Amendment Act, 1985',
    'Geneva Conventions Act, 1960',
    'Geographical Indications of Goods (Registration and Protection) Act, 1999',
    'Gift-tax Act, 1958',
    'Glanders and Farcy Act, 1899',
    'Gram Nyayalayas Act, 2009',
    'Goa Arbitration Act) Act, 1965',
    'Goa Mining Leases) Act, ',
    'Gold Bonds (Immunities and Exemptions) Act, 1993',
    'Central Goods and Services Act, 2017',
    'Central Goods and Services Tax (Extension to Jammu and Kashmir) Act, 2017',
    'State Goods and Services Tax Act, 2017',
    'Union Territory Goods and Services Tax Act, 2017',
    'Integrated Goods and Services Tax Act, 2017',
    'Integrated Goods and Services Tax (Extension to Jammu and Kashmir Act, 2017',
    'Goods and Services Tax (Compensation to States) Act, 2017',
    'Government Buildings Act, 1899',
    'Government Grants Act, 1895',
    'Government Management of Private Estates Act, 1892',
    'Government of National Capital Territory of Delhi Act, 1991',
    'Government of Union Territories Act, 1963',
    'Government Savings Banks Act, 1873',
    'Government Savings Certificates Act, 1959',
    'Government Seal Act, 1862',
    'Governent Securities Act, 2006',
    'Governor\'s (Emoulments, Allowances and Priveleges) Act, 1982',
    'Gresham and Craven of India (Private) Limited (Acquisition and Transfer of Undertakings) Act, 1977',
    'Guardians and Wards Act, 1890',
    'Hackney-carriage Act, 1879',
    'Haj Committee Act, 2002',
    'Handlooms (Reservation of Articles for Production) Act, 1985',
    'Haryana and Punjab Agricultural Universities Act, 1970',
    'Haryana and Uttar Pradesh (Alteration of Boundaries) Act, 1979',
    'High Court and Bombay (Extension of Jurisdiction to Goa Diu) Act, 1981',
    'High Court at Patna (Establishment of a Permanent Bench at Ranchi) Act, 1976',
    'High Court Judges (Salaries and Conditions of Service) Act, 1954',
    'High Courts (Seals) Act, 1950',
    'High Denomination Bank Notes (Demonetisation) Act, 1978',
    'Himachal Pradesh and Bilaspur (New State) Act, 1954',
    'Himachal Pradesh Legislative Assembly (Constitution and Proceedings) Validation Act, 1958',
    'Hind Cycles Limited and Sen-Raleigh Limited (Nationalisation) Act, 1980',
    'Hindi Sahitya Sammelan Act, 1962',
    'Hindu Adoptions and Maintenance Act, 1956',
    'Hindu Disposition of Property Act, 1916',
    'Hindu Gains of Learning Act, 1930',
    'Hindu Inheritance (Removal of Disabilities) Act, 1928',
    'Hindu Marriage Act, 1955',
    'Hindu Marriages (Validation of Proceedings) Act, 1960',
    'Hindu Minority and Guardianship Act, 1956',
    'Hindu Succession Act, 1956',
    'Hindustan Tractors Limited (Acquisition and Transfer of Undertakings) Act, 1978',
    'Homoeopathy Central Council Act, 1973',
    'Hooghly Docking and Engineering Company Limited (Acquisition and Transfer of Undertakings) Act, 1984',
    'Hotel-Receipts Tax Act, 1980',
    'Howrah Offences Act, 1857',
    'Identification of Prisoners Act, 1920',
    'Illegal Migrants (Determination by Tribunals) Act, 1983',
    'Immigration (Carriers Liability) Act, 2000',
    'Immigrants (Expulsion from Assam) Act, 1950',
    'Immoral Traffic (Prevention) Act, 1956',
    'Imperial Library (Change of Name) Act, 1948',
    'Improvements in Towns, 1850',
    'Inchek Tyres Limited and National Rubber Manufacturers Limited (Nationalisation) Act, 1984',
    'Income-tax Act, 1961',
    'Indecent Representation of Women (Prohibition) Act, 1986',
    'Indian Bar Councils Act, 1926',
    'Indian Bills of Lading Act, 1856',
    'Indian Boilers Act, 1923',
    'Indian Carriage of Goods by Sea Act, 1925',
    'Indian Christian Marriage Act, 1872',
    'Indian Council of World Affairs Act, 2001',
    'Indian Copper Corporation (Acquisition of Undertaking) Act, 1972',
    'Indian Criminal Law Amendment Act, 1908',
    'Easements Act, 1882',
    'Evidence Act, 1872',
    'Fisheries Act, 1897',
    'Forest Act, 1927',
    'Indian Independence Pakistan Courts (Pending Proceedings)Act, 1952',
    'Indian Iron and Steel Company (Acquisition of Shares) Act, 1976',
    'Law Reports Act, 1875',
    'Maritime University Act, 2008',
    'Matrimonial Causes (War Marriages) Act, 1948',
    'Medical Council Act, 1956',
    'Medical Degrees Act, 1916',
    'Medicine Central Council Act, 1970',
    'Museum Act, 1910',
    'Naval Armament Act, 1923',
    'Nursing Council Act, 1947',
    'Partnership Act, 1932',
    'Penal Code,1860',
    'Ports Act, 1908',
    'Post Office Act, 1898',
    'Railway Board Act, 1905',
    'Red Cross Society Act, 1920',
    'Reserve Forces Act, 1888',
    'Short Titles Act, 1897',
    'Soldiers (Litigation) Act, 1925',
    'Stamp Act, 1899',
    'Statistical Institute Act, 1959',
    'Succession Act, 1925',
    'Telegraph Act, 1885',
    'Tolls Act, 1851',
    'Tolls Act, 1888',
    'Tolls Act, 1864',
    'Tolls (Army and Air Force) Act, 1901',
    'Tramways Act, 1886',
    'Tramways Act, 1902',
    'Treasure-trove Act, 1878',
    'Trusts Act, 1882',
    'Veterinary Council Act, 1984',
    'Wireless Telegraphy Act, 1933',
    'Rifles (Repeal) Act, 2006',
    'Indira Gandhi National Open University Act, 1985',
    'Indira Gandhi National Tribal University Act, 2007',
    'Indo-Tibetan Border Police Force Act, 1992',
    'Industrial Development Bank (Transfer of Undertaking and Repeal) Act, 2003',
    'Industrial Disputes Act, 1947',
    'Industrial Disputes (Amendment and Miscellaneous Provisions) Act, 1956',
    'Industrial Disputes (Banking and Insurance Companies) Act, 1949',
    'Industrial Employment (Standing Orders) Act, 1946',
    'Industrial Finance Corporation (Transfer of Undertaking and Repeal) Act, 1993',
    'Industrial Reconstruction Bank (Transfer of Undertaking andRepeal) Act, 1997',
    'Industrial Reconstruction Bank of India Act, 1984',
    'Industries (Development and Regulation) Act, 1951',
    'Infant Milk Substitutes, Feeding Bottles and Infant Foods (Regulation of Production, Supply and Distribution) Act, 1992',
    'Inflammable Substances Act, 1952',
    'Information Technology Act, 2000',
    'Inland Vessels Act, 1917',
    'Inland Waterways Authority of India Act, 1985',
    'Insecticides Act, 1968',
    'Institutes of Technology Act, 1961',
    'Insurance Act, 1938',
    'Insurance Regulatory and Development Authority Act, 1999',
    'Intelligence Organisations (Restriction on Rights) Act, 1985',
    'Interest Act, 1978',
    'Interest Tax Act, 1974',
    'International Development Association (Status, Immunities and Privileges) Act, 1960',
    'International Finance Corporation (Status, Immunities and Privileges) Act, 1958',
    'International Monetary Fund and Bank Act, 1945',
    'Inter-State Corporation Act, 1957',
    'Inter-State Migrant Workmen (Regulation of Employment and Conditions of Service) Act, 1979',
    'Inter-StateRiver Water Disputes Act, 1956',
    'Iron Ore Mines Manganese Ore Mines and Chrome Ore Mines Labour Welfare Cess Act, 1976',
    'Iron Ore Mines Manganese Ore Mines and Chrome Ore Mines Labour Welfare Fund Act, 1976',
    'Indian Maritime University Act, 2008',
    'Jallianwala Bagh National Memorial Act, 1951',
    'Jamia Millia Islamia Act, 1988',
    'Jammu and Kashmir (Extension of Laws) Act, 1956',
    'Jammu and Kashmir Representation of the People (Supplementary) Act, 1968',
    'Jawaharlal Institute of Post Graduate Medical Education and Research Puducherry Act, 2008',
    'Jawaharlal Nehru University Act, 1966',
    'Jayanti Shipping Company (Acquisition of Shares) Act, 1971',
    'Jharkhand Contingency Fund (Amendment) Act, 2009',
    'Jubbalpore and Chhattisgarh Divisions (Divorce Proceedings Validation) Act, 1935',
    'Judges (Inquiry) Act, 1968',
    'Judges (Protection) Act, 1985',
    'Judicial Officers Protection Act, 1850',
    'Junagarh Administration (Property) Act, 1948',
    'Jute Companies (Nationalisation) Act, 1980',
    'Jute Manufactures Cess Act, 1983',
    'Jute Manufacturers Development Council Act, 1983',
    'Jute Packaging Materials (Compulsory Use in Packing Commodities) Act, 1987',
    'Juvenile Justice (Care and Protection of Children) Act, 2000',
    'Kalakshetra Foundation Act, 1993',
    'Kazis Act, 1880',
    'Khaddar (Protection of Name) Act, 1950',
    'Khadi and Village Industries Commission Act, 1956',
    'Khuda Bakhsh Oriental Public Library Act, 1969',
    'King of Oudh\'s Estate Act, 1887',
    'King of Oudh\'s Estate Act, 1888',
    'King of Oudh\'s Estate Validation Act, 1917',
    'Konkan Passenger Ships (Acquisition) Act, 1973',
    'Kosangas Company (Acquisition of Undertakings) Act, 1979',
    'Labour Laws (Exemption from Furnishing Returns and Maintaining Registers by certain Establishments) Act, 1988',
    'Lady Hardinge Medical College and Hospital (Acquisition andMiscellaneous Provisions) Act, 1977',
    'Lalitkala Akademi (Taking Over of Management) Act, 1997',
    'Land Acquisition Act, 1894',
    'Land Acquisition (Amendment) Act, 1962',
    'Land Acquisition (Amendment and Validation) Act, 1967',
    'Land Acquisition (Mines) Act, 1885',
    'Land Improvement Loans Act, 1883',
    'Land Port Authority of India Act, 2010',
    'Laws Local Extent Act, 1874',
    'Laxmirattan Cotton Mills Company Limited (Taking Over of Management) Act, 1976',
    'Leaders of Chief Whips of Recognised Parties and Groups in Parliament (Facilities) Act, 1999',
    'Legal Metrology Act, 2010',
    'Legal Practitioners Act, 1879',
    'Legal Practitioners (Fees) Act, 1926',
    'Legal Practitioners (Women) Act, 1923',
    'Legal Representatives\' Suits Act, 1855',
    'Legal Services Authorities Act, 1987',
    'Legal Tender (Inscribed Notes) Act, 1964',
    'Legislative Assembly of Nagaland (Change inRepresentation) Act, 1968',
    'Legislative Councils Act, 1957',
    'Lepers Act, 1898',
    'Levy Sugar Price Equalisation Fund Act, 1976',
    'Life Insurance Corporation Act, 1956',
    'Life Insurance Corporation (Modification of Settlement) Act, 1976',
    'Light House Act, 1927',
    'Limestone and Dolomite Mines Labour Welfare Fund Act, 1972',
    'Limited Liability Partnership Act, 2009',
    'Limitation Act, 1963',
    'Live-stock Importation Act, 1898',
    'Lokpal and Lokayuktas Act, 2014',
    'Local Authorities Loans Act, 1914',
    'Local Authorities Pensions and Gratuities Act, 1919',
    'Lok Sahayak Sena Act, 1956',
    'Lotteries (Regulation) Act, 1998',
    'Lushai Hills District (Change of Name) Act, 1954',
    'Madhya Pradesh Reorganisation Act, 2000',
    'Madras City Civil Court Act, 1892',
    'Madras City Land Revenue Act, 1851',
    'Madras Civil Courts Act, 1873',
    'Madras Compulsory Labour Act, 1858',
    'Madras District Police Act, 1859',
    'Madras Forest (Validation) Act, 1882',
    'Madras Public Property Malversation Act, 1837',
    'Madras Rent and Revenue Sales Act, 1839',
    'Madras Revenue Commissioner Act, 1849',
    'Madras Uncovenated Officers\' Act, 1857',
    'Mahatama Gandhi Antarashtriya Hindi Vishwavidyalaya Act, 1996',
    'Mahendra Pratap Singh Estate (Repeal) Act, 1960',
    'Maintenance and Welfare of Parents and Senior Citizens Act, 2007',
    'Maintenance Orders Enforcement Act, 1921',
    'Major Port Trusts Act, 1963',
    'Majority Act, 1875',
    'Mangrol and Manavadar (Administration of Property) Act, 1949',
    'Manipur and Tripura (Repeal of Laws) Act, 1958',
    'Manipur (Courts) Act, 1955',
    'Manipur (Hill Areas District Council) Act, 1971',
    'ManipurLand Revenue and Land Reforms Act, 1960',
    'Manipur Municipalities Act, 1994',
    'Manipur Panchayati Raj Act, 1994',
    'Manipur (Sales of Motor Spirit and Lubricants) Taxation Act, 1962',
    'Manipur University Act, 2005',
    'Manipur (Village Authorities in Hill Areas) Act, 1956',
    'Marine Insurance Act, 1963',
    'Marine Products Export Development Authority Act, 1972',
    'Maritime Zones of India (Regulation of Fishing by ForeignVessels) Act, 1981',
    'Marking of Heavy Packages Act, 1951',
    'Marriages Validation Act, 1892',
    'Married Women\'s Property Act, 1874',
    'Married Women\'s Property (Extension) Act, 1959',
    'Maruti Limited (Acquisition and Transfer of Undertakings)Act, 1980',
    'Maternity Benefit Act, 1961',
    'Maulana Azad National Urdu University Act, 1996',
    'Medical Termination of Pregnancy Act, 1971',
    'Medicinal and Toilet Preparations (Excise Duties) Act, 1955',
    'Mental Health Act, 1987',
    'Merchant Shipping Act, 1958',
    'Merchant Shipping (Amendment) Act, 1986',
    'Merchant Shipping (Amendment) Act, 1987',
    'Merged States (Laws) Act, 1949',
    'Metal Corporation (Nationalisation and Miscellaneous Provisions) Act, 1976',
    'Metal Tokens Act, 1889',
    'Metro Railways (Construction of Works) Act, 1978',
    'Mica Mines Labour Welfare Fund Act, 1946',
    'Mines Act, 1952',
    'Mines and Minerals (Development and Regulation ) Act, 1957',
    'Minimum Wages Act, 1948',
    'Mirzapur Stone Mahal Act, 1886',
    'Miscellaneous Personal Laws (Extension) Act, 1959',
    'Mizoram University Act, 2000',
    'Mogul Line Limited (Acquisition of Shares) Act, 1984',
    'Monopolies and Restrictive Trade Practices Act, 1969',
    'Motor Transport Workers Act, 1961',
    'Motor Vehicles Act, 1988',
    'Multimodal Transportation of Goods Act, 1993',
    'Multi-State Co-operative Societies Act, 2002',
    'Municipal Taxation Act, 1881',
    'Murshidabad Act, 1891',
    'Murshidabad Estate Administration Act, 1933',
    'Muslim Personal Law (Shariat) Application Act, 1937',
    'Muslim Women (Protection of Rights on Divorce) Act, 1986',
    'Mussalman Wakf Act, 1923',
    'Mussalman Wakf Validating Act, 1913',
    'Mussalman Wakf Validating Act, 1930',
    'Naga Hills-Tuensang Area Act, 1957',
    'NagalandUniversity Act, 1989',
    'Nalanda University Act, 2010',
    'Narcotic Drugs and Psychotropic Substances Act, 1985',
    'National Bank for Agriculture and Rural Development Act, 1981',
    'National Cadet Corps Act, 1948',
    'National Capital Territory of Delhi Laws (Special Provisions) Act, 2007',
    'National Capital Territory of Delhi Laws (Special Provisions) Act, 2011',
    'National Capital Territory of Delhi Laws (Special Provisions) Second Act, 2017',
    'National Capital Region Planning Board Act, 1985',
    'National Commission for Backward Classes Act, 1993',
    'National Commission for Minorities Act, 1992',
    'National Commission for Minority Educational Institutions Act, 2004',
    'National Commission for Safai Karamcharis Act, 1993',
    'National Commission for Women Act, 1990',
    'National Company (Acquisition and Transfer of Undertakings) Act, 1980',
    'National Co-operative Development Corporation Act, 1962',
    'National Council for Teacher Education Act, 1993',
    'National Dairy Development Board Act, 1987',
    'National Environment Appellate Authority Act, 1997',
    'National Environment Tribunal Act, 1995',
    'National Food Security Act, 2013',
    'National Green Tribunal Act, 2010',
    'National Highways Act, 1956',
    'National Highways Authority of India Act, 1988',
    'National Housing Bank Act, 1987',
    'National Institute of Fashion Technology Act, 2006',
    'National Institute of Pharmaceutical Education and Research Act, 1998',
    'National Investigation Agency Act, 2008',
    'National Jute Board Act, 2008',
    'National Library of India Act, 1976',
    'National Oil Seeds and Vegetable Oils Development Board Act, 1983',
    'National Rural Employment Guarantee Act, 2005',
    'National Rural Employment Guarantee (Amendment) Act, 2009',
    'National Rural Employment Guarantee (Extension to Jammu And Kashmir) Act, 2007',
    'National Institute of Design Act, 2014',
    'National Institutes of Technology Act, 2007',
    'National Institute of Mental Health and Neuro-Sciences Bangalore Act, 2012',
    'National Security Act, 1980',
    'National Security Guard Act, 1986',
    'National Service Act, 1972',
    'National Tax Tribunal Act, 2005',
    'National Thermal Power Corporation Limited, the National Hydroelectric Power Corporation Limited and the North-Eastern Electric Power Corporation Limited (Acquisition and Transfer of Power Transmission Systems) Act, 1993',
    'National Trust for Welfare of Persons with Autism, Cerebral Palsy, Mental Retardation and Multiple Disabilities Act, 1999',
    'National Waterway (Allahabad-Haldia Stretch of the Ganga-Bhagirathi-Hooghly River) Act, 1982',
    'National Waterway (Kollam-Kottapuram Stretch of West Coast Canal and Champakara and Udyogmandal Canals) Act, 1992',
    'National Waterway (Sadiya-Dhubri Stretch of the BrahmaputraRiver) Act, 1988',
    'National Waterway (Talcher-Dhamra Stretch of Rivers, Geonkhali-Charbatia Stretch of East Coast Canal, Charbatia Dhamra Stretch of Matai River and Mahanadi Delta Rivers) Act, 2008',
    'National Waterway (Kakinadi-Puducherry Stretch of Canals and the Kaluvelly Tank Vijayawada Stretch of River Krishna) Act, 2008',
    'Naval and Aircraft Prize Act, 1971',
    'Navy Act, 1957',
    'National Capital Territory of Delhi Laws (Special Provisions) Act, 2011',
    'National Capital Territory of Delhi Laws (Special Provisions)Second Act, 2011',
    'Negotiable Instruments Act, 1881',
    'New Delhi Municipal Council Act, 1994',
    'Newspaper (Price and Page) Act, 1956',
    'Newspaper (Price and Page Continuance) Act, 1961',
    'Neyveli Lignite Corporation Limited (Acquisition andTransfer of Power Transmission System) Act, 1994',
    'North-Eastern Area (Reorganisation) Act, 1971',
    'North-Eastern Council Act, 1971',
    'North-Eastern Hill University Act, 1973',
    'North-Eastern Provinces Village and Road Police Act, 1873',
    'Northern India Canal and Drainage Act, 1873',
    'Northern India Ferries Act, 1878',
    'Notaries Act, 1952',
    'Oaths Act, 1969',
    'Obstructions in Fairways Act, 1881',
    'Official Languages Act, 1963',
    'Official Secrets Act, 1923',
    'Official Trustees Act, 1913',
    'Offshore Areas Minerals (Development and Regulation) Act, 2003',
    'Oil and Natural Gas Commission (Transfer of Undertaking and Repeal) Act, 1993',
    'Oil Fields (Regulation and Development) Act, 1948',
    'Oil Industry (Development) Act, 1974',
    'Opium and Revenue Laws (Extension of Application) Act, 1950',
    'Oriental Gas Company Act, 1857',
    'Orissa Weights and Measures (Delhi Repeal) Act, 1958',
    'Orphanages and Other Charitable Homes (Supervision andControl) Act, 1960',
    'Orissa (Alteration of Name) Act, 2011',
    'Oudh Estates Act, 1869',
    'Oudh Laws Act, 1876',
    'Oudh Sub-settlement Act, 1866',
    'Oudh Taluqdars\' Relief Act, 1870',
    'Oudh Wasikas Act, 1886',
    'Parel Investment and Trading Private Limited and Domestic Gas Private Limited (Taking Over of Management) Repeal Act, 2005',
    'Parliament (Prevention of Disqualification) Act, 1959',
    'Parliamentary Proceedings (Protection of Publication) Act, 1977',
    'Parsi Marriage and Divorce Act, 1936',
    'Part B States (Laws) Act, 1951',
    'Part B States Marriages Validating Act, 1952',
    'Part C States Miscellaneous Laws (Repealing) Act, 1951',
    'Parliamentary Proceedings (Protection of Publication) Repeal Act, 1976',
    'Partition Act, 1893',
    'Partition of Revenue-paying Estates Act, 1863,Repealed',
    'Passport (Entry into India) Act, 1920',
    'Passports Act, 1967',
    'Patents Act, 1970',
    'Payment and Settlement Systems Act, 2007',
    'Payment of Bonus Act, 1965',
    'Payment of Gratuity Act, 1972',
    'Payment of Gratuity (Amendment) Act, 1987',
    'Payment of Wages Act, 1936',
    'Pensions Act, 1871',
    'Pension Fund Regulatory and Development Authority Act, 2013',
    'Personal Injuries (Compensation Insurance) Act, 1963',
    'Persons With Disabilities (Equal Opportunities Participation) Act, 1995',
    'Petroleum Act, 1934',
    'Petroleum and Minerals Pipelines (Acquisition of Right ofUser in Land) Act, 1962',
    'Petroleum and Natural Gas Regulatory Board Act, 2006',
    'Pharmacy Act, 1948',
    'Places of Worship (Special Provisions) Act, 1991',
    'Plantations Labour Act, 1951',
    'Poisons Act, 1919',
    'Police Act, 1861',
    'Police Act, 1888',
    'Police Act, 1949',
    'Police Forces (Restriction of Rights) Act, 1966',
    'Police (Incitement to Disaffection) Act, 1922',
    'Pondicherry (Administration) Act, 1962',
    'Pondicherry (Extension of Laws) Act, 1968',
    'Pondicherry University Act, 1985',
    'Pondichery (Alteration of Name) Act, 2006',
    'Porahat Estate Act, 1893',
    'Post-Graduate Institute of Medical Education and Research, Chandigarh Act, 1966',
    'Post Office Cash Certificates Act, 1917',
    'Powers-of-Attorney Act, 1882',
    'Prasar Bharati (Broadcasting Corporation of India) Act, 1990',
    'Preference Shares (Regulation of Dividend) Act, 1960',
    'Pre-conception and Pre-natal Diagnostic Techniques (Prohibition of Sex Selection) Act, 1994',
    'Presidency Small Cause Courts Act, 1882',
    'Presidency-towns Insolvency Act, 1909',
    'President (Discharge of Functions) Act, 1969',
    'Presidential and Vice-Presidential Elections Act, 1952',
    'President\'s Emoluments and Pension Act, 1951',
    'Press and Registration of Books Act, 1867',
    'Press Council Act, 1978',
    'Protection of Children From Sexual Offences Act, 2012',
    'Prevention and Control of Infections and Contagious Diseases in Animals Act, 2009',
    'Prevention of Blackmarketing and Maintenance ofSupplies of Essential Commodities Act, 1980',
    'Prevention of Corruption Act, 1988',
    'Prevention of Cruelty to Animals Act, 1960',
    'Prevention of Damage to Public Property Act, 1984',
    'Prevention of Food Adulteration Act, 1954',
    'Prevention of Money Laundering Act, 2002',
    'Prevention of Illicit Traffic in Narcotic Drugs and Psychotropic Substances Act, 1988',
    'Prevention of Insults to National Honour Act, 1971',
    'Prevention of Seditious Meetings Act, 1911',
    'Prevention of Terrorism (Repeal) Act, 2004',
    'Prisoners Act, 1900',
    'Prisoners (Attendance in Courts) Act, 1955',
    'Prisons Act, 1894',
    'Private Security Agencies (Regulation) Act, 2005',
    'Prize Chits and Money Circulation Scheme (Banning) Act, 1978',
    'Prize Competitions Act, 1955',
    'Probation of Offenders Act, 1958',
    'Produce Cess Laws (Abolition) Action,2006',
    'Professions Tax Limitation (Amendment and Validation) Act, 1949',
    'Prohibition of Child Marriage Act, 2007',
    'Prohibition of Employment as Manual Scavengers and Their Rehabilitation Act, 2013',
    'Protection of Civil Rights Act, 1955',
    'Protection of Human Rights Act, 1993',
    'Protection of Plant Varieties and Farmers Right Act, 2001',
    'Protection of Women from Domestic Violence Act, 2005',
    'Provident Funds Act, 1925',
    'Provincial Insolvency Act, 1920',
    'Provincial Small Cause Courts Act, 1887',
    'Provisional Collection of Taxes Act, 1931',
    'Provisions of the Panchayats (Extension to the ScheduledAreas) Act, 1996',
    'Public Accountants\' Defaults Act, 1850',
    'Public Debt Act, 1944',
    'Public Employment (Requirement as to Residence) Act, 1957',
    'Public Financial Institutions (Obligation as to Fidelityand Secrecy) Act, 1983',
    'Public Gambling Act, 1867',
    'Public Liability Insurance Act, 1991',
    'Public Premises (Eviction of Unauthorised Occupants) Act, 1971',
    'Public Provident Fund Act, 1968',
    'Public Records Act, 1993',
    'Public Sector Iron and Steel Companies(Restructuring) and Miscellaneous Provisions Act, 1978',
    'Public Servants (Inquiries) Act, 1850',
    'Public Suits Validation Act, 1932',
    'Public Wakfs (Extension of Limitation) Act, 1959',
    'Punjab District Boards Act, 1883',
    'Punjab Disturbed Areas Act, 1983',
    'Punjab Excise (Delhi Amendment) Act, 1979',
    'Punjab Gram Panchayat Act, 1952',
    'Punjab Land Revenue Act, 1887',
    'Punjab Laws Act, 1872',
    'Punjab Legislative Council (Abolition) Act, 1969',
    'Punjab Municipal Corporation Law (Extension to Chandigarh) Act, 1994',
    'Punjab Reorganisation Act, 1966',
    'Punjab State Legislature (Delegation of Powers) Act, 1984',
    'Punjab Tenancy Act, 1887',
    'Railway Claims Tribunal Act, 1987',
    'Railway Companies (Emergency Provisions) Act, 1951',
    'Railway Property (Unlawful Possession) Act, 1966',
    'Railway Protection Force Act, 1957',
    'Railway Protection Force (Amendment) Act, 1985',
    'Railways Act, 1989',
    'Railways Employment of Members of the Armed Forces Act, 1965',
    'Railways (Local Authorities\' Taxation) Act, 1941',
    'Raipur and Khattra Laws Act, 1879',
    'Rajasthan and Madhya Pradesh (Transfer of Territories) Act, 1959',
    'Rajghat Samadhi Act, 1951',
    'Rajiv Gandhi Institute of Petroleum Technology Act, 2007',
    'Rajiv Gandhi University Act, 2007',
    'Rajiv Gandhi National Institutes of Youth DevelopmentAct, 2012',
    'Rajiv Gandhi National Aviation University Act, 2013',
    'Rampur Raza Library Act, 1975',
    'Rani Lakshmi Bai Central Agricultural University Act, 2014',
    'Reciprocity Act, 1943',
    'Recovery of Debts Due to Banks and Financial Institutions Act, 1993',
    'Red Cross Society (Allocation of Property) Act, 1936',
    'Reformatory Schools Act, 1897',
    'Regional Rural Banks Act, 1976',
    'Registration Act, 1908',
    'Registration of Births and Deaths Act, 1969',
    'Registration of Foreigners Act, 1939',
    'Rehabilitation Council of India Act, 1992',
    'Rehabilitation Finance Administration Act, 1948',
    'Religious Endowments Act, 1863',
    'Religious Institutions (Prevention of Misuse) Act, 1988',
    'Religious Societies Act, 1880',
    'Remittances of Foreign Exchange and Investment in Foreign Exchange Bonds (Immunities and Exemptions) Act, 1991',
    'Rent Recovery Act, 1853',
    'Repatriation of Prisoners Act, 2003',
    'Representation of the People Act, 1950',
    'Representation of the People (Amendment) Act, 1989',
    'Representation of the People Act, 1951',
    'Representation of People (Amendment) Act, 2010',
    'Representation of the People (Miscellaneous Provisions) Act, 1956',
    'Requisitioned Land (Apportionment of Compensation) Act, 1949',
    'Requisitioning and Acquisition of Immovable Property Act, 1952',
    'Research and Development Cess Act, 1986',
    'Reserve Bank (Transfer of Public Ownership) Act, 1948',
    'Reserve Bank of India Act, 1934',
    'Reserve Bank of India (Amendment and Miscellaneous Provisions) Act, 1953',
    'Reserve and Auxiliary Air Forces Act, 1952',
    'Resettlement of Displaced Persons (Land Acquisition) Act, 1948',
    'Revenue Recovery Act, 1890',
    'Richardson and Cruddas Limited (Acquisition and Transfer of Undertakings) Act, 1972',
    'Right of Children to Free and Compulsory Education Act, 2009',
    'Right to Information Act, 2005',
    'Right to Fair Compensation and Transparency in Land Acquisition, Rehabilitation and Resettlement Act, 2013',
    'River Boards Act, 1956',
    'Road Transport Corporations Act, 1950',
    'Rubber Act, 1947',
    'SAARC Convention (Suppression of Terrorism) Act, 1993',
    'Salar Jung Museum Act, 1961',
    'Salaries and Allowances of Ministers Act, 1952',
    'Salaries and Allowances of Ministers (Amendment) Act, 2010',
    'Salaries and Allowances of Officers of Parliament Act, 1953',
    'Salary and Allowances of Leaders of Opposition in Parliament Act, 1977',
    'Sale of Goods Act, 1930',
    'Sales of Land for Revenue Arrears Act, 1845,repealed',
    'Sales Promotion Employees (Conditions of Service) Act, 1976',
    'Sales Tax Laws Validation Act, 1956',
    'Salt Cess Act, 1953',
    'Sarais Act, 1867',
    'Sashatra Seema Bal Act, 2007',
    'Scheduled Areas (Assimilation of Laws) Act, 1951',
    'Scheduled Areas (Assimilation of Laws) Act, 1953',
    'Scheduled Castes and Scheduled Tribes Orders (Amendment) Act, 1956',
    'Scheduled Castes and Scheduled Tribes Orders (Amendment) Act, 1976',
    'Scheduled Castes and the Scheduled Tribes (Prevention of Atrocities) Act, 1989',
    'Scheduled Securities (Hyderabad) Act, 1949',
    'Scheduled Tribes and other Traditional Forest Dwellers (Recognition of Forest Rights) Act, 2006',
    'Science and Engineering Research Board Act, 2009',
    'Seamen\'s Provident Fund Act, 1966',
    'Seaward Artillery Practice Act, 1949',
    'Securities and Exchange Board of India Act, 1992',
    'Securities Contracts (Regulation) Act, 1956',
    'Securitisation and Reconstruction of Financial Assets and Enforcement of Security Interest Act, 2002',
    'Seeds Act, 1966',
    'Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013',
    'Semiconductor Integrated Circuits Layout Design Act, 2000',
    'Sheriff of Calcutta (Power of Custody) Act, 1931',
    'Sheriffs\' Fees Act, 1852',
    'Shillong (Rifle Range and Umlong) Cantonments Assimilation of Laws Act, 1954',
    'Shipping Development Fund Committee (Abolition) Act, 1986',
    'Shore Nuisances (Bombay and Kolaba) Act, 1853',
    'Sick Industrial Companies (Special Provisions) Act, 1985',
    'Sick Industrial Companies (Special Provisions) Repeal Act, 2003',
    'Sick Textile Undertakings (Nationalisation) Act, 1974',
    'Sick Textile Undertakings (Taking Over of Management) Act, 1972',
    'Sikh Gurdwaras (Supplementary) Act, 1925',
    'Sikkim University Act, 2007',
    'Sir Dinshaw Maneckjee Petit Act, 1893',
    'Sir Jamsetjee Jejeebhoy Baronetcy Act, 1915',
    'Slum Areas (Improvement and Clearance) Act, 1956',
    'Small Industries Development Bank of India Act Undertakings) Act, 1989',
    'Smugglers and Foreign Exchange Manipulators (Forfeiture of Property) Act, 1976',
    'Societies Registration Act, 1860',
    'Sonthal Parganas Act, 1855',
    'Sonthal Parganas Act, 1857,South Asian University Act, 2009',
    'Special Court (Trial of Offences Relating to Transactions in Securities) Act, 1992',
    'Special Criminal Courts (Jurisdiction) Act, 1950',
    'Special Economic Zones Act, 2005',
    'Special Marriage Act, 1954',
    'Special Protection Group Act, 1988',
    'Specific Relief Act, 1963',
    'Spices Board Act, 1986',
    'The Spirituous Preparations (Inter-State Trade and Commerce) Control (Repeal) Act, 2006',
    'Sports Broadcasting Signals (Mandatory sharing with Prasar Bharti) Act, 2007',
    'Sree Chitra Tirunal Institute for Medical Sciences and Technology Trivandrum Act, 1980',
    'Stage Carriages Act, 1861',
    'Standards of Weights and Measures Act, 1976',
    'Standards of Weights and Measures (Enforcement) Act, 1985',
    'Standards of Weights and Measures (Extension to Kohima and Mokokchung Districts) Act, 1967',
    'State Acquisition of Lands for Union Purposes (Validation) Act, 1954',
    'State Agricultural Credit Corporation Act, 1968',
    'State Armed Police Forces (Extension of Laws) Act, 1952',
    'State Associated Banks (Miscellaneous Provisions) Act, 1962',
    'State Bank of Hyderabad Act, 1956',
    'State Bank of India Act, 1955',
    'State Bank of India (Subsidiary Banks) Act, 1959',
    'State Bank of Saurashtra (Repeal) and the State Bank of India (Subsidiary Bank) Amendment Act, 2009',
    'State Bank of Sikkim (Acquisition of Shares) and Miscellaneous Provisions Act, 1982',
    'State Emblems of India (Prohibition of Improper Use) Act, 2005',
    'State Financial Corporations Act, 1951',
    'State of Arunachal Pradesh Act, 1986',
    'State of Himachal Pradesh Act, 1970',
    'State of Mizoram Act, 1986',
    'State of Nagaland Act, 1962',
    'Street Venders (Protection of Livelihood and Regulation of Street Vending) Act, 2014',
    'States Reorganisation Act, 1956',
    'Sugarcane Act, 1934',
    'Sugar Cess Act, 1982',
    'Sugar Development Fund Act, 1982',
    'Sugar (Regulation of Production) Act, 1961',
    'Sugar Undertaking (Taking over of Management) Act, 1978',
    'Suits Valuation Act, 1887',
    'Suppression of Unlawful Acts against Safety of Civil Aviation Act, 1982',
    'Suppression of Unlawful Acts against Safety of Maritime Navigation and Fixed Platform on Continental Shelf Act, 2002',
    'Supreme Court Advocates (Practice in High Courts) Act, 1951',
    'Supreme Court (Enlargement of Criminal Appellate Jurisdiction) Act, 1970',
    'Supreme Court Judges (Salaries and Conditions of Service) Act, 1958',
    'Supreme Court (Number of Judges) Act, 1956',
    'Swadeshi Cotton Mills Company Limited (Acquisition and Transfer of Undertakings) Act, 1986',
    'Tamil Nadu Agricultural Service Co-operative Societies (Appointment of Special Officers) Amendment Act, 1988',
    'Tamil Nadu Legislative Council Act, 2010',
    'Taxation Laws (Amendment and Miscellaneous Provisions) Act, 1965',
    'Taxation Laws (Amendment and Miscellaneous Provisions) Act, 1986',
    'Taxation Laws (Continuation and Validation of Recovery Proceedings) Act, 1964',
    'Taxation Laws (Extension to Jammu and Kashmir) Act, 1954',
    'Taxation Laws (Extension to Jammu and Kashmir) Act, 1972',
    'Tea Act, 1953',
    'Tea Companies (Acquisition and Transfer of Sick Tea Units) Act, 1985',
    'Technology Development Board Act, 1995',
    'Telecom Regulatory Authority of India Act, 1997',
    'Telegraph Wires (Unlawful Possession) Act, 1950',
    'Terminal Tax on Railway Passengers Act, 1956',
    'Territorial Army Act, 1948',
    'Territorial Waters Maritime Zones Act, 1976',
    'Terrorist Affected Areas (Special Courts) Act, 1984',
    'Textile Undertakings (Nationalisation) Act, 1995',
    'Textile Undertakings (Taking Over of Management) Act, 1983',
    'Textiles Committee Act, 1963',
    'Tezpur University Act, 1993',
    'Tobacco Board Act, 1975',
    'Tobacco Cess Act, 1975',
    'Tobacco Duty (Town of Bombay) Act, 1857',
    'Tokyo Convention Act, 1975',
    'Trade Marks Act, 1999',
    'Trade Unions Act, 1926',
    'Trading with the Enemy (Continuance of Emergency Provisions) Act, 1947',
    'Transfer of Evacuee Deposits Act, 1954',
    'Transfer of Prisoners Act, 1950',
    'Transfer of Property Act, 1882',
    'Transfer of Property (Amendment) Supplementary Act, 1929',
    'Transformer and Switchgear Limited (Acquisition and Transfer of Undertakings) Act, 1983',
    'Transplantation of Human Organs Act, 1994',
    'Travancore-Cochin Vehicles Taxation (Amendment and Validation) Act, 1959',
    'Tripura Land Revenue and Land Reforms Act, 1960',
    'Tripura University Act, 2007',
    'Tyre Corporation of India Limited (Disinvestment of Ownership) Act, 2007',
    'Union Duties of Excise (Distribution) Act, 1979',
    'UnionTerritories (Direct Election to the House of thePeople) Act, 1965',
    'Union Territories (Laws) Act, 1950',
    'Union Territories (Separation of Judicial and Executive Functions) Act, 1969',
    'Union Territories (Stamp and Court-fees Laws) Act, 1961',
    'Union Duties of Excise (Electricity) Distribution Repeal Act, 2006',
    'Unit Trust of India Act, 1963',
    'Unit Trust of India (Transfer of Undertaking and Repeal) Act, 2002',
    'United Nations (Privileges and Immunities) Act, 1947',
    'United Nations (Security Council) Act, 1947',
    'United Provinces Act, 1890',
    'University Grants Commission Act, 1956',
    'University of Allahabad Act, 2005',
    'University of Hyderabad Act, 1974',
    'Unlawful Activities (Prevention) Act, 1967',
    'Untouchability (Offences) Amendment and Miscellaneous Provision Act, 1976',
    'Unorganised Sector Workers Social Security Act, 2008',
    'Urban Land (Ceiling and Regulation) Act, 1976',
    'Urban Land (Ceiling and Regulation) Repeal Act, 1999',
    'Usurious Loans Act, 1918',
    'Usury Laws Repeal Act, 1855',
    'Uttar Pradesh Cantonments (Control of Rent and Eviction) Repeal Act, 1971',
    'Uttar Pradesh Reorganisation Act, 2000',
    'Uttaranchal (Alteration of Name) Act, 2006',
    'Vice-President\'s Pension Act, 1997',
    'Victoria Memorial Act, 1903',
    'Visva-Bharati Act, 1951',
    'Voluntary Surrender of Salaries (Exemption from Taxation) Act, 1961',
    'Wakf Act, 1995',
    'War Injuries (Compensation Insurance) Act, 1943',
    'Warehousing Corporations Act, 1962',
    'Warehousing Corporations (Supplementary) Act, 1965',
    'Warehousing (Development and Regulation) Act, 2007',
    'Waste-Lands (Claims) Act, 1863',
    'Water (Prevention and Control of Pollution) Act, 1974',
    'Water (Prevention and Control of Pollution) Cess Act, 1977',
    'Wealth Tax Act, 1957',
    'Weapons of Mass Destruction and Their Delivery Systems (Prohibition of Unlawful Activities) Act, 2005',
    'Weekly Holidays Act, 1942',
    'West Godavari District (Assimilation of Laws on Federal Subjects) Act, 1949',
    'White Phosphorus Matches Prohibition Act, 1913',
    'Whistle Blowers Protection Act, 2011',
    'Wild Birds and Animals Protection Act, 1912',
    'Wild Life (Protection) Act, 1972',
    'Wild Life (Protection) Amendment Act, 1991',
    'Women\'s and Children\'s Institutions (Licensing) Act, 1956',
    'Working Journalists and other Newspaper Employees (Conditions of Service) and Miscellaneous Provisions Act, 1955',
    'Working Journalists (Fixation of Rates of Wages) Act, 1958',
    'Workmen\'s Compensation Act, 1923',
    'Workmen\'s Compensation (Amendment) Act, 2009',
    'Works of Defence Act, 1903',
    'Young Persons (Harmful Publications) Act, 1956',
    'Bombay Abkari Act, 1878',
    'Maharashtra Abolition of Subsisting Proprietary Rights to Mines and Minerals in Certain Lands Act, 1985',
    'Bombay Adjudication Proceedings (Transfer and Continuance) Act, 1947',
    'Maharashtra Advertisements Tax Act, 1967',
    'Maharashtra Advocates Welfare Fund Act, 1981',
    'Bombay Aerial Ropeways Act, 1955',
    'Bombay Agricultural Debtors Relief Act, 1947',
    'Maharashtra Agricultural Debtors Relief Act, 1969 (Vidarbha Region)',
    'Maharashtra Agricultural Lands (Ceiling on Holdings) Act, 1961',
    'Bombay Agricultural Pests and Diseases Act, 1947',
    'Maharashtra Agricultural Produce Marketing (Development and Regulation) Act, 1963',
    'Maharashtra Agricultural Universities (Krishi Vidyapeeths) Act, 1983',
    'Bombay Anatomy Act, 1949',
    'Maharashtra Ancient Monuments and Archaeological Sites and Remains Act, 1960',
    'Maharashtra Animal and Fishery Sciences University Act, 1988',
    'Maharashtra Animal Preservation Act, 1976',
    'Maharashtra Apartment Ownership Act, 1970',
    'Maharashtra Animal and Fishery Sciences University Act, 1988',
    'Bombay Betting Tax Act, 1925',
    'Bombay Bhagdari and Narwadari Tenures Abolition Act, 1949',
    'Bombay Bhil Naik Inams Abol on Act, 1955',
    'Maharashtra Bombay City (Inami And Special Tenures) Abolition and Land Revenue Code (Amendment) Act, 1969',
    'Bombay Borstal School Act, 1929',
    'Maharashtra Casinos (Control and Tax) Act, 1976',
    'Maharashtra Central India Spinning Weaving and Manufacturing Company Limited The Empress Mills  Nagpur (Acquisition and Transfer of Undertaking) Act, 1986',
    'Bombay Chhatrapati Shivaji Mahara Vastu Sangrahalaya Act, 1909',
    'Bombay Childrens Act, 1948',
    'Bombay Cinemas (Regulation) Act, 1953',
    'Bombay City Civil Court Act, 1948',
    'Bombay City of  (Building Works Restriction) Act, 1949',
    'Bombay City of  Mu al Investments Act, 1898',
    'Bombay City of  Primary Education Act, 1920',
    'Civil Courts Act, 1869',
    'Bombay Civil Jails Act, 1874',
    'Bombay Commissioners of Divisions Act, 1957',
    'Bombay Contingency Fund Act, ',
    'Maharashtra Control of Organised Crime Act, 1999',
    'Maharashtra Co-Operative Societies Act, 1960',
    'Bombay Corneal Grafting Act, 1957',
    'Maharashtra Coroners ( Repeal) Act, 1999',
    'Bombay Cotton Contracts Act, 1932',
    'Bombay Cotton Control Act, 1942',
    'Maharashtra Cotton Seeds (Regulation of Supply Distribution Sale and Fixation of Sale Price) Act, 2009',
    'Bombay Cotton (Statistics) Act, 1946',
    'Bombay Court-Fees Act, 1959',
    'Bombay Court of Wards Act, 1905',
    'Maharashtra Debt Relief Act, 1975',
    'Maharashtra Departmental Inquiries (Enforcement of Attendance of Witnesses and Production of Documents) Act, 1986',
    'Maharashtra Devdasi System (Abolition) Act, 2005',
    'Maharashtra Director of Prohibition and Excise (Change in Designation) Act, 1973',
    'Maharashtra Diseases of Animals Act, 1948',
    'Bombay Disqualification of Aliens Act, 1918',
    'Maharashtra District Planning Committees (Constitution and Functions) Act, 1998',
    'Maharashtra Dog Race-Courses Licensing Act, 1976',
    'Maharashtra Domestic Workers Welfare Board Act, 2008',
    'Maharashtra Drinking Water Supply Requisition Act, 1983',
    'Bombay Drugs (Control) Act, 1959',
    'Maharashtra Education and Employment Guarantee (Cess) Act, 1962',
    'Maharashtra Educational institutions (Management) Act, 1976',
    'Maharashtra Educational Institutions (Prohibition of Capitation Fee) Act, 1987',
    'Maharashtra Educational institutions (Transfer of Management) Act, 1971',
    'Bombay Edulabad and Warangaon Parganas Laws Act, 1866',
    'Bombay Electricity (Special Powers) Act, 1946',
    'Bombay Electricity Duty Act, 1958',
    'Maharashtra Employees of Private Schools (Conditions of Service) Regulation Act, 1977',
    'Maharashtra Employees Guarantee Act, 1977',
    'Bombay Entertainments Duty Act, 1923',
    'Maharashtra Eradication of Unfair activities at Pandharpur Temples (For Providing Better Facilities for Worship) Act, 1980',
    'Bombay Essential Commodities and Cattle (Control) Act, 1958',
    'Maharashtra Essential Services Maintenance Act, 2005',
    'Bombay Exemptions from Land Revenue (No. 1) Act, 1863',
    'Bombay Exemption from Land Revenue (No. 2) Act, 1863',
    'Maharashtra Felling of Trees (Regulation) Act, 1964',
    'Bombay Ferries and Island Vessels Act, 1868',
    'Maharashtra Finance Commission (Miscellaneous Provision) Act, 1994',
    'Maharashtra Fire Prevention and Life Safety Measures Act, 2006',
    'Maharashtra Fiscal Responsibility and Budgetary Management Act, 2005',
    'Maharashtra Fisheries Act, 1960',
    'Bombay Fodder and Grain Control Act, 1939',
    'Maharashtra Forest Development (Tax on Sale of Forest-Produce by Government of Forest Development Corporation) (Continuance) Act, 1983',
    'Maharashtra Forest Produce (Regulation of Trade) Act, 1969',
    'Bombay Forfeited Lands Restoration Act, 1938',
    'Bombay Forward Control Contracts Act, 1947',
    'Maharashtra Fruit Nurseries and Sale of Fruit of Fruit Plants (Regulation) Act, 1969',
    'Bombay Gandhi National Memorial Fund (Local Authorities Donations) Act, 1953',
    'Bombay Gas Companies Act, 1863',
    'Bombay Gas Supply Act, 1939',
    'Bombay General Clauses Act, 1904',
    'Maharashtra Godawari Marathwada Irrigation Development Corporation Act, 1998',
    'Bombay Government Premises (Eviction) Act, 1955',
    'Maharashtra Government Servants Inquiries (Evidence of Corruption) Act, 1965',
    'Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties Act, 2005',
    'Maharashtra Gramdan Act, 1964',
    'Maharashtra Groundwater (Regulation for Drinking Water Purposes) Act, 1993',
    'Maharashtra Gunthewari Developments (Regularisation Upgradation and Control) Act, 2001',
    'Bombay Habitual Offenders Act, 1959',
    'Bombay Hereditary Offices Act, 1874',
    'Maharashtra High Court (Hearing of Writ Petitions by Division Bench and Abolition of Letters Patent Appeals) Act, 1986',
    'Bombay Highways Act, 1955',
    'Bombay Hindu Heirs Relief Act, 1866',
    'Bombay Hindu Places of Public Worship (Entry Authorization) Act, 1956',
    'Bombay Home Guards Act, 1947',
    'Bombay Homoeopathic Practitioners Act, 1959',
    'Maharashtra Horticulture Development Corporation Act, 1984',
    'Maharashtra Housing and Area Development Act, 1976',
    'Maharashtra Increase of Land Revenue and Special Assessment Act, 1974',
    'Maharashtra Industrial Development Act, 1961',
    'Bombay Industrial Disputes ( Provision for Uniformity Act, 1959',
    'Bombay Industrial Relations Act, 1946',
    'Bombay Inferior Village Watans Abolition Act, 1958',
    'Maharashtra Inferior Village Watans Abolition Act, 2016',
    'Bombay Invalidation of Hindu Ceremonial Emoluments Act, 1926',
    'Maharashtra Irrigation Act, 1976',
    'Maharashtra Jeevan Authority Act, 1976',
    'Bombay Judicial Proceedings (Regulation Of Reports) Act, 1955',
    'Bombay Kauli and Katuban Tenures (Abolition) Act, 1953',
    'Maharashtra Kavi Kulaguru Kalidas Sanskrit Vishvavidyalaya University Act, 1997',
    'Maharashtra Keeping and Movement of Cattle In Urban Areas (Control) Act, 1976',
    'Bombay Khadi and Village Industries Act, 1960',
    'Maharashtra Khar Lands Development Act, 1979',
    'Bombay Khoti Abolition Act, 1949',
    'Maharashtra Kidney Transplantation Act, 1982',
    'Maharashtra Korikan Irrigation Development Corporation Act, 1997',
    'Maharashtra Krishna Valley Development Corporation Act, 1996',
    'Bombay Labour Welfare Fund Act, 1953',
    'Bombay Land Improvement Schemes Act, 1942',
    'Bombay Land Requisition Act, 1948',
    'Maharashtra Land Revenue (Revival of Certain Rules Relating to Non-Agricultural Assessment) Act, 1972',
    'Maharashtra Land Revenue Code, 1966',
    'Bombay Land Tenures Abolition (Recovery of Records) Act, 1953',
    'Bombay Landing and Wharfage Fees Act, 1882',
    'Maharashtra Leaders of The Opposition in The  Legislature Salaries and Allowances Act, 1978',
    'Maharashtra Legislative Council (Chairman and Deputy Chairman) and  Legislative Assembly (Speaker and Deputy Speaker) Salaries and Allowances Act, Bombay, 1956',
    'Maharashtra Legislature Members\' Pension Act, 1976',
    'Bombay Legislature Members\' Salaries and Allowances Act, 1956',
    'Bombay Legislature Members (Removal of Disqualifications) Act, 1956',
    'Maharashtra Lepers ( Repeal) Act, 1983',
    'Bombay Lifts Act, 1939',
    'Bombay Livestock Improvement Act, 1933',
    'Bombay Local Authorities Census Expanses Contribution Act, 1950',
    'Maharashtra Local Authority Members\' Disqualification Act, 1986',
    'Bombay Local Fund Audit Act, 1930',
    'Maharashtra Lokayukta and UpavLokayuktas Act, 1971',
    'Bombay Lotteries (Control and Tax) and Prize Competitions (Tax) Act, 1958',
    'Maharashtra Mahatma Phule Vastu Sangrahalaya Poona Act, 1968',
    'Bombay Mahul Creek (Extinguishment of Rights) Act, 1922',
    'Bombay Mamlatdars\' Courts Act, 1906',
    'Maharashtra Management of Irrigation Systems by Farmers Act, 2005',
    'Maharashtra Marine Fishing Regulation Act, 1981',
    'Maharashtra Maritime Board Act, 1996',
    'Bombay Markels and Fairs Act, 1862',
    'Maharashtra Mathadi Hamal and other Manual Workers (Regulation of Employment and Welfare) Act, 1969',
    'Maharashtra Medical and Dental Colleges Admissions (Regulation and Abolition of All India Quota) Act, 2003',
    'Maharashtra Medical Council Act, 1965',
    'Maharashtra Medical Practitioners Act, 1961',
    'Bombay Merged Territories (Janjira and Bhor) Khoti Tenure Abolition Act, 1953',
    'Bombay Merged Territories and Areas (Jagirs Abolition) Act, 1953',
    'Bombay Merged Territories Miscellaneous Alienations Abolition Act, 1955',
    'Maharashtra Metropolitan Planning Committees (Constitutions and Functions) (Continuance of Provisions) Act, 1999',
    'Maharashtra Metropolitan Region Specified Commodities Markets (Regulation of Location) Act, 1983',
    'Maharashtra Mumbai Metropolitan Region Development Authority Act, 1974',
    'Maharashtra Mineral Development (Creation and Utilisation) Fund Act, 2001',
    'Bombay Ministers\' Salaries and Allowances Act, 1956',
    'Maharashtra Miscellaneous Alienations (In Hyderabad Enclaves) Abolition Act, 1965',
    'Bombay Molasses (Control) Act, 1956',
    'Bombay Money-Lenders Act, 1946',
    'Bombay Motor Vehicles (Taxation of Passengers) Act, 1958',
    'Bombay Motor Vehicles Tax Act, 1958',
    'Bombay Mumbai Municipal Corporation Act, 1888',
    'Maharashtra Municipal Councils Nagar Panchayats and Industrial Townships Act, 1965',
    'Bombay Municipal Servants Act, 1890',
    'Maharashtra National and State Parks Act, 1970',
    'Bombay Non-Agriculturists\' Loans Act, 1928',
    'Maharashtra Non-Biodegradable Garbage (Control) Act, 2006',
    'Bombay Non-Trading Corporations Act, 1959',
    'Maharashtra Nurses Act, 1956',
    'Bombay Nursing Homes Registration Act, 1949',
    'Maharashtra Official Languages Act, 1964',
    'Bombay Opium Smoking Act, 1936',
    'Maharashtra Ownership Flas (Regulation of The Promotion of Construction Sale Management and Transfer) Act, 1963',
    'Maharashtra Pandharpur Development Authority Act, 2009',
    'Maharashtra Pandharpur Temples Act, 1973',
    'Bombay Paragana and Kulkarni Watans (Abolition) Act, 1950',
    'Bombay Peint Laws Act, 1894',
    'Bombay Personal Inams Abolition Act, 1952',
    'Bombay Pleaders Act, 1920',
    'Bombay Police Act, 1951',
    'Bombay Prevention of Begging Act, 1959',
    'Maharashtra Prevention of Communal  Anti-Social and Other Dangerous Activities Act, 1980',
    'Maharashtra Prevention of Dangerous Activities of Slumlords Bootleggers Drug-Offenders/Dangerous Persons and Video Pirates Act, 1981',
    'Maharashtra Prevention of Defacement of Property Act, 1995',
    'Bombay Prevention of Fragmentation and Consolidation of Holdings Act, 1947',
    'Bombay Prevention of Gambling Act, 1887',
    'Maharashtra Prevention of Malpractices at University, 1982',
    'Maharashtra Preventive Detention Act, 1970',
    'Bombay Primary Education Act, 1947',
    'Maharashtra Private forests (Acquisition) Act, 1975',
    'Maharashtra Private Professional Educational Institutions (Reservation of Seats for Admission for Scheduled Castes Scheduled Tribes Denotified Tribes (Vimukta Jatis) Nomadic Tribes and Other Backward Classes) Act, 2006',
    'Maharashtra Private Security Guards (Regulation of Employment and Welfare) Act, 1981',
    'Bombay Prohibition Act, 1949',
    'Maharashtra Prohibition of Ragging Act, 1999',
    'Bombay Prohibition of Simultaneous Membership Act, 1957',
    'Maharashtra Project Affected Persons Rehabilitation Act, 1999',
    'Maharashtra Protection of Interest of Depositors (In Financial Establishments) Act, 1999',
    'Bombay Protection of Pilgrims Act, 1887',
    'Bombay Provincial Municipal Corporations Act, 1949',
    'Maharashtra Provisional Collection of Taxes Act, 1962',
    'Maharashtra Provision of Facilities for Agricultural Credit by Banks Act, 1974',
    'Bombay Public Authorities Seals Act, 1883',
    'Bombay Public Conveyances Act, 1920',
    'Maharashtra Public Libraries Act, 1967',
    'Maharashtra Public Records Act, 2005',
    'Bombay Public Trusts Act, 1950',
    'Maharashtra Pulgaon Cotton Mills Limited (Acquisition of Shares) Act, 1982',
    'Maharashtra Purchase Tax on Sugarcane Act, 1962',
    'Bombay Race-Courses Licensing Act, 1912',
    'Maharashtra Rajiv Gandhi Science and Technology Commission Act, 2004',
    'Bombay Rationing (Preparatory and Continuance) Measures Act, 1947',
    'Maharashtra Raw Cotton (Procurement Processing and Marketing) Act, 1971',
    'Maharashtra Recognition of Trade Unions and Prevention of Unfair Labour Practices Act, 1971',
    'Bombay Refugees Act, 1948',
    'Maharashtra Regional and Town Planning Act, 1966',
    'Bombay Regulations, 1827',
    'Maharashtra Regulation of Marriage Bureaus and Registration of Marriages Act, 1998',
    'Maharashtra Regularisation of Unauthorised Developments in the City of Ulhasnagar Act, 2006',
    'Bombay Relief Undertakings (Special Provisions) Act, 1958',
    'Maharashtra Religious Endowments (Reconstruction on Resettlement Sites) Act, 1970',
    'Maharashtra Rent Control Act, 1999',
    'Bombay Rent Free Estates Act, 1852',
    'Maharashtra Requisitioning (And Control) of Motor Vehicles Act, 1965',
    'Maharashtra Restoration of Lands to Scheduled Tribes Act, 1974',
    'Maharashtra Restoration of Name \'Mumbai\' for \'Bombay\' Act, 1996',
    'Bombay Revenue Jurisdiction Act, 1876',
    'Maharashtra Revenue Patels (Abolition of Office) Act, 1962',
    'Maharashtra Right to Information (Repeal) Act, 2005',
    'Maharashtra Sale of Trees by Occupants Belonging to Scheduled Tribes (Regulation) Act, 1969',
    'Bombay Salsette Estates (Land Revenue Exemption Abolition) Act, 1951',
    'Bombay Satara Sholapur and Southern Maratha Country Laws Act, 1853',
    'Maharashtra Scheduled Castes Scheduled Tribes De-Notified Tribes (Vimukta Jatis) Nomadic Tribes Other Backward Classes and Special Backward Category Regulation of Issuance and Verification of Caste Certificate Act, 2000',
    'Bombay Seals Act, 1949',
    'Bombay Seals Act (Application to Scheduled Areas) Regulation, 1951',
    'Maharashtra Secondary and Higher Secondary Education Boards Act, 1965',
    'Bombay Separation of Judicial and Executive Functions Act, 1951',
    'Bombay Separation of Judicial and Executive Functions (Extension) and The Code of Criminal Procedure (Provision for Uniformity) Act, 1958',
    'Bombay Separation of Judicial and Executive Functions (Supplementary) Act, 1954',
    'Bombay Service Inams (Useful to Community) Abolition Act, 1953',
    'Bombay Shilotri Rights (Kolaba) Abolition Act, 1955',
    'Maharashtra Shivraj Fine Art Litho Works (Acquisition and Transfer of Undertaking) Act, 1984',
    'Bombay Shops and Establishments Act, 1948',
    'Maharashtra Shore Nuisances (Bombay and Kolaba) Act, 1853',
    'Maharashtra Shree Sai Baba Sansthan Trust (Shirdi) Act, 2004',
    'Maharashtra Shree Siddhi Vinayak Ganapati Temple Trust (Prabhadevi) Act, 1980',
    'Maharashtra Sinhastha Fair Pilgrim Tax Act, 1980',
    'Maharashtra Slum Areas (Improvement Clearance and Redevelopment) Act, 1971',
    'Bombay Smoke-Nuisances Act, 1912',
    'Maharashtra Special Provision for Payment of Court-Fees Act, 1976',
    'Bombay Special Tribunal (Continuance) Act, 1946',
    'Bombay Stamp Act, 1958',
    'Maharashtra State-Aid to Industries Act, 1960',
    'Maharashtra State Board of Technical Education Act, 1997',
    'Maharashtra State Commission for Backward Classes Act, 2005',
    'Maharashtra State Commission for Safai Karmacharis Act, 1997',
    'Maharashtra State Commission for Women Act, 1993',
    'Bombay State Commissioners of Police Act, 1959',
    'Maharashtra State Council for Occupational Therapy and Physiotherapy Act, 2002',
    'Maharashtra State Council of Examinations Act, 1998',
    'Maharashtra State Election Commissioner (Qualification and Appointment) Act, 1994',
    'Maharashtra State Enterprises (Restructuring and Other Special Provisions (Repeal) Act, 2007',
    'Maharashtra State Legislature Proceedings (Protection of Publication) Act, 1969',
    'Maharashtra State Minorities Commission Act, 2004',
    'Maharashtra State Public Services (Reservation for Scheduled Castes Scheduled Tribes De-Notified Tribes (Vimukta Jatis) Nomadic Tribes Special Backward Category and Other Backward Classes) Act, 2001',
    'Bombay State Reserve Police Force Act, 1951',
    'Bombay State Scarcity Relief Fund Act, 1956',
    'Bombay State Scarcity Relief Fund Act, 1958',
    'Maharashtra State Security Corporation Act, 2010',
    'Maharashtra State Tax on Professions Trades Ceilings and Employments Act, 1975',
    'Bombay Statutory Corporations (Regional Reorganisation) Act, 1960',
    'Bombay Statutory Funds Act, 1959',
    'Bombay Steam-Vessels Act, 1864',
    'Maharashtra Supply of Forest\' Produce by Government (Revision of Agreements) Act, 1982',
    'Maharashtra Surgeon General with Government etc. (Change in Designations) Act, 1972',
    'Maharashtra Tapi Irrigation Development Corporation Act, 1997',
    'Maharashtra Tax on Buildings (With Larger Residential Premises) (Re-Enacted) Act, 1979',
    'Maharashtra Tax on Lotteries Act, 2006',
    'Maharashtra Tax on Sale of Electricity Act, 1963',
    'Maharashtra Tax on The Entry of Goods into Local Areas Act, 2002',
    'Maharashtra Tax on Entry of Motor Vehicles into Local Areas Act, 1987',
    'Maharashtra Tax on Luxuries Act, 1987',
    'Bombay Tenancy and Agricultural Lands Act, 1948',
    'Maharashtra Textile Companies (Acquisition and Transfer of Undertakings) Act, 1982',
    'Bombay Tolls on Roads and Bridges Act, 1875',
    'Maharashtra Transfer of Ownership of Minor Forest Produce in The Scheduled Areas and The  Minor Forest Produce (Regulation of Trade) (Amendment) Act, 1997',
    'Maharashtra Tribals Economic Condition (Improvement) Act, 1976',
    'Maharashtra Truck Terminal (Regulation of Location) Act, 1995',
    'Maharashtra Tuljapur Development Authority Act, 2008',
    'Maharashtra Universities Act, 1994',
    'Maharashtra University of Health Sciences Act, 1998',
    'Maharashtra (Urban Areas) Protection and Preservation of Trees Act, 1975',
    'Maharashtra Urban Immovable Property Tax (Abolition) and General Tax (Increase of Maximum Rate) Act, 1962',
    'Maharashtra Value Added Tax Act, 2002',
    'Maharashtra Veterinary Practitioners Act, 1971',
    'Maharashtra Vexatious Litigation (Prevention) Act, 1971',
    'Maharashtra Vidarbha Irrigation Development Corporation Act, 1997',
    'Bombay Village Panchayat Act, 1958',
    'Maharashtra Village Police Act, 1967',
    'Bombay Village Sanitation Act, 1889',
    'Bombay Warehouses Act, 1959',
    'Maharashtra Water Conservation Corporation Act, 2000',
    'Maharashtra Water Resources Regulatory Authority Act, 2005',
    'Bombay Weights and Measures Act, 1932',
    'Bombay Weights and Measures (Enforcement) Act, 1958',
    'Maharashtra West Khandesh Mehwassi Estate (Proprietary Rights Abolition Etc.) Regulation, 1961',
    'Bombay West Khandesh Mehwassi Estates Regulation, 1949',
    'Bombay Wild Animals and Wild Birds Protection Act, 1951',
    'Maharashtra Workmens\' Minimum House-Rent Allowance Act, 1983',
    'Maharashtra Yashwantrao Chavan  Open University Act, 1989',
    'Maharashtra Zilla Parishads and Panchayat Samitis Act, 1961',
    'Maharashtra Right to Information (Repeal) Act, 2005',
    'Bombay Nursing Homes Registration (Amendment) Act, 2005',
    'Maharashtra State Board of Technical Education (Amendment) Act, 2005',
    'Maharashtra Public Records Act, 2005',
    'Bombay Tenancy and Agricultural Lands (Amendment) Act, 2005',
    'Maharashtra (Supplementary) Appropriation Act, 2006',
    'Maharashtra Appropriation (Vote on Account) Act, 2006',
    'Maharashtra Agricultural Lands (Ceiling on Holdings) (Amendment) Act, 2006',
    'Regularisation of Unauthorised Developments in the City of Ulhasnagar Act, 2006',
    'Maharashtra Non-biodegradable Garbage (Control) Act, 2006',
    'Maharashtra Appropriation Act, 2006',
    'Bombay Stamp (Amendment) Act, 2006',
    'Contract Labour (Regulation and Abolition) (Maharashtra Amendment) Act, 2005',
    'Bombay Motor Vehicles Tax (Second Amendment) Act, 2006',
    'Maharashtra Legislature Members\' Pension (Amendment) Act, 2006',
    'Bombay Entertainments Duty (Amendment) Act, 2006',
    'Maharashtra State Tax on Professions, Trades, Callings and Employments (Amendment) Act, 2006',
    'Maharashtra State Enterprises (Restructuring and Other Special Provisions) (Amendment) Act, 2006',
    'Maharashtra Municipal Corporations (Amendment) Act, 2006',
    'Maharashtra Co-operative Societies (Amendment) Act, 2006',
    'Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties Act, 2005',
    'Bombay Motor Vehicles Tax (Amendment) Act, 2006',
    'Industrial Disputes (Maharashtra Amendment) Act, 2003',
    'Mumbai Municipal Corporation (Amendment) Act, 2006',
    'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2006',
    'Maharashtra (Second Supplementary) Appropriation Act, 2006',
    'Mumbai Municipal Corporation (Second Amendment) Act, 2006',
    'Factories (Maharashtra Amendment) Act, 2006',
    'Bombay Prohibition (Amendment) Act, 2006',
    'Maharashtra Private Professional and Educational Institutions (Reservation seats for admission for Scheduled Castes, Scheduled Tribes, 2006',
    'De-notified Tribes (Vimukta Jatis), Nomadic Tribes and Other Backward Classes) Act, 2006',
    'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2006',
    'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2006',
    'Maharashtra Devdasi System (Abolition) Act, 2006',
    'Maharashtra State Commission for Backward Classes Act, 2005',
    'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2006',
    'Maharashtra Municipal Corporations & Municipal Councils, Nagar Panchayats and Industrial Townships (Third Amendment) Act, 2006',
    'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2006',
    'Bombay Village Panchayats (Amendment) Act, 2006',
    'Maharashtra (Third Supplementary) Appropriation Act, 2006',
    'Maharashtra Appropriation (Excess Expenditure) Act, 2006',
    'Maharashtra Appropriation (Second Excess Expenditure) Act, 2006',
    'Maharashtra Fiscal Responsibility and Budgetary Management (Amendment) Act, 2006',
    'Maharashtra Tax on Lotteries Act, 2006',
    'Bombay Motor Vehicles Tax (Third Amendment) Act, 2006',
    'Maharashtra Education and Employment Guarantee (Cess) (Amendment) Act, 2006',
    'Maharashtra Legislature Members\' Pension (Third Amendment) Act, 2006',
    'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Second Amendment) Act, 2006',
    'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Second Amendment) Act, 2006',
    'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2006',
    'Maharashtra Local Authority Members\' Disqualification (Amendment) Act, 2006',
    'Maharashtra Employment Guarantee (Amendment) Act, 2006',
    'Maharashtra Legislature Members\' Pension (Second Amendment) Act, 2006',
    'Hyderabad Abolition of Inams and Cash Grants (Amendment) Act, 2006',
    'Maharashtra Fire Prevention and Life Safety Measures Act, 2006',
    'Maharashtra (Supplementary) Appropriation Act, 2007',
    'Maharashtra Appropriation (Vote on Account) Act, 2007',
    'Maharashtra State Tax on Professions, Trades, Callings and Employments and the Maharashtra Value Added Tax (Amendment) Act, 2007',
    'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2007',
    'Vidarbha Irrigation Development Corporation and the Konkan Irrigation Development Corporation (Amendment) Act, 2007',
    'Maharashtra State Enterprises (Restructuring and Other Special Provisions)(Repeal) Act, 2007',
    'Maharashtra Appropriation Act, 2007',
    'Maharashtra Municipal Corporations (Amendment) Act, 2007',
    'Mumbai Municipal Corporation and the Bombay Provincial Municipal Corporation (Temporary Amendment) Act, 2007',
    'Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties (Amendment) Act, 2007',
    'Bombay Primary Education and the Maharashtra Employees of Private Schools (Conditions of Service) Regulation (Amendment) Act, 2007',
    'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2007',
    'Maharashtra Regional and Town Planning (Amendment) Act, 2007',
    'Maharashtra Land Revenue Code (Amendment) Act, 2007',
    'Maharashtra Legislature Members\' Salaries and Allowances (Amendment) Act, 2007',
    'Maharashtra Value Added Tax (Amendment) Act, 2007',
    'Maharashtra (Second Supplementary) Appropriation Act, 2007',
    'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2007',
    'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Township (Amendment) Act, 2007',
    'Maharashtra Land Revenue Code (Second Amendment) Act, 2007',
    'Maharashtra Land Revenue Code (Third Amendment) Act, 2007',
    'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2007',
    'Maharashtra Legislature Members\' Pension (Amendment) Act, 2007',
    'Code of Criminal Procedure (Maharashtra Amendment) Act, 2007',
    'Prevention of Food Adulteration (Maharashtra Amendment) Act, 2006',
    'Maharashtra (Third Supplementary) Appropriation Act, 2007',
    'Maharashtra Co-operative Societies (Amendment) Act, 2007',
    'Maharashtra Co-operative Societies (Third Amendment) Act, 2007',
    'Dangerous Persons (Amendment) Act, 2007',
    'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2007',
    'Bombay Government Premises (Eviction) (Amendment) Act, 2007',
    'Bombay Provincial Municipal Corporations, the City of Nagar Corporation and the Maharashtra Municipal Councils, Nagar',
    'Panchayats and Industrial Townships (Amendment) Act, 2007',
    'Maharashtra Municipal Corporations (Second Amendment) Act, 2007',
    'Maharashtra Ownership Flats (Regulation of the Promotion of Construction, Sale, Management and Transfer) (Amendment) Act, 2005',
    'Bombay State Tax on Professions, Trades, Callings and Employments Act, 1975',
    'Maharashtra Tax Laws (Levy and Amendment) Act, 2013',
    'Relief Fund (Repeal) Act, 2008',
    'Maharashtra (Supplementary) Appropriation Act, 2008',
    'Maharashtra Appropriation (Vote on Account) Act, 2008',
    'Maharashtra Value Added Tax (Amendment) Act, 2008',
    'Maharashtra Appropriation Act, 2008',
    'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2008',
    'Maharashtra Co-operative Societies (Amendment) Act, 2008',
    'Maharashtra Municipal Corporations and Municipal Councils (Third Amendment) Act, 2007',
    'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2008',
    'Mumbai Municipal Corporation (Amendment) Act, 2008',
    'Maharashtra Tax on Sales of Electricity (Amendment) Act, 2008',
    'Bombay Stamp (Amendment) Act, 2008',
    'Bombay Court-fees (Amendment) Act, 2008',
    'Mumbai Municipal Corporation (Second Amendment) Act, 2008',
    'Bombay Paragana and Kulkarni Watans (Abolition) Act, 1950',
    'Bombay Service Inams (Useful to Community) Abolition Act, 1953',
    'Maharashtra Merged Territories (Janjira and Bhor) Khoti Tenure Abolition Act, 2012',
    'Watans Abolition and the Maharashtra Revenue Patels (Abolition of Office) (Amendment) Act, 2008',
    'Maharashtra Legislature Members\' Salaries and allowances and Pension (Amendment) Act, 2008',
    'Shree Siddhi Vinayak Ganpati Trust (Prabhadevi) (Amendment) Act, 2008',
    'Mumbai Municipal Corporation (Temporary Amendment) Act, 2007',
    'Maharashtra Legislature Members\' Pension (Amendment) Act, 2008',
    'Maharashtra (Second Supplementary) Appropriation Act, 2008',
    'Maharashtra Legislature Members\' Salaries and Allowances (Amendment) Act, 2008',
    'Maharashtra High Court (Hearing of Writ Petitions by Division Bench and Abolition of Letters Patent Appeals) (Amendment) Act, 2008',
    'Maharashtra (Third Supplementary) Appropriation Act, 2008',
    'Maharashtra Appropriation (Excess Expenditure) Act, 2008',
    'Maharashtra Appropriation (Second Excess Expenditure) Act, 2008',
    'Maharashtra Housig and Area Development (Amendment) Act, 2008',
    'Maharashtra Domestic Workers Welfare Board Act, 2008',
    'Bombay Police (Amendment) Act, 2008',
    'Tuljapur Development Authority Act, 2008',
    'Bombay Provincial Municipal Corporations (Amendment) Act, 2008',
    'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2008',
    'Maharashtra Municipal Corporations and Municipal Councils (Fifth Amendment) Act, 2008',
    'Maharashtra Municipal Corporations and Municipal Councils (Sixth Amendment) Act, 2008',
    'Maharashtra (Supplementary) Appropriation Act, 2009',
    'Maharashtra Appropriation (Vote on Account) Act, 2009',
    'Maharashtra Land Revenue Code (Amendment) Act, 2009',
    'Mumbai Municipal Corporation (Third Amendment) Act, 2006',
    'Maharashtra Appropriation Act, 2009',
    'Maharashtra (Second Supplementary) Appropriation Act, 2009',
    'The Maharashtra Universities and the Kavi Kulaguru Kalidas Sanskrit Vishvavidyalaya (University) (Amendment) Act, 2009',
    'Pandharpur Development Authority Act, 2009',
    'Maharashtra Regional and Town Planning (Second Amendment) Act, 2009',
    'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2009',
    'Maharashtra Legislative Council (Chairman & Deputy Chairman) & Maharashtra Legislative Assembly (Speaker & Deputy Speaker) Salaries & Allowances Act, 2017',
    'Maharashtra Ministers Salaries and Allowances, 1956',
    'Maharashtra Legislature Members’ (Removal of Disqualifications) Act, 1956',
    'Maharashtra Legislature Members’ Pension Act, 1976',
    'Maharashtra Legislature Members’ Salaries and Allowances Act, 1956',
    'Maharashtra Legislature Salaries and Allowances (Amendment) Act, 2009',
    'Maharashtra Cotton Seeds (Regulation of Supply, Distribution, Sale and Fixation of Sale Price) Act, 2009',
    'Bombay Public Trusts (Amendment) Act, 2009',
    'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2009',
    'Maharashtra Value Added Tax (Levy and Amendment and Validation) Act, 2009',
    'Maharashtra (Third Supplementary) Appropriation Act, 2009',
    'Maharashtra Prevention of Dangerous Activities of Slumlords, Bottleggers, Drug-offenders and Dangerous persons (Amendment) Act, 2009',
    'Maharashtra Regional and Town Planning (Third Amendment) Act, 2009',
    'Maharashtra University of Health Sciences (Amendment) Act, 2009',
    'Bombay Provincial Municipal Corporations and the Bombay Village Panchayats (Amendment) Act, 2009',
    'Maharashtra Project Affected Persons Rehabilitation (Amendment) Act, 2009',
    'Maharashtra Motor Vehicles Tax (Amendment) Act, 2009',
    'Dr. Babasaheb Ambedkar Technological University and the Maharashtra Universities (Amendment) Act, 2009',
    'Bombay Entertainments Duty (Amendment) Act, 2009',
    'Maharashtra (Supplementary) Appropriation Act, 2010',
    'Maharashtra Appropriation (Vote on Account) Act, 2010',
    'Bombay Stamp (Amendment) Act, 2009',
    'Maharashtra State Security Corporation Act, 2010',
    'Maharashtra Value Added Tax (Amendment) Act, 2010',
    'Maharashtra Appropriation Act, 2010',
    'Bombay Court-fees (Amendment and Continuance) Act, 2010',
    'Bombay Provincial Municipal Corporations Act 1949',
    'City of Nagpur Corporation Act, 1948',
    'Maharashtra Municipal Corporations and Municipal Councils (Amendment), Act, 2012',
    'and the Maharashtra Tax on Buildings (with larger Residential Premises) (Re-enacted)(Amendment) Act, 2009',
    'Maharashtra Medicare Service Persons and Medicare Service Institutions (Prevention of Violence and Damage or Loss to Property) Act, 2010',
    'Maharashtra Tax Laws (Levy and Amendment) Act, 2010',
    'Bombay Motor Vehicles Tax (Amendment) Act, 2010',
    'Maharashtra Workmen\'s Minimum House-rent Allowance (Amendment) Act, 2010',
    'Bombay Betting Tax (Amendment) Act, 2010',
    'Bombay Village Panchayats and Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2010',
    'Maharashtra University of Health Sciences (Amendment) Act, 2010',
    'Maharashtra Agricultural Universities (Krishi Vidyapeeths) (Amendment) Act, 2010',
    'Maharashtra Animal and Fishery Sciences University (Amendment) Act, 2010',
    'Maharashtra (Second Supplementary) Appropriation Act, 2010',
    'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2010',
    'Bombay Entertainments Duty (Amendment) Act, 2010',
    'Bombay Village Panchayats (Amendment) Act, 2010',
    'Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2010',
    'Maharashtra (Third Supplementary) Appropriation Act, 2010',
    'Payment of Wages and Minimum Wages (Maharashtra Amendment) Act, 2010',
    'Maharashtra Municipal Corporations and Municipal Councils (Third Amendment) Act, 2010',
    'Bombay Village Panchayats (Second Amendment) Act, 2010',
    'Maharashtra Regional and Town Planning (Amendment and Validation) Act, 2010',
    'Bombay Motor Vehicles Tax (Second Amendment) Act, 2010',
    'Bombay Entertainments Duty (Second Amendment) Act, 2010',
    'Bombay Village Panchayats and Maharashtra Zilla Parishads and panchayat Samitis (Second Amendment) Act, 2010',
    'Maharashtra Regional and Town Planning (Amendment) Act, 2010',
    'Maharashtra University of Health Sciences (Second Amendment) Act, 2010',
    'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2010',
    'Maharashtra Co-operative Societies (Amendment) Act, 2010',
    'Payment of Bonus (Maharashtra Amendment) Act, 2010',
    'Maharashtra (Supplementary) Appropriation Act, 2011',
    'Maharashtra Appropriation (Vote on Account) Act, 2011',
    'Maharashtra Value Added Tax (Amendment and Validation) Act, 2011',
    'Maharashtra Value Added Tax (Amendment) Act, 2011',
    'Bombay Local Fund Audit (Amendment) Act, 2011',
    'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2010',
    'Maharashtra Regional and Town Planning (Second Amendment) Act, 2010',
    'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2011',
    'Maharashtra Municipal Corporations (Amendment) Act, 2011',
    'Bombay Entertainments Duty (Amendment) Act, 2011',
    'Maharashtra Municipal Property Tax Board Act, 2011',
    'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2011',
    'Maharashtra Universities (Amendment) Act, 2011',
    'Maharashtra Appropriation Act, 2011',
    'Maharashtra Lokayukta and Upa-Lokayuktas (Amendment) Act, 2011',
    'Bombay Village Panchayats and the Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2011',
    'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2011',
    'Maharashtra Water Resources Regulatory Authority (Amendment and Continuance) Act, 2011',
    'Bombay Homoeopathic Practitioners\' (Amendment) Act, 2011',
    'Maharashtra Legislature Members\' Pension (Amendment) Act, 2011',
    'Maharashtra (Second Supplementary) Appropriation Act, 2011',
    'Bombay Court-fees (Amendment) Act, 2011',
    'Bombay Provincial Municipal Corporations, the City of Nagar Corporation and the Maharashtra Municipal Councils, Nagar Panchayats',
    'and Industrial Townships (Amendment) Act, 2011',
    'Bombay Village Panchayats (Amendment) Act, 2011',
    'Maharashtra Universities (Second Amendment) Act, 2011',
    'Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2010',
    'Shree Sai Baba Sansthan Trust (Shridi) (Amendment) Act, 2011',
    'Nagpur Improvoement Trust (Amendment) Act, 2011',
    'Maharashtra Municipal Corporation (Second Amendment) Act, 2011',
    'Mimimum Wages (Maharashtra Amendment) Act, 2010',
    'Maharashtra (Third Supplementary) Appropriation Act, 2011',
    'Maharashtra Appropriation (Excess Expenditure) Act, 2011',
    'Maharashtra Appropriation (Second Excess Expenditure) Act, 2011',
    'Maharashtra Appropriation (Third Excess Expenditure) Act, 2011',
    'Maharashtra Appropriation (Fourth Excess Expenditure) Act, 2011',
    'Bombay Village Panchayats and Maharashtra Zilla Parishads and Panchayat Samitis (Second Amendment) Act, 2011',
    'Maharashtra Municipal Corporation (Third Amendment) Act, 2011',
    'Bombay Stamp (Amendment) Act, 2011',
    'Nagar Panchayats and Industrial Townships (Amendment) Act, 2011',
    'Maharashtra Land Revenue Code and the Maharashtra Restoration of Lands to Scheduled Tribes (Amendment) Act, 2010',
    'Bombay Civil Courts (Amendment) Act, 2011',
    'Maharashtra Agricultural Lands (Ceiling on Holdings) (Amendment) Act, 2011',
    'Maharashtra Municipal Corporations, Municipal Councils and Maharashtra Regional and Town Planning (Amendment) Act, 2010',
    'Maharashtra (Supplementary) Appropriation Act, 2012',
    'Maharashtra Appropriation (Vote on Account) Act, 2012',
    'Maharashtra Appropriation Act, 2012',
    'Mumbai Municipal Corporation and the Maharashtra (Urban Areas) Protection and Preservation of Trees (Amendment) Act, 2012',
    'Bombay Electricity Duty (Amendment) Act, 2012',
    'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2012',
    'Bombay Primary Education and the Maharashtra Employees of Private Schools (Conditions of Service) Regulation (Amendment) Act, 2011',
    'Registration (Maharashtra Amendment) Act, 2010',
    'Maharashtra Slum Areas (Improvement, Clearance and Redevelopment) (Amendment) Act, 2011',
    'Maharashtra Essential Services Maintenance Act, 2011',
    'Maharashtra (Second Supplementary) Appropriation Act, 2012',
    'Bombay Motor Vehicles Tax (Amendment) Act, 2012',
    'Maharashtra Municipal Councils Nagar Panchayats and Industrial Township (Amendment) Act, 2011',
    'Bombay Village Panchayats (Second Amendment) Act, 2012',
    'Maharashtra Municipal Corporations and Municipal Councils (Amendment) Act, 2012',
    'Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2012',
    'Maharashtra Land Revenue Code (Amendment) Act, 2012',
    'Mumbai Municipal Corporation (Amendment and Validation) Act, 2011',
    'Maharashtra State Minorities Commission (Amendment) Act, 2012',
    'Bombay Village Panchayats (Amendment) Act, 2012',
    'Bombay Provincial Municipal Corporations (Amendment) and the City of Nagpur Corporation (Repeal) Act, 2011',
    'Maharashtra (Change of Short Titles of certain Bombay Acts) Act, 2011',
    'Bombay City Civil Court (Amendment) Act, 2012',
    'Maharashtra Education and Employment Guarantee (Cess) (Amendment) Act, 2012',
    'State Election Commissioner (Qualifications and Appointment) (Amendment) Act, 2011',
    'Maharashtra Municipal Corporations and Municipal Councils (Third Amendment) Act, 2012',
    'Maharashtra Village Panchayats (Third Amendment) Act, 2012',
    'Maharashtra (Third Supplementary) Appropriation Act, 2012',
    'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Second Amendment) Act, 2012',
    'Maharashtra State Security Corporation (Amendment) Act, 2012',
    'Maharashtra District Planning Committees (Constitution and Functions) (Amendment) Act, 2012',
    'Maharashtra Self-financed Schools (Establishment and Regulation) Act, 2012',
    'Maharashtra (Supplementary) Appropriation Act, 2013',
    'Maharashtra Appropriation (Vote on Account) Act, 2013',
    'Maharashtra Value Added Tax (Amendment) Act, 2013',
    'Maharashtra Self-financed Schools (Establishment and Regulation) (Amendment) Act, 2013',
    'Maharashtra Entertainments Duty (Amendmen) Act, 2013',
    'Maharashtra Appropriation Act, 2013',
    'Maharashtra Tax Laws (Levy and Amendment) Act, 2013',
    'Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2013',
    'Maharashtra Project Affected Persons Rehabilitation (Amendment) Act, 2013',
    'Maharashtra Legislative Council (Chairman and Deputy Chairman) and Maharashtra Legislative Assembly',
    '(Speaker and Deputy Speaker) Salaries and Allowances, the Maharashtra Ministers\' Salaries and Allowances,',
    'the Maharashtra Legislature Members\' Salaries and Allowances and the Leaders of Opposition in',
    'Maharashtra Legislature Salaries and Allowances (Amendment) Act, 2013',
    'Maharashtra (Second Supplementary) Appropriation Act, 2013',
    'Maharashtra Agricultural Universities (Krishi Vidyapeeths) (Amendment) Act, 2013',
    'Maharashtra Universities (Amendment) Act, 2013',
    'Maharashtra Industrial Development (Amendment) Act, 2013',
    'Maharashtra Co-operative Societies (Amendment) Act, 2013',
    'Maharashtra Universities (Second Amendment) Act, 2013',
    'Maharashtra Live-Stock Improvement (Amendment) Act, 2013',
    'Maharashtra Self-financed Schools (Establishment and Regulation) (Second Amendment) Act, 2013',
    'Maharashtra Unauthorized Institutions and Unauthorized Courses of Study in Agriculture, Animal and Fishery Sciences, Health Sciences, Higher Technical and Vocational Education (Prohibition) Act, 2013',
    'Maharashtra Khadi and Village Industries (Amendment) Act, 2013',
    'Maharashtra Devdasi System (Abolition) (Amendment) Act, 2013',
    'Maharashtra State Board of Nursing and Paramedical Education Act, 2013',
    'Maharashtra Legislature Members\' Pension (Amendment) Act, 2013',
    'Bombay Shops and Establishments (Amendment) Act, 2011',
    'Maharashtra Groundwater (Development and Management) Act, 2009',
    'Maharashtra (Third Supplementary) Appropriation Act, 2013',
    'Maharashtra Appropriation (Excess Expenditure) Act, 2013',
    'Maharashtra Appropriation (Second Excess Expenditure) Act, 2013',
    'Maharashtra Prevention and Eradication of Human Sacrifice and other Inhuman, Evil and Aghori Practices and Black Magic Act, 2013',
    'Maharashtra Co-operative Societies (Second Amendment) Act, 2013',
    'Maharashtra Agricultural Universities (Krishi Vidyapeeths) (Second Amendment) Act, 2013',
    'Maharashtra Regulation of Surgarcane Price (Supplied to Factories) Act, 2013',
    'The Bombay Tenancy and Agricultural Lands, the Hyderabad Tenancy and Agricultural Lands and the Bombay Tenancy and Agricultural Lands (Vidarbha Region) (Amendment) Act, 2012',
    'The Maharashtra Housing (Regulation and Development) Act, 2012',
    'The Maharashtra (Suppllementary) Appropriation Act, 2014',
    'The Maharashtra Appropriation (Vote on Account) Act, 2014',
    'The Maharashtra Regional and Town Planning (Amendment and Continuance) Act, 2014',
    'The Maharashtra National Law University Act, 2014',
    'The Maharashtra Educational Institutions (Regulation of Fee) Act, 2011',
    'The Maharashtra Money-Lending (Regulation) Act, 2014',
    'The Maharashtra Slum Areas (Improvement, Clearance and Redevelopment) (Amendment) Act, 2014',
    'The Bombay Tenancy and Agricultural Lands, the Hyderabad Tenancy and Agricultural Lands and the Bombay Tenancy and Agricultural Lands (Vidarbha Region) (Amendment) Act, 2011',
    'The Maharashtra Municipal Corporations and Municipal Councils Temporary Postponement of Elections (of the Mayors and Deputy Mayors of certain Municipal Corporations and Presidents of certain Municipal Councils due to ensuing general elections to the State Legislative Assembly) Act, 2014',
    'The Maharashtra Appropriation Act, 2014',
    'The Amity University Act, 2014',
    'The Spicer Adventist University Act, 2014',
    'The Maharashtra Police (Second Amendment) Act, 2014',
    'The Maharashtra Government Servants Regulation of Transfers and Prevention of Delay in Discharge of Official Duties (Amendment) Act, 2014',
    'The Maharashtra Agricultural Pests and Diseases (Amendment) Act, 2014',
    'The Maharashtra Village Panchayats (Amendment and Continuance) Act, 2014',
    'The Maharashtra Homoeopathic Practitioners\' and the Maharashtra Medical Council (Amendment) Act, 2014',
    'The Maharashtra Value Added Tax (Amendment) Act, 2014',
    'The Maharashtra (Second Supplementary) Appropriation Act, 2014',
    'The Maharashtra Entertainments Duty (Amendment and Continuance) Act, 2014',
    'The Maharashtra Money-Lending (Regulation) Amendment Act, 2014',
    'The Maharashtra Police (Amendment and Continuance) Act, 2014',
    'The City of Mumbai Primary Education, the Maharashtra Primary Education, the Hyderabad Compulsory Primary Education and the Madhya Pradesh Primary Education (Repeal) Act, 2013',
    'Maharashtra Employment Guarantee (Amendment) Act, 2014',
    'Maharashtra Employment Guarantee Act, 1977',
    'The Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2014',
    'The Maharashtra Medical Practitioners (Amendment) Act, 2014',
    'Dr. Babasaheb Ambedkar Technological University Act, 2014',
    'Dr. Babasaheb Ambedkar Technological University Act, 1989',
    'The Maharashtra Land Revenue Code (Amendment) Act, 2014',
    'Maharashtra Land Improvement Schemes (Amendment) Act, 2013',
    'Maharashtra Forest Produce (Regulation of Trade) (Amendment) Act, 2014',
    'The Code of Criminal Procedure (Maharashtra Amendment) Act, 2006',
    'The Maharashtra Municipal Corporations and Municipal Councils (Second Amendment) Act, 2012',
    'The Maharashtra Regional and Town Planning (Amendment) Act, 2011',
    'Maharashtra Universities (Amendment) Act, 2014',
    'The Maharashtra Land Revenue Code (Second Amendment) Act, 2014',
    'Maharashtra Regional and Town Planning (Amendment) Act, 2014',
    'Maharashtra (Third Supplementary) Appropriation Act, 2014',
    'The Maharashtra Co-operative Societies (Amendment) Act, 2014',
    'The Maharashtra Appropriation (Excess Expenditure) Act, 2014',
    'The Maharashtra Entertainments Duty (Amendment) Act, 2014',
    'The Maharashtra Village Panchayats and the Maharashtra Regional and Town Planning (Amendment) Act, 2014',
    'Maharashtra State Reservation (of seats for admission in educational institutions in the State and for appointments or posts in the public services under the State) for Educationally and Socially Backward Category (ESBC) Act, 2014',
    'FLAME University Act, 2014',
    'Ajeenkya DY Patil University Act, 2014',
    'Maharashtra Land Revenue Code (Second Amendment) Act, 2012',
    'Maharashtra Animal Preservation (Amendment) Act, 1995',
    'Maharashtra (Supplementary) Appropriation Act, 2015',
    'Maharashtra Village Panchayats (Amendment) Act, 2015',
    'Maharashtra Universities (Amendment) Act, 2015',
    'Maharashtra Appropriation (Vote on Account) Act, 2015',
    'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2015',
    'Maharashtra Police (Amendment) Act, 2015',
    'Maharashtra Fire Prevention and Life Safety Measures (Amendment) Act, 2015',
    'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Second Amendment) Act, 2015',
    'Maharashtra Appropriation Act, 2015',
    'Maharashtra (Urban Areas) Protection and Preservation of Trees (Amendment) Act, 2015',
    'Nanded Sikh Gurudwara Sachkhand Shri Hazur Apchalnagar Sahib (Amendment) Act, 2015',
    'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2015',
    'Maharashtra Municipal Corporations (Amendment) Act, 2015',
    'Maharashtra Land Revenue Code (Amendment) Act, 2015',
    'Maharashtra Stamp (Amendment) Act, 2015',
    'Indian Forest (Maharashtra Amendment) Act, 2013',
    'Maharashtra Civil Courts (Amendment) Act, 2015',
    'Maharashtra (Second Supplementary) Appropriation Act, 2015',
    'Maharashtra Co-operative Societies (Amendment) Act, 2015',
    'Hyderabad Abolition of Inams and Cash Grants (Amendment) Act, 2015',
    'Maharashtra Entertainments Duty (Amendment) Act, 2015',
    'Maharashtra Land Revenue Code (Second Amendment) Act, 2015',
    'Maharashtra Unaided Private Professional Educational Institutions (Regulation of Admission and Fees) Act, 2015',
    'Maharashtra Universities (Temporary postponement of elections of members of university authorities and other bodies) Act, 2015',
    'Maharashtra Marine Fishing Regulation (Amendment) Act, 2015',
    'Maharashtra Right to Public Services Act, 2015',
    'Maharashtra Regional and Town Planning (Amendment) Act, 2015',
    'Maharashtra Fisheries (Amendment) Act, 2015',
    'Mumbai Municipal Corporation (Amendment) Act, 2015',
    'Maharashtra Municipal Corporations (Second Amendment) Act, 2015',
    'Maharashtra Official Languages (Amendment) Act, 2015',
    'Maharashtra Regional and Town Planning (Second Amendment) Act, 2015',
    'Sandip University Act, 2015',
    'MIT Art, Design and Technology University Act, 2015',
    'Factories (Maharashtra Amendment) Act, 2015',
    'Maharashtra Regional and Town Planning (Third Amemdment) Act, 2015',
    'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Third Amendment) Act, 2015',
    'Maharashtra Universities (Second Amendment) Act, 2015',
    'Maharashtra National Law University (Amendment) Act, 2015',
    'Maharashtra Advocates Welfare Fund (Amendment) Act, 2015',
    'Maharashtra Tenancy and Agricultural Lands, Hyderabad Tenancy and Agricultural Lands and Maharashtra Tenancy and Agricultural Lands (Vidarbha Region) (Amendment) Act, 2015',
    'Maharashtra State Public Services [Reservation for Scheduled Castes, Scheduled Tribes, De-notified Tribes (Vimukta Jatis), Nomadic Tribes, Special Backward Category and Other Backward Classes] (Amendment) Act, 2015',
    'Orphanges & Other Charitable Homes (Supervision & Control) the Persons with Disabilities (Equal Opportunities, Protection of Rights & Full Participation) & the Building & Other Construction Workers (Regulation of Employment & Conditions of Service) (Maharashtra Amendment) Act, 2009',
    'Maharashtra Prevention of Dangerous Activities of Slumlords, Bootleggers, Drug-offenders, Dangerous persons and video pirates (Amendment) Act, 2015',
    'Maharashtra Paramedical Council Act, 2011',
    'Maharashtra (Supplementary) Appropriation Act, 2016',
    'Maharashtra Appropriation (Vote on Account) Act, 2016',
    'Maharashtra Public Trusts (Amendment) Act, 2016',
    'Maharashtra Village Panchayats (Amendment) Act, 2016',
    'Maharashtra Land Revenue Code (Amendment) Act, 2016',
    'Maharashtra Prohibition of Obscene Dance in Hotels, Restaurants and Bar Rooms and Protection of Dignity of Women (working therein) Act, 2016',
    'Maharashtra Appropriation Act, 2016',
    'Maharashtra Water Conservation Corporation (Amendment) Act, 2016',
    'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2016',
    'Maharashtra Settlement of Arrears in Disputes Act, 2016',
    'Maharashtra Land Revenue Code (Second Amendment) Act, 2016',
    'Maharashtra Stamp (Amendment) Act, 2016',
    'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Fourth Amendment) Act, 2015',
    'Maharashtra Tenancy and Agricultural Lands, the Hyderabad Tenancy and Agricultural Lands and the Maharashtra Tenancy and Agricultural Lands (Vidarbha Region) (Second Amendment) Act, 2016',
    'Maharashtra Zilla Parishads and Panchayat Samities (Amendment) Act, 2016',
    'Maharashtra Universities (Amendment) Act, 2016',
    'Mumbai Municipal Corporation and Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2015',
    'Maharashtra (Second Supplementary) Appropriation Act, 2016',
    'Maharashtra Zilla Parishads and Panchayat Samitis (Second Amendment) Act, 2016',
    'Maharashtra Electricity Duty Act, 2016',
    'Maharashtra Land Revenue Code (Third Amendment) Act, 2016',
    'Maharashtra Highways (Amendment) Act, 2016',
    'Maharashtra Land Revenue Code (Fourth Amendment) Act, 2016',
    'Maharashtra Motor Vehicles Tax (Amendment) Act, 2016',
    'Maharashtra Repealing Act, 2016',
    'Maharashtra Legislative Council (Chairman and Deputy Chairman) and the Maharashtra Legislative Assembly (Speaker and Deputy Speaker) Salaries and Allowances, the Maharashtra Ministers Salaries and Allowances, the Maharashtra Legislature Members Salaries and Allowances, the Maharashtra Legislature Members Pension and the Leaders of Opposition in Maharashtra Legislature Salaries and Allowances (Amendment) Act, 2016',
    'Code of Criminal Procedure (Maharashtra Amendment) Act, 2015',
    'Maharashtra Co-operative Societies Act, 2016',
    'Maharashtra Agricultural Produce Marketing (Development and Regulaton) (Amendment and Continuance) Act, 2016',
    'Maharashtra Co-operative Societies (Second Amendment) Act, 2016',
    'Maharashtra Local Authority Members Disqualifications (Amendment) Act, 2016',
    'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2016',
    'Maharashtra (Third Supplementary) Appropriation Act, 2016',
    'Contract Labour (Regulation and Abolition) (Maharashtra Amendment) Act, 2016',
    'Maharashtra Metropolitan Region Development Authority Act, 2016',
    'Maharashtra Settlement of Arrears in Disputes (Amendment) Act, 2016',
    'Mumbai Municipal Corporation (Second Amendment) Act, 2016',
    'Maharashtra Public Universities Act, 2016',
    'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2016',
    'Maharashtra Water Resources Regulatory Authority (Amendment) Act, 2016',
    'Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2016',
    'Maharashtra Municipal Corporations (Amendment) Act, 2016',
    'Maharashtra Appropriation (Excess Expenditure) Act, 2016',
    'Maharashtra Appropriation (Second Excess Expenditure) Act, 2016',
    'Maharashtra (Urban Areas) Protection and Preservation of Trees (Amendment) Act, 2016',
    'Maharashtra Co-operative Societies (Third Amendment) Act, 2016',
    'Maharashtra Repealing (Second) Act, 2016',
    'Maharashtra Appropriation (Third Excess Expenditure) Act, 2016',
    'Maharashtra Legislative Council (Chairman and Deputy Chairman) and the Maharashtra Legislative Assembly (Speaker and Deputy Speaker) Salaries and Allowances, the Maharashtra Ministers Salaries and Allowances, the Maharashtra Legislature Members Salaries and Allowances and the Leaders of Opposition in Maharashtra Legislature Salaries and Allowances (Amendment) Act, 2016',
    'Maharashtra Aadhaar (Targeted Delivery of Financial and Other Subsidies, Benefits and Services) Act, 2016',
    'Maharashtra Prohibition (Amendment) Act, 2016',
    'Leaders of Opposition in Maharashtra Legislature Salaries and Allowances Act, 1978',
    'Maharashtra Mineral Development (Creation and Utilisation) Fund (Repeal) Act, 2016',
    'Maharashtra Land Revenue Code (Fifth Amendment) Act, 2016',
    'Mumbai Municipal Corporation (Amendment) Act, 2016',
    'Maharashtra (Supplementary) Appropriation Act, 2017',
    'Maharashtra Appropriation (Vote Account) Act, 2017',
    'Maharashtra Acupuncture System of Therapy Act, 2015',
    'Maharashtra Appropriation Act, 2017',
    'Mumbai Municipal Corporation (Amendment) Act, 2017',
    'Maharashtra Village Panchayats and Maharashtra Zilla Parishads and Panchayat Samitis (Amendment) Act, 2017',
    'Maharashtra State Commission for Backward Classes (Amendment) Act, 2017',
    'Maharashtra Land Revenue Code (Amendment) Act, 2017',
    'Maharashtra Tax Laws (Levy, Amendment and Validation) Act, 2017',
    'Maharashtra Regional and Town Planning (Amendment) Act, 2017',
    'Maharashtra Co-operative Societies (Amendment) Act, 2017',
    'Maharashtra Land Revenue Code (Second Amendment) Act, 2017',
    'Dr. Vishwanath Karad MIT World Peace University Act, 2016',
    'Maharashtra Paramedical Council (Amendment) Act, 2017',
    'Symbiosis Skills and Open University Act, 2017',
    'Vishwakarma University Act, 2017',
    'DSK World University Act, 2017',
    'Sanjay Ghodawat University, Kolhapur, Act, 2017',
    'Maharashtra Goods and Services Tax (Compensation to the Local Authorities) Act, 2017',
    'Maharashtra Goods and Services Tax related laws (Amendments, Validation and Savings) Act, 2017',
    'Maharashtra Goods and Services Tax Act, 2017 Notifications -12345',
    'Maharashtra Protection of People from Social Boycott (Prevention, Prohibition and Redressal) Act, 2016',
    'Prevention of Cruelty to Animals (Maharashtra Amendment) Act, 2017',
    'Maharashtra (Second Supplementary) Appropriation Act, 2017',
    'Maharashtra Stamp (Amendment) Act, 2017',
    'Shree Sai Baba Sansthan Trust (Shirdi) (Amendment) Act, 2017',
    'Maharashtra Prohibition (Amendment) Act, 2017',
    'Maharashtra Motor Vehicles Tax (Amendment) Act, 2017',
    'Mumbai Municipal Corporation and the Maharashtra Municipal Corporations (Amendment) Act, 2017',
    'Mumbai Municipal Corporation, the Maharashtra Municipal Corporations and the Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2017',
    'Maharashtra Municipal Corporations (Amendment) Act, 2017',
    'Maharashtra Land Revenue Code and the Maharashtra Land Revenue (Conversion of Use of Land and Nonagricultural Assessment) Rules (Amendment) Act, 2017',
    'Maharashtra Public Trusts (Second Amendment) Act, 2017',
    'Maharashtra Legislature Members (Removal of Disqualification) (Amendment) Act, 2017',
    'Maharashtra Co-operative Societies (Second Amendment) Act, 2017',
    'Maharashtra Prevention of Fragmentation and Consolidation of Holdings (Amendment) Act, 2017',
    'Maharashtra Stamp (Second Amendment) Act, 2017',
    'Maharashtra Land Revenue Code (Third Amendment) Act, 2017',
    'Maharashtra Shops and Establishments (Regulation of Employment and Conditions of Service) Act, 2017',
    'Maharashtra (Third Supplementary) Appropriation Act, 2017',
    'D. Y. Patil International University, Pune, Act, 2017',
    'Maharashtra Local Authority Members Disqualification (Amendment) Act, 2017',
    'Pandharpur Temples (Amendment) Act, 2017',
    'Maharashtra Appropriation (Excess Expenditure) Act, 2017',
    'Maharashtra Public Trusts (Third Amendment) Act, 2017',
    'Maharashtra Stamp (Amendment and Validation) Act, 2017',
    'Maharashtra Land Revenue Code (Fourth Amendment) Act, 2017',
    'Maharashtra Municipal Corporations (Second Amendment) Act, 2017',
    'Maharashtra Municipal Councils, Nagar Panchayats and Industrial Townships (Amendment) Act, 2017',
    'Maharashtra Witness Protection and Security Act, 2017',
    'Maharashtra Court-fees (Amendment) Act, 2017',
    'Maharashtra Village Panchayats (Amendment) and the Maharashtra Village Panchayats (Payment of Lumpsum Contribution by Factories in Lieu of Taxes) Rules (Repeal) Act, 2017',
    'Maharashtra Land Revenue Code (Fifth Amendment) Act, 2017',
    'Maharashtra Agricultural Produce Marketing (Development and Regulation) (Amendment) Act, 2017',
    'Maharashtra Co-operative Societies (Third Amendment) Act, 2017',
    'Maharashtra Lifts, Escalators and Moving Walks Act, 2017',
    'Indian Partnership (Maharashtra Amendment) Act, 2017',
    'Apprentices (Maharashtra Amendment) Act, 2017',
    'Maharashtra Essential Services Maintenance Act, 2017',
    'Maharashtra Agricultural Lands (Ceiling on Holdings) (Amendment) Act, 2017'
];

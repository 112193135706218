import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatSidenavModule,
  MatInputModule, MatRadioModule, MatSelectModule, MatSliderModule, MatTooltipModule, MatSortModule, MatExpansionModule,
  MatSlideToggleModule, MatToolbarModule, MatListModule, MatGridListModule, MatSnackBarModule, MatPaginatorModule, MatTabsModule,
  MatCardModule, MatIconModule, MatProgressSpinnerModule, MatDialogModule, MatTableModule, MatAutocompleteModule, MatProgressBarModule,
  MatChipsModule, MatBadgeModule, MatMenuModule, MatDividerModule, MatButtonToggleModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RestangularModule, Restangular } from 'ngx-restangular';
import { OwlModule } from 'ngx-owl-carousel';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

// import { Marka} from '../../node_modules/mark.js';

// import { Network, Timeline } from 'vis';

import 'hammerjs';

import { OnlyNumberDirective } from './directives/OnlyNumber';

import { baseURL } from './shared/baseurl';
import { RestangularConfigFactory } from './shared/restConfig';

import { HomepageService } from './services/homepage.service';
import { ProcessHttpmsgService } from './services/process-httpmsg.service';
import { AuthService } from './services/auth.service';

import { AuthEffects } from './store/effects/auth.effects';
import { reducers } from './store/app.states';

import { BrowseService } from './services/browse.service';
import { AuthInterceptor, UnauthorizedInterceptor} from './services/auth.interceptor';
import { SearchService } from './services/search.service';
import { ProfileService } from './services/profile.service';
import { AuthGuard } from './guards/auth.guard';
import { MessagingService } from './services/messaging.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeRegistrationComponent } from './home-registration/home-registration.component';
import { WebfeaturesComponent } from './webfeatures/webfeatures.component';
import { AppfeaturesComponent } from './appfeatures/appfeatures.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { BrowseComponent, MarketingComponent } from './browse/browse.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PaymentgatewayComponent } from './paymentgateway/paymentgateway.component';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { UserprofileComponent } from './userprofile/userprofile.component';
import { SearchComponent } from './search/search.component';
import { VisCaseprofileComponent } from './vis-caseprofile/vis-caseprofile.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TypesComponent } from './types/types.component';
import { HeaderComponent } from './header/header.component';
import { LatestjudgComponent } from './latestjudg/latestjudg.component';
import { ImpjudgComponent } from './impjudg/impjudg.component';
import { OftenjudgComponent } from './oftenjudg/oftenjudg.component';
import { LandmarkjudgComponent } from './landmarkjudg/landmarkjudg.component';
import { TimelineModalComponent } from './timeline-modal/timeline-modal.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AnnotationComponent } from './annotation/annotation.component';
import { AnnotationProfileComponent } from './annotation-profile/annotation-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeRegistrationComponent,
    WebfeaturesComponent,
    AppfeaturesComponent,
    HomepageComponent,
    LoginComponent,
    SignupComponent,
    BrowseComponent,
    AboutusComponent,
    PaymentgatewayComponent,
    PaymentResponseComponent,
    UserprofileComponent,
    SearchComponent,
    VisCaseprofileComponent,
    TypesComponent,
    HeaderComponent,
    LatestjudgComponent,
    ImpjudgComponent,
    OftenjudgComponent,
    LandmarkjudgComponent,
    TimelineModalComponent,
    PagenotfoundComponent,
    AnnotationComponent,
    OnlyNumberDirective,
    AnnotationProfileComponent,
    MarketingComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatSidenavModule,
    MatInputModule, MatRadioModule, MatSelectModule, MatSliderModule, MatTooltipModule, MatSortModule, MatDividerModule,
    MatSlideToggleModule, MatToolbarModule, MatListModule, MatGridListModule, MatSnackBarModule, MatPaginatorModule,
    MatCardModule, MatIconModule, MatProgressSpinnerModule, MatDialogModule, MatTableModule, MatExpansionModule,
    MatAutocompleteModule, MatProgressBarModule, MatTabsModule, MatChipsModule, MatBadgeModule, MatMenuModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    OwlModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    RestangularModule.forRoot(RestangularConfigFactory),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    ScrollingModule,
    StoreModule.forRoot(reducers, {}),
    EffectsModule.forRoot([AuthEffects]),
    DeviceDetectorModule.forRoot()
    // Network, Timeline
  ],
  entryComponents: [
    LoginComponent,
    SignupComponent,
    VisCaseprofileComponent,
    TimelineModalComponent,
    AnnotationComponent,
    AnnotationProfileComponent,
    MarketingComponent
  ],
  providers: [
    { provide: 'BaseURL', useValue: baseURL },
    HomepageService,
    ProcessHttpmsgService,
    AuthService,
    AuthEffects,
    BrowseService,
    SearchService,
    ProfileService,
    AuthGuard,
    MessagingService,
    AsyncPipe,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

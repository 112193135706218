import { Component, OnInit } from "@angular/core";
import { RouterModule, Routes, Router } from "@angular/router";
import {MatDialog, MatChip, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from "@angular/material";

import { AuthService } from "../services/auth.service";
import { BrowseService } from "../services/browse.service";
import { Judgement } from "../shared/judgement";

import { CookieService } from "ngx-cookie-service";
import { timer } from "rxjs";

@Component({
  selector: "app-types",
  templateUrl: "./types.component.html",
  styleUrls: ["./types.component.scss"]
})
export class TypesComponent implements OnInit {

  latest_spinner = false;
  often_spinner = false;
  landmark_spinner = false;
  important_spinner = false;

  usertype: string;

  latestJudgments: Judgement[] = [];
  importantJudgments: Judgement[] = [];
  oftenJudgments: Judgement[] = [];
  landmarkJudgments: Judgement[] = [];

  latestJudgCount: number;
  impJudgCount: string;
  oftenCitedJudgCount: string;
  landmarkJudgCount: string;

  latestPageNo = 1;
  latestTotalPages: number;
  impPageNo = 1;
  impTotalPages: number;
  oftenCitedPageNo = 1;
  oftenTotalPages: number;
  landmarkPageNo = 1;
  landmarkTotalPages: number;

  latestpagination = false;

  fontSize = 16;
  zoomInCount = 0;
  zoomOutCount = 0;
  zoomCount = 0;

  constructor(
    private authService: AuthService,
    private browseService: BrowseService,
    private cookieService: CookieService,
    private router: Router,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    // this.usertype = localStorage.getItem('usertype');
    this.usertype = this.cookieService.get("usertype");
    this.latest_spinner = true;
    this.often_spinner = true;
    this.landmark_spinner = true;
    this.important_spinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(1000).subscribe(() => {
        this.browseService.getLatestJudgements(0, this.usertype, "").subscribe(data => {
          this.latest_spinner = false;
          if ( data.hits.total > 0 ) {
            this.latestJudgCount = data.hits.total;
            for ( let i = 0; i < data.hits.hits.length; i++ ) {
              this.latestJudgments.push(data.hits.hits[i]._source);
            }
    
            // calculate total pages
            const a = this.latestJudgCount / 15;
            if ( this.latestJudgCount % 15 === 0 ) {
              this.latestTotalPages = a;
            } else if ( this.latestJudgCount % 15 > 0 ) {
              this.latestTotalPages = a + 1;
            }
    
            if ( a > 1 ) {
              this.latestpagination = true;
            } else {
              this.latestpagination = false;
            }
          }
        });
      });
    });
  }

  GetParent(elem) {
    speechSynthesis.cancel();
    const parentElem = document.getElementById(elem).parentElement.innerText;
    const text = parentElem.substr(33);
    const text1 = text.slice(0, -24);
    text1.replace(/\./g, " ");
    text1.replace(/\:/g, " ");
    const texttospeak = new SpeechSynthesisUtterance(text1);
    speechSynthesis.speak(texttospeak);
  }

  StopSpeech() {
    speechSynthesis.cancel();
  }

  ZoomIn(id) {
    this.zoomCount += 1;
    if ( this.zoomCount < 9 ) {
      this.fontSize += 1.2;  
      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if ( this.zoomCount >= 9 ) {
      this.zoomCount = 9;
    }
  }

  ZoomOut(id) {
    this.zoomCount -= 1;
    if ( this.zoomCount > -4 ) {
      this.fontSize -= 1.2;
      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if ( this.zoomCount < -3 ) {
      this.zoomCount = -3;
    }
  }

  nextLatestPage() {
    this.latestJudgments = [];
    this.latestPageNo += 1;

    if ( this.latestPageNo > this.latestTotalPages ) {
      this.snackBar.open("End of result!", "OK");
    } else {
      this.latest_spinner = true;
      const page_no = (this.latestPageNo - 1 ) * 15;

      this.authService.checkJWTtoken().then(() => {
        timer(1000).subscribe(() => {
          this.browseService.getLatestJudgements(page_no, this.usertype, "").subscribe(data => {
            this.latest_spinner = false;
    
            for ( let i = 0; i < data.hits.hits.length; i++ ) {
              this.latestJudgments.push(data.hits.hits[i]._source);
            }
    
    
          });
        });
      });
    }
  }

  previousLatestPage() {
    this.latestJudgments = [];
    this.latestPageNo -= 1;

    if ( this.latestPageNo > 0 ) {
      this.latest_spinner = true;
      const page_no = (this.latestPageNo - 1 ) * 15;

      this.authService.checkJWTtoken().then(() => {
        timer(1000).subscribe(() => {
          this.browseService.getLatestJudgements(page_no, this.usertype, "").subscribe(data => {
            this.latest_spinner = false;
    
            for ( let i = 0; i < data.hits.hits.length; i++ ) {
              this.latestJudgments.push(data.hits.hits[i]._source);
            }
    
    
          });
        });
      });
    } else {
      this.snackBar.open("Start of a result!", "OK");
    }
  }

}

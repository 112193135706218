import { Injectable } from "@angular/core";

import { Judgement } from "../shared/judgement";

import { baseURL } from "../shared/baseurl";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Http, Headers } from "@angular/http";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";

import { ProcessHttpmsgService } from "./process-httpmsg.service";
import { AuthService } from "./auth.service";

import { map, delay, catchError} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class SearchService {

  judgement: Judgement;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService,
    private authService: AuthService
  ) { }

  search(data, usertype, pagenumber): Observable<any> {
    const authToken = this.authService.getToken();
    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);
    // console.log('headers', headers);

    const params = new HttpParams();
    params.set("q", data);
    params.set("usertype", usertype);
    console.log(baseURL + "nearlaw/search/" + pagenumber + "?q=" + data + "&usertype=" + usertype);
    return this.http.get(baseURL + "nearlaw/search/" + pagenumber + "?q=" + data + "&usertype=" + usertype, {headers: headers, params: params})
    .pipe(map(res => res), catchError(error => {
      return this.processHTTPMsgService.handleError(error);
    }));
  }

  mainnode(citation: string): Observable<any> {
    const authToken = this.authService.getToken();
    // // console.log('token', this.authService.getToken());

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    return this.http.get(baseURL + "nearlaw/nodes/" + citation, {headers: headers})
    .pipe(map(res => res), catchError(error => {
      return this.processHTTPMsgService.handleError(error);
    }));
  }

  // link(id): Observable<any> {
  //   const authToken = this.authService.getToken();
  //   // // console.log('token', this.authService.getToken());

  //   let headers = new HttpHeaders();
  //   headers.append('Authorization', 'bearer ' + authToken);

  //   return this.http.get(baseURL + 'nearlaw/link/' + id, {headers: headers})
  //   .pipe(map(res => res), catchError(error => {
  //     return this.processHTTPMsgService.handleError(error);
  //   }));
  // }

  findByNodeId(id): Observable<any> {
    const authToken = this.authService.getToken();
    // // console.log('token', this.authService.getToken());

    const headers = new HttpHeaders();
    headers.append("Authorization", "bearer " + authToken);

    return this.http.get(baseURL + "nearlaw/findbynodeid/" + id, {headers: headers})
    .pipe(map(res => res), catchError(error => {
      return this.processHTTPMsgService.handleError(error);
    }));
  }
}

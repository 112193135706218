import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { AuthService } from "../services/auth.service";
import { DOCUMENT } from "@angular/common";
import { baseURL } from "../shared/baseurl";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-paymentgateway",
  templateUrl: "./paymentgateway.component.html",
  styleUrls: ["./paymentgateway.component.scss"]
})
export class PaymentgatewayComponent implements OnInit {
  showOTPfield = false;
  isDisabled = true;
  paymentWay = 2;
  states = [
    { value: "Maharashtra" },
    { value: "Goa" },
    { value: "Andhra Pradesh" },
    { value: "Andaman and Nicobar Islands (UT)" },
    { value: "Arunachal Pradesh" },
    { value: "Assam" },
    { value: "Bihar" },
    { value: "Chandigarh (UT)" },
    { value: "Chhatisgarh" },
    { value: "Dadar and Nagar Haveli (UT)" },
    { value: "Daman and Diu (UT)" },
    { value: "Delhi (UT)" },
    { value: "Gujarat" },
    { value: "Haryana" },
    { value: "Himachal Pradesh" },
    { value: "Jammu and Kashmir" },
    { value: "Jharkhand" },
    { value: "Karnataka" },
    { value: "Kerela" },
    { value: "Lakshadweep (UT)" },
    { value: "Madhya Pradesh" },
    { value: "Manipur" },
    { value: "Meghalaya" },
    { value: "Mizoram" },
    { value: "Nagaland" },
    { value: "Puducherry (UT)" },
    { value: "Odisha" },
    { value: "Punjab" },
    { value: "Rajasthan" },
    { value: "Sikkim" },
    { value: "Tamil Nadu" },
    { value: "Telangana" },
    { value: "Tripura" },
    { value: "Uttar Pradesh" },
    { value: "Uttarakhand" },
    { value: "West Bengal" }
  ];

  packages = [
    {
      value: 12000,
      view: "ALLMR CD 11.1 NEW Installation (Rs.15,000/- 20% discount)"
    },
    {
      value: 5500,
      view: "ALLMR CD 11.1 Renewal Update (Rs.5,500/-)"
    },
    {
      value: 8800,
      view:
        "ALLMR CD 10.1 + 11.1 2-year Renewal Update (Rs.11,000/- 20% discount)"
    },
    {
      value: 10000,
      view:
        "ALLMR CD 9.1 + 10.1 + 11.1 3-year Renewal Update (Rs.15,500/- 35% discount)"
    },
    {
      value: 6999,
      view: "NearLaw 12-months Access (Rs.5999/-)"
    },
    {
      value: 5000,
      view: "Renewal 12-months Access (Rs.5000/-)"
    },
    {
      value: 4000,
      view: "NearLaw 6-months Access (Rs.4,000/-)"
    },
    {
      value: 1000,
      view: "NearLaw 1-month Access (Rs.1,000/-)"
    }
  ];

  name = new FormControl("", [
    Validators.required,
    Validators.pattern("[a-zA-Z ]*"),
    Validators.minLength(5),
    Validators.maxLength(50)
  ]);

  state = new FormControl("", [Validators.required]);
  amountValue = new FormControl("");
  email = new FormControl("", [Validators.email]);

  street = new FormControl("");
  city = new FormControl("");
  zip = new FormControl("");
  // Contact No
  contactno = new FormControl("", [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10)
  ]);

  package = new FormControl([Validators.required]);
  amount: Number;
  product: String;
  prod: String;
  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document // to make use of document object
  ) {
    // to initialize the package
    this.state.setValue(this.states[0].value, { onlySelf: true });
    this.package.setValue(this.packages[0].value, { onlySelf: true });
    this.amount = this.packages[0].value;
    this.prod = "WEB";
    this.cookieService.deleteAll();
  }

  ngOnInit() {
    this.amountValue.valueChanges.subscribe(x => {
      this.amount = x;
    });
  }

  /**
   * @author Dharmil Parekh
   * @returns Void
   * @description Sets the product value;
   */
  _setProduct(value) {
    this.prod = [12000, 5500, 8800, 10000].includes(value) ? "CD" : "WEB";
  }
  /**
   * @author Dharmil Parekh
   * @returns Void
   * @description Updates the amount and internally sets the kind of Product
   */
  updateAmount() {
    if (this.paymentWay === 2) {
      this.amount = this.package.value;
      this._setProduct(this.package.value);
    } else {
      this.amount = 0;
    }
  }
  /**
   * @author Dharmil Parekh
   * @returns Void
   * @description Sends Data to the server via auth services
   */
  onPayClick() {
    if (
      this.contactno.status !== "VALID" ||
      this.amount === null ||
      this.name.status !== "VALID" ||
      this.state.status !== "VALID"
    ) {
      return 0;
    } else {
      this.authService
        .submitPaymentData({
          contactno: this.contactno.value,
          email: this.email.value,
          name: this.name.value,
          state: this.state.value,
          product: this.prod,
          amount: this.amount
        })
        .subscribe(data => {
          localStorage.setItem("paydetails", JSON.stringify(data));
          this.document.location.href = `${baseURL}assets/html/gotopaytm.html`;
        });
    }
  }
}

import { Component, OnInit } from "@angular/core";
import { RouterModule, Routes, Router } from "@angular/router";
import {
  MatDialog,
  MatSelectChange,
  MatChip,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar
} from "@angular/material";
import { AuthService } from "../services/auth.service";
import { BrowseService } from "../services/browse.service";
import { CookieService } from "ngx-cookie-service";
import { Judgement } from "../shared/judgement";
import { User } from "../shared/user";
import { courtNameForLatestJudg } from "../shared/courtnames";
import { AnnotationComponent } from "../annotation/annotation.component";
import { timer } from "rxjs";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-impjudg",
  templateUrl: "./impjudg.component.html",
  styleUrls: ["./impjudg.component.scss"]
})
export class ImpjudgComponent implements OnInit {
  user = new User();
  contactno: string;
  important_spinner = false;
  usertype: string;
  importantJudgments: Judgement[] = [];
  importantJudgCount: number;
  importantPageNo = 1;
  importantTotalPages: number;
  importantpagination = false;
  fontSize = 16;
  zoomInCount = 0;
  zoomOutCount = 0;
  zoomCount = 0;
  playSpeech = false;
  counterSpeech = 0;
  showFilters = true;
  showCourtFilters = true;
  isMobile = false;
  isDesktop = false;
  isTablet = false;
  totalResult: number = null;
  sortValue = "desc";
  filterDateFrom: Date = null;
  filterDateTo: Date = null;
  maxDate: Date = new Date(Date.now());
  showDateFilterCancelBtn = false;
  bookmarkSpinner = false;
  isSingleJudg = false;
  showListView = true;
  opened = false;
  singleJudg: Judgement;
  query = "";
  filterCourtList = courtNameForLatestJudg;
  filterCourtValue: string = null;
  filterSearchQuery = "";
  filterSearchQueryValue = "";
  filterQuery = "";
  filterDateQuery = "";
  filterCourtQuery = "";
  disablePreviousBtn = false;
  disableNextBtn = false;

  constructor(
    private authService: AuthService,
    private browseService: BrowseService,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    public snackBar: MatSnackBar,
    public annotationModal: MatDialog
  ) {}

  ngOnInit() {
    this.usertype = this.cookieService.get("usertype");
    this.contactno = this.cookieService.get("contactno");
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    if (this.isMobile === true) {
      this.showCourtFilters = false;
    }

    this.authService.checkJWTtoken().then(() => {
      timer(2000).subscribe(() => {
        this.authService
          .getUserByContactno(this.contactno)
          .subscribe(userdata => {
            this.user = userdata;
          });
      });
    });

    this.important_spinner = true;
    timer(3000).subscribe(() => {
      this.browseService
        .getimportantJudgements(0, this.usertype, this.sortValue)
        .subscribe(data => {
          this.important_spinner = false;
          if (data.hits.total > 0) {
            this.totalResult = data.hits.total;
            this.importantJudgCount = data.hits.total;
            for (let i = 0; i < data.hits.hits.length; i++) {
              this.importantJudgments.push(data.hits.hits[i]._source);
            }
            this.disablePreviousBtn = true;
            // retaining appropriate boomark icon on page load
            setTimeout(() => {
              this.importantJudgments.forEach(data => {
                let i = 0;
                i += 1;
                const citation = data.Citation;
                if (
                  this.user.bookmarks.find(cit => cit === citation) !==
                  undefined
                ) {
                  const x = document.getElementById(citation + " active");
                  if (x !== null) {
                    x.style.display = "block";
                  }
                  const y = document.getElementById(citation + " inactive");
                  if (y !== null) {
                    y.style.display = "none";
                  }
                } else {
                  const x = document.getElementById(citation + " active");
                  if (x !== null) {
                    x.style.display = "none";
                  }
                  const y = document.getElementById(citation + " inactive");

                  if (y !== null) {
                    y.style.display = "block";
                  }
                }
              });
            }, 100);

            // retaining appropriate annotation icon on page load
            setTimeout(() => {
              this.importantJudgments.forEach(data => {
                let i = 0;
                i += 1;

                const citation = data.Citation;

                if (
                  this.user.annotations.find(z => z.citation === citation) !==
                  undefined
                ) {
                  const x = document.getElementById(
                    citation + " annotation active"
                  );

                  if (x !== null) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(
                    citation + " annotation inactive"
                  );

                  if (y !== null) {
                    y.style.display = "none";
                  }
                } else {
                  const x = document.getElementById(
                    citation + " annotation active"
                  );

                  if (x !== null) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(
                    citation + " annotation inactive"
                  );

                  if (y !== null) {
                    y.style.display = "block";
                  }
                }
              });
            }, 200);

            // calculate total pages
            const a: number = this.importantJudgCount / 15;
            if (this.importantJudgCount % 15 === 0) {
              this.importantTotalPages = a;
            } else if (this.importantJudgCount % 15 > 0) {
              const temp = a.toFixed(1);
              const digit = temp[temp.length - 1];

              if (+digit < 5) {
                this.importantTotalPages = +a.toFixed() + 1;
              } else {
                this.importantTotalPages = +a.toFixed();
              }
            }

            if (a > 1) {
              this.importantpagination = true;
            } else {
              this.importantpagination = false;
            }
          }
        });
    });
  }
  /**
   * to insert the pdf in session storage
   * @param pdfName
   */
  setpdf(pdfName) {
    pdfName = "https://nearlaw.com/PDF/" + pdfName;
    sessionStorage.setItem("pdfUrl", pdfName);
  }
  GetParent(elem) {
    this.counterSpeech = this.counterSpeech + 1;
    // // console.log('counterSpeech', this.counterSpeech);
    this.playSpeech = !this.playSpeech;
    // // console.log('playSpeech', this.playSpeech);
    if (this.playSpeech === true) {
      if (this.counterSpeech > 1) {
        speechSynthesis.resume();
      } else {
        speechSynthesis.cancel();
        const parentElem = document.getElementById(elem).parentElement
          .innerText;
        const text = parentElem.substr(65);
        const text1 = text.slice(0, -19);
        text1.replace(/\./g, " ");
        text1.replace(/\:/g, " ");
        const chunk = text1.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,15}\b/g); // spillting at \n and every 15th whitespace
        const ttsArray: SpeechSynthesisUtterance[] = [];
        chunk.forEach(chunkElem => {
          ttsArray.push(new SpeechSynthesisUtterance(chunkElem));
        });
        ttsArray.forEach(ttsElem => {
          ttsElem.lang = "en-IN";
          ttsElem.pitch = 1;
          ttsElem.rate = 0.9;
        });
        // // console.log('ttsArray', ttsArray);
        ttsArray.forEach(ttsElem => {
          // ttsElem.lang = 'en-IN';
          // ttsElem.pitch = 1;
          // ttsElem.rate = 0.9;
          window.speechSynthesis.speak(ttsElem);
        });

        //  var texttospeak = new SpeechSynthesisUtterance(text1);
        // // console.log('tts', texttospeak);
        // window.speechSynthesis.speak(texttospeak);
        // const recognition = this.speechRecognition;
        // recognition.onspeechend = () => {
        //// console.log('Speech has stopped being detected');
        // }
      }
    } else if (this.playSpeech === false) {
      speechSynthesis.pause();
    }
  }

  StopSpeech() {
    speechSynthesis.cancel();
    this.counterSpeech = 0;
    this.playSpeech = false;
  }

  ZoomIn(id) {
    this.zoomCount += 1;
    // //('zoomincount', this.zoomCount);
    if (this.zoomCount < 9) {
      this.fontSize += 1.2;
      // //('fontsize', this.fontSize);

      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if (this.zoomCount >= 9) {
      this.zoomCount = 9;
    }
  }

  ZoomOut(id) {
    this.zoomCount -= 1;
    // //('zoomoutcount', this.zoomCount);

    if (this.zoomCount > -4) {
      this.fontSize -= 1.2;
      // //('fontsize', this.fontSize);

      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if (this.zoomCount < -3) {
      this.zoomCount = -3;
    }
  }

  nextimportantPage() {
    this.importantJudgments = [];
    this.importantPageNo += 1;

    if (this.importantPageNo > 1) {
      this.disablePreviousBtn = false;
    }

    if (this.importantPageNo === this.importantTotalPages) {
      this.disableNextBtn = true;
    }

    if (this.importantPageNo > this.importantTotalPages) {
      this.snackBar.open("End of result!", "OK");
    } else {
      this.important_spinner = true;
      const page_no = (this.importantPageNo - 1) * 15;
      // this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService
            .getimportantJudgements(page_no, this.usertype, this.sortValue)
            .subscribe(data => {
              this.important_spinner = false;
              for (let i = 0; i < data.hits.hits.length; i++) {
                this.importantJudgments.push(data.hits.hits[i]._source);
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.importantJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.importantJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            });
        });
      // });
    }
  }

  previousimportantPage() {
    this.importantJudgments = [];
    this.importantPageNo -= 1;

    if (this.importantPageNo === 1) {
      this.disablePreviousBtn = true;
    }

    if (this.importantPageNo < this.importantTotalPages) {
      this.disableNextBtn = false;
    }

    if (this.importantPageNo > 0) {
      this.important_spinner = true;
      const page_no = (this.importantPageNo - 1) * 15;
      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService
            .getimportantJudgements(page_no, this.usertype, this.sortValue)
            .subscribe(data => {
              // //('important next', data);
              this.important_spinner = false;

              for (let i = 0; i < data.hits.hits.length; i++) {
                this.importantJudgments.push(data.hits.hits[i]._source);
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.importantJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.importantJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            });
        });
      });
    } else {
      this.snackBar.open("Start of a result!", "OK");
    }
  }

  singlePrint(id: string) {
    const printContents = document.getElementById(id).innerHTML;
    const w = window.open();
    w.document.write("<h1 align=\"center\" style=\"color: blue;\">NearLaw</h1>");
    w.document.write(
      "<div style=\"text-align: justify\">" + printContents + "</div>"
    );
    w.document.write("<style>.hidden-print{display:none!important}</style>");

    w.document.write(
      "<scr" +
        "ipt type=\"text/javascript\">" +
        "window.onload = function() { window.print(); window.close(); };" +
        "</sc" +
        "ript>"
    );

    w.document.close(); // necessary for IE >= 10
    w.focus(); // necessary for IE >= 10
    return true;
  }

  userBoomarks(judgement) {
    const citation = judgement.Citation;
    if (this.user.bookmarks.find(cit => cit === citation) === undefined) {
      // to check if case is already bookarked
      if (this.user.bookmarks[0] === "") {
        this.user.bookmarks.splice(0, 1);
      }
      if (this.user.usertype === "Paid" || this.user.usertype === "MPP") {
        // Paid user can add unlimited bookmarks
        this.user.bookmarks.push(citation);
        const token = this.cookieService.get("JWT");
        const id = this.cookieService.get("ID");

        this.bookmarkSpinner = true;

        this.authService.checkJWTtoken().then(() => {
          timer(3000).subscribe(() => {
            this.authService
              .updateUserWithToken(id, this.user, token)
              .subscribe(userdata => {
                this.user = userdata.result;

                this.bookmarkSpinner = false;
                // ('userdata', this.user);
                // this.isBookmarked = true;
                const x = document.getElementById(citation + " active");
                x.style.display = "block";
                const y = document.getElementById(citation + " inactive");
                y.style.display = "none";
                this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                  duration: 3000
                });
              });
          });
        });
      } else if (
        this.user.usertype === "Trial" ||
        this.user.usertype === "Free"
      ) {
        // Trial and Free users can add only 5 bookmarks
        if (this.user.bookmarks.length < 5) {
          this.user.bookmarks.push(citation);
          // ('user', this.user);
          // const token = localStorage.getItem('JWT');
          const token = this.cookieService.get("JWT");
          // const id = localStorage.getItem('ID');
          const id = this.cookieService.get("ID");

          this.bookmarkSpinner = true;

          this.authService.checkJWTtoken().then(() => {
            timer(3000).subscribe(() => {
              this.authService
                .updateUserWithToken(id, this.user, token)
                .subscribe(userdata => {
                  this.user = userdata.result;

                  this.bookmarkSpinner = false;
                  // ('userdata', this.user);
                  // this.isBookmarked = true;
                  const x = document.getElementById(citation + " active");
                  x.style.display = "block";
                  const y = document.getElementById(citation + " inactive");
                  y.style.display = "none";
                  this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                    duration: 3000
                  });
                });
            });
          });
        } else {
          const upgradeSnackbar = this.snackBar.open(
            "To save more than 5 bookmarks, please Upgrade to premium plan!",
            "Upgrade",
            {
              duration: 3000
            }
          );
          upgradeSnackbar.onAction().subscribe(() => {
            this.router.navigate(["/paymentgateway"]);
          });
        }
      }
    } else {
      this.user.bookmarks.splice(this.user.bookmarks.indexOf(citation), 1);
      const token = this.cookieService.get("JWT");
      const id = this.cookieService.get("ID");

      this.bookmarkSpinner = true;

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.authService
            .updateUserWithToken(id, this.user, token)
            .subscribe(userdata => {
              this.user = userdata.result;

              this.bookmarkSpinner = false;
              // ('userdata', this.user);
              // this.isBookmarked = false;
              const x = document.getElementById(citation + " active");
              x.style.display = "none";
              const y = document.getElementById(citation + " inactive");
              y.style.display = "block";
              this.snackBar.open("Successfully removed from Bookmarks!", "OK", {
                duration: 3000
              });
            });
        });
      });
    }
  }

  openAnnotationModal() {
    const loginDialogRef = this.annotationModal.open(AnnotationComponent, {
      width: "400px",
      height: "400px"
    });
  }

  userAnnotations(judgement) {
    const citation = judgement.Citation;

    if (
      this.user.annotations.find(
        annotation => annotation.citation === citation
      ) === undefined ||
      this.user.annotations.length === 0
    ) {
      this.cookieService.set("cit", citation);
      this.openAnnotationModal();
    } else {
      this.user.annotations.forEach(annotation => {
        if (annotation.citation === citation) {
          const cit = annotation.citation;
          const body = annotation.body;

          this.cookieService.set("cit", cit);

          this.cookieService.set("body", body);
          this.openAnnotationModal();
        }
      });
    }
  }

  searchImportantJudg() {
    const from = this.filterDateFrom
      .toISOString()
      .replace("T", " ")
      .slice(0, -5);
    const to = this.filterDateTo
      .toISOString()
      .replace("T", " ")
      .slice(0, -5);
    this.filterDateQuery = "doj_sort:[\"" + from + "\"+TO+\"" + to + "\"] AND ";
    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);
    this.important_spinner = true;
    this.importantJudgments = [];
    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService
          .searchImportantJudgements(
            0,
            this.usertype,
            this.sortValue,
            this.query
          )
          .subscribe(resp => {
            this.important_spinner = false;
            this.showDateFilterCancelBtn = true;

            this.importantPageNo = 1;
            this.totalResult = resp.hits.total.value;
            if (resp.hits.total.value > 0) {
              for (let i = 0; i < resp.hits.hits.length; i++) {
                this.importantJudgments.push(resp.hits.hits[i]._source);
              }

              this.disablePreviousBtn = true;
              this.disableNextBtn = false;

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.importantJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.importantJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);

              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.importantTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.importantTotalPages = +a.toFixed() + 1;
                } else {
                  this.importantTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.importantpagination = true;
              } else {
                this.importantpagination = false;
              }
            } else {
              this.snackBar.open("No Results found!", "OK", {
                duration: 4000
              });
            }
          });
      });
    });
  }

  defaultSearch() {
    this.filterDateFrom = null;
    this.filterDateTo = null;
    this.important_spinner = true;
    this.importantJudgments = [];
    // this.query = '';
    this.filterDateQuery = "";
    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);

    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService
          .searchImportantJudgements(
            0,
            this.usertype,
            this.sortValue,
            this.query
          )
          .subscribe(resp => {
            this.important_spinner = false;
            this.showDateFilterCancelBtn = false;

            this.importantPageNo = 1;
            this.totalResult = resp.hits.total.value;
            if (resp.hits.total.value > 0) {
              for (let i = 0; i < resp.hits.hits.length; i++) {
                this.importantJudgments.push(resp.hits.hits[i]._source);
              }

              this.disablePreviousBtn = true;
              this.disableNextBtn = false;

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.importantJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.importantJudgments.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }
                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }
                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);

              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.importantTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.importantTotalPages = +a.toFixed() + 1;
                } else {
                  this.importantTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.importantpagination = true;
              } else {
                this.importantpagination = false;
              }
            }
          });
      });
    });
  }

  getJudgOnSort() {
    this.sortValue = this.sortValue === "desc" ? "asc" : "desc";
    if (this.sortValue === "desc") {
      this.important_spinner = true;
      this.importantJudgments = [];
      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService
            .searchImportantJudgements(
              0,
              this.usertype,
              this.sortValue,
              this.query
            )
            .subscribe(resp => {
              this.important_spinner = false;
              // // console.log('data', resp);
              this.importantPageNo = 1;
              this.totalResult = resp.hits.total.value;
              if (resp.hits.total.value > 0) {
                for (let i = 0; i < resp.hits.hits.length; i++) {
                  this.importantJudgments.push(resp.hits.hits[i]._source);
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.importantJudgments.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.importantJudgments.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);

                if (this.showListView === false) {
                  this.singleJudg = this.importantJudgments[0];
                  this.isSingleJudg = true;
                }
              }
            });
        });
      });
    } else if (this.sortValue === "asc") {
      this.important_spinner = true;
      this.importantJudgments = [];
      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService
            .searchImportantJudgements(
              0,
              this.usertype,
              this.sortValue,
              this.query
            )
            .subscribe(resp => {
              this.important_spinner = false;
              this.importantPageNo = 1;

              this.totalResult = resp.hits.total.value;
              if (resp.hits.total.value > 0) {
                for (let i = 0; i < resp.hits.hits.length; i++) {
                  this.importantJudgments.push(resp.hits.hits[i]._source);
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.importantJudgments.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.importantJudgments.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);

                if (this.showListView === false) {
                  this.singleJudg = this.importantJudgments[0];
                  this.isSingleJudg = true;
                }
              }
            });
        });
      });
    }
  }

  toggleView() {
    this.showListView = !this.showListView;
    if (this.showListView === false) {
      this.singleJudg = this.importantJudgments[0];
      this.isSingleJudg = true;

      // retaining appropriate boomark icon on view change
      setTimeout(() => {
        this.importantJudgments.forEach(data => {
          const citation = data.Citation;

          if (this.user.bookmarks.find(cit => cit === citation) !== undefined) {
            const x = document.getElementById(citation + " active");

            if (x !== null) {
              x.style.display = "block";
            }

            const y = document.getElementById(citation + " inactive");

            if (y !== null) {
              y.style.display = "none";
            }
          } else {
            const x = document.getElementById(citation + " active");

            if (x !== null) {
              x.style.display = "none";
            }

            const y = document.getElementById(citation + " inactive");

            if (y !== null) {
              y.style.display = "block";
            }
          }
        });
      }, 100);

      // retaining appropriate annotation icon on view change
      setTimeout(() => {
        this.importantJudgments.forEach(data => {
          const citation = data.Citation;

          if (
            this.user.annotations.find(z => z.citation === citation) !==
            undefined
          ) {
            const x = document.getElementById(citation + " annotation active");

            if (x !== null) {
              x.style.display = "block";
            }

            const y = document.getElementById(
              citation + " annotation inactive"
            );

            if (y !== null) {
              y.style.display = "none";
            }
          } else {
            const x = document.getElementById(citation + " annotation active");

            if (x !== null) {
              x.style.display = "none";
            }

            const y = document.getElementById(
              citation + " annotation inactive"
            );

            if (y !== null) {
              y.style.display = "block";
            }
          }
        });
      }, 300);
    } else if (this.showListView === true) {
      // retaining appropriate boomark icon on view change
      setTimeout(() => {
        this.importantJudgments.forEach(data => {
          const citation = data.Citation;

          if (this.user.bookmarks.find(cit => cit === citation) !== undefined) {
            const x = document.getElementById(citation + " active");

            if (x !== null) {
              x.style.display = "block";
            }

            const y = document.getElementById(citation + " inactive");

            if (y !== null) {
              y.style.display = "none";
            }
          } else {
            const x = document.getElementById(citation + " active");

            if (x !== null) {
              x.style.display = "none";
            }

            const y = document.getElementById(citation + " inactive");

            if (y !== null) {
              y.style.display = "block";
            }
          }
        });
      }, 100);

      // retaining appropriate annotation icon on view change
      setTimeout(() => {
        this.importantJudgments.forEach(data => {
          const citation = data.Citation;

          if (
            this.user.annotations.find(z => z.citation === citation) !==
            undefined
          ) {
            // // console.log('2');
            const x = document.getElementById(citation + " annotation active");
            // // console.log('x', x);
            if (x !== null) {
              x.style.display = "block";
            }

            const y = document.getElementById(
              citation + " annotation inactive"
            );
            // // console.log('y', y);
            if (y !== null) {
              y.style.display = "none";
            }
          } else {
            // // console.log('1');
            const x = document.getElementById(citation + " annotation active");
            // // console.log('x', x);
            if (x !== null) {
              x.style.display = "none";
            }

            const y = document.getElementById(
              citation + " annotation inactive"
            );
            // // console.log('y', y);
            if (y !== null) {
              y.style.display = "block";
            }
          }
        });
      }, 300);
    }
  }

  showJudgementData(data: Judgement) {
    this.singleJudg = data;
    // // // console.log('single judg', this.singleJudg);
    this.isSingleJudg = true;

    // retaining appropriate boomark icon on page load
    setTimeout(() => {
      const citation = this.singleJudg.Citation;
      if (this.user.bookmarks.find(cit => cit === citation) !== undefined) {
        const x = document.getElementById(citation + " active");
        if (x !== null) {
          x.style.display = "block";
        }
        const y = document.getElementById(citation + " inactive");
        if (y !== null) {
          y.style.display = "none";
        }
      } else {
        const x = document.getElementById(citation + " active");
        if (x !== null) {
          x.style.display = "none";
        }
        const y = document.getElementById(citation + " inactive");
        if (y !== null) {
          y.style.display = "block";
        }
      }
    }, 100);

    // retaining appropriate annotation icon on page load
    setTimeout(() => {
      const citation = this.singleJudg.Citation;
      if (
        this.user.annotations.find(z => z.citation === citation) !== undefined
      ) {
        const x = document.getElementById(citation + " annotation active");
        if (x !== null) {
          x.style.display = "block";
        }
        const y = document.getElementById(citation + " annotation inactive");
        if (y !== null) {
          y.style.display = "none";
        }
      } else {
        const x = document.getElementById(citation + " annotation active");
        if (x !== null) {
          x.style.display = "none";
        }
        const y = document.getElementById(citation + " annotation inactive");
        if (y !== null) {
          y.style.display = "block";
        }
      }
    }, 200);
  }

  onFilterCourtChange(event: MatSelectChange) {
    const court = "";
    const param = "";
    this.importantJudgments = [];
    if (event.value !== "All") {
      this.filterCourtQuery = "tribunal:\"" + event.value + "\" AND ";
    } else {
      this.filterCourtQuery = "";
    }
    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);
    this.important_spinner = true;
    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService
          .searchImportantJudgements(
            0,
            this.usertype,
            this.sortValue,
            this.query
          )
          .subscribe(data => {
            this.importantPageNo = 1;
            this.important_spinner = false;
            if (data.hits.total.value > 0) {
              this.totalResult = data.hits.total.value;
              for (let i = 0; i < data.hits.hits.length; i++) {
                this.importantJudgments.push(data.hits.hits[i]._source);
              }

              this.disablePreviousBtn = true;
              this.disableNextBtn = false;

              this.singleJudg = data.hits.hits[0]._source;
              this.isSingleJudg = true;

              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.importantTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.importantTotalPages = +a.toFixed() + 1;
                } else {
                  this.importantTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.importantpagination = true;
              } else {
                this.importantpagination = false;
              }
            } else {
              this.totalResult = data.hits.total.value;
              this.snackBar.open("No results found!", "OK", {
                duration: 4000
              });
            }
          });
      });
    });
  }

  filterSearch() {
    // this.filterSearchState = this.filterSearchState === 'initial' ? 'final' : 'initial';
    if (this.filterSearchQueryValue.trim().indexOf(" ") !== -1) {
      this.filterSearchQuery =
        this.filterSearchQueryValue.trim().replace(/\s+/g, " AND ") + " AND ";
    } else {
      this.filterSearchQuery = this.filterSearchQueryValue.trim() + " AND ";
    }
    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);
    // console.log('query', this.query);

    this.importantJudgments = [];
    this.important_spinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.browseService
          .searchImportantJudgements(
            0,
            this.usertype,
            this.sortValue,
            this.query
          )
          .subscribe(data => {
            this.importantPageNo = 1;
            this.important_spinner = false;

            this.totalResult = data.hits.total.value;
            if (data.hits.total.value > 0) {
              for (let i = 0; i < data.hits.hits.length; i++) {
                this.importantJudgments.push(data.hits.hits[i]._source);
              }

              this.disablePreviousBtn = true;
              this.disableNextBtn = false;

              this.singleJudg = data.hits.hits[0]._source;
              this.isSingleJudg = true;

              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.importantTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.importantTotalPages = +a.toFixed() + 1;
                } else {
                  this.importantTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.importantpagination = true;
              } else {
                this.importantpagination = false;
              }
            } else {
              this.snackBar.open("No results found!", "OK", {
                duration: 4000
              });
            }
          });
      });
    });
  }

  cancelSearchFilter() {
    this.filterSearchQuery = "";
    this.filterSearchQueryValue = "";
    this.query =
      this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);
    // console.log('query', this.query);

    this.importantJudgments = [];
    this.important_spinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.browseService
          .searchImportantJudgements(
            0,
            this.usertype,
            this.sortValue,
            this.query
          )
          .subscribe(data => {
            this.importantPageNo = 1;
            this.important_spinner = false;

            this.totalResult = data.hits.total.value;
            if (data.hits.total.value > 0) {
              for (let i = 0; i < data.hits.hits.length; i++) {
                this.importantJudgments.push(data.hits.hits[i]._source);
              }
              this.disablePreviousBtn = true;
              this.disableNextBtn = false;
              this.singleJudg = data.hits.hits[0]._source;
              this.isSingleJudg = true;
              // calculate total pages
              const a: number = this.totalResult / 15;

              if (this.totalResult % 15 === 0) {
                this.importantTotalPages = a;
              } else if (this.totalResult % 15 > 0) {
                const temp = a.toFixed(1);
                const digit = temp[temp.length - 1];

                if (+digit < 5) {
                  this.importantTotalPages = +a.toFixed() + 1;
                } else {
                  this.importantTotalPages = +a.toFixed();
                }
              }

              if (a > 1) {
                this.importantpagination = true;
              } else {
                this.importantpagination = false;
              }
            } else {
              this.snackBar.open("No results found!", "OK", {
                duration: 4000
              });
            }
          });
      });
    });
  }
}

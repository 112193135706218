import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { CookieService } from "ngx-cookie-service";
import { Observable, throwError } from "rxjs";
import { tap, map, catchError } from "rxjs/operators";
import { baseDomain } from "../shared/baseurl";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private inj: Injector, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.inj.get(AuthService);
    // Get the auth header from the service.
    const authToken = authService.getToken();
    // Clone the request to add the new header.
    const authReq = req.clone({headers: req.headers.set("Authorization", "bearer " + authToken)});
        // Pass on the cloned request instead of the original request.
    return next.handle(authReq);
  }
}

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private inj: Injector, private router: Router, private snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.inj.get(AuthService);
    const cookieService = this.inj.get(CookieService);
    const authToken = authService.getToken();

    async function verifyToken() {
      // console.log('hi');
      await authService.verifyToken().subscribe( data1 => {
        // console.log('data1', data1);
        if ( data1.success === true ) {
          const newToken = data1.token;
          const newExpiryToken = data1.expiryToken;
          const newRefreshToken = data1.refreshToken;
          const newExpiryRefreshToken = data1.expiryRefreshToken;
          // localStorage.setItem('JWT', newToken);
          cookieService.set("JWT", newToken, 30, "/", baseDomain);
          // localStorage.setItem('expToken', newExpiryToken);
          cookieService.set("expToken", newExpiryToken, 30, "/", baseDomain);
          // localStorage.setItem('refreshToken', newRefreshToken);
          cookieService.set("refreshToken", newRefreshToken, 30, "/", baseDomain);

          if ( data1.user ) {
            // localStorage.setItem('username', data1.user.username);
            cookieService.set("username", data1.user.username, 30, "/", baseDomain);
            // localStorage.setItem('contactno', data1.user.contactno);
            cookieService.set("contactno", data1.user.contactno, 30, "/", baseDomain);
            // localStorage.setItem('ID', data1.user._id);
            cookieService.set("ID", data1.user._id, 30, "/", baseDomain);
            // localStorage.setItem('usertype', data30, '/', baseDomain.user.usertype);
            cookieService.set("usertype", data1.user.usertype, 30, "/", baseDomain);
          }
          // this.response = 1;
        } else {
          verifyToken();
        }
      });
    }


    // console.log('hey');
    return next.handle(req)
      .pipe(
        // tap((err: any) => {
        //   // console.log('err', err);
        //   if (err instanceof HttpErrorResponse) {
        //     // console.log('err status', err.status);
        //     if (err.status === 401 && authToken) {
        //       // // console.log("Unauthorized Interceptor: ", err);
        //       // authService.checkJWTtoken();
        //       authService.destroyUserCredentials();
        //       this.router.navigate(['/homepage']);
        //     } else if (err.status === 403 && authToken) {
        //       this.snackBar.open('You are not authorized to perform this action', 'OK', {
        //         duration: 3000
        //       });
        //     }
        //   }
        // }),
        catchError((error: HttpErrorResponse) => {
          // console.log('error res', error);
          if (error instanceof HttpErrorResponse) {
            // console.log('err status', error.status);
            if (error.status === 401 && authToken) {
              // // console.log("Unauthorized Interceptor: ", err);
              // authService.checkJWTtoken();
              

              verifyToken();
              
              // authService.destroyUserCredentials();
              // this.router.navigate(['/homepage']);
            } else if (error.status === 403 && authToken) {
              this.snackBar.open("You are not authorized to perform this action", "OK", {
                duration: 3000
              });
            }
          }
          // let data = {};
          // data = {
          //     reason: error && error.error.reason ? error.error.reason : '',
          //     status: error.status
          // };
          // this.errorDialogService.openDialog(data);
          return throwError(error);
        })
      );
      // .do((event: HttpEvent<any>) => {
      //   // do nothing
      // }, (err: any) => {
      //   if (err instanceof HttpErrorResponse) {
      //     if (err.status === 401 && authToken) {
      //       // // console.log("Unauthorized Interceptor: ", err);
      //       authService.checkJWTtoken();
      //     }
      //   }
      // });
  }
}




import { Component, OnInit, Inject } from "@angular/core";
import { RouterModule, Routes, Router, ActivatedRoute } from "@angular/router";
import {
  MatDialog,
  MatChip,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar
} from "@angular/material";
import { AuthService } from "../services/auth.service";
import { BrowseService } from "../services/browse.service";
import { CookieService } from "ngx-cookie-service";
import { TimelineModalComponent } from "../timeline-modal/timeline-modal.component";
import { AnnotationComponent } from "../annotation/annotation.component";
import { User } from "../shared/user";
import { Judgement } from "../shared/judgement";
import { flattenStyles } from "@angular/platform-browser/src/dom/dom_renderer";
import { timer, forkJoin } from "rxjs";

// import { MessagingService } from '../services/messaging.service';
// export interface IWindow extends Window {
//   webkitSpeechRecognition: SpeechRecognition;
// }

export interface DialogData {
  totalJudgements: string;
  latest: string;
}

@Component({
  selector: "app-browse",
  templateUrl: "./browse.component.html",
  styleUrls: ["./browse.component.scss"]
})
export class BrowseComponent implements OnInit {
  // firebase
  message;

  user = new User();
  username: string;
  contactno: string;
  token: string;
  usertype: string;

  PrintpageNo = 0;
  PrinttotalPages: number;
  SCpageNo = 0;
  SCtotalPages: number;
  mumHCpageNo = 0;
  mumHCtotalPages: number;
  CATpageNo = 0;
  CATtotalPages: number;
  MATpageNo = 0;
  MATtotalPages: number;
  RCTpageNo = 0;
  RCTtotalPages: number;
  IPABpageNo = 0;
  IPABtotalPages: number;
  NCDRCpageNo = 0;
  NCDRCtotalPages: number;
  MCDRCpageNo = 0;
  MCDRCtotalPages: number;
  ITATpageNo = 0;
  ITATtotalPages: number;
  CESTATpageNo = 0;
  CESTATtotalPages: number;
  NCLATpageNo = 0;
  NCLATtotalPages: number;
  MAHARERApageNo = 0;
  MAHARERAtotalPages: number;
  MSCApageNo = 0;
  MSCAtotalPages: number;
  MACTpageNo = 0;
  MACTtotalPages: number;
  MILCpageNo = 0;
  MILCtotalPages: number;
  MSTpageNo = 0;
  MSTtotalPages: number;
  SATpageNo = 0;
  SATtotalPages: number;
  ATFPpageNo = 0;
  ATFPtotalPages: number;
  BIFRpageNo = 0;
  BIFRtotalPages: number;
  PNGpageNo = 0;
  PNGtotalPages: number;
  DRTpageNo = 0;
  DRTtotalPages: number;

  fontSize = 16;
  zoomInCount = 0;
  zoomOutCount = 0;
  zoomCount = 0;

  printjudgements: Judgement[] = [];
  scjudgements: Judgement[] = [];
  mumHCjudgements: Judgement[] = [];
  catjudgements: Judgement[] = [];
  matjudgements: Judgement[] = [];
  rctjudgements: Judgement[] = [];
  ipabjudgements: Judgement[] = [];
  ncdrcjudgements: Judgement[] = [];
  mcdrcjudgements: Judgement[] = [];
  itatjudgements: Judgement[] = [];
  cestatjudgements: Judgement[] = [];
  nclatjudgements: Judgement[] = [];
  maharerajudgements: Judgement[] = [];
  mscajudgements: Judgement[] = [];
  mactjudgements: Judgement[] = [];
  milcjudgements: Judgement[] = [];
  mstjudgements: Judgement[] = [];
  satjudgements: Judgement[] = [];
  atfpjudgements: Judgement[] = [];
  bifrjudgements: Judgement[] = [];
  pngjudgements: Judgement[] = [];
  drtjudgements: Judgement[] = [];

  printJudgCount: string;
  scJudgCount: string;
  mumHCJudgCount: string;
  catJudgCount: string;
  matJudgCount: string;
  rctJudgCount: string;
  ipabJudgCount: string;
  ncdrcJudgCount: string;
  mcdrcJudgCount: string;
  itatJudgCount: string;
  cestatJudgCount: string;
  nclatJudgCount: string;
  mahareraJudCount: string;
  mscaJudgCount: string;
  mactJudgCount: string;
  milcJudgCount: string;
  mstJudgCount: string;
  satJudgCount: string;
  atfpJudgCount: string;
  bifrJudgCount: string;
  pngJudgCount: string;
  drtJudgCount: string;

  printpagination = false;
  scpagination = false;
  mumHCpagination = false;
  catpagination = false;
  matpagination = false;
  rctpagination = false;
  ipabpagination = false;
  ncdrcpagination = false;
  mcdrcpagination = false;
  itatpagination = false;
  cestatpagination = false;
  nclatpagination = false;
  maharerapagination = false;
  mscapagination = false;
  mactpagination = false;
  milcpagination = false;
  mstpagination = false;
  satpagination = false;
  atfppagination = false;
  bifrpagination = false;
  pngpagination = false;
  drtpagination = false;

  printSpinner = false;
  scSpinner = false;
  mumHCSpinner = false;
  catSpinner = false;
  matSpinner = false;
  rctSpinner = false;
  ipabSpinner = false;
  ncdrcSpinner = false;
  mcdrcSpinner = false;
  itatSpinner = false;
  cestatSpinner = false;
  nclatSpinner = false;
  mahareraSpinner = false;
  mscaSpinner = false;
  mactSpinner = false;
  milcSpinner = false;
  mstSpinner = false;
  satSpinner = false;
  atfpSpinner = false;
  bifrSpinner = false;
  pngSpinner = false;
  drtSpinner = false;

  isBookmarked = false;
  isReadMore = true;
  HeadNoteLess = "";

  playSpeech = false;
  counterSpeech = 0;
  recentlyAdded: any;
  totalCount: any;
  // speechRecognition = new SpeechRecognition();

  constructor(
    private authService: AuthService,
    private browseService: BrowseService,
    private router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public timelineModal: MatDialog,
    // private messagingService: MessagingService,
    private cookieService: CookieService,
    public annotationModal: MatDialog,
    public marketing: MatDialog
  ) {}

  ngOnInit() {
    // firebase
    const userId = this.cookieService.get("contactno");
    // this.messagingService.requestPermission(userId);
    // this.messagingService.receiveMessage();
    // this.message = this.messagingService.currentMessage;

    this.username = this.cookieService.get("username");
    this.contactno = this.cookieService.get("contactno");
    this.authService.authToken = this.cookieService.get("JWT");
    this.usertype = this.cookieService.get("usertype");

    this.printSpinner = true;
    this.scSpinner = true;
    this.mumHCSpinner = true;
    this.catSpinner = true;
    this.matSpinner = true;
    this.rctSpinner = true;
    this.ipabSpinner = true;
    this.ncdrcSpinner = true;
    this.mcdrcSpinner = true;
    this.itatSpinner = true;
    this.cestatSpinner = true;
    this.nclatSpinner = true;
    this.mahareraSpinner = true;
    this.mscaSpinner = true;
    this.mactSpinner = true;
    this.milcSpinner = true;
    this.mstSpinner = true;
    this.satSpinner = true;
    this.atfpSpinner = true;
    this.bifrSpinner = true;
    this.pngSpinner = true;
    this.drtSpinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(2000).subscribe(() => {
        this.authService
          .getUserByContactno(this.contactno)
          .subscribe(userdata => {
            this.user = userdata;
          });
      });
    });
    // Print first page
    timer(3000).subscribe(() => {
      this.browseService.getAllPrintData(0, this.usertype).subscribe(data => {
        this.printSpinner = false;
        this.PrintpageNo = 1;
        if (data.hits.total > 0) {
          this.printJudgCount = data.hits.total;
          const a: Number = Number(this.printJudgCount) / 15;
          if (Number(this.printJudgCount) % 15 === 0) {
            this.PrinttotalPages = 0;
          } else if (Number(this.printJudgCount) % 15 > 0) {
            const temp = a.toFixed(1);
            const digit = temp[temp.length - 1];
            if (+digit < 5) {
              this.PrinttotalPages = +a.toFixed() + 1;
            } else {
              this.PrinttotalPages = +a.toFixed();
            }
          }
          for (let i = 0; i < data.hits.hits.length; i++) {
            this.printjudgements.push(data.hits.hits[i]["_source"]);
          }
          // //('judgements', this.scjudgements);
          if (a > 1) {
            this.printpagination = true;
          } else {
            this.printpagination = false;
          }

          // retaining appropriate boomark icon on page load
          setTimeout(() => {
            this.scjudgements.forEach(data => {
              let i = 0;
              i += 1;
              const citation = data.Citation;
              if (
                this.user.bookmarks.find(cit => cit === citation) !== undefined
              ) {
                const x = document.getElementById(citation + " active");
                if (x !== null) {
                  x.style.display = "block";
                }
                const y = document.getElementById(citation + " inactive");
                if (y !== null) {
                  y.style.display = "none";
                }
              } else {
                const x = document.getElementById(citation + " active");
                if (x !== null) {
                  x.style.display = "none";
                }
                const y = document.getElementById(citation + " inactive");
                if (y !== null) {
                  y.style.display = "block";
                }
              }
            });
          }, 2000);

          // retaining appropriate annotation icon on page load
          setTimeout(() => {
            this.scjudgements.forEach(data => {
              let i = 0;
              i += 1;
              const citation = data.Citation;
              if (
                this.user.annotations.find(z => z.citation === citation) !==
                undefined
              ) {
                const x = document.getElementById(
                  citation + " annotation active"
                );
                if (x !== null) {
                  x.style.display = "block";
                }
                const y = document.getElementById(
                  citation + " annotation inactive"
                );
                if (y !== null) {
                  y.style.display = "none";
                }
              } else {
                const x = document.getElementById(
                  citation + " annotation active"
                );
                if (x !== null) {
                  x.style.display = "none";
                }
                const y = document.getElementById(
                  citation + " annotation inactive"
                );
                if (y !== null) {
                  y.style.display = "block";
                }
              }
            });
          }, 2000);
        } else if (data.msg === "err") {
          this.snackBar.open(
            "Error in fetching Supreme Court judgements, Please try again!",
            "OK"
          );
        }
      });
    });
  }

  onLogout() {
    this.authService.destroyUserCredentials();
    localStorage.clear();
    this.router.navigate(["/homepage"]).then(() => {
      this.snackBar.open(
        "Thank You for using NearLaw, " + this.username + "!",
        "OK",
        {
          duration: 3000
        }
      );
    });
  }

  GetParent(elem) {
    this.counterSpeech = this.counterSpeech + 1;
    this.playSpeech = !this.playSpeech;
    if (this.playSpeech === true) {
      if (this.counterSpeech > 1) {
        speechSynthesis.resume();
      } else {
        speechSynthesis.cancel();
        const parentElem = document.getElementById(elem).parentElement
          .innerText;
        const text = parentElem.substr(59);
        const text1 = text.slice(0, -24);
        text1.replace(/\./g, " ");
        text1.replace(/\:/g, " ");
        const chunk = text1.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,15}\b/g); // spillting at \n and every 15th whitespace
        const ttsArray: SpeechSynthesisUtterance[] = [];
        chunk.forEach(chunkElem => {
          ttsArray.push(new SpeechSynthesisUtterance(chunkElem));
        });
        ttsArray.forEach(ttsElem => {
          ttsElem.lang = "en-IN";
          ttsElem.pitch = 1;
          ttsElem.rate = 0.9;
        });
        ttsArray.forEach(ttsElem => {
          window.speechSynthesis.speak(ttsElem);
        });
      }
    } else if (this.playSpeech === false) {
      speechSynthesis.pause();
    }
  }

  StopSpeech() {
    speechSynthesis.cancel();
    this.counterSpeech = 0;
    this.playSpeech = false;
  }

  ZoomIn(id) {
    this.zoomCount += 1;
    if (this.zoomCount < 9) {
      this.fontSize += 1.2;
      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if (this.zoomCount >= 9) {
      this.zoomCount = 9;
    }
  }

  ZoomOut(id) {
    this.zoomCount -= 1;
    if (this.zoomCount > -4) {
      this.fontSize -= 1.2;
      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if (this.zoomCount < -3) {
      this.zoomCount = -3;
    }
  }

  getTimelineData(citation: string) {
    const timelineDialogRef = this.timelineModal.open(TimelineModalComponent, {
      width: "850px",
      height: "550px"
    });
    this.cookieService.set("timeline", citation);
  }

  nextPagePrint() {
    this.printSpinner = true;
    this.PrintpageNo += 1;
    if (this.PrintpageNo <= this.PrinttotalPages) {
      const page_no = (this.PrintpageNo - 1) * 15;
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllPrintData(page_no, this.usertype)
            .subscribe(data => {
              this.printSpinner = false;
              this.printjudgements = [];
              if (data.hits.total > 0) {
                this.printJudgCount = data.hits.total;
                const a: Number = Number(this.printJudgCount) / 15;
                if (Number(this.printJudgCount) % 15 === 0) {
                  this.PrinttotalPages = 0;
                } else if (Number(this.printJudgCount) % 15 > 0) {
                  const temp = a.toFixed(1);
                  const digit = temp[temp.length - 1];
                  if (+digit < 5) {
                    this.PrinttotalPages = +a.toFixed() + 1;
                  } else {
                    this.PrinttotalPages = +a.toFixed();
                  }
                }
                for (let i = 0; i < data.hits.hits.length; i++) {
                  this.printjudgements.push(data.hits.hits[i]["_source"]);
                }
                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.printjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.printjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching Supreme Court judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.PrintpageNo > this.PrinttotalPages) {
      this.PrintpageNo = this.PrinttotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  previousPagePrint() {
    this.printSpinner = true;
    this.PrintpageNo -= 1;
    if (this.PrintpageNo > 0) {
      const page_no = (this.PrintpageNo - 1) * 15;
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllPrintData(page_no, this.usertype)
            .subscribe(data => {
              this.printSpinner = false;
              if (data.hits.total > 0) {
                this.printJudgCount = data.hits.total;
                this.printjudgements = [];
                // //('initialpageno', this.SCpageNo);
                const a: Number = Number(this.printJudgCount) / 15;
                if (Number(this.printJudgCount) % 15 === 0) {
                  this.PrinttotalPages = 0;
                } else if (Number(this.printJudgCount) % 15 > 0) {
                  const temp = a.toFixed(1);
                  const digit = temp[temp.length - 1];
                  if (+digit < 5) {
                    this.PrinttotalPages = +a.toFixed() + 1;
                  } else {
                    this.PrinttotalPages = +a.toFixed();
                  }
                }
                for (let i = 0; i < data.hits.hits.length; i++) {
                  this.printjudgements.push(data.hits.hits[i]["_source"]);
                }
                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.printjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.printjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching Print judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.PrintpageNo < 1) {
      this.PrintpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  nextPageSC() {
    this.scSpinner = true;
    this.SCpageNo += 1;
    if (this.SCpageNo <= this.SCtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllSCData(this.SCpageNo, this.usertype)
            .subscribe(data => {
              this.scSpinner = false;
              if (data.msg === "success") {
                this.scJudgCount = data.result.total;
                this.SCpageNo = Number(data.result.page);
                this.SCtotalPages = data.result.pages;
                this.scjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.scjudgements.push(data.result.docs[i]);
                }
                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.scjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.scjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching Supreme Court judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.SCpageNo > this.SCtotalPages) {
      this.SCpageNo = this.SCtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  previousPageSC() {
    this.scSpinner = true;
    this.SCpageNo -= 1;
    if (this.SCpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllSCData(this.SCpageNo, this.usertype)
            .subscribe(data => {
              this.scSpinner = false;
              if (data.msg === "success") {
                this.scJudgCount = data.result.total;
                this.SCpageNo = Number(data.result.page);
                this.SCtotalPages = data.result.pages;
                this.scjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.scjudgements.push(data.result.docs[i]);
                }
                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.scjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.scjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching Supreme Court judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.SCpageNo < 1) {
      this.SCpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  MumHCnextPage() {
    this.mumHCpageNo += 1;
    this.mumHCSpinner = true;
    if (this.mumHCpageNo <= this.mumHCtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMumHCData(this.mumHCpageNo, this.usertype)
            .subscribe(data => {
              this.mumHCSpinner = false;
              if (data.msg === "success") {
                this.mumHCJudgCount = data.result.total;
                this.mumHCpageNo = Number(data.result.page);
                this.mumHCtotalPages = data.result.pages;
                this.mumHCjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mumHCjudgements.push(data.result.docs[i]);
                }
                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mumHCjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);
                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mumHCjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching Bombay High Court judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.mumHCpageNo > this.mumHCtotalPages) {
      this.mumHCpageNo = this.SCtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  MumHCpreviousPage() {
    this.mumHCSpinner = true;
    this.mumHCpageNo -= 1;
    if (this.mumHCpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMumHCData(this.mumHCpageNo, this.usertype)
            .subscribe(data => {
              this.mumHCSpinner = false;
              if (data.msg === "success") {
                this.mumHCJudgCount = data.result.total;
                this.mumHCpageNo = Number(data.result.page);
                this.mumHCtotalPages = data.result.pages;
                this.mumHCjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mumHCjudgements.push(data.result.docs[i]);
                }
                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mumHCjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);
                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mumHCjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching Bombay High Court judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.mumHCpageNo < 1) {
      this.mumHCpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  catNextPage() {
    this.catSpinner = true;
    this.CATpageNo += 1;

    if (this.CATpageNo <= this.CATtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllCATdata(this.CATpageNo, this.usertype)
            .subscribe(data => {
              this.catSpinner = false;

              if (data.msg === "success") {
                this.catJudgCount = data.result.total;
                this.CATpageNo = Number(data.result.page);
                this.CATtotalPages = data.result.pages;
                this.catjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.catjudgements.push(data.result.docs[i]);
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.catjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.catjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching CAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.CATpageNo > this.CATtotalPages) {
      this.CATpageNo = this.CATtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  catPreviousPage() {
    this.catSpinner = true;
    this.CATpageNo -= 1;

    if (this.CATpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllCATdata(this.CATpageNo, this.usertype)
            .subscribe(data => {
              this.catSpinner = false;

              if (data.msg === "success") {
                this.catJudgCount = data.result.total;
                this.CATpageNo = Number(data.result.page);
                this.CATtotalPages = data.result.pages;
                this.catjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.catjudgements.push(data.result.docs[i]);
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.catjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.catjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching CAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.CATpageNo < 1) {
      this.CATpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  matNextPage() {
    this.MATpageNo += 1;
    this.matSpinner = true;

    if (this.MATpageNo <= this.MATtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMATdata(this.MATpageNo, this.usertype)
            .subscribe(data => {
              this.matSpinner = false;

              if (data.msg === "success") {
                this.matJudgCount = data.result.total;
                this.MATpageNo = Number(data.result.page);
                this.MATtotalPages = data.result.pages;
                this.matjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.matjudgements.push(data.result.docs[i]);
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.matjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.matjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MATpageNo > this.MATtotalPages) {
      this.MATpageNo = this.MATtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  matPreviousPage() {
    this.MATpageNo -= 1;
    this.matSpinner = true;
    if (this.MATpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMATdata(this.MATpageNo, this.usertype)
            .subscribe(data => {
              this.matSpinner = false;
              if (data.msg === "success") {
                this.matJudgCount = data.result.total;
                this.MATpageNo = Number(data.result.page);
                this.MATtotalPages = data.result.pages;
                this.matjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.matjudgements.push(data.result.docs[i]);
                }
                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.matjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);
                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.matjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 2000);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.CATpageNo < 1) {
      this.MATpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  rctNextPage() {
    this.RCTpageNo += 1;
    this.rctSpinner = true;
    if (this.RCTpageNo <= this.RCTtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllRCTdata(this.RCTpageNo, this.usertype)
            .subscribe(data => {
              this.rctSpinner = false;
              if (data.msg === "success") {
                this.rctJudgCount = data.result.total;
                this.RCTpageNo = Number(data.result.page);
                this.RCTtotalPages = data.result.pages;
                this.rctjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.rctjudgements.push(data.result.docs[i]);
                }
                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.rctjudgements.forEach(data => {
                    let i = 0;
                    i += 1;
                    const citation = data.Citation;
                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");
                      if (x !== null) {
                        x.style.display = "none";
                      }
                      const y = document.getElementById(citation + " inactive");
                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.rctjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching RCT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.RCTpageNo > this.RCTtotalPages) {
      this.RCTpageNo = this.RCTtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  rctPreviousPage() {
    this.RCTpageNo -= 1;
    this.rctSpinner = true;

    if (this.RCTpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllRCTdata(this.RCTpageNo, this.usertype)
            .subscribe(data => {
              this.rctSpinner = false;

              if (data.msg === "success") {
                this.rctJudgCount = data.result.total;
                this.RCTpageNo = Number(data.result.page);
                this.RCTtotalPages = data.result.pages;
                this.rctjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.rctjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.rctjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.rctjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.rctjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching RCT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.RCTpageNo < 1) {
      this.RCTpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  ipabNextPage() {
    this.IPABpageNo += 1;
    this.ipabSpinner = true;
    // //('pageno', this.IPABpageNo);
    if (this.IPABpageNo <= this.IPABtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllRCTdata(this.IPABpageNo, this.usertype)
            .subscribe(data => {
              this.ipabSpinner = false;

              if (data.msg === "success") {
                this.ipabJudgCount = data.result.total;
                this.IPABpageNo = Number(data.result.page);
                this.IPABtotalPages = data.result.pages;
                this.ipabjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.ipabjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.ipabjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.ipabjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.ipabjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching IPAB judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.IPABpageNo > this.IPABtotalPages) {
      this.IPABpageNo = this.IPABtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  ipabPreviousPage() {
    this.IPABpageNo -= 1;
    this.ipabSpinner = true;
    // //('pageno', this.IPABpageNo);

    if (this.IPABpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllIPABdata(this.IPABpageNo, this.usertype)
            .subscribe(data => {
              this.ipabSpinner = false;

              if (data.msg === "success") {
                this.ipabJudgCount = data.result.total;
                this.IPABpageNo = Number(data.result.page);
                this.IPABtotalPages = data.result.pages;
                this.ipabjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.ipabjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.ipabjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.ipabjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.ipabjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 2000);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching IPAB judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.IPABpageNo < 1) {
      this.IPABpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  ncdrcNextPage() {
    this.ncdrcSpinner = true;
    this.NCDRCpageNo += 1;
    // //('pageno', this.NCDRCpageNo);
    if (this.NCDRCpageNo <= this.NCDRCtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllNCDRCdata(this.NCDRCpageNo, this.usertype)
            .subscribe(data => {
              this.ncdrcSpinner = false;

              if (data.msg === "success") {
                this.ncdrcJudgCount = data.result.total;
                this.NCDRCpageNo = Number(data.result.page);
                this.NCDRCtotalPages = data.result.pages;
                this.ncdrcjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.ncdrcjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.ncdrcjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.ncdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.ncdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching NCDRC judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.NCDRCpageNo > this.NCDRCtotalPages) {
      this.NCDRCpageNo = this.NCDRCtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  ncdrcPreviousPage() {
    this.ncdrcSpinner = true;
    this.NCDRCpageNo -= 1;
    // //('pageno', this.NCDRCpageNo);

    if (this.NCDRCpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllNCDRCdata(this.NCDRCpageNo, this.usertype)
            .subscribe(data => {
              this.ncdrcSpinner = false;

              if (data.msg === "success") {
                this.ncdrcJudgCount = data.result.total;
                this.NCDRCpageNo = Number(data.result.page);
                this.NCDRCtotalPages = data.result.pages;
                this.ncdrcjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.ncdrcjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.ncdrcjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.ncdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.ncdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching NCDRC judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.NCDRCpageNo < 1) {
      this.NCDRCpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mcdrcNextPage() {
    this.mcdrcSpinner = true;
    this.MCDRCpageNo += 1;
    // //('pageno', this.MCDRCpageNo);
    if (this.MCDRCpageNo <= this.MCDRCtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMCDRCdata(this.MCDRCpageNo, this.usertype)
            .subscribe(data => {
              this.mcdrcSpinner = false;

              if (data.msg === "success") {
                this.mcdrcJudgCount = data.result.total;
                this.MCDRCpageNo = Number(data.result.page);
                this.MCDRCtotalPages = data.result.pages;
                this.mcdrcjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mcdrcjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.mcdrcjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mcdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }
                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mcdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MCDRC judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MCDRCpageNo > this.MCDRCtotalPages) {
      this.MCDRCpageNo = this.MCDRCtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mcdrcPreviousPage() {
    this.MCDRCpageNo -= 1;
    // //('pageno', this.MCDRCpageNo);
    this.mcdrcSpinner = false;
    if (this.MCDRCpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMCDRCdata(this.MCDRCpageNo, this.usertype)
            .subscribe(data => {
              this.mcdrcSpinner = true;

              if (data.msg === "success") {
                this.mcdrcJudgCount = data.result.total;
                this.MCDRCpageNo = Number(data.result.page);
                this.MCDRCtotalPages = data.result.pages;
                this.mcdrcjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mcdrcjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.mcdrcjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mcdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mcdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MCDRC judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MCDRCpageNo < 1) {
      this.MCDRCpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  itatNextPage() {
    this.ITATpageNo += 1;
    this.itatSpinner = true;
    // //('pageno', this.ITATpageNo);
    if (this.ITATpageNo <= this.ITATtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllITATdata(this.ITATpageNo, this.usertype)
            .subscribe(data => {
              this.itatSpinner = false;

              if (data.msg === "success") {
                this.itatJudgCount = data.result.total;
                this.ITATpageNo = Number(data.result.page);
                this.ITATtotalPages = data.result.pages;
                this.itatjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.itatjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.itatjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.itatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.itatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.itatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching ITAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.ITATpageNo > this.ITATtotalPages) {
      this.ITATpageNo = this.ITATtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  itatPreviousPage() {
    this.ITATpageNo -= 1;
    // //('pageno', this.ITATpageNo);
    this.itatSpinner = true;
    if (this.ITATpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllITATdata(this.ITATpageNo, this.usertype)
            .subscribe(data => {
              this.itatSpinner = false;

              if (data.msg === "success") {
                this.itatJudgCount = data.result.total;
                this.ITATpageNo = Number(data.result.page);
                this.ITATtotalPages = data.result.pages;
                this.itatjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.itatjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.itatjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.itatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.itatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching ITAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.ITATpageNo < 1) {
      this.ITATpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  cestatNextPage() {
    this.CESTATpageNo += 1;
    this.cestatSpinner = true;
    // //('pageno', this.CESTATpageNo);
    if (this.CESTATpageNo <= this.CESTATtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllCESTATdata(this.CESTATpageNo, this.usertype)
            .subscribe(data => {
              this.cestatSpinner = false;

              if (data.msg === "success") {
                this.cestatJudgCount = data.result.total;
                this.CESTATpageNo = Number(data.result.page);
                this.CESTATtotalPages = data.result.pages;
                this.cestatjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.cestatjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.cestatjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.cestatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.cestatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching CESTAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.CESTATpageNo > this.CESTATtotalPages) {
      this.CESTATpageNo = this.CESTATtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  cestatPreviousPage() {
    this.CESTATpageNo -= 1;
    this.cestatSpinner = true;
    // //('pageno', this.CESTATpageNo);

    if (this.CESTATpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllCESTATdata(this.CESTATpageNo, this.usertype)
            .subscribe(data => {
              this.cestatSpinner = false;

              if (data.msg === "success") {
                this.cestatJudgCount = data.result.total;
                this.CESTATpageNo = Number(data.result.page);
                this.CESTATtotalPages = data.result.pages;
                this.cestatjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.cestatjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.cestatjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.cestatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.cestatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching CESTAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.CESTATpageNo < 1) {
      this.CESTATpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  nclatNextPage() {
    this.NCLATpageNo += 1;
    this.nclatSpinner = true;
    // //('pageno', this.NCLATpageNo);
    if (this.NCLATpageNo <= this.NCLATtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllNCLATdata(this.NCLATpageNo, this.usertype)
            .subscribe(data => {
              this.nclatSpinner = false;

              if (data.msg === "success") {
                this.nclatJudgCount = data.result.total;
                this.NCLATpageNo = Number(data.result.page);
                this.NCLATtotalPages = data.result.pages;
                this.nclatjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.nclatjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.nclatjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.nclatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.nclatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching NCLAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.NCLATpageNo > this.NCLATtotalPages) {
      this.NCLATpageNo = this.NCLATtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  nclatPreviousPage() {
    this.nclatSpinner = true;
    this.NCLATpageNo -= 1;
    // //('pageno', this.NCLATpageNo);

    if (this.NCLATpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllNCLATdata(this.NCLATpageNo, this.usertype)
            .subscribe(data => {
              this.nclatSpinner = false;

              if (data.msg === "success") {
                this.nclatJudgCount = data.result.total;
                this.NCLATpageNo = Number(data.result.page);
                this.NCLATtotalPages = data.result.pages;
                this.nclatjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.nclatjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.nclatjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.nclatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.nclatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching NCLAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.CESTATpageNo < 1) {
      this.NCLATpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mahareraNextPage() {
    this.mahareraSpinner = true;
    this.MAHARERApageNo += 1;
    // //('pageno', this.MAHARERApageNo);
    if (this.MAHARERApageNo <= this.MAHARERAtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllNCLATdata(this.MAHARERApageNo, this.usertype)
            .subscribe(data => {
              this.mahareraSpinner = false;

              if (data.msg === "success") {
                this.mahareraJudCount = data.result.total;
                this.MAHARERApageNo = Number(data.result.page);
                this.MAHARERAtotalPages = data.result.pages;
                this.maharerajudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.maharerajudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.maharerajudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.maharerajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.maharerajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MAHARERA judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MAHARERApageNo > this.MAHARERAtotalPages) {
      this.MAHARERApageNo = this.MAHARERAtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mahareraPreviousPage() {
    this.mahareraSpinner = true;
    this.MAHARERApageNo -= 1;
    // //('pageno', this.MAHARERApageNo);

    if (this.MAHARERApageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMAHARERAdata(this.MAHARERApageNo, this.usertype)
            .subscribe(data => {
              this.mahareraSpinner = false;

              if (data.msg === "success") {
                this.mahareraJudCount = data.result.total;
                this.MAHARERApageNo = Number(data.result.page);
                this.MAHARERAtotalPages = data.result.pages;
                this.maharerajudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.maharerajudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.maharerajudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.maharerajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.maharerajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MAHARERA judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MAHARERApageNo < 1) {
      this.MAHARERApageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mscaNextPage() {
    this.MSCApageNo += 1;
    this.mscaSpinner = true;
    // //('pageno', this.MSCApageNo);
    if (this.MSCApageNo <= this.MAHARERAtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMSCAdata(this.MSCApageNo, this.usertype)
            .subscribe(data => {
              this.mscaSpinner = false;

              if (data.msg === "success") {
                this.mscaJudgCount = data.result.total;
                this.MSCApageNo = Number(data.result.page);
                this.MSCAtotalPages = data.result.pages;
                this.mscajudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mscajudgements.push(data.result.docs[i]);
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mscajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mscajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MSCA judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MSCApageNo > this.MSCAtotalPages) {
      this.MSCApageNo = this.MSCAtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mscaPreviousPage() {
    this.MSCApageNo -= 1;
    this.mscaSpinner = true;
    // //('pageno', this.MSCApageNo);

    if (this.MSCApageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMSCAdata(this.MSCApageNo, this.usertype)
            .subscribe(data => {
              this.mscaSpinner = false;

              if (data.msg === "success") {
                this.mscaJudgCount = data.result.total;
                this.MSCApageNo = Number(data.result.page);
                this.MSCAtotalPages = data.result.pages;
                this.mscajudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mscajudgements.push(data.result.docs[i]);
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mscajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mscajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MSCA judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MSCApageNo < 1) {
      this.MSCApageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mactNextPage() {
    this.MACTpageNo += 1;
    this.mactSpinner = true;
    // //('pageno', this.MACTpageNo);
    if (this.MACTpageNo <= this.MACTtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMACTdata(this.MACTpageNo, this.usertype)
            .subscribe(data => {
              this.mactSpinner = false;

              if (data.msg === "success") {
                this.mactJudgCount = data.result.total;
                this.MACTpageNo = Number(data.result.page);
                this.MACTtotalPages = data.result.pages;
                this.mactjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mactjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.mactjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mactjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mactjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MACT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MACTpageNo > this.MACTtotalPages) {
      this.MACTpageNo = this.MACTtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mactPreviousPage() {
    this.mactSpinner = true;
    this.MACTpageNo -= 1;
    // //('pageno', this.MACTpageNo);

    if (this.MACTpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMSCAdata(this.MACTpageNo, this.usertype)
            .subscribe(data => {
              this.mactSpinner = false;

              if (data.msg === "success") {
                this.mactJudgCount = data.result.total;
                this.MACTpageNo = Number(data.result.page);
                this.MACTtotalPages = data.result.pages;
                this.mactjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mactjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.mactjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mactjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mactjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MACT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MACTpageNo < 1) {
      this.MACTpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  milcNextPage() {
    this.milcSpinner = true;
    this.MILCpageNo += 1;
    // //('pageno', this.MILCpageNo);
    if (this.MILCpageNo <= this.MILCtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMILCdata(this.MILCpageNo, this.usertype)
            .subscribe(data => {
              this.milcSpinner = false;

              if (data.msg === "success") {
                this.milcJudgCount = data.result.total;
                this.MILCpageNo = Number(data.result.page);
                this.MILCtotalPages = data.result.pages;
                this.milcjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.milcjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.milcjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.milcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.milcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MILC judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MILCpageNo > this.MILCtotalPages) {
      this.MILCpageNo = this.MILCtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  milcPreviousPage() {
    this.milcSpinner = true;
    this.MILCpageNo -= 1;
    // //('pageno', this.MILCpageNo);

    if (this.MILCpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMILCdata(this.MILCpageNo, this.usertype)
            .subscribe(data => {
              this.milcSpinner = false;

              if (data.msg === "success") {
                this.milcJudgCount = data.result.total;
                this.MILCpageNo = Number(data.result.page);
                this.MILCtotalPages = data.result.pages;
                this.milcjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.milcjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.milcjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.milcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.milcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MILC judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MILCpageNo < 1) {
      this.MILCpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mstNextPage() {
    this.mstSpinner = true;
    this.MSTpageNo += 1;
    // //('pageno', this.MSTpageNo);
    if (this.MSTpageNo <= this.MSTtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMSTdata(this.MSTpageNo, this.usertype)
            .subscribe(data => {
              this.mstSpinner = false;

              if (data.msg === "success") {
                this.mstJudgCount = data.result.total;
                this.MSTpageNo = Number(data.result.page);
                this.MSTtotalPages = data.result.pages;
                this.mstjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mstjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.mstjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mstjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mstjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MST judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MSTpageNo > this.MSTtotalPages) {
      this.MSTpageNo = this.MSTtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  mstPreviousPage() {
    this.MSTpageNo -= 1;
    // //('pageno', this.MSTpageNo);
    this.mstSpinner = true;
    if (this.MSTpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMSTdata(this.MSTpageNo, this.usertype)
            .subscribe(data => {
              this.mstSpinner = false;

              if (data.msg === "success") {
                this.mstJudgCount = data.result.total;
                this.MSTpageNo = Number(data.result.page);
                this.MSTtotalPages = data.result.pages;
                this.mstjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mstjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.mstjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mstjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mstjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MST judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.MSTpageNo < 1) {
      this.MSTpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  satNextPage() {
    this.satSpinner = true;
    this.SATpageNo += 1;
    // //('pageno', this.SATpageNo);
    if (this.SATpageNo <= this.SATtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllSATdata(this.SATpageNo, this.usertype)
            .subscribe(data => {
              this.satSpinner = false;

              if (data.msg === "success") {
                this.satJudgCount = data.result.total;
                this.SATpageNo = Number(data.result.page);
                this.SATtotalPages = data.result.pages;
                this.satjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.satjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.satjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.satjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.satjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching SAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.SATpageNo > this.SATtotalPages) {
      this.SATpageNo = this.SATtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  satPreviousPage() {
    this.satSpinner = true;
    this.SATpageNo -= 1;
    // //('pageno', this.SATpageNo);

    if (this.SATpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllSATdata(this.SATpageNo, this.usertype)
            .subscribe(data => {
              this.satSpinner = false;

              if (data.msg === "success") {
                this.satJudgCount = data.result.total;
                this.SATpageNo = Number(data.result.page);
                this.SATtotalPages = data.result.pages;
                this.satjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.satjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.satjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.satjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.satjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching SAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.SATpageNo < 1) {
      this.SATpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  atfpNextPage() {
    this.ATFPpageNo += 1;
    this.atfpSpinner = true;
    // //('pageno', this.ATFPpageNo);
    if (this.ATFPpageNo <= this.ATFPtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllATFPdata(this.ATFPpageNo, this.usertype)
            .subscribe(data => {
              this.atfpSpinner = false;

              if (data.msg === "success") {
                this.atfpJudgCount = data.result.total;
                this.ATFPpageNo = Number(data.result.page);
                this.ATFPtotalPages = data.result.pages;
                this.atfpjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.atfpjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.atfpjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.atfpjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.atfpjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching ATFP judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.ATFPpageNo > this.ATFPtotalPages) {
      this.ATFPpageNo = this.ATFPtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  atfpPreviousPage() {
    this.ATFPpageNo -= 1;
    this.atfpSpinner = true;
    // //('pageno', this.ATFPpageNo);

    if (this.ATFPpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllSATdata(this.ATFPpageNo, this.usertype)
            .subscribe(data => {
              this.atfpSpinner = false;

              if (data.msg === "success") {
                this.atfpJudgCount = data.result.total;
                this.ATFPpageNo = Number(data.result.page);
                this.ATFPtotalPages = data.result.pages;
                this.atfpjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.atfpjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.atfpjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.atfpjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.atfpjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching ATFP judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.ATFPpageNo < 1) {
      this.ATFPpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  bifrNextPage() {
    this.BIFRpageNo += 1;
    this.bifrSpinner = true;
    // //('pageno', this.BIFRpageNo);
    if (this.BIFRpageNo <= this.BIFRtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllBIFRdata(this.BIFRpageNo, this.usertype)
            .subscribe(data => {
              this.bifrSpinner = false;

              if (data.msg === "success") {
                this.bifrJudgCount = data.result.total;
                this.BIFRpageNo = Number(data.result.page);
                this.BIFRtotalPages = data.result.pages;
                this.bifrjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.bifrjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.bifrjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.bifrjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.bifrjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching BIFR judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.BIFRpageNo > this.BIFRtotalPages) {
      this.BIFRpageNo = this.BIFRtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  bifrPreviousPage() {
    this.bifrSpinner = true;
    this.BIFRpageNo -= 1;
    // //('pageno', this.BIFRpageNo);

    if (this.BIFRpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllBIFRdata(this.BIFRpageNo, this.usertype)
            .subscribe(data => {
              this.bifrSpinner = false;

              if (data.msg === "success") {
                this.bifrJudgCount = data.result.total;
                this.BIFRpageNo = Number(data.result.page);
                this.BIFRtotalPages = data.result.pages;
                this.bifrjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.bifrjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.bifrjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.bifrjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.bifrjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching BIFR judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.BIFRpageNo < 1) {
      this.BIFRpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  pngNextPage() {
    this.PNGpageNo += 1;
    this.pngSpinner = true;
    // //('pageno', this.PNGpageNo);
    if (this.PNGpageNo <= this.PNGtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllPNGdata(this.PNGpageNo, this.usertype)
            .subscribe(data => {
              this.pngSpinner = false;

              if (data.msg === "success") {
                this.pngJudgCount = data.result.total;
                this.PNGpageNo = Number(data.result.page);
                this.pngjudgements = data.result.pages;
                this.pngjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.pngjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.pngjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.pngjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.pngjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching PNG judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.PNGpageNo > this.PNGtotalPages) {
      this.PNGpageNo = this.PNGtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  pngPreviousPage() {
    this.pngSpinner = true;
    this.PNGpageNo -= 1;
    // //('pageno', this.PNGpageNo);

    if (this.PNGpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllPNGdata(this.PNGpageNo, this.usertype)
            .subscribe(data => {
              this.pngSpinner = false;

              if (data.msg === "success") {
                this.pngJudgCount = data.result.total;
                this.PNGpageNo = Number(data.result.page);
                this.PNGtotalPages = data.result.pages;
                this.pngjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.pngjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.pngjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.pngjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.pngjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching PNG judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.PNGpageNo < 1) {
      this.PNGpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  drtNextPage() {
    this.drtSpinner = true;
    this.DRTpageNo += 1;
    // //('pageno', this.DRTpageNo);
    if (this.DRTpageNo <= this.DRTtotalPages) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllDRTdata(this.DRTpageNo, this.usertype)
            .subscribe(data => {
              this.drtSpinner = false;

              if (data.msg === "success") {
                this.drtJudgCount = data.result.total;
                this.DRTpageNo = Number(data.result.page);
                this.drtjudgements = data.result.pages;
                this.drtjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.drtjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.drtjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.drtjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.drtjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching DRT/DRAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.DRTpageNo > this.DRTtotalPages) {
      this.DRTpageNo = this.DRTtotalPages + 1;
      this.snackBar.open("End of Result!", "OK", {
        duration: 4000
      });
    }
  }

  drtPreviousPage() {
    this.drtSpinner = true;
    this.DRTpageNo -= 1;
    // //('pageno', this.DRTpageNo);

    if (this.DRTpageNo > 0) {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllDRTdata(this.DRTpageNo, this.usertype)
            .subscribe(data => {
              this.drtSpinner = false;

              if (data.msg === "success") {
                this.drtJudgCount = data.result.total;
                this.DRTpageNo = Number(data.result.page);
                this.DRTtotalPages = data.result.pages;
                this.drtjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.drtjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.drtjudgements);

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.drtjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.drtjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching DRT/DRAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (this.DRTpageNo < 1) {
      this.DRTpageNo = 0;
      this.snackBar.open("Start of Result!", "OK", {
        duration: 4000
      });
    }
  }

  // readmore(headnote: string, id: string) {
  //   // this.isReadMore = !this.isReadMore;
  //   const a = headnote.substring(0, 260);
  //   // //('a', a);
  //   // //('id', id);
  //   const b = document.getElementById(id);
  //   // //('b', b);
  //   b.innerText = a + ' ...';
  // }

  tribunalTab(event) {
    // //('1');
    const selectedTab = event.tab;
    // //('tabselection', selectedTab);
    // //('label', selectedTab.textLabel);
    const tabname: string = selectedTab.textLabel;
    const court = tabname.split(" ");
    // //('court', court[0]);

    if (court[0] === "Supreme") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService.getAllSCData(1, this.usertype).subscribe(data => {
            this.scSpinner = false;

            if (data.msg === "success") {
              this.scJudgCount = data.result.total;
              this.SCpageNo = Number(data.result.page);
              // //('initialpageno', this.SCpageNo);
              this.SCtotalPages = data.result.pages;
              for (let i = 0; i < data.result.docs.length; i++) {
                this.scjudgements.push(data.result.docs[i]);
              }
              // //('judgements', this.scjudgements);
              if (this.SCtotalPages !== 1 || this.scjudgements.length !== 0) {
                this.scpagination = true;
              } else {
                this.scpagination = false;
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.scjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.scjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);

              // this.scjudgements.forEach(judgement => {
              //   // const a = judgement.HeadNote;
              //   // this.HeadNoteLess = a.substring(0, 260) + ' ...';
              //   if (typeof judgement.HeadNote !== undefined && judgement.HeadNote !== '' ) {
              //     this.readmore(judgement.HeadNote, judgement._id);
              //   }

              // });
            } else if (data.msg === "err") {
              this.snackBar.open(
                "Error in fetching Supreme Court judgements, Please try again!",
                "OK"
              );
            }
          });
        });
      });
    } else if (court[1] === "Print") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllPrintData(1, this.usertype)
            .subscribe(data => {
              this.printSpinner = false;

              if (data.hits.total > 0) {
                this.printJudgCount = data.hits.total;
                this.PrintpageNo = this.PrintpageNo + 1;
                // //('initialpageno', this.SCpageNo);
                const a: Number = Number(this.printJudgCount) / 15;
                if (Number(this.printJudgCount) % 15 === 0) {
                  this.PrinttotalPages = 0;
                } else if (Number(this.printJudgCount) % 15 > 0) {
                  const temp = a.toFixed(1);
                  const digit = temp[temp.length - 1];
                  if (+digit < 5) {
                    this.PrinttotalPages = +a.toFixed() + 1;
                  } else {
                    this.PrinttotalPages = +a.toFixed();
                  }
                }
                for (let i = 0; i < data.hits.hits.length; i++) {
                  this.printjudgements.push(data.hits.hits[i]["_source"]);
                }
                // //('judgements', this.scjudgements);
                if (a > 1) {
                  this.printpagination = true;
                } else {
                  this.printpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.printjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.printjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching Print judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "Bombay") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMumHCData(1, this.usertype)
            .subscribe(data => {
              this.mumHCSpinner = false;
              if (data.msg === "success") {
                this.mumHCJudgCount = data.result.total;
                this.mumHCpageNo = Number(data.result.page);
                this.mumHCtotalPages = data.result.pages;
                this.mumHCjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mumHCjudgements.push(data.result.docs[i]);
                }

                if (
                  this.mumHCtotalPages !== 1 ||
                  this.mumHCjudgements.length !== 0
                ) {
                  this.mumHCpagination = true;
                } else {
                  this.mumHCpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mumHCjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mumHCjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching Bombay High Court judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "CAT") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService.getAllCATdata(1, this.usertype).subscribe(data => {
            this.catSpinner = false;

            if (data.msg === "success") {
              this.catJudgCount = data.result.total;
              this.CATpageNo = Number(data.result.page);
              this.CATtotalPages = data.result.pages;
              this.catjudgements = [];
              for (let i = 0; i < data.result.docs.length; i++) {
                this.catjudgements.push(data.result.docs[i]);
              }

              if (this.CATtotalPages !== 1 || this.catjudgements.length !== 0) {
                this.catpagination = true;
              } else {
                this.catpagination = false;
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.catjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.catjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            } else if (data.msg === "err") {
              this.snackBar.open(
                "Error in fetching CAT judgements, Please try again!",
                "OK"
              );
            }
          });
        });
      });
    } else if (court[0] === "MAT") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService.getAllMATdata(1, this.usertype).subscribe(data => {
            this.matSpinner = false;

            if (data.msg === "success") {
              this.matJudgCount = data.result.total;
              this.MATpageNo = Number(data.result.page);
              this.MATtotalPages = data.result.pages;
              this.matjudgements = [];
              for (let i = 0; i < data.result.docs.length; i++) {
                this.matjudgements.push(data.result.docs[i]);
              }

              if (this.MATtotalPages !== 1 || this.matjudgements.length !== 0) {
                this.matpagination = true;
              } else {
                this.matpagination = false;
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.matjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.matjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            } else if (data.msg === "err") {
              this.snackBar.open(
                "Error in fetching MAT judgements, Please try again!",
                "OK"
              );
            }
          });
        });
      });
    } else if (court[0] === "IPAB") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllIPABdata(1, this.usertype)
            .subscribe(data => {
              this.ipabSpinner = false;

              if (data.msg === "success") {
                this.ipabJudgCount = data.result.total;
                this.IPABpageNo = Number(data.result.page);
                this.IPABtotalPages = data.result.pages;
                this.ipabjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.ipabjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.ipabjudgements);
                if (
                  this.IPABtotalPages !== 1 ||
                  this.ipabjudgements.length !== 0
                ) {
                  this.ipabpagination = true;
                } else {
                  this.ipabpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.ipabjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.ipabjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching IPAB judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "RCT") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService.getAllRCTdata(1, this.usertype).subscribe(data => {
            this.rctSpinner = false;

            if (data.msg === "success") {
              this.rctJudgCount = data.result.total;
              this.RCTpageNo = Number(data.result.page);
              this.RCTtotalPages = data.result.pages;
              this.rctjudgements = [];
              for (let i = 0; i < data.result.docs.length; i++) {
                this.rctjudgements.push(data.result.docs[i]);
              }
              // //('judgements', this.rctjudgements);
              if (this.RCTtotalPages !== 1 || this.rctjudgements.length !== 0) {
                this.rctpagination = true;
              } else {
                this.rctpagination = false;
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.rctjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.rctjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            } else if (data.msg === "err") {
              this.snackBar.open(
                "Error in fetching RCT judgements, Please try again!",
                "OK"
              );
            }
          });
        });
      });
    } else if (court[0] === "NCDRC") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllNCDRCdata(1, this.usertype)
            .subscribe(data => {
              this.ncdrcSpinner = false;

              if (data.msg === "success") {
                this.ncdrcJudgCount = data.result.total;
                this.NCDRCpageNo = Number(data.result.page);
                this.NCDRCtotalPages = data.result.pages;
                this.ncdrcjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.ncdrcjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.ncdrcjudgements);
                if (
                  this.NCDRCtotalPages !== 1 ||
                  this.ncdrcjudgements.length !== 0
                ) {
                  this.ncdrcpagination = true;
                } else {
                  this.ncdrcpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.ncdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.ncdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching NCDRC judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "MCDRC") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMCDRCdata(1, this.usertype)
            .subscribe(data => {
              this.mcdrcSpinner = false;

              if (data.msg === "success") {
                this.mcdrcJudgCount = data.result.total;
                this.MCDRCpageNo = Number(data.result.page);
                this.MCDRCtotalPages = data.result.pages;
                this.mcdrcjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mcdrcjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.mcdrcjudgements);
                if (
                  this.MCDRCtotalPages !== 1 ||
                  this.mcdrcjudgements.length !== 0
                ) {
                  this.mcdrcpagination = true;
                } else {
                  this.mcdrcpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mcdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mcdrcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MCDRC judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "ITAT") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllITATdata(1, this.usertype)
            .subscribe(data => {
              this.itatSpinner = false;

              if (data.msg === "success") {
                this.itatJudgCount = data.result.total;
                this.ITATpageNo = Number(data.result.page);
                this.ITATtotalPages = data.result.pages;
                this.itatjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.itatjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.itatjudgements);
                if (
                  this.ITATtotalPages !== 1 ||
                  this.itatjudgements.length !== 0
                ) {
                  this.itatpagination = true;
                } else {
                  this.itatpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.itatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.itatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching ITAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "CESTAT") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllCESTATdata(1, this.usertype)
            .subscribe(data => {
              this.cestatSpinner = false;

              if (data.msg === "success") {
                this.cestatJudgCount = data.result.total;
                this.CESTATpageNo = Number(data.result.page);
                this.CESTATtotalPages = data.result.pages;
                this.cestatjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.cestatjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.cestatjudgements);
                if (
                  this.CESTATtotalPages !== 1 ||
                  this.cestatjudgements.length !== 0
                ) {
                  this.cestatpagination = true;
                } else {
                  this.cestatpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.cestatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.cestatjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching CESTAT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "NCLAT") {
      this.authService.checkJWTtoken().then(() => {
        this.browseService.getAllNCLATdata(1, this.usertype).subscribe(data => {
          this.nclatSpinner = false;

          if (data.msg === "success") {
            this.nclatJudgCount = data.result.total;
            this.NCLATpageNo = Number(data.result.page);
            this.NCLATtotalPages = data.result.pages;
            this.nclatjudgements = [];
            for (let i = 0; i < data.result.docs.length; i++) {
              this.nclatjudgements.push(data.result.docs[i]);
            }
            // //('judgements', this.nclatjudgements);
            if (
              this.NCLATtotalPages !== 1 ||
              this.nclatjudgements.length !== 0
            ) {
              this.nclatpagination = true;
            } else {
              this.nclatpagination = false;
            }

            // retaining appropriate boomark icon on page load
            setTimeout(() => {
              this.nclatjudgements.forEach(data => {
                let i = 0;
                i += 1;

                const citation = data.Citation;

                if (
                  this.user.bookmarks.find(cit => cit === citation) !==
                  undefined
                ) {
                  const x = document.getElementById(citation + " active");

                  if (x !== null) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " inactive");

                  if (y !== null) {
                    y.style.display = "none";
                  }
                } else {
                  const x = document.getElementById(citation + " active");

                  if (x !== null) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " inactive");

                  if (y !== null) {
                    y.style.display = "block";
                  }
                }
              });
            }, 100);

            // retaining appropriate annotation icon on page load
            setTimeout(() => {
              this.nclatjudgements.forEach(data => {
                let i = 0;
                i += 1;

                const citation = data.Citation;

                if (
                  this.user.annotations.find(z => z.citation === citation) !==
                  undefined
                ) {
                  const x = document.getElementById(
                    citation + " annotation active"
                  );

                  if (x !== null) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(
                    citation + " annotation inactive"
                  );

                  if (y !== null) {
                    y.style.display = "none";
                  }
                } else {
                  const x = document.getElementById(
                    citation + " annotation active"
                  );

                  if (x !== null) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(
                    citation + " annotation inactive"
                  );

                  if (y !== null) {
                    y.style.display = "block";
                  }
                }
              });
            }, 200);
          } else if (data.msg === "err") {
            this.snackBar.open(
              "Error in fetching NCLAT judgements, Please try again!",
              "OK"
            );
          }
        });
      });
    } else if (court[0] === "MAHARERA") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMAHARERAdata(1, this.usertype)
            .subscribe(data => {
              this.mahareraSpinner = false;

              if (data.msg === "success") {
                this.mahareraJudCount = data.result.total;
                this.MAHARERApageNo = Number(data.result.page);
                this.MAHARERAtotalPages = data.result.pages;
                this.maharerajudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.maharerajudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.maharerajudgements);
                if (
                  this.MAHARERAtotalPages !== 1 ||
                  this.maharerajudgements.length !== 0
                ) {
                  this.maharerapagination = true;
                } else {
                  this.maharerapagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.maharerajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.maharerajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MAHARERA judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "MSCA") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMSCAdata(1, this.usertype)
            .subscribe(data => {
              this.mscaSpinner = false;

              if (data.msg === "success") {
                this.mscaJudgCount = data.result.total;
                this.MSCApageNo = Number(data.result.page);
                this.MSCAtotalPages = data.result.pages;
                this.mscajudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mscajudgements.push(data.result.docs[i]);
                }

                if (
                  this.MSCAtotalPages !== 1 ||
                  this.mscajudgements.length !== 0
                ) {
                  this.mscapagination = true;
                } else {
                  this.mscapagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mscajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mscajudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MSCA judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "MACT") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMACTdata(1, this.usertype)
            .subscribe(data => {
              this.mactSpinner = false;

              if (data.msg === "success") {
                this.mactJudgCount = data.result.total;
                this.MACTpageNo = Number(data.result.page);
                this.MACTtotalPages = data.result.pages;
                this.mactjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.mactjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.mactjudgements);
                if (
                  this.MACTtotalPages !== 1 ||
                  this.mactjudgements.length !== 0
                ) {
                  this.mactpagination = true;
                } else {
                  this.mactpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.mactjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.mactjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MACT judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "MILC") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllMILCdata(1, this.usertype)
            .subscribe(data => {
              this.milcSpinner = false;

              if (data.msg === "success") {
                this.milcJudgCount = data.result.total;
                this.MILCpageNo = Number(data.result.page);
                this.MILCtotalPages = data.result.pages;
                this.milcjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.milcjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.milcjudgements);
                if (
                  this.MILCtotalPages !== 1 ||
                  this.milcjudgements.length !== 0
                ) {
                  this.milcpagination = true;
                } else {
                  this.milcpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.milcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.milcjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching MILC judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "MST") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService.getAllMSTdata(1, this.usertype).subscribe(data => {
            this.mstSpinner = false;

            if (data.msg === "success") {
              this.mstJudgCount = data.result.total;
              this.MSTpageNo = Number(data.result.page);
              this.MSTtotalPages = data.result.pages;
              this.mstjudgements = [];
              for (let i = 0; i < data.result.docs.length; i++) {
                this.mstjudgements.push(data.result.docs[i]);
              }
              // //('judgements', this.mstjudgements);
              if (this.MSTtotalPages !== 1 || this.mstjudgements.length !== 0) {
                this.mstpagination = true;
              } else {
                this.mstpagination = false;
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.mstjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.mstjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            } else if (data.msg === "err") {
              this.snackBar.open(
                "Error in fetching MST judgements, Please try again!",
                "OK"
              );
            }
          });
        });
      });
    } else if (court[0] === "SAT") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService.getAllSATdata(1, this.usertype).subscribe(data => {
            this.satSpinner = false;

            if (data.msg === "success") {
              this.satJudgCount = data.result.total;
              this.SATpageNo = Number(data.result.page);
              this.SATtotalPages = data.result.pages;
              this.satjudgements = [];
              for (let i = 0; i < data.result.docs.length; i++) {
                this.satjudgements.push(data.result.docs[i]);
              }
              // //('judgements', this.satjudgements);
              if (this.SATtotalPages !== 1 || this.satjudgements.length !== 0) {
                this.satpagination = true;
              } else {
                this.satpagination = false;
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.satjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.satjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            } else if (data.msg === "err") {
              this.snackBar.open(
                "Error in fetching SAT judgements, Please try again!",
                "OK"
              );
            }
          });
        });
      });
    } else if (court[0] === "ATFP") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllATFPdata(1, this.usertype)
            .subscribe(data => {
              this.atfpSpinner = false;

              if (data.msg === "success") {
                this.atfpJudgCount = data.result.total;
                this.ATFPpageNo = Number(data.result.page);
                this.ATFPtotalPages = data.result.pages;
                this.atfpjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.atfpjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.atfpjudgements);
                if (
                  this.ATFPtotalPages !== 1 ||
                  this.atfpjudgements.length !== 0
                ) {
                  this.atfppagination = true;
                } else {
                  this.atfppagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.atfpjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.atfpjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching ATFP judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "BIFR") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService
            .getAllBIFRdata(1, this.usertype)
            .subscribe(data => {
              this.bifrSpinner = false;

              if (data.msg === "success") {
                this.bifrJudgCount = data.result.total;
                this.BIFRpageNo = Number(data.result.page);
                this.BIFRtotalPages = data.result.pages;
                this.bifrjudgements = [];
                for (let i = 0; i < data.result.docs.length; i++) {
                  this.bifrjudgements.push(data.result.docs[i]);
                }
                // //('judgements', this.bifrjudgements);
                if (
                  this.BIFRtotalPages !== 1 ||
                  this.bifrjudgements.length !== 0
                ) {
                  this.bifrpagination = true;
                } else {
                  this.bifrpagination = false;
                }

                // retaining appropriate boomark icon on page load
                setTimeout(() => {
                  this.bifrjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.bookmarks.find(cit => cit === citation) !==
                      undefined
                    ) {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(citation + " active");

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(citation + " inactive");

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 100);

                // retaining appropriate annotation icon on page load
                setTimeout(() => {
                  this.bifrjudgements.forEach(data => {
                    let i = 0;
                    i += 1;

                    const citation = data.Citation;

                    if (
                      this.user.annotations.find(
                        z => z.citation === citation
                      ) !== undefined
                    ) {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "block";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "none";
                      }
                    } else {
                      const x = document.getElementById(
                        citation + " annotation active"
                      );

                      if (x !== null) {
                        x.style.display = "none";
                      }

                      const y = document.getElementById(
                        citation + " annotation inactive"
                      );

                      if (y !== null) {
                        y.style.display = "block";
                      }
                    }
                  });
                }, 200);
              } else if (data.msg === "err") {
                this.snackBar.open(
                  "Error in fetching BIFR judgements, Please try again!",
                  "OK"
                );
              }
            });
        });
      });
    } else if (court[0] === "PNG") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService.getAllPNGdata(1, this.usertype).subscribe(data => {
            this.pngSpinner = false;

            if (data.msg === "success") {
              this.pngJudgCount = data.result.total;
              this.PNGpageNo = Number(data.result.page);
              this.PNGtotalPages = data.result.pages;
              this.pngjudgements = [];
              for (let i = 0; i < data.result.docs.length; i++) {
                this.pngjudgements.push(data.result.docs[i]);
              }
              // //('judgements', this.pngjudgements);
              if (this.PNGtotalPages !== 1 || this.pngjudgements.length !== 0) {
                this.pngpagination = true;
              } else {
                this.pngpagination = false;
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.pngjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.pngjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            } else if (data.msg === "err") {
              this.snackBar.open(
                "Error in fetching PNG judgements, Please try again!",
                "OK"
              );
            }
          });
        });
      });
    } else if (court[0] === "DRT/DRAT") {
      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.browseService.getAllDRTdata(1, this.usertype).subscribe(data => {
            this.drtSpinner = false;

            if (data.msg === "success") {
              this.drtJudgCount = data.result.total;
              this.DRTpageNo = Number(data.result.page);
              this.DRTtotalPages = data.result.pages;
              this.drtjudgements = [];
              for (let i = 0; i < data.result.docs.length; i++) {
                this.drtjudgements.push(data.result.docs[i]);
              }
              // //('judgements', this.drtjudgements);
              if (this.DRTtotalPages !== 1 || this.drtjudgements.length !== 0) {
                this.drtpagination = true;
              } else {
                this.drtpagination = false;
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.drtjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.bookmarks.find(cit => cit === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(citation + " active");

                    if (x !== null) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");

                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.drtjudgements.forEach(data => {
                  let i = 0;
                  i += 1;

                  const citation = data.Citation;

                  if (
                    this.user.annotations.find(z => z.citation === citation) !==
                    undefined
                  ) {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );

                    if (x !== null) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );

                    if (y !== null) {
                      y.style.display = "none";
                    }
                  } else {
                    const x = document.getElementById(
                      citation + " annotation active"
                    );
                    if (x !== null) {
                      x.style.display = "none";
                    }
                    const y = document.getElementById(
                      citation + " annotation inactive"
                    );
                    if (y !== null) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 200);
            } else if (data.msg === "err") {
              this.snackBar.open(
                "Error in fetching DRT judgements, Please try again!",
                "OK"
              );
            }
          });
        });
      });
    }
  }

  userBoomarks(judgement) {
    // (judgement);
    const citation = judgement.Citation;
    if (this.user.bookmarks.find(cit => cit === citation) === undefined) {
      // to check if case is already bookmarked
      if (this.user.usertype === "Paid" || this.user.usertype === "MPP") {
        // Paid user can add unlimited bookmarks
        if (this.user.bookmarks[0] === "") {
          this.user.bookmarks.splice(0, 1);
        }
        this.user.bookmarks.push(citation);
        const token = this.cookieService.get("JWT");
        const id = this.cookieService.get("ID");
        this.authService.checkJWTtoken().then(() => {
          timer(3500).subscribe(() => {
            this.authService
              .updateUserWithToken(id, this.user, token)
              .subscribe(userdata => {
                this.user = userdata.result;
                const x = document.getElementById(citation + " active");
                x.style.display = "block";
                const y = document.getElementById(citation + " inactive");
                y.style.display = "none";
                this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                  duration: 3000
                });
              });
          });
        });
      } else if (
        this.user.usertype === "Trial" ||
        this.user.usertype === "Free"
      ) {
        // Trial and Free users can add only 5 bookmarks
        if (this.user.bookmarks[0] === "") {
          this.user.bookmarks.splice(0, 1);
        }
        if (this.user.bookmarks.length < 5) {
          this.user.bookmarks.push(citation);
          const token = this.cookieService.get("JWT");
          const id = this.cookieService.get("ID");

          this.authService.checkJWTtoken().then(() => {
            timer(3500).subscribe(() => {
              this.authService
                .updateUserWithToken(id, this.user, token)
                .subscribe(userdata => {
                  this.user = userdata.result;
                  const x = document.getElementById(citation + " active");
                  x.style.display = "block";
                  const y = document.getElementById(citation + " inactive");
                  y.style.display = "none";
                  this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                    duration: 3000
                  });
                });
            });
          });
        } else {
          const upgradeSnackbar = this.snackBar.open(
            "To save more than 5 bookmarks, please Upgrade to premium plan!",
            "Upgrade",
            {
              duration: 3000
            }
          );
          upgradeSnackbar.onAction().subscribe(() => {
            this.router.navigate(["/paymentgateway"]);
          });
        }
      }
    } else {
      this.user.bookmarks.splice(this.user.bookmarks.indexOf(citation), 1);
      const token = this.cookieService.get("JWT");
      const id = this.cookieService.get("ID");

      this.authService.checkJWTtoken().then(() => {
        timer(3500).subscribe(() => {
          this.authService
            .updateUserWithToken(id, this.user, token)
            .subscribe(userdata => {
              this.user = userdata.result;
              const x = document.getElementById(citation + " active");
              x.style.display = "none";
              const y = document.getElementById(citation + " inactive");
              y.style.display = "block";
              this.snackBar.open("Successfully removed from Bookmarks!", "OK", {
                duration: 3000
              });
            });
        });
      });
    }
  }

  openAnnotationModal() {
    const loginDialogRef = this.annotationModal.open(AnnotationComponent, {
      width: "400px",
      height: "400px"
    });
  }

  userAnnotations(judgement) {
    const citation = judgement.Citation;
    if (
      this.user.annotations.find(
        annotation => annotation.citation === citation
      ) === undefined ||
      this.user.annotations.length === 0
    ) {
      this.cookieService.set("cit", citation, 1);
      this.openAnnotationModal();
    } else {
      this.user.annotations.forEach(annotation => {
        if (annotation.citation === citation) {
          const cit = annotation.citation;
          const body = annotation.body;
          this.cookieService.set("cit", citation, 1);
          this.cookieService.set("body", body, 1);
          this.openAnnotationModal();
        }
      });
    }
  }
}
@Component({
  // tslint:disable-next-line:component-selector
  selector: "marketing",
  templateUrl: "marketing_dailoge.html"
})
export class MarketingComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

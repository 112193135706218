import { Component, OnInit } from '@angular/core';

import { paymentResponse } from '../shared/paymentResponse';
import { AuthService } from  '../services/auth.service';

@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.scss']
})
export class PaymentResponseComponent implements OnInit {

  paymentResponse = new paymentResponse();

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    var payResponse = localStorage.getItem('paydetails');
    // console.log('payresponse', payResponse);
    this.paymentResponse = JSON.parse(payResponse);
    // console.log('paymentresponse', this.paymentResponse);
    this.authService.sendDatatoPaytm(this.paymentResponse).subscribe(data => {
      // console.log('data', data);
    });
  }

}

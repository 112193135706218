export class Judgement {
    _id: string;
    Case_No: string;
    Petitioner: string;
    Respondent: string;
    Citation: string;
    Adv_petitioner: string;
    Adv_respondent: string;
    Judge_name: string;
    Court: string;
    cases_citated: string;
    District: string;
    Date: string;
    HeadNote: string;
    headline: string;
    // HeadNote_keywords: string;
    decision: string;
    fileurl: string;
    pdfurl: string;
    file_url: string;
    tribunal: string;
    rule: string;
    order: string;
    judgement: string;
    section: string;
    notification: boolean;
    latest_important: boolean;
    old_citation: string;
    act_statue_name: string;
    citedNo: number;
    article: string;
    Highcourt: string;
    hc_decision: string;
    topics: [string];
    createdAt: string;
    updatedAt: string;
    // sort: string; // sorting latest judg in asc/desc order
}



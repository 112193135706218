export class paymentResponse {
    CALLBACK_URL: string;
    CHANNEL_ID: string;
    CHECKSUMHASH: string;
    CUST_ID: string;
    EMAIL: string;
    INDUSTRY_TYPE_ID: string;
    MID: string;
    MOBILE_NO: string;
    ORDER_ID: string;
    TXN_AMOUNT: string;
    WEBSITE: string;
}
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBSf9InsDj-fL-TDHJMtJT56OEjsd01M9Q',
    authDomain: 'nearlawweb.firebaseapp.com',
    databaseURL: 'https://nearlawweb.firebaseio.com',
    projectId: 'nearlawweb',
    storageBucket: 'nearlawweb.appspot.com',
    messagingSenderId: '1070372078359',
    appId: '1:1070372078359:web:70e1a7a5ab74dc3c26ed52'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Component, OnInit } from "@angular/core";
import { RouterModule, Routes, Router } from "@angular/router";
import {MatDialog, MatChip, MatSelectChange, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from "@angular/material";

import { AuthService } from "../services/auth.service";
import { BrowseService } from "../services/browse.service";
import { CookieService } from "ngx-cookie-service";

import { Judgement } from "../shared/judgement";
import { User } from "../shared/user";
import { courtNameForLatestJudg } from "../shared/courtnames";

import { AnnotationComponent } from "../annotation/annotation.component";
import { timer } from "rxjs";

import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "app-oftenjudg",
  templateUrl: "./oftenjudg.component.html",
  styleUrls: ["./oftenjudg.component.scss"]
})
export class OftenjudgComponent implements OnInit {

  user = new User();
  contactno: string;
  oftencited_spinner = false;
  usertype: string;
  oftencitedJudgments: Judgement[] = [];
  oftencitedJudgCount: number;
  oftencitedPageNo = 1;
  oftencitedTotalPages: number;
  oftencitedpagination = false;

  fontSize = 16;
  zoomInCount = 0;
  zoomOutCount = 0;
  zoomCount = 0;

  playSpeech = false;
  counterSpeech = 0;

  showFilters = true;
  showCourtFilters = true;

  isMobile = false;
  isDesktop = false;
  isTablet = false;
  totalResult: number = null;
  sortValue = "desc";
  filterDateFrom: Date = null;
  filterDateTo: Date = null;
  maxDate: Date = new Date(Date.now());
  showDateFilterCancelBtn = false;
  bookmarkSpinner = false;
  isSingleJudg = false;
  showListView = true;
  opened = false;
  singleJudg: Judgement;
  query = "";

  filterCourtList = courtNameForLatestJudg;
  filterCourtValue: string = null;

  filterSearchQuery = "";
  filterSearchQueryValue = "";
  filterQuery = "";
  filterDateQuery = "";
  filterCourtQuery = "";
  filterSearchState = "initial";

  disablePreviousBtn = false;
  disableNextBtn = false;
  
  constructor(
    private authService: AuthService,
    private browseService: BrowseService,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    public snackBar: MatSnackBar,
    public annotationModal: MatDialog
  ) { }

  ngOnInit() {
    this.usertype = this.cookieService.get("usertype");
    this.contactno = this.cookieService.get("contactno");
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    if ( this.isMobile === true ) {
      this.showCourtFilters = false;
    }

    this.authService.checkJWTtoken().then(() => {
      timer(2000).subscribe(() => {
        this.authService.getUserByContactno(this.contactno).subscribe(userdata => {
          this.user = userdata;
        });
      });
    });
    this.oftencited_spinner = true;
    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService.getoftencitedJudgements(0, this.usertype, this.sortValue).subscribe(data => {
          this.oftencited_spinner = false;
          if ( data.hits.total > 0 ) {
            this.totalResult = data.hits.total;
            this.oftencitedJudgCount = data.hits.total;
            for ( let i = 0; i < data.hits.hits.length; i++ ) {
              this.oftencitedJudgments.push(data.hits.hits[i]._source);
            }
            this.disablePreviousBtn = true;
            // retaining appropriate boomark icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                
                if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }

                } else {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }
                }
              });
            }, 100);

            // retaining appropriate annotation icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                
                if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }

                } else {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }


                }
              });
            }, 200);

            // calculate total pages
            const a: number = this.oftencitedJudgCount / 15;
            
            
            if ( this.oftencitedJudgCount % 15 === 0 ) {
              this.oftencitedTotalPages = a;
            } else if ( this.oftencitedJudgCount % 15 > 0 ) {

              const temp = a.toFixed(1);
              const digit = temp[temp.length - 1];
              

              if ( +digit < 5 ) {
                this.oftencitedTotalPages = +(a.toFixed()) + 1;
              } else {
                this.oftencitedTotalPages = +(a.toFixed());
              }
            }
            
            if ( a > 1 ) {
              this.oftencitedpagination = true;
            } else {
              this.oftencitedpagination = false;
            }
          }
        });
      });
    });
  }
  /**
   * to insert the pdf in session storage
   * @param pdfName
   */
  setpdf(pdfName) {
    pdfName = "https://nearlaw.com/PDF/" + pdfName;
    sessionStorage.setItem("pdfUrl", pdfName);
    window.open(
      "https://nearlaw.com/PDF/web/viewer.html",
      "_blank"
    );
  }
  GetParent(elem) {
    this.counterSpeech = this.counterSpeech + 1;
    // // console.log('counterSpeech', this.counterSpeech);
    this.playSpeech = !this.playSpeech;
    // // console.log('playSpeech', this.playSpeech);
    if ( this.playSpeech === true ) {
      if ( this.counterSpeech > 1 ) {
        speechSynthesis.resume();
      } else {
        speechSynthesis.cancel();
        const parentElem = document.getElementById(elem).parentElement.innerText;
        const text = parentElem.substr(65);
        const text1 = text.slice(0, -19);
        text1.replace(/\./g, " ");
        text1.replace(/\:/g, " ");
        const chunk = text1.match(/\b[\w']+(?:[^\w\n]+[\w']+){0,15}\b/g);  // spillting at \n and every 15th whitespace
        const ttsArray: SpeechSynthesisUtterance[] = [];
        chunk.forEach((chunkElem) => {
          ttsArray.push(new SpeechSynthesisUtterance(chunkElem));
        });
        ttsArray.forEach((ttsElem) => {
          ttsElem.lang = "en-IN";
          ttsElem.pitch = 1;
          ttsElem.rate = 0.9;
        });
        ttsArray.forEach((ttsElem) => {
          window.speechSynthesis.speak(ttsElem);
        });
      }
    } else if ( this.playSpeech === false ) {
      speechSynthesis.pause();
    }
  }

  StopSpeech() {
    speechSynthesis.cancel();
    this.counterSpeech = 0;
    this.playSpeech = false;
  }

  ZoomIn(id) {
    this.zoomCount += 1;
    if ( this.zoomCount < 9 ) {
      this.fontSize += 1.2;
      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if ( this.zoomCount >= 9 ) {
      this.zoomCount = 9;
    }
  }

  ZoomOut(id) {
    this.zoomCount -= 1;
    if ( this.zoomCount > -4 ) {
      this.fontSize -= 1.2;
      const elem = document.getElementById(id).parentElement;
      elem.style.fontSize = this.fontSize + "px";
    }
    if ( this.zoomCount < -3 ) {
      this.zoomCount = -3;
    }
  }

  nextoftencitedPage() {
    this.oftencitedJudgments = [];
    this.oftencitedPageNo += 1;

    if ( this.oftencitedPageNo > 1 ) {
      this.disablePreviousBtn = false;
    }

    if ( this.oftencitedPageNo === this.oftencitedTotalPages ) {
      this.disableNextBtn = true;
    }

    if ( this.oftencitedPageNo > this.oftencitedTotalPages ) {
      this.snackBar.open("End of result!", "OK");
    } else {
      this.oftencited_spinner = true;
      const page_no = (this.oftencitedPageNo - 1 ) * 15;

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService.searchOftenCitedJudgements(page_no, this.usertype, this.sortValue, this.query).subscribe(data => {
            
            this.oftencited_spinner = false;

            for ( let i = 0; i < data.hits.hits.length; i++ ) {
              this.oftencitedJudgments.push(data.hits.hits[i]._source);
            }

            // retaining appropriate boomark icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                
                if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }

                } else {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }
                }
              });
            }, 100);

            // retaining appropriate annotation icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                
                if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }

                } else {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }


                }
              });
            }, 200);

          });
        });
      });

    }
  }

  previousoftencitedPage() {
    this.oftencitedJudgments = [];
    this.oftencitedPageNo -= 1;

    if ( this.oftencitedPageNo === 1 ) {
      this.disablePreviousBtn = true;
    }

    if ( this.oftencitedPageNo < this.oftencitedTotalPages ) {
      this.disableNextBtn = false;
    }

    if ( this.oftencitedPageNo > 0 ) {
      this.oftencited_spinner = true;
      const page_no = (this.oftencitedPageNo - 1 ) * 15;

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService.searchOftenCitedJudgements(page_no, this.usertype, this.sortValue, this.query).subscribe(data => {
            
            this.oftencited_spinner = false;

            for ( let i = 0; i < data.hits.hits.length; i++ ) {
              this.oftencitedJudgments.push(data.hits.hits[i]._source);
            }

            // retaining appropriate boomark icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                
                if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }

                } else {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }
                }
              });
            }, 100);

            // retaining appropriate annotation icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                
                if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }

                } else {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }


                }
              });
            }, 200);

          });
        });
      });

    } else {
      this.snackBar.open("Start of a result!", "OK");
    }
  }

  singlePrint(id: string) {
    // // //('divId', id);
    const printContents = document.getElementById(id).innerHTML;
    // // //(printContents);
    const w = window.open();
    w.document.write("<h1 align=\"center\" style=\"color: blue;\">NearLaw</h1>");
    w.document.write("<div style=\"text-align: justify\">" + printContents + "</div>");
    w.document.write("<style>.hidden-print{display:none!important}</style>");
    w.document.write("<scr" + "ipt type=\"text/javascript\">" + "window.onload = function() { window.print(); window.close(); };" + "</sc" + "ript>");

    w.document.close(); // necessary for IE >= 10
    w.focus(); // necessary for IE >= 10
    // window.print();
    return true;
  }

  userBoomarks(judgement) {
    // (judgement);
    const citation = judgement.Citation;
    // ('citation', judgement.Citation);
    // ('user', this.user);
    if ( this.user.bookmarks.find((cit) => cit === citation) === undefined ) {      // to check if case is already bookarked
      if ( this.user.bookmarks[0] === "" ) {
        this.user.bookmarks.splice(0, 1);
      }
      if ( this.user.usertype === "Paid" || this.user.usertype === "MPP" ) {                                        // Paid user can add unlimited bookmarks
        this.user.bookmarks.push(citation);
        // ('user', this.user);
        // const token = localStorage.getItem('JWT');
        const token = this.cookieService.get("JWT");
        // const id = localStorage.getItem('ID');
        const id = this.cookieService.get("ID");

        this.bookmarkSpinner = true;

        this.authService.checkJWTtoken().then(() => {
          timer(3000).subscribe(() => {
            this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
              this.user = userdata.result;
              this.bookmarkSpinner = false;
              // ('userdata', this.user);
              // this.isBookmarked = true;
              const x = document.getElementById(citation + " active");
              x.style.display = "block";
              const y = document.getElementById(citation + " inactive");
              y.style.display = "none";
              this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                duration: 3000
              });
            });
          });
        });

      } else if ( this.user.usertype === "Trial" || this.user.usertype === "Free" ) {   // Trial and Free users can add only 5 bookmarks
        if ( this.user.bookmarks.length < 5 ) {
          this.user.bookmarks.push(citation);
          // ('user', this.user);
          // const token = localStorage.getItem('JWT');
          const token = this.cookieService.get("JWT");
          // const id = localStorage.getItem('ID');
          const id = this.cookieService.get("ID");

          this.bookmarkSpinner = true;

          this.authService.checkJWTtoken().then(() => {
            timer(3000).subscribe(() => {
              this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
                this.user = userdata.result;

                this.bookmarkSpinner = false;
                // ('userdata', this.user);
                // this.isBookmarked = true;
                const x = document.getElementById(citation + " active");
                x.style.display = "block";
                const y = document.getElementById(citation + " inactive");
                y.style.display = "none";
                this.snackBar.open("Successfully added to Bookmarks!", "OK", {
                  duration: 3000
                });
              });
            });
          });
        } else {
          const upgradeSnackbar = this.snackBar.open("To save more than 5 bookmarks, please Upgrade to premium plan!", "Upgrade", {
            duration: 3000
          });
          upgradeSnackbar.onAction().subscribe(() => {
            this.router.navigate(["/paymentgateway"]);
          });
        }
      }
    } else {
      // this.snackBar.open('Already Bookmarked!', 'OK', {
      //   duration: 3000
      // });
      this.user.bookmarks.splice(this.user.bookmarks.indexOf(citation), 1);
      // ('after delete', this.user);
      // const token = localStorage.getItem('JWT');
      const token = this.cookieService.get("JWT");
      // const id = localStorage.getItem('ID');
      const id = this.cookieService.get("ID");

      this.bookmarkSpinner = true;

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
            this.user = userdata.result;

            this.bookmarkSpinner = false;
            // ('userdata', this.user);
            // this.isBookmarked = false;
            const x = document.getElementById(citation + " active");
            x.style.display = "none";
            const y = document.getElementById(citation + " inactive");
            y.style.display = "block";
            this.snackBar.open("Successfully removed from Bookmarks!", "OK", {
              duration: 3000
            });
          });
        });
      });

    }
  }

  openAnnotationModal() {
    const loginDialogRef = this.annotationModal.open(AnnotationComponent, {
      width: "400px",
      height: "400px"
    });
  }

  userAnnotations(judgement) {
    const citation = judgement.Citation;
    // // console.log('annotation', this.user);
    if ((this.user.annotations.find((annotation) => annotation.citation === citation) === undefined) || (this.user.annotations.length === 0)) {
      // localStorage.setItem('cit', citation);
      this.cookieService.set("cit", citation);
      this.openAnnotationModal();

    } else {
      this.user.annotations.forEach((annotation) => {
        if ( annotation.citation === citation ) {
          const cit = annotation.citation;
          const body = annotation.body;
          // localStorage.setItem('cit', cit);
          this.cookieService.set("cit", cit);
          localStorage.setItem("body", body);
          this.cookieService.set("body", body);
          this.openAnnotationModal();
        }
      });
    }
  }

  searchOftenCitedJudg() {
    // // console.log('from', this.filterDateFrom.toISOString());
    // // console.log('to', this.filterDateTo.toISOString());
    const from = this.filterDateFrom.toISOString().replace("T", " ").slice(0, -5);
    const to = this.filterDateTo.toISOString().replace("T", " ").slice(0, -5);

    this.filterDateQuery = "doj_sort:[\"" + from + "\"+TO+\"" + to + "\"] AND ";

    this.query = this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);

    // // console.log('query1', this.query);

    // if ( this.filterCourtValue !== null && this.filterCourtValue !== 'All' ) {
    //   this.query = 'doj_sort:["' + from + '"+TO+"' + to + '"] AND tribunal:"' + this.filterCourtValue + '"';
    // } else {
    //   this.query = 'doj_sort:["' + from + '"+TO+"' + to + '"]';
    // }

    // // console.log('query1', this.query);
    // const query = '';

    this.oftencited_spinner = true;
    this.oftencitedJudgments = [];
    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService.searchOftenCitedJudgements(0, this.usertype, this.sortValue, this.query).subscribe((resp) => {
          this.oftencited_spinner = false;
          this.showDateFilterCancelBtn = true;
          // // console.log('resp', resp);
          this.oftencitedPageNo = 1;
          this.totalResult = resp.hits.total.value;
          if ( resp.hits.total.value > 0 ) {
            for ( let i = 0; i < resp.hits.hits.length; i++ ) {
              this.oftencitedJudgments.push(resp.hits.hits[i]._source);
            }

            this.disablePreviousBtn = true;
            this.disableNextBtn = false;

            // retaining appropriate boomark icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                // // // console.log('citation', citation);
                if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }

                } else {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }
                }
              });
            }, 100);

            // retaining appropriate annotation icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                
                if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }

                } else {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }


                }
              });
            }, 200);

            // calculate total pages
            const a: number = this.totalResult / 15;
            
            // console.log('latest count', this.totalResult);
            if ( this.totalResult % 15 === 0 ) {
              this.oftencitedTotalPages = a;
            } else if ( this.totalResult % 15 > 0 ) {
              this.oftencitedTotalPages = +(a.toFixed()) + 1;
            }
            
            if ( a > 1 ) {
              this.oftencitedpagination = true;
            } else {
              this.oftencitedpagination = false;
            }
          } else {
            this.snackBar.open("No Results found!", "OK", {
              duration: 4000
            });
          }
        });
      });
    });

  }

  defaultSearch() {
    this.filterDateFrom = null;
    this.filterDateTo = null;
    this.oftencited_spinner = true;
    this.oftencitedJudgments = [];
    // this.query = '';
    this.filterDateQuery = "";
    this.query = this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);
    // // console.log('query', this.query);

    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService.searchOftenCitedJudgements(0, this.usertype, this.sortValue, this.query).subscribe((resp) => {
          this.oftencited_spinner = false;
          this.showDateFilterCancelBtn = false;
          // // console.log('resp', resp);
          this.oftencitedPageNo = 1;
          this.totalResult = resp.hits.total.value;
          if ( resp.hits.total.value > 0 ) {
            for ( let i = 0; i < resp.hits.hits.length; i++ ) {
              this.oftencitedJudgments.push(resp.hits.hits[i]._source);
            }

            this.disablePreviousBtn = true;
            this.disableNextBtn = false;

            // retaining appropriate boomark icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                // // // console.log('citation', citation);
                if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }

                } else {
                  
                  const x = document.getElementById(citation + " active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }

                  const y = document.getElementById(citation + " inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }
                }
              });
            }, 100);

            // retaining appropriate annotation icon on page load
            setTimeout(() => {
              this.oftencitedJudgments.forEach(data => {
                let i = 0;
                i += 1;
                
                const citation = data.Citation;
                
                if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "block";
                  }
                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "none";
                  }
                } else {
                  
                  const x = document.getElementById(citation + " annotation active");
                  
                  if ( x !== null ) {
                    x.style.display = "none";
                  }
                  const y = document.getElementById(citation + " annotation inactive");
                  
                  if ( y !== null ) {
                    y.style.display = "block";
                  }
                }
              });
            }, 200);

            // calculate total pages
            const a: number = this.totalResult / 15;
            
            // console.log('latest count', this.totalResult);
            if ( this.totalResult % 15 === 0 ) {
              this.oftencitedTotalPages = a;
            } else if ( this.totalResult % 15 > 0 ) {
              const temp = a.toFixed(1);
              const digit = temp[temp.length - 1];
              

              if ( +digit < 5 ) {
                this.oftencitedTotalPages = +(a.toFixed()) + 1;
              } else {
                this.oftencitedTotalPages = +(a.toFixed());
              }
            }
            
            if ( a > 1 ) {
              this.oftencitedpagination = true;
            } else {
              this.oftencitedpagination = false;
            }
          }
        });
      });
    });
  }

  getJudgOnSort() {
    this.sortValue = this.sortValue === "desc" ? "asc" : "desc";
    if ( this.sortValue === "desc" ) {
      this.oftencited_spinner = true;
      this.oftencitedJudgments = [];
      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService.searchOftenCitedJudgements(0, this.usertype, this.sortValue, this.query).subscribe((resp) => {
            this.oftencited_spinner = false;
            // // console.log('data', resp);
            this.oftencitedPageNo = 1;
            this.totalResult = resp.hits.total.value;
            if ( resp.hits.total.value > 0 ) {
              for ( let i = 0; i < resp.hits.hits.length; i++ ) {
                this.oftencitedJudgments.push(resp.hits.hits[i]._source);
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.oftencitedJudgments.forEach(data => {
                  let i = 0;
                  i += 1;
                  
                  const citation = data.Citation;
                  // // // console.log('citation', citation);
                  if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                    
                    const x = document.getElementById(citation + " active");
                    
                    if ( x !== null ) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");
                    
                    if ( y !== null ) {
                      y.style.display = "none";
                    }

                  } else {
                    
                    const x = document.getElementById(citation + " active");
                    
                    if ( x !== null ) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");
                    
                    if ( y !== null ) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.oftencitedJudgments.forEach(data => {
                  let i = 0;
                  i += 1;
                  
                  const citation = data.Citation;
                  
                  if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                    
                    const x = document.getElementById(citation + " annotation active");
                    
                    if ( x !== null ) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " annotation inactive");
                    
                    if ( y !== null ) {
                      y.style.display = "none";
                    }

                  } else {
                    
                    const x = document.getElementById(citation + " annotation active");
                    
                    if ( x !== null ) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " annotation inactive");
                    
                    if ( y !== null ) {
                      y.style.display = "block";
                    }


                  }
                });
              }, 200);

              if ( this.showListView === false ) {
                this.singleJudg = this.oftencitedJudgments[0];
                this.isSingleJudg = true;
              }
            }
          });
        });
      });
    } else if ( this.sortValue === "asc" ) {
      this.oftencited_spinner = true;
      this.oftencitedJudgments = [];
      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.browseService.searchOftenCitedJudgements(0, this.usertype, this.sortValue, this.query).subscribe((resp) => {
            this.oftencited_spinner = false;
            this.oftencitedPageNo = 1;
            // // console.log('resp', resp);
            this.totalResult = resp.hits.total.value;
            if ( resp.hits.total.value > 0 ) {
              for ( let i = 0; i < resp.hits.hits.length; i++ ) {
                this.oftencitedJudgments.push(resp.hits.hits[i]._source);
              }

              // retaining appropriate boomark icon on page load
              setTimeout(() => {
                this.oftencitedJudgments.forEach(data => {
                  let i = 0;
                  i += 1;
                  
                  const citation = data.Citation;
                  // // // console.log('citation', citation);
                  if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {
                    
                    const x = document.getElementById(citation + " active");
                    
                    if ( x !== null ) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " inactive");
                    
                    if ( y !== null ) {
                      y.style.display = "none";
                    }

                  } else {
                    
                    const x = document.getElementById(citation + " active");
                    
                    if ( x !== null ) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " inactive");
                    
                    if ( y !== null ) {
                      y.style.display = "block";
                    }
                  }
                });
              }, 100);

              // retaining appropriate annotation icon on page load
              setTimeout(() => {
                this.oftencitedJudgments.forEach(data => {
                  let i = 0;
                  i += 1;
                  
                  const citation = data.Citation;
                  
                  if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
                    
                    const x = document.getElementById(citation + " annotation active");
                    
                    if ( x !== null ) {
                      x.style.display = "block";
                    }

                    const y = document.getElementById(citation + " annotation inactive");
                    
                    if ( y !== null ) {
                      y.style.display = "none";
                    }

                  } else {
                    
                    const x = document.getElementById(citation + " annotation active");
                    
                    if ( x !== null ) {
                      x.style.display = "none";
                    }

                    const y = document.getElementById(citation + " annotation inactive");
                    
                    if ( y !== null ) {
                      y.style.display = "block";
                    }


                  }
                });
              }, 200);

              if ( this.showListView === false ) {
                this.singleJudg = this.oftencitedJudgments[0];
                this.isSingleJudg = true;
              }
            }
          });
        });
      });
    }
  }

  toggleView() {
    this.showListView = !this.showListView;
    if ( this.showListView === false ) {
      this.singleJudg = this.oftencitedJudgments[0];
      this.isSingleJudg = true;

      // retaining appropriate boomark icon on view change
      setTimeout(() => {
        this.oftencitedJudgments.forEach(data => {
          const citation = data.Citation;

          if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {

            const x = document.getElementById(citation + " active");

            if ( x !== null ) {
              x.style.display = "block";
            }

            const y = document.getElementById(citation + " inactive");

            if ( y !== null ) {
              y.style.display = "none";
            }

          } else {

            const x = document.getElementById(citation + " active");

            if ( x !== null ) {
              x.style.display = "none";
            }

            const y = document.getElementById(citation + " inactive");

            if ( y !== null ) {
              y.style.display = "block";
            }
          }
        });
      }, 100);

      // retaining appropriate annotation icon on view change
      setTimeout(() => {
        this.oftencitedJudgments.forEach(data => {

          const citation = data.Citation;

          if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {

            const x = document.getElementById(citation + " annotation active");

            if ( x !== null ) {
              x.style.display = "block";
            }

            const y = document.getElementById(citation + " annotation inactive");

            if ( y !== null ) {
              y.style.display = "none";
            }

          } else {

            const x = document.getElementById(citation + " annotation active");

            if ( x !== null ) {
              x.style.display = "none";
            }

            const y = document.getElementById(citation + " annotation inactive");

            if ( y !== null ) {
              y.style.display = "block";
            }

          }
        });
      }, 300);

    } else if ( this.showListView === true ) {
      // retaining appropriate boomark icon on view change
      setTimeout(() => {
        this.oftencitedJudgments.forEach(data => {
          const citation = data.Citation;

          if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {

            const x = document.getElementById(citation + " active");

            if ( x !== null ) {
              x.style.display = "block";
            }

            const y = document.getElementById(citation + " inactive");

            if ( y !== null ) {
              y.style.display = "none";
            }

          } else {

            const x = document.getElementById(citation + " active");

            if ( x !== null ) {
              x.style.display = "none";
            }

            const y = document.getElementById(citation + " inactive");

            if ( y !== null ) {
              y.style.display = "block";
            }
          }
        });
      }, 100);

      // retaining appropriate annotation icon on view change
      setTimeout(() => {
        this.oftencitedJudgments.forEach(data => {

          const citation = data.Citation;

          if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {
            // // console.log('2');
            const x = document.getElementById(citation + " annotation active");
            // // console.log('x', x);
            if ( x !== null ) {
              x.style.display = "block";
            }

            const y = document.getElementById(citation + " annotation inactive");
            // // console.log('y', y);
            if ( y !== null ) {
              y.style.display = "none";
            }

          } else {
            // // console.log('1');
            const x = document.getElementById(citation + " annotation active");
            // // console.log('x', x);
            if ( x !== null ) {
              x.style.display = "none";
            }

            const y = document.getElementById(citation + " annotation inactive");
            // // console.log('y', y);
            if ( y !== null ) {
              y.style.display = "block";
            }

          }
        });
      }, 300);
    }
  }

  showJudgementData(data: Judgement ) {
    this.singleJudg = data;
    // // // console.log('single judg', this.singleJudg);
    this.isSingleJudg = true;

    // retaining appropriate boomark icon on page load
    setTimeout(() => {
      //   this.judgements.forEach(data =>{
      //     let i = 0;
      //     i += 1;
      //     //('i', i);
      const citation = this.singleJudg.Citation;
      // // // // console.log('citation', citation);
          // // // // console.log('test', this.user.bookmarks.find((cit) => cit === citation))
          if ( this.user.bookmarks.find((cit) => cit === citation) !== undefined ) {

            const x = document.getElementById(citation + " active");

            if ( x !== null ) {
              x.style.display = "block";
            }

            const y = document.getElementById(citation + " inactive");

            if ( y !== null ) {
              y.style.display = "none";
            }

          } else {

            const x = document.getElementById(citation + " active");

            if ( x !== null ) {
              x.style.display = "none";
            }

            const y = document.getElementById(citation + " inactive");

            if ( y !== null ) {
              y.style.display = "block";
            }
          }
      //   });
      }, 100);

      // retaining appropriate annotation icon on page load
      setTimeout(() => {
      //   this.judgements.forEach(data =>{
      //     let i = 0;
      //     i += 1;
      //     //('i', i);
        const citation = this.singleJudg.Citation;
        // // // // console.log('citation', citation);
        // // // // console.log('test', this.user.annotations.find((z) => z.citation === citation));
        if ( this.user.annotations.find((z) => z.citation === citation) !== undefined ) {

          const x = document.getElementById(citation + " annotation active");

          if ( x !== null ) {
            x.style.display = "block";
          }

          const y = document.getElementById(citation + " annotation inactive");

          if ( y !== null ) {
            y.style.display = "none";
          }

        } else {

          const x = document.getElementById(citation + " annotation active");

          if ( x !== null ) {
            x.style.display = "none";
          }

          const y = document.getElementById(citation + " annotation inactive");

          if ( y !== null ) {
            y.style.display = "block";
          }

        }
    //   });
    }, 200);
  }

  onFilterCourtChange(event: MatSelectChange) {
    // // console.log(event.value);
    // // console.log(this.query);
    // // console.log('test', this.query.indexOf('tribunal:'));
    const court = "";
    const param = "";

    this.oftencitedJudgments = [];
    if ( event.value !== "All" ) {
      this.filterCourtQuery = "tribunal:\"" + event.value + "\" AND ";
    } else {
      this.filterCourtQuery = "";
    }

    this.query = this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);

    // if ( this.query !== '' ) {
    //   if ( this.query.includes('tribunal:') === true ) {    // prevent adding of tribunal value multiple times in a query
    //     const index = this.query.indexOf('tribunal:');
    //     // // console.log('index', index);
    //     this.query = this.query.slice(0, -(this.query.length - index));
    //     // console.log('query1', this.query);
    //     if ( event.value !== 'All' ) {
    //       param = 'tribunal:"' + event.value + '" AND ';
    //     } else {
    //       param = '';
    //     }
    //   } else {
    //     if ( event.value !== 'All' ) {
    //       param = ' AND tribunal:"' + event.value + '" AND ';
    //     } else {
    //       param = ' AND ';
    //     }
    //   }
    //   this.query = this.query + param;
    //   // this.query = this.query.slice(0, -5);
    // } else {
    //   if ( event.value !== 'All' ) {
    //     param = 'tribunal:"' + event.value + '" AND ';
    //   } else {
    //     param = ' AND ';
    //   }
    //   this.query = param;
    //   // this.query = this.query.slice(0, -5);
    // }
    // this.query = this.query.slice(0, -5);
    this.oftencited_spinner = true;
    // console.log('query', this.query);
    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.browseService.searchOftenCitedJudgements(0, this.usertype, this.sortValue, this.query).subscribe((data) => {
          // console.log('data', data);
          // this.query = this.query.slice(0, -(param.length));
          this.oftencitedPageNo = 1;
          // // console.log('query', this.query);

          this.oftencited_spinner = false;
          if ( data.hits.total.value > 0 ) {
            this.totalResult = data.hits.total.value;
            // // console.log(data.hits.total);
            for ( let i = 0; i < data.hits.hits.length; i++ ) {
              this.oftencitedJudgments.push(data.hits.hits[i]._source);
            }

            this.disablePreviousBtn = true;
            this.disableNextBtn = false;

            this.singleJudg = data.hits.hits[0]._source;
            this.isSingleJudg = true;

            // calculate total pages
            const a: number = this.totalResult / 15;
            
            // console.log('latest count', this.totalResult);
            if ( this.totalResult % 15 === 0 ) {
              this.oftencitedTotalPages = a;
            } else if ( this.totalResult % 15 > 0 ) {
              const temp = a.toFixed(1);
              const digit = temp[temp.length - 1];
              

              if ( +digit < 5 ) {
                this.oftencitedTotalPages = +(a.toFixed()) + 1;
              } else {
                this.oftencitedTotalPages = +(a.toFixed());
              }
            }
            
            if ( a > 1 ) {
              this.oftencitedpagination = true;
            } else {
              this.oftencitedpagination = false;
            }
          } else {
            this.totalResult = data.hits.total.value;
            this.snackBar.open("No results found!", "OK", {
              duration: 4000
            });
          }
        });
      });
    });
  }

  filterSearch() {
    // this.filterSearchState = this.filterSearchState === 'initial' ? 'final' : 'initial';
    if ( this.filterSearchQueryValue.trim().indexOf(" ") !== -1 ) {
      this.filterSearchQuery = this.filterSearchQueryValue.trim().replace(/\s+/g, " AND ") + " AND ";
    } else {
      this.filterSearchQuery = this.filterSearchQueryValue.trim() + " AND ";
    }
    this.query = this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);
    // console.log('query', this.query);

    this.oftencitedJudgments = [];
    this.oftencited_spinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.browseService.searchOftenCitedJudgements(0, this.usertype, this.sortValue, this.query).subscribe((data) => {
          this.oftencitedPageNo = 1;
          this.oftencited_spinner = false;
          // // console.log('query', this.query);
          this.totalResult = data.hits.total.value;
          if ( data.hits.total.value > 0 ) {

            // // console.log(data.hits.total);
            for ( let i = 0; i < data.hits.hits.length; i++ ) {
              this.oftencitedJudgments.push(data.hits.hits[i]._source);
            }

            this.disablePreviousBtn = true;
            this.disableNextBtn = false;

            this.singleJudg = data.hits.hits[0]._source;
            this.isSingleJudg = true;

            // calculate total pages
            const a: number = this.totalResult / 15;
            
            // console.log('latest count', this.totalResult);
            if ( this.totalResult % 15 === 0 ) {
              this.oftencitedTotalPages = a;
            } else if ( this.totalResult % 15 > 0 ) {
              const temp = a.toFixed(1);
              const digit = temp[temp.length - 1];
              

              if ( +digit < 5 ) {
                this.oftencitedTotalPages = +(a.toFixed()) + 1;
              } else {
                this.oftencitedTotalPages = +(a.toFixed());
              }
            }
            
            if ( a > 1 ) {
              this.oftencitedpagination = true;
            } else {
              this.oftencitedpagination = false;
            }
          } else {
            this.snackBar.open("No results found!", "OK", {
              duration: 4000
            });
          }
        });
      });
    });
  }

  cancelSearchFilter() {
    this.filterSearchQuery = "";
    this.filterSearchQueryValue = "";
    this.query = this.filterSearchQuery + this.filterDateQuery + this.filterCourtQuery;
    this.query = this.query.slice(0, -5);
    // console.log('query', this.query);

    this.oftencitedJudgments = [];
    this.oftencited_spinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(3500).subscribe(() => {
        this.browseService.searchOftenCitedJudgements(0, this.usertype, this.sortValue, this.query).subscribe((data) => {
          this.oftencitedPageNo = 1;
          this.oftencited_spinner = false;
          // // console.log('query', this.query);
          this.totalResult = data.hits.total.value;
          if ( data.hits.total.value > 0 ) {

            // // console.log(data.hits.total);
            for ( let i = 0; i < data.hits.hits.length; i++ ) {
              this.oftencitedJudgments.push(data.hits.hits[i]._source);
            }

            this.disablePreviousBtn = true;
            this.disableNextBtn = false;

            this.singleJudg = data.hits.hits[0]._source;
            this.isSingleJudg = true;

            // calculate total pages
            const a: number = this.totalResult / 15;
            
            // console.log('latest count', this.totalResult);
            if ( this.totalResult % 15 === 0 ) {
              this.oftencitedTotalPages = a;
            } else if ( this.totalResult % 15 > 0 ) {
              const temp = a.toFixed(1);
              const digit = temp[temp.length - 1];
              

              if ( +digit < 5 ) {
                this.oftencitedTotalPages = +(a.toFixed()) + 1;
              } else {
                this.oftencitedTotalPages = +(a.toFixed());
              }
            }
            
            if ( a > 1 ) {
              this.oftencitedpagination = true;
            } else {
              this.oftencitedpagination = false;
            }

          } else {
            this.snackBar.open("No results found!", "OK", {
              duration: 4000
            });
          }
        });
      });
    });
  }
}

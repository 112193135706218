import { Component, OnInit, OnDestroy } from "@angular/core";
import {FormControl, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from "@angular/material";
import { ErrorStateMatcher } from "@angular/material/core";
import { RouterModule, Routes, Router } from "@angular/router";

import { User } from "../shared/user";
import { Judgement } from "../shared/judgement";
import { Annotation } from "../shared/user";

import { AuthService } from "../services/auth.service";
import { SearchService } from "../services/search.service";
import { CookieService } from "ngx-cookie-service";
import { empty, timer } from "rxjs";

// import { UserprofileComponent } from '../userprofile/userprofile.component';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: "app-annotation",
  templateUrl: "./annotation.component.html",
  styleUrls: ["./annotation.component.scss"]
})
export class AnnotationComponent implements OnInit {

  user = new User();
  judgement = new Judgement();
  annotation = new Annotation();
  citation: string;
  annotationText: string;
  disable = true;
  annotationPresent = false;    // annotation already present ?
  annotationSpinner = false;
  index: number;
  showDeleteBtn = false;
  showCancelBtn = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private searchService: SearchService,
    private cookieService: CookieService,
    public snackBar: MatSnackBar,
    public annotationModal: MatDialogRef<AnnotationComponent>,
    public annotationModal1: MatDialog
    // public userProfileComponent: UserprofileComponent
  ) { }

  ngOnInit() {
    // const contactno = localStorage.getItem('contactno');
    const contactno = this.cookieService.get("contactno");
    this.annotationSpinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(1000).subscribe(() => {
        this.authService.getUserByContactno(contactno).subscribe(data => {
          this.user = data;
          // console.log('user', this.user);
    
          // this.citation = localStorage.getItem('cit');
          this.citation = this.cookieService.get("cit");
          // console.log('citation', this.citation);
    
          this.annotationSpinner = false;
    
          this.user.annotations.forEach((annotation) => {
            if ( annotation.citation === this.citation) {
              this.annotation.citation = annotation.citation;
              this.annotation.body = annotation.body;
              // console.log('annotation', this.annotation);
            } 
          });
    
          // console.log('test', this.user.annotations.find((x) => x.citation === this.citation));
          if ( this.user.annotations.find((x) => x.citation === this.citation) === undefined ) {
            // console.log('hey');
            this.disable = false;
            this.showDeleteBtn = false;
            this.showCancelBtn = true;
          } else {
            this.showDeleteBtn = true;
            this.showCancelBtn = false;
          }
    
          // const searchData = 'Citation:"' + this.citation + '"';
          // // console.log('usertype', this.user.usertype);
          // this.searchService.search(searchData, this.user.usertype, 0).subscribe(data => {
            
          //   this.judgement = data.hits.hits[0]._source;
          //   // console.log('judgement', this.judgement);
          // });
        });
      });
    });
    

    // const cit = localStorage.getItem('cit');
    // const body = localStorage.getItem('body');

    // if ((cit !== '' || cit !== undefined) && (body !== '' || body !== undefined)) {
    //   this.annotationPresent = true;
    //   this.annotation.citation = cit;
    //   this.annotation.body = body;
    // } else {
    //   this.annotationPresent = false;
    //   this.annotation.body = '';
    //   // console.log('anno', this.annotation);
    // }
    // // console.log('anno', this.annotation);
  }

  saveAnnotation() {
    // console.log('save');
    this.annotation.body = this.annotationText.trim();
    this.annotation.citation = this.citation;
    // console.log('citation', this.citation);
    
    if ( this.user.usertype === "Paid" || this.user.usertype === "MPP" ) {
      if ( this.user.annotations.length > 0 ) {

        if ( this.user.annotations.find((x) => x.citation === this.citation) === undefined ) {
          // console.log('cit no matched');
          this.user.annotations.push(this.annotation);
          // const token = localStorage.getItem('JWT');
          const token = this.cookieService.get("JWT");
          // const id = localStorage.getItem('ID');
          const id = this.cookieService.get("ID");

          this.authService.checkJWTtoken().then(() => {
            timer(1000).subscribe(() => {
              this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
                this.user = userdata.result;
                // console.log('Afterupdate', this.user);
    
                // refreshing user profile
                // this.router.navigateByUrl('/homepage', {skipLocationChange: true}).then(() => {
                //   this.router.navigate(['/profile']);
                // });
                // setTimeout(() => {
                //   this.userProfileComponent.refreshData();
                // }, 1500);
                
          
                this.disable = true;
                this.showDeleteBtn = true;
                this.showCancelBtn = false;
          
                const x = document.getElementById(this.citation + " annotation inactive");
                x.style.display = "none";
                const y = document.getElementById(this.citation + " annotation active");
                y.style.display = "block";
          
                this.snackBar.open("Successfully added to Annotations!", "OK", {
                  duration: 3000
                });
              });
            });
          });
        } else {
          let i = 0;
          this.user.annotations.forEach((anno) => {
            // console.log('annocit', anno.citation);
            if ( anno.citation === this.citation ) {
              this.index = i;
              // console.log('index', this.index);
              this.user.annotations.splice(this.index, 1);
              // console.log('after removeanno', this.user.annotations)
              this.user.annotations.splice(this.index, 0, this.annotation);
              // console.log('remove addanno', this.user.annotations);
              // const token = localStorage.getItem('JWT');
              const token = this.cookieService.get("JWT");
              // const id = localStorage.getItem('ID');
              const id = this.cookieService.get("ID");

              this.authService.checkJWTtoken().then(() => {
                timer(1000).subscribe(() => {
                  this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
                    this.user = userdata.result;
                    // console.log('Afterupdate', this.user);
                    this.annotation = this.user.annotations[this.index];
                    // console.log('aftersave', this.annotation);
    
                    // refreshing user profile
                    // this.router.navigateByUrl('/homepage', {skipLocationChange: true}).then(() => {
                    //   this.router.navigate(['/profile']);
                    // });
    
                    this.disable = true;
                    this.showDeleteBtn = true;
                    this.showCancelBtn = false;
    
                    const x = document.getElementById(this.citation + " annotation inactive");
                    x.style.display = "none";
                    const y = document.getElementById(this.citation + " annotation active");
                    y.style.display = "block";
    
                    this.snackBar.open("Successfully saved to Annotations!", "OK", {
                      duration: 3000
                    });
                  });
                });
              });
              
            }
            i += 1;
          });
        }

      } else {
        this.user.annotations.push(this.annotation);
        // const token = localStorage.getItem('JWT');
        const token = this.cookieService.get("JWT");
        // const id = localStorage.getItem('ID');
        const id = this.cookieService.get("ID");

        this.authService.checkJWTtoken().then(() => {
          timer(1000).subscribe(() => {
            this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
              this.user = userdata.result;
              // console.log('Afterupdate', this.user);
    
              // refreshing user profile
              // this.router.navigateByUrl('/homepage', {skipLocationChange: true}).then(() => {
              //   this.router.navigate(['/profile']);
              // });
              // setTimeout(() => {
              //   this.userProfileComponent.refreshData();
              // }, 1500);
        
              this.disable = true;
              this.showDeleteBtn = true;
              this.showCancelBtn = false;
        
              const x = document.getElementById(this.citation + " annotation inactive");
              x.style.display = "none";
              const y = document.getElementById(this.citation + " annotation active");
              y.style.display = "block";
        
              this.snackBar.open("Successfully added to Annotations!", "OK", {
                duration: 3000
              });
            });
          });
        });
        
      }
    } else if ( this.user.usertype === "Free" || this.user.usertype === "Trial" ) {
      // console.log('free/trial');
      if ( this.user.annotations.length < 5 ) {             // allow to add/update if length less than 5 for trial/free users
        // console.log('less than 5');
        if ( this.user.annotations.length > 0 ) {           // if length greater than 0 then check to add or update if length is 0 then directly add.
          
          if ( this.user.annotations.find((x) => x.citation === this.citation) === undefined ) {
            // console.log('push');
            this.user.annotations.push(this.annotation);
            // const token = localStorage.getItem('JWT');
            const token = this.cookieService.get("JWT");
            // const id = localStorage.getItem('ID');
            const id = this.cookieService.get("ID");

            this.authService.checkJWTtoken().then(() => {
              timer(1000).subscribe(() => {
                this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
                  this.user = userdata.result;
                  // console.log('Afterupdate', this.user);
    
                  // refreshing user profile
                  // this.router.navigateByUrl('/homepage', {skipLocationChange: true}).then(() => {
                  //   this.router.navigate(['/profile']);
                  // });
            
                  this.disable = true;
                  this.showDeleteBtn = true;
                  this.showCancelBtn = false;
      
                  const x = document.getElementById(this.citation + " annotation inactive");
                  x.style.display = "none";
                  const y = document.getElementById(this.citation + " annotation active");
                  y.style.display = "block";
    
                  // this.userProfleComponent.ngOnInit();
      
                  this.snackBar.open("Successfully added to Annotations!", "OK", {
                    duration: 3000
                  });
                });
              });
            });
          } else {
            let i = 0;
            this.user.annotations.forEach((anno) => {
              // console.log('edit');
              if ( anno.citation === this.citation ) {
                this.index = i;
                // console.log('index', this.index);
                this.user.annotations.splice(this.index, 1);
                this.user.annotations.splice(this.index, 0, this.annotation);
                // const token = localStorage.getItem('JWT');
                const token = this.cookieService.get("JWT");
                // const id = localStorage.getItem('ID');
                const id = this.cookieService.get("ID");

                this.authService.checkJWTtoken().then(() => {
                  timer(1000).subscribe(() => {
                    this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
                      this.user = userdata.result;
                      // console.log('Afterupdate', this.user);
                      this.annotation = this.user.annotations[this.index];
                      // console.log('aftersave', this.annotation);
    
                      // refreshing user profile
                      // this.router.navigateByUrl('/homepage', {skipLocationChange: true}).then(() => {
                      //   this.router.navigate(['/profile']);
                      // });
    
                      this.disable = true;
                      this.showDeleteBtn = true;
                      this.showCancelBtn = false;
    
                      const x = document.getElementById(this.citation + " annotation inactive");
                      x.style.display = "none";
                      const y = document.getElementById(this.citation + " annotation active");
                      y.style.display = "block";
    
                      // this.userProfleComponent.ngOnInit();
    
                      this.snackBar.open("Successfully saved to Annotations!", "OK", {
                        duration: 3000
                      });
                    });
                  });
                });
              }
              i += 1;
            });
          }
          
        } else {
          // console.log('push');
          this.user.annotations.push(this.annotation);
          // const token = localStorage.getItem('JWT');
          const token = this.cookieService.get("JWT");
          // const id = localStorage.getItem('ID');
          const id = this.cookieService.get("ID");

          this.authService.checkJWTtoken().then(() => {
            timer(1000).subscribe(() => {
              this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
                this.user = userdata.result;
                // console.log('Afterupdate', this.user);
    
                // refreshing user profile
                // this.router.navigateByUrl('/profile', {skipLocationChange: true}).then(() => {
                //   this.router.navigate(['/profile']);
                // });
                // setTimeout(() => {
                //   this.userProfileComponent.refreshData();
                // }, 1500);
    
                this.disable = true;
                this.showDeleteBtn = true;
                this.showCancelBtn = false;
        
                const x = document.getElementById(this.citation + " annotation inactive");
                x.style.display = "none";
                const y = document.getElementById(this.citation + " annotation active");
                y.style.display = "block";
    
                // this.userProfleComponent.ngOnInit();
        
                this.snackBar.open("Successfully added to Annotations!", "OK", {
                  duration: 3000
                });
              });
            });
          });
          
        }
      } else if ( this.user.annotations.length >= 5 ) {
        const upgradeSnackbar = this.snackBar.open("To add more than 5 annotations, please Upgrade to premium plan!", "Upgrade", {
          duration: 5000
        });

        upgradeSnackbar.onAction().subscribe(() => {
          this.router.navigate(["/paymentgateway"]);
        });
      }
    }
    
  }

  editAnnotation() {
    this.disable = false;
    this.showCancelBtn = true;
    this.annotationText = this.annotation.body;
  }

  cancelEditing() {
    this.disable = true;
    this.showCancelBtn = false;
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    // localStorage.removeItem('cit');
    this.cookieService.delete("cit");
    // localStorage.removeItem('body');
    this.cookieService.delete("body");
  }

  closeAnnotationModal() {
    this.annotationModal.close();
  }

  deleteAnnotation() {
    // const index = this.user.annotations.findIndex((annotation) => annotation.citation === this.citation);
    let i = 0;
    this.user.annotations.forEach((Annotation) => {
      if ( Annotation.citation === this.annotation.citation ) {
        this.index = i;
        // console.log('index', this.index);
        this.user.annotations.splice(this.index, 1);
        // const token = localStorage.getItem('JWT');
        const token = this.cookieService.get("JWT");
        // const id = localStorage.getItem('ID');
        const id = this.cookieService.get("ID");

        this.authService.checkJWTtoken().then(() => {
          timer(1000).subscribe(() => {
            this.authService.updateUserWithToken(id, this.user, token).subscribe(userdata => {
              this.user = userdata.result;
              // console.log('afterdelete', this.user);
              // this.annotation.body = '';
              // console.log('annotationbody', this.annotation.body);
              this.showDeleteBtn = false;
              this.annotationModal.close();
    
              const x = document.getElementById(this.citation + " annotation inactive");
              x.style.display = "block";
              const y = document.getElementById(this.citation + " annotation active");
              y.style.display = "none";
    
              const deleteSnackbar = this.snackBar.open("Successfully deleted!", "Undo", {
                duration: 6000
              });
    
              deleteSnackbar.onAction().subscribe(() => {
                // console.log('body', this.annotation);
                this.user.annotations.splice(this.index, 0, this.annotation);
                // console.log('after undo', this.user);
                this.authService.updateUserWithToken(id, this.user, token).subscribe(undouserdata => {
                  this.user = undouserdata.result;
                  // console.log('userdata', this.user);
                  this.user.annotations.forEach((annotation) => {
                    if ( annotation.citation === this.citation) {
                      this.annotation.citation = annotation.citation;
                      this.annotation.body = annotation.body;
                      // console.log('annotation', this.annotation);
                    }
                  });
                  // const loginDialogRef = this.annotationModal1.open(AnnotationComponent, {
                  //   width: '400px',
                  //   height: '400px'
                  // });
    
                  this.showDeleteBtn = true;
                  
                  const x = document.getElementById(this.citation + " annotation inactive");
                  x.style.display = "none";
                  const y = document.getElementById(this.citation + " annotation active");
                  y.style.display = "block";
    
                });
              });
            });
          });
        });
        
      }
      i += 1;
    });
    // console.log('annotations', this.user.annotations);
  }
}

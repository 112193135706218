import { Component, OnInit } from '@angular/core';
import { MatGridListModule, MatGridList } from '@angular/material';

@Component({
  selector: 'app-home-registration',
  templateUrl: './home-registration.component.html',
  styleUrls: ['./home-registration.component.scss']
})
export class HomeRegistrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}


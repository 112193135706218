/**
 * Here all the app configurations are written
 */
import { Component, OnInit, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { Router, RouterOutlet, ActivatedRoute, RouterStateSnapshot } from "@angular/router";
import { Location, LocationStrategy, PathLocationStrategy } from "@angular/common";
import { ConnectionService } from "ng-connection-service";
import { AuthService } from "../app/services/auth.service";
import { CookieService } from "ngx-cookie-service";
import { baseURL, baseDomain } from "./shared/baseurl";
import { User } from "./shared/user";
import { fader, slider } from "./shared/route-animations";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
  trigger,
  transition,
  style,
  query,
  group,
  animateChild,
  animate,
  keyframes,
} from "@angular/animations";
import { MessagingService } from "./services/messaging.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    // fader,
    // slider
  ]
})
export class AppComponent implements OnInit {
  title = "app";
  isConnected = true;
  message; // for firebase
  time: any;
  httpSecure = false;
  user = new User();

  constructor(
    private messagingService: MessagingService,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private connectionService: ConnectionService,
    private authService: AuthService,
    private location: Location,
    private cookieService: CookieService
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected === false) {
        this.snackBar.open("Kindly check your internet connection!", "OK");
      } else {
        this.snackBar.dismiss();
      }
    });
  }

  ngOnInit() {
    const check = this.cookieService.check("JWT");
    let trialexpirydate;
    let expirydate;
    if (check === false) {
      // to open the `/paymentgateway` url
      if (window.location.href.includes("/paymentgateway")) {
        this.router.navigate(["/paymentgateway"]);
      } else if (window.location.href.includes("/callback")) {
        setTimeout(() => {
          const mb = null;
        }, 4000);
      } else {
        this.router.navigate(["/homepage"]);
      }
    } else {
      this.authService.verifyToken().subscribe(data1 => {
        if (data1.success === true) {
          const newToken = data1.token;
          const newExpiryToken = data1.expiryToken;
          const newRefreshToken = data1.refreshToken;
          const newExpiryRefreshToken = data1.expiryRefreshToken;

          if (typeof newToken !== "undefined") {
            this.cookieService.set("JWT", newToken, 30, "/", baseDomain, false, "Lax");
          }
          if (typeof newExpiryToken !== "undefined") {
            this.cookieService.set("expToken", newExpiryToken, 30, "/", baseDomain, false, "Lax");
          }
          // localStorage.setItem('expToken', newExpiryToken);
          if (typeof newRefreshToken !== "undefined") {
            this.cookieService.set("refreshToken", newRefreshToken, 30, "/", baseDomain, false, "Lax");
          }
          if (typeof newExpiryRefreshToken !== "undefined") {
            this.cookieService.set("expiryRefreshToken", newExpiryRefreshToken, 30, "/", baseDomain, false, "Lax");
          }

          if (data1.user) {
            this.cookieService.set("username", data1.user.username, 30, "/", baseDomain, false, "Lax");
            this.cookieService.set("contactno", data1.user.contactno, 30, "/", baseDomain, false, "Lax");
            this.cookieService.set("ID", data1.user._id, 30, "/", baseDomain, false, "Lax");
            this.cookieService.set("usertype", data1.user.usertype, 30, "/", baseDomain, false, "Lax");

            updateSession(data1.user.contactno, data1.user.usertype);

            if (typeof data1.user.expirydate !== null) {
              const expirystring = data1.user.expirydate;
              expirydate = new Date(expirystring);
            }

            if (typeof data1.user.trialexpirydate !== null) {
              const trialexpirystring = data1.user.trialexpirydate;
              trialexpirydate = new Date(trialexpirystring);
            }

            const currentdateiso = Date.now();
            const currentdate = new Date(currentdateiso);
            this.user = data1.user;
            if ((currentdate > trialexpirydate) && data1.user.usertype === "Trial") {
              // // console.log('1');
              this.user.usertype = "Free";

              // updating session
              updateSession(data1.user.contactno, this.user.usertype);

              this.authService.updateUserWithToken(data1.user._id, this.user, data1.token).subscribe(result => {
                // // console.log('result', result);
                this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain, false, "Lax");
                // this.sessionService.updateUserInSession(result);
              });
            } else if ((currentdate <= trialexpirydate) && data1.user.usertype === "Trial") {
              // // console.log('2');
              this.cookieService.set("usertype", data1.user.usertype, 30, "/", baseDomain, false, "Lax");

              // updating session
              updateSession(data1.user.contactno, this.user.usertype);
            } else if (data1.user.usertype === "Free") {
              this.cookieService.set("usertype", data1.user.usertype, 30, "/", baseDomain, false, "Lax");

              // updating session
              updateSession(data1.user.contactno, this.user.usertype);

            } else if (typeof data1.user.expirydate !== null && (expirydate <= currentdate) && (data1.user.usertype === "Paid" || data1.user.usertype === "MPP")) {
              this.user.usertype = "Free";
              this.user.paidflag = false;
              this.user.amount = "";

              // updating session
              updateSession(data1.user.contactno, this.user.usertype);

              this.authService.updateUserWithToken(data1.user._id, this.user, data1.token).subscribe(result => {
                // localStorage.setItem('usertype', result.usertype);
                this.cookieService.set("usertype", result.usertype, 30, "/", baseDomain, false, "Lax");
                // this.sessionService.updateUserInSession(result);
              });

            } else if (typeof data1.user.expirydate !== null && (expirydate > currentdate) && (data1.user.usertype === "Paid" || data1.user.usertype === "MPP")) {
              // localStorage.setItem('usertype', data.user.usertype);
              this.cookieService.set("usertype", data1.user.usertype, 30, "/", baseDomain, false, "Lax");

              // updating session
              updateSession(data1.user.contactno, this.user.usertype);
            }
          }

          // go to browse after updating token
          // console.log(window.location.href);
          const url = window.location.href;
          // console.log(url.includes('/browse'));
          setTimeout(() => {
            // // console.log('yo');
            if (url.includes("/browse") === true) {
              this.router.navigate(["/browse"]);
            } else if (url.includes("/search") === true) {
              this.router.navigate(["/search"]);
            } else if (url.includes("/latestjudgments") === true) {
              this.router.navigate(["/latestjudgments"]);
            } else if (url.includes("/oftencitedjudgments") === true) {
              this.router.navigate(["/oftencitedjudgments"]);
            } else if (url.includes("/importantjudgments") === true) {
              this.router.navigate(["/importantjudgments"]);
            } else if (url.includes("/landmarkjudgments") === true) {
              this.router.navigate(["/landmarkjudgments"]);
            } else if (url.includes("/profile") === true) {
              this.router.navigate(["/profile"]);
            } else if (url.includes("/paymentgateway") === true) {
              this.router.navigate(["/paymentgateway"]);
            } else {
              this.router.navigate(["/browse"]);
            }

            // setInterval( async () => {
            //   await this.authService.checkJWTtoken();
            // }, 4000);     // 4 seconds
          }, 100);
        }
      });

    }

    const authService = this.authService;
    function updateSession(contactno, data) {
      authService.updateSession(contactno, data);
      // .subscribe(resp => {
      //   // // console.log('resp', resp);
      // });
    }


    // const token = localStorage.getItem('JWT');
    // setTimeout(() => {
    //   const token = this.cookieService.get('JWT');

    //   // this.cookieService.check()
    //   // // console.log('token', token);
    //   // // console.log('token type', typeof token);
    //   // // console.log(this.router);
    //   if ( token !== 'undefined' ) {
    //     this.router.navigate(['/browse']);
    //   } else if ( token === 'undefined') {
    //     this.router.navigate(['/homepage']);
    //   }
    // }, 1000);

  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"];
  }

}

import { Component, OnInit } from '@angular/core';
import {MatDialog, MatChip, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar} from '@angular/material';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { LoginComponent } from '../login/login.component';
import { SignupComponent } from '../signup/signup.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  constructor(
    public loginModal: MatDialog,
    public signupModal: MatDialog,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  openLoginModal() {
    const loginDialogRef = this.loginModal.open(LoginComponent, {
      width: '400px',
      height: '400px'
    });
  }

  openSignupModal() {
    const signupDialogRef = this.signupModal.open(SignupComponent, {
      width: '450px',
      height: '450px'
    });
  }

}

import { Component, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar
} from "@angular/material";
import { Network, Timeline, DataSet } from "vis";
import * as moment from "moment";

import { Router } from "@angular/router";

import { BrowseService } from "../services/browse.service";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "../services/auth.service";
import { timer } from "rxjs";

// import moment = require('moment');

@Component({
  selector: "app-timeline-modal",
  templateUrl: "./timeline-modal.component.html",
  styleUrls: ["./timeline-modal.component.scss"]
})
export class TimelineModalComponent implements OnInit {
  constructor(
    public timelineModal: MatDialogRef<TimelineModalComponent>,
    public snackBar: MatSnackBar,
    private browseService: BrowseService,
    private cookieService: CookieService,
    private authService: AuthService,
    private router: Router
  ) // private Moment: moment
  {}

  ngOnInit() {
    const citation: string = this.cookieService.get("timeline");
    this.authService.checkJWTtoken().then(() => {
      timer(1000).subscribe(() => {
        this.browseService.getTimelineData(citation).subscribe(data => {
          try {
            const timelinedata = data.result[0].timelinedata;
            if (timelinedata.length > 0) {
              const items = new DataSet();
              items.add(timelinedata);

              const container = document.getElementById(
                "timelinevisualization"
              );
              const options = {
                width: "600px",
                height: "300px"
              };
              document.getElementById("timelinevisualization").innerHTML = "";
              document.getElementById("log").innerHTML =
                "<h4>Click on any Date to expand case information related to that event.</h4>";
              const timeline = new Timeline(container, items, options);

              timeline.on("click", function(properties) {
                document.getElementById("log").innerHTML = "";
                for (let i = 0; i < timelinedata.length; i++) {
                  if (properties.item === timelinedata[i].id) {
                    const log = document.getElementById("log");
                    log.innerHTML =
                      "<h4>Shortnote : </h4>" + timelinedata[i].paragraph;
                  }
                }
              });
            } else {
              document.getElementById("log").innerHTML = "";
              document.getElementById("timelinevisualization").innerHTML =
                "<h4>No timeline for this case. You can check for headnote</h4>";
            }
          } catch (e) {
            document.getElementById("log").innerHTML = "";
            document.getElementById("timelinevisualization").innerHTML =
              "<h4>No timeline for this case. You can check for headnote</h4>";
          }
        });
      });
    });
  }

  closetimelineModal() {
    this.timelineModal.close();
  }
}

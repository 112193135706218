import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar
} from "@angular/material";
import { Router } from "@angular/router";

import { CookieService } from "ngx-cookie-service";

import { AuthService } from "../services/auth.service";
import { SearchService } from "../services/search.service";
import { User } from "../shared/user";
import { baseURL } from "../shared/baseurl";
import { Judgement } from "../shared/judgement";
import { Annotation } from "../shared/user";

import { AnnotationProfileComponent } from "../annotation-profile/annotation-profile.component";
import { timer } from "rxjs";
import { FormControl, Validators } from "@angular/forms";
// import { timingSafeEqual } from 'crypto';

@Component({
  selector: "app-userprofile",
  templateUrl: "./userprofile.component.html",
  styleUrls: ["./userprofile.component.scss"]
})
export class UserprofileComponent implements OnInit {
  username: string;
  contactno: string;
  user = new User();
  userID: string;
  profilepic: File;
  tempurl: string;
  showUpload = false;
  nophotoURL = "https://nearlaw.com/PDF/profilephotos/no-photo.png";

  showSecondaryPractice = false;
  showTertiaryPractice = false;

  bookmarks: string[];
  bookmarkBody: Judgement;
  bookmarkSpinner = false;

  annotations: Annotation[] = [];
  annotation = new Annotation();
  annotationSpinner = false;
  annotatedJudgment: Judgement;
  editannotation = false;
  annotationText: string;
  index: number;

  fontSize = 16;
  zoomInCount = 0;
  zoomOutCount = 0;
  zoomCount = 0;

  fontSizeAnnotation = 16;
  zoomInCountAnnotation = 0;
  zoomOutCountAnnotation = 0;
  zoomCountAnnotation = 0;

  praciceAreas = [
    { value: "Select one" },
    { value: "Accident Claims" },
    { value: "Administrative Law" },
    { value: "Arbitration & Mediation" },
    { value: "Armed Forces" },
    { value: "Banking & Finance" },
    { value: "Company Law" },
    { value: "Civil & Criminal" },
    { value: "Competition/ Monopoly" },
    { value: "Contracts" },
    { value: "Constitution of India" },
    { value: "Criminal" },
    { value: "Deeds & Conveyance" },
    { value: "Environmental Law" },
    { value: "Family" },
    { value: "Goods & Services" },
    { value: "Immigration" },
    { value: "Information Technology" },
    { value: "Insurance" },
    { value: "Intellectual Property" },
    { value: "International" },
    { value: "Juvenile" },
    { value: "Labour & Service" },
    { value: "Law of Medicine" },
    { value: "Law of Torts" },
    { value: "Legal Process Outsourcing" },
    { value: "Maritime" },
    { value: "Media & Entertainment" },
    { value: "Other Civil" },
    { value: "Property & Real Estate" },
    { value: "Public Interest Litigation" },
    { value: "Sports" },
    { value: "Taxation" },
    { value: "Wills & Trusts" },
    { value: "Women & Children" }
  ];

  emaildisabled = true;
  agedisabled = true;
  genderdisabled = true;
  practiceareadisabled = true;
  secondarypracticeareadisabled = true;
  tertiarypracticeareadisabled = true;

  profilePhotoSpinner = false;

  emailid: string;
  age;
  practicearea: string;
  secondarypracticearea: string;
  tertiarypracticearea: string;

  showbookmark = false;

  interval: any;

  constructor(
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private router: Router,
    private searchService: SearchService,
    private cookieService: CookieService,
    public annotationModal: MatDialog
  ) {
    this.age = new FormControl("", [Validators.maxLength(2)]);
  }

  ngOnInit() {
    this.refreshData();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    clearInterval(this.interval);
  }

  refreshData() {
    this.username = this.cookieService.get("username");
    this.contactno = this.cookieService.get("contactno");
    this.authService.checkJWTtoken().then(() => {
      timer(2000).subscribe(() => {
        this.authService.getUserByContactno(this.contactno).subscribe(data => {
          this.user = data;
          this.age.setValue(data.age);
          if (this.user.practicearea !== "") {
            this.showSecondaryPractice = true;
          }

          if (
            this.user.practicearea !== "" &&
            this.user.secondarypracticearea !== ""
          ) {
            this.showTertiaryPractice = true;
          }

          this.bookmarks = this.user.bookmarks;
          if (this.bookmarks[0] === "") {
            const removed = this.bookmarks.splice(0, 1); // remove if first element is empty
          }

          this.annotations = this.user.annotations;

          if (this.user.userprofilephotopath !== "") {
            this.tempurl = this.user.userprofilephotopath;
          }
        });
      });
    });

    this.userID = this.cookieService.get("ID");
  }

  onFileChanged(event) {
    this.profilepic = event.target.files[0];
    // //('profilepic', this.profilepic);
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        this.user.userprofilephotopath = event.target.result;
        // // //('url', this.url);
      };
      reader.readAsDataURL(event.target.files[0]);
      this.showUpload = true;
    }
  }

  // onSelect() {

  //   // this.showUpload = true;
  // }

  onUpload() {
    this.user.userprofilephotopath =
      baseURL + "PDF/profilephotos/" + this.profilepic.name;
    this.user.userprofilephotoname = this.profilepic.name;
    this.profilePhotoSpinner = true;

    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.authService
          .uploadPprofilePic(this.profilepic, this.user)
          .subscribe(data => {
            // //('data', data);
            if (data.msg === "successful") {
              this.user = data.result;
              this.profilePhotoSpinner = false;
              this.snackBar.open("Profile Photo successfully saved!", "OK", {
                duration: 3000
              });
              this.showUpload = false;
              this.authService
                .getUserByContactno(this.contactno)
                .subscribe(data => {
                  this.user = data;
                  if (this.user.userprofilephotopath !== "") {
                    this.tempurl = this.user.userprofilephotopath;
                    // //('tempurl', this.tempurl);
                  }
                  // //('userdata', this.user);
                });
            } else {
              this.snackBar.open(
                "Error has occured while saving profile photo, Kindly upload again!",
                "OK"
              );
            }
          });
      });
    });
  }

  stopUpload() {
    this.showUpload = false;
    this.profilePhotoSpinner = false;
    this.user.userprofilephotopath = this.tempurl;
    this.profilepic = null;
  }

  editemail() {
    this.emaildisabled = false;
  }

  saveemail() {
    this.user.emailid = this.emailid.trim();

    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.authService
          .updateUserById(this.userID, this.user)
          .subscribe(data => {
            // //('data', data);
            if (data.msg === "successful") {
              this.user = data.result;
              // //('afterupdate', this.user);
              this.snackBar.open("Successfully edited!", "OK", {
                duration: 3000
              });
            } else if (data.msg === "unsuccessful") {
              this.snackBar.open(
                "Error occured while editing, kindly edit again!",
                "OK"
              );
            }
          });
      });
    });
    this.emaildisabled = true;
  }
  closemail() {
    this.emaildisabled = true;
  }

  editage() {
    this.age.reset({ value: "", disabled: false });
    this.agedisabled = false;
  }

  saveage() {
    if (this.age.status === "VALID") {
      this.user.age = this.age.value;

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.authService
            .updateUserById(this.userID, this.user)
            .subscribe(data => {
              // //('data', data);
              if (data.msg === "successful") {
                this.user = data.result;
                // //('afterupdate', this.user);
                this.snackBar.open("Successfully edited!", "OK", {
                  duration: 3000
                });
              } else if (data.msg === "unsuccessful") {
                this.snackBar.open(
                  "Error occured while editing, kindly edit again!",
                  "OK"
                );
              }
            });
        });
      });
      this.agedisabled = true;
      this.age.reset({ value: this.age.value, disabled: true });
    } else {
      this.snackBar.open("Invalid Age", "OK");
    }
  }

  closeage() {
    this.agedisabled = true;
  }

  editpracticearea() {
    this.practiceareadisabled = false;
  }

  savepracticearea() {
    this.user.practicearea = this.practicearea;

    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.authService
          .updateUserById(this.userID, this.user)
          .subscribe(data => {
            // //('data', data);
            if (data.msg === "successful") {
              this.user = data.result;
              // //('afterupdate', this.user);
              this.showSecondaryPractice = true;

              this.snackBar.open("Successfully edited!", "OK", {
                duration: 3000
              });
            } else if (data.msg === "unsuccessful") {
              this.snackBar.open(
                "Error occured while editing, kindly edit again!",
                "OK"
              );
            }
          });
      });
    });
    this.practiceareadisabled = true;
  }

  closepracticearea() {
    this.practiceareadisabled = true;
  }

  editsecondarypracticearea() {
    this.secondarypracticeareadisabled = false;
  }

  savesecondarypracticearea() {
    if (this.user.practicearea !== this.secondarypracticearea) {
      this.user.secondarypracticearea = this.secondarypracticearea;

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.authService
            .updateUserById(this.userID, this.user)
            .subscribe(data => {
              // //('data', data);
              if (data.msg === "successful") {
                this.user = data.result;

                this.showTertiaryPractice = true;
                // //('afterupdate', this.user);
                this.snackBar.open("Successfully edited!", "OK", {
                  duration: 3000
                });
              } else if (data.msg === "unsuccessful") {
                this.snackBar.open(
                  "Error occured while editing, kindly edit again!",
                  "OK"
                );
              }
            });
        });
      });
      this.secondarypracticeareadisabled = true;
    } else {
      this.snackBar.open(
        "Value of Primary Practice Area and Secondary Practice Area cannot be same!",
        "OK",
        {
          duration: 4000
        }
      );
    }
  }

  closesecondarypracticearea() {
    this.secondarypracticeareadisabled = true;
  }

  edittertiarypracticearea() {
    this.tertiarypracticeareadisabled = false;
  }

  savetertiarypracticearea() {
    if (
      this.user.practicearea !== this.tertiarypracticearea &&
      this.user.secondarypracticearea !== this.tertiarypracticearea
    ) {
      this.user.tertiarypracticearea = this.tertiarypracticearea;

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.authService
            .updateUserById(this.userID, this.user)
            .subscribe(data => {
              // //('data', data);
              if (data.msg === "successful") {
                this.user = data.result;
                // //('afterupdate', this.user);
                this.snackBar.open("Successfully edited!", "OK", {
                  duration: 3000
                });
              } else if (data.msg === "unsuccessful") {
                this.snackBar.open(
                  "Error occured while editing, kindly edit again!",
                  "OK"
                );
              }
            });
        });
      });
      this.tertiarypracticeareadisabled = true;
    } else {
      this.snackBar.open(
        "Value of Tertiary Practice Area cannot be same as Primary and Secondary Practice Area!",
        "OK",
        {
          duration: 5000
        }
      );
    }
  }

  closetertiarypracticearea() {
    this.tertiarypracticeareadisabled = true;
  }

  onLogout() {
    this.authService.destroyUserCredentials();
    // localStorage.removeItem('username');
    localStorage.clear();
    this.cookieService.deleteAll();
    this.router.navigate(["/homepage"]).then(() => {
      this.snackBar.open(
        "Thank You for using NearLaw, " + this.username + "!",
        "OK",
        {
          duration: 3000
        }
      );
    });
  }

  getBookmarkBody(bookmark) {
    this.zoomCount = 0;
    this.bookmarkSpinner = true;
    // ('test');
    // ('bookmark', bookmark);
    const query = "Citation: \"" + bookmark + "\"";

    this.authService.checkJWTtoken().then(() => {
      timer(3000).subscribe(() => {
        this.searchService
          .search(query, this.user.usertype, 0)
          .subscribe(data => {
            this.bookmarkBody = data.hits.hits[0]._source;
            const citation = this.bookmarkBody.Citation;

            setTimeout(() => {
              if (
                this.user.annotations.find(z => z.citation === citation) !==
                undefined
              ) {
                const x = document.getElementById(
                  citation + " annotation active"
                );
                if (x !== null) {
                  x.style.display = "block";
                }

                const y = document.getElementById(
                  citation + " annotation inactive"
                );
                if (y !== null) {
                  y.style.display = "none";
                }
              } else {
                const x = document.getElementById(
                  citation + " annotation active"
                );
                // ('x', x);
                if (x !== null) {
                  x.style.display = "none";
                }

                const y = document.getElementById(
                  citation + " annotation inactive"
                );
                // ('y', y);
                if (y !== null) {
                  y.style.display = "block";
                }
              }
            }, 1000);

            this.bookmarkSpinner = false;
          });
      });
    });
  }

  deleteBookmark(bookmark) {
    // ('bookmark', bookmark);
    if (this.user.bookmarks.find(cit => cit === bookmark) !== undefined) {
      const bookmarkIndex = this.user.bookmarks.indexOf(bookmark);
      this.user.bookmarks.splice(this.user.bookmarks.indexOf(bookmark), 1);
      // ('after delete', this.user);
      // const token = localStorage.getItem('JWT');
      const token = this.cookieService.get("JWT");
      // const id = localStorage.getItem('ID');
      const id = this.cookieService.get("ID");

      this.authService.checkJWTtoken().then(() => {
        timer(3000).subscribe(() => {
          this.authService
            .updateUserWithToken(id, this.user, token)
            .subscribe(userdata => {
              this.user = userdata.result;
              this.bookmarks = this.user.bookmarks;
              const deleteSnackBar = this.snackBar.open(
                "Successfully removed from Bookmarks!",
                "Undo",
                {
                  duration: 3000
                }
              );
              deleteSnackBar.onAction().subscribe(() => {
                this.user.bookmarks.splice(bookmarkIndex, 0, bookmark);
                this.authService
                  .updateUserWithToken(id, this.user, token)
                  .subscribe(userdata => {
                    this.user = userdata.result;
                    this.bookmarks = this.user.bookmarks;
                  });
              });
            });
        });
      });
    }
  }

  singlePrint(id) {
    const printContents = document.getElementById(id + " bookmarkBody")
      .innerHTML;
    const w = window.open();
    w.document.write("<h1 align=\"center\" style=\"color: blue;\">NearLaw</h1>");
    if (this.bookmarkBody.Citation !== undefined) {
      w.document.write(
        "<p><strong>Citation:</strong> " + this.bookmarkBody.Citation + "</p>"
      );
    }
    if (this.bookmarkBody.Case_No !== undefined) {
      w.document.write(
        "<p><strong>Case No:</strong> " + this.bookmarkBody.Case_No + "</p>"
      );
    }
    if (this.bookmarkBody.Petitioner !== undefined) {
      w.document.write(
        "<p><strong>Petitioner:</strong> " +
          this.bookmarkBody.Petitioner +
          "</p>"
      );
    }
    if (this.bookmarkBody.Respondent !== undefined) {
      w.document.write(
        "<p><strong>Respondent:</strong> " +
          this.bookmarkBody.Respondent +
          "</p>"
      );
    }
    if (this.bookmarkBody.Court !== undefined) {
      w.document.write(
        "<p><strong>Court:</strong> " + this.bookmarkBody.Court + "</p>"
      );
    }
    if (this.bookmarkBody.Judge_name !== undefined) {
      w.document.write(
        "<p><strong>Judge:</strong> " + this.bookmarkBody.Judge_name + "</p>"
      );
    }
    if (this.bookmarkBody.Date !== undefined) {
      w.document.write(
        "<p><strong>Date:</strong> " + this.bookmarkBody.Date + "</p>"
      );
    }
    if (this.bookmarkBody.decision !== undefined) {
      w.document.write(
        "<p><strong>Decision:</strong> " + this.bookmarkBody.decision + "</p>"
      );
    }
    if (this.bookmarkBody.hc_decision !== undefined) {
      w.document.write(
        "<p><strong>Decision:</strong> " +
          this.bookmarkBody.hc_decision +
          "</p>"
      );
    }
    if (this.bookmarkBody.headline !== undefined) {
      w.document.write(
        "<p><strong>Headline:</strong> " + this.bookmarkBody.headline + "</p>"
      );
    }

    w.document.write(
      "<p><strong>Headnote:</strong> " + this.bookmarkBody.HeadNote + "</p>"
    );
    w.document.write("<style>.hidden-print{display:none!important}</style>");
    w.document.write(
      "<scr" +
        "ipt type=\"text/javascript\">" +
        "window.onload = function() { window.print(); window.close(); };" +
        "</sc" +
        "ript>"
    );

    w.document.close(); // necessary for IE >= 10
    w.focus(); // necessary for IE >= 10
    return true;
  }

  singlePrintAnnotation(citation) {
    const printContents = document.getElementById(
      citation + " annotatedJudgment"
    ).innerHTML;

    this.user.annotations.forEach(x => {
      if (x.citation === citation) {
        this.annotation.createdAt = x.createdAt;
        this.annotation.body = x.body;
      }
    });
    const date = this.annotation.createdAt.toString();
    const normalDate = date.substring(0, 10);
    const w = window.open();
    w.document.write("<h1 align=\"center\" style=\"color: blue;\">NearLaw</h1>");
    w.document.write(
      "<p class=\"align: right\"><strong>Created on: </strong>" +
        normalDate +
        "</p>"
    );
    w.document.write(
      "<p class=\"text-align: right\"><strong>Annotation: </strong><span style=\"white-space: pre-wrap;\">" +
        this.annotation.body +
        "</span></p>"
    );
    if (this.annotatedJudgment.Citation !== undefined) {
      w.document.write(
        "<p><strong>Citation:</strong> " +
          this.annotatedJudgment.Citation +
          "</p>"
      );
    }
    if (this.annotatedJudgment.Case_No !== undefined) {
      w.document.write(
        "<p><strong>Case No:</strong> " +
          this.annotatedJudgment.Case_No +
          "</p>"
      );
    }
    if (this.annotatedJudgment.Petitioner !== undefined) {
      w.document.write(
        "<p><strong>Petitioner:</strong> " +
          this.annotatedJudgment.Petitioner +
          "</p>"
      );
    }
    if (this.annotatedJudgment.Respondent !== undefined) {
      w.document.write(
        "<p><strong>Respondent:</strong> " +
          this.annotatedJudgment.Respondent +
          "</p>"
      );
    }
    if (this.annotatedJudgment.Court !== undefined) {
      w.document.write(
        "<p><strong>Court:</strong> " + this.annotatedJudgment.Court + "</p>"
      );
    }
    if (this.annotatedJudgment.Judge_name !== undefined) {
      w.document.write(
        "<p><strong>Judge:</strong> " +
          this.annotatedJudgment.Judge_name +
          "</p>"
      );
    }
    if (this.annotatedJudgment.Date !== undefined) {
      w.document.write(
        "<p><strong>Date:</strong> " + this.annotatedJudgment.Date + "</p>"
      );
    }
    if (this.annotatedJudgment.decision !== undefined) {
      w.document.write(
        "<p><strong>Decision:</strong> " +
          this.annotatedJudgment.decision +
          "</p>"
      );
    }
    if (this.annotatedJudgment.hc_decision !== undefined) {
      w.document.write(
        "<p><strong>Decision:</strong> " +
          this.annotatedJudgment.hc_decision +
          "</p>"
      );
    }
    if (this.annotatedJudgment.headline !== undefined) {
      w.document.write(
        "<p><strong>Headline:</strong> " +
          this.annotatedJudgment.headline +
          "</p>"
      );
    }

    w.document.write(
      "<p><strong>Headnote:</strong> <span class=\"white-space: pre-wrap;\">" +
        this.annotatedJudgment.HeadNote +
        "</span></p>"
    );
    w.document.write("<style>.hidden-print{display:none!important}</style>");
    w.document.write(
      "<scr" +
        "ipt type=\"text/javascript\">" +
        "window.onload = function() { window.print(); window.close(); };" +
        "</sc" +
        "ript>"
    );

    w.document.close(); // necessary for IE >= 10
    w.focus(); // necessary for IE >= 10
    return true;
  }

  printAll() {
    const printbody = this.bookmarks.toString();
    const w = window.open();
    w.document.write(
      "<h1 align=\"center\" style=\"color: blue;\">NearLaw</h1><br>"
    );
    w.document.write("<h3>List of Bookmarks:</h3><br>");
    for (let i = 0; i < this.bookmarks.length; i++) {
      w.document.write(
        "<div style=\"text-align: justify\"><ul><li>" +
          this.bookmarks[i] +
          "</li></ul></div>"
      );
    }

    w.document.write("<style>.hidden-print{display:none!important}</style>");
    w.document.write(
      "<scr" +
        "ipt type=\"text/javascript\">" +
        "window.onload = function() { window.print(); window.close(); };" +
        "</sc" +
        "ript>"
    );

    w.document.close(); // necessary for IE >= 10
    w.focus(); // necessary for IE >= 10
    return true;
  }

  printAllAnnotations() {
    const citations = [];
    this.annotations.forEach(x => citations.push(x.citation.toString()));
    const bodies = [];
    this.annotations.forEach(x => bodies.push(x.body.toString()));
    const w = window.open();
    w.document.write(
      "<h1 align=\"center\" style=\"color: blue;\">NearLaw</h1><br>"
    );
    w.document.write("<h3>List of Annotations:</h3><br>");
    for (let i = 0; i < this.annotations.length; i++) {
      w.document.write(
        "<div style=\"text-align: justify\"><ul><li><p>Citation: " +
          citations[i] +
          "</p><p style=\"white-space: pre-wrap;\">Annotation: " +
          bodies[i] +
          "</p></li></ul></div>"
      );
    }
    w.document.write("<style>.hidden-print{display:none!important}</style>");
    w.document.write(
      "<scr" +
        "ipt type=\"text/javascript\">" +
        "window.onload = function() { window.print(); window.close(); };" +
        "</sc" +
        "ript>"
    );

    w.document.close(); // necessary for IE >= 10
    w.focus(); // necessary for IE >= 10
    return true;
  }

  openAnnotationModal() {
    const loginDialogRef = this.annotationModal.open(
      AnnotationProfileComponent,
      {
        width: "400px",
        height: "400px"
      }
    );
  }

  userAnnotation(judgement) {
    const citation = judgement.Citation;
    if (
      this.user.annotations.find(
        annotation => annotation.citation === citation
      ) === undefined ||
      this.user.annotations.length === 0
    ) {
      this.cookieService.set("cit", citation);
      this.openAnnotationModal();
    } else {
      this.user.annotations.forEach(annotation => {
        if (annotation.citation === citation) {
          const cit = annotation.citation;
          const body = annotation.body;
          this.cookieService.set("cit", cit);
          localStorage.setItem("body", body);
          this.cookieService.set("body", body);
          this.openAnnotationModal();
        }
      });
    }
  }

  getAnnotatedJudgment(citation) {
    this.zoomCountAnnotation = 0;
    this.annotationSpinner = true;
    const query = "Citation:\"" + citation + "\"";

    this.authService.checkJWTtoken().then(() => {
      timer(2500).subscribe(() => {
        this.searchService
          .search(query, this.user.usertype, 0)
          .subscribe(data => {
            this.annotatedJudgment = data.hits.hits[0]._source;
            this.annotationSpinner = false;
          });
      });
    });
  }

  editAnnotation(annotation) {
    this.editannotation = true;
    this.annotationText = annotation.body;
  }

  saveAnnotation(annotation) {
    const citation = annotation.citation;
    this.annotation.citation = citation;
    this.annotation.body = this.annotationText.trim();
    this.annotation.createdAt = annotation.createdAt;
    let i = 0;
    this.user.annotations.forEach(Annotation => {
      if (Annotation.citation === citation) {
        this.index = i;
        this.user.annotations.splice(this.index, 1);
        this.user.annotations.splice(this.index, 0, this.annotation);
        const token = this.cookieService.get("JWT");
        const id = this.cookieService.get("ID");

        this.authService.checkJWTtoken().then(() => {
          timer(3000).subscribe(() => {
            this.authService
              .updateUserWithToken(id, this.user, token)
              .subscribe(userdata => {
                this.user = userdata.result;
                this.annotations = this.user.annotations;
                this.snackBar.open("Successfully saved to Annotations!", "OK", {
                  duration: 3000
                });
              });
          });
        });
      }
      i += 1;
    });
    this.editannotation = false;
  }

  cancelAnnotation() {
    this.editannotation = false;
  }

  deleteAnnotation(citation) {
    let i = 0;
    this.user.annotations.forEach(Annotation => {
      if (Annotation.citation === citation) {
        this.index = i;
        this.user.annotations.splice(this.index, 1);
        const token = this.cookieService.get("JWT");
        const id = this.cookieService.get("ID");
        this.annotation = Annotation;

        this.authService.checkJWTtoken().then(() => {
          timer(3000).subscribe(() => {
            this.authService
              .updateUserWithToken(id, this.user, token)
              .subscribe(userdata => {
                this.user = userdata.result;
                this.annotations = this.user.annotations;

                const deleteSnackbar = this.snackBar.open(
                  "Successfully deleted!",
                  "Undo",
                  {
                    duration: 6000
                  }
                );

                deleteSnackbar.onAction().subscribe(() => {
                  this.user.annotations.splice(this.index, 0, this.annotation);
                  this.authService
                    .updateUserWithToken(id, this.user, token)
                    .subscribe(undouserdata => {
                      this.user = undouserdata.result;
                      this.annotations = this.user.annotations;
                    });
                });
              });
          });
        });
      }
      i += 1;
    });
  }

  GetParent(elem) {
    speechSynthesis.cancel();
    const parentElem = document.getElementById(elem + " bookmarkBody")
      .innerText;
    const text = parentElem;
    const text1 = text.slice(0, -24);
    text1.replace(/\./g, " ");
    text1.replace(/\:/g, " ");
    const texttospeak = new SpeechSynthesisUtterance(text1);
    speechSynthesis.speak(texttospeak);
  }

  StopSpeech() {
    speechSynthesis.cancel();
  }

  ZoomIn(id) {
    this.zoomCount += 1;
    if (this.zoomCount < 9) {
      this.fontSize += 1.2;
      const elem = document.getElementById(id + " bookmarkBody");
      elem.style.fontSize = this.fontSize + "px";
    }
    if (this.zoomCount >= 9) {
      this.zoomCount = 9;
    }
  }

  ZoomOut(id) {
    this.zoomCount -= 1;
    if (this.zoomCount > -4) {
      this.fontSize -= 1.2;
      const elem = document.getElementById(id + " bookmarkBody");
      elem.style.fontSize = this.fontSize + "px";
    }
    if (this.zoomCount < -3) {
      this.zoomCount = -3;
    }
  }
}

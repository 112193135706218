import { Injectable } from '@angular/core';

import { Judgement }  from '../shared/judgement';

import { baseURL } from '../shared/baseurl';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Http, Headers } from '@angular/http';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';

import { ProcessHttpmsgService } from './process-httpmsg.service';
import { AuthService } from './auth.service';

import { map, delay, catchError, mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  judgement: Judgement;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService,
    private authService: AuthService
  ) { }
}

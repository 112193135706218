import { Injectable } from "@angular/core";

import { Judgement } from "../shared/judgement";

import { baseURL } from "../shared/baseurl";

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Http, Headers } from "@angular/http";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";

import { ProcessHttpmsgService } from "./process-httpmsg.service";
// import { AuthService } from './auth.service';

import { map, delay, catchError} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class HomepageService {

  judgement: Judgement;

  constructor(
    private http: HttpClient,
    private processHTTPMsgService: ProcessHttpmsgService,
    // private authService: AuthService
  ) { }

  getLatestJudgement(): Observable<any> {
    return this.http.get(baseURL + "latestjudgements?q=latest_important:true")
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  homeSearch(query: string): Observable<any> {
    return this.http.get(baseURL + "homeSearch?q=" + query)
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  sendAppLink(data): Observable<any> {
    return this.http.post(baseURL + "applink", data)
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }

  requestDemo(data): Observable<any> {
    const headers = new HttpHeaders();
    headers.append("Access-Control-Allow-Origin", "*");

    return this.http.post(baseURL + "requestdemo", data)
      .pipe(map(res => res), catchError(error => {
        return this.processHTTPMsgService.handleError(error);
      }));
  }
}
